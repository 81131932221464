const { assoc } = require('ramda');
const { setName } = require('../mainChannel');
const { isType } = require('../../../lib');

/**
 * @typedef {import('../channelMember')} ChannelMember
 * @typedef {import('../message')} Message
 * @typedef {import('../file')} File
 * @typedef {{}} Link
 * @typedef {import('../../tag')} Tag
 * @typedef {string} HEX - example #000000
 */

/**
 * @typedef {object} Topic
 * @property {string} type
 * @property {string} id
 * @property {string} name
 * @property {string} title
 * @property {string} desc
 * @property {[File]} files
 * @property {[Link]} links
 * @property {[Tag]} tags
 * @property {Date} createdAt
 * @property {number} ownerEmployeeId
 * @property {HEX} color
 * @property {(null|Message)} lastMessage
 * @property {[ChannelMember]} members
 * @property {string} firstUnreadMessageId
 * @property {[string]} unreadMessagesId
 * @property {boolean} haveNewMessages
 * @property {boolean} archived
 * @property {[number]} notificationOffMembers
 * @property {boolean} notificationOff
 * @property {boolean} isClosed
 */

/**
 * Creates a Topic data type
 *
 * @param {object} params
 * @param {string} params.id
 * @param {string} params.name
 * @param {string} params.title
 * @param {string} params.desc
 * @param {[File]} params.files
 * @param {[Link]} params.links
 * @param {[Tag]} params.tags
 * @param {Date} params.createdAt
 * @param {number} params.ownerEmployeeId
 * @param {HEX} params.color
 * @param {(null|Message)} params.lastMessage
 * @param {[ChannelMember]} params.members
 * @param {string} params.firstUnreadMessageId
 * @param {[string]} params.unreadMessagesId
 * @param {boolean} params.haveNewMessages
 * @param {boolean} params.archived
 * @param {[string]} params.confirm
 * @param {[number]} params.notificationOffMembers
 * @param {boolean} params.notificationOff
 * @param {[Message]} params.pinnedMessages
 * @param {boolean} params.isClosed
 * @returns {Topic}
 */
exports.makeTopic = ({
  id,
  name,
  title,
  desc = '',
  files = [],
  links = [],
  tags = [],
  ownerEmployeeId,
  color,
  lastMessage,
  members = [],
  firstUnreadMessageId,
  unreadMessagesId,
  haveNewMessages,
  archived,
  notificationOffMembers = [],
  notificationOff,
  scheduleMessages = [],
  confirm = [],
  createdAt,
  pinnedMessages = [],
  isClosed = false
} = {}) => ({
  type: 'Topic',
  id,
  name,
  title,
  desc,
  files,
  links,
  tags,
  ownerEmployeeId,
  color,
  lastMessage,
  members,
  firstUnreadMessageId,
  unreadMessagesId,
  haveNewMessages,
  archived,
  notificationOffMembers,
  notificationOff,
  scheduleMessages,
  confirm,
  createdAt,
  pinnedMessages,
  isClosed
});

/**
 * Set topic name
 *
 * @param {Topic} topic
 * @param {string} name
 * @returns {Topic}
 */
exports.setName = setName;

/**
 * Set topic title
 *
 * @param {Topic} topic
 * @param {string} title
 * @returns {Topic}
 */
exports.setTitle = (topic, title) => assoc('title', title, topic);

/**
 * Set topic description
 *
 * @param {Topic} topic
 * @param {string} description
 * @returns {Topic}
 */
exports.setDescription = (topic, description) =>
  assoc('desc', description, topic);

/**
 * Check if chat is topic
 *
 * @param {Topic} chat
 * @returns {boolean}
 */
exports.isTopic = (chat) => isType('Topic', chat);

/**
 * Add files to topic
 *
 * @param {Topic} topic
 * @param {[File]} filesToAdd
 * @returns {boolean}
 */
exports.addFilesToTopic = (topic, filesToAdd = []) =>
  assoc('files', topic.files.concat(filesToAdd), topic);

/**
 * Remove files from topic
 *
 * @param {Topic} topic
 * @param {[File]} filesForRemove
 * @returns {boolean}
 */
exports.removeFilesFromTopic = (topic, filesForRemove = []) =>
  assoc(
    'files',
    topic.files.filter((file) => !filesForRemove.includes(file.id)),
    topic
  );
