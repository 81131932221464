import React from 'react';

/* eslint-disable max-len */
const IconDelete = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="cws-icon-main-stroke"
      d="M4.5 4V14.5C4.5 15.6046 5.39543 16.5 6.5 16.5H11.5C12.6046 16.5 13.5 15.6046 13.5 14.5V4"
      stroke="#767B92"
    />
    <line
      className="cws-icon-main-stroke"
      x1="3"
      y1="4.5"
      x2="15"
      y2="4.5"
      stroke="#767B92"
    />
    <line
      className="cws-icon-main-stroke"
      x1="7"
      y1="1.5"
      x2="11"
      y2="1.5"
      stroke="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconDelete;
