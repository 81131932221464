import { bindActionCreators } from 'redux';
import { addMessageToWaitingCase } from './addMessageToWaitingCase';
import { applyState } from '../../utils';
import * as actions from '../actions';
import { getChatInputLoadingFilesIds } from '../../messageInput/getters';
import { clearInputBlock } from '../../messageInput/actions';
import { sendWaitingMessagesCase } from './sendWaitingMessage';
import { getWaitingMessages } from '../getters';
import * as messagesStorage from '../../messages/storage';

export const addMessageToWaiting = (message) => (dispatch, getState) =>
  addMessageToWaitingCase(
    {
      getChatInputLoadingFilesIds: applyState(
        getState,
        getChatInputLoadingFilesIds
      ),
      clearInputBlock: bindActionCreators(clearInputBlock, dispatch),
      addWaitingMessage: bindActionCreators(actions.addWaitingMessage, dispatch)
    },
    { message }
  );

export const sendWaitingMessages = () => (dispatch, getState) =>
  sendWaitingMessagesCase({
    getWaitingMessages: applyState(getState, getWaitingMessages),
    removeWaitingMessage: bindActionCreators(
      actions.removeWaitingMessage,
      dispatch
    ),
    sendMessage: messagesStorage.sendMsg
  });
