import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { initService } from './components/ChatWidget/useCases/service';
import { customEventsMiddleware } from './middlewares';

import reducer from './reducers/main';

export default () => {
  const service = initService();

  const store = createStore(
    reducer,
    compose(
      applyMiddleware(
        thunkMiddleware.withExtraArgument(service),
        customEventsMiddleware
      ),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true })
        : compose
    )
  );

  if (module.hot) {
    module.hot.accept('./reducers/main', () => {
      const nextRootReducer = require('./reducers/main');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};
