import { CLEAR_CHAT_INPUT, SAVE_CHAT_INPUT } from './constants';

export const saveChatInput = (payload) => ({
  type: SAVE_CHAT_INPUT,
  payload
});

export const clearChatInput = (payload) => ({
  type: CLEAR_CHAT_INPUT,
  payload
});
