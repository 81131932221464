import React from 'react';

/* eslint-disable max-len */
function IconDelayedMessage() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.0555 0.194336C5.37767 0.194336 5.63883 0.455503 5.63883 0.777669V1.361H8.36106V0.777669C8.36106 0.455503 8.62223 0.194336 8.94439 0.194336C9.26656 0.194336 9.52772 0.455503 9.52772 0.777669V1.361H10.8888C12.0701 1.361 13.0277 2.31862 13.0277 3.49989V11.2777C13.0277 12.4589 12.0701 13.4166 10.8888 13.4166H3.11106C1.92978 13.4166 0.972168 12.4589 0.972168 11.2777V3.49989C0.972168 2.31862 1.92978 1.361 3.11106 1.361H4.47217V0.777669C4.47217 0.455503 4.73334 0.194336 5.0555 0.194336ZM4.47217 2.52767H3.11106C2.57411 2.52767 2.13883 2.96295 2.13883 3.49989V4.08322H11.8611V3.49989C11.8611 2.96295 11.4258 2.52767 10.8888 2.52767H9.52772V2.72211C9.52772 3.04428 9.26656 3.30545 8.94439 3.30545C8.62223 3.30545 8.36106 3.04428 8.36106 2.72211V2.52767H5.63883V2.72211C5.63883 3.04428 5.37767 3.30545 5.0555 3.30545C4.73334 3.30545 4.47217 3.04428 4.47217 2.72211V2.52767ZM11.8611 5.24989H2.13883V11.2777C2.13883 11.8146 2.57411 12.2499 3.11106 12.2499H10.8888C11.4258 12.2499 11.8611 11.8146 11.8611 11.2777V5.24989Z"
        fill="#5B5F73"
      />
      <circle cx="12" cy="12" r="2" fill="#E92A58" />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconDelayedMessage;
