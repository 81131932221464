import io from 'socket.io-client';

export const badgeSocket = io('/badge');

export const deleteOrderBadges = ({ userId, companyId, ordersId }) =>
  badgeSocket.emit(
    'deleteOrderBadges',
    JSON.stringify({ userId, companyId, orders: ordersId })
  );

export const orderCustomerViewed = ({ orderId, userId }) =>
  badgeSocket.emit('orderCustomerViewed', JSON.stringify({ orderId, userId }));
