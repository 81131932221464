import { PropsWithChildren } from 'react';
import cn from 'classnames';
import { BaseCell } from '@/components/EditableTable/components/BaseCell';
import { WithClassName } from '@/components/common.types';
import styles from './BaseHeader.module.scss';

interface BaseHeaderProps extends PropsWithChildren, WithClassName {
  colSpan?: number;
  width?: number;
}

export function BaseHeader({
  className,
  children,
  colSpan,
  width
}: BaseHeaderProps) {
  return (
    <BaseCell
      className={cn(className, styles.container, styles.cell)}
      colSpan={colSpan}
      width={width}>
      <div className={styles.content}>{children}</div>
    </BaseCell>
  );
}
