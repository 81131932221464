import { SET_GROUP_CHAT, SHOW_GROUP_CHAT, HIDE_GROUP_CHAT } from './constants';

export const setGroupChats = (blockGroups) => ({
  type: SET_GROUP_CHAT,
  payload: blockGroups
});

export const showGroupChat = (kind) => ({
  type: SHOW_GROUP_CHAT,
  payload: { kind }
});

export const hideGroupChat = () => ({ type: HIDE_GROUP_CHAT, payload: {} });
