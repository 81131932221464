import { defStruct } from '../../lib';

/**
 * @typedef PaginationQuery
 */
const methods = defStruct('PaginationQuery', ['offset', 'limit']);

/**
 * Create a pagination query data type
 *
 * @param {object} params
 * @param {number} params.offset
 * @param {number} params.limit
 * @returns {PaginationQuery}
 */
export const makePaginationQuery = ({ offset = 0, limit = 20 } = {}) =>
  methods.makePaginationQuery(offset, limit);

/**
 * Gives limit of pagination query
 *
 * @param {PaginationQuery} paginationQuery
 * @returns {number}
 */
export const getLimit = (paginationQuery) => methods.getLimit(paginationQuery);

/**
 * Gives offset of pagination query
 *
 * @param {PaginationQuery} paginationQuery
 * @returns {number}
 */
export const getOffset = (paginationQuery) =>
  methods.getOffset(paginationQuery);

/**
 * Checks if is pagination query
 *
 * @param {*} a
 * @returns {boolean}
 */
export const isPaginationQuery = (a) => methods.isPaginationQuery(a);
