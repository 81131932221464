import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef
} from 'react';
import { Snackbar } from '@/shared/components';

const SnackbarContext = createContext(null);

/**
 * @deprecated use toast (@root/modules/toast) instead
 */
export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }) => {
  const snackbarRef = useRef(null);

  const showSnackbar = useCallback((text, options = {}) => {
    snackbarRef.current.show(text, options);
  }, []);

  const closeSnackbar = useCallback(() => {
    snackbarRef.current.close();
  }, []);

  const provider = useMemo(
    () => [showSnackbar, closeSnackbar],
    [showSnackbar, closeSnackbar]
  );

  return (
    <SnackbarContext.Provider value={provider}>
      {children}
      <Snackbar ref={snackbarRef} />
    </SnackbarContext.Provider>
  );
};

export function withSnackbar(Component) {
  function ComponentWithSnackbarProps(props) {
    const snackbarHandlers = useSnackbar();

    return <Component {...props} snackbarHandlers={snackbarHandlers} />;
  }

  return ComponentWithSnackbarProps;
}
