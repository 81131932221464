import { assoc } from 'ramda';

/**
 * @typedef {(number|string)} Id
 */

/**
 * @typedef Badge
 * @property {Id} chatId
 * @property {boolean} haveNewMention
 * @property {number} count
 */

/**
 * @typedef BlockBadges
 * @property {string} type
 * @property {[Badge]} badges
 */

/**
 * Creates a block badges
 *
 * @param {[Badge]} badges
 * @returns {BlockBadges}
 */
export const makeBlockBadges = (badges = []) => ({
  type: 'BlockBadges',
  badges
});

/**
 * Add or update badge item by chat id
 *
 * @param {BlockBadges} blockBadges
 * @param {Badge} badge
 * @returns {BlockBadges}
 */
export const addOrUpdateBadge = (blockBadges, badge) => {
  let newBadges = [];
  const existBadge = blockBadges.badges.find(
    (badgeItem) => badgeItem.chatId === badge.chatId
  );

  if (existBadge) {
    newBadges = blockBadges.badges.map((badgeItem) =>
      badgeItem.chatId === badge.chatId ? badge : badgeItem
    );
  } else {
    newBadges = [...blockBadges.badges, badge];
  }

  return assoc('badges', newBadges, blockBadges);
};

/**
 * Clear badge item by chat id
 *
 * @param {BlockBadges} blockBadges
 * @param {Id} chatId
 * @returns {BlockBadges}
 */
export const clearBadgeByChatId = (blockBadges, chatId) => {
  const newBadges = blockBadges.badges.filter(
    (badge) => badge.chatId !== chatId
  );
  return assoc('badges', newBadges, blockBadges);
};
