import { defRecord } from 'core/lib';

export const { makeMsgForward } = defRecord(
  'MsgForward',
  [
    'chatId',
    'msg',
    'html',
    'mentions',
    'files',
    'forwardedMessages',
    'deliveryId'
  ],
  {
    msg: '',
    html: '<p></p>',
    mentions: [],
    files: []
  }
);
