import io, { Socket, ManagerOptions, SocketOptions } from 'socket.io-client';

interface QueueItem<T = unknown> {
  event: string;
  payload: T;
}

export class BaseSocket {
  private io = io;

  private initializing = false;

  private connected = false;

  private instance: Socket | undefined;

  private queue: QueueItem[] = [];

  init(namespace: string, options?: Partial<ManagerOptions & SocketOptions>) {
    try {
      if (this.ready) return;
      this.initializing = true;

      this.instance = this.io(namespace, options)
        .on('connect', () => {
          this.connected = true;
          this.initializing = false;

          if (this.ready) {
            this.queue.forEach((message) => {
              this.emit(message.event, message.payload);
            });

            this.clearQueue();
          }
        })
        .on('disconnect', () => {
          this.connected = false;
        });
    } finally {
      this.initializing = false;
    }
  }

  get ready() {
    return !this.initializing && this.connected;
  }

  private clearQueue() {
    this.queue = [];
  }

  emit<T = unknown>(event: string, payload: T) {
    if (this.ready) {
      this.instance!.emit(event, payload);
    } else {
      this.queue.push({ event, payload });
    }
  }

  joinToRoom(roomName: string) {
    this.emit('join-room', { roomName });
  }

  leaveRoom(roomName: string) {
    this.emit('leave-room', { roomName });
  }

  listen(event: string, cb: (...args: any[]) => void) {
    if (this.instance) {
      this.instance.on(event, cb);
    }
  }

  removeListener(event: string, cb: (...args: any[]) => void) {
    this.instance!.removeListener(event, cb);
  }
}
