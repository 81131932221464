import { Map, List } from 'immutable';

export const initState = () =>
  new Map({
    orders: new List()
  });

export const addErrors = (data, { type, msg }) =>
  data.updateIn([type], (errs) => errs.push(msg));
export const clearErrors = (data, type) => data.setIn([type], new List());
