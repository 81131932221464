import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from '@/modules/toast';
import {
  updateInternetConnection,
  updateServerConnection
} from '@/action-creators/connections';
import {
  onInterruptedConnection,
  onOffline,
  onOnline,
  onRestoredConnection
} from '@/lib/internetConnection';
import {
  socketClosed,
  socketConnected,
  socketServerClosed
} from '@/lib/serverConnection';
import { getChatMode } from '@/components/ChatWidget/getter';
import { prevChatMode } from '@/components/ChatWidget/modules/chatMode';

const reloadPage = () => location.reload();

const DELAY_SHOW_RECONNECT_MESSAGE = 2000;
let IS_FIRST_RENDER = true;
let showReconnectMessageTimeout;

export function useConnectionHandler() {
  const dispatch = useDispatch();

  useEffect(() => {
    onInterruptedConnection(() => {
      showTryToConnectMessage();
    });

    onRestoredConnection(() => hideTryToConnectMessage());
    onOffline(() => {
      dispatch(updateInternetConnection(false));
    });
    onOnline(() => {
      handleReconnect();
    });
    socketClosed(() => {
      showServerDisconnected();

      if (showReconnectMessageTimeout) {
        clearTimeout(showReconnectMessageTimeout);
        showReconnectMessageTimeout = null;
      }

      showReconnectMessageTimeout = setTimeout(() => {
        showTryToConnectMessage();
      }, DELAY_SHOW_RECONNECT_MESSAGE);
      dispatch(updateServerConnection(false));
    });
    socketConnected(() => {
      if (!IS_FIRST_RENDER) {
        handleReconnect();
        showServerConnected();
      }

      if (showReconnectMessageTimeout) {
        clearTimeout(showReconnectMessageTimeout);
        showReconnectMessageTimeout = null;
      }

      IS_FIRST_RENDER = false;
    });
    socketServerClosed(() => {
      showServerDisconnected();

      if (showReconnectMessageTimeout) {
        clearTimeout(showReconnectMessageTimeout);
        showReconnectMessageTimeout = null;
      }

      showReconnectMessageTimeout = setTimeout(() => {
        showTryToConnectMessage();
      }, DELAY_SHOW_RECONNECT_MESSAGE);

      dispatch(updateServerConnection(false));
    });
  }, []);

  function clearNoInternetConnectionPlugChat() {
    return (_, getState) => {
      const { currentMode } = getChatMode(getState());

      if (currentMode === 'no-connection-internet') {
        dispatch(prevChatMode());
      }
    };
  }

  function showTryToConnectMessage() {
    toast.system(`Messenger IstockLink is trying to connect to the Internet`, {
      key: 'reconnect'
    });
    // toast.system(`Messenger is trying to connect to the Internet`, { key: 'reconnect', data: { key:`Messenger IstockLink is trying to connect to the Internet`  } });
  }

  function showServerDisconnected() {
    toast.system(`IstockLink service disconnect socket`, {
      key: 'disconnected'
    });
  }

  function showServerConnected() {
    const data = {
      key: 'Goodwix service connect socket',
      linkKey: 'Refresh the page',
      linkClickHandler: reloadPage
    };
    toast.system('', { key: 'connected', data });
  }

  function hideTryToConnectMessage() {
    toast.clearWaitingQueue();
  }

  function handleReconnect() {
    if (showReconnectMessageTimeout) {
      clearTimeout(showReconnectMessageTimeout);
      showReconnectMessageTimeout = null;
    }

    const key = 'Messenger IstockLink restored Internet connection';
    dispatch(updateInternetConnection(true));
    dispatch(updateServerConnection(true));
    dispatch(clearNoInternetConnectionPlugChat());

    const data = {
      key,
      linkKey: 'Refresh the page',
      linkClickHandler: reloadPage
    };
    toast.system('', { key: 'connected', data });
  }
}
