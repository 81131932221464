.tableRoot:global(.MuiTable-root) {
  width: auto;
}

.table {
  .th,
  .td {
    background: var(--color-white);
    overflow: hidden;
  }

  &.sticky {
    overflow: scroll;

    .header,
    .footer {
      position: sticky;
      z-index: 1;
      width: fit-content;
    }

    .header {
      top: 0;
    }

    .footer {
      bottom: 0;
    }

    .body {
      position: relative;
      z-index: 0;
    }

    [data-sticky-td] {
      position: sticky;
    }
  }
}
