import { useLayoutEffect } from 'react';
import { isMobileIosDevice } from '@/lib';

export function useAutoLockZoomWhenControlIsFocused() {
  useLayoutEffect(() => {
    if (isMobileIosDevice()) {
      (
        document.head.querySelector('meta[name="viewport"]') as HTMLMetaElement
      ).content = 'width=device-width, initial-scale=1, maximum-scale=1';
    }
  }, []);
}
