import { logUseCase } from '@/utils/logger';
import { monitoringSocket } from '@/storage/monitoring.storage';

const log = logUseCase.extend('internetConnection');

let isClosed = false;

export function socketClosed(handler = () => {}) {
  monitoringSocket.on('disconnect', () => {
    log('onInterruptedConnectionSocket');
    if (!isClosed) {
      handler();
      isClosed = true;
    }
  });
}

export function socketServerClosed(handler = () => {}) {
  monitoringSocket.on('connect_error', () => {
    log('serverOffline');
    if (!isClosed) {
      handler();
      isClosed = true;
    }
  });
}

export function socketConnected(handler = () => {}) {
  monitoringSocket.on('connect', () => {
    log('onConnectionSocket');
    handler();
    isClosed = false;
  });
}
