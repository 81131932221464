import React from 'react';

/* eslint-disable max-len */
const IconStar = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4003 5.89311L8.92671 2.90735L7.45315 5.89311C7.10178 6.60507 6.42257 7.09854 5.63688 7.21271L2.34189 7.6915L4.72616 10.0156C5.2947 10.5698 5.55413 11.3682 5.41992 12.1507L4.85707 15.4324L7.80419 13.883C8.50694 13.5136 9.34648 13.5136 10.0492 13.883L12.9964 15.4324L12.4335 12.1507C12.2993 11.3682 12.5587 10.5698 13.1273 10.0156L15.5115 7.6915L12.2165 7.21271C11.4309 7.09854 10.7516 6.60507 10.4003 5.89311ZM10.0083 2.37356C9.56586 1.47711 8.28756 1.47711 7.84514 2.37356L6.37157 5.35932C6.19589 5.7153 5.85629 5.96204 5.46344 6.01912L2.16845 6.49791C1.17916 6.64166 0.784145 7.8574 1.5 8.55519L3.88428 10.8793C4.16854 11.1564 4.29826 11.5556 4.23115 11.9469L3.6683 15.2285C3.49931 16.2138 4.53348 16.9652 5.41832 16.5L8.36545 14.9506C8.71682 14.7659 9.1366 14.7659 9.48797 14.9506L12.4351 16.5C13.3199 16.9652 14.3541 16.2138 14.1851 15.2285L13.6223 11.9469C13.5552 11.5556 13.6849 11.1564 13.9691 10.8793L16.3534 8.55519C17.0693 7.8574 16.6743 6.64166 15.685 6.49791L12.39 6.01912C11.9971 5.96204 11.6575 5.7153 11.4818 5.35932L10.0083 2.37356Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconStar;
