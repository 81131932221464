import React from 'react';

/* eslint-disable max-len */
function IconExcel() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        d="M9.82558 0.736816H4.04151C3.60321 0.736816 3.18287 0.910928 2.87295 1.22085C2.56303 1.53077 2.38892 1.95111 2.38892 2.38941V15.6101C2.38892 16.0484 2.56303 16.4688 2.87295 16.7787C3.18287 17.0886 3.60321 17.2627 4.04151 17.2627H13.9571C14.3954 17.2627 14.8157 17.0886 15.1256 16.7787C15.4355 16.4688 15.6097 16.0484 15.6097 15.6101V6.52089L9.82558 0.736816Z"
        fill="#23A566"
      />
      <path
        className="cws-icon-main-fill"
        d="M10.1406 5.50222L15.6247 10.3835V6.6107L10.335 4.54932L10.1406 5.50222Z"
        fill="#1C8653"
      />
      <path
        className="cws-icon-main-fill"
        d="M9.82562 0.736816V4.31743C9.82562 5.53437 10.8121 6.52089 12.0291 6.52089H15.6097"
        fill="#8ED1B1"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.1162 9.27547C6.08578 9.27547 6.06112 9.30014 6.06112 9.33056V10.7657H8.72387V9.27547H6.1162ZM9.27473 9.27547V10.7657H11.9375V9.33056C11.9375 9.30014 11.9128 9.27547 11.8824 9.27547H9.27473ZM11.9375 11.3166H9.27473V12.6246H11.9375V11.3166ZM11.9375 13.1754H9.27473V14.4834H11.8824C11.9128 14.4834 11.9375 14.4588 11.9375 14.4283V13.1754ZM8.72387 14.4834V13.1754H6.06112V14.4283C6.06112 14.4588 6.08578 14.4834 6.1162 14.4834H8.72387ZM6.06112 12.6246H8.72387V11.3166H6.06112V12.6246ZM5.51025 9.33056C5.51025 8.9959 5.78155 8.72461 6.1162 8.72461H11.8824C12.2171 8.72461 12.4883 8.9959 12.4883 9.33056V14.4283C12.4883 14.763 12.2171 15.0343 11.8824 15.0343H6.1162C5.78155 15.0343 5.51025 14.763 5.51025 14.4283V9.33056Z"
        fill="white"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconExcel;
