import cn from 'classnames';
import { useEditableTableContext } from '@/components/EditableTable/EditableTable.context';
import { Typography } from '@/components/Typography';
import { makeKey } from '@/components/EditableTable/utils';
import styles from './TableFooter.module.scss';

export function TableFooter() {
  const context = useEditableTableContext();

  if (!context.footer) return null;

  return (
    <tfoot>
      {context.footer.map((row, rowIndex) => (
        // TODO Сгенерировать статичный key вместо rowIndex
        // eslint-disable-next-line react/no-array-index-key
        <tr key={makeKey(rowIndex)}>
          {row.columns.map((column, columnIndex) => (
            // TODO Сгенерировать статичный key вместо columnIndex
            // eslint-disable-next-line react/no-array-index-key
            <td
              key={makeKey(columnIndex)}
              className={cn(column.className, styles.cell)}
              colSpan={column.colSpan}>
              <Typography variant="body1Reg" align="right">
                {column.value}
              </Typography>
            </td>
          ))}
        </tr>
      ))}
    </tfoot>
  );
}
