import React from 'react';

/* eslint-disable max-len */
function IconTrash() {
  return (
    <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.99.916c-.557.119-1.128.58-1.41 1.139-.172.34-.209.555-.209 1.218l-.001.558-1.642.022c-.904.011-1.693.034-1.754.05a.498.498 0 0 0-.327.336c-.047.177-.02.527.051.648a.571.571 0 0 0 .162.163c.096.059.156.066.675.08l.57.015.015 5.16.015 5.16.067.195c.241.696.883 1.286 1.553 1.425.314.066 8.176.066 8.49 0 .67-.139 1.312-.729 1.553-1.425l.067-.195.015-5.16.015-5.16.57-.015c.636-.017.709-.037.832-.236.076-.124.106-.47.056-.655a.498.498 0 0 0-.327-.336c-.061-.016-.85-.039-1.754-.05l-1.642-.022-.001-.558c0-.663-.037-.878-.209-1.218-.285-.566-.855-1.022-1.425-1.14C10.696.852 7.283.854 6.99.916m3.844 1.293c.18.083.375.279.455.457.054.119.065.212.075.656l.013.518H6.623l.013-.518c.014-.591.046-.704.259-.917a.78.78 0 0 1 .435-.244c.069-.014.854-.024 1.745-.021 1.589.005 1.623.006 1.759.069m2.779 7.969-.008 5.017-.064.139a1.051 1.051 0 0 1-.457.457l-.139.064h-7.89l-.139-.064a1.051 1.051 0 0 1-.457-.457l-.064-.139-.008-5.017-.008-5.018h9.242l-.008 5.018"
        fillRule="evenodd"
        fill="#000"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconTrash;
