import { makeRequestMany } from 'core/data/requestMany';
import { makeConfigs, setItem, mergeConfigs } from 'core/data/configs';
import { makeConfigItem } from 'core/data/configItem';
import {
  USER_CONFIGS_SET_ITEM,
  USER_CONFIGS_SET_ITEM_LIST,
  USER_CONFIGS_TO_DEFAULT_STATE
} from '../../actionTypes/userConfigs';
import { MY_ORDERS } from '../../constants';

const defaultState = () =>
  makeConfigs([
    makeConfigItem(MY_ORDERS, 'requestMany', makeRequestMany()),
    makeConfigItem(MY_ORDERS, 'excludedColumns', []),
    makeConfigItem('orders-customers', 'excludedColumns', []),
    makeConfigItem('prices', 'excludedColumns', [])
  ]);

const reducers = {
  [USER_CONFIGS_SET_ITEM]: (configs, configItem) =>
    setItem(configItem, configs),
  [USER_CONFIGS_SET_ITEM_LIST]: (configs, newConfigs) =>
    mergeConfigs(configs, newConfigs),
  [USER_CONFIGS_TO_DEFAULT_STATE]: () => defaultState()
};

export default (state = defaultState(), { type, payload }) => {
  if (reducers[type]) return reducers[type](state, payload);
  return state;
};
