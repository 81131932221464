import React from 'react';

function IconPublish() {
  return (
    <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M8.865 1.377a1.034 1.034 0 0 0-.18.071c-.049.026-1.051 1.011-2.226 2.188-1.494 1.495-2.151 2.173-2.183 2.251a.654.654 0 0 0 .183.738.648.648 0 0 0 .709.077c.053-.027.786-.736 1.628-1.575.842-.84 1.54-1.527 1.552-1.527.012 0 .022 2.147.022 4.771 0 4.238.005 4.783.046 4.882.212.507.956.507 1.168 0 .041-.099.046-.644.046-4.882 0-2.624.01-4.771.022-4.771.012 0 .71.687 1.552 1.527.842.839 1.575 1.548 1.628 1.575a.64.64 0 0 0 .937-.56.782.782 0 0 0-.045-.255c-.056-.136-4.261-4.361-4.424-4.445a.698.698 0 0 0-.435-.065M1.789 3.824c-.167.06-.267.169-.482.527A8.96 8.96 0 0 0 .078 10.08a9 9 0 0 0 6.027 7.43A8.623 8.623 0 0 0 9 17.978a8.615 8.615 0 0 0 2.895-.468 9.018 9.018 0 0 0 4.816-3.89 9.022 9.022 0 0 0 .324-8.649c-.226-.452-.526-.937-.642-1.038-.272-.239-.699-.174-.914.14-.059.086-.076.152-.083.335-.011.253-.023.22.294.767.472.814.786 1.724.951 2.76.066.419.075 1.632.014 2.04-.269 1.791-.98 3.236-2.195 4.457a7.428 7.428 0 0 1-2.967 1.861 7.242 7.242 0 0 1-2.716.408 7.043 7.043 0 0 1-2.162-.371 7.453 7.453 0 0 1-3.075-1.898c-1.215-1.221-1.926-2.666-2.195-4.457-.061-.408-.052-1.621.014-2.04.171-1.074.496-1.994 1.011-2.865.209-.355.222-.386.234-.588.014-.251-.046-.402-.22-.545a.582.582 0 0 0-.595-.113"
        fillRule="evenodd"
        fill="#5B5F73"
      />
    </svg>
  );
}

export default IconPublish;
