import { bindActionCreators } from 'redux';
import * as actions from './actions';
import * as profileActions from '../profile/actions';
import * as profileStorage from '../profile/storage';
import * as profileGetters from '../profile/getters';
import { getDialogInfo } from './getters';
import { applyState } from '../utils';
import { showProfile } from '../profile';
import { updateDialogInfoCase } from './useCases/updateDialogInfo';
import { updateDialogInfoByEmployeeIdCase } from './useCases/updateDialogInfoByEmployeeId';

export const updateDataInProfile = (employeeId) => async (dispatch, getState) =>
  updateDialogInfoCase(
    {
      getDialogInfo: applyState(getState, getDialogInfo),
      showProfile: (propEmployeeId) => dispatch(showProfile(propEmployeeId)),
      setDialogInfo: (info) => dispatch(actions.setDialogInfo(info))
    },
    { employeeId }
  );

export const updateDialogInfoByEmployeeId =
  (employeeId) => async (dispatch, getState) =>
    updateDialogInfoByEmployeeIdCase(
      {
        getContactInfo: applyState(getState, profileGetters.getProfile),
        fetchContactInfo: profileStorage.getContactInfo,
        getDialogInfo: applyState(getState, getDialogInfo),
        setDialogInfo: bindActionCreators(actions.setDialogInfo, dispatch),
        setContactInfo: bindActionCreators(profileActions.setProfile, dispatch)
      },
      { employeeId }
    );
