import React from 'react';
/* eslint-disable max-len */
function IconView() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.92409 9.49994C2.36392 10.0349 3.19646 10.9675 4.28744 11.8264C5.62925 12.8827 7.2724 13.757 9.00001 13.757C10.7276 13.757 12.3708 12.8827 13.7126 11.8264C14.8036 10.9675 15.6361 10.0349 16.0759 9.49994C15.6361 8.96494 14.8036 8.0324 13.7126 7.17353C12.3708 6.1172 10.7276 5.24292 9.00001 5.24292C7.2724 5.24292 5.62925 6.1172 4.28744 7.17353C3.19646 8.0324 2.36392 8.96494 1.92409 9.49994ZM3.3596 5.99493C4.81303 4.85072 6.7826 3.74292 9.00001 3.74292C11.2174 3.74292 13.187 4.85072 14.6404 5.99493C16.1056 7.14836 17.1374 8.41073 17.4941 8.87233C17.7815 9.24427 17.7815 9.75562 17.4941 10.1276C17.1374 10.5892 16.1056 11.8515 14.6404 13.005C13.187 14.1492 11.2174 15.257 9.00001 15.257C6.7826 15.257 4.81303 14.1492 3.3596 13.005C1.89444 11.8515 0.862606 10.5892 0.505944 10.1276C0.218557 9.75562 0.218561 9.24426 0.505945 8.87232C0.862607 8.41073 1.89444 7.14836 3.3596 5.99493Z"
        fill="#5B5F73"
      />
      <path
        d="M11.9869 9.49994C11.9869 11.1495 10.6497 12.4868 9.00007 12.4868C7.35049 12.4868 6.01323 11.1495 6.01323 9.49994C6.01323 7.85036 7.35049 6.5131 9.00007 6.5131C10.6497 6.5131 11.9869 7.85036 11.9869 9.49994Z"
        fill="#5B5F73"
      />
    </svg>
  );
}
/* eslint-enable max-len */
export default IconView;
