import { makeCommand, makeEvent } from '@/core/data/messageBus/message';
import { makeQuery } from '@/core/messages/utils';

const NS = 'VIDEO_ROOM_PEER';

export const addedMemberEVT = makeEvent(NS, 'MEMBER_WAS_ADDED');
export const removedMemberEVT = makeEvent(NS, 'MEMBER_WAS_REMOVED');

export const viewCMD = makeCommand(NS, 'view');
export const videoRoomPeerQRY = makeQuery(NS, 'video-room-peer');
