import React from 'react';

/* eslint-disable max-len */
const IconRemove2 = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      className="cws-icon-hover"
      cx="17"
      cy="17"
      r="17"
      fill="#767B92"
      fillOpacity="0"
    />

    <g clipPath="url(#clip0_22127_19865)">
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 11.2255H12V24.2255C12 24.7777 12.4477 25.2255 13 25.2255H21C21.5523 25.2255 22 24.7777 22 24.2255V11.2255ZM11 10.2255V24.2255C11 25.33 11.8954 26.2255 13 26.2255H21C22.1046 26.2255 23 25.33 23 24.2255V10.2255H11Z"
        fill="#767B92"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 11.2255H10V10.2255H24V11.2255Z"
        fill="#767B92"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 9.22546H14V8.22546H20V9.22546Z"
        fill="#767B92"
      />
    </g>
    <defs>
      <clipPath id="clip0_22127_19865">
        <rect
          className="cws-icon-main-fill"
          width="18"
          height="18"
          fill="white"
          transform="translate(8 8.22546)"
        />
      </clipPath>
    </defs>
  </svg>
);
/* eslint-enable max-len */

export default IconRemove2;
