import React from 'react';

const ImageMinusS = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      className="cws-icon-main-stroke"
      x="0.5"
      y="0.5"
      width="17"
      height="17"
      rx="8.5"
      stroke="white"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.23535 8.73633L13.7648 8.73633V9.26574L4.23535 9.26574L4.23535 8.73633Z"
      fill="white"
      stroke="white"
      strokeWidth="0.5"
    />
  </svg>
);

export default ImageMinusS;
