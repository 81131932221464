import type { Module } from '@/types/module';
import { ModuleName } from '@/constants/module';

interface Configuration {
  modules: Array<Module>;
}

export const config: Configuration = {
  modules: [
    {
      name: ModuleName.ETP,
      url: '/'
    },
    {
      name: ModuleName.Marketplace,
      url: '/marketplace/goods'
    }
  ]
};
