import { findMember, isOwner } from 'core/data/light/channel';
import { isEmpty, isNil } from 'ramda';
import {
  haveActivatedMS,
  haveReaderMR,
  haveMemberStatus
} from 'core/data/light/channelMember';
import { createDeepEqualSelector } from '@/lib';
import {
  isSupport as checkIsSupport,
  isAdmin as checkIsAdmin,
  getSupportIstockEmployeeId
} from '@/lib/goodwix';
import {
  haveSelectedItems,
  getSelectedCount
} from './data-type/block-list-with-select';
import { findQuery } from './data-type/query-list';
import { getChatWidget } from './getterCommon';
import { getDraftMessages } from './modules/draftMessages/getters';
import { isMaxFiles } from './data-type/block-input';

export { getChatContact } from './modules/contacts/getters';
export { getGroupContacts } from './modules/categories/contacts/getters';
export { getGroupChats } from './modules/categories/chats/getters';
export { getProfile } from './modules/profile/getters';
export { getDialogInfo } from './modules/dialogInfo/getters';
export { getChatForward } from './modules/forwardMessages/getters';

export { getChatBlock, getChatArchive } from './modules/chats/getters';
export { getChatWidget };
export const getIsShown = (state) => getChatWidget(state).shown;
export const getWhereChats = (state) => getChatWidget(state).whereChats;
export const getWhereArchivies = (state) => getChatWidget(state).whereArchivies;
export const getOpenedChat = (state) => getChatWidget(state).openedChat;
export const getFavoritesMsgs = (state) =>
  getChatWidget(state).favoriteMessages;
export const getChatInput = (state) => getChatWidget(state).blockInput;
export const getChatTemp = (state) => getChatWidget(state).blockTemp;
export const getChatMsgs = (state) => getChatWidget(state).blockMessages;
export const getBlockChat = (state) => getChatWidget(state).blockChat;
export const getChatUnsentAllUsers = (state) =>
  getChatWidget(state).blockUnsent;
export const getChatSearchMsgs = (state) =>
  getChatWidget(state).blockSearchMsgs;
export const getQueryList = (state) => getChatWidget(state).queryList;
export const getChatMode = (state) => getChatWidget(state).mode;
export const getCurrentChatMode = (state) => getChatMode(state).currentMode;
export const getCurrUser = (state) => state.getIn(['user', 'user']);
export const getCurrEmplId = (state) =>
  state.getIn(['user', 'user', 'employeeId']);
export const getSidebarHeaderView = (state) =>
  getChatWidget(state).sidebarHeaderView;
export const getEditMsg = (state) => getChatWidget(state).blockEdit;
export const getChatFilter = (state) => getChatWidget(state).filter;
export const getUserLang = (state) => state.getIn(['user', 'user', 'language']);
export const getUserCurrCompanyId = (state) =>
  state.getIn(['user', 'user', 'currentCompany']);
export const getActiveTab = (state) => getChatWidget(state).activeTab;
export const getGroupEdit = (state) => getChatWidget(state).blockGroupEdit;
export const getLoadingFilesIds = (state) =>
  getChatInput(state).loadingFilesIds;
export const getChatUnreadMsgsCount = (state) =>
  getChatWidget(state).unreadMsgsCount;
export const getTypingInfo = createDeepEqualSelector(
  [(state) => getChatWidget(state).typingInfo],
  (typingInfo) => typingInfo
);
export const getAdvancedSearch = (state) =>
  getChatWidget(state).blockAdvancedSearch;
export const isAdvancedSearchActive = (state) =>
  Boolean(getAdvancedSearch(state)?.search);
export const getErrors = (state) => getChatWidget(state).errors;
export const getBadges = (state) => getChatWidget(state).blockBadges;
export const getLastUnreadMessages = (state) =>
  getChatWidget(state).blockLastUnreadMessages;
export const getReplyMessages = (state) => getChatWidget(state).blockReply;

export const getChatInputText = (state) => getChatWidget(state).blockInput.text;

export const isEditMessageMode = (state) => !isEmpty(getEditMsg(state));

export const isInputMaxFilesLimitReached = (state) =>
  isMaxFiles(getChatInput(state));

export const getOpenedChatId = (state) => {
  const openedChat = getOpenedChat(state);
  if (isNil(openedChat)) throw new Error('Not opened chat');
  return openedChat.id;
};

export const getOpenedChatType = (state) => {
  const openedChat = getOpenedChat(state);
  return openedChat?.type;
};

export const maybeGetOpenedChatId = (state) => {
  const openedChat = getOpenedChat(state);
  return isNil(openedChat) ? null : openedChat.id;
};

export function getTypingFromOpenedChat(state) {
  const openedChatId = maybeGetOpenedChatId(state);

  if (isNil(openedChatId)) return false;

  const typingInfo = getTypingInfo(state);

  if (isNil(typingInfo)) return false;

  return typingInfo[openedChatId];
}

export const checkIsOpenedChat = (state) => {
  const openedChat = maybeGetOpenedChatId(state);

  return !isNil(openedChat);
};

export const getCurrentMember = (state) => {
  const employeeId = getCurrEmplId(state);
  return findMember(getOpenedChat(state), employeeId);
};

export const checkCurrentMemberIsAdmin = (state) => {
  const employeeId = getCurrEmplId(state);
  return checkIsAdmin(employeeId);
};

export const checkCurrentMemberIsSupport = (state) => {
  const employeeId = getCurrEmplId(state);

  return checkIsSupport(employeeId);
};

export const checkDialogWithIstock = (state) => {
  const chat = getOpenedChat(state);

  if (!chat) return false;

  const member = findMember(chat, getSupportIstockEmployeeId());

  return !isNil(member);
};

export const checkCurrentMemberHaveActivedMS = (state) => {
  const employeeId = getCurrEmplId(state);

  const chat = getOpenedChat(state);

  if (!chat) return false;

  const member = findMember(chat, employeeId);

  if (isNil(member)) return false;
  return haveActivatedMS(member);
};

export const checkCurrentMemberLeaveChat = (state) => {
  const employeeId = getCurrEmplId(state);

  const chat = getOpenedChat(state);

  if (!chat) return false;

  const member = findMember(chat, employeeId);

  if (isNil(member)) return false;
  return haveMemberStatus('LEAVE', member);
};

export const checkCurrentMemberHaveReaderRights = (state) => {
  const employeeId = getCurrEmplId(state);

  const chat = getOpenedChat(state);

  if (!chat) return false;

  const member = findMember(chat, employeeId);

  if (isNil(member)) return false;
  return haveReaderMR(member);
};

export const checkIfCurrentUserOwnerChannel = (state) => {
  const employeeId = getCurrEmplId(state);
  const chat = getOpenedChat(state);

  if (!chat) return false;

  return isOwner(chat, employeeId);
};

export const haveSelectedMessages = (state) =>
  haveSelectedItems(getChatMsgs(state));

export const getSelectedMessagesCount = (state) =>
  getSelectedCount(getChatMsgs(state));

export const getReplyMessageByChat = (state) => {
  const chat = getOpenedChat(state);

  if (!chat) return null;

  const message = getReplyMessages(state).messages.find(
    (msg) => msg.channelId === chat.id
  );

  return message || null;
};

export const getReplyMessageId = (state) => {
  const message = getReplyMessageByChat(state);

  if (!message) return null;

  return message.id;
};

export const getLastMessageRenderMode = (state, chatId) => {
  if (isTyping()) return 'typing';
  if (haveUnreadMessage()) return 'unread-message';
  if (haveDraft()) return 'draft-message';
  return 'last-message';

  function isTyping() {
    const typingInfo = getTypingInfo(state);

    return Boolean(typingInfo[chatId] && typingInfo[chatId].length);
  }

  function haveUnreadMessage() {
    const { badges = [] } = getBadges(state);
    const chatBadges = badges.find((badge) => badge.chatId === chatId);

    return !isNil(chatBadges) && chatBadges.count > 0;
  }

  function haveDraft() {
    const drafts = getDraftMessages(state);

    return Boolean(drafts[chatId]);
  }
};

export const getOnlyExistCurrEmplId = (state) => {
  const currentEmployeeId = state.getIn(['user', 'user', 'employeeId']);

  // assert(!isNil(currentEmployeeId));

  return currentEmployeeId;
};

export const getChatUnsent = (state) => {
  const allChatUnsent = getChatUnsentAllUsers(state);
  const currentEmployeeId = getOnlyExistCurrEmplId(state);

  return allChatUnsent[currentEmployeeId];
};

export const getFilterByName = (name, state) => {
  const chatFilter = getChatFilter(state);
  const filter = chatFilter[name];
  if (filter === null) throw new Error(`Not found filter with name ${name}`);
  return filter;
};

export const getQueryByName = (queryName, state) => {
  const queryList = getQueryList(state);
  const query = findQuery(queryName, queryList);
  if (query === null) throw new Error(`Not found query with name ${queryName}`);
  return query;
};

export const getLastUnreadMessageByChatId = (state, { id }) => {
  const { list: unreadMessagesList } = getLastUnreadMessages(state);

  const lastUnreadMessage = unreadMessagesList.find(
    (item) => item.channelId === id
  );

  return !isNil(lastUnreadMessage) ? lastUnreadMessage.messageId : null;
};
