import React from 'react';
import { ToastConfigurator } from '@link/react-components';
import { SignalIcon, NoSignalIcon, UploadIcon, AnimatedSignal } from './assets';
import { ToastContentContainer } from './ToastContentContainer';
import { SubscribeCompanyPurchaseRequestToast } from './toasts';

const CONNECTION_STATE_TOAST_ID = 'connection-state-toast-id';
const UPLOAD_FILES_TOAST_ID = 'upload-files-toast-id';

const CLOSE_DELAY = 3000;

const CAN_BE_CLOSE_MANUALLY = {
  closeOnClick: true,
  closeButton: true,
  closeOnClickOutside: true
};

const CAN_NOT_CLOSE_MANUALLY = {
  closeOnClick: false,
  closeButton: false,
  closeOnClickOutside: false
};

export const toastConfigurator = ToastConfigurator()
  .options({
    ...CAN_BE_CLOSE_MANUALLY,
    autoClose: CLOSE_DELAY
  })
  .setContent(<ToastContentContainer />)
  .extend('system', {
    autoClose: false,
    ...CAN_NOT_CLOSE_MANUALLY,
    toasts: {
      disconnected: {
        icon: <NoSignalIcon />,
        toastId: CONNECTION_STATE_TOAST_ID
      },
      reconnect: {
        icon: <AnimatedSignal />,
        toastId: CONNECTION_STATE_TOAST_ID
      },
      connected: {
        icon: <SignalIcon />,
        autoClose: false,
        closeOnClickOutside: false,
        closeButton: true,
        closeOnClick: true,
        toastId: CONNECTION_STATE_TOAST_ID
      },
      'upload-files-processing': {
        icon: <UploadIcon />,
        toastId: UPLOAD_FILES_TOAST_ID
      },
      'upload-files-success': {
        ...CAN_BE_CLOSE_MANUALLY,
        type: 'success',
        autoClose: CLOSE_DELAY,
        toastId: UPLOAD_FILES_TOAST_ID
      },
      'upload-files-failed': {
        ...CAN_BE_CLOSE_MANUALLY,
        type: 'error',
        autoClose: CLOSE_DELAY,
        toastId: UPLOAD_FILES_TOAST_ID
      }
    }
  })
  .extend('double', {
    toasts: {
      'subscribe-company-purchase-requests': {
        content: <SubscribeCompanyPurchaseRequestToast />,
        style: { maxWidth: 438 },
        icon: false,
        autoClose: false
      }
    }
  });

export const { toast } = toastConfigurator;
