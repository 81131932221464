import React from 'react';

/* eslint-disable max-len */
const IconCross = ({ width = 10, height = 10 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L9 9M9 1L1 9" stroke="#767B92" strokeLinecap="round" />
  </svg>
);
/* eslint-enable max-len */

export default IconCross;
