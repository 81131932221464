import { ADD_OR_UPDATE_DRAFT_INPUT_MESSAGE, CLEAR_DRAFT } from './constants';

export const addOrUpdateDraftMessage = ({ chatId, text, files }) => ({
  type: ADD_OR_UPDATE_DRAFT_INPUT_MESSAGE,
  payload: { chatId, text, files }
});

export const clearDraft = (chatId) => ({
  type: CLEAR_DRAFT,
  payload: { chatId }
});
