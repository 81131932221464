import { Route, Routes } from 'react-router-dom';
import { usePermissions } from '@/modules/permissions';
import {
  CostAccountingNavigation,
  CostObjects,
  CostsDistribution,
  CostAnalysis,
  CostObjectCreationPage,
  CostObjectEditionPage,
  CostObjectView
} from '@/pages/costAccounting';
import { ProtectedRoute } from '@/components/PrivateRoute';
import { useSelector } from 'react-redux';
import { getPlainCurrentUser } from '@/storeGetters';

export const CostAccountingRouter = () => {
  const permissions = usePermissions();

  const haveCostAccountingDistributionPermissions =
    permissions.haveCostAccountingDistributionPermissions();
  const haveCostAccountingAnalyzePermissions =
    permissions.haveCostAccountingAnalyzePermissions();

  const { isCustomer } = useSelector(getPlainCurrentUser);

  return (
    <ProtectedRoute isAllowed={isCustomer} redirectPath={null}>
      <Routes>
        <Route path="/" element={<CostAccountingNavigation />} />
        <Route path="/cost-objects" element={<CostObjects />} />
        <Route
          path="/cost-objects/create"
          element={
            <ProtectedRoute
              isAllowed={haveCostAccountingDistributionPermissions}
              redirectPath={null}>
              <CostObjectCreationPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cost-objects/:id/edit"
          element={
            <ProtectedRoute
              isAllowed={haveCostAccountingDistributionPermissions}
              redirectPath={null}>
              <CostObjectEditionPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cost-objects/:id"
          element={
            <ProtectedRoute
              isAllowed={haveCostAccountingDistributionPermissions}
              redirectPath={null}>
              <CostObjectView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/costs-distribution"
          element={
            <ProtectedRoute
              isAllowed={haveCostAccountingDistributionPermissions}
              redirectPath={null}>
              <CostsDistribution />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cost-analysis"
          element={
            <ProtectedRoute
              isAllowed={haveCostAccountingAnalyzePermissions}
              redirectPath={null}>
              <CostAnalysis />
            </ProtectedRoute>
          }
        />
      </Routes>
    </ProtectedRoute>
  );
};
