import React from 'react';

/* eslint-disable max-len */
function IconArrowDown() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        d="M2.02783 6.63208L7.7523 12.1681C7.86779 12.2798 8.05078 12.2807 8.16733 12.1701L14.0043 6.63208"
        stroke="#5B5F73"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconArrowDown;
