/* eslint-disable max-len */
import React from 'react';

const Information = () => (
  <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_91)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99998 1.60194C4.91414 1.60194 1.60191 4.91417 1.60191 9.00001C1.60191 13.0858 4.91414 16.3981 8.99998 16.3981C13.0858 16.3981 16.398 13.0858 16.398 9.00001C16.398 4.91417 13.0858 1.60194 8.99998 1.60194ZM0.30191 9.00001C0.30191 4.1962 4.19617 0.301941 8.99998 0.301941C13.8038 0.301941 17.698 4.1962 17.698 9.00001C17.698 13.8038 13.8038 17.6981 8.99998 17.6981C4.19617 17.6981 0.30191 13.8038 0.30191 9.00001Z"
        fill="#5B5F73"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.7178 7.39744C6.7178 7.03845 7.00882 6.74744 7.3678 6.74744H9.11719C9.47618 6.74744 9.76719 7.03845 9.76719 7.39744V12.9677H10.8116C11.1706 12.9677 11.4616 13.2587 11.4616 13.6177C11.4616 13.9767 11.1706 14.2677 10.8116 14.2677H7.3678C7.00882 14.2677 6.7178 13.9767 6.7178 13.6177C6.7178 13.2587 7.00882 12.9677 7.3678 12.9677H8.46719V8.04744H7.3678C7.00882 8.04744 6.7178 7.75642 6.7178 7.39744Z"
        fill="#5B5F73"
      />
      <path
        d="M10.1256 5.12728C10.1256 5.69939 9.66186 6.16319 9.08974 6.16319C8.51762 6.16319 8.05383 5.69939 8.05383 5.12728C8.05383 4.55516 8.51762 4.09137 9.08974 4.09137C9.66186 4.09137 10.1256 4.55516 10.1256 5.12728Z"
        fill="#5B5F73"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_91">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Information;

/* eslint-enable max-len */
