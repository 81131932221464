import React from 'react';

/* eslint-disable max-len */
function IconPerson() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99999 7C10.6568 7 12 5.65685 12 4C12 2.34315 10.6568 1 8.99999 1C7.34313 1 5.99999 2.34315 5.99999 4C5.99999 5.65685 7.34313 7 8.99999 7ZM3.18304 12.1114C2.56225 14.5945 4.44037 17 6.99998 17H11C13.5596 17 15.4377 14.5945 14.8169 12.1114C14.6536 11.4582 14.0668 11 13.3935 11H4.60644C3.93319 11 3.34633 11.4582 3.18304 12.1114ZM8.99999 8C11.2091 8 13 6.20914 13 4C13 1.79086 11.2091 0 8.99999 0C6.79085 0 4.99999 1.79086 4.99999 4C4.99999 6.20914 6.79085 8 8.99999 8ZM4.60644 10C3.47432 10 2.48748 10.7705 2.2129 11.8688C1.43432 14.9832 3.7898 18 6.99998 18H11C14.2102 18 16.5657 14.9832 15.7871 11.8688C15.5125 10.7705 14.5257 10 13.3935 10H4.60644Z"
        fill="#5B5F73"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconPerson;
