import { Map, fromJS } from 'immutable'; // eslint-disable-line

const initState = () =>
  Map({
    // eslint-disable-line
    baidgesCount: new Map()
  });

const setBaidgesCount = (data, { baidgesCount }) =>
  data.remove('baidgesCount').set('baidgesCount', fromJS(baidgesCount));

// const deleteBadgesCount = (data, { deletedBadges }) => {
//   if (deletedBadges.length) {
//     return data.update('baidgesCount', baidgesCount =>
//       baidgesCount.set('prices', baidgesCount.get('prices') - deletedBadges.length)
//     );
//   }
//   return data;
// };

const decreaseBadgesCount = (data, { type }) =>
  data.update('baidgesCount', (baidgesCount) =>
    baidgesCount.set(type, baidgesCount.get(type) - 1)
  );

const baidges = (state = initState(), { type, payload }) => {
  switch (type) {
    case 'COMPANY:GET_BADGES_COUNT:SUCCESS':
      return setBaidgesCount(state, payload);
    case 'COMPANY:GET_INFO:CLEAR':
      return initState();
    // TODO: Badges
    // case 'SUPPLIERS:DELETE:SUCCESS':
    //   return deleteBadgesCount(state, payload);
    case 'CUSTOMERS:CONFIRM_ADD:SUCCESS':
    case 'CUSTOMERS:REJECT_ADD:SUCCESS':
    case 'SUPPLIERS:REJECT_ADD:SUCCESS':
    case 'SUPPLIERS:CONFIRM_ADD:SUCCESS':
      return decreaseBadgesCount(state, payload);
    default:
      return state;
  }
};

export default baidges;
