import React from 'react';

/* eslint-disable max-len */
function IconAlert() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="8.99997"
        cy="9.00021"
        r="8.04807"
        stroke="#5B5F73"
        strokeWidth="1.3"
      />
      <path
        d="M9.0042 11.3798C8.7535 11.3798 8.53983 11.1979 8.49979 10.9504L7.65407 5.72359C7.52291 4.913 8.14877 4.17773 8.9699 4.17773C9.78413 4.17773 10.4081 4.90135 10.2884 5.70673L9.50961 10.944C9.47237 11.1944 9.25737 11.3798 9.0042 11.3798Z"
        fill="#5B5F73"
      />
      <circle cx="8.99997" cy="12.9598" r="1.01692" fill="#5B5F73" />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconAlert;
