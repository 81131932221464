import React from 'react';

export function VideoOff() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width="18"
      height="19"
      viewBox="0 0 18 19">
      <path
        stroke="#5B5F73"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M3.4 4.023a2.4 2.4 0 0 0-2.4 2.4v6.4a2.4 2.4 0 0 0 2.4 2.4h7.2a2.4 2.4 0 0 0 2.296-1.7m.104-3.9 2.908-2.907c.342-.343.514-.514.66-.526a.4.4 0 0 1 .336.139c.096.112.096.354.096.84v4.909c0 .485 0 .727-.096.84a.4.4 0 0 1-.335.138c-.147-.011-.319-.183-.661-.525L13 9.623Zm0 0v-1.76c0-1.344 0-2.016-.262-2.53a2.4 2.4 0 0 0-1.048-1.048c-.514-.262-1.186-.262-2.53-.262H7m-6-2.4 16 16"
      />
    </svg>
  );
}
