import { makeQuery } from 'core/messages/utils';
import { makeEvent, makeCommand } from 'core/data/messageBus/message';

const NS = 'purchaseRequest';

export const unpublishedEVT = makeEvent(NS, 'unpublished');
export const createCMD = makeCommand(NS, 'create');
export const createdEVT = makeEvent(NS, 'created');

export const viewCMD = makeEvent(NS, 'view');

export const purchaseRequestQRY = makeQuery('purchase-request');

export const roomName = ({ employeeId }) =>
  `purchase-request-view.${employeeId}`;
