import React from 'react';

/* eslint-disable max-len */
const IconView = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 9C1 9 3 15 9 15C15 15 17 9 17 9C17 9 15 3 9 3C3 3 1 9 1 9ZM2.07337 9C2.12933 8.86851 2.20461 8.70186 2.30068 8.50971C2.55677 7.99753 2.95436 7.31681 3.51822 6.64018C4.63977 5.29433 6.3811 4 9 4C11.6189 4 13.3602 5.29433 14.4818 6.64018C15.0456 7.31681 15.4432 7.99753 15.6993 8.50971C15.7954 8.70186 15.8707 8.86851 15.9266 9C15.8707 9.1315 15.7954 9.29814 15.6993 9.49029C15.4432 10.0025 15.0456 10.6832 14.4818 11.3598C13.3602 12.7057 11.6189 14 9 14C6.3811 14 4.63977 12.7057 3.51822 11.3598C2.95436 10.6832 2.55677 10.0025 2.30068 9.49029C2.20461 9.29814 2.12933 9.1315 2.07337 9Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 11C10.1046 11 11 10.1046 11 9C11 7.89543 10.1046 7 9 7C7.89543 7 7 7.89543 7 9C7 10.1046 7.89543 11 9 11ZM9 12C10.6569 12 12 10.6569 12 9C12 7.34315 10.6569 6 9 6C7.34315 6 6 7.34315 6 9C6 10.6569 7.34315 12 9 12Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconView;
