import { memo, useId } from 'react';
import { InputLabel } from '../InputLabel';
import { Typography } from '../Typography';
import { Textarea } from './textarea';
import styles from './textarea.module.scss';

export interface TextareaCounterProps {
  value: string;
  onChange(value: string): void;
  label: string;
  total: number;
  required?: boolean;
  placeholder?: string;
  rows?: number;
  maxRows?: number;
  valid?: boolean;
  disabled?: boolean;
}

export const TextareaCounter = memo((props: TextareaCounterProps) => {
  const {
    value,
    label,
    placeholder,
    onChange,
    total,
    rows,
    maxRows,
    required = false,
    valid = true,
    disabled
  } = props;

  const id = useId();

  const handleChange = (textareaValue: string) => {
    onChange(textareaValue.substring(0, total));
  };

  return (
    <div className={styles.textarea}>
      <InputLabel
        htmlFor={id}
        className={styles.label}
        text={label}
        required={required}
        endAdornment={
          <Typography className={styles.counter} variant="body2Reg">
            {`${value.length}/${total}`}
          </Typography>
        }
      />
      <Textarea
        value={value}
        valid={valid}
        placeholder={placeholder}
        onChange={handleChange}
        id={id}
        rows={rows}
        maxRows={maxRows}
        disabled={disabled}
      />
    </div>
  );
});

TextareaCounter.displayName = 'TextareaCounter';
