import {
  ALLOW_TYPE_MSG_FOR_EDIT,
  DEFAULT_TIME_MSG_EDIT
} from '../../../../../constants';

export const editMessageCase = (
  { getChatMsgs, getCurrentEmployeeId, editMessage },
  { chatId }
) => {
  const blockMessages = getChatMsgs();
  const employeeId = getCurrentEmployeeId();

  const lastMessage = blockMessages.list
    .filter(
      (message) =>
        ALLOW_TYPE_MSG_FOR_EDIT.includes(message.type) &&
        checkEditingAllow(new Date(message.time))
    )
    .reverse()
    .find((message) => message.employeeId === employeeId);

  if (lastMessage) {
    editMessage(chatId, lastMessage);
  }
};

function checkEditingAllow(msgDate, currentDate = new Date()) {
  return (
    currentDate <
    new Date(msgDate.setMinutes(msgDate.getMinutes() + DEFAULT_TIME_MSG_EDIT))
  );
}
