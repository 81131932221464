import React from 'react';

/* eslint-disable max-len */
const IconViber = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="cws-icon-main-fill"
      d="M15.3773 2.76935C14.0429 1.46115 11.8856 0.827284 8.96422 0.884402C6.0185 0.942337 4.04944 1.45937 2.7675 2.51158C1.52802 3.52883 0.950745 5.01738 0.950745 7.1961V9.41966C0.950745 11.9917 2.329 14.8194 4.96317 15.2473C5.26728 15.297 5.55376 15.0903 5.60314 14.7861C5.65252 14.4821 5.44606 14.1955 5.14196 14.1461C3.14426 13.8216 2.06632 11.4688 2.06632 9.41966V7.1961C2.06632 4.07204 3.22392 2.11302 8.98608 1.99968C11.5984 1.94792 13.4839 2.47529 14.5962 3.56587C15.463 4.41587 15.8843 5.60328 15.8843 7.1961V9.41966C15.8843 12.7868 14.373 14.2719 10.4968 14.7092H7.74817C7.60024 14.7092 7.45834 14.768 7.35377 14.8726L5.41706 16.8093C5.19923 17.0271 5.19923 17.3803 5.41706 17.5982C5.52594 17.707 5.66873 17.7616 5.81145 17.7616C5.95417 17.7616 6.09697 17.7071 6.20585 17.5982L7.97924 15.8248H10.528C10.5485 15.8248 10.5691 15.8237 10.5895 15.8214C12.8079 15.575 14.327 14.9976 15.3701 14.0043C16.4668 12.9598 17 11.4602 17 9.41959V7.1961C17 5.31457 16.454 3.82521 15.3773 2.76935Z"
      fill="#7D3DAF"
    />
    <path
      className="cws-icon-main-fill"
      d="M9.32351 4.52497C9.32336 4.52497 9.32358 4.52497 9.32351 4.52497C10.2223 4.52497 11.0675 4.87504 11.7031 5.51062C12.3388 6.14627 12.6888 6.99143 12.6888 7.89036C12.6888 8.19841 12.9384 8.44815 13.2465 8.44815C13.5545 8.44815 13.8043 8.19849 13.8043 7.89044C13.8044 6.6935 13.3383 5.56818 12.492 4.72183C11.6457 3.87548 10.5206 3.40947 9.32373 3.40947C9.32381 3.40947 9.32366 3.40947 9.32373 3.40947C9.01569 3.40947 8.76565 3.65921 8.76565 3.96726C8.76572 4.27523 9.01546 4.52497 9.32351 4.52497Z"
      fill="#7D3DAF"
    />
    <path
      className="cws-icon-main-fill"
      d="M9.32336 6.48617C10.0974 6.48632 10.7273 7.1161 10.7274 7.89009C10.7274 8.19813 10.9771 8.44787 11.2852 8.4478C11.5933 8.4478 11.843 8.19798 11.8429 7.88994C11.8427 6.50097 10.7125 5.37082 9.32351 5.3706C9.01546 5.3706 8.76572 5.62026 8.76565 5.92831C8.76565 6.23636 9.01531 6.48617 9.32336 6.48617Z"
      fill="#7D3DAF"
    />
    <path
      className="cws-icon-main-fill"
      d="M6.82222 7.44165C6.56794 7.61554 6.50272 7.96263 6.67653 8.2169C6.85033 8.47118 7.1975 8.53633 7.45178 8.36252C7.65176 8.22583 8.12015 7.90558 8.08728 7.26539C8.06646 6.88223 7.72085 6.33515 7.4346 5.94366C7.01588 5.37085 6.65503 5.02584 6.36431 4.91889C6.09286 4.81782 5.80898 4.81567 5.51997 4.91265C4.92136 5.11434 4.48673 5.47884 4.26317 5.96672C4.04548 6.44165 4.05031 6.98747 4.27722 7.54548C4.81314 8.86022 5.56341 10.0113 6.51216 10.9716C7.46747 11.9153 8.61844 12.6655 9.93371 13.2017C10.2183 13.3175 10.4999 13.3755 10.7705 13.3755C11.0301 13.3755 11.2796 13.3222 11.5121 13.2156C12 12.992 12.3644 12.5575 12.5663 11.9581C12.663 11.6698 12.6608 11.386 12.5606 11.1164C12.4529 10.8236 12.1078 10.4629 11.5351 10.0441C11.1435 9.75789 10.5964 9.41228 10.2118 9.39138C9.57293 9.35925 9.25298 9.82683 9.11629 10.0268C8.94241 10.2811 9.00748 10.6282 9.26176 10.8021C9.51611 10.9761 9.86313 10.9109 10.0371 10.6567C10.0978 10.5679 10.1324 10.5276 10.1523 10.51C10.4654 10.6103 11.3582 11.2663 11.5189 11.5177C11.5229 11.5337 11.5238 11.5582 11.5089 11.6027C11.4385 11.8117 11.3013 12.0851 11.0473 12.2016C10.8094 12.3105 10.5391 12.2437 10.3545 12.1686C9.17593 11.6881 8.147 11.0184 7.30095 10.1827C6.46048 9.33196 5.79076 8.30303 5.31054 7.12491C5.23528 6.93987 5.16842 6.66938 5.27737 6.43169C5.39376 6.17771 5.6673 6.04042 5.87554 5.97021C5.92002 5.95534 5.94449 5.95616 5.96144 5.96025C6.21289 6.12126 6.86848 7.01357 6.96888 7.32675C6.95118 7.34631 6.91095 7.38104 6.82222 7.44165Z"
      fill="#7D3DAF"
    />
  </svg>
);

/* eslint-enable max-len */
export default IconViber;
