import React from 'react';

/* eslint-disable max-len */
const IconCross1 = () => (
  <svg
    width="11"
    height="10"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.733398 9.09091L9.82431 1.22609e-06L10.7334 0.909092L1.64249 10L0.733398 9.09091Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.64249 0L10.7334 9.09091L9.82431 10L0.733398 0.909091L1.64249 0Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconCross1;
