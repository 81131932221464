import React from 'react';
import { audioAnalyserEventDispatcher } from '../lib/audioAnalyser';
import { isFunction } from '../../../utils/utils';

const DELAY = 1500;
export function useDetectedSpeech(stream, node) {
  const timer = React.useRef(null);
  const [isSpeaking, setIsSpeaking] = React.useState(false);

  const onVolume = React.useCallback(({ detail: { volume } }) => {
    if (volume > 6) {
      setIsSpeaking(true);
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => setIsSpeaking(false), DELAY);
    }
  }, []);

  React.useEffect(() => {
    const unsubscribe = audioAnalyserEventDispatcher(stream, {
      node,
      onVolume
    });

    return () => {
      if (isFunction(unsubscribe)) {
        unsubscribe();
      }
    };
  }, []);

  return isSpeaking;
}
