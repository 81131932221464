.cellWithEndAdornment {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--indent-xs);
}

.cellWithStartAdornment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--indent-xs);
}
