import { makeBlockGroup } from '../../../data-type/block-group';

export const requestContactQueryDataCase = async ({
  getGroups,
  setGroupContacts
}) => {
  const groups = await getGroups();

  if (groups) {
    const blockGroupContact = makeBlockGroup({ selectIds: groups.checked });
    setGroupContacts(blockGroupContact);
  }
};
