import { isEmpty } from 'ramda';

export const sendWaitingMessagesCase = ({
  getWaitingMessages,
  removeWaitingMessage,
  sendMessage
}) => {
  const waitingMessages = getWaitingMessages();

  waitingMessages.forEach((message) => {
    if (!isNeedToSend(message)) return;

    send(message);

    removeWaitingMessage(message.id);
  });

  function send(message) {
    sendMessage({
      chatId: message.chatId,
      msg: message.msg,
      html: message.html,
      mentions: message.mentions,
      files: message.files,
      forwardedMessages: message.forwardedMessages,
      replyByMessageId: message.replyByMessageId,
      deliveryId: message.deliveryId
    });
  }
};

function isNeedToSend(message) {
  return isEmpty(message.waitingFiles);
}
