import request from '../../../../lib/request';
import { initRequest } from '../../data-type/request';
import { getFilter, getQuery } from '../query';
import { getGroupContacts } from '../categories/contacts/getters';

const getContacts = ({
  search = '',
  limit = 20,
  page = 0,
  filterBy = [],
  mode = 'all'
} = {}) =>
  request.post('/api/contacts', {
    search,
    contactLists: filterBy,
    limit,
    page,
    mode
  });

export const reqGetContacts = initRequest(
  (state) => ({
    query: getQuery('contacts', state),
    blockGroup: getGroupContacts(state),
    filter: getFilter('contact', state)
  }),
  (params) => ({
    search: params.query.search,
    limit: params.query.limit,
    page: params.query.page,
    filterBy: params.blockGroup.selectIds,
    mode: params.filter.value
  }),
  getContacts
);

export const reqHideContactGeneral = (employeeId) =>
  request.post('/api/contacts/hide/general', { employeeId });

export const reqUnhideContactGeneral = (employeeId) =>
  request.post('/api/contacts/unhide/general', { employeeId });

export const reqHideContact = (employeeId) =>
  request.post('/api/contacts/hide', { employeeId });

export const reqUnhideContact = (employeeId) =>
  request.post('/api/contacts/unhide', { employeeId });
