/**
 * @typedef {string} MODERATION
 * @typedef {string} ACTIVATED
 * @typedef {string} EXCLUDED
 * @typedef {string} LEAVE
 * @typedef {string} NOT_PARTNER
 * @typedef {string} DELETED
 * @typedef {(ACTIVATED|EXCLUDED|LEAVE|NOT_PARTNER|DELETED|MODERATION)} MemberStatus
 */

export const MODERATION = 'MODERATION';

export const ACTIVATED = 'ACTIVATED';

export const EXCLUDED = 'EXCLUDED';

export const LEAVE = 'LEAVE';

export const NOT_PARTNER = 'NOT_PARTNER';

export const DELETED = 'DELETED';
