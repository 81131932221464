import React from 'react';

function IconSelect() {
  return (
    <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M8.4.019C5.758.216 3.402 1.493 1.809 3.592.899 4.792.332 6.162.082 7.77c-.092.588-.092 1.872 0 2.46.385 2.469 1.578 4.495 3.513 5.963 1.194.906 2.572 1.475 4.175 1.725.588.092 1.872.092 2.46 0 2.188-.341 4.047-1.327 5.442-2.886 1.237-1.382 1.951-2.91 2.246-4.802.092-.588.092-1.872 0-2.46-.25-1.603-.819-2.981-1.725-4.175C14.704 1.633 12.577.399 10.125.074A14.136 14.136 0 0 0 8.4.019m1.404 1.572a7.456 7.456 0 0 1 5.699 3.764c.48.85.801 1.859.906 2.841.04.375.04 1.233 0 1.608a7.463 7.463 0 0 1-5.538 6.412A6.836 6.836 0 0 1 9 16.447c-.71 0-1.221-.063-1.871-.231a7.458 7.458 0 0 1-5.538-6.412c-.04-.375-.04-1.233 0-1.608a7.452 7.452 0 0 1 6.479-6.59 11.029 11.029 0 0 1 1.734-.015m2.477 4.337c-.092.045-1.025.974-3.311 3.297-.649.66-1.196 1.2-1.214 1.2-.019 0-.437-.414-.929-.921-.565-.582-.928-.932-.985-.952a.919.919 0 0 0-.549.025.837.837 0 0 0-.405.386c-.087.182-.096.483-.018.641.084.171 2.202 2.336 2.379 2.431.294.159.629.175.907.046.101-.047.705-.65 2.55-2.547 1.49-1.531 2.439-2.529 2.471-2.597.06-.131.07-.429.018-.58-.061-.179-.197-.348-.334-.414-.157-.076-.441-.084-.58-.015"
        fill="#5B5F73"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default IconSelect;
