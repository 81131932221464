export const CHANNEL_SET_INFO = 'CHANNEL:SET_INFO';
export const CHANNEL_SET_FILES = 'CHANNEL:SET_FILES';
export const CHANNEL_SET_MESSAGES = 'CHANNEL:SET_MESSAGES';
export const CHANNEL_ADD_MESSAGE = 'CHANNEL:ADD_MESSAGE';
export const CHANNEL_REMOVE_MESSAGE = 'CHANNEL:REMOVE_MESSAGE';
export const CHANNEL_CLEAR = 'CHANNEL:CLEAR';
export const CHANNEL_ADD_UNREAD_MESSAGE = 'CHANNEL:ADD_UNREAD_MESSAGE';
export const CHANNEL_CLEAR_UNREAD_MESSAGE = 'CHANNEL:CLEAR_UNREAD_MESSAGE';
export const CHANNEL_SET_MODE = 'CHANNEL:SET_MODE';
export const CHANNEL_SET_SEARCH = 'CHANNEL:SET_SEARCH';
export const CHANNEL_CLEAR_SEARCH = 'CHANNEL:CLEAR_SEARCH';
export const CHANNEL_UPDATE_MESSAGE = 'CHANNEL:UPDATE_MESSAGE';
export const CHANNEL_SET_BOOKMARKED_IDS = 'CHANNEL:SET_BOOKMARKED_IDS';
export const CHANNEL_CLEAR_BOOKMARKED_IDS = 'CHANNEL:CLEAR_BOOKMARKED_IDS';
