export const makeBlockCompanies = ({
  list = [],
  limit = 20,
  offset = 0,
  restCount = 0,
  search = '',
  openedCompanyProfile = null
} = {}) => ({
  type: 'BlockCompanies',
  list,
  limit,
  offset,
  restCount,
  search,
  openedCompanyProfile
});

export const makeCompanyProfile = ({
  id,
  email,
  name,
  description,
  country,
  region,
  city,
  industry,
  isPartner = false,
  logo,
  requisites = [],
  waitingRequests = {}
} = {}) => ({
  type: 'CompanyProfile',
  id,
  email,
  name,
  description,
  country,
  region,
  city,
  industry,
  isPartner,
  logo,
  requisites,
  waitingRequests
});
