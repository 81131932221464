import io from 'socket.io-client';

export const chatSocket = io('/chat');

export const getDialogs = (user) =>
  new Promise((resolve) => {
    chatSocket.on('set-dialog-list', (dialogs) => {
      resolve(dialogs);
      chatSocket.removeListener('set-dialog-list');
    });
    const userIdCompanyId = `${user.currentCompany}.${user.id}`;
    chatSocket.emit(
      'get-dialog-list',
      user.currentCompany,
      userIdCompanyId,
      user.id
    );
  });
