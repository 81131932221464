import { assoc, uniq } from 'ramda';

/**
 * @typedef Query
 * @property {number} limit
 * @property {number} page
 * @property {string} search
 */

const DEFAULT_PAGE = 0;
const DEFAULT_LIMIT = 99999;
const DEFAULT_LOAD_COUNT = 0;
const DEFAULT_SEARCH = '';
const DEFAULT_FILTER_BY = [];
const DEFAULT_ORDER_BY = '';
const DEFAULT_DIRECTION = '';

/**
 * Creates a query data type
 *
 * @param {object} params
 * @param {number} params.limit
 * @param {number} params.page
 * @param {string} params.search
 * @param params.loadCount
 * @param params.filterBy
 * @param params.orderBy
 * @param params.direction
 * @returns {Query}
 */
const makeQuery = ({
  limit = DEFAULT_LIMIT,
  page = DEFAULT_PAGE,
  search = DEFAULT_SEARCH,
  loadCount = DEFAULT_LOAD_COUNT,
  filterBy = DEFAULT_FILTER_BY,
  orderBy = DEFAULT_ORDER_BY,
  direction = DEFAULT_DIRECTION
} = {}) => ({
  type: 'Query',
  limit,
  page,
  search,
  loadCount,
  filterBy,
  orderBy,
  direction
});

/**
 * Increases a page number by 1
 *
 * @param {Query} query
 * @returns {Query}
 */
const nextPage = (query) => assoc('page', query.page + 1, query);

/**
 * Reset a page number by DEFAULT_PAGE
 *
 * @param {Query} query
 * @returns {Query}
 */
const resetPage = (query) => assoc('page', DEFAULT_PAGE, query);

/**
 * Increases a load counter by 1
 *
 * @param {Query} query
 * @returns {Query}
 */
const increateLoadCounter = (query) =>
  assoc('loadCount', query.loadCount + 1, query);

/**
 * Set filter by
 *
 * @param {Query} query
 * @param {[*]} list
 * @returns {Query}
 */
const setFilterBy = (query, list) => assoc('filterBy', list, query);

/**
 * Add to filter by
 *
 * @param {Query} query
 * @param {*} item
 * @returns {Query}
 */
const addToFilter = (query, item) => {
  const updatedFilterBy = uniq([...query.filterBy, item]);
  return setFilterBy(query, updatedFilterBy);
};

/**
 * Remove from filter by
 *
 * @param {Query} query
 * @param {*} item
 * @returns {Query}
 */
const removeFromFilter = (query, item) => {
  const updatedFilterBy = query.filterBy.filter(
    (filterItem) => filterItem !== item
  );
  return setFilterBy(query, updatedFilterBy);
};

/**
 * Set DEFAULT_FILTER_BY to filter by
 *
 * @param {Query} query
 * @returns {Query}
 */
const clearFilterBy = (query) => setFilterBy(query, DEFAULT_FILTER_BY);

/**
 * Set order by
 *
 * @param {Query} query
 * @param {string} order
 * @returns {Query}
 */
const setOrderBy = (query, order) => assoc('orderBy', order, query);

/**
 * Set DEFAULT_ORDER_BY to orderBy
 *
 * @param {Query} query
 * @returns {Query}
 */
const clearOrderBy = (query) => setOrderBy(query, DEFAULT_ORDER_BY);

/**
 * Set direction
 *
 * @param {Query} query
 * @param {string} direction
 * @returns {Query}
 */
const setDirection = (query, direction) => assoc('direction', direction, query);

/**
 * Set DEFAULT_DIRECTION to direction
 *
 * @param {Query} query
 * @returns {Query}
 */
const clearDirection = (query) => setDirection(query, DEFAULT_DIRECTION);

/**
 * Set orderBy and direction
 *
 * @param {Query} query
 * @param {string} orderBy
 * @param {string} direction
 * @returns {Query}
 */
const setOrderByAndDirection = (query, orderBy, direction) => {
  let updatedQuery = setOrderBy(query, orderBy);
  updatedQuery = setDirection(updatedQuery, direction);
  return updatedQuery;
};

/**
 * Set DEFAULT_ORDER_BY to orderBy and DEFAULT_DIRECTION to direction
 *
 * @param {Query} query
 * @returns {Query}
 */
const clearOrderByAndDirection = (query) => {
  let updatedQuery = clearOrderBy(query);
  updatedQuery = clearDirection(updatedQuery);
  return updatedQuery;
};

/**
 * Determines if item includes in filter by
 *
 * @param {Query} query
 * @param {*} item
 * @returns {boolean}
 */
const includes = (query, item) => query.filterBy.includes(item);

/**
 * Determines if all item includes in filter by
 *
 * @param {Query} query
 * @param {[*]} items
 * @returns {boolean}
 */
const isSelectedAll = (query, items) =>
  items.every((item) => includes(query, item));

export {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  makeQuery,
  nextPage,
  resetPage,
  increateLoadCounter,
  setFilterBy,
  addToFilter,
  removeFromFilter,
  clearFilterBy,
  includes,
  isSelectedAll,
  setOrderBy,
  clearOrderBy,
  setDirection,
  clearDirection,
  setOrderByAndDirection,
  clearOrderByAndDirection
};
