import { getOpenedChat } from '@/components/ChatWidget/getter';
import { showChatWidget } from '@/components/ChatWidget/useCases/showChatWidget';
import { showChat } from '@/components/ChatWidget/useCases/showChat';

export function openChannelById(channelId) {
  return async (dispatch, getState, service) => {
    const { getFindChannel } = service.chat;
    const [findChannel, cancelFetch] = getFindChannel(channelId);

    findChannel().then((chat) => {
      if (!isChatWithSameIdAlreadyOpened()) {
        dispatch(showChatWidget());
        dispatch(showChat(chat));
      }
    });

    return [cancelFetch];

    function isChatWithSameIdAlreadyOpened() {
      const openedChat = getOpenedChat(getState());
      return openedChat && openedChat.id === channelId;
    }
  };
}
