.container {
  width: 100%;
  position: relative;
}

.input {
  width: 100%;
  outline: none;
  resize: none;
  border: none;
  padding: var(--indent-xxs) var(--indent-xs);
  font-family: var(--font);
  font-size: var(--main-text-size);
  font-weight: var(--text-regular);
  line-height: var(--l1-line-height);
  color: var(--main-dark-gray);
  border-radius: 4px;
}

.input::placeholder {
  color: var(--color-gray-600);
}

.input:hover {
  background-color: var(--hover-color);
}

.inputContainer {
  display: flex;
  align-items: center;
  border: 1px solid transparent;
}

.controls {
  display: flex;
  column-gap: var(--indent-xxs);
  padding: var(--indent-xxs);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-300);
  box-shadow: 0 0 16px rgb(66 61 99 / 13%);
  border-radius: 2px;
}

.popper {
  display: flex;

  &:global(.MuiTooltip-popper[data-popper-placement*='bottom'] .MuiTooltip-tooltip),
  &:global(.MuiTooltip-popper[data-popper-placement*='top'] .MuiTooltip-tooltip) {
    padding: 0;
  }

  &:global(.MuiTooltip-popper[data-popper-placement*='bottom'] .MuiTooltip-tooltip) {
    margin: var(--indent-xxs) 0 0;
  }

  &:global(.MuiTooltip-popper[data-popper-placement*='top'] .MuiTooltip-tooltip) {
    margin: 0 0 var(--indent-xxs) 0;
  }
}

.focused ~ :not(.error) .inputContainer {
  background-color: var(--color-white);
  border: 1px solid var(--color-blue-400);
  border-radius: 4px;
}

.focused .controls {
  opacity: 1;
  visibility: visible;
}

.error,
.focused ~ .error .inputContainer {
  border: 1px solid var(--color-red-error);
  border-radius: var(--indent-xxs);
}
