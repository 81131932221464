import React from 'react';
import { isNil } from 'ramda';
import cn from 'classnames';
import styles from './DoubleToast.module.css';

export const DoubleToast = ({ first, second }) => (
  <div>
    <div
      className={cn({
        [styles.section]: !isNil(second)
      })}>
      {first}
    </div>
    {second && <div className={styles.section}>{second}</div>}
  </div>
);
