import io from 'socket.io-client';
import request from '../../lib/request';

export const socket = io('/contact');

export const toggleBlock = (employeeId) =>
  socket.emit('toggle-block:contact', { employeeId });

export const subToBlockedContact = (cb) =>
  socket.on('toggle-blocked:contact', cb);
export const subToBlockedMe = (cb) => socket.on('toggle-blocked:me', cb);

export const scktHideContact = (employeeId) =>
  socket.emit('hide:contact', { employeeId });
export const subToHideContact = (cb) => socket.on('hide:contact', cb);
export const unsubToHideContact = () => socket.removeListener('hide:contact');

export const subToUpdateContactByInvite = (cb) =>
  socket.on('updateContactListByInvite:contact', cb);
export const unsubToUpdateContactByInvite = () =>
  socket.removeListener('updateContactListByInvite:contact');

export const unsubToBlockedContact = () =>
  socket.removeListener('toggle-blocked:contact');
export const unsubToBlockedMe = () =>
  socket.removeListener('toggle-blocked:me');

export const addContactAsPartner = async (contactId) =>
  request.get(`/api/contacts/${contactId}/add`);
