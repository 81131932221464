import React from 'react';

/* eslint-disable max-len */
function IconFilter() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6793 1.70287C17.8923 2.16199 17.8195 2.70299 17.4926 3.08945L11.8 9.821V15.75C11.8 16.2006 11.5667 16.619 11.1834 16.8559C10.8002 17.0927 10.3216 17.1143 9.91862 16.9128L6.91862 15.4128C6.4782 15.1926 6.2 14.7424 6.2 14.25V9.821L0.507358 3.08945C0.180541 2.70299 0.10773 2.16199 0.320746 1.70287C0.533762 1.24376 0.993875 0.950012 1.5 0.950012H16.5C17.0061 0.950012 17.4662 1.24376 17.6793 1.70287ZM7.5 9.34501V14.25L10.5 15.75V9.34501L16.5 2.25001H1.5L7.5 9.34501Z"
        fill="#5B5F73"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconFilter;
