import { assoc } from 'ramda';
import { getItem } from '../../../data-type/block-list';

export const addUnsentMessageCase = (
  {
    getOpenedChatId,
    getCurrentEmployeeId,
    getChatList,
    hasInternetConnection,
    addUnsentMessage,
    updateChat,
    writeUnsentChatMessagesToLS
  },
  { message }
) => {
  const chatId = getOpenedChatId();
  const employeeId = getCurrentEmployeeId();
  addUnsentMessage({ chatId, employeeId, message });
  updateChatListWithoutConnection(message);
  writeUnsentChatMessagesToLS();

  function updateChatListWithoutConnection() {
    if (!hasInternetConnection()) {
      const chatList = getChatList();
      const chat = getItem(chatList, chatId);
      const updatedChat = assoc('lastMessage', message, chat);
      updateChat(updatedChat);
    }
  }
};
