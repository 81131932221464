import React from 'react';

/* eslint-disable max-len */
function IconContactAdd() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.46744 11.3653C3.16969 10.663 4.12215 10.2685 5.11528 10.2685H10.7523C11.7455 10.2685 12.6979 10.663 13.4002 11.3653C14.1024 12.0675 14.497 13.02 14.497 14.0131V15.6237C14.497 15.9128 14.2626 16.1471 13.9735 16.1471C13.6844 16.1471 13.4501 15.9128 13.4501 15.6237V14.0131C13.4501 13.2976 13.1659 12.6115 12.6599 12.1055C12.154 11.5996 11.4678 11.3154 10.7523 11.3154H5.11528C4.3998 11.3154 3.71362 11.5996 3.2077 12.1055C2.70177 12.6115 2.41755 13.2976 2.41755 14.0131V15.6237C2.41755 15.9128 2.1832 16.1471 1.89411 16.1471C1.60502 16.1471 1.37067 15.9128 1.37067 15.6237V14.0131C1.37067 13.02 1.76519 12.0675 2.46744 11.3653Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.9338 2.89974C6.44388 2.89974 5.23606 4.10756 5.23606 5.59748C5.23606 7.0874 6.44388 8.29521 7.9338 8.29521C9.42371 8.29521 10.6315 7.0874 10.6315 5.59748C10.6315 4.10756 9.42371 2.89974 7.9338 2.89974ZM4.18918 5.59748C4.18918 3.52938 5.8657 1.85286 7.9338 1.85286C10.0019 1.85286 11.6784 3.52938 11.6784 5.59748C11.6784 7.66557 10.0019 9.34209 7.9338 9.34209C5.8657 9.34209 4.18918 7.66557 4.18918 5.59748Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0607 5.59748C15.3498 5.59748 15.5841 5.83183 15.5841 6.12092V10.9527C15.5841 11.2418 15.3498 11.4761 15.0607 11.4761C14.7716 11.4761 14.5372 11.2418 14.5372 10.9527V6.12092C14.5372 5.83183 14.7716 5.59748 15.0607 5.59748Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1213 8.53681C12.1213 8.24772 12.3557 8.01337 12.6448 8.01337H17.4765C17.7656 8.01337 18 8.24772 18 8.53681C18 8.8259 17.7656 9.06025 17.4765 9.06025H12.6448C12.3557 9.06025 12.1213 8.8259 12.1213 8.53681Z"
        fill="#5B5F73"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconContactAdd;
