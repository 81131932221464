import React from 'react';

/* eslint-disable max-len */
const IconOtherContacts = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <line
      x1="0.5"
      y1="-0.5"
      x2="10.5"
      y2="-0.5"
      transform="matrix(-1 0 0 1 17 6)"
      stroke="#767B92"
      strokeLinecap="round"
    />
    <line
      x1="0.5"
      y1="-0.5"
      x2="10.5"
      y2="-0.5"
      transform="matrix(-1 0 0 1 17 13)"
      stroke="#767B92"
      strokeLinecap="round"
    />
    <circle r="1" transform="matrix(-1 0 0 1 2.5 5.5)" stroke="#767B92" />
    <circle r="1" transform="matrix(-1 0 0 1 2.5 12.5)" stroke="#767B92" />
  </svg>
);

/* eslint-enable max-len */
export default IconOtherContacts;
