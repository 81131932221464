import { List } from 'immutable';

const loadLanguageList = (state, languageList) =>
  state.clear().merge(languageList);

const languageList = (state = List(), action) => {
  switch (action.type) {
    case 'LANGUAGE_LIST_LOAD':
      return loadLanguageList(state, action.languageList);
    default:
      return state;
  }
};

export default languageList;
