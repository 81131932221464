import React from 'react';

/* eslint-disable max-len */
function IconSelectActive() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 1.54286C4.88153 1.54286 1.54286 4.88153 1.54286 9C1.54286 13.1185 4.88153 16.4571 9 16.4571C13.1185 16.4571 16.4571 13.1185 16.4571 9C16.4571 4.88153 13.1185 1.54286 9 1.54286ZM0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.029 6.05642C13.2894 6.32436 13.3459 6.82641 13.0854 7.09435L8.38276 11.9317C8.01809 12.3068 7.37037 12.2392 7.0057 11.864L4.97103 9.7711C4.71055 9.50316 4.75619 9.0273 5.01666 8.75936C5.27714 8.49142 5.74413 8.38383 6.0046 8.65177L7.75629 10.4536L12.0857 6.05642C12.3462 5.78848 12.7685 5.78848 13.029 6.05642Z"
        fill="#5B5F73"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconSelectActive;
