import { PropsWithChildren } from 'react';

import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  ThemeOptions,
  CssBaseline
} from '@mui/material';
// @ts-ignore
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { theme as styledTheme } from '@/styledComponents/theme';

import DefaultTheme from './theme.default';

export interface ThemeProviderProps extends PropsWithChildren {
  theme?: ThemeOptions;
}

export function ThemeProvider({ theme, children }: ThemeProviderProps) {
  const currentTheme = createTheme(theme ?? DefaultTheme);

  return (
    <MuiThemeProvider theme={currentTheme}>
      <>
        <CssBaseline />
        <StyledThemeProvider theme={styledTheme}>
          {children}
        </StyledThemeProvider>
      </>
    </MuiThemeProvider>
  );
}
