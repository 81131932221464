import { ColumnDef } from '@/components/EditableTable/types';
import { Typography } from '@/components/Typography';
import { BaseHeader } from '@/components/EditableTable/components/TableHeader/components/BaseHeader';
import { SortController } from '@/components/EditableTable/components/TableHeader/components/SortController';
import { CheckboxHeader } from '../CheckboxHeader';
import styles from './ColumnHeader.module.scss';

interface ColumnHeaderProps {
  column: ColumnDef;
}

export function ColumnHeader({ column }: ColumnHeaderProps) {
  if (column.type === 'checkbox') {
    return <CheckboxHeader column={column} />;
  }

  return (
    <BaseHeader
      className={styles.container}
      width={column.width}
      colSpan={column.colSpan}>
      <Typography variant="body1Med" className={column.headerClassName}>
        {column.headerName}
      </Typography>
      {column.sortable && (
        <SortController
          className={styles.sortController}
          accessor={column.accessor}
        />
      )}
    </BaseHeader>
  );
}
