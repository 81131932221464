import { assocPath } from 'ramda';
import { makeBlockList } from './dataType';
import {
  ADD_FILE_TO_WAITING_MESSAGE,
  ADD_WAITING_MESSAGE,
  REMOVE_WAITING_MESSAGE,
  REMOVE_WAITING_MESSAGE_FILE_ID
} from './constants';

export const defaultState = () => ({
  blockWaitingMessages: makeBlockList()
});

const setBlockWMList = (state, list) =>
  assocPath(['blockWaitingMessages', 'list'], list, state);

export const reducers = {
  [ADD_WAITING_MESSAGE]: (state, payload) =>
    setBlockWMList(state, [
      ...state.blockWaitingMessages.list,
      payload.message
    ]),
  [REMOVE_WAITING_MESSAGE]: (state, payload) =>
    setBlockWMList(
      state,
      state.blockWaitingMessages.list.filter(
        (message) => message.id !== payload.id
      )
    ),
  [REMOVE_WAITING_MESSAGE_FILE_ID]: removeFileId,
  [ADD_FILE_TO_WAITING_MESSAGE]: addFileToMessage
};

function removeFileId(state, payload) {
  const newList = state.blockWaitingMessages.list.map((message) =>
    message.waitingFiles.includes(payload.id)
      ? {
          ...message,
          waitingFiles: message.waitingFiles.filter((id) => id !== payload.id)
        }
      : message
  );

  return setBlockWMList(state, newList);
}

function addFileToMessage(state, payload) {
  const newList = state.blockWaitingMessages.list.map((message) =>
    message.waitingFiles.includes(payload.fileId)
      ? {
          ...message,
          files: [...message.files, payload.file]
        }
      : message
  );

  return setBlockWMList(state, newList);
}
