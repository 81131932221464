import { ChangeEvent } from 'react';
import { TextField } from '@mui/material';
import styles from './textarea.module.scss';

export interface TextareaProps {
  value: string;
  onChange(value: string): void;
  id?: string;
  placeholder?: string;
  valid?: boolean;
  rows?: number;
  maxRows?: number;
  disabled?: boolean;
}

export function Textarea(props: TextareaProps) {
  const {
    value,
    placeholder,
    onChange,
    id,
    valid = true,
    rows = 5,
    maxRows = 5,
    disabled
  } = props;

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  return (
    <TextField
      id={id}
      value={value}
      error={!valid}
      placeholder={placeholder}
      onChange={handleChange}
      multiline
      fullWidth
      rows={rows}
      maxRows={maxRows}
      classes={{
        root: styles.root
      }}
      disabled={disabled}
    />
  );
}
