import React from 'react';

function IconSort() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-stroke"
        d="M4.88989 1.57172V16.4283M4.88989 16.4283L1.3559 13.1904M4.88989 16.4283L8.34362 13.1904"
        stroke="black"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="cws-icon-main-stroke"
        d="M16.6442 3.43359H7.90802M7.90802 6.75782H16.6442M7.90802 10.082H13.7401"
        stroke="black"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconSort;
