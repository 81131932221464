import { logUseCase } from '../../../../../../utils/logger';

const log = logUseCase.extend('updateDataAfterToggleHide');

export const updateDataAfterToggleHideCase = (
  { getDialogInfo, setDialogInfo },
  { isHidden = false, employeeId }
) => {
  const dialogInfo = getDialogInfo();

  if (dialogInfo && dialogInfo.employeeId === employeeId) {
    log(`Update dialog info with employee ${employeeId}`);
    setDialogInfo({ ...dialogInfo, isHidden });
  }
};
