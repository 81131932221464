import { searchQuery } from '@/utils/searchQuery';

export const getCurrentLocation = () => window.location.pathname;

export const handleQueryParams = (
  searchParams,
  { handler } = { handler: 'replace' }
) => {
  const url = new URL(window.document.URL);

  const currentSearchParams = Object.fromEntries(url.searchParams);

  const historyUrl = `${url.pathname}${searchQuery.generate({
    ...currentSearchParams,
    ...searchParams
  })}`;

  if (handler === 'push') {
    window.history.pushState({}, '', historyUrl);
  } else {
    window.history.replaceState(window.history.state, '', historyUrl);
  }
};

export const getQueryStringParams = () => {
  const url = new URL(window.document.URL);
  return Object.fromEntries(url.searchParams);
};
