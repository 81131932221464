import { getGroupChats } from '@/components/ChatWidget/modules/categories/chats/getters';
import { setList } from '@/components/ChatWidget/data-type/block-group';
import { ac } from '@/components/ChatWidget/useCases/shared/actions';

export const getChatGroups = () => async (dispatch, getState, service) => {
  const { getGroups } = service.chat;
  const groups = await getGroups();
  const groupChat = getGroupChats(getState());
  const blockGroups = setList(groupChat, groups.user.all);
  dispatch(ac.setGroupChats(blockGroups));
};
