import { assoc, dissoc } from 'ramda';

/**
 * @typedef {(number|string)} Id
 */

/**
 * Any object with property id and any properties
 *
 * @typedef {object} Draft
 * @property {string} text
 * @property {Array<string>} files
 */

/**
 * @typedef BlockDraftsMessages
 * @property {object} list - object with id
 */

/**
 * Sets list
 *
 * @param {BlockDraftsMessages} blockDraftMessages
 * @param {object} list
 * @returns {BlockDraftsMessages}
 */
const setList = (blockDraftMessages, list = {}) =>
  assoc('list', list, blockDraftMessages);

/**
 * Sets draft to list
 *
 * @param {object} list
 * @param {Id} chatId
 * @param {Draft} draft
 * @returns {object}
 */
const setDraftToList = (list, chatId, draft = {}) =>
  assoc([chatId], draft, list);

const removeFromList = (list, chatId) => dissoc(chatId, list);

/**
 * Creates a block list data type
 *
 * @param {object} params
 * @param {object} params.list
 * @returns {BlockDraftsMessages}
 */
const makeBlockDraftMessages = ({ list = {} } = {}) => ({
  type: 'BlockDraftsMessages',
  list
});

/**
 * Add or update draft text
 *
 * @param {BlockDraftsMessages} blockDraftMessages
 * @param {Id} chatId
 * @param {Draft} draft
 * @returns {BlockDraftsMessages}
 */
const addOrUpdateDraftText = (blockDraftMessages, chatId, draft) => {
  const updatedList = setDraftToList(blockDraftMessages.list, chatId, draft);
  return setList(blockDraftMessages, updatedList);
};

/**
 * Clear draft
 *
 * @param {BlockDraftsMessages} blockDraftMessages
 * @param {Id} chatId
 * @returns {BlockDraftsMessages}
 */
const clearDraft = (blockDraftMessages, chatId) => {
  const draft = blockDraftMessages.list[chatId];

  if (!draft) return blockDraftMessages;

  const updatedList = removeFromList(blockDraftMessages.list, chatId);

  return setList(blockDraftMessages, updatedList);
};

export { makeBlockDraftMessages, addOrUpdateDraftText, clearDraft };
