import React from 'react';
import { useVideoChatHandlers } from './hooks/useVideoChatHandlers';
import VideoChatWrapper from './components/VideoChatWrapper';
import ControlPanel from './components/ControlPanel';
import Theatre from './components/Theatre';
import Sidebar from './components/Sidebar';
import { ButtonHideVideoChat } from './components/Buttons';
import './style.css';
import { useVideoChatContext } from './hooks';
import { VIDEO_CHAT_PANEL_MODES } from './hooks/useVideoChat';

const VideoChat = () => {
  useVideoChatHandlers();
  const { panelMode } = useVideoChatContext();
  return (
    <VideoChatWrapper>
      <div className="video-chat__section-top-panel">
        <ButtonHideVideoChat />
      </div>
      <Theatre />
      <Sidebar />
      {panelMode === VIDEO_CHAT_PANEL_MODES.Open && <ControlPanel />}
    </VideoChatWrapper>
  );
};

export default VideoChat;
