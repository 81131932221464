import { removeByField } from '../../../../data-type/block-list';

export const hideContactCase = async (
  {
    getChatContact,
    reqHideContact,
    setContacts,
    hideProfile,
    setSuccessMessage,
    updateDataAfterToggleHide,
    scktHideContact
  },
  { employeeId }
) => {
  await reqHideContact(employeeId);
  scktHideContact(employeeId);
  setSuccessMessage({ key: 'contact_was_deleted_from_list' });
  const blockContact = getChatContact();
  const contacts = removeByField(blockContact, employeeId, 'employeeId');
  setContacts({ contacts: contacts.list, size: contacts.size });
  hideProfile();
  updateDataAfterToggleHide(employeeId, { isHidden: true });
};

export const hideContactWithConfirmCase = async (
  {
    showConfirmModal,
    reqHideContact,
    getChatContact,
    setContacts,
    hideProfile,
    setSuccessMessage,
    scktHideContact,
    updateDataAfterToggleHide
  },
  { name, employeeId }
) => {
  const onDelete = async () =>
    hideContactCase(
      {
        getChatContact,
        reqHideContact,
        setContacts,
        hideProfile,
        setSuccessMessage,
        scktHideContact,
        updateDataAfterToggleHide
      },
      { employeeId }
    );

  showConfirmModal({ name, employeeId, onDelete });
};

export const subscribeToDeleteContactCase = ({ subToDeleteContact }) => {
  subToDeleteContact();
};

export const unSubscribeToDeleteContactCase = ({ unSubToDeleteContact }) => {
  unSubToDeleteContact();
};
