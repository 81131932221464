import React from 'react';
import Text from 'react-components/src/components/Typography';
import Icon, { Icons } from 'react-components/src/icons';
import { useVideoChatContext } from '../../../../hooks/useVideoChatContext';
import './style.css';

const Header = () => {
  const { closeSidebar } = useVideoChatContext();

  return (
    <div className="transcript-chat-header">
      <Text className="transcript-chat-header__title" variant="h2">
        Стенограмма
      </Text>
      <div className="transcript-chat-header__actions">
        <div onClick={closeSidebar}>
          <Icon icon={Icons.Cross} size={12} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Header);
