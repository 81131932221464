import React from 'react';
import classNames from 'classnames';
import { FONTS } from './config';
import './Text.css';

export { FONTS };

/**
 * Simple text component
 *
 * level: (1 = 14px, 2 = 12px, 3 = 10px);
 * weight: (1 = 600, 2 = 500, 3 = 400)
 * lineHeight: (1 = 18px, 2 = 17px, 3 = 16px, 4 = 16px, 5 = 12px)
 *
 * @param children
 * @param { 'h1', 'h2', 'h3', 'body1Sb', 'body1Med', 'body1Reg', 'body2Med', 'body2Reg', 'caption' } variant
 * @param level
 * @param weight
 * @param lineHeight
 * @param className
 * @param Component
 * @param restProps
 * @returns {JSX.Element}
 */

function Text({
  children,
  variant = 'body1Med',
  level,
  weight,
  lineHeight,
  className,
  Component,
  ...restProps
}) {
  const TextComponent = Component || variant.Component || 'span';
  const font = typeof variant === 'string' ? FONTS[variant] : variant;

  return (
    <TextComponent
      {...restProps}
      className={classNames(
        'Text',
        `Text-l-${level || font.level}`,
        `Text-w-${weight || font.weight}`,
        `Text-lh-${lineHeight || font.lineHeight}`,
        {
          [className]: Boolean(className)
        }
      )}>
      {children}
    </TextComponent>
  );
}

export default Text;
