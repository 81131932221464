export default {
  Pin: 'pin',
  Unpin: 'unpin',
  At: 'at',
  Alarm: 'alarm',
  AlarmActive: 'alarm-active',
  Block: 'block',
  Cross: 'cross',
  DelayedMessageEmpty: 'delayed-message-empty',
  DelayedMessage: 'delayed-message',
  Drop: 'drop',
  Excel: 'excel',
  Forward: 'forward',
  Group: 'group',
  Purchase: 'purchase',
  SelectBlank: 'select-blank',
  SelectActive: 'select-active',
  Reply: 'reply',
  Topic: 'topic',
  Task: 'task',
  Notification: 'notification',
  NotificationMute: 'notification-mute',
  Add: 'add',
  ArrowDown: 'arrow-down',
  AddChat: 'add-chat',
  ArchiveDownload: 'archive-download',
  Chat: 'chat',
  CloseLarge: 'close-large',
  List: 'list',
  Search: 'search',
  Sliders: 'sliders',
  Submenu: 'submenu',
  Users: 'users',
  Video: 'video',
  Filter: 'filter',
  Person: 'person',
  ContactAdd: 'contact-add',
  Favorite: 'favorite',
  Settings: 'settings',
  Alert: 'alert',
  Copy: 'copy',
  Mail: 'mail',
  Global: 'global',
  StylePunkt: 'style-punkt',
  StylePunkt1: 'style-punkt1',
  StylePunkt2: 'style-punkt2',
  Email: 'email',
  StyleItalic: 'style-italic',
  StyleBold: 'style-bold',
  StyleUnderline: 'style-underline',
  View: 'view',
  Hide: 'hide',
  AttachFileChat: 'attach-file-chat',
  Emoji: 'emoji',
  FormatText: 'format-text',
  IconData: 'icon-data',
  BtnSendDisabled: 'btn-send-disabled',
  BtnSendEnable: 'btn-send-enable',
  Attach: 'attach',
  Trash: 'trash',
  Select: 'select',
  AlarmDone: 'alarm-done',
  Ok: 'ok',
  Publish: 'publish',
  SlidersPath: 'slidersPath',
  Pen: 'pen',
  Sort: 'sort'
};
