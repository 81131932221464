import React from 'react';

const IconChat = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.5 9H5.50778M9 9H9.00778M12.5 9H12.5078M9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2C5.13401 2 2 5.13401 2 9C2 9.93111 2.18179 10.8198 2.51183 11.6324C2.57499 11.7879 2.60657 11.8657 2.62066 11.9286C2.63445 11.99 2.63949 12.0355 2.63949 12.0986C2.6395 12.163 2.62781 12.2332 2.60442 12.3735L2.14321 15.1407C2.09491 15.4305 2.07076 15.5754 2.1157 15.6802C2.15503 15.7719 2.22811 15.845 2.31981 15.8843C2.42458 15.9292 2.56948 15.9051 2.85926 15.8568L5.62651 15.3956C5.76685 15.3722 5.83702 15.3605 5.90144 15.3605C5.96445 15.3605 6.00996 15.3656 6.07145 15.3793C6.13431 15.3934 6.21207 15.425 6.3676 15.4882C7.18024 15.8182 8.06889 16 9 16ZM5.88889 9C5.88889 9.21478 5.71478 9.38889 5.5 9.38889C5.28522 9.38889 5.11111 9.21478 5.11111 9C5.11111 8.78522 5.28522 8.61111 5.5 8.61111C5.71478 8.61111 5.88889 8.78522 5.88889 9ZM9.38889 9C9.38889 9.21478 9.21478 9.38889 9 9.38889C8.78522 9.38889 8.61111 9.21478 8.61111 9C8.61111 8.78522 8.78522 8.61111 9 8.61111C9.21478 8.61111 9.38889 8.78522 9.38889 9ZM12.8889 9C12.8889 9.21478 12.7148 9.38889 12.5 9.38889C12.2852 9.38889 12.1111 9.21478 12.1111 9C12.1111 8.78522 12.2852 8.61111 12.5 8.61111C12.7148 8.61111 12.8889 8.78522 12.8889 9Z"
      stroke="#5B5F73"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconChat;
