import React from 'react';

function IconDrop() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 7H7V9H21V7ZM6 6V10H22V6H6Z"
        fill="#767B92"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 13H7V15H21V13ZM6 12V16H22V12H6Z"
        fill="#767B92"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 19H7V21H21V19ZM6 18V22H22V18H6Z"
        fill="#767B92"
      />
      <rect x="0.5" y="0.5" width="27" height="27" rx="2.5" stroke="#EBEEF4" />
    </svg>
  );
}

export default IconDrop;
