import React from 'react';

/* eslint-disable max-len */
function IconUsers() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.7501 2.89998C5.45223 2.89998 4.4001 3.95211 4.4001 5.24998C4.4001 6.54784 5.45223 7.59998 6.7501 7.59998C8.04797 7.59998 9.1001 6.54784 9.1001 5.24998C9.1001 3.95211 8.04797 2.89998 6.7501 2.89998ZM3.1001 5.24998C3.1001 3.23414 4.73426 1.59998 6.7501 1.59998C8.76594 1.59998 10.4001 3.23414 10.4001 5.24998C10.4001 7.26581 8.76594 8.89998 6.7501 8.89998C4.73426 8.89998 3.1001 7.26581 3.1001 5.24998ZM11.3704 2.18625C11.4595 1.83848 11.8136 1.62875 12.1613 1.71779C12.9465 1.91881 13.6423 2.37543 14.1393 3.01565C14.6362 3.65586 14.906 4.44327 14.906 5.25373C14.906 6.06418 14.6362 6.85159 14.1393 7.4918C13.6423 8.13202 12.9465 8.58864 12.1613 8.78966C11.8136 8.87871 11.4595 8.66897 11.3704 8.3212C11.2814 7.97343 11.4911 7.61933 11.8389 7.53029C12.3444 7.40086 12.7924 7.10688 13.1124 6.69468C13.4323 6.28248 13.606 5.77553 13.606 5.25373C13.606 4.73193 13.4323 4.22497 13.1124 3.81277C12.7924 3.40057 12.3444 3.10659 11.8389 2.97716C11.4911 2.88812 11.2814 2.53402 11.3704 2.18625ZM1.16916 11.669C1.85367 10.9845 2.78206 10.6 3.7501 10.6H9.7501C10.7181 10.6 11.6465 10.9845 12.331 11.669C13.0155 12.3535 13.4001 13.2819 13.4001 14.25V15.75C13.4001 16.109 13.1091 16.4 12.7501 16.4C12.3911 16.4 12.1001 16.109 12.1001 15.75V14.25C12.1001 13.6267 11.8525 13.029 11.4118 12.5883C10.9711 12.1476 10.3734 11.9 9.7501 11.9H3.7501C3.12684 11.9 2.52911 12.1476 2.0884 12.5883C1.64769 13.029 1.4001 13.6267 1.4001 14.25V15.75C1.4001 16.109 1.10908 16.4 0.750098 16.4C0.391113 16.4 0.100098 16.109 0.100098 15.75V14.25C0.100098 13.2819 0.48465 12.3535 1.16916 11.669ZM14.3707 11.185C14.4605 10.8374 14.815 10.6284 15.1626 10.7181C15.9456 10.9203 16.6394 11.3768 17.1348 12.016C17.6303 12.6551 17.8995 13.4408 17.9001 14.2495V15.75C17.9001 16.109 17.6091 16.4 17.2501 16.4C16.8911 16.4 16.6001 16.109 16.6001 15.75V14.2505C16.5997 13.7298 16.4264 13.224 16.1074 12.8124C15.7884 12.4009 15.3417 12.107 14.8376 11.9768C14.49 11.8871 14.281 11.5326 14.3707 11.185Z"
        fill="#5B5F73"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconUsers;
