import React from 'react';

/* eslint-disable max-len */
function IconFavorite() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99999 0.476135C9.4967 0.476135 9.94999 1.31367 10.168 1.75996L12.0191 5.54865L16.1944 6.13835C16.6862 6.20782 17.301 6.47326 17.4545 6.94566C17.608 7.41805 17.2733 8.01482 16.9163 8.3601L13.885 11.2913L14.6144 15.4445C14.7003 15.9337 14.6997 16.6851 14.2978 16.977C13.896 17.269 13.1632 17.0505 12.7245 16.8176L8.99999 14.8405L5.27551 16.8176C4.83679 17.0505 4.10253 17.1688 3.70069 16.8769C3.29885 16.5849 3.29966 15.9337 3.38558 15.4445L4.11497 11.2913L1.08372 8.3601C0.726654 8.01482 0.319497 7.38468 0.472987 6.91228C0.626477 6.43989 1.31379 6.20782 1.80561 6.13835L5.98088 5.54865L7.83195 1.75996C8.05 1.31367 8.50329 0.476135 8.99999 0.476135ZM11.0375 6.50089C11.1103 6.65002 11.2525 6.75328 11.4168 6.77649L16.0126 7.42558L12.676 10.652C12.5567 10.7674 12.5024 10.9345 12.5312 11.0979L13.334 15.6694L9.23443 13.4932C9.08783 13.4153 8.91216 13.4153 8.76556 13.4932L4.66598 15.6694L5.46883 11.0979C5.49754 10.9345 5.44326 10.7674 5.32394 10.652L1.98741 7.42558L6.58318 6.77649C6.74753 6.75328 6.88964 6.65002 6.9625 6.50089L8.99999 2.33064L11.0375 6.50089Z"
        fill="#5B5F73"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconFavorite;
