import {
  BLOCK_CONTACT_TOGGLE,
  TOGGLE_CONTACT_LIST,
  COMPANY_INFO_TOGGLE_BLOCK_ME,
  COMPANY_EMPLOYEES_TOGGLE_BLOCK_ME,
  CHAT_TOGGLE_BLOCK_ME
} from './constants';

export const toggleContactList = (id) => (dispatch) => {
  dispatch({ type: TOGGLE_CONTACT_LIST, payload: { id } });
};

export const toggleBlockMeOfCompInfo = ({ employeeId, isBlocked }) => ({
  type: COMPANY_INFO_TOGGLE_BLOCK_ME,
  payload: { employeeId, isBlocked }
});

export const toggleBlockMeOfEmployees = ({ employeeId, isBlocked }) => ({
  type: COMPANY_EMPLOYEES_TOGGLE_BLOCK_ME,
  payload: { employeeId, isBlocked }
});

export const toggleBlockMe = ({ employeeId, isBlocked }) => ({
  type: CHAT_TOGGLE_BLOCK_ME,
  payload: { employeeId, isBlocked }
});

export const toggleBlockContact = ({ employeeId, isBlocked }) => ({
  type: BLOCK_CONTACT_TOGGLE,
  payload: { employeeId, isBlocked }
});
