import React from 'react';
import { isFunction } from '@/utils/utils';

export class Interceptor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      unregister: null
    };
  }

  componentDidMount() {
    const { interceptor } = this.props;

    if (isFunction(interceptor)) {
      const unregister = interceptor();

      if (isFunction(unregister)) {
        this.setState({ unregister });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.interceptor !== this.props.interceptor) {
      const { unregister } = prevState;
      const { interceptor } = this.props;

      if (isFunction(unregister)) {
        unregister();
      }

      if (isFunction(interceptor)) {
        const newUnregister = interceptor();
        this.setState({ unregister: newUnregister });
      }
    }
  }

  componentWillUnmount() {
    const { unregister } = this.state;

    if (isFunction(unregister)) {
      unregister();
    }
  }

  render() {
    return this.props.children;
  }
}
