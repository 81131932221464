import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { useId, useFocusVisible } from '@/hooks';

import { TextFieldClasses, TextField } from '@mui/material';
import { Typography } from '@/components/Typography';
import { InputLabel } from '@/components/InputLabel';

import styles from './Input.module.scss';
import {
  AUTOCOMPLETE,
  Autocomplete,
  AutocompleteActions,
  AutocompleteProps,
  InputProps
} from './Input.types';

const textFieldClasses: Partial<TextFieldClasses> = {
  root: styles.root
};

function useAutocomplete({
  autocompleteOn,
  autocompleteOnlyByFocus
}: AutocompleteProps): [Autocomplete, AutocompleteActions] {
  const { isFocused, onFocus, onBlur } = useFocusVisible();

  const calcAutocomplete = useCallback(() => {
    if (autocompleteOn && autocompleteOnlyByFocus) {
      return isFocused ? AUTOCOMPLETE.On : AUTOCOMPLETE.Off;
    }

    return autocompleteOn ? AUTOCOMPLETE.On : AUTOCOMPLETE.Off;
  }, [autocompleteOn, autocompleteOnlyByFocus, isFocused]);

  const [autocomplete, setAutocomplete] = useState<Autocomplete>(
    calcAutocomplete()
  );

  useEffect(() => {
    if (autocompleteOnlyByFocus) {
      setAutocomplete(calcAutocomplete());
    }
  }, [isFocused, autocompleteOnlyByFocus, calcAutocomplete]);

  return [autocomplete, { onFocus, onBlur }];
}

export function Input({
  className,
  defaultValue = '',
  onChange,
  valid = true,
  value,
  id: idOverride,
  type,
  disabled,
  placeholder,
  startAdornment,
  endAdornment,
  name,
  autocompleteOn = true,
  autocompleteOnlyByFocus = false,
  required = false,
  label,
  errorText
}: InputProps) {
  const id = useId(idOverride);
  const [inputValue, setInputValue] = useState<string>(defaultValue || '');

  const [autocomplete, autocompleteActions] = useAutocomplete({
    autocompleteOn,
    autocompleteOnlyByFocus
  });

  useEffect(() => {
    if (typeof value !== 'undefined' && value !== null) {
      setInputValue(value);
    }
  }, [value]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onChange(newValue);
  };

  return (
    <div className={className}>
      {label && <InputLabel text={label} required={required} htmlFor={id} />}
      <TextField
        name={name}
        classes={textFieldClasses}
        className={cn(className, styles.background)}
        error={!valid}
        id={id}
        data-testid="input"
        type={type}
        onChange={onInputChange}
        value={inputValue}
        InputProps={{
          endAdornment,
          startAdornment
        }}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={autocomplete}
        onFocus={autocompleteActions.onFocus}
        onBlur={autocompleteActions.onBlur}
      />

      {!valid && errorText && (
        <Typography variant="caption" className={styles.errorMessage}>
          {errorText}
        </Typography>
      )}
    </div>
  );
}
