import * as contactsActions from '@/components/ChatWidget/modules/contacts/actions';
import * as chatsActions from '@/components/ChatWidget/modules/chats/actions';
import * as usersActions from '@/action-creators/user';
import { getCurrentEmployeeId } from '@/storeGetters';
import { getOpenedChat } from '@/components/ChatWidget/getter';
import { isDialog } from '@/components/ChatWidget/helpers/chatType';

export const setUserStatus = (employeeId, status) => (dispatch, getState) => {
  const state = getState();
  const currentEmployeeId = getCurrentEmployeeId(state);
  const openedChat = getOpenedChat(state);

  if (currentEmployeeId === employeeId) {
    dispatch(usersActions.setUserStatus(status));
    return;
  }

  dispatch(contactsActions.setContactStatus({ employeeId, status }));
  dispatch(chatsActions.setMemberStatus({ employeeId, status }));

  if (isDialog(openedChat)) {
    const hasTargetMember = openedChat.members.some(
      (member) => member.employeeId === employeeId
    );

    if (hasTargetMember) {
      const updatedChat = {
        ...openedChat,
        members: openedChat.members.map((member) => ({ ...member, status }))
      };

      dispatch(chatsActions.setOpenChat(updatedChat));
    }
  }
};

export const clearUserStatus = (employeeId) => setUserStatus(employeeId, null);
