import * as storage from './storage';
import * as actions from './actions';

export const subscribeToUpdate = () => async (dispatch) => {
  storage.subToConnected((id) => dispatch(actions.connected(id)));
  storage.subToDisconnected((id) => dispatch(actions.disconnected(id)));

  const onlines = await storage.getAllOnlines();
  dispatch(actions.setOnlines(onlines));
};
