.collapsibleArea {
  display: flex;
  flex-direction: column;
}

.visibleHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.visibleContent {
  flex-grow: 1;
}

.button:hover {
  background-color: transparent;
}

.collapsibleContent {
  display: flex;
  justify-content: space-between;
}

.horizontalLine {
  color: var(--color-gray-300);
  opacity: 0.3;
  size: 1px;
  width: 100%;
}
