import { SyntheticEvent } from 'react';
import cn from 'classnames';
import { isFunction } from '@/lib/utils';
import { Typography } from '../Typography';
import styles from './Link.module.scss';
import { LinkProps } from './Link.types';

export function Link({
  href,
  className = '',
  displayName = '',
  openInNewTab = true,
  variant = 'body1Med',
  onClick,
  testId
}: LinkProps) {
  const handleClick = (event: SyntheticEvent) => {
    if (onClick && href) {
      event.preventDefault();
    }

    if (isFunction(onClick)) {
      onClick();
    }
  };
  return (
    <Typography variant={variant} className={cn(styles.link, className)}>
      <a
        href={href}
        className={styles.link}
        target={(openInNewTab && '_blank') || '_self'}
        onClick={handleClick}
        data-testid={testId}>
        {displayName.length === 0 ? href : displayName}
      </a>
    </Typography>
  );
}
