import io from 'socket.io-client';
import { emit, handle } from 'core/data/messageBus/frontend';

export const messageSocket = io('/message', {
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: 99999
});

export const init = () => {
  messageSocket.on('/to-frontend', ({ message, params }) => {
    emit(message, params);
  });

  handle({ name: 'some-msg' }, (...args) => {
    console.info('some-msg', args);
  });
};

export const emitToServer = (message, params) =>
  messageSocket.emit('/to-backend', { message, params });
