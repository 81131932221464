import React from 'react';
import {
  ButtonEndCall,
  ButtonCamera,
  ButtonMicrophone
  // ButtonControlTranscript
} from '../Buttons';

const ControlPanel = () => (
  <div className="video-chat__section-buttons">
    {/* Пригласить участника */}
    <div>{}</div>
    <div className="video-chat__section-buttons-center">
      <ButtonMicrophone />
      <ButtonCamera />
    </div>
    <ButtonEndCall />
    {/* <ButtonControlTranscript /> */}
  </div>
);

export default ControlPanel;
