import { assoc, unionWith, eqBy, prop, uniqWith, concat } from 'ramda';

const EMPTY_ID = null;

/**
 * @typedef {(EMPTY_ID|number|string)} ShownId
 * @typedef {number} Size
 */

/**
 * Any object with property id and any properties
 *
 * @typedef {object} Item
 * @property {(number|string)} id
 */

/**
 * @typedef BlockList
 * @property {ShownId} shownId
 * @property {number} size
 * @property {[Item]} list - object with id
 */

/**
 * Creates a block list data type
 *
 * @param {object} params
 * @param {[Item]} params.list
 * @param {ShownId} params.shownId
 * @param {Size} params.size
 * @returns {BlockList}
 */
const makeBlockList = ({ list = [], shownId = EMPTY_ID, size = 0 } = {}) => ({
  type: 'BlockList',
  list,
  shownId,
  size
});

/**
 * Merge data to block list data type
 *
 * @param {BlockList} blockList
 * @param {string} fieldName
 * @param {object} params
 * @param {[Item]} params.list
 * @param {ShownId} params.shownId
 * @param {Size} params.size
 * @returns {BlockList}
 */
const mergeBlockList = (
  blockList,
  fieldName,
  { list = [], shownId = EMPTY_ID, size = 0 }
) => {
  // const contactsList = blockList.list.concat(
  // list.filter(i => !!blockList.list.find(bi => bi[fieldName] !== i[fieldName]))
  // );
  const contactsList = unionWith(eqBy(prop(fieldName)), blockList.list, list);
  return makeBlockList({ list: contactsList, shownId, size });
};

/**
 * Sets a shown id
 *
 * @param {BlockList} blockList
 * @param {ShownId} id
 * @returns {BlockList}
 */
const setShownId = (blockList, id) => assoc('shownId', id, blockList);

/**
 * Sets list
 *
 * @param {BlockList} blockList
 * @param {[object]} list
 * @returns {BlockList}
 */
const setList = (blockList, list = []) => assoc('list', list, blockList);

/**
 * Sets a shown id at EMPTY_ID
 *
 * @param {BlockList} blockList
 * @returns {BlockList}
 */
const clearShownId = (blockList) => setShownId(blockList, EMPTY_ID);

/**
 * Determines if shown id is id
 *
 * @param {BlockList} blockList
 * @param {ShownId} id
 * @returns {BlockList}
 */
const equalShownId = (blockList, id) => blockList.shownId === id;

/**
 * Determines if shown id equal EMPTY_ID
 *
 * @param {BlockList} blockList
 * @returns {BlockList}
 */

const isHidden = (blockList) => equalShownId(blockList, EMPTY_ID);

/**
 * Determines if shown id not equal EMPTY_ID
 *
 * @param {BlockList} blockList
 * @returns {BlockList}
 */
const isShown = (blockList) => !isHidden(blockList);

/**
 * Determines if shown id equal item with id
 *
 * @param {BlockList} blockList
 * @param {Item} item
 * @returns {BlockList}
 */
const isActiveItem = (blockList, item) => equalShownId(blockList, item.id);

/**
 * Determines if includes item
 *
 * @param {BlockList} blockList
 * @param {(number|string)} id
 * @returns {Item}
 */
const includes = (blockList, id) =>
  blockList.list.findIndex((item) => item.id === id) !== -1;

/**
 * Gets item by id if exists
 *
 * @param {BlockList} blockList
 * @param {(number|string)} id
 * @returns {Item}
 */
const getItem = (blockList, id) =>
  blockList.list.find((item) => item.id === id);

/**
 * Gets item which is a shown id
 *
 * @param {BlockList} blockList
 * @returns {Item}
 */
const getActiveItem = (blockList) =>
  isShown(blockList) && getItem(blockList, blockList.shownId);

/**
 * Update data for item in list
 *
 * @param {BlockList} blockList
 * @param {number} id
 * @param {object} info
 * @returns {BlockList}
 */
const updateListItemData = (blockList, id, info) => {
  const index = blockList.list.findIndex((i) => i.employeeId === id);
  const newList = [...blockList.list];
  newList[index] = { ...blockList.list[index], ...info };
  return setList(blockList, newList);
};

/**
 * Update item in list
 *
 * @param {BlockList} blockList
 * @param {Item} item
 * @returns {BlockList}
 */
const updateItem = (blockList, item) => {
  const updatedList = blockList.list.map((i) => {
    if (i.id === item.id) return { ...i, ...item };
    return i;
  });
  return setList(blockList, updatedList);
};

/**
 * Update item or push in list if not exists
 *
 * @param {BlockList} blockList
 * @param {Item} item
 * @returns {BlockList}
 */
const updateOrPush = (blockList, item) => {
  const index = blockList.list.findIndex((i) => i.id === item.id);

  if (index === -1) {
    return setList(blockList, [...blockList.list, item]);
  }
  return updateItem(blockList, item);
};

/**
 * Update item or unshift in list if not exists
 *
 * @param {BlockList} blockList
 * @param {Item} item
 * @returns {BlockList}
 */
const updateOrUnshift = (blockList, item) => {
  const index = blockList.list.findIndex((i) => i.id === item.id);

  if (index === -1) {
    return setList(blockList, [item, ...blockList.list]);
  }
  return updateItem(blockList, item);
};

/**
 * Update item and unshift in list if not exists
 *
 * @param {BlockList} blockList
 * @param {Item} item
 * @returns {BlockList}
 */
const updateAndUnshift = (blockList, item) => {
  const index = blockList.list.findIndex((i) => i.id === item.id);

  if (index === -1) {
    return setList(blockList, [item, ...blockList.list]);
  }
  const listWithoutItem = blockList.list.filter((i) => i.id !== item.id);
  return setList(blockList, [item, ...listWithoutItem]);
};

/**
 * Update item and unshift in list if not exists before filtered items
 *
 * @param {BlockList} blockList
 * @param {Item} item
 * @param {Function} filter
 * @returns {BlockList}
 */
const updateAndUnshiftBeforeItems = (blockList, item, filter = () => {}) => {
  const { list } = blockList;
  const beforeItems = list.filter(filter);

  if (beforeItems.length === 0) {
    return updateAndUnshift(blockList, item);
  }

  const beforeItemsIds = beforeItems.map((i) => i.id);
  if (beforeItemsIds.includes(item.id)) {
    return updateItem(blockList, item);
  }

  beforeItems.push(item);
  const afterItems = list.filter(
    (i) => !beforeItemsIds.includes(i.id) && i.id !== item.id
  );
  const newList = beforeItems.concat(afterItems);
  return setList(blockList, [...newList]);
};

/**
 * Merge items list and update size
 *
 * @param {BlockList} blockListA
 * @param {BlockList} blockListB
 * @returns {BlockList}
 */
const merge = (blockListA, blockListB) => {
  const list = unionWith(eqBy(prop('id')), blockListB.list, blockListA.list);

  return makeBlockList({
    list,
    shownId: blockListA.shownId,
    size: blockListB.size
  });
};

/**
 * push items list to uniq list and update size
 *
 * @param {BlockList} blockListA
 * @param {BlockList} blockListB
 * @returns {BlockList}
 */
const concatList = (blockListA, blockListB) => {
  const newList = concat(blockListA.list, blockListB.list);
  const list = uniqWith(eqBy(prop('id')), newList);

  return makeBlockList({
    list,
    shownId: blockListA.shownId,
    size: blockListB.size
  });
};

/**
 * Removes item from list
 *
 * @param {BlockList} blockList
 * @param {string} id
 * @returns {BlockList}
 */
const remove = (blockList, id) =>
  setList(
    blockList,
    blockList.list.filter((item) => item.id !== id)
  );

/**
 * Removes item from list by field
 *
 * @param {BlockList} blockList
 * @param {string} id
 * @param {string} field
 * @returns {BlockList}
 */
const removeByField = (blockList, id, field) =>
  setList(
    blockList,
    blockList.list.filter((item) => item[field] !== id)
  );

/**
 * Adds item at first from list
 *
 * @param {BlockList} blockList
 * @param {Item} item
 * @returns {BlockList}
 */
const prepend = (blockList, item) =>
  setList(blockList, [item, ...blockList.list]);

const mapList = (blockList, fn) => setList(blockList, blockList.list.map(fn));

export {
  makeBlockList,
  setList,
  mapList,
  setShownId,
  clearShownId,
  equalShownId,
  isHidden,
  isShown,
  isActiveItem,
  getActiveItem,
  mergeBlockList,
  updateListItemData,
  getItem,
  updateItem,
  updateOrPush,
  updateOrUnshift,
  updateAndUnshift,
  updateAndUnshiftBeforeItems,
  merge,
  includes,
  remove,
  removeByField,
  prepend,
  concatList
};
