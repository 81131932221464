export interface UITouchEvent extends MouseEvent, TouchEvent {}
export type UITouchHandler = (event: UITouchEvent) => void;

const coordX = (event: UITouchEvent): number => {
  if (event.clientX !== null) {
    return event.clientX;
  }

  return event.changedTouches && event.changedTouches[0].clientX;
};

const coordY = (event: UITouchEvent): number => {
  if (event.clientY !== null) {
    return event.clientY;
  }

  return event.changedTouches && event.changedTouches[0].clientY;
};

const touchEnabled = () => document && 'ontouchstart' in window;

function getSupportedEvents(): string[] {
  if (touchEnabled()) {
    return ['touchstart', 'touchmove', 'touchend', 'touchcancel'];
  }

  return ['mousedown', 'mousemove', 'mouseup', 'mouseleave'];
}

export { getSupportedEvents, coordX, coordY, touchEnabled };
