import {
  getChatArchive,
  getChatBlock,
  getOpenedChat
} from '@/components/ChatWidget/getter';
import {
  getItem,
  updateItem
} from '@/components/ChatWidget/data-type/block-list';
import { addUnreadMsg } from 'core/data/light/mainChannel';
import { ac } from '@/components/ChatWidget/useCases/shared/actions';

export const addMsgToUnread = (chatId, messageId) => (dispatch, getState) => {
  const state = getState();
  const openedChat = getOpenedChat(state);
  // This fix broken read message after send (set read)
  // if (!isNil(openedChat) && openedChat.id === chatId) {
  //   return;
  // }
  const chatList = getChatBlock(state);
  const chat = getItem(chatList, chatId);
  const archivesList = getChatArchive(state);
  const archive = getItem(archivesList, chatId);

  if (chat) {
    const updatedChat = addUnreadMsg(chat, messageId);
    dispatch(ac.updateChat(updatedChat));
  } else if (archive) {
    const updatedArhive = addUnreadMsg(archive, messageId);
    const updateArchives = updateItem(archivesList, updatedArhive);
    dispatch(ac.setArchives(updateArchives));
  } else if (openedChat) {
    const updatedChat = addUnreadMsg(openedChat, messageId);
    dispatch(ac.setOpenChat(updatedChat));
  }
};
