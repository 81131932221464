export const uploadMessagesService = async (
  { reqFindChunkPrev, reqGetUnsentFiles, setMessages, setInputFiles },
  chat
) => {
  setMessages([], 0);
  // const uploadedMsgs = await req.getMessages(chat.id);
  const files = await reqGetUnsentFiles(chat.id);
  const chunk = await reqFindChunkPrev(chat.id);

  setMessages(chunk.list, chunk.amountLeft);
  setInputFiles(files);
};
