import React from 'react';

/* eslint-disable max-len */
function IconMail() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00001 3.6501C2.53399 3.6501 2.15001 4.03408 2.15001 4.5001V13.5001C2.15001 13.9661 2.53399 14.3501 3.00001 14.3501H15C15.466 14.3501 15.85 13.9661 15.85 13.5001V4.5001C15.85 4.03408 15.466 3.6501 15 3.6501H3.00001ZM0.850006 4.5001C0.850006 3.31611 1.81602 2.3501 3.00001 2.3501H15C16.184 2.3501 17.15 3.31611 17.15 4.5001V13.5001C17.15 14.6841 16.184 15.6501 15 15.6501H3.00001C1.81602 15.6501 0.850006 14.6841 0.850006 13.5001V4.5001Z"
        fill="#1C50DE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.9675 4.1274C1.17336 3.83331 1.57866 3.76179 1.87275 3.96765L9 8.95673L16.1273 3.96765C16.4213 3.76179 16.8266 3.83331 17.0325 4.1274C17.2384 4.42149 17.1668 4.82679 16.8728 5.03265L9.37275 10.2827C9.14894 10.4393 8.85106 10.4393 8.62725 10.2827L1.12725 5.03265C0.833158 4.82679 0.761636 4.42149 0.9675 4.1274Z"
        fill="#1C50DE"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconMail;
