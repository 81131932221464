import { defStruct } from '../../lib';

/**
 * @typedef ConfigItem - configuration item
 */
const methods = defStruct('ConfigItem', ['label', 'key', 'value']);

/**
 * Create a configuration item data type
 *
 * @param {string} label
 * @param {string} key
 * @param {string} value
 * @returns {ConfigItem}
 */
export const makeConfigItem = (label = '', key = '', value = null) =>
  methods.makeConfigItem(label, key, value);

/**
 * Gives a label of configuration item
 *
 * @param {ConfigItem} configItem
 * @returns {string}
 */
export const getLabel = (configItem) => methods.getLabel(configItem);

/**
 * Gives a key of configuration item
 *
 * @param {ConfigItem} configItem
 * @returns {string}
 */
export const getKey = (configItem) => methods.getKey(configItem);

/**
 * Gives a value of configuration item
 *
 * @param {ConfigItem} configItem
 * @returns {*}
 */
export const getValue = (configItem) => methods.getValue(configItem);

/**
 * Checks if is ConfigItem
 *
 * @param {*} configItem
 * @returns {boolean}
 */
export const isConfigItem = (configItem) => methods.isConfigItem(configItem);
