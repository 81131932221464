import { ImgHTMLAttributes, SyntheticEvent, useState } from 'react';

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  fallbackSrc: string;
}

export function Image(props: ImageProps) {
  const { src, fallbackSrc, alt, onError, ...restProps } = props;

  const [hasError, setHasError] = useState(false);

  const errorHandler = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    setHasError(true);
    onError?.(event);
  };

  return (
    <img
      {...restProps}
      src={hasError ? fallbackSrc : src}
      onError={errorHandler}
      alt={alt}
    />
  );
}
