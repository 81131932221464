import { defStruct } from '../../lib';

/**
 * @typedef DataPart - A Partial data
 */
const methods = defStruct('DataPart', ['allIds', 'part', 'totalCount']);

/**
 * Create a partial data data type
 *
 * @param {object} params
 * @param {[number]} params.allIds
 * @param {[*]} params.part
 * @param {number} params.totalCount
 * @returns {DataPart}
 */
export const makeDataPart = ({ allIds = [], part = [], totalCount = 0 } = {}) =>
  methods.makeDataPart(allIds, part, totalCount);

/**
 * Gives all ids, which match all the data
 *
 * @param {DataPart} dataPart
 * @returns {[number]}
 */
export const getAllIds = (dataPart) => methods.getAllIds(dataPart);

/**
 * Gives a part data from DataPart
 *
 * @param {DataPart} dataPart
 * @returns {[*]}
 */
export const getPart = (dataPart) => methods.getPart(dataPart);

/**
 * Gives value part from part data of dataPart
 *
 * @param {number} id
 * @param {DataPart} dataPart
 * @returns {object}
 */
export const getById = (id, dataPart) =>
  methods.getPart(dataPart).find((p) => p.id === id);

/**
 * Checks if is DataPart
 *
 * @param {*} a
 * @returns {boolean}
 */
export const isDataPart = (a) => methods.isDataPart(a);

/**
 * Gives a total size of all data
 *
 * @param {DataPart} dataPart
 * @returns {number}
 */
export const getTotalSize = (dataPart) => methods.getAllIds(dataPart).length;

/**
 * Checks if is dataPart is empty
 *
 * @param {DataPart} dataPart
 * @returns {boolean}
 */
export const isEmpty = (dataPart) => getTotalSize(dataPart) === 0;

/**
 * Gives total count value
 *
 * @param {DataPart} dataPart
 * @returns {number}
 */
export const getTotalCount = (dataPart) => methods.getTotalCount(dataPart);
