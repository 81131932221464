import { bindActionCreators } from 'redux';
import * as actions from '../actions';
import * as messageInputActions from '../../messageInput/actions';
import * as storage from '../storage';
import * as filesStorage from '../../files/storage';
import { uploadMessagesService } from './uploadMessages';

export const uploadMessages = (chat) => async (dispatch) =>
  uploadMessagesService(
    {
      reqGetUnsentFiles: filesStorage.getUnsentFiles,
      reqFindChunkPrev: storage.findChunkPrev,
      setMessages: bindActionCreators(actions.setMessages, dispatch),
      setInputFiles: bindActionCreators(
        messageInputActions.setInputFiles,
        dispatch
      )
    },
    chat
  );
