const {
  assoc,
  pipe,
  has,
  isNil,
  type,
  equals,
  zipObj,
  curry
} = require('ramda');
// Data is a Object
// Id is a Number
// Constructor is a methods of create Struct and get data from struct
// Struct is a typed data

// String -> String
const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

// Struct -> String
export const getType = (data) =>
  !isNil(data) && has('type', data) ? data.type : type(data);

const getErrorMsg = (methodName, methodType, sentType) =>
  `The method "${methodName}" belongs to the type "${methodType}" by your "${sentType}"`;
const genGetMethodName = (propName) => `get${capitalize(propName)}`;

const createGetMethods = (props, typeName) =>
  props.reduce(
    (acc, pr) =>
      assoc(
        genGetMethodName(pr),
        (struct) => {
          if (!equals(getType(struct), typeName)) {
            throw new Error(
              getErrorMsg(genGetMethodName(pr), typeName, getType(struct))
            );
          }
          return struct[pr];
        },
        assoc(
          pr,
          (struct) => {
            if (!equals(getType(struct), typeName)) {
              throw new Error(getErrorMsg(pr, typeName, getType(struct)));
            }
            return struct[pr];
          },
          acc
        )
      ),
    {}
  );

// String Struct -> Boolean
export const isType = curry((typeName, data) => getType(data) === typeName);

// String [String] -> Constructor
export const defStruct = (typeName, props = []) =>
  pipe(
    createGetMethods,
    assoc('getModuleType', () => typeName),
    assoc(`make${typeName}`, (...args) =>
      assoc('type', typeName, zipObj(props, args))
    ),
    assoc(`is${typeName}`, (struct) => isType(typeName, struct))
  )(props, typeName);
