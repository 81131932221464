export const attachFilesFromStorageCase = async (
  {
    getChatInput,
    errorHandler,
    attachUnsentFileFromStorage,
    setInputFiles,
    setFocusOnInputMessage,
    awaiter = setTimeout
  },
  { chatId, fileIds }
) => {
  const input = getChatInput();

  const { files: attachedFiles, maxFiles } = input;
  const totalFiles = fileIds.length + attachedFiles.length;

  if (totalFiles > maxFiles) {
    errorHandler('error_file_upload_limit', { amount: maxFiles });
    return;
  }

  const newFiles = await attachUnsentFileFromStorage(chatId, fileIds);

  setInputFiles([...attachedFiles, ...newFiles]);
  awaiter(setFocusOnInputMessage, 0);
};
