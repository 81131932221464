import { Map, fromJS } from 'immutable';

const loadUnitCodes = (state, unitsCodes) =>
  state.clear().merge(fromJS(unitsCodes));

export default (state = Map(), { type, payload }) => {
  switch (type) {
    case 'UNITCODES_LIST_LOAD':
      return loadUnitCodes(state, payload);
    default:
      return state;
  }
};
