import { Map, List, Set, fromJS } from 'immutable';

const setProducts = (
  state,
  {
    data,
    offset,
    limit,
    size,
    search,
    allSize,
    productsId,
    orderBy,
    direction,
    filterValues
  }
) =>
  state
    .set('list', List(fromJS(data)))
    .set('size', size)
    .set('limit', limit)
    .set('offset', offset)
    .set('search', search)
    .set('allSize', allSize)
    .set('productsId', productsId)
    .set('orderBy', orderBy)
    .set('direction', direction)
    .set('filterValues', fromJS(filterValues));

const selectProduct = (state, { id }) => {
  if (id === undefined) {
    console.error('need pass id');
    return state;
  }

  return state.update('selectedList', (selected) =>
    selected.has(id) ? selected.remove(id) : selected.add(id)
  );
};

const selectAllProducts = (state) => {
  if (state.get('selectedList').size === state.get('list').size) {
    return state.update('selectedList', (selected) => selected.clear());
  }

  const idsOfProducts = state.get('list').map((product) => product.get('id'));
  return state.update('selectedList', () => Set(idsOfProducts));
};

const selectProducts = (data, { ids }) =>
  data.update('selectedList', (selected) => selected.union(Set(ids)));
const unselectProducts = (data, { ids }) =>
  data.update('selectedList', (selected) => selected.subtract(Set(ids)));

const toggleFilterSelected = (state) =>
  state.set('isEnabledFilterSelected', !state.get('isEnabledFilterSelected'));

const initState = () =>
  Map({
    list: List(),
    selectedList: Set(),
    productsId: Set(),
    size: 0,
    allSize: 0,
    limit: 20,
    offset: 0,
    search: '',
    isEnabledFilterSelected: false,
    orderBy: 'name',
    direction: 'ASC',
    filterValues: [],
    appliedFilterValues: []
  });

const setFilterField = (data, { name, value }) => {
  const index = data
    .get('filterValues')
    .findIndex((filter) => filter.get('fieldName') === name);
  const filter = data.getIn(['filterValues', index]).toJS();
  return data.setIn(['filterValues', index], fromJS({ ...filter, ...value }));
};

const applyFilter = (data, { filters }) =>
  data.set('appliedFilterValues', filters);

const list = {
  TABLE_PRODUCT_LOADED: setProducts,
  TABLE_PRODUCT_SELECT: selectProduct,
  TABLE_PRODUCT_SELECT_ALL: selectAllProducts,
  CATALOG_SELECT_PRODUCTS: selectProducts,
  CATALOG_UNSELECT_PRODUCTS: unselectProducts,
  CATALOG_FILTER_SELECT_TOGGLE: toggleFilterSelected,
  CLEAR_PRODUCT_TABLE: initState,
  'TABLE_PRODUCTS:SET_FILTER_FIELD': setFilterField,
  'TABLE_PRODUCTS:APPLY_FILTER': applyFilter
};

export default (state = initState(), action) => {
  const reducer = list[action.type];
  return reducer === undefined ? state : reducer(state, action.payload);
};
