import { isEmpty } from 'ramda';
import { getChatWidget } from '../../getterCommon';

export const getChatInput = (state) => getChatWidget(state).blockInput;
export const getChatInputText = (state) => getChatInput(state).text;
export const getChatInputFiles = (state) => getChatInput(state).files;
export const getChatInputLoadingFilesIds = (state) =>
  getChatInput(state).loadingFilesIds;
export const isChatInputLoadingFilesIdsEmpty = (state) =>
  isEmpty(getChatInput(state).loadingFilesIds);

export const getEditMsg = (state) => getChatWidget(state).blockEdit;
