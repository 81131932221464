import { append, remove } from 'ramda';
import { CONNECTED, DISCONNECTED, SET_ONLINES } from './constants';

const reducers = {
  [SET_ONLINES]: (state, { list = [] }) => list,
  [CONNECTED]: (state, { employeeId }) => {
    if (state.includes(employeeId)) return state;
    return append(employeeId, state);
  },
  [DISCONNECTED]: (state, { employeeId }) => {
    const index = state.findIndex((emId) => emId === employeeId);

    if (index === -1) return state;
    return remove(index, 1, state);
  }
};

export default (state = [], { type, payload }) => {
  if (reducers[type]) return reducers[type](state, payload);
  return state;
};
