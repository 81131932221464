export enum ProcurementType {
  regular = 'Regular',
  regularWithConfirmationRequest = 'RegularWithConfirmation',
  auction = 'Auction',
  auctionWithConfirmation = 'AuctionWithConfirmation'
}

export enum ProcurementStatus {
  draft = 'draft',
  receivingResponses = 'receiving_responses',
  receptionCompleted = 'reception_completed',
  onIntroduction = 'on_introduction',
  onApproval = 'on_approval',
  denialOfApproval = 'denial_of_approval',
  forPublication = 'for_publication',
  completed = 'completed',
  canceled = 'canceled',
  scheduled = 'scheduled',
  underModeration = 'under_moderation',
  moderationFailed = 'moderation_failed'
}
