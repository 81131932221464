import { isNil } from 'ramda';
import assert from 'assert';
import { implies } from '@/utils/asserts';
// export const isAdmin = email => email === process.env.REACT_APP_ADMIN_LOGIN;
// export const isSupport = email => email === process.env.SUPPORT_ACCOUNT;

export const getLocationOrigin = () => {
  if (process.env.NODE_ENV === 'testing') return null;
  return window.location.origin;
};

export const isAdmin = (id) => id === +process.env.REACT_APP_ADMIN_ACCOUNT_ID;
export const isSupport = (id) =>
  id === +process.env.REACT_APP_SUPPORT_ACCOUNT_ID;
export const isAdminAccount = (id) => isAdmin(id) || isSupport(id);
export const isWidget = (path) => !path.includes('chat');

export const DOMAIN = process.env.REACT_APP_UNICAT_MESSENGER_SERVICE;
export const AUTH_DOMAIN = process.env.REACT_APP_UNICAT_MESSENGER_SERVICE;

export const getPageRegToken = (token, type = 'simple') => {
  if (!token) {
    return `${AUTH_DOMAIN}/registration`;
  }
  if (type === 'simple') {
    return `${AUTH_DOMAIN}/registration/${token}`;
  }
  if (type === 'link') {
    return `${AUTH_DOMAIN}/welcome/registration/${token}`;
  }
  if (type === 'partner') {
    return `${AUTH_DOMAIN}/welcome/partner/registration/${token}`;
  }
  throw new Error(`not such type ${type}`);
};

const isEnabledIstockSupport = () =>
  process.env.REACT_APP_ENABLED_ISTOCK_SUPPORT === 'true';

assert(
  implies(
    isEnabledIstockSupport(),
    !isNil(process.env.REACT_APP_ADMIN_ACCOUNT_ID)
  ),
  'Need pass process.env.REACT_APP_ADMIN_ACCOUNT_ID'
);
export const getSupportIstockEmployeeId = () =>
  +process.env.REACT_APP_ADMIN_ACCOUNT_ID;

export const isDesktopApp = () =>
  window.navigator.userAgent.split('Electron').length > 1;

export function defineOS() {
  if (navigator.userAgent.indexOf('Win') !== -1) return 'win';
  if (navigator.userAgent.indexOf('Mac') !== -1) return 'mac';
  if (navigator.userAgent.indexOf('X11') !== -1) return 'UNIX';
  if (navigator.userAgent.indexOf('Linux') !== -1) return 'linux';
  return 'unknown';
}
