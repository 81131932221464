export const TEMPORARY_SET = 'TEMPORARY:SET';
export const TEMPORARY_CLEAR = 'TEMPORARY:CLEAR';

export const TEMPORARY_VALUE_SET = 'TEMPORARY:VALUE:SET';
export const TEMPORARY_VALUE_CLEAR = 'TEMPORARY:VALUE:CLEAR';

export const TEMPORARY_SET_TO_SET = 'TEMPORARY:SET:SET_VALUES';
export const TEMPORARY_APPEND_TO_SET = 'TEMPORARY:SET:APPEND_VALUE';
export const TEMPORARY_REMOVE_FROM_SET = 'TEMPORARY:SET:REMOVE_VALUE';
export const TEMPORARY_TOGGLE_IN_SET = 'TEMPORARY:SET:TOGGLE_VALUE';
export const TEMPORARY_CLEAR_SET = 'TEMPORARY:SET:CLEAR';

export const TEMPORARY_SET_TO_MAP = 'TEMPORARY:MAP:SET';
export const TEMPORARY_DELETE_FROM_MAP = 'TEMPORARY:MAP:DELETE';
export const TEMPORARY_CLEAR_MAP = 'TEMPORARY:MAP:CLEAR';
