.root {
  width: 100%;

  :global(.MuiInputBase-root) {
    color: inherit;
    font: inherit;
    padding: var(--indent-xs) var(--indent-m);

    input {
      color: inherit;
      font: inherit;
      padding: var(--indent-xs) var(--indent-m);
      line-height: 18px;
      height: auto;

      &::placeholder {
        color: var(--color-gray-600);
        opacity: 1;
      }
    }

    :global(.MuiOutlinedInput-notchedOutline) {
      border-color: var(--color-gray-500);
    }

    &:hover {
      :global(.MuiOutlinedInput-notchedOutline) {
        border-color: var(--color-blue-200);
      }
    }

    &:global(.MuiOutlinedInput-root.Mui-focused) {
      & > fieldset {
        border: 1px solid var(--color-blue-500);
        border-radius: 4px;
        box-shadow: 0 0 5px rgb(35 105 241 / 31%);
      }
    }

    &:global(.MuiOutlinedInput-root.Mui-disabled) {
      background-color: var(--color-gray-300);

      & > fieldset {
        border: 1px solid transparent;
        border-radius: 4px;
        color: var(--color-gray-700);
      }

      & > input {
        -webkit-text-fill-color: var(--color-gray-700);
      }

      & > input::placeholder {
        color: var(--color-gray-600);
      }
    }

    &:global(.MuiOutlinedInput-root.Mui-error) {
      & > fieldset {
        border: 1px solid var(--color-red-error);
        border-radius: 4px;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }
}

.counter {
  color: var(--color-gray-800);
}

.label {
  margin-bottom: 0;
}

.textarea {
  display: flex;
  flex-direction: column;
}
