import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react';
import { AccordionContext } from './AccordionContext';
import { AccordionItem } from './AccordionItem';
import {
  AccordionContextValue,
  AccordionProps,
  AccordionRegisterItem
} from './Accordion.types';

export function Accordion({
  children,
  className,
  alwaysOpen
}: PropsWithChildren<AccordionProps>) {
  const alwaysOpenRef = useRef(alwaysOpen);

  useEffect(() => {
    alwaysOpenRef.current = alwaysOpen;
  }, [alwaysOpen]);

  const accordionItems = useMemo(() => new Map(), []);

  const registerAccordionItem = useCallback(
    (itemId: string, item: AccordionRegisterItem) => {
      accordionItems.set(itemId, item);
    },
    [accordionItems]
  );

  const closeOtherItems = useCallback(
    (idDoNotClose: string) => {
      if (alwaysOpenRef.current) return;

      accordionItems.forEach((item, key) => {
        if (key !== idDoNotClose) {
          item.close();
        }
      });
    },
    [accordionItems]
  );

  const contextValue = useMemo<AccordionContextValue>(
    () => ({
      register: registerAccordionItem,
      closeOtherItems
    }),
    [registerAccordionItem, closeOtherItems]
  );

  return (
    <div data-testid="accordion-group" className={className}>
      <AccordionContext.Provider value={contextValue}>
        {children}
      </AccordionContext.Provider>
    </div>
  );
}

Accordion.Item = AccordionItem;
