import { Typography } from '@/components/Typography';
import { DotBadge } from '@/components/DotBadge';
import styles from './title.module.scss';

interface TitleProps {
  title: string;
  shownBadge?: boolean;
}

export function Title(props: TitleProps) {
  const { title, shownBadge } = props;

  return (
    <div>
      <Typography variant="body1Med" className={styles.title}>
        {title}
      </Typography>
      {shownBadge && <DotBadge shown={shownBadge} />}
    </div>
  );
}
