import React from 'react';

export function MicOff() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width={18}
      height={19}
      viewBox="0 0 18 19">
      <g clipPath="url(#a)">
        <path
          stroke="#5B5F73"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.3"
          d="M11.154 6.682V3.376A2.254 2.254 0 0 0 6.88 2.373M8.9 14.588v2.979m0-2.979a5.26 5.26 0 0 1-5.26-5.26V7.826m5.26 6.762a5.26 5.26 0 0 0 5.26-5.26V7.826m-8.266 9.74h6.011M1.386 1.815l15.028 15.028M8.9 11.582a2.254 2.254 0 0 1-2.254-2.254V7.074l3.849 3.847a2.247 2.247 0 0 1-1.595.662Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 .428h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
