import * as storage from '../storage';
import { changeMutedCase } from './changeMuted';
import urlBase64ToUint8Array from '../../../../../utils/urlBase64ToUint8Array';

export const setPushNotificationsMuted = async () =>
  changeMutedCase(
    {
      changeMuted: storage.changeMuted
    },
    { isMuted: true }
  );

export const setPushNotificationsActivated = async () =>
  changeMutedCase(
    {
      changeMuted: storage.changeMuted
    },
    { isMuted: false }
  );

export const subscribe = async (payload) => {
  const { subscriptionKey } = payload;

  if ((await Notification.requestPermission()) === 'denied') return;

  const registration = await navigator.serviceWorker.ready;
  const vapidPublicKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;
  const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
  const subscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: convertedVapidKey
  });

  await storage.pushSubscribe(subscription, subscriptionKey);
};

export const unsubscribe = async (payload) => {
  const { subscriptionKey } = payload;

  const registration = await navigator.serviceWorker.ready;
  const subscription = await registration.pushManager.getSubscription();
  const { unsubscribe: needUnsubscribe } = await storage.pushUnsubscribe(
    subscription,
    subscriptionKey
  );

  if (needUnsubscribe) {
    await subscription.unsubscribe();
  }
};
