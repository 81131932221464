import React from 'react';

/* eslint-disable max-len */
const IconUnblock = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 8H5C4.44772 8 4 8.44772 4 9V15C4 15.5523 4.44772 16 5 16H13C13.5523 16 14 15.5523 14 15V9C14 8.44772 13.5523 8 13 8ZM5 7C3.89543 7 3 7.89543 3 9V15C3 16.1046 3.89543 17 5 17H13C14.1046 17 15 16.1046 15 15V9C15 7.89543 14.1046 7 13 7H5Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 4.5C5 2.29086 6.79086 0.5 9 0.5C11.2091 0.5 13 2.29086 13 4.5V5H12V4.5C12 2.84315 10.6569 1.5 9 1.5C7.34315 1.5 6 2.84315 6 4.5V8H5V4.5Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconUnblock;
