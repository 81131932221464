export class SaveDraftPurchaseRequestDto {
  constructor(props) {
    this.type = props.type;
    this.id = props.id;
    this.title = props.title;
    this.routeId = props.routeId;
    this.categoriesId = props.categoriesId;
    this.country = props.country;
    this.region = props.region;
    this.city = props.city;
    this.productsList = props.productsList;
    this.products = props.products;
    this.partResponse = props.partResponse;
    this.supplierRequirements = props.supplierRequirements;
    this.attachments = props.attachments;
    this.responsibleUser = props.responsibleUser;
    this.sum = props.sum || null;
    this.responseEndDate = props.responseEndDate || null;
    this.responseStartDate = props.responseStartDate || null;
    this.finalizationIn = props.finalizationIn || null;
    this.autoRenewalPeriod = props.autoRenewalPeriod || 0;
    this.decreaseRate = props.decreaseRate;
    this.disableBidEdit = props.disableBidEdit;
    this.preBidAllowed = props.preBidAllowed;
    this.isSupplierBidHidden = props.isSupplierBidHidden;
    this.links = props.links;
    this.number = props.number;
    this.accessCompanyList = props.accessCompanyList || null;
    this.suppliers = props.suppliers || [];
    this.lastPublishedDate = props.lastPublishedDate;
    this.status = props.status;
    this.authorizedCompanies = props.authorizedCompanies;
    this.executors = props.executors;
    this.nonPriceCriteria = props.nonPriceCriteria;
    this.accreditationQuestionnaireId = props.accreditationQuestionnaireId;
    this.vatIncluded = props.vatIncluded;
    this.rankType = props.rankType;
    this.contactInfo = props.contactInfo;
    this.currencies = props.currencies;
    this.supplierSpecificPrices = props.supplierSpecificPrices;
    this.warehouses = props.warehouses;
    this.address = props.address;
  }
}
