import { addOrUpdateDraftMessage, clearDraft } from './actions';
import { getChatInput } from '../../getter';
import { getDraftMessages } from './getters';

const TIMEOUT_SET_DRAFT = 30000;

let timeoutSetDraftId = null;

const prepareFiles = (files) => files.map(({ name }) => name);

const haveMessage = (text, files) => text.length || files.length;

const setDraft = (chatId, text, files) => (dispatch) => {
  dispatch(
    addOrUpdateDraftMessage({ chatId, text, files: prepareFiles(files) })
  );
};

export const setDraftMessage =
  (chatId, { withTimeout = true, text = '' } = {}) =>
  (dispatch, getState) => {
    const { files } = getChatInput(getState());

    if (getDraftMessages(getState())[chatId]) {
      dispatch(clearDraft(chatId));
    }

    clearTimeout(timeoutSetDraftId);

    if (haveMessage(text, files)) {
      if (withTimeout) {
        timeoutSetDraftId = setTimeout(
          () => dispatch(setDraft(chatId, text, files)),
          TIMEOUT_SET_DRAFT
        );
      } else {
        dispatch(setDraft(chatId, text, files));
      }
    }
  };
