import { PropsWithChildren } from 'react';
import cn from 'classnames';
import { WithClassName } from '@/components/common.types';
import styles from './formContainer.module.scss';

interface FormCardProps extends PropsWithChildren, WithClassName {}

export function FormContainer(props: FormCardProps) {
  const { className, children } = props;

  return <div className={cn(styles.formContainer, className)}>{children}</div>;
}
