import React from 'react';

function IconEmail() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.83329 6.66675C7.63668 6.66675 6.66663 7.6368 6.66663 8.83341C6.66663 10.03 7.63668 11.0001 8.83329 11.0001C10.0299 11.0001 11 10.03 11 8.83341C11 7.6368 10.0299 6.66675 8.83329 6.66675ZM5.99996 8.83341C5.99996 7.26861 7.26849 6.00008 8.83329 6.00008C10.3981 6.00008 11.6666 7.26861 11.6666 8.83341C11.6666 10.3982 10.3981 11.6667 8.83329 11.6667C7.26849 11.6667 5.99996 10.3982 5.99996 8.83341Z"
        fill="#7F869E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0166 10.8776C12.2307 11.1558 12.5488 11.3334 13 11.3334C13.4511 11.3334 13.7693 11.1558 13.9834 10.8776C14.2054 10.5892 14.3333 10.1672 14.3333 9.66675H15C15 10.2708 14.8468 10.8489 14.5116 11.2843C14.1686 11.73 13.6534 12.0001 13 12.0001C12.3465 12.0001 11.8314 11.73 11.4883 11.2843C11.1531 10.8489 11 10.2708 11 9.66675H11.6666C11.6666 10.1672 11.7945 10.5892 12.0166 10.8776Z"
        fill="#7F869E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.83329 3.33341C5.79573 3.33341 3.33329 5.79585 3.33329 8.83341C3.33329 11.871 5.79573 14.3334 8.83329 14.3334C9.01739 14.3334 9.16663 14.4827 9.16663 14.6667C9.16663 14.8508 9.01739 15.0001 8.83329 15.0001C5.42754 15.0001 2.66663 12.2392 2.66663 8.83341C2.66663 5.42766 5.42754 2.66675 8.83329 2.66675C12.239 2.66675 15 5.42766 15 8.83341V9.66675H14.3333V8.83341C14.3333 5.79585 11.8709 3.33341 8.83329 3.33341ZM11.6666 9.00008V9.66675H11V9.00008H11.6666Z"
        fill="#7F869E"
      />
    </svg>
  );
}

export default IconEmail;
