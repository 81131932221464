import React from 'react';

/* eslint-disable max-len */
const IconArchive = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.24998 5.35001C2.60896 5.35001 2.89998 5.64102 2.89998 6.00001V15.1H15.1V6.00001C15.1 5.64102 15.391 5.35001 15.75 5.35001C16.109 5.35001 16.4 5.64102 16.4 6.00001V15.75C16.4 16.109 16.109 16.4 15.75 16.4H2.24998C1.89099 16.4 1.59998 16.109 1.59998 15.75V6.00001C1.59998 5.64102 1.89099 5.35001 2.24998 5.35001Z"
      fill="#5B5F73"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.0999756 2.25001C0.0999756 1.89102 0.39099 1.60001 0.749976 1.60001H17.25C17.609 1.60001 17.9 1.89102 17.9 2.25001V6.00001C17.9 6.35899 17.609 6.65001 17.25 6.65001H0.749976C0.39099 6.65001 0.0999756 6.35899 0.0999756 6.00001V2.25001ZM1.39998 2.90001V5.35001H16.6V2.90001H1.39998Z"
      fill="#5B5F73"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.84998 9.00001C6.84998 8.64102 7.14099 8.35001 7.49998 8.35001H10.5C10.859 8.35001 11.15 8.64102 11.15 9.00001C11.15 9.35899 10.859 9.65001 10.5 9.65001H7.49998C7.14099 9.65001 6.84998 9.35899 6.84998 9.00001Z"
      fill="#5B5F73"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconArchive;
