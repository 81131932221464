import { all, includes, __, reduce, isEmpty } from 'ramda';
import { defStruct } from '../../lib';
import {
  makeSelectedList,
  getIds,
  add,
  contains,
  remove,
  isEmpty as isEmptySelectedList
} from '../selectedList';
import { makeDataPart, getAllIds } from '../dataPart';

/**
 * @typedef Select
 * @typedef {import('../dataPart')} DataPart
 * @typedef {import('../selectedList')} SelectedList
 */
const methods = defStruct('Select', ['dataPart', 'selectedList']);

/**
 * Creates a select data type
 *
 * @param {object} params
 * @param {DataPart} params.dataPart
 * @param {SelectedList} params.selectedList
 * @returns {Select}
 */
export const makeSelect = ({
  dataPart = makeDataPart(),
  selectedList = makeSelectedList()
} = {}) => methods.makeSelect(dataPart, selectedList);

/**
 * Checks if is select
 *
 * @param {*} a
 * @returns {boolean}
 */
export const isSelect = (a) => methods.isSelect(a);

/**
 * Checks if all ids data part is selected
 *
 * @param {Select} select
 * @returns {boolean}
 */
export const isSelectedAllDataPartIds = (select) => {
  const selectedListIds = getIds(methods.getSelectedList(select));
  const dataPartAllIds = getAllIds(methods.getDataPart(select));

  if (isEmpty(selectedListIds) && isEmpty(dataPartAllIds)) return false;

  return all(includes(__, selectedListIds), dataPartAllIds);
};

/**
 * Adds or removes an item from the list, depending on whether it exists in the list or not
 *
 * @param {number} id
 * @param {Select} select
 * @returns {SelectedList}
 */
export const toggleItem = (id, select) => {
  const selectedList = methods.getSelectedList(select);

  return contains(id, selectedList)
    ? remove(id, selectedList)
    : add(id, selectedList);
};

/**
 * Checks if item is selected
 *
 * @param {number} id
 * @param {Select} select
 * @returns {boolean}
 */
export const isSelectedItem = (id, select) =>
  contains(id, methods.getSelectedList(select));

/**
 * Adds or removes all elements from the list, depending on whether they exist in the list
 *
 * @param {Select} select
 * @returns {SelectedList}
 */
export const toggleAllItems = (select) => {
  const selectedList = methods.getSelectedList(select);
  const dataPartAllIds = getAllIds(methods.getDataPart(select));

  if (isEmptySelectedList(selectedList))
    return makeSelectedList({ ids: dataPartAllIds });

  if (isSelectedAllDataPartIds(select)) {
    return reduce((acc, id) => remove(id, acc), selectedList, dataPartAllIds);
  }

  return reduce((acc, id) => add(id, acc), selectedList, dataPartAllIds);
};
