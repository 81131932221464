import { REMOVE_UNSENT_MSG, SET_UNSENT_MSG } from './constants';

export const addUnsentMessage = ({ chatId, employeeId, message }) => ({
  type: SET_UNSENT_MSG,
  payload: { chatId, employeeId, message }
});

export const removeUnsentMessage = ({
  chatId,
  employeeId,
  message,
  deliveryId
}) => ({
  type: REMOVE_UNSENT_MSG,
  payload: { chatId, employeeId, message, deliveryId }
});
