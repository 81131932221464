import { Map, fromJS } from 'immutable'; // eslint-disable-line

export const selectCompany = (data, { item }) => {
  const selected = data.get('selectedCompany');
  if (selected.size > 0 && selected.get('id') === item.get('id')) {
    return data.remove('selectedCompany').set('selectedCompany', Map());
  }
  return data.remove('selectedCompany').set('selectedCompany', fromJS(item));
};

export const clearSelected = (data) =>
  data.remove('selectedCompany').set('selectedCompany', Map());
