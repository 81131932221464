import { getScktChl } from '../../useCases/service';

const scktChl = getScktChl();

export const sendRemoveDivider = (chatId) =>
  scktChl.emit('messages-divider:clear', { chatId });

export const subscribeToRemoveDivider = (cb) =>
  scktChl.on('messages-divider:clear', cb);
export const unsubscribeFromRemoveDivider = () =>
  scktChl.removeListener('messages-divider:clear');
