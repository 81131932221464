import React from 'react';

/* eslint-disable max-len */
function IconHide() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.506061 7.0795C0.862722 7.5411 1.89456 8.80347 3.35971 9.9569C4.81315 11.1011 6.78272 12.2089 9.00013 12.2089C11.2175 12.2089 13.1871 11.1011 14.6405 9.9569C16.1057 8.80347 17.1375 7.5411 17.4942 7.0795C17.9372 6.20221 16.714 5.5423 16.0761 6.45189C15.6362 6.98688 14.8037 7.91943 13.7127 8.7783C12.3709 9.83463 10.7277 10.7089 9.00013 10.7089C7.27252 10.7089 5.62937 9.83463 4.28756 8.7783C3.19658 7.91943 2.36404 6.98689 1.92421 6.45189C1.23032 5.47792 0.0715693 6.05737 0.506061 7.0795Z"
        fill="#5B5F73"
      />
      <path
        d="M6.68054 11.4485L5.92388 13.5274"
        stroke="#5B5F73"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M2.41162 9.08057L0.788691 10.7035"
        stroke="#5B5F73"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11.485 11.7125L12.1704 13.5956"
        stroke="#5B5F73"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15.8949 8.70654L17.3119 10.1235"
        stroke="#5B5F73"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
/* eslint-enable max-len */
export default IconHide;
