import { isEmpty } from 'ramda';

export const removeUnsentFileCase = async (
  { getEditMsg, getChatInput, setInputFiles, removeUnsentFile, getUnsentFiles },
  { chatId, file }
) => {
  const editMsg = getEditMsg().message;
  const inputBlock = getChatInput();
  if (!isEmpty(editMsg)) {
    const files = inputBlock.files.filter((f) => f.id !== file.id);
    setInputFiles(files);
    return;
  }

  await removeUnsentFile(chatId, file.id);
  const uploadedFiles = await getUnsentFiles(chatId);
  setInputFiles(uploadedFiles);
};
