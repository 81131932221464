import { Namespace } from 'i18next';
import React, { useContext, JSX, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export type TranslateFunction = (
  value: Namespace,
  params?: {
    [key: string]: unknown;
  }
) => string;

interface UseTranslateProps {
  prefix?: string;
}

export const TransContext = React.createContext(null as any);

/**
 * @deprecated use useTranslation with namespaces
 */
export const useTranslate = (props: UseTranslateProps = {}) => {
  const { prefix } = props;
  const t = useContext<TranslateFunction>(TransContext);

  return useCallback<TranslateFunction>(
    (value, params) =>
      prefix ? t(`${prefix}.${value}`, params) : t(value, params),
    [t, prefix]
  );
};

const TranslateProvider = ({ children }: { children: JSX.Element }) => {
  const { t } = useTranslation('ui');

  return <TransContext.Provider value={t}>{children}</TransContext.Provider>;
};

export default TranslateProvider;
