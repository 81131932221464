import { Checkbox } from '@/components/Checkbox';
import { Tooltip } from '@/components/Tooltip';
import { isFunction } from '@/lib/utils';
import { BaseCell } from '@/components/EditableTable/components/BaseCell';
import { CheckboxColumnDef, RowDef } from '@/components/EditableTable/types';

interface CheckboxCellProps {
  row: RowDef;
  rowIndex: number;
  column: CheckboxColumnDef;
}

export function CheckboxCell({ row, rowIndex, column }: CheckboxCellProps) {
  const selected = isFunction(column.selected)
    ? column.selected(row, rowIndex)
    : column.selected;
  const handleSelect = () => column.onSelect(row, rowIndex);
  const disabled = isFunction(column.disabled)
    ? column.disabled(row, rowIndex)
    : column.disabled;
  const title = isFunction(column.title)
    ? column.title({ row, rowIndex, disabled })
    : column.title;
  const cellClassName = isFunction(column.cellClassName)
    ? column.cellClassName(row, rowIndex)
    : column.cellClassName;

  return (
    <BaseCell
      className={cellClassName}
      colSpan={column.colSpan}
      width={column.width}
      style={column.style}>
      <Tooltip
        title={title}
        maxWidth={column.titleMaxWidth}
        align={column.titleAlign}>
        <Checkbox
          checked={selected}
          onChange={handleSelect}
          disabled={disabled}
        />
      </Tooltip>
    </BaseCell>
  );
}
