.chip {
  display: flex;
  align-items: center;
  background-color: #1c50de;
  padding: 0 6px;
  border-radius: 4px;
  margin: 2px;
  word-break: break-word;
}

.editor {
  color: #252b49;
  outline: none;
}

.icon {
  padding-left: 4px;
  cursor: pointer;
  height: 18px;
}

.icon path {
  fill: #767b92;
}

.dbs__input-container {
  overflow: hidden;
}

.select {
  color: #252b49;
  font-size: 14px;
  line-height: 150%;
  width: 100%;

  :global(.dbs__value-container) {
    cursor: pointer;
  }

  :global(.dbs__control) {
    border: 1px solid #fff;
    box-shadow: none;
    min-height: 34px;
  }

  &.focused,
  &:hover {
    :global(.dbs__control) {
      border: 1px solid #ebeef4 !important;

      :global(.dbs__indicators) {
        visibility: visible;
      }
    }
  }

  :global(.dbs__indicators) {
    border: none;
    visibility: hidden;
    height: 34px;
  }

  :global(.dbs__placeholder) {
    display: flex;
    align-items: center;
    color: #767b92 !important;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }

  &.focused :global(.dbs__placeholder) {
    color: #c6cae3 !important;
    font-size: 14px;
    line-height: 34px;
    font-weight: 400;
  }

  :global(.Select--multi) :global(.Select-input) {
    display: inline-block !important;
  }

  &:hover :global(.dbs__control) {
    background-color: #f2f4f8;
  }

  &:focus :global(.dbs__control) {
    background-color: inherit;
  }
}
