import {
  initState,
  changeInternetConnection,
  changeServerConnection
} from '../work-with-data/connections';

const connections = (state = initState(), { type, payload }) => {
  switch (type) {
    case 'INTERNET_CONNECTION':
      return changeInternetConnection(state, payload);
    case 'SERVER_CONNECTION':
      return changeServerConnection(state, payload);
    default:
      return state;
  }
};

export default connections;
