.root {
  border-color: var(--color-gray-500);

  &.horizontal-no,
  &.vertical-no {
    margin: 0;
  }

  &.horizontal-xxs {
    margin: var(--indent-xxs) 0;
  }

  &.vertical-xxs {
    margin: 0 var(--indent-xxs);
  }

  &.horizontal-xs {
    margin: var(--indent-xs) 0;
  }

  &.vertical-xs {
    margin: 0 var(--indent-xs);
  }

  &.horizontal-s {
    margin: var(--indent-s) 0;
  }

  &.vertical-s {
    margin: 0 var(--indent-s);
  }

  &.horizontal-m {
    margin: var(--indent-m) 0;
  }

  &.vertical-m {
    margin: 0 var(--indent-m);
  }

  &.horizontal-l {
    margin: var(--indent-ml) 0;
  }

  &.vertical-l {
    margin: 0 var(--indent-ml);
  }
}
