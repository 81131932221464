import React from 'react';
import { Button, Icon, Modal, Typography } from '@link/react-components';
import { useModal } from '@hooks';
import { Trans as Interpolate } from 'react-i18next';
import { useTranslate } from '../../../../TranslateProvider';
import styles from './ModalConfirmMember.module.css';
import {
  sendAllowToJoin,
  sendRejectJoinRequest
} from '../../../../modules/VideoChat/commandsAndEvents';
import { useVideoChatContext } from '../../../../modules/VideoChat/hooks/useVideoChatContext';

const MODAL_KEY = 'MODAL_CONFIRM_MEMBER';
export const key = MODAL_KEY;

function generateTranslate(key) {
  return `videoChat.modals.confirmMember.${key}`;
}

const UserName = ({ name }) => (
  <Typography variant="body1Sb" className={styles.userName}>
    {name}&nbsp;
  </Typography>
);

export const ModalConfirmMember = ({ employee, incomingRequests }) => {
  const { employeeId, socketId } = employee;
  const { roomId } = useVideoChatContext();
  const t = useTranslate();

  function translate(key, options) {
    return t(`videoChat.modals.confirmMember.${key}`, options);
  }

  const { onClose } = useModal();

  const onAccept = () => {
    incomingRequests.remove();
    sendAllowToJoin({ roomId, employeeId, socketId });
    onClose();
  };

  const onCancel = () => {
    incomingRequests.remove();
    sendRejectJoinRequest({ roomId, employeeId, socketId });
    onClose();
  };

  return (
    <Modal disableBackdropClick open alignmentTop onClose={onCancel}>
      <Modal.Header>
        <div className={styles.content}>
          <Icon
            iconName="person"
            width={18}
            height={18}
            className={styles.icon}
          />
          <Typography variant="body1Reg" inline>
            <Interpolate
              i18nKey={generateTranslate('text')}
              className={styles.text}
              components={{
                userName: <UserName name={employee.userName} />
              }}
            />
          </Typography>
        </div>
      </Modal.Header>
      <Modal.Footer align="right">
        <Modal.Actions>
          <Button onClick={onCancel} mode="text" size="small">
            {t(translate('cancelBtn'))}
          </Button>
          <Button onClick={onAccept} size="small">
            {t(translate('confirmBtn'))}
          </Button>
        </Modal.Actions>
      </Modal.Footer>
    </Modal>
  );
};
