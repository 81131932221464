export enum ChannelType {
  Dialog = 'Dialog',
  Channel = 'Channel',
  Topic = 'Topic',
  NewsFeedChannel = 'NewsFeedChannel',
  PurchaseInboundChannel = 'PurchaseInboundChannel',
  PurchaseRequestChannel = 'PurchaseRequestsChannel',
  PurchaseResponseChat = 'ResponseChat',
  PurchaseInfoChannel = 'PurchaseInfoChannel',
  ProcurementChannel = 'ProcurementChannel'
}

export enum ChanelSubType {
  RequestChannel = 'request-channel',
  NewsFeedChannel = 'news-feed-channel',
  PurchaseInboundChannel = 'purchase-inbound-channel',
  ProcurementChannel = 'procurement-channel'
}

export enum AvatarType {
  AvatarInChat = 'avatar-in-chat',
  User = 'user',
  Company = 'company',
  ColorChat = 'color-char',
  ColorIcon = 'color-icon',
  ColorAvatar = 'color-avatar'
}
