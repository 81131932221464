import { assoc } from 'ramda';
import { makeTopic } from 'core/data/light/topic';
import {
  CURRENT_TOPIC,
  CURRENT_MESSAGES,
  TOPIC_SET_INFO,
  TOPIC_SET_MESSAGES,
  TOPIC_TOTAL_CLEAR
} from './constants';

const defaultValues = () => ({
  [CURRENT_TOPIC]: makeTopic({ name: '' }),
  [CURRENT_MESSAGES]: []
});

const reducers = {
  [TOPIC_SET_INFO]: (state, topic) => assoc(CURRENT_TOPIC, topic, state),
  [TOPIC_SET_MESSAGES]: (state, messages) =>
    assoc(CURRENT_MESSAGES, messages, state),
  [TOPIC_TOTAL_CLEAR]: defaultValues
};

export default (state = defaultValues(), { type, payload }) => {
  if (reducers[type]) return reducers[type](state, payload);
  return state;
};
