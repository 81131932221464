import {
  getChatInput,
  getChatTemp,
  getOpenedChat
} from '@/components/ChatWidget/getter';
import { getTempInput } from '@/components/ChatWidget/data-type/block-temp';
import { isNil } from 'ramda';
import { saveScrollData } from '@/components/ChatWidget/modules/scrollPosition/useCase';
import { setChatMode } from '@/components/ChatWidget/modules/chatMode';
import { saveClientData } from '@/components/ChatWidget/modules/clientData';
import { isType } from 'core/lib';
import { PURCHASE_REQUESTS_CHANNEL } from 'core/data/light/purchaseRequestChannel';
import * as dictionariesStorage from '@/modules/dictionaries/storage';
import {
  getScrollPositionData,
  hasScrollPositionData
} from '@/components/ChatWidget/modules/scrollPosition/getters';
import { getInterlocutor } from 'core/data/light/dialog';
import { ac } from '@/components/ChatWidget/useCases/shared/actions';
import { req } from '@/components/ChatWidget/useCases/shared/requests';
import { subscribeToUpdateChat } from '@/components/ChatWidget/useCases/subscribeToUpdateChat';
import { unsubscribeToUpdateChat } from '@/components/ChatWidget/useCases/unsubscribeToUpdateChat';
import { uploadMessages } from '@/components/ChatWidget/useCases/uploadMessages';
import { jumpToMsg } from '@/components/ChatWidget/useCases/jumpToMsg';
import { ChannelType } from '../types';

export const showChat = (chat) => async (dispatch, getState) => {
  const addToTempInput = (chatId) => {
    const blockInput = getChatInput(getState());
    if (blockInput.text) {
      dispatch(
        ac.addOrUpdateTempInput(chatId, { id: chatId, text: blockInput.text })
      );
      dispatch(ac.setInputText(''));
    }
  };

  const setInput = () => {
    const blockTemp = getChatTemp(getState());
    const temp = getTempInput(blockTemp, chat.id);

    if (!isNil(temp)) {
      dispatch(ac.setInputText(temp.text));
      dispatch(ac.removeInputFromTemp(chat.id));
    }
  };

  const openedChat = getOpenedChat(getState());

  if (!isNil(openedChat) && openedChat.id !== chat.id) {
    dispatch(saveScrollData(openedChat.id));
    unsubscribeToUpdateChat(openedChat.id, dispatch)(dispatch);
    addToTempInput(openedChat.id);
  }

  setInput();
  dispatch(ac.clearSelectedMessages());
  dispatch(ac.setOpenChat(chat));

  try {
    dispatch(setChatMode('loading'));
    await dispatch(uploadMessages(chat, true));
    dispatch(setChatMode('chat'));
  } catch (e) {
    dispatch(setChatMode('none'));
    throw e;
  }

  subscribeToUpdateChat(chat.id)(dispatch, getState);
  dispatch(
    saveClientData(chat.id, { isActiveTab: true, isScrollBottom: true })
  );

  if (isType(PURCHASE_REQUESTS_CHANNEL, chat))
    await dispatch(dictionariesStorage.getPurchaseRequestsCategories());

  if (hasScrollPositionData(getState())) {
    const scrollData = getScrollPositionData(getState(), chat.id);

    await dispatch(
      jumpToMsg(scrollData.message, { align: 'start', showBlink: false })
    );
  }

  if (chat.type === ChannelType.Dialog) {
    const employeeId = getState().getIn(['user', 'user', 'employeeId']);
    const interlocutor = getInterlocutor(employeeId, chat);
    const info = await req.getContactInfo(interlocutor.employeeId);
    dispatch(ac.setDialogInfo(info));
  }
};
