import React from 'react';

export const NoSignalIcon = ({ width = 18, height = 18 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 9C9.82843 9 10.5 8.32843 10.5 7.5C10.5 6.67157 9.82843 6 9 6C8.17157 6 7.5 6.67157 7.5 7.5C7.5 8.32843 8.17157 9 9 9ZM9 9V15.75"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g opacity="0.3">
      <path
        d="M3.69652 12.8033C0.767584 9.87439 0.767584 5.12565 3.69652 2.19672M14.3034 2.19672C17.2324 5.12565 17.2324 9.87439 14.3034 12.8033"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g opacity="0.3">
      <path
        d="M12.182 4.31799C13.9394 6.07535 13.9394 8.92459 12.182 10.682M5.81802 10.682C4.06066 8.92459 4.06066 6.07535 5.81802 4.31799"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
