export const getBadgeCount = (chatBadgeCount) => {
  const { muted, unMuted } = chatBadgeCount;
  if (!muted && !unMuted) return null;
  if (muted && unMuted) return unMuted > 99 ? '99*' : `${unMuted}*`;
  if (!muted && unMuted) return unMuted > 99 ? '99+' : `${unMuted}`;
  if (muted && !unMuted) return '*';
  return null;
};

export const getBadgeUnmutedCount = (chatBadgeCount) => {
  const { unMuted } = chatBadgeCount;

  return unMuted || 0;
};
