import React from 'react';

function IconStyleUnderline() {
  return (
    <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.4.558a.75.75 0 0 0-.427.378l-.043.094v2.63c0 2.935-.009 2.737.142 3.197a3.61 3.61 0 0 0 1.503 1.921c.705.438 1.581.652 2.505.615 1.145-.046 2.074-.414 2.761-1.093.591-.585.946-1.314 1.018-2.09.029-.311.028-4.963-.001-5.121a.644.644 0 0 0-.355-.483C9.406.558 9.366.55 9.22.55c-.13 0-.191.01-.261.042a.731.731 0 0 0-.292.252c-.11.175-.107.093-.107 2.826 0 2.887.014 2.655-.188 3.08-.381.798-1.111 1.245-2.177 1.333-.765.064-1.524-.115-2.033-.478a2.38 2.38 0 0 1-.886-1.235l-.045-.16-.011-2.6-.01-2.6-.043-.094A.752.752 0 0 0 2.851.6C2.746.55 2.496.526 2.4.558m.028 9.625a.736.736 0 0 0-.37.245.648.648 0 0 0 .231.969l.101.053 3.474.005c3.442.005 3.474.005 3.581-.035.538-.201.562-.951.038-1.197l-.113-.053-3.43-.003c-2.047-.002-3.463.004-3.512.016"
        fill="#7F869E"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default IconStyleUnderline;
