.container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-left: auto;
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.icon {
  line-height: 0;
  cursor: pointer;

  &.active path {
    fill: var(--editable-table-active-sort-background-color);
  }
}
