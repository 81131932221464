import request from '../../../../lib/request';
import { getScktChl } from '../socket';

export const reqBadges = () => request.get('/api/dialogs/badges');

const scktChl = getScktChl();
export const subscribeToUpdateBadges = (cb) => scktChl.on('badges:update', cb);
export const unsubscribeFromUpdateBadges = () =>
  scktChl.removeListener('badges:update');
export const subscribeToUnreadMessageCount = (cb) =>
  scktChl.on('updateUnreadMsgsCount', cb);
export const unsubscribeFromUnreadMessageCount = () =>
  scktChl.removeListener('updateUnreadMsgsCount');
