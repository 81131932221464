import { assoc, assocPath } from 'ramda';
import {
  ADD_DELAYED_MESSAGE,
  ADD_CHUNK_DELAYED_MESSAGES,
  UPDATE_DELAYED_MESSAGE,
  DELETE_DELAYED_MESSAGE,
  CLEAR_DELAYED_MESSAGES,
  REDUX_KEY,
  SET_DELAYED_MESSAGES,
  SET_PLANNED_DATE,
  CLEAR_PLANNED_DATE
} from './constants';
import {
  makeDelayedMessages,
  addChunkMessages,
  addOneMessage,
  updateOneMessage,
  deleteOneMessage
} from './dataType';

export const actions = {
  setDelayedMessages: (data) => ({ type: SET_DELAYED_MESSAGES, payload: data }),
  addDelayedMessage: (data) => ({ type: ADD_DELAYED_MESSAGE, payload: data }),
  addChunkDelayedMessages: (data) => ({
    type: ADD_CHUNK_DELAYED_MESSAGES,
    payload: data
  }),
  updateDelayedMessage: (data) => ({
    type: UPDATE_DELAYED_MESSAGE,
    payload: data
  }),
  deleteDelayedMessage: (data) => ({
    type: DELETE_DELAYED_MESSAGE,
    payload: data
  }),
  clearDelayedMessages: () => ({ type: CLEAR_DELAYED_MESSAGES }),
  setPlannedDate: (date) => ({ type: SET_PLANNED_DATE, payload: date }),
  clearPlannedDate: () => ({ type: CLEAR_PLANNED_DATE })
};

export const defaultState = () => ({
  [REDUX_KEY]: makeDelayedMessages()
});

const setDelayedMessages = (state, data) => assoc(REDUX_KEY, data, state);
const addChunk = (state, data) =>
  assoc(REDUX_KEY, addChunkMessages(state[REDUX_KEY], data), state);
const addMessage = (state, data) =>
  assoc(REDUX_KEY, addOneMessage(state[REDUX_KEY], data), state);
const updateMessage = (state, data) =>
  assoc(REDUX_KEY, updateOneMessage(state[REDUX_KEY], data), state);
const deleteMessage = (state, data) =>
  assoc(REDUX_KEY, deleteOneMessage(state[REDUX_KEY], data), state);
const clearDelayedMessages = (state) =>
  setDelayedMessages(state, makeDelayedMessages());
const setPlannedDate = (state, date) =>
  assocPath([REDUX_KEY, 'plannedDate'], date, state);
const clearPlannedDate = (state) => setPlannedDate(state, null);

export const reducers = {
  [SET_DELAYED_MESSAGES]: setDelayedMessages,
  [ADD_CHUNK_DELAYED_MESSAGES]: addChunk,
  [ADD_DELAYED_MESSAGE]: addMessage,
  [UPDATE_DELAYED_MESSAGE]: updateMessage,
  [DELETE_DELAYED_MESSAGE]: deleteMessage,
  [CLEAR_DELAYED_MESSAGES]: clearDelayedMessages,
  [SET_PLANNED_DATE]: setPlannedDate,
  [CLEAR_PLANNED_DATE]: clearPlannedDate
};
