import styles from './badge.module.scss';

interface BadgeProps {
  amount: number;
  max?: number;
}

const DEFAULT_MAX_TO_SHOW = 99;

export function Badge(props: BadgeProps) {
  const { amount, max = DEFAULT_MAX_TO_SHOW } = props;

  if (!amount) {
    return null;
  }

  return (
    <div className={styles.badge}>{amount > max ? `${max}+` : amount}</div>
  );
}
