import { useTranslation } from 'react-i18next';
import { Button, Popup, usePopupContext } from '@link/react-components';
import { useNewFeaturesWizard } from '@/components/Wizards';
import { HINTS, hintService } from '@/api';
import { config } from './newFeaturesPopup.config';
import styles from './newFeaturesPopup.module.scss';

export function NewFeaturesPopup() {
  const context = usePopupContext();
  const { onClose } = context;
  const [t] = useTranslation(config.translate.ns, {
    keyPrefix: config.translate.prefix
  });
  const [openNewFeaturesWizard] = useNewFeaturesWizard();

  const handleClose = () => {
    hintService.view({ hintName: HINTS.NewFeatures });
    onClose();
  };

  const handleSubmit = () => {
    openNewFeaturesWizard({});
    onClose();
  };

  return (
    <Popup className={styles.container}>
      <Popup.Header>{t('title')}</Popup.Header>
      <Popup.Footer>
        <Popup.Actions>
          <Button mode="text" onClick={handleClose}>
            {t('buttons.cancel')}
          </Button>
          <Button onClick={handleSubmit}>{t('buttons.submit')}</Button>
        </Popup.Actions>
      </Popup.Footer>
    </Popup>
  );
}

export const NewFeaturesPopupKey = config.key;
