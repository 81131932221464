import { bindActionCreators } from 'redux';
import * as messages from './message';
import * as cases from './useCases';

export const init = () => (dispatch) => {
  messages.onCreatedDelayedMessage(
    bindActionCreators(cases.createdDelayedMessage, dispatch)
  );
  messages.onPublishedDelayedMessage(
    bindActionCreators(cases.publishedDelayedMessage, dispatch)
  );
  messages.onUpdateDelayedMessage(
    bindActionCreators(cases.editedDelayedMessage, dispatch)
  );
  messages.onDeletedDelayedMessage(
    bindActionCreators(cases.deletedDelayedMessage, dispatch)
  );
};
