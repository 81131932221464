import { fromJS, Map, Set } from 'immutable';

import tableReducer from './table';

const deleteTable = (state, table) => state.delete(table);

const setCatalogPublicationComplete = (
  data,
  { catalogId, excelSrc, publishDate }
) =>
  data.updateIn(['catalogs', 'items'], (items) => {
    if (!items) return items;
    const index = items.findIndex((i) => i.get('id') === +catalogId);
    return items
      .setIn([index, 'status'], 'published')
      .setIn([index, 'excelSrc'], excelSrc)
      .setIn([index, 'publishDate'], publishDate);
  });

const setCatalogPublicationStart = (data, { catalogId }) =>
  data.updateIn(['catalogs', 'items'], (items) => {
    if (!items) return items;
    const index = items.findIndex((i) => i.get('id') === +catalogId);
    return items.setIn([index, 'status'], 'publishing');
  });

const unblockItems = (data, { ids = [] }) =>
  data.updateIn(['catalogs', 'items'], (items) => {
    if (!items) return items;
    ids.forEach((id) => {
      const index = items.findIndex((i) => i.get('id') === +id);
      // eslint-disable-next-line no-param-reassign
      items = items.setIn([index, 'blocked'], false);
    });
    return items;
  });

const deselectDocs = (data) => data.setIn(['docs', 'selected'], Set());

const setCatalogListStatus = (data, { catalogId, status }) =>
  data.updateIn(['catalogs', 'items'], (items) => {
    if (!items) return items;
    const index = items.findIndex((i) => i.get('id') === +catalogId);
    return items.setIn([index, 'status'], status);
  });

export default (
  state = fromJS({ common: { items: [], selected: [] } }),
  action
) => {
  switch (action.type) {
    case 'TABLE_DELETE':
      return deleteTable(state, action.table);
    case 'CATALOG_PUBLICATION_START':
      return setCatalogPublicationStart(state, action.payload);
    case 'CATALOG_PUBLICATION_COMPLETE_NOTIFY':
      return setCatalogPublicationComplete(state, action.payload);
    case 'CATALOGS:UNBLOCK_ITEMS:SUCCESS':
      return unblockItems(state, action.payload);
    case 'DOCS:DESELECT_ALL':
      return deselectDocs(state);
    case 'CATALOG_LIST_CHANGE_STATUS':
      return setCatalogListStatus(state, action.payload);
    default:
      return state.update(action.table, Map(), (tableState) =>
        tableReducer(tableState, action)
      );
  }
};
