import { setList } from '../../../data-type/block-group';

export const getChatGroupsService = async ({
  reqGetGroups,
  getGroupChats,
  setGroupChats
}) => {
  const groups = await reqGetGroups();
  const groupChat = getGroupChats();
  const blockGroups = setList(groupChat, groups.user.all);
  setGroupChats(blockGroups);
};
