import cn from 'classnames';
import styles from './Loader.module.scss';
import { LoaderProps, LoaderSize } from './Loader.types';

export function Loader({ size = LoaderSize.medium, className }: LoaderProps) {
  return (
    <div className={cn(styles.loader, className)}>
      <div className={cn(styles.dots, styles[size])}>
        <hr className={styles.dot} />
        <hr className={styles.dot} />
        <hr className={styles.dot} />
        <hr className={styles.dot} />
      </div>
    </div>
  );
}
