import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { config } from '@/config/modules';
import { ModuleName } from '@/constants/module';
import { backwardProhibit } from '@/utils';
import { getActiveModule } from '@/storeGetters/storeGetters';
import { useActions } from '@/hooks/useActions';
import { moduleActions } from '@/reducers/module.slice';

const { modules } = config;

interface SwitchModuleOptions {
  disableRedirect?: boolean;
  url?: string;
}

export function useSwitchModule() {
  const navigate = useNavigate();

  const activeModule = useSelector(getActiveModule);
  const { setActiveModule } = useActions(moduleActions);

  const handleSwitchModule = useCallback(
    (moduleName: ModuleName, options: SwitchModuleOptions = {}) => {
      const chosenModule = modules.find((module) => module.name === moduleName);

      if (!chosenModule) {
        console.error(`Module ${moduleName} was not found`);
        return;
      }

      setActiveModule(chosenModule.name);

      backwardProhibit();

      if (!options?.disableRedirect) {
        navigate(options?.url ?? chosenModule.url);
      }
    },
    [navigate, setActiveModule]
  );

  return {
    activeModule,
    onSwitchModule: handleSwitchModule
  };
}
