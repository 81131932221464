import { assoc } from 'ramda';
import {
  HIDE_GROUP_CONTACT,
  SHOW_GROUP_CONTACT,
  SET_GROUP_CONTACT
} from './constants';
import { hide, makeBlockGroup, show } from '../../../data-type/block-group';

export const defaultState = () => ({
  blockGroupContacts: makeBlockGroup()
});

const setBGContacts = (chatWidget, value) =>
  assoc('blockGroupContacts', value, chatWidget);

export const reducers = {
  [SHOW_GROUP_CONTACT]: (chatWidget, { kind }) =>
    setBGContacts(chatWidget, show(chatWidget.blockGroupContacts, kind)),
  [HIDE_GROUP_CONTACT]: (chatWidget) =>
    setBGContacts(chatWidget, hide(chatWidget.blockGroupContacts)),
  [SET_GROUP_CONTACT]: (chatWidget, blockGroups) =>
    setBGContacts(chatWidget, blockGroups)
};
