import { assocPath } from 'ramda';
import { makeBlockList } from './dataType';
import { SAVE_SCROLL_POSITION } from './constants';

export const defaultState = () => ({
  scrollPositions: makeBlockList()
});

const setScrollPositions = (state, chatId, scrollPositions) =>
  assocPath(['scrollPositions', 'list', chatId], scrollPositions, state);

export const reducers = {
  [SAVE_SCROLL_POSITION]: (state, { chatId, data }) =>
    setScrollPositions(state, chatId, data)
};
