import request from '@/lib/request';
import { CustomerPackage } from '@/pages/Finance/dataTypes';
import { searchQuery } from '@/utils/searchQuery';

class MyCompanyService {
  private baseUrl = '/api/my-company';

  async getEmployees({ limit = 20, offset = 0, search = '' } = {}) {
    const query = searchQuery.generate({ limit, offset, search });

    return request.get(`${this.baseUrl}/employees${query}`);
  }

  async addEmployee(payload: unknown) {
    return request.post(`${this.baseUrl}/add-employee`, payload);
  }

  async inviteEmployee(payload: unknown) {
    return request.post(`${this.baseUrl}/invite-employee`, payload);
  }

  async getPermissions(employeeId: number) {
    return request.get(`${this.baseUrl}/${employeeId}/permissions`);
  }

  async updatePermissions(employeeId: number, data: unknown) {
    return request.post(
      `${this.baseUrl}/${employeeId}/update-permissions`,
      data
    );
  }

  async deletePermissions(employeeId: number) {
    return request.delete(`${this.baseUrl}/${employeeId}/delete-permissions`);
  }

  async updateAdmin(newOwnerUserId: number) {
    return request.post(`${this.baseUrl}/update-admin`, { newOwnerUserId });
  }

  async deleteEmployees(usersId: number) {
    return request.delete(`${this.baseUrl}/employees`, { usersId });
  }

  async updateMyStatus({
    statusId,
    notification
  }: {
    statusId: number;
    notification: unknown;
  }) {
    return request.post(`${this.baseUrl}/update-status`, {
      statusId,
      notificationsEnabled: notification
    });
  }

  async deleteMyStatus() {
    return request.delete(`${this.baseUrl}/delete-status`);
  }

  // Create/update company access token
  async generateAccessToken(): Promise<{ token: string }> {
    return request.post(`${this.baseUrl}/access-token/generate`);
  }

  // Get company access token
  async getAccessToken() {
    return request.get(`${this.baseUrl}/access-token`);
  }

  async getSupplierFinanceInfo() {
    return request.get(`${this.baseUrl}/finance-supplier`);
  }

  async getCustomerFinanceInfo(): Promise<CustomerPackage[]> {
    return request.get(`${this.baseUrl}/finance-customer`);
  }

  async getCommissions({
    limit = 20,
    offset = 0,
    from,
    to
  }: {
    limit?: number;
    offset?: number;
    from: unknown;
    to: unknown;
  }) {
    const query = searchQuery.generate({ limit, offset });
    return request.post(`${this.baseUrl}/finance${query}`, { to, from });
  }
}

export const myCompanyService = new MyCompanyService();
