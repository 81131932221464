import assert from 'assert';
import { assoc, pipe } from 'ramda';

/**
 * @typedef {string} ADMIN
 * @typedef {string} EDITOR
 * @typedef {string} PARTICIPANT
 * @typedef {string} READER
 * @typedef {(ADMIN|EDITOR|PARTICIPANT|READER)} MemberRole
 */

export const ADMIN = 'ADMIN';

export const EDITOR = 'EDITOR';

export const PARTICIPANT = 'PARTICIPANT';

export const READER = 'READER';

const possibleRolesForAdmin = [ADMIN, EDITOR, READER, PARTICIPANT];
const possibleRolesForEditor = [EDITOR, READER, PARTICIPANT];

/**
 * Check if channelMember have admin role
 *
 * @param {ChannelMember} channelMember
 * @returns {boolean}
 */
const haveAdminMR = (channelMember) => channelMember.memberRole === ADMIN;

/**
 * Check if channelMember have editor role
 *
 * @param {ChannelMember} channelMember
 * @returns {boolean}
 */
const haveEditorMR = (channelMember) => channelMember.memberRole === EDITOR;

/**
 * Sets a member role for channelMember
 *
 * @param {ChannelMember} channelMember
 * @param {MemberRole} memberRole
 * @param {Date} [changedMRAt=new Date()]
 * @returns {ChannelMember}
 */
const setMemberRole = (channelMember, memberRole, changedMRAt = new Date()) =>
  pipe(
    assoc('memberRole', memberRole),
    assoc('changedMRAt', changedMRAt)
  )(channelMember);

function setMemberRoleAfterCheckRole({ currentMember, targetMember, role }) {
  return setMemberRole(targetMember, checkRole());

  function getPossibleRoles() {
    if (haveAdminMR(currentMember)) return possibleRolesForAdmin;
    if (haveEditorMR(currentMember)) {
      if (haveAdminMR(targetMember)) return [];
      return possibleRolesForEditor;
    }
    return [];
  }

  function checkRole() {
    const possibleRoles = getPossibleRoles();

    assert(possibleRoles.includes(role), 'Change role failed. Permission deny');

    return role;
  }
}

export const updateMembersRoles = (channel, currentMember, membersRoles) => {
  const updatedMembers = channel.members.map((channelMember) => {
    const updatedMemberRole = membersRoles.find(
      (memberRole) => +memberRole[0] === +channelMember.employeeId
    );
    if (updatedMemberRole) {
      const [memberEmployeeId, role] = updatedMemberRole;

      assert(
        currentMember.employeeId !== memberEmployeeId,
        'You can not change member role for yourself'
      );

      return setMemberRoleAfterCheckRole({
        currentMember,
        targetMember: channelMember,
        role
      });
    }
    return channelMember;
  });

  return assoc('members', updatedMembers, channel);
};
