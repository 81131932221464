import { makeCommand, makeEvent } from 'core/data/messageBus/message';
import { emit, handle } from 'core/data/messageBus/frontend';
import { emitToServer } from '../../../../storage/message';

const NS = 'MESSAGES';

const closeEditMessagePanelCMD = makeCommand(NS, 'CLOSE_EDIT_MESSAGE_PANEL');

export const sendCloseEditMessagePanel = () =>
  emit(closeEditMessagePanelCMD, {});
export const onCloseEditMessagePanel = (cb) =>
  handle(closeEditMessagePanelCMD, cb);

const createDelayedMessageCMD = makeCommand(NS, 'CREATE_DELAYED_MESSAGE'); // { channelId, time, data }
const delayedMessageCreated = makeEvent(NS, 'DELAYED_MESSAGE_WAS_CREATED'); // { channelId, messageId, employeeId, message }

const updateDelayedMessageCMD = makeCommand(NS, 'UPDATE_DELAYED_MESSAGE'); // { channelId, messageId, time, data }
const delayedMessageUpdated = makeEvent(NS, 'DELAYED_MESSAGE_WAS_UPDATED'); // { channelId, messageId, message }

const publishDelayedMessageCMD = makeCommand(NS, 'PUBLISH_DELAYED_MESSAGE'); // { channelId, messageId }
const delayedMessagePublished = makeEvent(NS, 'DELAYED_MESSAGE_WAS_PUBLISHED'); // { channelId, messageId }

const deleteDelayedMessageCMD = makeCommand(NS, 'DELETE_DELAYED_MESSAGE'); // { channelId, messageId }
const delayedMessageDeleted = makeEvent(NS, 'DELAYED_MESSAGE_WAS_DELETED'); // { channelId, messageId }

export const sendCreateDelayedMessageCMD = ({ channelId, time, data }) =>
  emitToServer(createDelayedMessageCMD, { channelId, time, data });
export const onCreatedDelayedMessage = (cb) =>
  handle(delayedMessageCreated, cb);

export const sendPublishDelayedMessageCMD = ({ channelId, messageId }) =>
  emitToServer(publishDelayedMessageCMD, { channelId, messageId });
export const onPublishedDelayedMessage = (cb) =>
  handle(delayedMessagePublished, cb);

export const sendUpdateDelayedMessageCMD = ({
  channelId,
  messageId,
  time,
  data
}) =>
  emitToServer(updateDelayedMessageCMD, { channelId, messageId, time, data });
export const onUpdateDelayedMessage = (cb) => handle(delayedMessageUpdated, cb);

export const sendDeleteDelayedMessageCMD = ({ channelId, messageId }) =>
  emitToServer(deleteDelayedMessageCMD, { channelId, messageId });
export const onDeletedDelayedMessage = (cb) =>
  handle(delayedMessageDeleted, cb);
