import { assoc, assocPath, dissocPath, hasPath, path } from 'ramda';

/**
 * @typedef {(number|string)} Id
 */

/**
 * Any object with property id and any properties
 *
 * @typedef {object} Item
 * @property {(number|string)} id
 */

/**
 * @typedef BLForward - BlockListForward
 * @property {[Id]} chatId
 * @property {[Item]} list - object with id
 */

const makeListsOfBLForward = ({ list = {} } = {}) => ({
  type: 'ListOfBLForward',
  list
});

const addToList = (blockList, chatId, blforward) =>
  assocPath(['list', chatId], blforward, blockList);
const removeFromList = (blockList, chatId) =>
  dissocPath(['list', chatId], blockList);

const getList = (blockList, chatId) => path(['list', chatId], blockList);
const hasList = (blockList, chatId) => hasPath(['list', chatId], blockList);

/**
 * Creates a block list data type
 *
 * @param {object} params
 * @param {[Item]} params.list
 * @param {number|string|null} params.fromChatId
 * @returns {BLForward}
 */
const makeBLForward = ({ list = [], fromChatId = null } = {}) => ({
  type: 'BlockListForward',
  list,
  fromChatId
});

/**
 * Sets list
 *
 * @param {BLForward} blockListForward
 * @param {[object]} list
 * @returns {BLForward}
 */
const setList = (blockListForward, list = []) =>
  assoc('list', list, blockListForward);

/**
 * Remove item from list
 *
 * @param {BLForward} blockListForward
 * @param {Id} itemId
 * @returns {BLForward}
 */
const removeItem = (blockListForward, itemId) => {
  const updatedList = blockListForward.list.filter((i) => i.id !== itemId);
  return setList(blockListForward, updatedList);
};

export {
  makeListsOfBLForward,
  addToList,
  removeFromList,
  getList,
  hasList,
  makeBLForward,
  setList,
  removeItem
};
