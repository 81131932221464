import {
  ADD_OR_UPDATE_TEMP_INPUT,
  REMOVE_INPUT_FROM_TEMP,
  SET_TEMP_BLOCK
} from './constants';

export const setTempBlock = (blockTemp) => ({
  type: SET_TEMP_BLOCK,
  payload: blockTemp
});
export const addOrUpdateTempInput = (chatId, input) => ({
  type: ADD_OR_UPDATE_TEMP_INPUT,
  payload: { chatId, input }
});
export const removeInputFromTemp = (chatId) => ({
  type: REMOVE_INPUT_FROM_TEMP,
  payload: { chatId }
});
