import { defStruct } from '../../lib';

/**
 * @typedef SortBy
 */
const methods = defStruct('SortBy', ['field', 'direction']);

/**
 * Creates a sortBy data type
 *
 * @param {object} params
 * @param {string} params.field
 * @param {string} params.direction
 * @returns {SortBy}
 */
export const makeSortBy = ({ field, direction } = {}) =>
  methods.makeSortBy(field, direction);

/**
 * Get sortBy field value
 *
 * @param {SortBy} sortBy
 * @returns {string}
 */
export const getField = (sortBy) => methods.getField(sortBy);

/**
 * Set sortBy field value
 *
 * @param {string} field
 * @param {SortBy} sortBy
 * @returns {SortBy}
 */
export const setField = (field, sortBy) =>
  methods.makeSortBy(field, methods.getDirection(sortBy));

/**
 * Get sortBy direction value
 *
 * @param {SortBy} sortBy
 * @returns {string}
 */
export const getDirection = (sortBy) => methods.getDirection(sortBy);

/**
 * Set sortBy direction value
 *
 * @param {string} direction
 * @param {SortBy} sortBy
 * @returns {SortBy}
 */
export const setDirection = (direction, sortBy) =>
  methods.makeSortBy(methods.getField(sortBy), direction);

/**
 * Check is sortBy
 *
 * @param {*} a
 * @returns {boolean}
 */
export const isSortBy = (a) => methods.isSortBy(a);

/**
 * Check is direction DESC
 *
 * @param {SortBy} sortBy
 * @returns {boolean}
 */
export const isDescDirection = (sortBy) =>
  methods.getDirection(sortBy) === 'DESC';

/**
 * Check is column sort
 *
 * @param {string} columnName
 * @param {SortBy} sortBy
 * @returns {boolean}
 */
export const isSortColumn = (columnName, sortBy) =>
  methods.getField(sortBy) === columnName;

/**
 * Toggle direction
 *
 * @param {SortBy} sortBy
 * @returns {string}
 */
export const toggleDirection = (sortBy) =>
  methods.getDirection(sortBy) === 'DESC' ? 'ASC' : 'DESC';
