import React, { useRef } from 'react';
import cn from 'classnames';
import { useVideoChatContext } from '../../hooks';
import MemberCard from '../MemberCard';
import { MemberCardExtra } from '../MemberCardExtra';

const Theatre = () => {
  const { videoChatMembers } = useVideoChatContext();

  const styleMemberRef = useRef('');
  const styleContainerRef = useRef('');

  const numberMembers = videoChatMembers.length;

  switch (numberMembers) {
    case 1:
      styleContainerRef.current = 'video-chat__section_xl';
      styleMemberRef.current = 'video-chat__container-dimensions__xl';
      break;
    case 2:
    case 3:
    case 4:
      styleContainerRef.current = 'video-chat__section_l';
      styleMemberRef.current = 'video-chat__container-dimensions__l';
      break;
    case 5:
    case 6:
      styleContainerRef.current = 'video-chat__section_m';
      styleMemberRef.current = 'video-chat__container-dimensions__m';
      break;
    case 7:
    case 8:
      styleContainerRef.current = 'video-chat__section_s_opt1';
      styleMemberRef.current = 'video-chat__container-dimensions__s';
      break;
    case 9:
      styleContainerRef.current = 'video-chat__section_s_opt2';
      styleMemberRef.current = 'video-chat__container-dimensions__s';
      break;
    case 10:
    case 11:
    case 12:
    default:
      styleContainerRef.current = 'video-chat__section_s_opt3';
      styleMemberRef.current = 'video-chat__container-dimensions__s';
      break;
  }

  return (
    <div className={cn('video-chat__section-video', styleContainerRef.current)}>
      {videoChatMembers.map((member, index) =>
        numberMembers > 12 && index >= 11 ? (
          index === 11 && (
            <MemberCardExtra
              membersNumber={numberMembers - 11}
              className={styleMemberRef.current}
            />
          )
        ) : (
          <MemberCard
            key={member.peerId}
            member={member}
            className={cn(
              styleMemberRef.current,
              numberMembers === 10
                ? index === 4 || index === 7
                  ? 'video-chat__container-extra-margin_left'
                  : index === 6 || index === 9
                  ? 'video-chat__container-extra-margin_right'
                  : ''
                : ''
            )}
          />
        )
      )}
    </div>
  );
};

export default Theatre;
