import {
  endOfDay,
  startOfDay,
  isValid,
  isAfter,
  isBefore,
  isEqual,
  format as toFormat,
  parse,
  isSameDay as externalIsSameDay
} from 'date-fns';

interface GetTimeOptions {
  returnStartOfDay?: boolean;
  returnEndOfDay?: boolean;
}

export function getTimeNumber(
  date: Date | number | null,
  options?: GetTimeOptions
) {
  if (!date) return null;

  const { returnStartOfDay, returnEndOfDay = false } = options || {};

  if (returnStartOfDay) {
    return startOfDay(date).getTime();
  }

  if (returnEndOfDay) {
    return endOfDay(date).getTime();
  }

  return new Date(date).getTime();
}

export function isValidDate(date: unknown) {
  return isValid(date);
}

export function isSameDay(
  date: Date | number | null,
  dateToCompare: Date | number | null
) {
  if (!date || !dateToCompare) {
    return false;
  }

  return externalIsSameDay(date, dateToCompare!);
}

function isBiggerOrEqualThanDate(value: Date | number, date: Date | number) {
  return isAfter(value, date) || isEqual(value, date);
}

function isSmallerOrEqualThanDate(value: Date | number, date: Date | number) {
  return isBefore(value, date) || isEqual(value, date);
}

export function isInRange(
  value: Date | number,
  min?: Date | number,
  max?: Date | number
) {
  if (min && max) {
    return (
      isBiggerOrEqualThanDate(value, min) &&
      isSmallerOrEqualThanDate(value, max)
    );
  }

  if (min) {
    return isBiggerOrEqualThanDate(value, min);
  }

  if (max) {
    return isSmallerOrEqualThanDate(value, max);
  }

  return true;
}

interface GetDefaultDateOptions {
  min?: Date | number;
  max?: Date | number;
  autoCorrect?: boolean;
  returnEndOfDay?: boolean;
}

export function getConstraintDateNumber(
  date: Date | number | null,
  options?: GetDefaultDateOptions
): number | null {
  if (!date) return null;

  const {
    autoCorrect = false,
    returnEndOfDay = false,
    min,
    max
  } = options || {};

  const constraintDate = returnEndOfDay ? endOfDay(date) : new Date(date);

  if (!autoCorrect || isInRange(constraintDate, min, max)) {
    return getTimeNumber(constraintDate, { returnEndOfDay });
  }

  if (min && isSmallerOrEqualThanDate(constraintDate, min)) {
    return getTimeNumber(min, { returnEndOfDay });
  }

  if (max && isBiggerOrEqualThanDate(constraintDate, max)) {
    return getTimeNumber(max, { returnEndOfDay });
  }

  return null;
}

export function formatDayOfWeek(day: string) {
  return `${day.toUpperCase()}`;
}

export function getInputValueFromDate(
  date: Date | number | null,
  format: string
) {
  if (!date) return '';

  return toFormat(date, format);
}

export function isMatch(value: string, format: string) {
  return value.length === format.length;
}

export function makeDateString(str: string, format: string) {
  return parse(str, format, new Date());
}
