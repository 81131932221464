.link {
  color: var(--color-blue-500);
  text-decoration: none;

  &:visited {
    color: var(--color-blue-300);
  }

  &:hover {
    color: var(--color-blue-700);
  }
}
