export const makeDelayedMessages = ({
  list = [],
  amountLeft = 0,
  plannedDate = null
} = {}) => ({
  type: 'BlockDelayedMessages',
  list,
  amountLeft,
  plannedDate
});

export const addChunkMessages = (blockListA, blockListB) =>
  makeDelayedMessages({
    list: blockListA.list.concat(blockListB.list),
    amountLeft: blockListB.amountLeft,
    plannedDate: blockListA.plannedDate
  });

export const addOneMessage = (blockList, message) =>
  makeDelayedMessages({
    list: [...blockList.list, message],
    amountLeft: blockList.amountLeft,
    plannedDate: blockList.plannedDate
  });

export const updateOneMessage = (blockList, message) =>
  makeDelayedMessages({
    ...blockList,
    list: blockList.list.map((m) => (m.id === message.id ? message : m))
  });

export const deleteOneMessage = (blockList, messageId) =>
  makeDelayedMessages({
    ...blockList,
    list: blockList.list.filter((message) => message.id !== messageId)
  });
