import { createTheme } from '@mui/material/styles';

const DatePickerTheme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          marginTop: '4px',
          boxShadow: '0 6px 22px rgba(32, 34, 45, 0.21)'
        }
      }
    }
  }
});

export default DatePickerTheme;
