/**
 * @typedef {import('../channelMember')} ChannelMember
 * @typedef {import('../message')} Message
 */

/**
 * @typedef {object} Dialog
 * @property {string} type
 * @property {string} id
 * @property {Date} createdAt
 * @property {Message} lastMessage
 * @property {[ChannelMember]} members - only 2 members
 * @property {string} firstUnreadMessageId
 * @property {[string]} unreadMessagesId
 * @property {boolean} haveNewMessages
 * @property {boolean} archived
 * @property {[number]} notificationOffMembers
 * @property {boolean} notificationOff
 */

/**
 * Creates a dialog data type
 *
 * @param {object} params
 * @param {string} params.id
 * @param {Date} params.createdAt
 * @param {string} params.lastMessage
 * @param {[ChannelMember]} params.members
 * @param {string} params.firstUnreadMessageId
 * @param {[string]} params.unreadMessagesId
 * @param {boolean} params.haveNewMessages
 * @param {boolean} params.archived
 * @param {[number]} params.notificationOffMembers
 * @param {boolean} params.notificationOff
 * @param {[string]} params.confirm
 * @returns {Dialog}
 */
export const makeDialog = ({
  id,
  lastMessage,
  members,
  firstUnreadMessageId,
  unreadMessagesId,
  haveNewMessages,
  archived,
  notificationOffMembers = [],
  notificationOff,
  scheduleMessages = [],
  confirm = [],
  createdAt
} = {}) => ({
  type: 'Dialog',
  id,
  lastMessage,
  members,
  firstUnreadMessageId,
  unreadMessagesId,
  haveNewMessages,
  archived,
  notificationOffMembers,
  notificationOff,
  scheduleMessages,
  confirm,
  createdAt
});

/**
 * Gets interlocutor
 *
 * @param {number} employeeId
 * @param {Dialog} dialog
 * @returns {ChannelMember}
 */
export const getInterlocutor = (employeeId, dialog) =>
  dialog.members.find((member) => member.employeeId !== employeeId);
