import React from 'react';
import classNames from 'classnames';
import './Badge.css';

export const BADGES_TYPES = {
  Online: 'online',
  Notification: 'notification'
};

function Badge({
  children,
  type = BADGES_TYPES.Online,
  disabled = false,
  className
}) {
  return (
    <div
      className={classNames('Badge', `Badge-type-${type}`, {
        disabled,
        [className]: Boolean(className)
      })}>
      {children}
    </div>
  );
}

export default Badge;
