import React from 'react';

/* eslint-disable max-len */
const IconBellOff = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      className="cws-icon-hover"
      cx="17"
      cy="17"
      r="17"
      fill="#767B92"
      fillOpacity="0"
    />
    <path
      className="cws-icon-main-fill"
      d="M12 14C12 11.2386 14.2385 9 17 9C19.103 9 20.9028 10.2984 21.6415 12.1373L20.834 12.856C20.3418 11.2043 18.8115 10 17 10C14.7908 10 13 11.7909 13 14V20.5H12V14Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 21H9.99995L9.99995 20H13L13 21Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 21L17 21V20L24 20V21Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 21V16H22V21H21Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 24C17.7402 24 18.3866 23.5978 18.7324 23H15.2675C15.6133 23.5978 16.2597 24 17 24ZM14.1707 23C14.0601 22.6872 14 22.3506 14 22H20C20 22.3506 19.9398 22.6872 19.8292 23C19.4174 24.1652 18.3062 25 17 25C15.6937 25 14.5825 24.1652 14.1707 23Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.14062 24.1406L24.1406 9.14063L24.8477 9.84773L9.84773 24.8477L9.14062 24.1406Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconBellOff;
