import { getFullName } from '../../../utils/utils';
import * as messageActions from '../../../action-creators/message';

export const onRejectedJoin = (owner) => (dispatch) => {
  dispatch(
    messageActions.setErrorMessage({
      key: 'videoChat.notifications.rejectedJoin',
      params: { userName: getFullName(owner) }
    })
  );
};
