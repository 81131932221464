import React from 'react';

/* eslint-disable max-len */
const IconResend = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="cws-icon-main-fill"
      d="M12.625 1V6.44444H7.375"
      fill="#767B92"
      strokeLinecap="round"
    />
    <path
      d="M13 8.77779C13 12.2142 10.3137 15 7 15C3.68629 15 1 12.2142 1 8.77779C1 5.34135 3.68629 2.55558 7 2.55558C9.37234 2.55558 11.4231 3.98339 12.3968 6.05557"
      stroke="#767B92"
      fill="none"
      strokeLinecap="round"
    />
  </svg>
);

export default IconResend;
