import React from 'react';

/* eslint-disable max-len */
function IconPin() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.30647 0.28853C8.61619 -0.109138 9.13454 -0.029339 9.39425 0.230368L13.7696 4.60575C14.0293 4.86545 14.1091 5.38381 13.7115 5.69353C13.2321 6.06687 12.6703 6.00873 12.3627 5.9769C12.3382 5.97437 12.3154 5.97201 12.2944 5.97005C11.9288 5.9359 11.8521 5.96213 11.7769 6.03738L9.1412 8.67304C9.50803 9.31177 9.52702 9.94574 9.39735 10.4759C9.25364 11.0634 8.9333 11.5096 8.76014 11.7208C8.4632 12.083 7.95801 12.0518 7.67579 11.7696L5.42102 9.5148L2.2386 12.6972C2.01079 12.925 1.64145 12.925 1.41364 12.6972C1.18583 12.4694 1.18583 12.1001 1.41364 11.8723L4.59606 8.68984L2.23043 6.3242C1.94821 6.04199 1.91695 5.5368 2.27916 5.23985C2.49037 5.0667 2.93659 4.74635 3.52413 4.60264C4.05425 4.47298 4.68822 4.49197 5.32696 4.85879L7.96261 2.22314C8.03786 2.14789 8.06409 2.07119 8.02995 1.70564C8.02798 1.68459 8.02562 1.66176 8.02309 1.63732C7.99126 1.32972 7.93313 0.76789 8.30647 0.28853ZM8.12545 10.5693C8.1814 10.4576 8.23135 10.3325 8.26409 10.1987C8.34259 9.87773 8.32801 9.49578 8.01041 9.07463C7.79891 8.79418 7.7939 8.37042 8.07552 8.0888L10.9519 5.21243C11.4107 4.75358 11.9673 4.77032 12.3143 4.80033L9.19967 1.6857C9.22968 2.03267 9.24641 2.58925 8.78757 3.0481L5.91119 5.92447C5.62957 6.20609 5.20581 6.20109 4.92536 5.98959C4.50422 5.67198 4.12226 5.6574 3.80133 5.7359C3.66746 5.76864 3.54235 5.81859 3.43069 5.87455L8.12545 10.5693Z"
        fill="#7F869E"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconPin;
