.expendableTextContent {
  line-height: 1.2em;
  margin: 0;
}

.expandableContentButton {
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--color-blue-500);
  padding: 0;
  margin-top: 12px;
  cursor: pointer;
}
