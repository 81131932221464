import React from 'react';

/* eslint-disable max-len */
function IconPen() {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.357.946a2.595 2.595 0 0 0-.768.326c-.146.095-1.278 1.211-4.997 4.925-2.645 2.642-4.837 4.851-4.872 4.91-.091.153-.846 3.17-.846 3.379 0 .208.109.403.285.513.241.15.258.148 2.014-.292.857-.214 1.595-.407 1.64-.429.044-.021 2.277-2.237 4.962-4.925 4.492-4.497 4.89-4.902 4.992-5.087a2.257 2.257 0 0 0 .138-1.968A2.219 2.219 0 0 0 13.29.946a3.104 3.104 0 0 0-.933 0m.876 1.326c.188.088.367.268.465.469.066.133.075.183.075.393 0 .453.448-.033-4.885 5.296l-4.715 4.71-.876.218c-.483.119-.883.211-.891.204a14.02 14.02 0 0 1 .206-.89l.219-.876 4.738-4.735c2.606-2.605 4.768-4.751 4.804-4.77a1.06 1.06 0 0 1 .86-.019M7.466 13.838a.65.65 0 0 0-.247 1.12c.206.171.018.163 3.876.156l3.505-.007.107-.058a.666.666 0 0 0 .37-.582.581.581 0 0 0-.18-.449c-.214-.22.072-.205-3.868-.202-1.923.001-3.526.011-3.563.022"
        fill="#5B5F73"
        fillRule="evenodd"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconPen;
