import { bindActionCreators } from 'redux';
import { saveDataCase } from './saveData';
import * as actions from '../actions';
import * as getters from '../getters';
import { clearDataCase } from './clearData';
import { setReduxDataToChatInputCase } from './setReduxDataToChatInput';
import { getOpenedChatId } from '../../../getter';
import { applyState } from '../../utils';

export const clearChatInputDataFromStorage = (chatId) => (dispatch) =>
  clearDataCase(
    {
      clearData: bindActionCreators(actions.clearChatInput, dispatch)
    },
    { chatId }
  );

export const saveChatInputDataToStorage = (data) => (dispatch) =>
  saveDataCase(
    {
      setData: bindActionCreators(actions.saveChatInput, dispatch),
      clearData: bindActionCreators(clearChatInputDataFromStorage, dispatch)
    },
    { data }
  );

export const setReduxDataToChatInput = () => (dispatch, getState) =>
  setReduxDataToChatInputCase({
    getMsgText: (chatId) => getters.getChatInputItemText(getState(), chatId),
    getOpenedChatId: applyState(getState, getOpenedChatId),
    clearData: bindActionCreators(clearChatInputDataFromStorage, dispatch)
  });
