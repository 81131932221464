import { assoc } from 'ramda';
import { SAVE_CLIENT_DATA } from './constants';
import { makeClientData, updateClientData } from './dataType';

export const defaultState = () => ({
  clientData: makeClientData()
});

const setClientData = (chatWidget, clientData) =>
  assoc('clientData', clientData, chatWidget);

export const reducers = {
  [SAVE_CLIENT_DATA]: (chatWidget, { chatId, data }) =>
    setClientData(
      chatWidget,
      updateClientData(chatWidget.clientData, chatId, data)
    )
};
