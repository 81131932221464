import React from 'react';

/* eslint-disable max-len */
const IconCancelCircle = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      className="cws-icon-hover"
      cx="17"
      cy="17"
      r="17"
      fill="#767B92"
      fillOpacity="0"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 25C21.4183 25 25 21.4183 25 17C25 12.5817 21.4183 9 17 9C12.5817 9 9 12.5817 9 17C9 21.4183 12.5817 25 17 25ZM17 26C21.9706 26 26 21.9706 26 17C26 12.0294 21.9706 8 17 8C12.0294 8 8 12.0294 8 17C8 21.9706 12.0294 26 17 26Z"
      fill="#252B49"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6465 22.6464L22.6465 10.6464L23.3536 11.3536L11.3536 23.3536L10.6465 22.6464Z"
      fill="#252B49"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconCancelCircle;
