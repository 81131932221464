import React from 'react';

/* eslint-disable max-len */
const IconRemoveM = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      className="cws-icon-hover"
      width="28"
      height="28"
      rx="14"
      fill="#767B92"
      fillOpacity="0"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0001 14.5002C20.0001 14.7764 19.7762 15.0002 19.5001 15.0002C12.352 15.0003 15.3102 15.0003 8.5 15.0003C8.22386 15.0003 8 14.7764 8 14.5003C8 14.2242 8.22385 14.0003 8.5 14.0003C15.648 14.0003 12.6817 14.0003 19.5001 14.0002C19.7762 14.0002 20.0001 14.2241 20.0001 14.5002Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconRemoveM;
