import { makeBlockChatInputStorage, removeData, setData } from './dataType';
import { REDUX_KEY, SAVE_CHAT_INPUT, CLEAR_CHAT_INPUT } from './constants';

export const defaultState = () => ({
  [REDUX_KEY]: makeBlockChatInputStorage()
});

export const reducers = {
  [SAVE_CHAT_INPUT]: (state, payload) =>
    setData(state, payload.chatId, payload.data),
  [CLEAR_CHAT_INPUT]: (state, payload) => removeData(state, payload.chatId)
};
