import {
  makeModalManager,
  show,
  showLast,
  hide
} from 'core/data/light/modalManager';

export default (state = makeModalManager(), action) => {
  switch (action.type) {
    case 'SHOW_MODAL':
      return show(state, action.name, action.props);
    case 'HIDE_MODAL':
      return hide(state);
    case 'SHOW_PREVIOUS_MODAL':
      return showLast(state);
    default:
      return state;
  }
};
