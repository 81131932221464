import {
  SET_MESSAGES,
  ADD_OR_UPDATE_LAST_UNREAD_MESSAGE,
  CLEAR_LAST_UNREAD_MESSAGE,
  CLEAR_SELECTED_MSGS,
  SELECT_MESSAGE,
  SET_EDIT_MSG,
  SET_MSG_TO_EDITOR
} from './constants';

export const setMessages = (list, amountLeft) => ({
  type: SET_MESSAGES,
  payload: { list, amountLeft }
});

export const updateLastUnreadMsg = (item) => ({
  type: ADD_OR_UPDATE_LAST_UNREAD_MESSAGE,
  payload: item
});

export const removeLastUnreadMsg = (channelId) => ({
  type: CLEAR_LAST_UNREAD_MESSAGE,
  payload: channelId
});

export const selectMessage = (messageId) => ({
  type: SELECT_MESSAGE,
  payload: { messageId }
});

export const setMsgToEditor = (msg) => ({
  type: SET_MSG_TO_EDITOR,
  payload: msg
});

export const setEditingMessage = (data) => ({
  type: SET_EDIT_MSG,
  payload: data
});

export const clearSelectedMessages = () => ({ type: CLEAR_SELECTED_MSGS });
