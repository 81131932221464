import iconsView from './constants';
/* eslint-disable */

import Trash from './Trash';
import ArrowDown from './ArrowDown';
import Reset from './Reset';
import Filter from './Filter';
import CloseLarge from './CloseLarge';
import Select from './Select';
import Information from './Information';

export const Icons = {
  [iconsView.Trash]: Trash,
  [iconsView.ArrowDown]: ArrowDown,
  [iconsView.Reset]: Reset,
  [iconsView.Filter]: Filter,
  [iconsView.CloseLarge]: CloseLarge,
  [iconsView.Select]: Select,
  [iconsView.Information]: Information
};
/* eslint-enable */
