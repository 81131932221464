import { logUseCase } from '../../../../../utils/logger';
import { isSearchQueryEmpty } from '../../../data-type/block-advanced-search';
import { repeatSearch } from '../../../sidebar/AdvancedSearch/messages';

const log = logUseCase.extend('updateDateAfterToggleBlockCase');

export const updateDateAfterToggleBlockCase = async (
  {
    getAdvancedSearch,
    getChatContact,
    getDialogInfo,
    getProfile,
    updateContact,
    setDialogInfo,
    showProfile
  },
  { employeeId, isBlockedContact }
) => {
  const dialogInfo = getDialogInfo();
  const contact = getChatContact().list.find(
    (user) => user.employeeId === employeeId
  );

  updateContact({ ...contact, isBlockedContact });
  if (dialogInfo && dialogInfo.employeeId === employeeId) {
    log(`dialogInfo.employeeId = employeeId`);
    setDialogInfo({ ...dialogInfo, isBlockedContact });
  }

  // Upd contact in result search after toggle block
  const search = getAdvancedSearch();

  if (!isSearchQueryEmpty(search)) {
    log(`Advanced search is work`);
    repeatSearch.emit();
  }
};
