.root {
  position: fixed;
  z-index: 1299;
  bottom: 42px;
  right: 42px;
  max-height: 400px;
  min-width: 280px;
  max-width: 280px;
  border-radius: 8px;
  padding: 24px;
  overflow: hidden;
  background: var(--color-white);
  box-shadow: 0 8px 24px 0 #1e222e1f, 0 4px 8px #1e222e0a;
}

.closeBtn {
  position: absolute;
  right: var(--indent-md);
  top: var(--indent-md);
}

.header {
  box-sizing: border-box;

  .headerText {
    color: var(--color-gray-900);
    max-width: 100%;
  }
}

.footer {
  padding: 0 var(--indent-md);
  display: flex;
  align-items: center;
  column-gap: var(--indent-s);
  row-gap: var(--indent-s);
  flex-wrap: wrap;

  &.right {
    justify-content: right;
  }

  &.center {
    justify-content: center;
  }

  &.left {
    justify-content: left;
  }

  &.space-between {
    justify-content: space-between;
  }
}

.content {
  box-sizing: border-box;
  padding: 0 var(--indent-md);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  color: var(--color-gray-900);
}

.content::-webkit-scrollbar {
  width: 8px;
  background-color: var(--color-gray-200);
}

.content::-webkit-scrollbar-thumb {
  background: rgb(12 35 98 / 20%);
  border-radius: 13px;
}

.actions {
  display: flex;
  align-items: center;
  gap: var(--indent-s);
}
