import { defRecord } from 'core/lib';

const { makeBlockList } = defRecord('BlockList', ['list'], { list: {} });

const { makeScrollPosition } = defRecord(
  'ScrollPosition',
  ['message', 'scrollByTop'],
  {
    message: null,
    scrollByTop: -1
  }
);

export { makeBlockList, makeScrollPosition };
