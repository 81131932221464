import { assoc, assocPath } from 'ramda';
import {
  ADD_ERROR,
  ADD_LAST_UNREAD_MESSAGES,
  ADD_LOADING_FILE_ID_INPUT_BLOCK,
  ADD_OR_UPDATE_LAST_UNREAD_MESSAGE,
  ADD_OR_UPDATE_MESSAGE,
  ADD_OR_UPDATE_TEMP_INPUT,
  ADD_REPLY_MSG,
  CLEAR_ERRORS,
  CLEAR_INPUT_BLOCK,
  CLEAR_LAST_UNREAD_MESSAGE,
  CLEAR_REPLY_MSG,
  CLEAR_REPLY_MSG_BY_CHAT,
  CLEAR_SELECTED_MSGS,
  HIDE,
  HIDE_ADVANCED_SEARCH,
  HIDE_INPUT_BLOCK,
  REMOVE_INPUT_FROM_TEMP,
  REMOVE_LOADING_FILE_ID_INPUT_BLOCK,
  REMOVE_MESSAGE,
  REMOVE_UNSENT_MSG,
  SELECT_MESSAGE,
  SET_ADVANCED_SEARCH,
  SET_ADVANCED_SEARCH_GLOBAL_SEARCH,
  SET_ADVANCED_SEARCH_PARAM,
  SET_ADVANCED_SEARCH_RESULT,
  SET_ADVANCED_SEARCH_RESULT_PART,
  SET_CURRENT_EMPLOYEE_ID,
  SET_EDIT_MSG,
  SET_ERRORS,
  SET_FAVOTIRE_MSGS,
  SET_FILES_INPUT_BLOCK,
  SET_FILTER,
  SET_GROUP_CONTACT,
  SET_GROUP_EDIT,
  SET_HEADER_VIEW,
  SET_INPUT_BLOCK,
  SET_LOADING_FILES_IDS_INPUT_BLOCK,
  SET_MESSAGES,
  SET_MESSAGES_LOADED,
  SET_MESSAGES_MANY,
  SET_MESSAGES_NEXT,
  SET_MODE,
  SET_OPEN_CHAT,
  SET_REPLY_MSGS,
  SET_SEARCH_MESSAGES,
  SET_TAB,
  SET_TEMP_BLOCK,
  SET_TEXT_INPUT_BLOCK,
  SET_UNSENT_MSG,
  SHOW,
  SHOW_ADVANCED_SEARCH,
  SHOW_INPUT_BLOCK,
  UPDATE_FAVOTIRE_MSG,
  UPDATE_LAST_MESSAGE,
  UPDATE_MESSAGE,
  UPDATE_TYPING_INFO
} from './constants';
import { updateItem } from './data-type/block-list';
import { makeMode } from './data-type/mode';
import { makeBlockGroup } from './data-type/block-group';
import {
  addLoadingFileId,
  hide as hideInput,
  makeBlockInput,
  removeLoadingFileId,
  setFiles,
  setLoadingFilesIds,
  setText,
  show as showInput
} from './data-type/block-input';
import {
  addOrUpdateItem,
  makeBLWithSelect,
  removeItem,
  selectOrUnselectId,
  setAmountLeft,
  setList as setMsgsList,
  setSelectedIds,
  updateItem as updateItemSelect
} from './data-type/block-list-with-select';
import {
  addOrUpdateUnsentList,
  makeListsOfBLUnsent,
  removeFromUnsentList
} from './data-type/block-list-unsent';
import { makeBlockEditMsg } from './data-type/block-msg-edit';
import { makeBlockGroupEdit } from './data-type/block-group-edit';
import {
  constr,
  setAmountLeftNext,
  setAmountLeftPrev,
  setInitTopItemIndex,
  withAmountLeftNext,
  withAmountLeftPrev,
  withInitTopItemIndex,
  withList,
  withSelectedIds,
  withSubList
} from './data-type/lists-kit';
import {
  addOrUpdateToTempInput,
  makeBlockTemp,
  removeInputFromTemp
} from './data-type/block-temp';
import {
  hideAdvancedSearch,
  makeBlockAdvancedSearch,
  setASBlockGlobalSearch,
  setASBlockParams,
  setASBlockResults,
  setASBlockResultsPart,
  showAdvancedSearch
} from './data-type/block-advanced-search';
import { addError, clearErrors } from './data-type/errors';
import {
  addLastUnreadMessages,
  addOrUpdateLastUnreadMessage,
  clearLastUnreadMessageByChatId,
  makeBlockLastUnreadMessages
} from './data-type/block-last-unread-messages';
import {
  addReplyMessage,
  clearReplyMessageByChatId,
  clearReplyMessageById,
  makeBlockReplyMsg
} from './data-type/block-msg-reply';
import {
  defaultState as draftMessageState,
  reducers as draftMessageReducers
} from './modules/draftMessages/reducers';
import {
  defaultState as contactsState,
  reducers as contactsReducers
} from './modules/contacts/reducers';
import {
  defaultState as contactsCategoriesState,
  reducers as contactsCategoriesReducers
} from './modules/categories/contacts/reducers';
import {
  defaultState as chatCategoriesState,
  reducers as chatCategoriesReducers
} from './modules/categories/chats/reducers';
import {
  defaultState as queryState,
  reducers as queryReducers
} from './modules/query/reducers';
import {
  defaultState as profileState,
  reducers as profileReducers
} from './modules/profile/reducers';
import {
  defaultState as dialogInfoState,
  reducers as dialogInfoReducers
} from './modules/dialogInfo/reducers';
import {
  defaultState as badgesState,
  reducers as badgesReducers
} from './modules/badges/reducers';
import {
  defaultState as chatsState,
  reducers as chatsReducers
} from './modules/chats/reducers';
import {
  defaultState as clientsChatsDataState,
  reducers as clientsChatsDataReducers
} from './modules/clientData/reducers';
import {
  defaultState as forwardMessageState,
  reducers as forwardMessageReducers
} from './modules/forwardMessages/reducers';
import {
  defaultState as scrollPositionState,
  reducers as scrollPositionReducers
} from './modules/scrollPosition/reducers';
import {
  defaultState as waitingMessagesState,
  reducers as waitingMessagesReducers
} from './modules/waitingMessages/reducers';
import {
  defaultState as navSidebarState,
  reducers as navSidebarReducers
} from '../MessengerNavSidebar/reducers';
import {
  defaultState as showPurchaseRequestsState,
  reducers as showPurchaseRequestsReducers
} from './PurchaseRequestsFilter/reducers';
import {
  initState as initStatePRWR,
  reducers as reducersPRWR
} from './modules/purchaseRequestWithResponse/redux-storage';
import {
  initState as initStatePRWRC,
  reducers as reducersPRWRC
} from './modules/purchaseRequestWithResponseCompany/redux-storage';
import {
  defaultState as chatInputStorageState,
  reducers as chatInputStorageReducers
} from './modules/chatInputStorage/reducers';
import {
  defaultState as companiesState,
  reducers as companiesReducers
} from './modules/companies/reducer';
import {
  defaultState as constructorState,
  reducers as constructorReducers
} from './modules/constructor/reducer';

import {
  defaultState as delayedMessagesState,
  reducers as delayedMessagesReducers
} from './modules/delayedMessages/reducer';

const limitMsgsLoad = 40;
const makeBlockMessages = () =>
  constr(
    withList(),
    withSubList(),
    withAmountLeftNext(),
    withAmountLeftPrev(),
    withSelectedIds(),
    withInitTopItemIndex(limitMsgsLoad - 1)
  );

const defaultState = () => ({
  shown: false,
  currentEmployeeId: null,
  mode: makeMode(),
  activeTab: '',
  sidebarHeaderView: 'dashboard',
  typingInfo: {},
  errors: [],
  filter: {
    contact: { icon: 'without', value: 'all-contacts' },
    chat: { icon: 'without', value: 'all-chats' },
    archive: { icon: 'without', value: 'all-chats' }
  },
  groupBy: '',
  blockAdvancedSearch: makeBlockAdvancedSearch(),
  blockGroupChats: makeBlockGroup(),
  blockGroupEdit: makeBlockGroupEdit(),
  blockInput: makeBlockInput(),
  blockTemp: makeBlockTemp(),
  blockMessages: makeBlockMessages(),
  blockSearchMsgs: makeBLWithSelect(),
  blockEdit: makeBlockEditMsg(),
  blockReply: makeBlockReplyMsg(),
  blockUnsent:
    JSON.parse(localStorage.getItem('unsentMessages')) || makeListsOfBLUnsent(),
  blockLastUnreadMessages: makeBlockLastUnreadMessages(),
  favoriteMessages: makeBLWithSelect(),
  ...queryState(),
  ...draftMessageState(),
  ...contactsState(),
  ...chatCategoriesState(),
  ...profileState(),
  ...dialogInfoState(),
  ...badgesState(),
  ...chatsState(),
  ...contactsCategoriesState(),
  ...clientsChatsDataState(),
  ...forwardMessageState(),
  ...scrollPositionState(),
  ...waitingMessagesState(),
  ...navSidebarState(),
  ...showPurchaseRequestsState(),
  ...initStatePRWR,
  ...initStatePRWRC,
  ...chatInputStorageState(),
  ...companiesState(),
  ...constructorState(),
  ...delayedMessagesState()
});

const setOpenChat = (chatWidget, chat) => assoc('openedChat', chat, chatWidget);
const setBGEdit = (chatWidget, value) =>
  assoc('blockGroupEdit', value, chatWidget);
const setBlockInput = (chatWidget, value) =>
  assoc('blockInput', value, chatWidget);
const setBlockTemp = (chatWidget, value) =>
  assoc('blockTemp', value, chatWidget);
const setBlockMsgs = (chatWidget, value) =>
  assoc('blockMessages', value, chatWidget);
const setBlockMessagesLoaded = (chatWidget, isLoaded) =>
  assocPath(['blockMessages', 'isLoaded'], isLoaded, chatWidget);
const setBlockSerarchMsgs = (chatWidget, value) =>
  assoc('blockSearchMsgs', value, chatWidget);
const setBlockUnsent = (chatWidget, value) =>
  assoc('blockUnsent', value, chatWidget);
const setFavotireMsgs = (chatWidget, data) =>
  assoc('favoriteMessages', data, chatWidget);
const setSidebarHeaderView = (chatWidget, value) =>
  assoc('sidebarHeaderView', value, chatWidget);
const setEditMsg = (chatWidget, data) => assoc('blockEdit', data, chatWidget);
const setASBlock = (chatWidget, data) =>
  assoc('blockAdvancedSearch', data, chatWidget);
const setErrors = (chatWidget, errors) => assoc('errors', errors, chatWidget);
const setLastUnreadMessages = (chatWidget, lastUnreadBlock) =>
  assoc('blockLastUnreadMessages', lastUnreadBlock, chatWidget);
const setReplyMsgs = (chatWidget, messages) =>
  assoc('blockReply', messages, chatWidget);
const setLastMessage = (chatWidget, updatedBlock) =>
  assoc('blockChat', updatedBlock, chatWidget);

const updateTypingInfo = (chatWidget, { id, userName, value, employeeId }) => {
  const { typingInfo: tInfo } = chatWidget;
  const typingInfo = { ...tInfo };
  if (!typingInfo[id]) {
    typingInfo[id] = [];
  }
  let typingList = typingInfo[id];
  if (value === true) {
    const alreadyInList = !!typingList.find((i) => i.employeeId === employeeId);
    if (!alreadyInList) {
      typingList.push({ userName, employeeId });
    }
  } else {
    typingList = typingList.filter((i) => i.employeeId !== employeeId);
    if (typingList.length === 0) {
      delete typingInfo[id];
      return assoc('typingInfo', typingInfo, chatWidget);
    }
  }
  typingInfo[id] = typingList;
  return assoc('typingInfo', typingInfo, chatWidget);
};

const indexByLength = (length) => length - 1;
const calcInitTopItemIndex = (list) => {
  if (indexByLength(limitMsgsLoad) > indexByLength(list.length)) {
    return Math.max(indexByLength(list.length), 0);
  }
  return indexByLength(limitMsgsLoad);
};

const reducers = {
  [SHOW]: (chatWidget) => assoc('shown', true, chatWidget),
  [HIDE]: (chatWidget) => assoc('shown', false, chatWidget),
  [SET_TAB]: (chatWidget, { tab }) => assoc('activeTab', tab, chatWidget),
  [SET_MODE]: (chatWidget, mode) => assoc('mode', mode, chatWidget),
  [SET_FILTER]: (chatWidget, { name, value }) =>
    assocPath(['filter', name], value, chatWidget),
  [SET_GROUP_CONTACT]: (chatWidget, { value }) =>
    assoc('groupBy', value, chatWidget),

  [SET_OPEN_CHAT]: (chatWidget, chat) => setOpenChat(chatWidget, chat),

  [SET_MESSAGES]: (
    chatWidget,
    {
      list,
      amountLeft = 0,
      amountLeftNext = chatWidget.blockMessages.amountLeftNext,
      cancelLoad
    }
  ) =>
    setBlockMsgs(chatWidget, {
      ...setInitTopItemIndex(
        calcInitTopItemIndex(list),
        setAmountLeftNext(
          amountLeftNext,
          setAmountLeftPrev(
            amountLeft,
            setMsgsList(chatWidget.blockMessages, list)
          )
        )
      ),
      cancelLoad
    }),
  [SET_MESSAGES_LOADED]: (chatWidget, { isLoaded }) =>
    setBlockMessagesLoaded(chatWidget, isLoaded),
  [SET_MESSAGES_NEXT]: (chatWidget, { list, amountLeft = 0 }) =>
    setBlockMsgs(
      chatWidget,
      setAmountLeftNext(amountLeft, setMsgsList(chatWidget.blockMessages, list))
    ),
  [SET_MESSAGES_MANY]: (
    chatWidget,
    { list, amountLeftNext, amountLeftPrev, initTopItemIndex }
  ) =>
    setBlockMsgs(
      chatWidget,
      setInitTopItemIndex(
        initTopItemIndex,
        setAmountLeftPrev(
          amountLeftPrev,
          setAmountLeftNext(
            amountLeftNext,
            setMsgsList(chatWidget.blockMessages, list)
          )
        )
      )
    ),
  [ADD_OR_UPDATE_MESSAGE]: (chatWidget, { msg }) =>
    setBlockMsgs(chatWidget, addOrUpdateItem(chatWidget.blockMessages, msg)),
  [UPDATE_MESSAGE]: (chatWidget, { msg }) =>
    setBlockMsgs(chatWidget, updateItemSelect(chatWidget.blockMessages, msg)),
  [REMOVE_MESSAGE]: (chatWidget, { messageId }) =>
    setBlockMsgs(chatWidget, removeItem(chatWidget.blockMessages, messageId)),
  [SELECT_MESSAGE]: (chatWidget, { messageId }) =>
    setBlockMsgs(
      chatWidget,
      selectOrUnselectId(chatWidget.blockMessages, messageId)
    ),

  [CLEAR_SELECTED_MSGS]: (chatWidget) =>
    setBlockMsgs(chatWidget, setSelectedIds(chatWidget.blockMessages, [])),

  [SET_SEARCH_MESSAGES]: (chatWidget, { list, amountLeft = 0 }) =>
    setBlockSerarchMsgs(
      chatWidget,
      setAmountLeft(setMsgsList(chatWidget.blockMessages, list), amountLeft)
    ),

  [SET_UNSENT_MSG]: (chatWidget, { chatId, employeeId, message }) => {
    let bUnsent = chatWidget.blockUnsent[employeeId] || {};
    bUnsent = addOrUpdateUnsentList(bUnsent, chatId, message);
    const updatedBlockUnsent = assoc(
      employeeId,
      bUnsent,
      chatWidget.blockUnsent
    );
    return setBlockUnsent(chatWidget, updatedBlockUnsent);
  },

  [REMOVE_UNSENT_MSG]: (
    chatWidget,
    { chatId, employeeId, message, deliveryId }
  ) => {
    let bUnsent = chatWidget.blockUnsent[employeeId] || {};
    bUnsent = removeFromUnsentList(bUnsent, chatId, message, deliveryId);
    const updatedBlockUnsent = assoc(
      employeeId,
      bUnsent,
      chatWidget.blockUnsent
    );
    return setBlockUnsent(chatWidget, updatedBlockUnsent);
  },

  [SET_GROUP_EDIT]: (chatWidget, blockGroups) =>
    setBGEdit(chatWidget, blockGroups),

  [SET_INPUT_BLOCK]: (chatWidget, blockInput) =>
    setBlockInput(chatWidget, blockInput),
  [SET_TEXT_INPUT_BLOCK]: (chatWidget, { text }) =>
    setBlockInput(chatWidget, setText(chatWidget.blockInput, text)),
  [SET_FILES_INPUT_BLOCK]: (chatWidget, { files }) =>
    setBlockInput(chatWidget, setFiles(chatWidget.blockInput, files)),
  [SET_LOADING_FILES_IDS_INPUT_BLOCK]: (chatWidget, { ids }) =>
    setBlockInput(chatWidget, setLoadingFilesIds(chatWidget.blockInput, ids)),
  [ADD_LOADING_FILE_ID_INPUT_BLOCK]: (chatWidget, { id }) =>
    setBlockInput(chatWidget, addLoadingFileId(chatWidget.blockInput, id)),
  [REMOVE_LOADING_FILE_ID_INPUT_BLOCK]: (chatWidget, { id }) =>
    setBlockInput(chatWidget, removeLoadingFileId(chatWidget.blockInput, id)),
  [HIDE_INPUT_BLOCK]: (chatWidget) =>
    setBlockInput(chatWidget, hideInput(chatWidget.blockInput)),
  [SHOW_INPUT_BLOCK]: (chatWidget) =>
    setBlockInput(chatWidget, showInput(chatWidget.blockInput)),
  [CLEAR_INPUT_BLOCK]: (chatWidget) =>
    setBlockInput(chatWidget, makeBlockInput()),

  [SET_TEMP_BLOCK]: (chatWidget, blockTemp) =>
    setBlockTemp(chatWidget, blockTemp),
  [ADD_OR_UPDATE_TEMP_INPUT]: (chatWidget, { chatId, input }) =>
    setBlockTemp(
      chatWidget,
      addOrUpdateToTempInput(chatWidget.blockTemp, chatId, input)
    ),
  [REMOVE_INPUT_FROM_TEMP]: (chatWidget, { chatId }) =>
    setBlockTemp(chatWidget, removeInputFromTemp(chatWidget.blockTemp, chatId)),

  [SET_FAVOTIRE_MSGS]: (chatWidget, fmessages) =>
    setFavotireMsgs(chatWidget, fmessages),
  [UPDATE_FAVOTIRE_MSG]: (chatWidget, fmessage) =>
    setFavotireMsgs(
      chatWidget,
      updateItem(chatWidget.favoriteMessages, fmessage)
    ),

  [SET_HEADER_VIEW]: (chatWidget, view) =>
    setSidebarHeaderView(chatWidget, view),

  [SET_EDIT_MSG]: (chatWidget, { message, chatId }) =>
    setEditMsg(chatWidget, makeBlockEditMsg({ message, chatId })),

  [UPDATE_TYPING_INFO]: (chatWidget, data) =>
    updateTypingInfo(chatWidget, data),

  [SET_ADVANCED_SEARCH]: (chatWidget, blockSearch) =>
    setASBlock(chatWidget, blockSearch),
  [SHOW_ADVANCED_SEARCH]: (chatWidget) =>
    setASBlock(chatWidget, showAdvancedSearch(chatWidget.blockAdvancedSearch)),
  [HIDE_ADVANCED_SEARCH]: (chatWidget) =>
    setASBlock(chatWidget, hideAdvancedSearch(chatWidget.blockAdvancedSearch)),
  [SET_ADVANCED_SEARCH_PARAM]: (chatWidget, { key, value }) =>
    setASBlock(
      chatWidget,
      setASBlockParams(chatWidget.blockAdvancedSearch, key, value)
    ),
  [SET_ADVANCED_SEARCH_RESULT]: (chatWidget, results) =>
    setASBlock(
      chatWidget,
      setASBlockResults(chatWidget.blockAdvancedSearch, results)
    ),
  [SET_ADVANCED_SEARCH_RESULT_PART]: (chatWidget, payload) =>
    setASBlock(
      chatWidget,
      setASBlockResultsPart(chatWidget.blockAdvancedSearch, payload)
    ),
  [SET_ADVANCED_SEARCH_GLOBAL_SEARCH]: (chatWidget, globalSearch) =>
    setASBlock(
      chatWidget,
      setASBlockGlobalSearch(chatWidget.blockAdvancedSearch, globalSearch)
    ),

  [SET_ERRORS]: (chatWidget, errors) => setErrors(chatWidget, errors),
  [ADD_ERROR]: (chatWidget, error) =>
    setErrors(chatWidget, addError(chatWidget.errors, error)),
  [CLEAR_ERRORS]: (chatWidget) =>
    setErrors(chatWidget, clearErrors(chatWidget.errors)),

  [ADD_LAST_UNREAD_MESSAGES]: (chatWidget, lastUnreadMessages) =>
    setLastUnreadMessages(
      chatWidget,
      addLastUnreadMessages(
        chatWidget.blockLastUnreadMessages,
        lastUnreadMessages
      )
    ),

  [ADD_OR_UPDATE_LAST_UNREAD_MESSAGE]: (chatWidget, lastUnreadMessage) =>
    setLastUnreadMessages(
      chatWidget,
      addOrUpdateLastUnreadMessage(
        chatWidget.blockLastUnreadMessages,
        lastUnreadMessage
      )
    ),
  [CLEAR_LAST_UNREAD_MESSAGE]: (chatWidget, channelId) =>
    setLastUnreadMessages(
      chatWidget,
      clearLastUnreadMessageByChatId(
        chatWidget.blockLastUnreadMessages,
        channelId
      )
    ),

  [SET_REPLY_MSGS]: (chatWidget, messages) =>
    setReplyMsgs(chatWidget, messages),
  [ADD_REPLY_MSG]: (chatWidget, message) =>
    setReplyMsgs(chatWidget, addReplyMessage(chatWidget.blockReply, message)),
  [CLEAR_REPLY_MSG]: (chatWidget, messageId) =>
    setReplyMsgs(
      chatWidget,
      clearReplyMessageById(chatWidget.blockReply, messageId)
    ),
  [CLEAR_REPLY_MSG_BY_CHAT]: (chatWidget, chatId) =>
    setReplyMsgs(
      chatWidget,
      clearReplyMessageByChatId(chatWidget.blockReply, chatId)
    ),
  [UPDATE_LAST_MESSAGE]: (chatWidget, block) =>
    setLastMessage(chatWidget, block),
  [SET_CURRENT_EMPLOYEE_ID]: (chatWidget, employeeId) =>
    assoc('currentEmployeeId', employeeId, chatWidget),

  ...queryReducers,
  ...draftMessageReducers,
  ...contactsReducers,
  ...chatCategoriesReducers,
  ...profileReducers,
  ...dialogInfoReducers,
  ...badgesReducers,
  ...chatsReducers,
  ...contactsCategoriesReducers,
  ...clientsChatsDataReducers,
  ...forwardMessageReducers,
  ...scrollPositionReducers,
  ...waitingMessagesReducers,
  ...navSidebarReducers,
  ...showPurchaseRequestsReducers,
  ...reducersPRWR,
  ...reducersPRWRC,
  ...chatInputStorageReducers,
  ...companiesReducers,
  ...constructorReducers,
  ...delayedMessagesReducers
};

export default (chatWidget = defaultState(), { type, payload }) => {
  if (reducers[type]) return reducers[type](chatWidget, payload);
  return chatWidget;
};
