import { useCallback, useState } from 'react';

export const useUpdateKey = (): [number, () => void] => {
  const [value, setValue] = useState(1);

  const increment = useCallback(() => {
    setValue((prevState) => prevState + 1);
  }, []);

  return [value, increment];
};
