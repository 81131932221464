import { Map, List, fromJS } from 'immutable';
import {
  BLOCK_CONTACT_TOGGLE,
  BLOCK_ME_TOGGLE
} from '../modules/contact/constants';

const initialState = Map({
  contacts: List(),
  contactsForChannel: List(),
  lists: List(),
  checked: List(),
  contactInfo: Map(),
  search: '',
  size: 0,
  limit: 9999,
  page: 0,
  isEmpty: false
});

// Contacts for channel reducers
// ----------------------------------------------------------------------------------
const setContactForChannel = (state, { contacts = [] } = {}) =>
  state.set('contactsForChannel', fromJS(contacts));

const clearContactForChannel = (state) =>
  state.set('contactsForChannel', List());

// Contacts reducers
// ----------------------------------------------------------------------------------
const setContacts = (state, { contacts, size, limit, page, isEmpty }) =>
  state
    .set('contacts', fromJS(contacts))
    .set('size', size)
    .set('limit', limit)
    .set('page', page)
    .set('isEmpty', isEmpty);

const mergeContacts = (state, { contacts, size, limit, page, isEmpty }) => {
  const mergedContacts = state.get('contacts').concat(fromJS(contacts));

  return state
    .set('contacts', mergedContacts)
    .set('size', size)
    .set('limit', limit)
    .set('page', page)
    .set('isEmpty', isEmpty);
};

const clearContacts = (state) => state.set('contacts', List());

const setContactsSearch = (state, { search }) => state.set('search', search);

// Contact lists reducers
// ----------------------------------------------------------------------------------
const setContactsLists = (state, { lists, checked }) =>
  state.set('lists', fromJS(lists)).set('checked', fromJS(checked));

const addContactList = (state, { id, name }) =>
  state
    .update('lists', (lists) =>
      lists.push(fromJS({ id, name, system: false, translate: false }))
    )
    .update('contacts', (contacts) =>
      contacts.map((contact) =>
        contact.update('lists', (lists) =>
          lists.push(
            fromJS({
              id,
              name,
              system: false,
              translate: false,
              checked: false
            })
          )
        )
      )
    );

const editContactList = (state, { id, name }) =>
  state
    .update('lists', (lists) =>
      lists.map((list) =>
        list.get('id') === id ? list.set('name', name) : list
      )
    )
    .update('contacts', (contacts) =>
      contacts.map((contact) =>
        contact.update('lists', (lists) =>
          lists.map((list) =>
            list.get('id') === id ? list.set('name', name) : list
          )
        )
      )
    );

const deleteContactList = (state, { id }) =>
  state
    .update('lists', (lists) => lists.filter((list) => list.get('id') !== id))
    .update('contacts', (contacts) =>
      contacts.map((contact) =>
        contact.update('lists', (lists) =>
          lists.filter((list) => list.get('id') !== id)
        )
      )
    )
    .update('checked', (checked) =>
      checked.includes(id) ? checked.remove(checked.indexOf(id)) : checked
    );

const addMemberToContactList = (state, { memberId, listId }) =>
  state.update('contacts', (contacts) =>
    contacts.map((contact) =>
      contact.get('userId') === memberId
        ? contact.update('lists', (lists) =>
            lists.map((list) =>
              list.get('id') === listId ? list.set('checked', true) : list
            )
          )
        : contact
    )
  );

const removeMemberFromContactList = (state, { memberId, listId }) =>
  state.update('contacts', (contacts) =>
    contacts.map((contact) =>
      contact.get('userId') === memberId
        ? contact.update('lists', (lists) =>
            lists.map((list) =>
              list.get('id') === listId ? list.set('checked', false) : list
            )
          )
        : contact
    )
  );

const toggleContactList = (state, { id }) =>
  state.update('checked', (checked) =>
    checked.includes(id)
      ? checked.remove(checked.indexOf(id))
      : checked.push(id)
  );

const clearContactsLists = (state) => state.set('lists', List());

const setContactInformation = (state, contact) =>
  state.set('contactInfo', fromJS(contact));
const clearContactInformation = (state) => state.set('contactInfo', Map());

const setIsBlockedContact = (state, { employeeId, isBlocked }) => {
  if (state.getIn(['contactInfo', 'employeeId']) === employeeId) {
    return state.setIn(['contactInfo', 'isBlockedContact'], isBlocked);
  }
  return state;
};

const setIsBlockedMe = (state, { employeeId, isBlocked }) => {
  if (state.getIn(['contactInfo', 'employeeId']) === employeeId) {
    return state.setIn(['contactInfo', 'isBlockedMe'], isBlocked);
  }
  return state;
};

const contactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CONTACTS_FOR_CHANNEL':
      return setContactForChannel(state, action.payload);
    case 'CLEAR_CONTACTS_FOR_CHANNEL':
      return clearContactForChannel(state);
    case 'SET_CONTACTS':
      return setContacts(state, action.payload);
    case 'MERGE_CONTACTS':
      return mergeContacts(state, action.payload);
    case 'CONTACTS:SET_CONTACT_INFORMATION':
      return setContactInformation(state, action.payload);
    case 'CONTACTS:CLEAR_CONTACT_INFORMATION':
      return clearContactInformation(state);
    case 'CLEAR_CONTACTS':
      return clearContacts(state);
    case 'SET_CONTACTS_SEARCH':
      return setContactsSearch(state, action.payload);
    case 'SET_CONTACTS_LISTS':
      return setContactsLists(state, action.payload);
    case 'ADD_CONTACT_LIST':
      return addContactList(state, action.payload);
    case 'EDIT_CONTACT_LIST':
      return editContactList(state, action.payload);
    case 'DELETE_CONTACT_LIST':
      return deleteContactList(state, action.payload);
    case 'ADD_MEMBER_TO_CONTACT_LIST':
      return addMemberToContactList(state, action.payload);
    case 'REMOVE_MEMBER_FROM_CONTACT_LIST':
      return removeMemberFromContactList(state, action.payload);
    case 'TOGGLE_CONTACT_LIST':
      return toggleContactList(state, action.payload);
    case 'CLEAR_CONTACTS_LISTS':
      return clearContactsLists(state);
    case BLOCK_CONTACT_TOGGLE:
      return setIsBlockedContact(state, action.payload);
    case BLOCK_ME_TOGGLE:
      return setIsBlockedMe(state, action.payload);
    default:
      return state;
  }
};

export default contactsReducer;
