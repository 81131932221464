.autocomplete {
  width: 100%;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-500);
  border-radius: 4px;

  &.error {
    border: 1px solid var(--color-red-error);
    border-radius: 4px;
  }

  &:hover {
    border-color: var(--color-blue-200);
  }

  &.active {
    border: 1px solid var(--color-blue-400);
    border-radius: 4px;
    box-shadow: 0 0 5px rgb(35 105 241 / 31%);
  }

  :global(.MuiAutocomplete-root) {
    padding: 0;
    text-transform: none;
    border: none;

    :global(.MuiInputBase-root) {
      font-size: inherit;
      font-weight: inherit;
      font-family: inherit;

      &:global(.MuiOutlinedInput-root) {
        padding-right: 10px;
      }
    }

    :global(.MuiAutocomplete-inputRoot) {
      text-transform: none;
      padding: 0;

      :global(.MuiAutocomplete-input) {
        padding: var(--indent-xs) 16px;
        font-family: inherit;
        border: none;
        color: var(--main-dark-gray);
        height: unset;
        line-height: 16px;

        &::placeholder {
          color: var(--color-gray-600);
          opacity: 1;
        }
      }

      :global(.MuiOutlinedInput-notchedOutline) {
        border: none;
      }
    }
  }
}

.popper {
  border-radius: 4px;
  box-shadow: 0 3px 7px 0 rgb(36 48 103 / 20%);

  :global(.MuiPaper-root) {
    border: none;
    box-shadow: none;
    color: var(--color-gray-900);

    :global(.MuiAutocomplete-listbox) {
      :global(.MuiAutocomplete-option) {
        padding: 0;
        background-color: var(--color-white);
      }

      :global(.Mui-focused) {
        border-radius: 2px;
        background-color: var(--color-blue-50);
      }
    }
  }
}

.option {
  margin: 0 12px 0 16px;
  cursor: pointer;

  .optionContent {
    padding: 6px;
    margin: 0;
  }
}

.noOption:global(.MuiAutocomplete-noOptions) {
  padding: var(--indent-xs);
}

.loader {
  position: relative;
}

.label {
  display: block;
  margin-bottom: var(--indent-xxs);
}

.labelContent {
  display: flex;
  justify-content: space-between;
  column-gap: var(--indent-xxs);
  color: var(--color-gray-800);
}

.required::after {
  content: ' *';
  color: var(--color-red-error);
}

.errorMessage {
  margin-top: 2px;
  color: var(--color-red-error);
}
