import React from 'react';

/* eslint-disable max-len */
function IconBlock() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        d="M8 14.75C11.4518 14.75 14.25 11.9518 14.25 8.5C14.25 5.04822 11.4518 2.25 8 2.25C4.54822 2.25 1.75 5.04822 1.75 8.5C1.75 11.9518 4.54822 14.75 8 14.75Z"
        stroke="#DD1E2A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="cws-icon-main-fill"
        d="M4.25 12.25L12.25 4.25"
        stroke="#DD1E2A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconBlock;
