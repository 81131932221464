export const updateDialogInfoCase = async (
  { getDialogInfo, showProfile, setDialogInfo },
  { employeeId }
) => {
  const profileInfo = await showProfile(employeeId);

  const dialogInfo = getDialogInfo();
  if (dialogInfo.employeeId === profileInfo.employeeId) {
    setDialogInfo(profileInfo);
  }
};
