import React from 'react';
import { Checkbox as MUICheckBox, FormControlLabel } from '@mui/material';
import { Typography } from '@/components/Typography';
import cn from 'classnames';
import { WithClassName } from '@/components/common.types';
import styles from './Checkbox.module.scss';
import {
  CheckboxChecked,
  CheckboxUnchecked,
  CheckboxIndeterminate
} from './Checkbox.assets';

export interface CheckboxProps extends WithClassName {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  id?: string;
  label?: string;
  labelLeft?: boolean;
  indeterminate?: boolean;
  noWrap?: boolean;
  size?: number;
}

interface CheckboxContainerProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  disabled?: boolean;
  indeterminate?: boolean;
  size: number;
}

function CheckboxContainer({
  checked,
  disabled,
  onChange,
  id,
  indeterminate,
  size
}: CheckboxContainerProps) {
  return (
    <MUICheckBox
      checked={checked}
      indeterminate={indeterminate}
      onChange={onChange}
      id={id}
      disabled={disabled}
      inputProps={{ 'data-testid': 'checkbox-input' } as {}}
      icon={<CheckboxUnchecked height={size} width={size} />}
      checkedIcon={<CheckboxChecked height={size} width={size} />}
      indeterminateIcon={<CheckboxIndeterminate height={size} width={size} />}
      disableRipple
    />
  );
}

export function Checkbox({
  className,
  label,
  labelLeft = false,
  checked = false,
  disabled = false,
  onChange,
  id = '',
  indeterminate = false,
  noWrap = false,
  size = 18
}: CheckboxProps) {
  return (
    <div
      data-testid="checkbox"
      className={cn(className, styles.checkbox, noWrap && styles.noWrap)}>
      {label ? (
        <FormControlLabel
          data-testid="checkbox-label"
          disableTypography
          label={
            <Typography variant="body1Reg" noWrap={noWrap}>
              {label}
            </Typography>
          }
          labelPlacement={labelLeft ? 'start' : 'end'}
          disabled={disabled}
          control={
            <CheckboxContainer
              checked={checked}
              indeterminate={indeterminate}
              onChange={onChange}
              id={id}
              disabled={disabled}
              size={size}
            />
          }
        />
      ) : (
        <CheckboxContainer
          checked={checked}
          indeterminate={indeterminate}
          onChange={onChange}
          id={id}
          disabled={disabled}
          size={size}
        />
      )}
    </div>
  );
}
