import { BaseCell } from '@/components/EditableTable/components/BaseCell';
import { Typography } from '@/components/Typography';
import { NumberColumnDef, RowDef } from '@/components/EditableTable/types';
import { isFunction } from '@/lib/utils';

interface NumberCellProps {
  itemIndex: number;
  item: RowDef;
  column: NumberColumnDef;
}

export function NumberCell({ column, item, itemIndex }: NumberCellProps) {
  const { width, variant = 'body1Reg', align, noWrap, colSpan, style } = column;

  const cellClassName = isFunction(column.cellClassName)
    ? column.cellClassName(item, itemIndex)
    : column.cellClassName;

  return (
    <BaseCell
      className={cellClassName}
      colSpan={colSpan}
      width={width}
      style={style}>
      <Typography variant={variant} align={align} noWrap={noWrap}>
        {itemIndex + 1}
      </Typography>
    </BaseCell>
  );
}
