import React from 'react';

/* eslint-disable max-len */
const IconAt = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75 6.5C7.95507 6.5 6.5 7.95507 6.5 9.75C6.5 11.5449 7.95507 13 9.75 13C11.5449 13 13 11.5449 13 9.75C13 7.95507 11.5449 6.5 9.75 6.5ZM5.5 9.75C5.5 7.40279 7.40279 5.5 9.75 5.5C12.0972 5.5 14 7.40279 14 9.75C14 12.0972 12.0972 14 9.75 14C7.40279 14 5.5 12.0972 5.5 9.75Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5249 12.8163C14.8461 13.2336 15.3233 13.5 16 13.5C16.6767 13.5 17.1539 13.2336 17.4751 12.8163C17.8082 12.3836 18 11.7507 18 11H19C19 11.9061 18.7702 12.7732 18.2675 13.4263C17.7529 14.0949 16.9802 14.5 16 14.5C15.0198 14.5 14.2471 14.0949 13.7325 13.4263C13.2298 12.7732 13 11.9061 13 11H14C14 11.7507 14.1918 12.3836 14.5249 12.8163Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75 1.5C5.19365 1.5 1.5 5.19365 1.5 9.75C1.5 14.3063 5.19365 18 9.75 18C10.0261 18 10.25 18.2239 10.25 18.5C10.25 18.7761 10.0261 19 9.75 19C4.64137 19 0.5 14.8586 0.5 9.75C0.5 4.64137 4.64137 0.5 9.75 0.5C14.8586 0.5 19 4.64137 19 9.75V11H18V9.75C18 5.19365 14.3063 1.5 9.75 1.5ZM14 10V11H13V10H14Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconAt;
