import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { usePermissions } from '@/modules/permissions';
import { ProtectedRoute } from '@/components/PrivateRoute';
import {
  Accreditation,
  NewQuestionnairePage,
  EditQuestionnairePage,
  NewAccreditationRequest,
  EditAccreditationRequestPage,
  AccreditationRequest,
  Questionnaire,
  AccreditationRequests
} from '@/pages/Accreditation';

export const AccreditationRoutes = () => {
  const permissions = usePermissions();

  const haveAccreditationReadPermissions =
    permissions.haveAccreditationReadPermissions();

  const haveAccreditationRequestPermissions =
    permissions.haveAccreditationRequestPermissions();

  const haveAccreditationQuestionnairePermissions =
    permissions.haveAccreditationQuestionnairePermissions();

  return (
    <ProtectedRoute isAllowed={haveAccreditationReadPermissions}>
      <Routes>
        <Route path="/" element={<Accreditation initialTab="requests" />} />
        <Route
          path="/questionnaire/new"
          element={
            <ProtectedRoute
              isAllowed={haveAccreditationQuestionnairePermissions}>
              <NewQuestionnairePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/questionnaire/:questionnaireId/edit"
          element={<EditQuestionnairePage />}
        />
        <Route path="/requests" element={<AccreditationRequests />} />
        <Route
          path="/questionnaire/:questionnaireId"
          element={<Questionnaire />}
        />
        <Route
          path="/questionnaire/:questionnaireId/requests/new"
          element={
            <ProtectedRoute isAllowed={haveAccreditationRequestPermissions}>
              <NewAccreditationRequest />
            </ProtectedRoute>
          }
        />
        <Route
          path="/requests/:requestId/edit"
          element={
            <ProtectedRoute isAllowed={haveAccreditationRequestPermissions}>
              <EditAccreditationRequestPage />
            </ProtectedRoute>
          }
        />
        <Route path="/requests/:requestId" element={<AccreditationRequest />} />
      </Routes>
    </ProtectedRoute>
  );
};
