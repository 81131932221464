import { assoc } from 'ramda';
import {
  SHOW_PROFILE,
  HIDE_PROFILE,
  SET_PROFILE,
  UPDATE_PROFILE
} from './constants';

export const defaultState = () => ({
  openedContact: null
});

const setOpenContact = (chatWidget, contact) =>
  assoc('openedContact', contact, chatWidget);
const updateOpenContact = (chatWidget, props) =>
  assoc('openedContact', { ...chatWidget.openedContact, ...props }, chatWidget);

export const reducers = {
  [SHOW_PROFILE]: (chatWidget, contact) => setOpenContact(chatWidget, contact),
  [HIDE_PROFILE]: (chatWidget) => setOpenContact(chatWidget, null),
  [SET_PROFILE]: (chatWidget, contact) => setOpenContact(chatWidget, contact),
  [UPDATE_PROFILE]: (chatWidget, props) => updateOpenContact(chatWidget, props)
};
