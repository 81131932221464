export default (fn, wait = 250, immediate = false) => {
  const timeouts = {};
  return (key, ...props) => {
    const context = this;
    const args = [key].concat(props);

    const later = () => {
      delete timeouts[key];
      if (!immediate) fn.apply(context, args);
    };

    const isCallNow = immediate && !timeouts[key];
    clearTimeout(timeouts[key]);
    timeouts[key] = setTimeout(later, wait);
    if (isCallNow) fn.apply(context, args);
  };
};

export function debounce(func, timeout) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
