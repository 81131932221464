import React from 'react';

/* eslint-disable max-len */
function IconSubmenu() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        d="M13 9C13 9.82842 13.6716 10.5 14.5 10.5C15.3284 10.5 16 9.82842 16 9C16 8.17157 15.3284 7.5 14.5 7.5C13.6716 7.5 13 8.17157 13 9Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        d="M7.5 9C7.5 9.82842 8.17157 10.5 9 10.5C9.82843 10.5 10.5 9.82842 10.5 9C10.5 8.17157 9.82843 7.5 9 7.5C8.17157 7.5 7.5 8.17157 7.5 9Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        d="M2 9C2 9.82842 2.67157 10.5 3.5 10.5C4.32843 10.5 5 9.82842 5 9C5 8.17157 4.32843 7.5 3.5 7.5C2.67157 7.5 2 8.17157 2 9Z"
        fill="#5B5F73"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconSubmenu;
