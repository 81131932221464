import { assoc, uniq } from 'ramda';

const EMPTY_KIND = null;

/**
 * @typedef {(number|string)} Id
 */

/**
 * Any object with property id and any properties
 *
 * @typedef {object} Item
 * @property {Id} id
 */

/**
 * @typedef BlockGroupEdit
 * @property {(EMPTY_KIND|string)} kind
 * @property {[Id]} includeIds
 * @property {[Id]} excludeIds
 * @property {[Item]} list
 */

/**
 * Creates a block list data type
 *
 * @param {object} params
 * @param {[object]} params.list
 * @param {(EMPTY_KIND|string)} params.kind
 * @param {[Id]} params.includeIds
 * @param {[Id]} params.excludeIds
 * @returns {BlockGroupEdit}
 */
const makeBlockGroupEdit = ({
  list = [],
  kind = EMPTY_KIND,
  includeIds = [],
  excludeIds = []
} = {}) => ({
  type: 'BlockGroupEdit',
  includeIds,
  excludeIds,
  list,
  kind
});

/**
 * Sets list
 *
 * @param {BlockGroupEdit} blockGroupEdit
 * @param {[object]} list
 * @returns {BlockGroupEdit}
 */
const setBGEList = (blockGroupEdit, list = []) =>
  assoc('list', list, blockGroupEdit);

/**
 * Add to includes list id
 *
 * @param {BlockGroupEdit} blockList
 * @param {Id} id
 * @returns {BlockGroupEdit}
 */
const addToIncludeId = (blockList, id) => {
  const updateIncludeId = uniq([...blockList.includeIds, id]);
  return assoc('includeIds', updateIncludeId, blockList);
};

/**
 * Remove to includes list id
 *
 * @param {BlockGroupEdit} blockList
 * @param {Id} id
 * @returns {BlockGroupEdit}
 */
const removeFromIncludeId = (blockList, id) => {
  const updateIncludeId = blockList.includeIds.filter(
    (itemId) => itemId !== id
  );
  return assoc('includeIds', updateIncludeId, blockList);
};

/**
 * Clears includes
 *
 * @param {BlockGroupEdit} blockList
 * @returns {BlockGroupEdit}
 */
const clearInclude = (blockList) => assoc('includeIds', [], blockList);

/**
 * Add to excludes list id
 *
 * @param {BlockGroupEdit} blockList
 * @param {Id} id
 * @returns {BlockGroupEdit}
 */
const addToExcludeId = (blockList, id) => {
  const updateExcludeId = uniq([...blockList.excludeIds, id]);
  return assoc('excludeIds', updateExcludeId, blockList);
};

/**
 * Remove to excludes list id
 *
 * @param {BlockGroupEdit} blockList
 * @param {Id} id
 * @returns {BlockGroupEdit}
 */
const removeFromExcludeId = (blockList, id) => {
  const updateExcludeId = blockList.excludeIds.filter(
    (itemId) => itemId !== id
  );
  return assoc('excludeIds', updateExcludeId, blockList);
};

/**
 * Clears excludes
 *
 * @param {BlockGroupEdit} blockList
 * @returns {BlockGroupEdit}
 */
const clearExclude = (blockList) => assoc('excludeIds', [], blockList);

/**
 * Check includes id
 *
 * @param {BlockGroupEdit} blockList
 * @param {Id} id
 * @returns {boolean}
 */
const isIncludedId = (blockList, id) => blockList.includeIds.includes(id);

/**
 * Check excludes id
 *
 * @param {BlockGroupEdit} blockList
 * @param {Id} id
 * @returns {boolean}
 */
const isExcludedId = (blockList, id) => blockList.excludeIds.includes(id);

/**
 * Include id
 *
 * @param {BlockGroupEdit} blockList
 * @param {Id} id
 * @returns {BlockGroupEdit}
 */
const includeBGEId = (blockList, id) => {
  if (isExcludedId(blockList, id)) {
    return removeFromExcludeId(blockList, id);
  }
  return addToIncludeId(blockList, id);
};

/**
 * Include list of id
 *
 * @param {BlockGroupEdit} blockList
 * @param {[Id]} ids
 * @returns {BlockGroupEdit}
 */
const includeBGEIds = (blockList, ids) => {
  let updatedBlock = { ...blockList };

  ids.forEach((id) => {
    updatedBlock = includeBGEId(updatedBlock, id);
  });

  return updatedBlock;
};

/**
 * Exclude id
 *
 * @param {BlockGroupEdit} blockList
 * @param {Id} id
 * @returns {BlockGroupEdit}
 */
const excludeBGEId = (blockList, id) => {
  if (isIncludedId(blockList, id)) {
    return removeFromIncludeId(blockList, id);
  }
  return addToExcludeId(blockList, id);
};

/**
 * Exclude list of id
 *
 * @param {BlockGroupEdit} blockList
 * @param {[Id]} ids
 * @returns {BlockGroupEdit}
 */
const excludeBGEIds = (blockList, ids) => {
  let updatedBlock = { ...blockList };

  ids.forEach((id) => {
    updatedBlock = excludeBGEId(updatedBlock, id);
  });

  return updatedBlock;
};

/**
 * Sets alreadyAdded for items of list depending on the Ids and flag
 *
 * @param {BlockGroupEdit} blockGroupEdit
 * @param {[Id]} ids
 * @param {boolean} flag
 * @returns {BlockGroupEdit}
 */
const prepareBGEList = (blockGroupEdit, ids = [], flag = false) => {
  const updatedList = blockGroupEdit.list.map((item) =>
    ids.includes(item.id) ? { ...item, alreadyAdded: flag } : item
  );
  return setBGEList(blockGroupEdit, updatedList);
};

export {
  makeBlockGroupEdit,
  setBGEList,
  prepareBGEList,
  includeBGEId,
  includeBGEIds,
  clearInclude,
  excludeBGEId,
  excludeBGEIds,
  clearExclude
};
