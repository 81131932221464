import { uploadContactsCase } from '@/components/ChatWidget/modules/contacts/useCases/uploadContacts';
import * as storage from '@/components/ChatWidget/modules/contacts/storage';
import { applyState } from '@/components/ChatWidget/modules/utils';
import { getGroupContacts } from '@/components/ChatWidget/modules/categories/contacts/getters';
import * as actions from '@/components/ChatWidget/modules/contacts/actions';
import * as actionsContactCategories from '@/components/ChatWidget/modules/categories/contacts/actions';
import { getQuery, updateQuery } from '@/components/ChatWidget/modules/query';

const getQueryUseState = (getState) => () => getQuery('contacts', getState());
const updateQueryMethod = (newQuery) => updateQuery('contacts', newQuery);

export const uploadContacts =
  (newParams = {}) =>
  async (dispatch, getState, service) =>
    uploadContactsCase(
      {
        getQuery: getQueryUseState(getState),
        updateQuery: (newQuery) => dispatch(updateQueryMethod(newQuery)),
        getContacts: async (params) =>
          storage.reqGetContacts(getState(), params),
        getGroups: async () => service.contacts.getGroups(),
        getGroupContacts: applyState(getState, getGroupContacts),
        setContacts: (contacts) => dispatch(actions.setContacts(contacts)),
        setFilteredContacts: (contacts) =>
          dispatch(actions.setFilteredContacts(contacts)),
        setGroupContacts: (groupContacts) =>
          dispatch(actionsContactCategories.setGroupContacts(groupContacts)),
        setLoading: (isLoading) =>
          dispatch(actions.setLoadingContacts(isLoading))
      },
      { newParams }
    );
