import React from 'react';

/* eslint-disable max-len */
function IconNotification() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.21806 3.11692C6.54913 2.86235 6.91858 2.65348 7.32847 2.49388C7.47793 1.64319 8.16944 1 8.99994 1C9.83043 1 10.5219 1.64317 10.6714 2.49384C11.0813 2.65345 11.4508 2.86233 11.7819 3.11692C12.6153 3.75771 13.148 4.64397 13.492 5.61534C14.1597 7.5009 14.1675 9.87453 14.1677 11.8502L14.9883 13.6646C15.2727 14.2934 14.8128 15.006 14.1227 15.006H11.536C11.4854 16.3116 10.3693 17.3551 8.99997 17.3551C7.63061 17.3551 6.51456 16.3116 6.46398 15.006H3.8773C3.18715 15.006 2.72731 14.2934 3.01171 13.6646L3.83228 11.8502C3.83244 9.87453 3.84023 7.5009 4.50795 5.61534C4.85193 4.64397 5.38471 3.75771 6.21806 3.11692ZM7.76642 15.006C7.81653 15.5547 8.30736 16.0551 8.99997 16.0551C9.69258 16.0551 10.1834 15.5547 10.2335 15.006H9.50933H8.49064H7.76642ZM8.99999 3.44067C8.13172 3.51399 7.49278 3.77663 7.0105 4.14747C6.4325 4.59192 6.02108 5.23685 5.73338 6.04929C5.1463 7.70715 5.13235 9.87479 5.13228 11.9261C5.13227 12.0621 5.10311 12.195 5.04787 12.3172L4.41973 13.706H8.49064H9.50933H13.5802L12.9521 12.3172C12.8969 12.195 12.8677 12.0621 12.8677 11.9261C12.8676 9.87479 12.8537 7.70715 12.2666 6.04929C11.9789 5.23685 11.5675 4.59192 10.9895 4.14747C10.5072 3.77663 9.86826 3.51399 8.99999 3.44067Z"
        fill="#5B5F73"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconNotification;
