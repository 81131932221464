import cn from 'classnames';
import { WithClassName } from '@/components/common.types';
import { DeleteRowIcon } from '@/components/EditableTable/assets';
import styles from './DeleteRow.module.scss';

interface DeleteRowProps extends WithClassName {
  onClick: VoidFunction;
}

export function DeleteRow({ onClick, className }: DeleteRowProps) {
  return (
    <div
      className={cn(styles.container, className)}
      role="presentation"
      onClick={onClick}>
      <DeleteRowIcon width={12} height={12} />
    </div>
  );
}
