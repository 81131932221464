import { Map, List, Set, fromJS } from 'immutable';

const setProducts = (
  state,
  {
    data,
    offset,
    limit,
    size,
    search,
    productsId,
    orderBy,
    direction,
    filterValues,
    showReceiptDate,
    showVat,
    showDiscount,
    showDiscountPrice
  }
) =>
  state
    .set('list', List(fromJS(data)))
    .set('size', size)
    .set('limit', limit)
    .set('offset', offset)
    .set('search', search)
    .set('productsId', productsId)
    .set('orderBy', orderBy)
    .set('direction', direction)
    .set('filterValues', fromJS(filterValues))
    .set('showReceiptDate', !!showReceiptDate)
    .set('showVat', showVat)
    .set('showDiscount', showDiscount)
    .set('showDiscountPrice', showDiscountPrice);

const selectProduct = (state, { id }) => {
  if (id === undefined) {
    console.error('need pass id');
    return state;
  }

  return state.update('selectedList', (selected) =>
    selected.has(id) ? selected.remove(id) : selected.add(id)
  );
};

const selectAllProducts = (state) => {
  if (state.get('selectedList').size === state.get('list').size) {
    return state.update('selectedList', (selected) => selected.clear());
  }

  const idsOfProducts = state.get('list').map((product) => product.get('id'));
  return state.update('selectedList', () => Set(idsOfProducts));
};

const updateCost = (state, { id, cost }) => {
  const index = state.get('list').findIndex((item) => item.get('id') === id);
  return state.setIn(['list', index, 'cost'], cost);
};

const updateVat = (state, { id, vat }) => {
  const index = state.get('list').findIndex((item) => item.get('id') === id);
  return state.setIn(['list', index, 'vat'], vat);
};

const updateDiscountProduct = (state, { id, discount }) => {
  const index = state.get('list').findIndex((item) => item.get('id') === id);
  return state.setIn(['list', index, 'discount'], discount);
};

const updateDiscount = (state, { id, purchaseCategoryId, discount }) => {
  const indexProduct = state
    .get('list')
    .findIndex((item) => item.get('id') === id);
  const indexDiscount = state
    .getIn(['list', indexProduct, 'offers'])
    .findIndex(
      (offer) => offer.get('purchaseCategoryId') === purchaseCategoryId
    );

  if (indexDiscount === -1) {
    return state.updateIn(['list', indexProduct, 'offers'], (offers) =>
      offers.push(Map({ purchaseCategoryId, discount, cost: null }))
    );
  }
  return state.setIn(
    ['list', indexProduct, 'offers', indexDiscount, 'discount'],
    discount
  );
};

const updatePurchaseCost = (state, action) => {
  const { id, purchaseCategoryId, cost } = action;
  const indexProduct = state
    .get('list')
    .findIndex((item) => item.get('id') === id);
  const indexDiscount = state
    .getIn(['list', indexProduct, 'offers'])
    .findIndex(
      (offer) => offer.get('purchaseCategoryId') === purchaseCategoryId
    );
  return state.setIn(
    ['list', indexProduct, 'offers', indexDiscount, 'cost'],
    cost
  );
};

const selectProducts = (data, { ids }) =>
  data.update('selectedList', (selected) => selected.union(Set(ids)));
const unselectProducts = (data, { ids }) =>
  data.update('selectedList', (selected) => selected.subtract(Set(ids)));

const toggleFilterSelected = (state) =>
  state.set('isEnabledFilterSelected', !state.get('isEnabledFilterSelected'));

const initState = Map({
  list: List(),
  selectedList: Set(),
  productsId: Set(),
  size: 0,
  limit: 20,
  offset: 0,
  search: '',
  isEnabledFilterSelected: false,
  orderBy: 'name',
  direction: 'ASC',
  filterValues: [],
  appliedFilterValues: []
});

const clearProducts = () => initState;

const setFilterField = (data, { name, value }) => {
  const index = data
    .get('filterValues')
    .findIndex((filter) => filter.get('fieldName') === name);
  const filter = data.getIn(['filterValues', index]).toJS();
  return data.setIn(['filterValues', index], fromJS({ ...filter, ...value }));
};

const applyFilter = (data, { filters }) =>
  data.set('appliedFilterValues', filters);

const list = {
  TABLE_PRICE_PRODUCT_LOADED: setProducts,
  TABLE_PRICE_PRODUCT_SELECT: selectProduct,
  TABLE_PRICE_PRODUCT_SELECT_ALL: selectAllProducts,
  'TABLE_PRICE_PRODUCT:SET_FILTER_FIELD': setFilterField,
  'TABLE_PRICE_PRODUCT:APPLY_FILTER': applyFilter,
  'TABLE_PRICE_PRODUCT:UPDATE_COST:SUCCESS': updateCost,
  'TABLE_PRICE_PRODUCT:UPDATE_VAT:SUCCESS': updateVat,
  'TABLE_PRICE_PRODUCT:UPDATE_DISCOUNT:SUCCESS': updateDiscount,
  'TABLE_PRICE_PRODUCT:UPDATE_PURCHASE_COST:SUCCESS': updatePurchaseCost,
  'TABLE_PRICE_PRODUCT:UPDATE_DISCOST:SUCCESS': updateDiscountProduct,
  TABLE_PRICE_SELECT_PRODUCTS: selectProducts,
  TABLE_PRICE_UNSELECT_PRODUCTS: unselectProducts,
  TABLE_PRICE_FILTER_SELECT_TOGGLE: toggleFilterSelected,
  CLEAR_TABLE_PRICE_PRODUCT: clearProducts
};

export default (state = initState, action) => {
  const reducer = list[action.type];
  return reducer === undefined ? state : reducer(state, action.payload);
};
