import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useTranslate } from '@/TranslateProvider';
import { Typography } from '@link/react-components';
import { isFunction } from '@/utils/utils';
import styles from './ToastContentContainer.module.css';

export const ToastContentContainer = (props) => {
  const { data } = props;
  const t = useTranslate();
  const navigate = useNavigate();

  const {
    key = '',
    text = '',
    linkKey,
    params,
    linkUrl,
    linkClickHandler
  } = data;

  const onLinkClick = useCallback(() => {
    if (linkUrl) {
      navigate(linkUrl);
    } else if (isFunction(linkClickHandler)) {
      linkClickHandler(t);
    }
  }, [t, linkUrl, linkClickHandler, navigate]);

  const renderLink = () => {
    if (!linkKey) return null;

    return (
      <button
        type="button"
        className="link link-alert"
        id="messageLink"
        style={{ cursor: 'pointer', marginLeft: 5 }}
        onClick={onLinkClick}>
        <Typography variant="body1Reg">{t(linkKey)}</Typography>
      </button>
    );
  };

  return (
    <div>
      <Typography variant="body1Reg" className={styles.Text}>
        {t(key || text, params)}
      </Typography>
      {renderLink()}
    </div>
  );
};
