export function getDividerIdByChatId(chatId) {
  return `unread-msg-divider-${chatId}`;
}

export function getDividerAnchorByChatId(chatId) {
  return `unread-msg-divider-${chatId}-anchor`;
}

export function getMessageDOMId(id) {
  return `msgId-${id}`;
}

export function extractMessageIdFromNodeId(nodeId) {
  return typeof nodeId === 'string' ? nodeId.split('msgId-')[1] : null;
}

export function getDividerNode(chatId) {
  return document.getElementById(getDividerIdByChatId(chatId));
}

export function getDividerNodeAnchor(chatId) {
  return document.getElementById(getDividerAnchorByChatId(chatId));
}

export function getMessageNode(id) {
  return document.getElementById(getMessageDOMId(id));
}
