import { logUseCase } from '@/utils/logger';

const TIMEOUT_WARNING_INTERNET_CONNECTION = 500;

// NOTE:
// interrupted connection - user's internet was cut off LESS then time = TIMEOUT_WARNING_INTERNET_CONNECTION
// restored connection - user's internet was restored after interrupted
// offline - user's internet was cut off MORE then time = TIMEOUT_WARNING_INTERNET_CONNECTION
// online - user's internet was restored after offline

const log = logUseCase.extend('internetConnection');
let isOffline = false;

export function onInterruptedConnection(handler) {
  window.addEventListener('offline', () => {
    log('onInterruptedConnection');
    handler();
  });
}

export function onRestoredConnection(handler) {
  window.addEventListener('online', () => {
    log('onRestoredConnection');
    handler();
  });
}

export function onOnline(handler) {
  window.addEventListener('online', () => {
    log('flag ', isOffline);
    if (isOffline) {
      log('onOnline');
      isOffline = false;
      handler();
    }
  });
}

export function onOffline(handler) {
  let timeoutId = null;

  function debounce() {
    timeoutId = setTimeout(() => {
      log('onInterruptedConnection');
      handler();
      isOffline = true;
      clearTimeout(timeoutId);
    }, TIMEOUT_WARNING_INTERNET_CONNECTION);
  }
  window.addEventListener('offline', debounce);
  window.addEventListener('online', () => clearTimeout(timeoutId));
}
