import { assoc, isEmpty } from 'ramda';
import createSlice from '../../../lib/actionReducer';
import {
  viewed,
  rework,
  rejected,
  onApproval,
  requestInvoice
} from '../../../presenters/sidebars/PurchaseRequestResponsesSidebar/status';

const initState = {
  isUploaded: false,
  responses: [],
  size: 0,
  response: {},
  limit: 20,
  offset: 0,
  search: '',
  orderBy: '',
  direction: [],
  selectedCategoriesId: [],
  suppliers: [],
  filterApplied: false,
  countries: [],
  regions: [],
  cities: [],
  regionsCount: 0,
  filters: [],
  selectedStatus: []
};

function updateResponseStatus(state, { responseId, status, manualProposal }) {
  const responses = [...state.responses];

  const foundResponseIndex = responses.findIndex(
    (responseItem) =>
      responseItem.id === responseId &&
      !!responseItem.manualProposal === !!manualProposal
  );

  if (foundResponseIndex !== -1) {
    responses[foundResponseIndex] = setStatus(
      responses[foundResponseIndex],
      status
    );
    return { ...state, responses };
  }

  if (
    state.response?.id === responseId &&
    !!state.response?.manualProposal === !!manualProposal
  ) {
    return { ...state, response: { ...state.response, status } };
  }

  return state;
}

function acceptResponse(state, { responseId, status }) {
  if (status) {
    return setStatusToResponse(status)(state, { responseId });
  }

  return setStatusToResponse(requestInvoice)(state, { responseId });
}

const slice = createSlice({
  name: 'PURCHASE_REQUEST_RESPONSES',
  initState,
  reducers: {
    setIsUploaded,
    getAllResponses,
    getResponseById: setPayloadToState,
    purgeResponse: (state) => ({ ...state, response: {} }),
    onApprovalResponse: setStatusToResponse(onApproval),
    acceptResponse,
    rejectResponse: setStatusToResponse(rejected),
    viewResponse: setStatusToResponse(viewed),
    sendToRework: setStatusToResponse(rework),
    cancelResponse: setStatusToResponse(viewed),
    setFeedbackIdToResponse: setFeedbackIdToResponse(),
    getAllSuppliersOfMyRequests: setPayloadToState,
    getCountries: setPayloadToState,
    getRegionsCount: setPayloadToState,
    getRegions: setPayloadToState,
    getCities: setPayloadToState,
    purgeRegions: (state) => ({ ...state, regions: [] }),
    purgeCities: (state) => ({ ...state, cities: [] }),
    setFilters: setPayloadToState,
    setSearch: (state, search) => ({ ...state, search }),
    reset: () => initState,
    setSelectedStatus,
    updateResponse,
    updateResponseStatus
  }
});

export const { actions } = slice;
export default slice.reducer;

function setPayloadToState(state, payload) {
  return { ...state, ...payload };
}

function setIsUploaded(state, isUploaded = false) {
  return { ...state, isUploaded };
}

function getAllResponses(
  state,
  {
    list: responses,
    size,
    search = '',
    limit = 20,
    offset = 0,
    orderBy = '',
    direction = [],
    selectedCategoriesId = [],
    filterApplied = false
  }
) {
  return {
    ...state,
    responses,
    size,
    search,
    limit,
    offset,
    orderBy,
    direction,
    selectedCategoriesId,
    filterApplied,
    isUploaded: true
  };
}

const setStatus = (response, status) => assoc('status', status, response);

function setSelectedStatus(state, selectedStatus) {
  return { ...state, selectedStatus };
}

function setStatusToResponse(status) {
  return (state, { responseId }) => {
    const response = !isEmpty(state.response)
      ? setStatus(state.response, status)
      : state.response;
    const responses = [...state.responses];

    if (!isEmpty(responses)) {
      const responseForReject = responses.find(
        (responseItem) => responseItem.id === responseId
      );

      if (responseForReject) responseForReject.status = status;
    }

    return { ...state, response, responses };
  };
}

function setFeedbackIdToResponse() {
  return (state, { responseId, feedbackId }) => {
    const response = !isEmpty(state.response)
      ? assoc('feedbackId', feedbackId, state.response)
      : state.response;
    const responses = [...state.responses];

    if (!isEmpty(responses)) {
      const responseToSetFeedback = responses.find(
        (responseItem) => responseItem.id === responseId
      );

      if (responseToSetFeedback) responseToSetFeedback.feedbackId = feedbackId;
    }

    return { ...state, response, responses };
  };
}

function updateResponse(state, { response }) {
  const responses = [...state.responses];

  const foundResponseIndex = responses.findIndex(
    (responseItem) =>
      responseItem.id === response.id &&
      !!responseItem.manualProposal === !!response.manualProposal
  );

  if (foundResponseIndex !== -1) {
    responses[foundResponseIndex] = setStatus(
      responses[foundResponseIndex],
      response.status
    );
    return { ...state, responses };
  }

  if (
    state.response?.id === response.id &&
    !!state.response?.manualProposal === !!response.manualProposal
  ) {
    return { ...state, response: { ...state.response, ...response } };
  }

  return state;
}
