import { isEmpty } from 'ramda';

export const MAX_SIZE = 20971520; // ~20mb in bytes
export const MAX_FILES = 5;
const RESERVED_CHARACTERS_REGEXP = /[\\/:*?"<>|%]/g;
const replaceSymbolsDict = ['_', '0x', '=', 'ok', '#'];

export const ALLOWED_EXTENSION = [
  'jpeg',
  'jpg',
  'png',
  'webp',
  'pdf',
  'csv',
  'txt',
  'rtf',
  'xls',
  'xlsx',
  'djvu',
  'djv',
  'odt',
  'doc',
  'docx',
  'rar',
  'zip'
];

export const EXCLUDED_FILE_EXTENSIONS = [
  'exe',
  'application',
  'msi',
  'msp',
  'bat',
  'cmd',
  'vb',
  'vbs',
  'vbe',
  'ws',
  'wsf',
  'xwsc',
  'wsh'
];

export const MODES = Object.freeze({
  Exclude: 'Exclude',
  Include: 'Include'
});

export const checkFileAllowType = (fileName) => {
  const fileType = fileName.split('.').pop().toLowerCase();
  return !EXCLUDED_FILE_EXTENSIONS.includes(fileType);
};

const getExtension = (file) => {
  const ext = file.name.split('.').pop();
  if (ext === undefined) return '';
  return ext.toLowerCase();
};

const haveExt =
  (extensions = []) =>
  ({ file }) =>
    extensions.includes(getExtension(file));

const haveOneMaxSize = (files, maxSize) =>
  files.some(({ file }) => file.size > (maxSize || MAX_SIZE));

const haveAllAllowedExt = (files, allowedExt = []) =>
  files.every(({ file }) => allowedExt.includes(getExtension(file)));

const haveNotExcludedExt = (files, excludedExt) =>
  files.some(({ file }) => !excludedExt.includes(getExtension(file)));

const randomElement = (array) =>
  array[Math.floor(Math.random() * array.length)];

const transformName = ({ id, file }) => {
  const transformedFile = new File(
    [file],
    file.name
      .trim()
      .replace(RESERVED_CHARACTERS_REGEXP, randomElement(replaceSymbolsDict))
      .substring(0, 240),
    { type: file.type }
  );
  return { id, file: transformedFile };
};

const transformFiles = (files) => files.map(transformName);

function calcAllowedExtensions(includeToAllow = []) {
  const result = ALLOWED_EXTENSION;

  if (!isEmpty(includeToAllow)) result.push(...includeToAllow);

  return result;
}

function calcExcludedExtensions(includeToAllow = []) {
  let excludedExtensions = EXCLUDED_FILE_EXTENSIONS;

  if (!isEmpty(includeToAllow)) {
    excludedExtensions = excludedExtensions.filter(
      (extension) => !includeToAllow.includes(extension)
    );
  }

  return excludedExtensions;
}

function generateErrors(result = []) {
  return result
    .filter((res) => res.status === 'rejected')
    .map((res) => res.reason.msg);
}

function convertBytesToMBs(bytes = 0) {
  return Math.round(bytes / 2 ** 20);
}

export const filesLoadHandler = async ({
  mode = MODES.Include,
  files,
  attachedFiles,
  maxFiles = MAX_FILES,
  beforeLoading = () => {},
  loadFile,
  onError,
  onLoaded = () => {},
  includeToAllowExt = [],
  maxSize
}) => {
  const totalFiles = files.length + attachedFiles.length;

  if (totalFiles > maxFiles) {
    return onError('error_file_upload_limit', { amount: maxFiles });
  }

  if (haveOneMaxSize(files, maxSize)) {
    return onError('filesLoad.errors.max_file_size', {
      size: convertBytesToMBs(maxSize || MAX_SIZE)
    });
  }

  if (mode === MODES.Exclude) {
    const excludedExtensions = calcExcludedExtensions(includeToAllowExt);
    if (!haveNotExcludedExt(files, excludedExtensions)) {
      return onError('filesLoad.errors.incorrect_extensions');
    }
  }

  if (mode === MODES.Include) {
    const allowedExtensions = calcAllowedExtensions(includeToAllowExt);
    if (!haveAllAllowedExt(files, allowedExtensions)) {
      return onError('File cant be upload');
    }
  }

  try {
    beforeLoading();
    const result = await Promise.allSettled(
      transformFiles(files).map(loadFile)
    );
    await onLoaded(generateErrors(result));
  } catch (e) {
    console.error(e);
    onError(e.msg || 'Error while uploading files');
  }
  return Promise.resolve();
};
