import { dissoc, assoc, assocPath, uniq, append, equals } from 'ramda';
import {
  TEMPORARY_SET,
  TEMPORARY_CLEAR,
  TEMPORARY_VALUE_SET,
  TEMPORARY_VALUE_CLEAR,
  TEMPORARY_SET_TO_SET,
  TEMPORARY_APPEND_TO_SET,
  TEMPORARY_REMOVE_FROM_SET,
  TEMPORARY_TOGGLE_IN_SET,
  TEMPORARY_CLEAR_SET,
  TEMPORARY_SET_TO_MAP,
  TEMPORARY_DELETE_FROM_MAP,
  TEMPORARY_CLEAR_MAP
} from '../../actionTypes/temporary';

const defaultValue = () => ({});

const reducers = {
  [TEMPORARY_SET_TO_MAP]: (state, { name, key, value }) =>
    assocPath([name, key.toString()], value, state),
  [TEMPORARY_DELETE_FROM_MAP]: (state, { name, key }) =>
    assocPath([name, key.toString()], undefined, state),
  [TEMPORARY_CLEAR_MAP]: (state, { name }) => assoc(name, {}, state),
  [TEMPORARY_SET_TO_SET]: (state, { name, values }) =>
    assoc(name, uniq(values), state),
  [TEMPORARY_APPEND_TO_SET]: (state, { name, value }) => {
    const array = uniq(append(value, state[name]));
    return assoc(name, array, state);
  },
  [TEMPORARY_REMOVE_FROM_SET]: (state, { name, value }) => {
    const array = state[name].filter((v) => !equals(v, value));
    return assoc(name, array, state);
  },
  [TEMPORARY_TOGGLE_IN_SET]: (state, { name, value }) => {
    const index = state[name].findIndex((v) => equals(v, value));
    let array = state[name];
    if (index === -1) {
      array = uniq(append(value, state[name]));
    } else {
      array = state[name].filter((v) => !equals(v, value));
    }
    return assoc(name, array, state);
  },
  [TEMPORARY_CLEAR_SET]: (state, { name }) => assoc(name, [], state),
  [TEMPORARY_VALUE_SET]: (state, { name, value }) => assoc(name, value, state),
  [TEMPORARY_VALUE_CLEAR]: (state, { name }) => dissoc(name, state),
  [TEMPORARY_SET]: (state, newState) => newState,
  [TEMPORARY_CLEAR]: defaultValue
};

export default (state = defaultValue(), { type, payload }) => {
  if (reducers[type]) return reducers[type](state, payload);
  return state;
};
