import { PREFIX } from '../constants';

export const SET_MESSAGES = `${PREFIX}:SET_MESSAGES`;
export const ADD_OR_UPDATE_MESSAGE = `${PREFIX}:ADD_OR_UPDATE_MESSAGE`;
export const UPDATE_MESSAGE = `${PREFIX}:UPDATE_MESSAGE`;
export const REMOVE_MESSAGE = `${PREFIX}:REMOVE_MESSAGE`;
export const SELECT_MESSAGE = `${PREFIX}:SELECT_MESSAGE`;
export const CLEAR_SELECTED_MSGS = `${PREFIX}:CLEAR_SELECTED_MSGS`;

export const ADD_OR_UPDATE_LAST_UNREAD_MESSAGE = `${PREFIX}:ADD_OR_UPDATE_LAST_UNREAD_MESSAGE`;
export const CLEAR_LAST_UNREAD_MESSAGE = `${PREFIX}:CLEAR_LAST_UNREAD_MESSAGE`;

export const SET_EDIT_MSG = `${PREFIX}:SET_EDIT_MSG`;

export const SET_MSG_TO_EDITOR = `${PREFIX}:SET_MSG_TO_EDITOR`;
