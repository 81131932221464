import React from 'react';

/* eslint-disable max-len */
const IconDownload = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 2.20215V5.30005C10.5 5.72009 10.5 5.93011 10.5817 6.09055C10.6537 6.23167 10.7684 6.3464 10.9095 6.41831C11.0699 6.50005 11.28 6.50005 11.7 6.50005H14.7979M6.75 11.75L9 14M9 14L11.25 11.75M9 14L9 9.5M10.5 2H6.6C5.33988 2 4.70982 2 4.22852 2.24524C3.80516 2.46095 3.46095 2.80516 3.24524 3.22852C3 3.70982 3 4.33988 3 5.6V13.4C3 14.6601 3 15.2902 3.24524 15.7715C3.46095 16.1948 3.80516 16.539 4.22852 16.7548C4.70982 17 5.33988 17 6.6 17H11.4C12.6601 17 13.2902 17 13.7715 16.7548C14.1948 16.539 14.539 16.1948 14.7548 15.7715C15 15.2902 15 14.6601 15 13.4V6.5L10.5 2Z"
      stroke="#5B5F73"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconDownload;
