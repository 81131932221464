import request from '../../../../lib/request';
import { makeProfile } from './entity';

export const getContactInfo = async (employeeId) => {
  const contactData = await request.get(
    `/api/contacts/info/employeeid/${employeeId}`
  );

  return makeProfile(contactData);
};
