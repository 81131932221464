import { Map, Set, List, fromJS, OrderedMap } from 'immutable';

const newInvites = () => new List();
const newCompany = () =>
  new Map({
    email: '',
    name: '',
    ordersCount: '',
    description: '',
    industry: '',
    url: '',
    systemId: '',
    logo: '',
    uniqueEmail: true,
    uniqueSystemId: true,
    requisites: new Map(),
    contacts: new OrderedMap(),
    roles: new Map({ customer: true, supplier: true })
  });

export const initState = () =>
  new Map({
    invites: newInvites(),
    description: '',
    newCompany: newCompany(),
    listOfCompanies: List(),
    listOfUsers: List(),
    isUploaded: false,
    uniqueNameInCompany: true,
    selectedEmployee: Set(),
    selectedInvites: Set()
  });

export const setInvites = (data, { invites }) =>
  data.remove('invites').set('invites', fromJS(invites));

export const updateNewCompany = (data, { field, value }) =>
  data.updateIn(['newCompany', field], () => value);

export const updateNewCompanyRole = (data, { role, value }) =>
  data.setIn(['newCompany', 'roles', role], value);

export const clearNewCompany = (data) =>
  data.remove('newCompany').set('newCompany', newCompany());

export const selectEmployee = (data, { item }) =>
  data.update('selectedEmployee', Set(), (selected) =>
    selected.includes(item.get('id'))
      ? selected.remove(item.get('id'))
      : selected.add(item.get('id'))
  );

export const selectInvite = (data, { id }) =>
  data.update('selectedInvites', Set(), (selected) =>
    selected.includes(id) ? selected.remove(id) : selected.add(id)
  );

export const clearSelected = (data) =>
  data.remove('selectedEmployee').set('selectedEmployee', Set());

export const clearSelectedInvite = (data) =>
  data.remove('selectedInvites').set('selecteInvites', Map());
