import { JSX, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { Typography } from '@/components/Typography';
import { WithClassName } from '@/components/common.types';
import { useKey } from 'react-use';
import { usePopupContext } from './popup.context';
import styles from './popup.module.scss';

enum Align {
  Right = 'right',
  Left = 'left',
  Center = 'center',
  SpaceBetween = 'space-between'
}

interface PopupRootProps extends PropsWithChildren, WithClassName {}
interface PopupContentProps extends PropsWithChildren, WithClassName {}
interface PopupHeaderProps extends PropsWithChildren, WithClassName {}
interface PopupActionsProps extends PropsWithChildren, WithClassName {}
interface PopupFooterProps extends PropsWithChildren, WithClassName {
  align?: Align;
}

export function Popup(props: PopupRootProps): JSX.Element | null {
  const context = usePopupContext();
  const { onClose } = context;
  const { className, children } = props;

  useKey('Escape', () => onClose());

  return createPortal(
    <div className={cn(styles.root, className)} data-testid="popup-root">
      {children}
    </div>,
    document.body
  );
}

export function PopupHeader(props: PopupHeaderProps) {
  const { className, children } = props;

  return (
    <div className={cn(styles.header, className)} data-testid="popup-header">
      <Typography variant="h2" className={styles.headerText}>
        {children}
      </Typography>
    </div>
  );
}

export function PopupContent(props: PopupContentProps) {
  const { className, children } = props;

  return (
    <div className={cn(styles.content, className)} data-testid="popup-content">
      {children}
    </div>
  );
}

export function PopupFooter(props: PopupFooterProps) {
  const { className, align = Align.Right, children } = props;

  return (
    <div
      className={cn(styles.footer, styles[align], className)}
      data-testid="popup-footer">
      {children}
    </div>
  );
}

export function PopupActions(props: PopupActionsProps) {
  const { className, children } = props;

  return (
    <div className={cn(styles.actions, className)} data-testid="popup-actions">
      {children}
    </div>
  );
}

Popup.Header = PopupHeader;
Popup.Content = PopupContent;
Popup.Footer = PopupFooter;
Popup.Actions = PopupActions;
