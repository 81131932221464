import React from 'react';
import Video from './Video';
import Audio from './Audio';

function isMyStream(type) {
  return type === 'my-stream';
}

// TODO Т.к. isMutedAudioComponent и isMutedVideoComponent отвечают за звук, то не ясно зачем тут isCameraMuted
const MediaContent = ({
  id,
  type,
  stream,
  isMicrophoneMuted = false,
  isCameraMuted = false
}) => {
  const isMutedAudioComponent = !isCameraMuted || isMicrophoneMuted;
  const isMutedVideoComponent =
    isMyStream(type) || isMicrophoneMuted || !isMutedAudioComponent;

  console.info(
    `peerId = ${id}: isMutedAudioComponent = ${isMutedAudioComponent}, isMutedVideoComponent = ${isMutedVideoComponent}`
  );

  return (
    <>
      <Video stream={stream} isMicrophoneMuted={isMutedVideoComponent} />
      {!isMyStream(type) && (
        <Audio id={id} stream={stream} muted={isMutedAudioComponent} />
      )}
    </>
  );
};

export default MediaContent;
