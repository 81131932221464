import { Map } from 'immutable';
import { reducer as formReducer } from 'redux-form/immutable';
import {
  cartReducer,
  CART_REDUCER_NAME
} from '@/modules/marketplace/entities/cart';
import tablesReducer from './tables';
import userReducer from './user';
import validatorReducer from './validator';
import modalReducer from './modal';
import messageReducer from './message';
import languageListReducer from './languageList';
import browserReducer from './browser';
import companyReducer from './company';
import listsReducers from './lists';
import companyCombine from './company/info';
import companyBaidges from './company/baidges';
import chatReducer from './chat';
import badgeReducer from './badge';
import tablesReducers from './tablesNew';
import unitReducer from './units';
import serviceReducer from './service';
import storageReducer from './storage';
import adminReducer from './admin';
import requestsReducer from './purchaseRequests';
import userConfigs from './userConfigs';
import loaderReducer from './loaderList';
import filterOptions from './filterOptions';
import channelReducer from './channel';
import contactsReducer from './contacts';
import temporaryReducer from './temporary';
import partnersReducer from './partners';
import visualReducers from '../modules/visual/reducers';
import onlineReducers from '../modules/online/reducers';
import dialogLists from './dialogLists';
import topicReducers from '../modules/topic/reducers';
import chatWidgetReducers from '../components/ChatWidget/reducers';
import verifyEmail from './verifyEmail';
import resetPassword from './resetPassword';
import invitesReducer from './invites';
import connectionsReducer from './connections';
import errorsReducer from './errors';
import purchaseRequestResponseReducer from '../components/PurchaseRequests/Responses/reducer';
import myResponses from '../components/PurchaseRequests/Responses/MyResponses/reducer';
import myPurchaseRequestReducer from '../components/PurchaseRequests/MyRequests/reducer';
import dictionariesReducer from '../modules/dictionaries/slice';
import taskManagerReducer from '../tasks-manager/reducers/task';
import emailMessagesReducer from '../components/ChatWidget/modules/emailMessages/redux';
import externalAPIReducer from '../components/ChatWidget/modules/externalAPI/redux';
import { MODULE_REDUCER_NAME, moduleReducer } from './module.slice';
import {
  MY_PROCUREMENTS_FILTER_REDUCER,
  myProcurementFilterReducer
} from '../components/myProcurementsFilter';

export default (state = Map(), action) =>
  state
    .update('user', (user) => userReducer(user, action))
    .update('tables', (tablesState) => tablesReducer(tablesState, action))
    .update('validator', (validatorState) =>
      validatorReducer(validatorState, action)
    )
    .update('modal', (modalState) => modalReducer(modalState, action))
    .update('message', (messageState) => messageReducer(messageState, action))
    .update('languageList', (languageList) =>
      languageListReducer(languageList, action)
    )
    .update('browserProps', (browserState) =>
      browserReducer(browserState, action)
    )
    .update('company', (companyState) => companyReducer(companyState, action))
    .update('companiesInfo', (companyState) =>
      companyCombine(companyState, action)
    )
    .update('companyBaidges', (companyState) =>
      companyBaidges(companyState, action)
    )
    .update('lists', (lists) => listsReducers(lists, action))
    .update('userConfigs', (configs) => userConfigs(configs, action))
    .update('chat', (services) => chatReducer(services, action))
    .update('badges', (services) => badgeReducer(services, action))
    .update('tablesNew', (states) => tablesReducers(states, action))
    .update('unit', (states) => unitReducer(states, action))
    .update('service', (states) => serviceReducer(states, action))
    .update('storage', (states) => storageReducer(states, action))
    .update('admin', (states) => adminReducer(states, action))
    .update('purchaseRequests', (states) => requestsReducer(states, action))
    .update('purchaseRequestResponses', (states) =>
      purchaseRequestResponseReducer(states, action)
    )
    .update('myResponses', (states) => myResponses(states, action))
    .update('myPurchaseRequests', (states) =>
      myPurchaseRequestReducer(states, action)
    )
    .update('loaderList', (states) => loaderReducer(states, action))
    .update('channel', (states) => channelReducer(states, action))
    .update('filterOptions', (states) => filterOptions(states, action))
    .update('temporary', (states) => temporaryReducer(states, action))
    .update('contacts', (states) => contactsReducer(states, action))
    .update('partners', (states) => partnersReducer(states, action))
    .update('visual', (states) => visualReducers(states, action))
    .update('online', (states) => onlineReducers(states, action))
    .update('topicStore', (states) => topicReducers(states, action))
    .update('chat-widget', (states) => chatWidgetReducers(states, action))
    .update('dialogLists', (states) => dialogLists(states, action))
    .update('form', (states) => formReducer(states, action))
    .update('verifyEmail', (states) => verifyEmail(states, action))
    .update('resetPassword', (states) => resetPassword(states, action))
    .update('invite', (states) => invitesReducer(states, action))
    .update('connections', (states) => connectionsReducer(states, action))
    .update('dictionaries', (states) => dictionariesReducer(states, action))
    .update('task-manager', (states) => taskManagerReducer(states, action))
    .update('emailMessages', (states) => emailMessagesReducer(states, action))
    .update('externalAPI', (states) => externalAPIReducer(states, action))
    .update(MODULE_REDUCER_NAME, (states) => moduleReducer(states, action))
    .update(CART_REDUCER_NAME, (states) => cartReducer(states, action))
    .update(MY_PROCUREMENTS_FILTER_REDUCER, (states) =>
      myProcurementFilterReducer(states, action)
    )
    .update('errors', (states) => errorsReducer(states, action));
