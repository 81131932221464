/* eslint-disable import/no-dynamic-require */
import { intersection, isEmpty } from 'ramda';

export enum Roles {
  Customer = 'customer',
  Supplier = 'supplier'
}

interface RawNewFeature {
  preview?: string;
  description: string;
  roles: Roles[];
}

export interface NewFeature extends Omit<RawNewFeature, 'roles'> {}

class NewFeaturesService {
  getNewFeaturesByVersionAndRoles(
    version: string,
    roles: Roles[]
  ): NewFeature[] {
    try {
      const rawNewFeatures:
        | RawNewFeature[]
        | null = require(`../../public/updates/release-${version}/log.json`);

      if (!rawNewFeatures) {
        return [];
      }

      return rawNewFeatures
        .filter(
          (rawNewFeature) =>
            isEmpty(rawNewFeature.roles) ||
            intersection(rawNewFeature.roles, roles).length ===
              rawNewFeature.roles.length
        )
        .map((rawNewFeature) => {
          const { preview, description } = rawNewFeature;

          const newFeature: NewFeature = { description };

          if (preview) {
            newFeature.preview = preview.replace(
              '//',
              `${window.location.origin}/updates/release-${version}/assets/`
            );
          }

          return newFeature;
        });
    } catch (e) {
      return [];
    }
  }
}

export const newFeaturesService = new NewFeaturesService();
