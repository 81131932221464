import React from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import { stopStream } from '../../../utils';

const Video = ({ stream, isMicrophoneMuted = false }) => {
  const videoRef = React.useRef();

  useIsomorphicLayoutEffect(() => {
    if (videoRef.current) {
      if (videoRef.current.srcObject) {
        stopStream(videoRef.current.srcObject);
      }

      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  // eslint-disable-next-line jsx-a11y/media-has-caption
  return (
    <video
      ref={videoRef}
      className="video-chat__video"
      autoPlay
      playsInline
      muted={isMicrophoneMuted}
    />
  );
};

export default Video;
