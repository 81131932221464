.container {
  :global(.MuiAccordion-root) {
    box-shadow: none;
    border-radius: 0;

    &:first-of-type,
    &:last-of-type {
      border-radius: 0;
    }
  }

  :global(.MuiAccordionSummary-root) {
    padding: var(--padding-xxs, 4px) var(--padding-xs, 8px);
    min-height: unset;
    column-gap: 10px;
    box-shadow: none;
  }

  :global(.MuiAccordionSummary-root.Mui-expanded) {
    min-height: unset;
    margin: 0;
  }

  :global(.MuiAccordionSummary-content) {
    margin: 0;
  }

  :global(.MuiAccordionSummary-content.Mui-expanded) {
    margin: 0;
  }

  :global(.MuiAccordionDetails-root) {
    padding: 0;
  }

  .paddingDetails:global(.MuiAccordionDetails-root) {
    padding: var(--padding-xxs, 4px) var(--padding-xs, 8px) 0;
  }
}

.titleNoWrap {
  :global(.MuiAccordionSummary-content) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
