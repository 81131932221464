import * as storage from './storage';

let typingTimeout = null;

export const typing = (chatId) => {
  if (!typingTimeout) {
    storage.emmitStartTyping(chatId);
    typingTimeout = setTimeout(() => {
      typingTimeout = null;
    }, 1000);
  }
};
