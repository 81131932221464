import { bindActionCreators } from 'redux';
import { getActiveTab } from '../../../getter';
import { getQuery } from '../../query';
import * as services from '../services';
import * as actions from '../actions';
import { applyState } from '../../utils';
import { sortChatsCase } from './sortChats';
import { reloadChatListCase } from './reloadChatList';
import { loadChatsByGroupsCase } from './loadChatsByGroups';

export const sortChats =
  (sortBy, direction = 'up') =>
  async (dispatch, getState) =>
    sortChatsCase(
      {
        getActiveTab: applyState(getState, getActiveTab),
        updateChatsFilter: bindActionCreators(
          actions.updateChatsFilter,
          dispatch
        ),
        updateArchiviesFilter: bindActionCreators(
          actions.updateArchiviesFilter,
          dispatch
        )
      },
      { sortBy, direction }
    );

export const reloadChatList = () => async (dispatch, getState) =>
  reloadChatListCase({
    getChatsQuery: () => getQuery('chats', getState()),
    uploadChats: async (prop) => dispatch(services.uploadChats(prop))
  });

export const loadChatsByGroups = (blockGroup) => async (dispatch) =>
  loadChatsByGroupsCase(
    {
      setGroupChats: (prop) => dispatch(actions.setGroupChats(prop))
    },
    { blockGroup }
  );
