import { REMOVE_FORWARD_MSG, SET_FORWARD_MSGS } from './constants';

export const setForwardMessages = ({ toChatId, fromChatId, messages }) => ({
  type: SET_FORWARD_MSGS,
  payload: { toChatId, fromChatId, messages }
});

export const removeForwardMessage = ({ toChatId, messageId }) => ({
  type: REMOVE_FORWARD_MSG,
  payload: { toChatId, messageId }
});
