import { defStruct } from '../../lib';
import { makePaginationQuery } from '../paginationQuery';
import { makeWhereParams } from '../whereParams';
import { makeSortBy } from '../sortBy';

/**
 * @typedef RequestMany
 * @typedef {import('../paginationQuery')} PaginationQuery
 * @typedef {import('../whereParams')} WhereParams
 * @typedef {import('../sortBy')} SortBy
 */
const methods = defStruct('RequestMany', [
  'paginationQuery',
  'whereParams',
  'sortBy'
]);

/**
 * Creates a request many data type
 *
 * @param {object} params
 * @param {PaginationQuery} params.paginationQuery
 * @param {WhereParams} params.whereParams
 * @param {SortBy} params.sortBy
 * @returns {RequestMany}
 */
export const makeRequestMany = ({
  paginationQuery = makePaginationQuery(),
  whereParams = makeWhereParams(),
  sortBy = makeSortBy()
} = {}) => methods.makeRequestMany(paginationQuery, whereParams, sortBy);

/**
 * Gives pagination query data type from request many
 *
 * @param {RequestMany} requestMany
 * @returns {PaginationQuery}
 */
export const getPaginationQuery = (requestMany) =>
  methods.getPaginationQuery(requestMany);

/**
 * Gives where params data type from request many
 *
 * @param {RequestMany} requestMany
 * @returns {WhereParams}
 */
export const getWhereParams = (requestMany) =>
  methods.getWhereParams(requestMany);

/**
 * Get sortBy data type from request many
 *
 * @param {RequestMany} requestMany
 * @returns {SortBy}
 */
export const getSortBy = (requestMany) => methods.getSortBy(requestMany);

/**
 * Checks if is request many
 *
 * @param {*} a
 * @returns {boolean}
 */
export const isRequestMany = (a) => methods.isRequestMany(a);

/**
 * Sets the where params into request many
 *
 * @param {WhereParams} whereParams
 * @param {RequestMany} requestMany
 * @returns {RequestMany}
 */
export const setWhereParams = (whereParams, requestMany) =>
  methods.makeRequestMany(
    methods.getPaginationQuery(requestMany),
    whereParams,
    methods.getSortBy(requestMany)
  );

/**
 * Sets the pagination query into request many
 *
 * @param {PaginationQuery} paginationQuery
 * @param {RequestMany} requestMany
 * @returns {RequestMany}
 */
export const setPaginationQuery = (paginationQuery, requestMany) =>
  methods.makeRequestMany(
    paginationQuery,
    methods.getWhereParams(requestMany),
    methods.getSortBy(requestMany)
  );
