import { PropsWithChildren } from 'react';
import cn from 'classnames';
import { WithClassName } from '@/components/common.types';
import { Typography, TypographyProps } from '@/components/Typography';
import { Icon, IconProps } from '@/components/Icon';
import styles from './typographyWithIcon.module.scss';

export interface TypographyWithIconProps
  extends Omit<TypographyProps, 'width' | 'height'>,
    IconProps {
  iconClassName?: string;
  href?: string;
  onClick?: () => void;
}

interface LinkContainerProps extends PropsWithChildren, WithClassName {
  href?: string;
  onClick?: () => void;
}

function LinkContainer(props: LinkContainerProps) {
  const { href, className, children, onClick } = props;

  const preparedClassName = cn(styles.container, className, {
    [styles.link]: !!href || !!onClick
  });

  if (href || onClick) {
    return (
      <a
        className={preparedClassName}
        target="_blank"
        rel="noopener noreferrer"
        href={href}
        onClick={onClick}>
        {children}
      </a>
    );
  }

  return <div className={preparedClassName}>{children}</div>;
}

export function TypographyWithIcon(props: TypographyWithIconProps) {
  const {
    height,
    width,
    iconName,
    href,
    onClick,
    children,
    className,
    iconClassName,
    ...restTypographyProps
  } = props;

  return (
    <LinkContainer className={className} href={href} onClick={onClick}>
      <Icon
        iconName={iconName}
        width={width}
        height={height}
        className={iconClassName}
      />
      <Typography {...restTypographyProps}>{children}</Typography>
    </LinkContainer>
  );
}
