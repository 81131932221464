import { assoc, prop } from 'ramda';
import { makePartList, getDataPart, getSelectedList } from 'core/data/partList';
import { makeSelect, toggleAllItems, toggleItem } from 'core/data/select';
import { getPart } from 'core/data/dataPart';
import {
  LISTS_SET,
  LISTS_CLEAR,
  LISTS_TOGGLE_ALL,
  LISTS_TOGGLE_ONE,
  LISTS_CLEAR_SELECT_LIST,
  LISTS_ADD_OR_UPDATE
} from '../../actionTypes/lists';
import { MY_ORDERS } from '../../constants';

const LIST_OF_NAMES = [MY_ORDERS];

const defaultValue = () =>
  LIST_OF_NAMES.reduce((acc, name) => assoc(name, makePartList(), acc), {});

const setter = (func) => (list, name, payload) =>
  assoc(name, func(prop(name, list), payload), list);

const reducers = {
  [LISTS_SET]: setter((partList, { dataPart }) =>
    makePartList({
      dataPart,
      selectedList: getSelectedList(partList)
    })
  ),
  [LISTS_CLEAR]: setter(() => makePartList()),
  [LISTS_TOGGLE_ALL]: setter((partList) =>
    makePartList({
      dataPart: getDataPart(partList),
      selectedList: toggleAllItems(
        makeSelect({
          dataPart: getDataPart(partList),
          selectedList: getSelectedList(partList)
        })
      )
    })
  ),
  [LISTS_TOGGLE_ONE]: setter((partList, { id }) =>
    makePartList({
      dataPart: getDataPart(partList),
      selectedList: toggleItem(
        id,
        makeSelect({
          dataPart: getDataPart(partList),
          selectedList: getSelectedList(partList)
        })
      )
    })
  ),
  [LISTS_CLEAR_SELECT_LIST]: setter((partList) =>
    makePartList({
      dataPart: getDataPart(partList)
    })
  ),
  [LISTS_ADD_OR_UPDATE]: setter((partList, { newOrders }) => {
    const dataPart = getDataPart(partList);
    const selectedList = getSelectedList(partList);
    let part = getPart(dataPart);
    const sliceLimit = 20 - newOrders.length;
    newOrders.forEach((order) => {
      const itemIndex = part.findIndex((i) => i.id === order.id);
      if (itemIndex !== -1) {
        dataPart.part[itemIndex] = order;
      } else {
        part = sliceLimit <= 0 ? [] : part.slice(0, sliceLimit);
        part.unshift(order);
        dataPart.part = part;
      }
    });
    return makePartList({ dataPart, selectedList });
  })
};

export default (state = defaultValue(), { type, name, payload }) => {
  if (reducers[type]) return reducers[type](state, name, payload);
  return state;
};
