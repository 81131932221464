import React from 'react';

/* eslint-disable max-len */
const IconMessage = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 15H18V0H0V15H6L9 18L12 15ZM9 16.5858L11.5858 14H17V1H1V14H6.41421L9 16.5858Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 8C4.77614 8 5 7.77614 5 7.5C5 7.22386 4.77614 7 4.5 7C4.22386 7 4 7.22386 4 7.5C4 7.77614 4.22386 8 4.5 8ZM4.5 9C5.32843 9 6 8.32843 6 7.5C6 6.67157 5.32843 6 4.5 6C3.67157 6 3 6.67157 3 7.5C3 8.32843 3.67157 9 4.5 9Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 8C9.27614 8 9.5 7.77614 9.5 7.5C9.5 7.22386 9.27614 7 9 7C8.72386 7 8.5 7.22386 8.5 7.5C8.5 7.77614 8.72386 8 9 8ZM9 9C9.82843 9 10.5 8.32843 10.5 7.5C10.5 6.67157 9.82843 6 9 6C8.17157 6 7.5 6.67157 7.5 7.5C7.5 8.32843 8.17157 9 9 9Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 8C13.7761 8 14 7.77614 14 7.5C14 7.22386 13.7761 7 13.5 7C13.2239 7 13 7.22386 13 7.5C13 7.77614 13.2239 8 13.5 8ZM13.5 9C14.3284 9 15 8.32843 15 7.5C15 6.67157 14.3284 6 13.5 6C12.6716 6 12 6.67157 12 7.5C12 8.32843 12.6716 9 13.5 9Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconMessage;
