import React from 'react';

/* eslint-disable max-len */
function IconTrash() {
  return (
    <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.369.584a1.723 1.723 0 0 0-.868.532c-.185.2-.307.403-.388.646-.056.17-.062.226-.071.635l-.01.45-.552.002c-.303.001-.877.009-1.275.018l-.723.015-.111.064a.506.506 0 0 0-.175.175c-.06.104-.064.128-.064.379s.004.275.064.379a.506.506 0 0 0 .175.175c.106.062.126.065.507.076l.397.012.012 3.955.011 3.955.064.186c.095.279.215.465.45.7.235.235.421.355.7.45l.186.064h6.604l.186-.064c.279-.095.465-.215.7-.45.235-.235.355-.421.45-.7l.064-.186.011-3.955.012-3.955.397-.012c.381-.011.401-.014.507-.076a.506.506 0 0 0 .175-.175c.06-.104.064-.128.064-.379s-.004-.275-.064-.379a.506.506 0 0 0-.175-.175l-.111-.064-.723-.015a81.148 81.148 0 0 0-1.275-.018l-.552-.002-.01-.45c-.009-.409-.015-.465-.071-.635-.092-.276-.212-.464-.449-.7-.235-.235-.421-.355-.7-.45L8.552.548 7.047.543C5.619.539 5.533.541 5.369.584m3.008 1.274c.239.113.306.254.321.68l.011.309H5.291l.011-.309c.012-.336.041-.445.153-.563a.525.525 0 0 1 .293-.155c.053-.01.643-.018 1.31-.016 1.184.004 1.216.006 1.319.054m2.071 6.105c-.006 3.804-.006 3.809-.055 3.914a.577.577 0 0 1-.305.283c-.133.047-6.043.047-6.176 0a.577.577 0 0 1-.305-.283c-.049-.105-.049-.11-.055-3.914l-.006-3.81h6.908l-.006 3.81"
        fillRule="evenodd"
        fill="#5B5F73"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconTrash;
