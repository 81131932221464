import { defRecord } from 'core/lib';

export const { makeMsgUnsent } = defRecord(
  'MsgUnsent',
  [
    'originId',
    'deliveryId',
    'id',
    'employeeId',
    'userName',
    'avatarSrc',
    'time',
    'edited',
    'files',
    'authorEmployeeId',
    'authorUserName',
    'authorAvatarSrc',
    'originTime',
    'msg',
    'html',
    'channelId',
    'files',
    'accessList',
    'mentions',
    'bookmarkedBy',
    'hidden'
  ],
  {
    files: [],
    mentions: [],
    accessList: [],
    bookmarkedBy: [],
    edited: false,
    hidden: false
  }
);
