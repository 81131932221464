import slice from '../../lib/actionReducer';

const initState = {
  countries: [],
  regions: [],
  cities: [],
  regionsCount: 0,
  companies: [],
  purchaseRequestsCategories: []
};

const dictionariesSlice = slice({
  name: 'DICTIONARIES',
  initState,
  reducers: {
    setCountries: setPayloadToState('countries'),
    setRegions: setPayloadToState('regions'),
    setCities: setPayloadToState('cities'),
    setRegionsCount: setPayloadToState('regionsCount'),
    setCompanies: setPayloadToState('companies'),
    setPurchaseRequestsCategories: setPayloadToState(
      'purchaseRequestsCategories'
    ),
    purgeRegions: purge('regions'),
    purgeCities: purge('cities')
  }
});

export const { actions } = dictionariesSlice;
export default dictionariesSlice.reducer;

function setPayloadToState(key = '') {
  return function (state, payload) {
    return { ...state, [key]: payload[key] };
  };
}

function purge(key = '') {
  return function (state) {
    return { ...state, [key]: initState[key] };
  };
}
