import React from 'react';

/* eslint-disable max-len */
const IconPublish = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 12.7431C9.27614 12.7431 9.5 12.5192 9.5 12.2431V2.24309C9.5 1.96695 9.27614 1.74309 9 1.74309C8.72386 1.74309 8.5 1.96695 8.5 2.24309V12.2431C8.5 12.5192 8.72386 12.7431 9 12.7431Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4751 6.4751C13.6704 6.27984 13.6704 5.96326 13.4751 5.76799L9.35355 1.64645C9.15829 1.45118 8.84171 1.45118 8.64645 1.64645L4.5249 5.76799C4.32964 5.96326 4.32964 6.27984 4.5249 6.4751C4.72016 6.67036 5.03674 6.67036 5.23201 6.4751L9 2.70711L12.768 6.4751C12.9633 6.67036 13.2798 6.67036 13.4751 6.4751Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.84839 4.27959C1.99913 5.53868 1.5 7.07126 1.5 8.72721C1.5 13.0359 4.87353 16.5 9 16.5C13.1265 16.5 16.5 13.0359 16.5 8.72721C16.5 7.07126 16.0009 5.53868 15.1516 4.27959L15.9807 3.72041C16.939 5.14127 17.5 6.86791 17.5 8.72721C17.5 13.5564 13.7101 17.5 9 17.5C4.28991 17.5 0.5 13.5564 0.5 8.72721C0.5 6.86791 1.06097 5.14127 2.01935 3.72041L2.84839 4.27959Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconPublish;
