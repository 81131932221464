import React from 'react';

/* eslint-disable max-len */
const IconNoticeOff = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="cws-icon-main-fill"
      d="M3.85933 6C3.85933 3.23858 6.0979 1 8.85933 1C10.9624 1 12.7622 2.2984 13.5008 4.13732L12.6933 4.85596C12.2012 3.20429 10.6709 2 8.85933 2C6.65019 2 4.85933 3.79086 4.85933 6V12.5H3.85933V6Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.85933 13H1.85933L1.85933 12H4.85933L4.85933 13Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8593 13L8.85933 13V12L15.8593 12V13Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8593 13V8H13.8593V13H12.8593Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.85933 16C9.59961 16 10.246 15.5978 10.5918 15H7.12689C7.4727 15.5978 8.11905 16 8.85933 16ZM6.03003 15C5.91948 14.6872 5.85933 14.3506 5.85933 14H11.8593C11.8593 14.3506 11.7992 14.6872 11.6886 15C11.2768 16.1652 10.1655 17 8.85933 17C7.55311 17 6.44187 16.1652 6.03003 15Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 16.1406L16 1.14063L16.7071 1.84773L1.70711 16.8477L1 16.1406Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconNoticeOff;
