import { path } from 'ramda';

export const safetyServiceWorkerPostMessage = (msg) => {
  const postMessage = path(
    ['serviceWorker', 'controller', 'postMessage'],
    navigator
  );
  if (postMessage) {
    postMessage.call(navigator.serviceWorker.controller, msg);
  }
};
