import React from 'react';

/* eslint-disable max-len */
const IconConversationM = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_21386:16289)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 15.918H18.5V0.917969H0.5V15.918H6.5L9.5 18.918L12.5 15.918ZM9.5 17.5038L12.0858 14.918H17.5V1.91797H1.5V14.918H6.91421L9.5 17.5038Z"
        fill="#767B92"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 8.91797C5.27614 8.91797 5.5 8.69411 5.5 8.41797C5.5 8.14183 5.27614 7.91797 5 7.91797C4.72386 7.91797 4.5 8.14183 4.5 8.41797C4.5 8.69411 4.72386 8.91797 5 8.91797ZM5 9.91797C5.82843 9.91797 6.5 9.2464 6.5 8.41797C6.5 7.58954 5.82843 6.91797 5 6.91797C4.17157 6.91797 3.5 7.58954 3.5 8.41797C3.5 9.2464 4.17157 9.91797 5 9.91797Z"
        fill="#767B92"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 8.91797C9.77614 8.91797 10 8.69411 10 8.41797C10 8.14183 9.77614 7.91797 9.5 7.91797C9.22386 7.91797 9 8.14183 9 8.41797C9 8.69411 9.22386 8.91797 9.5 8.91797ZM9.5 9.91797C10.3284 9.91797 11 9.2464 11 8.41797C11 7.58954 10.3284 6.91797 9.5 6.91797C8.67157 6.91797 8 7.58954 8 8.41797C8 9.2464 8.67157 9.91797 9.5 9.91797Z"
        fill="#767B92"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 8.91797C14.2761 8.91797 14.5 8.69411 14.5 8.41797C14.5 8.14183 14.2761 7.91797 14 7.91797C13.7239 7.91797 13.5 8.14183 13.5 8.41797C13.5 8.69411 13.7239 8.91797 14 8.91797ZM14 9.91797C14.8284 9.91797 15.5 9.2464 15.5 8.41797C15.5 7.58954 14.8284 6.91797 14 6.91797C13.1716 6.91797 12.5 7.58954 12.5 8.41797C12.5 9.2464 13.1716 9.91797 14 9.91797Z"
        fill="#767B92"
      />
    </g>
    <defs>
      <clipPath id="clip0_21386:16289">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0.5 0.917969)"
        />
      </clipPath>
    </defs>
  </svg>
);
/* eslint-enable max-len */

export default IconConversationM;
