import { isNil } from 'ramda';
import { Map } from 'immutable';

const NO_INTERNET_CONNECTION_MESSAGE_KEY =
  'Goodwix is trying to connect to the Internet';

const haveNoInternetConnectionMessage = (state) => {
  if (isNil(state)) return false;

  return (
    state.get('type') === 'info' &&
    state.get('body') &&
    state.get('body').key === NO_INTERNET_CONNECTION_MESSAGE_KEY
  );
};

const setMessage = (state, message) => {
  if (message == null) {
    return state.clear();
  }
  if (
    haveNoInternetConnectionMessage(state) &&
    isNil(message.body) &&
    message.type !== 'info'
  ) {
    return state;
  }
  return state
    .set('type', message.type)
    .set('body', message.body)
    .set('dismissible', message.dismissible);
};

const messageReducer = (state = Map(), action) => {
  switch (action.type) {
    case 'SET_MESSAGE':
      return setMessage(state, action.message);
    default:
      return state;
  }
};

export default messageReducer;
