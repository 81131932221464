import { Route, Routes } from 'react-router-dom';
import { usePermissions } from '@/modules/permissions';
import { ProtectedRoute } from '@/components/PrivateRoute';
import {
  Applications,
  NewApplication,
  QualificationPage,
  Suppliers,
  NewEntryQuestionnaire,
  ApplicationReview,
  ApplicationView,
  EditApplication,
  EditEntryQuestionnaire
} from '@/pages/qualification';
import { ApplicationTabs } from '@/pages/qualification/applications/types';
import {
  InboxApplicationsPage,
  OutboxApplicationsPage
} from '@/pages/qualification/applications';

export const QualificationRoutes = () => {
  const permissions = usePermissions();

  const havePartnersEditPermissions = permissions.havePartnersEditPermissions();

  const havePartnersReadPermissions = permissions.havePartnersReadPermissions();

  return (
    <ProtectedRoute redirectPath={null} isAllowed={havePartnersReadPermissions}>
      <Routes>
        <Route
          path="/:companyId/application/new"
          element={
            <ProtectedRoute
              redirectPath={null}
              isAllowed={havePartnersEditPermissions}>
              <NewApplication />
            </ProtectedRoute>
          }
        />
        <Route
          path="/applications/outbox/:id/edit"
          element={
            <ProtectedRoute isAllowed={havePartnersEditPermissions}>
              <EditApplication />
            </ProtectedRoute>
          }
        />
        <Route
          path="/entry-questionnaire/new"
          element={
            <ProtectedRoute
              redirectPath={null}
              isAllowed={havePartnersEditPermissions}>
              <NewEntryQuestionnaire />
            </ProtectedRoute>
          }
        />
        <Route
          path="/entry-questionnaire/edit"
          element={
            <ProtectedRoute isAllowed={havePartnersEditPermissions}>
              <EditEntryQuestionnaire />
            </ProtectedRoute>
          }
        />
        <Route
          path="/applications/inbox/:id"
          element={
            <ProtectedRoute
              redirectPath={null}
              isAllowed={havePartnersReadPermissions}>
              <ApplicationReview />
            </ProtectedRoute>
          }
        />
        <Route
          path="/applications/outbox/:id"
          element={
            <ProtectedRoute
              redirectPath={null}
              isAllowed={havePartnersReadPermissions}>
              <ApplicationView />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<QualificationPage />} />
        <Route
          path="/applications"
          element={<Applications initialTab={ApplicationTabs.inbox} />}
        />
        <Route path="/applications/inbox" element={<InboxApplicationsPage />} />
        <Route
          path="/applications/outbox"
          element={<OutboxApplicationsPage />}
        />
        <Route path="/suppliers" element={<Suppliers />} />
      </Routes>
    </ProtectedRoute>
  );
};
