import { useMemo } from 'react';
import { useEditableTableContext } from '@/components/EditableTable/EditableTable.context';
import { Checkbox } from '@/components/Checkbox';
import { CheckboxColumnDef } from '@/components/EditableTable/types';
import { isFunction, notEmpty } from '@/lib/utils';
import { Tooltip } from '@/components/Tooltip';
import { BaseHeader } from '../BaseHeader';

interface CheckboxHeaderProps {
  column: CheckboxColumnDef;
}

export function CheckboxHeader({ column }: CheckboxHeaderProps) {
  const { rows } = useEditableTableContext();

  const availableForSelect = useMemo(() => {
    const disableSelect = column.disabled;

    if (disableSelect === true) return [];

    if (isFunction(disableSelect)) {
      return rows
        .map((rowItem, rowItemIndex) =>
          !disableSelect(rowItem, rowItemIndex) ? rowItemIndex : null
        )
        .filter(notEmpty);
    }

    return rows.map((_, rowItemIndex) => rowItemIndex);
  }, [column, rows]);

  const isSelectedAll =
    availableForSelect.length > 0 &&
    column.selectedList.length === availableForSelect.length;
  const indeterminate =
    column.selectedList.length > 0 &&
    column.selectedList.length < availableForSelect.length;
  const disabled = isFunction(column.disabled)
    ? rows.every(column.disabled)
    : column.disabled;

  return (
    <BaseHeader>
      <Tooltip
        title={column.headerTitle}
        maxWidth={column.titleMaxWidth}
        align={column.titleAlign}>
        <Checkbox
          checked={isSelectedAll}
          onChange={column.onSelectAll}
          indeterminate={indeterminate}
          disabled={disabled}
        />
      </Tooltip>
    </BaseHeader>
  );
}
