import { defStruct } from '../../lib';

const methods = defStruct('FilterOptions', ['options']);

/**
 * @typedef FilterOptions
 * @typedef {import('./filterSelectValue')} FilterSelectValue
 */

/**
 * Create a FilterOptions
 *
 * @param {object.<string, Array>} options
 * @returns {FilterOptions}
 */
export const makeFilterOptions = (options = {}) =>
  methods.makeFilterOptions(options);

/**
 * Returns FilterOptions options by name
 *
 * @param {string} name
 * @param {FilterOptions} filterOptions
 * @returns {[FilterSelectValue]}
 */
export const findOptions = (name, filterOptions) => {
  const allOptions = methods.getOptions(filterOptions);
  const options = allOptions[name];
  return options !== undefined ? options : [];
};

/**
 *  Checks is instance is a FilterOptions type
 *
 * @param {any} any
 * @returns {boolean}
 */
export const isFilterOptions = (any) => methods.isFilterOptions(any);
