import { PropsWithChildren, useMemo } from 'react';
import { PopupProvider } from '@link/react-components';
import { NewFeaturesPopup, NewFeaturesPopupKey } from './newFeaturesPopup';

export { useNewFeaturesPopup } from './newFeaturesPopup';

export function PopupRoot(props: PropsWithChildren) {
  const { children } = props;

  const popups = useMemo(
    () => ({
      [NewFeaturesPopupKey]: NewFeaturesPopup
    }),
    []
  );

  return <PopupProvider popups={popups}>{children}</PopupProvider>;
}
