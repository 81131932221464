import React from 'react';

/* eslint-disable max-len */
const IconBlock = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      className="cws-icon-main-stroke"
      cx="9"
      cy="9"
      r="7.5"
      stroke="#767B92"
    />
    <path className="cws-icon-main-stroke" d="M14 4L4 14" stroke="#767B92" />
  </svg>
);
/* eslint-enable max-len */

export default IconBlock;
