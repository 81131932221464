import { Tooltip, Icon } from '@link/react-components';
import { useTranslate } from '@/hooks';
import { HelperProps, HelperContentProps } from './helper.types';

const echo = (value: string, _params: any) => value;
const HelperContent = ({
  text,
  id,
  textParams,
  children
}: HelperContentProps) => {
  const t = useTranslate() || echo;

  if (!text && !children) {
    return null;
  }

  return (
    <div id={id || 'popover-positioned-left'}>
      {t(text, textParams)}
      {children}
    </div>
  );
};

export function Helper({
  text,
  id,
  textParams,
  children,
  placement = 'right',
  maxWidth = 500,
  className,
  size = 14
}: HelperProps) {
  return (
    <Tooltip
      title={
        <HelperContent id={id} text={text} textParams={textParams}>
          {children}
        </HelperContent>
      }
      placement={placement}
      maxWidth={maxWidth}>
      <Icon
        iconName="question-fill"
        width={size}
        height={size}
        className={className}
      />
    </Tooltip>
  );
}
