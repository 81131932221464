import React from 'react';
import classNames from 'classnames';
import config from '../../config';
import { convertUrl } from '../../../../components/ChatWidget/modules/files/utils';

const Background = ({ show = false, avatar, label }) => {
  const backgroundSrc = avatar ? convertUrl(avatar) : config.defaultAvatar;
  return (
    <div
      className={classNames({
        'video-chat__background': true,
        show
      })}>
      <div className="video-char__background_avatar__container">
        {avatar ? (
          <img
            className="video-chat__background_avatar"
            src={backgroundSrc}
            alt="background"
          />
        ) : (
          <div className="video-chat__background_avatar">{label}</div>
        )}
      </div>
    </div>
  );
};

export default Background;
