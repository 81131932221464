import { prop, identity } from 'ramda';

export const makeAction = (type, payload) => ({ type, payload });
const payload = (action) => action.payload;
const reducerName = (action) => action.type;

const maybeReducer = (action, reducers) =>
  prop(reducerName(action), reducers) || identity;
const applyReducer = (state, action, reducer) =>
  reducer(state, payload(action));

export const makeReduxDispatcher = ({ name, initState, reducers }) => ({
  name,
  initState,
  reducers
});

const getName = (dispatcher, key) => `${dispatcher.name}/${key}`;

const mapReducersProps = (fn, dispatcher) =>
  Object.keys(dispatcher.reducers).reduce((acc, key) => {
    const value = dispatcher.reducers[key];
    const result = fn(key, value);
    const params = { key, value, ...result };
    return { ...acc, [params.key]: params.value };
  }, {});

export const extractActions = (dispatcher) =>
  mapReducersProps(
    (key) => ({
      value: (payloadData) => makeAction(getName(dispatcher, key), payloadData)
    }),
    dispatcher
  );

export const extractReducers = (dispatcher) =>
  mapReducersProps((key) => ({ key: getName(dispatcher, key) }), dispatcher);

const initHandler = (dispatcher) => {
  const reducers = extractReducers(dispatcher);
  return (state = dispatcher.initState, action) => {
    const reducer = maybeReducer(action, reducers);
    return applyReducer(state, action, reducer);
  };
};

export default ({ name, initState, reducers }) => {
  const dispatcher = makeReduxDispatcher({ name, initState, reducers });

  return {
    actions: extractActions(dispatcher),
    reducer: initHandler(dispatcher)
  };
};

// ============================================================================
// How to use
// ============================================================================

// Example with main method (recommended)
// const { actions, reducer } = prepareHandler({
//   name: 'my-dispatcher',
//   initState,
//   reducers: {
//     multiply: R.multiply,
//     add: R.add
//   }
// });
//
// export const { multiply, add } = actions;
// export default reducer;

// Example without main method
// const dispatcher = makeReduxDispatcher({
//   name: 'my-dispatcher',
//   initState,
//   reducers: {
//     multiply: R.multiply,
//     add: R.add
//   }
// });
//
// const { multiply, add } = extractActions(dispatcher);
// const reducers = extractReducers(dispatcher);
// export { multiply, add, reducers };
//
// {
//   [SET_ARCHIVES]: (chatWidget, chats) => setBlockArchive(chatWidget, chats),
//   [ADD_ARCHIVES]: (chatWidget, chats) => setBlockArchive(chatWidget, concatList(chatWidget.blockArchive, chats)),
//   ...reducers
// }
