import { getChatMsgs } from '@/components/ChatWidget/getter';
import blinkMsg from '@/components/ChatWidget/main/chat-messages/blinkMsg';
import { ac } from './shared/actions';

export const jumpToMsg =
  (message, { align = 'center', showBlink = true } = {}) =>
  async (dispatch, getState, service) => {
    const { findChunkPrev, findChunkNext } = service.chatMessage;
    const blockMessages = getChatMsgs(getState());
    const index = blockMessages.list.findIndex((msg) => msg.id === message.id);
    if (index === -1) {
      const date = message.time;
      const amountPrevMsg = 20;
      const amountNextMsg = 19;
      const [prev, next] = await Promise.all([
        findChunkPrev(message.channelId, date, amountPrevMsg),
        findChunkNext(message.channelId, date, amountNextMsg)
      ]);

      const list = prev.list.concat([message], next.list);
      const indexOfMsg = list.findIndex((msg) => msg.id === message.id);
      dispatch(
        ac.setManyMessages({
          list,
          amountLeftNext: next.amountLeft,
          amountLeftPrev: prev.amountLeft,
          initTopItemIndex: indexOfMsg
        })
      );

      setTimeout(() => dispatch(ac.scrollToMsg(message.id, { align })), 500); // time to render msgs
      if (showBlink) blinkMsg(message.id);
    } else {
      setTimeout(() => dispatch(ac.scrollToMsg(message.id, { align })), 500);
      if (showBlink) blinkMsg(message.id);
    }
  };
