import {
  Table as MUITable,
  TableBody as MUITableBody,
  TableCell as MUITableCell,
  TableClasses,
  TableHead as MUITableHead,
  TableRow as MUITableRow
} from '@mui/material';
import { useBlockLayout, useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';
import cn from 'classnames';
import styles from './Table.module.scss';
import { TableProps } from './Table.types';

/*
 *
 * TODO Внедрить виртуализацию.
 * В подходе который был применен, используется хук useSticky, который работает только с тегами таблицы.
 * По дефолту AutoSize и List используют div.
 * И при оверайде тега, результат не изменяется.
 * Также сложность с получением высоты строки, так как она может быть разная.
 *
 * */

const tableClasses: Partial<TableClasses> = {
  root: styles.tableRoot
};

export function Table<CellData>({
  data,
  columns,
  className,
  stickyHeader = false
}: TableProps<CellData>) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data
      },
      useBlockLayout,
      useSticky
    );

  return (
    <MUITable
      {...getTableProps()}
      classes={tableClasses}
      className={cn(
        styles.table,
        { [styles.sticky]: stickyHeader },
        className
      )}>
      <MUITableHead className={styles.header}>
        {headerGroups.map((headerGroup) => (
          <MUITableRow
            {...headerGroup.getHeaderGroupProps()}
            className={styles.tr}>
            {headerGroup.headers.map((column) => (
              <MUITableCell {...column.getHeaderProps()} className={styles.th}>
                {column.render('Header')}
              </MUITableCell>
            ))}
          </MUITableRow>
        ))}
      </MUITableHead>
      <MUITableBody {...getTableBodyProps()} className={styles.body}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <MUITableRow {...row.getRowProps()} className={styles.tr}>
              {row.cells.map((cell) => (
                <MUITableCell {...cell.getCellProps()} className={styles.td}>
                  {cell.render('Cell')}
                </MUITableCell>
              ))}
            </MUITableRow>
          );
        })}
      </MUITableBody>
    </MUITable>
  );
}
