import { useCallback, useEffect, useState } from 'react';
import { SortDirections, SortType } from '@/components/EditableTable/types';
import { isFunction } from '@/lib/utils';

type ToggleSortType = (accessor: string, direction: SortDirections) => void;
type CallbackType = ((sort: SortType) => void) | undefined;

export function useSort(cb: CallbackType): [SortType, ToggleSortType] {
  const [sort, setSort] = useState<SortType>({} as SortType);

  useEffect(() => {
    if (isFunction(cb)) {
      cb(sort);
    }
  }, [sort]);

  const toggleSort = useCallback<ToggleSortType>((accessor, direction) => {
    setSort((prevSort) => {
      if (!prevSort[accessor]) {
        return { ...prevSort, [accessor]: direction };
      }

      const shallowPrevSort = { ...prevSort };
      if (prevSort[accessor] === direction) {
        delete shallowPrevSort[accessor];
      } else {
        shallowPrevSort[accessor] = direction;
      }

      return shallowPrevSort;
    });
  }, []);

  return [sort, toggleSort];
}
