import { SET_ONLINES, CONNECTED, DISCONNECTED } from './constants';

export const setOnlines = (list) => ({
  type: SET_ONLINES,
  payload: { list }
});

export const connected = (id) => ({
  type: CONNECTED,
  payload: { employeeId: id }
});

export const disconnected = (id) => ({
  type: DISCONNECTED,
  payload: { employeeId: id }
});
