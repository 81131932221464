import { Map, List, fromJS, Set } from 'immutable';

const loadTable = (state, { items, size }) =>
  state
    .set('items', fromJS(items))
    .set('size', size || null)
    .set('selected', state.get('selected') || Set());

const loadTableProducts = (state, { items, categoryId, size, limit, skip }) =>
  state
    .setIn(['items', categoryId], fromJS(items))
    .set('size', size)
    .set('limit', limit)
    .set('skip', skip);

const loadPublishedTable = (state, items) =>
  state
    .remove('publishedItems')
    .set('items', fromJS(items))
    .set('selected', Set());

const loadTablePublishedProducts = (
  state,
  { items, categoryId, size, limit, skip }
) =>
  state
    .setIn(['publishedItems', categoryId], fromJS(items))
    .set('size', size)
    .set('limit', limit)
    .set('skip', skip);

const clearTable = (state) => state.set('items', List()).set('selected', Set());

const addTableItems = (state, items) =>
  state.update('items', List(), (oldItems) => oldItems.concat(fromJS(items)));

const updateTableItems = (state, updatedItems) =>
  state.update('items', (oldItems) => {
    let result = oldItems;
    fromJS(updatedItems).forEach((updatedItem) => {
      result = result.update(
        result.findIndex((item) => item.get('id') === updatedItem.get('id')),
        () => updatedItem
      );
    });
    return result;
  });

const touch = (state, itemId) =>
  state.update('selected', Set(), (selected) =>
    selected.includes(itemId) ? selected.remove(itemId) : selected.add(itemId)
  );

const touchSet = (state, ids) => state.set('selected', Set(ids));

const clearTouch = (state) => state.update('selected', () => Set());

const touchOne = (state, itemId) =>
  state.update('selected', Set(), (selected) =>
    selected.includes(itemId) ? Set() : Set([itemId])
  );

const deselectAll = (state) => state.set('selected', Set());

const removeFromTable = (state, { list }) =>
  deselectAll(state).update('items', (items) =>
    items.filter((item) => !list.includes(item.get('id')))
  );

const removeFromTableProducts = (state, product) =>
  state
    .remove('selected')
    .updateIn(
      ['items', product.get('ProductsCategories.categoryId').toString()],
      (item) => item.filter((point) => point.get('id') !== product.get('id'))
    );

const getStatus = (item) => {
  const startPublish = item.get('startPublish');
  const publishDate = item.get('publishDate');
  if (startPublish && (!publishDate || startPublish > publishDate)) {
    return 'inPublication';
  }
  return !publishDate || item.get('lastUpdate') > publishDate
    ? 'draft'
    : 'publish';
};

const getComparator = (state, key) => {
  if (key === 'status') {
    return (a, b) => getStatus(a).localeCompare(getStatus(b));
  }
  return typeof state.getIn(['items', 0, key]) === 'number'
    ? (a, b) => b.get(key) - a.get(key)
    : (a, b) =>
        (a.get(key) || '')
          .toLowerCase()
          .localeCompare((b.get(key) || '').toLowerCase()); // ^ sortedStrict;
};

const sort = (state, key) => {
  const wasSortedByThisKey = state.getIn(['sorted', 'key']) === key;
  return state
    .update('items', (items) =>
      wasSortedByThisKey
        ? items && items.reverse()
        : items && items.sort(getComparator(state, key))
    )
    .setIn(['sorted', 'key'], key)
    .updateIn(['sorted', 'strict'], (flag) => !wasSortedByThisKey || !flag);
};

const init = () =>
  Map({
    selected: Set(),
    items: List()
  });

export default (state = init(), action) => {
  switch (action.type) {
    case 'TABLE_UPDATE_ITEMS':
      return updateTableItems(state, action.items);
    case 'TABLE_LOAD':
      return loadTable(state, action);
    case 'TABLE_LOAD_PRODUCTS':
      return loadTableProducts(state, action);
    case 'TABLE_LOAD_PUBLISHED':
      return loadPublishedTable(state, action.items);
    case 'TABLE_LOAD_PUBLISHED_PRODUCTS':
      return loadTablePublishedProducts(state, action);
    case 'TABLE_CLEAR':
      return clearTable(state);
    case 'TABLE_ADD':
      return addTableItems(state, action.items);
    case 'TABLE_TOUCH':
      return touch(state, action.id);
    case 'TABLE_SET_TOUCH':
      return touchSet(state, action.ids);
    case 'TABLE_CLEAR_TOUCH':
      return clearTouch(state);
    case 'TABLE_PRODUCTS_TOUCH':
      return touch(state, action.id);
    case 'TABLE_SINGLE_TOUCH':
      return touchOne(state, action.id);
    case 'TABLE_DESELECT_ALL':
      return deselectAll(state);
    case 'TABLE_REMOVE_LIST':
      return removeFromTable(state, action);
    case 'TABLE_PRODUCTS_REMOVE_LIST':
      return removeFromTableProducts(state, action.product);
    case 'TABLE_SORT':
      return sort(state, action.key);
    default:
      return state;
  }
};
