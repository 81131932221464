.chip {
  max-width: 100%;

  .root {
    background-color: var(--color-alpha-blue-100);
    padding: var(--indent-xxs) var(--indent-xs);
    border-radius: var(--indent-xxs);
    display: flex;
    align-items: center;
    gap: var(--indent-xs);
    height: fit-content;

    .label {
      padding: 0;
    }

    .deleteButton {
      display: inline-block;
      vertical-align: text-bottom;
    }

    .deleteButton svg {
      width: 12px;
      height: 12px;
    }

    .errorDeleteButton svg path {
      stroke: var(--color-white);
    }
  }

  .errorRoot {
    background-color: var(--color-red-error);
  }

  .content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    flex-wrap: nowrap;
  }

  .labelTextLimited {
    width: calc(100% - 16px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .labelText {
    white-space: break-spaces;
  }

  .errorLabelText {
    color: var(--color-white);
  }
}
