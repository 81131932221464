import {
  allPass,
  curry,
  flatten,
  includes,
  isEmpty,
  isNil,
  keys,
  last,
  length,
  lt,
  not,
  nth,
  omit,
  pipe,
  prop,
  values,
  equals
} from 'ramda';
import {
  ACCEPTABLE_AGREEMENT_CARDS,
  STEP_TYPES,
  THRESHOLD_FOR_PARALLEL_STEP,
  routeType,
  serviceTypes,
  serviceCardIds,
  createStepBtn,
  MAX_STEPS_IN_PATH
} from './constants';

const getLastStepFromPath = last;

export const getKeysFromStep = keys;

const curriedLt = curry(lt);

const excludeIdFromStep = omit(['id']);
const getValuesListFromStepTypes = values;
const makeFlattenValuesList = flatten;
const getLengthOfList = length;
const isListLengthMoreThenThreshold = curriedLt(THRESHOLD_FOR_PARALLEL_STEP);

export const getLengthOfPath = length;
const pathNotAchieveLimit = pipe(
  getLengthOfPath,
  equals(MAX_STEPS_IN_PATH),
  not
);

export const isParallelStep = pipe(
  excludeIdFromStep,
  getValuesListFromStepTypes,
  makeFlattenValuesList,
  getLengthOfList,
  isListLengthMoreThenThreshold
);

export const isPathExist = (path) => !isEmpty(path);

const isStepKeysHasPublication = (lastStepKeys) =>
  includes(STEP_TYPES.publication, lastStepKeys);

// const isNotPathAchieveLimit = (path) => length(path) < MAX_STEPS_IN_PATH

export const lastStepHasNotPublication = pipe(
  getLastStepFromPath,
  getKeysFromStep,
  isStepKeysHasPublication,
  not
);
export const lastStepHasPublication = pipe(
  getLastStepFromPath,
  getKeysFromStep,
  isStepKeysHasPublication
);

export const isStepHasPublication = pipe(
  getKeysFromStep,
  isStepKeysHasPublication
);

export const canDrawLineToCreateButton = (path, isEndStepExist) =>
  allPass([isPathExist, lastStepHasNotPublication])(path) && !isEndStepExist;

export const canDeleteEndStepCard = allPass([
  pathNotAchieveLimit,
  lastStepHasNotPublication
]);

export const endIdForBeginStepCard = (path, isEndStepExist) => {
  const pathLen = getLengthOfPath(path);

  if (!pathLen && isEndStepExist) {
    return serviceCardIds[STEP_TYPES.end];
  }
  if (!pathLen) {
    return createStepBtn;
  }
  return getPropId(path[0]);
};

export const startIdForEndStepCard = (path) => {
  const pathLen = getLengthOfPath(path);

  if (!pathLen) {
    return serviceCardIds[STEP_TYPES.begin];
  }
  return getLastStepId(path);
};

export const getPropId = prop('id');

export const getLastStepId = pipe(getLastStepFromPath, getPropId);

export const isCardsExist = (cards) => !isNil(cards) && !isEmpty(cards);

const curriedNth = curry(nth);

export const isServiceType = (type) => serviceTypes.includes(type);
export const isTypeBegin = (type) => type === STEP_TYPES.begin;

export const isTypeEnd = (type) => type === STEP_TYPES.end;

const getPreLastStepFromSteps = curriedNth(-2);

export const getPreLastStepIdFromSteps = pipe(
  getPreLastStepFromSteps,
  getPropId
);

export const numberOfAgreementCards = (cards) => length(cards);

export const isCanAddAgreementCard = (cards) =>
  !isCardsExist(cards) ||
  numberOfAgreementCards(cards) < ACCEPTABLE_AGREEMENT_CARDS;

export const isCanAddIntroductionCard = (cards) => !isCardsExist(cards);

export const isPublicationType = (type) => STEP_TYPES.publication === type;

export const isAgreementType = (type) => STEP_TYPES.agreement === type;

/* eslint-disable */
export const isErrorOnSave = (path) => {
  for (let step of path) {
    if (!isNil(step[STEP_TYPES.publication])) {
      const publications = step[STEP_TYPES.publication];
      for (let publication of publications) {
        if (isEmpty(publication.members)) return true;
      }
    }
    if (!isNil(step[STEP_TYPES.agreement])) {
      const agreements = step[STEP_TYPES.agreement];
      for (let agreement of agreements) {
        if (isEmpty(agreement.members)) return true;
      }
    }
    if (!isNil(step[STEP_TYPES.introduction])) {
      const introductions = step[STEP_TYPES.introduction];
      for (let introduction of introductions) {
        if (length(introduction.members) < 1) return true;
      }
    }
  }
  return false;
};
/* eslint-enable  */
export const getAllUsersInTheBuilder = (builder) => {
  const users = builder.path.map((step) => {
    const subUsers = [];
    if (!isNil(step[STEP_TYPES.publication])) {
      subUsers.push(...step[STEP_TYPES.publication]);
    }
    if (!isNil(step[STEP_TYPES.agreement])) {
      subUsers.push(...step[STEP_TYPES.agreement]);
    }
    if (!isNil(step[STEP_TYPES.introduction])) {
      subUsers.push(...step[STEP_TYPES.introduction]);
    }
    return subUsers.map((card) => card.members);
  });
  return [...flatten(users)].map((user) => user.id);
};

export const isRequestType = equals(routeType.request);
export const isResponseType = equals(routeType.response);
