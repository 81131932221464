import { ProductDto } from './product';

export class ResponseInfoDto {
  constructor(props) {
    this.requestId = props.requestId;
    this.text = props.text;
    this.links = props.links;
    this.files = props.files;
    this.products = props.products.map(
      (product, index) =>
        new ProductDto({ ...product, id: product.id ?? index })
    );
    this.extraOptions = props.extraOptions;
    this.currency = props.currency;
  }
}
