import { getStyle, setStyle } from './storage';
import { SHORT, FULL, MENU_STYLE_TOGGLE } from './constans';

const defaultValue = () => {
  let savedStyle = getStyle();

  if (savedStyle === null) {
    setStyle(FULL);
    savedStyle = FULL;
  }

  return {
    menuStyle: savedStyle
  };
};

const reducers = {
  [MENU_STYLE_TOGGLE]: (state) => {
    const nextStyle = state.menuStyle === FULL ? SHORT : FULL;

    setStyle(nextStyle);
    return { ...state, menuStyle: nextStyle };
  }
};

export default (state = defaultValue(), { type, payload }) => {
  if (reducers[type]) return reducers[type](state, payload);
  return state;
};
