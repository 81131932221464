export class BasePermissionsStrategy {
  #baseReadResult = true;

  #baseResult = false;

  setPermissions() {}

  haveCompanyReadPermissions() {
    return this.#baseReadResult;
  }

  haveCompanyEditPermissions() {
    return this.#baseResult;
  }

  haveCompanyDeletePermissions() {
    return this.#baseResult;
  }

  haveRoleEditPermissions() {
    return this.#baseResult;
  }

  haveEmployeesReadPermissions() {
    return this.#baseReadResult;
  }

  haveEmployeesEditPermissions() {
    return this.#baseResult;
  }

  haveEmployeesDeletePermissions() {
    return this.#baseResult;
  }

  havePermissionsEditPermissions() {
    return this.#baseResult;
  }

  haveRequestsReadPermissions() {
    return this.#baseReadResult;
  }

  haveRequestsEditPermissions() {
    return this.#baseResult;
  }

  haveRequestsWithConfirmationCreatePermissions() {
    return this.#baseResult;
  }

  haveRequestsWithConfirmationCanExecutePermissions() {
    return this.#baseResult;
  }

  haveNeedsReadPermissions() {
    return this.#baseReadResult;
  }

  haveNeedsEditPermissions() {
    return this.#baseResult;
  }

  hasNeedsAcceptancePermissions() {
    return this.#baseResult;
  }

  haveResponsesWithConfirmationCanExecutePermissions() {
    return this.#baseResult;
  }

  haveResponsesReadPermissions() {
    return this.#baseReadResult;
  }

  haveResponsesEditPermissions() {
    return this.#baseResult;
  }

  haveRoutesReadPermissions() {
    return this.#baseReadResult;
  }

  haveRoutesEditPermissions() {
    return this.#baseResult;
  }

  haveAdministrationReadPermissions() {
    return this.#baseResult;
  }

  haveAdministrationEditPermissions() {
    return this.#baseResult;
  }

  haveCompaniesReadPermissions() {
    return this.#baseReadResult;
  }

  havePartnersReadPermissions() {
    return this.#baseReadResult;
  }

  havePartnersEditPermissions() {
    return this.#baseResult;
  }

  hasManualProposalCreationPrivilege() {
    return this.#baseResult;
  }

  hasWarehousesManagementPrivilege() {
    return this.#baseResult;
  }

  /* Goodwix */
  havePricesReadPermissions() {
    return this.#baseReadResult;
  }

  havePricesEditPermissions() {
    return this.#baseResult;
  }

  havePricesDeletePermissions() {
    return this.#baseResult;
  }

  haveStocksReadPermissions() {
    return this.#baseReadResult;
  }

  haveStocksEditPermissions() {
    return this.#baseResult;
  }

  haveStocksDeletePermissions() {
    return this.#baseResult;
  }

  haveBillingReadPermissions() {
    return this.#baseReadResult;
  }

  haveBillingEditPermissions() {
    return this.#baseResult;
  }

  haveBillingDeletePermissions() {
    return this.#baseResult;
  }

  haveCatalogsReadPermissions() {
    return this.#baseReadResult;
  }

  haveCatalogsEditPermissions() {
    return this.#baseResult;
  }

  haveCatalogsDeletePermissions() {
    return this.#baseResult;
  }

  haveCustomersReadPermissions() {
    return this.#baseReadResult;
  }

  haveCustomersEditPermissions() {
    return this.#baseResult;
  }

  haveCustomersDeletePermissions() {
    return this.#baseResult;
  }

  haveSuppliersReadPermissions() {
    return this.#baseReadResult;
  }

  haveSuppliersEditPermissions() {
    return this.#baseResult;
  }

  haveSuppliersDeletePermissions() {
    return this.#baseResult;
  }

  haveCustomersOrdersReadPermissions() {
    return this.#baseReadResult;
  }

  haveCustomersOrdersEditPermissions() {
    return this.#baseResult;
  }

  haveCustomersOrdersDeletePermissions() {
    return this.#baseResult;
  }

  haveSuppliersOrdersReadPermissions() {
    return this.#baseReadResult;
  }

  haveSuppliersOrdersEditPermissions() {
    return this.#baseResult;
  }

  haveSuppliersOrdersDeletePermissions() {
    return this.#baseResult;
  }

  haveCompanyAccessTokenGeneratePermissions() {
    return this.#baseResult;
  }

  haveCostAccountingDistributionPermissions() {
    return this.#baseResult;
  }

  haveCostAccountingAnalyzePermissions() {
    return this.#baseResult;
  }
}
