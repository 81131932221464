import React from 'react';

function IconFormatText() {
  return (
    <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.609 1.091a.915.915 0 0 0-.62.571c-.048.137-.054.279-.054 1.368 0 1.193.001 1.217.066 1.338.192.36.591.473.93.263a.576.576 0 0 0 .225-.247l.084-.161V2.37h5.13v13.77H5.827l-.146.098c-.423.282-.382.875.079 1.121.1.053.245.056 3.18.065 2.26.007 3.118 0 3.237-.025a.666.666 0 0 0 .481-.472.64.64 0 0 0-.334-.73c-.105-.053-.192-.057-1.373-.057H9.69V2.37h5.13v.893c0 .491.013.938.029.995.108.376.54.584.886.426a.699.699 0 0 0 .375-.458c.024-.113.032-.509.025-1.316-.01-1.132-.011-1.158-.079-1.308a1.018 1.018 0 0 0-.471-.468l-.15-.069-6.345-.006c-4.946-.004-6.375.003-6.481.032"
        fill="#5B5F73"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default IconFormatText;
