export const showModal = (name, props) => ({
  type: 'SHOW_MODAL',
  name,
  props
});

export const hideModal = () => ({
  type: 'HIDE_MODAL'
});

export const showPrevModal = () => ({
  type: 'SHOW_PREVIOUS_MODAL'
});

export const hideModalDialog = () => (dispatch) => dispatch(hideModal());

export const hideTargetModal = (modalName) => (dispatch, getState) => {
  const modal = getState().getIn(['modal']);

  if (modal.current && modal.current.name === modalName) {
    dispatch(hideModalDialog());
  }
};
