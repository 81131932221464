import React from 'react';

function IconBtnSendDisabled() {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9236 8.38855C18.7236 8.78855 18.9236 9.55521 18.9236 9.88855L10.9409 9.88875L18.9236 9.88895C18.9236 10.2223 18.7236 10.9889 17.9236 11.3889L2.92358 18.8889C1.92358 19.3887 0.923584 19.0889 0.923584 17.8889C0.923584 16.7562 1.77901 12.7718 2.19788 10.8887H8.92358C9.47587 10.8887 9.92358 10.441 9.92358 9.88867C9.92358 9.33639 9.47587 8.88867 8.92358 8.88867H2.19784C1.77897 7.00552 0.923584 3.02128 0.923584 1.88855C0.923584 0.688548 1.92358 0.388747 2.92358 0.888548L17.9236 8.38855Z"
        fill="#D5E0FF"
      />
    </svg>
  );
}

export default IconBtnSendDisabled;
