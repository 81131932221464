import { assoc, isEmpty, isNil, mergeRight } from 'ramda';
import { nanoid } from 'nanoid';
import { isFuture } from 'date-fns';
import {
  getChatBlock,
  getChatInput,
  getEditMsg,
  getOpenedChat,
  getReplyMessageId,
  getReplyMessages
} from '@/components/ChatWidget/getter';
import { getItem } from '@/components/ChatWidget/data-type/block-list';
import { writeUnsentChatMessagesToLS } from '@/components/ChatWidget/useCases/writeUnsentChantMessageToLS';
import { setReadMessagesInChat } from '@/components/ChatWidget/modules/messages/useCases';
import * as delayedMessagesCases from '@/components/ChatWidget/modules/delayedMessages/useCases';
import * as delayedMessagesGetters from '@/components/ChatWidget/modules/delayedMessages/getters';
import { makeBlockEditMsg } from '@/components/ChatWidget/data-type/block-msg-edit';
import { makeBlockInput } from '@/components/ChatWidget/data-type/block-input';
import { sendForwardMessages } from '@/components/ChatWidget/modules/forwardMessages/useCases';
import { setErrorMessage, setInfoMessage } from '@/action-creators/message';
import { isChatInputLoadingFilesIdsEmpty } from '@/components/ChatWidget/modules/messageInput/getters';
import { addMessageToWaiting } from '@/components/ChatWidget/modules/waitingMessages/useCases';
import { setDraftMessage } from '@/components/ChatWidget/modules/draftMessages/useCases';
import * as typingCases from '@/components/ChatWidget/modules/typing/useCases';
import * as messageInputCases from '@/components/ChatWidget/modules/messageInput/useCases';
import { clearMessageDivider } from '@/components/ChatWidget/modules/messagesDivider';
import { showProfile } from '@/components/ChatWidget/modules/profile';
import * as chatInputStorageCases from '@/components/ChatWidget/modules/chatInputStorage/useCases';
import { dispatcher } from '@/components/ChatWidget/useCases/utils';
import { ac } from './shared/actions';
import { sckt } from './shared/socket';

export const handleInputMsg = (event, value) => async (dispatch, getState) => {
  const getChatTypeAndId = (state) => {
    const openedChat = getOpenedChat(state);
    return isNil(openedChat)
      ? null
      : { chatId: openedChat.id, chatType: openedChat.type };
  };

  const state = getState();
  const { chatId } = getChatTypeAndId(state) || {};
  const deliveryId = nanoid();
  const haveInternetConnection =
    getState().get('connections')?.toJS().internet ?? false;

  const handleUnsentMessages = (type, removedFiles, updateId) => {
    const avatarSrc = getState().getIn(['user', 'user', 'avatar']);
    const employeeId = getState().getIn(['user', 'user', 'employeeId']);
    const firstName = getState().getIn(['user', 'user', 'firstName']);
    const lastName = getState().getIn(['user', 'user', 'lastName']);
    const isEmptyMsg = ({ msg, files = [] }) => Boolean(!msg && !files.length);
    const updateChatListWithoutConnection = (message) => {
      if (!haveInternetConnection) {
        const chatList = getChatBlock(state);
        const chat = getItem(chatList, chatId);
        const updatedChat = assoc('lastMessage', message, chat);
        dispatch(ac.updateChat(updatedChat));
      }
    };

    const createUnsentMsg = () => {
      const { html, msg, mentions } = value;
      const inputBlock = getChatInput(state);
      return {
        avatarSrc,
        bookmarkedBy: [],
        channelId: chatId,
        edited: false,
        employeeId,
        files: inputBlock.files,
        hidden: false,
        html,
        id: deliveryId,
        deliveryId,
        mentions,
        msg,
        time: new Date(),
        type: 'MsgUnsent',
        userName: `${lastName} ${firstName}`
      };
    };
    const createReplyUnsentMsg = () => {
      const unsentReplyMessage = createUnsentMsg();
      const replyMessage = getReplyMessages(state).messages[0];
      const preparedReplyMessage = assoc(
        'targetMsg',
        replyMessage,
        unsentReplyMessage
      );
      return preparedReplyMessage;
    };

    if (type === 'MsgUser') {
      const msg = createUnsentMsg();
      if (!isEmptyMsg(msg)) {
        dispatch(ac.addUnsentMessage({ chatId, employeeId, message: msg }));
        updateChatListWithoutConnection(msg);
        dispatch(writeUnsentChatMessagesToLS());
      }
      return msg;
    }
    if (type === 'MsgReply') {
      const msg = createReplyUnsentMsg();
      dispatch(ac.addUnsentMessage({ chatId, employeeId, message: msg }));
      updateChatListWithoutConnection(msg);
      dispatch(writeUnsentChatMessagesToLS());
      return msg;
    }
    if (type === 'MsgUpdate') {
      const msg = createReplyUnsentMsg();
      const updatedMsg = mergeRight(msg, {
        edited: true,
        removedFiles,
        id: updateId
      });
      dispatch(
        ac.addUnsentMessage({ chatId, employeeId, message: updatedMsg })
      );
      updateChatListWithoutConnection(updatedMsg);
      dispatch(writeUnsentChatMessagesToLS());
      return msg;
    }
    return undefined;
  };

  const resend = () => {
    const [chatSendId, blockListUnsent] = value;
    const { list } = blockListUnsent;
    if (list.length > 0) {
      list.forEach((unsentMsg) => {
        const {
          channelId,
          id,
          html,
          msg,
          mentions,
          removedFiles,
          files,
          forwardedMessages,
          replyByMessageId,
          // eslint-disable-next-line no-shadow
          deliveryId,
          edited,
          targetMsg,
          originId
        } = unsentMsg;

        if (originId) {
          sckt.sendMsg({
            channelId: chatSendId,
            msg: '',
            html: '<p></p>',
            mentions,
            files: [],
            forwardedMessages: { msgsIds: [originId], chatId: channelId },
            replyByMessageId: null,
            deliveryId: id
          });
          return;
        }
        if (edited) {
          sckt.updateMsg({
            chatId: channelId,
            messageId: id,
            html,
            msg,
            mentions,
            removedFiles,
            deliveryId
          });
          return;
        }
        if (targetMsg) {
          const replyOriginId = targetMsg.id;
          sckt.sendMsg({
            channelId,
            msg,
            html,
            mentions,
            files,
            forwardedMessages,
            replyByMessageId: replyOriginId,
            deliveryId
          });
          return;
        }
        sckt.sendMsg({
          channelId,
          msg,
          html,
          mentions,
          files,
          forwardedMessages,
          replyByMessageId,
          deliveryId
        });
      });
    }
  };

  const submit = () => {
    const editMsg = getEditMsg(state).message;
    dispatch(setReadMessagesInChat(chatId));

    const updateMsg = ({
      inputBlock,
      html,
      msg,
      mentions,
      isDelayedMsgMode
    }) => {
      const { id, files: msgFiles } = editMsg;
      const { files } = inputBlock;
      const removedFiles = msgFiles
        .filter((f) => !files.find((i) => i.id === f.id))
        .map((i) => i.id);
      const addedFiles = files.filter(
        (file) => !msgFiles.find((msgFile) => msgFile.id === file.id)
      );

      if (isDelayedMsgMode) {
        dispatch(
          delayedMessagesCases.editDelayedMessage({
            messageId: id,
            data: { html, msg, mentions, removedFiles, deliveryId }
          })
        );
        return;
      }
      sckt.updateMsg({
        chatId,
        messageId: id,
        html,
        msg,
        mentions,
        addedFiles,
        removedFiles,
        deliveryId
      });
    };

    if (chatId !== null) {
      if (!isEmpty(editMsg)) {
        const { html, msg, mentions } = value;

        const inputBlock = getChatInput(state);
        const plannedDate = delayedMessagesGetters.getDelayMessagesPlannedDate(
          getState()
        );

        updateMsg({
          inputBlock,
          html,
          msg,
          mentions,
          isDelayedMsgMode: !isNil(plannedDate)
        });
        dispatch(ac.setEditingMessage(makeBlockEditMsg()));
        dispatch(ac.setInputBlock(makeBlockInput()));
      } else {
        const isDelayedMsgMode = checkIsDelayedMessagesMode();
        const inputBlock = getChatInput(state);

        const replyByMessageId = getReplyMessageId(state);
        if (!isDelayedMsgMode && replyByMessageId) {
          dispatch(ac.clearReplyMsg(replyByMessageId));
          handleUnsentMessages('MsgReply');
        }
        const { html, msg, mentions } = value;

        dispatch(
          sendForwardMessages({
            mentions,
            sendMessage: initSendForwardMessage(),
            isCreateUnsent: !isDelayedMsgMode
          })
        );
        dispatch(ac.removeLastUnreadMsg(chatId));

        if (!haveInternetConnection) {
          const onCloseCallback = () =>
            dispatch(
              setInfoMessage(
                {
                  key: `Messenger IstockLink is trying to connect to the Internet`
                },
                false
              )
            );
          dispatch(
            setErrorMessage({
              key: 'Message not sent No internet connection',
              onCloseCallback
            })
          );
        }

        if (!isDelayedMsgMode && !replyByMessageId) {
          handleUnsentMessages('MsgUser');
        }

        if (!isEmptyMsg(msg, inputBlock.files)) {
          const message = {
            channelId: chatId,
            msg,
            html,
            mentions,
            files: inputBlock.files,
            forwardedMessages: null,
            replyByMessageId,
            deliveryId
          };

          if (isChatInputLoadingFilesIdsEmpty(getState())) {
            sendSimpleMessage(message);
          } else {
            dispatch(addMessageToWaiting(message));
          }
          dispatch(ac.setInputBlock(makeBlockInput()));
        }
      }
    }

    return undefined;

    function sendSimpleMessage(msg, idx) {
      return checkIsDelayedMessagesMode()
        ? dispatch(delayedMessagesCases.sendDelayedMessage({ ...msg, idx }))
        : sckt.sendMsg(msg);
    }

    function initSendForwardMessage() {
      return checkIsDelayedMessagesMode()
        ? (msg, idx) =>
            dispatch(delayedMessagesCases.sendDelayedMessage({ ...msg, idx }))
        : undefined;
    }

    function checkIsDelayedMessagesMode() {
      const plannedDate = delayedMessagesGetters.getDelayMessagesPlannedDate(
        getState()
      );

      return !isNil(plannedDate) && isFuture(new Date(plannedDate));
    }

    function isEmptyMsg(msgText, msgFiles) {
      return isEmpty(msgText) && isEmpty(msgFiles);
    }
  };

  const onChangeChat = () => {
    if (isNil(value) || isEmpty(value.prevText)) return;

    const { prevId, prevText } = value;
    dispatch(setDraftMessage(prevId, { withTimeout: false, text: prevText }));
  };

  const handlers = {
    typing: () => typingCases.typing(chatId),
    submit,
    resend,
    edit: () => dispatch(messageInputCases.editMessage(chatId)),
    'attach-storage': async () =>
      dispatch(messageInputCases.attachFilesFromStorageModal(chatId)),
    'attach-local': async () =>
      dispatch(messageInputCases.attachLocalFiles(chatId, value)),
    'delete-file': async () =>
      dispatch(messageInputCases.removeUnsentFile(chatId, value)),
    'read-messages': () => dispatch(setReadMessagesInChat(chatId)),
    'remove-divider': () => dispatch(clearMessageDivider()),
    'view-specified-profile': () => dispatch(showProfile(value)),
    'change-text': () => dispatch(messageInputCases.changeText(value)),
    'set-draft': () => {
      dispatch(setDraftMessage(chatId, { withTimeout: true, text: value }));
    },
    'change-chat': onChangeChat,
    'set-text': () =>
      dispatch(chatInputStorageCases.saveChatInputDataToStorage(value))
  };

  await dispatcher('handleInputMsg', handlers, event);
};
