import { useCallback, useState, useEffect } from 'react';
import { Switch as MUISwitch } from '@mui/material';
import cn from 'classnames';
import { isBoolean, isFunction } from '@/lib/utils';
import { Typography } from '@/components/Typography';
import { SwitchProps } from './Switch.types';
import styles from './Switch.module.scss';

export function Switch({
  defaultChecked = false,
  value,
  onSwitch,
  className,
  label,
  disabled = false
}: SwitchProps) {
  const [checked, setChecked] = useState(defaultChecked);
  const handleToggle = useCallback(() => {
    if (!disabled) {
      setChecked((prev) => {
        const newState = !prev;
        if (onSwitch && isFunction(onSwitch)) {
          onSwitch(isBoolean(value) ? !value : newState);
        }

        return newState;
      });
    }
  }, [onSwitch, disabled, value]);

  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  return (
    <div
      className={cn(styles.switch, className, { [styles.disabled]: disabled })}
      data-testid="switch"
      onClick={handleToggle}
      role="presentation">
      <MUISwitch checked={value ?? checked} disableRipple disabled={disabled} />
      {label && (
        <Typography
          className={cn(styles.label, { [styles.disabled]: disabled })}
          variant="body1Reg">
          {label}
        </Typography>
      )}
    </div>
  );
}
