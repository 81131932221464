import { assoc, uniq } from 'ramda';

const EMPTY_KIND = null;

/**
 * @typedef {(number|string)} Id
 */

/**
 * Any object with property id and any properties
 *
 * @typedef {object} Item
 * @property {Id} id
 */

/**
 * @typedef BlockGroup
 * @property {(EMPTY_KIND|string)} kind
 * @property {[Id]} selectIds
 * @property {[Item]} list
 */

/**
 * Creates a block list data type
 *
 * @param {object} params
 * @param {[object]} params.list
 * @param {(EMPTY_KIND|string)} params.kind
 * @param {[Id]} params.selectIds
 * @param {boolean} params.isLoading
 * @returns {BlockGroup}
 */
const makeBlockGroup = ({
  list = [],
  kind = EMPTY_KIND,
  selectIds = [],
  isLoading = false
} = {}) => ({
  type: 'BlockGroup',
  selectIds,
  list,
  kind,
  isLoading
});

/**
 * Sets list
 *
 * @param {BlockGroup} blockGroup
 * @param {[object]} list
 * @returns {BlockGroup}
 */
const setList = (blockGroup, list = []) => assoc('list', list, blockGroup);

/**
 * Determines if hidden block group
 *
 * @param {BlockGroup} blockGroup
 * @returns {BlockGroup}
 */
const isHidden = (blockGroup) => blockGroup.kind === EMPTY_KIND;

/**
 * Determines if shown block group
 *
 * @param {BlockGroup} blockGroup
 * @returns {BlockGroup}
 */
const isShown = (blockGroup) => !isHidden(blockGroup);

/**
 * Sets kind value
 *
 * @param {BlockGroup} blockGroup
 * @param {string} kind
 * @returns {BlockGroup}
 */
const show = (blockGroup, kind) => assoc('kind', kind, blockGroup);

/**
 * Sets kind EMPTY_KIND
 *
 * @param {BlockGroup} blockGroup
 * @returns {BlockGroup}
 */
const hide = (blockGroup) => assoc('kind', EMPTY_KIND, blockGroup);

/**
 * Add to select list id
 *
 * @param {BlockGroup} blockList
 * @param {Id} id
 * @returns {BlockGroup}
 */
const selectId = (blockList, id) => {
  const updateSelectId = uniq([...blockList.selectIds, id]);
  return assoc('selectIds', updateSelectId, blockList);
};

/**
 * Add to select list id
 *
 * @param {BlockGroup} blockList
 * @param {Id} id
 * @returns {BlockGroup}
 */
const unselectId = (blockList, id) => {
  const updateSelectId = blockList.selectIds.filter((sid) => sid !== id);
  return assoc('selectIds', updateSelectId, blockList);
};

/**
 * Add to select list all id from list
 *
 * @param {BlockGroup} blockList
 * @returns {BlockGroup}
 */
const selectAll = (blockList) =>
  assoc(
    'selectIds',
    blockList.list.map((item) => item.id),
    blockList
  );

/**
 * Clears select
 *
 * @param {BlockGroup} blockList
 * @returns {BlockGroup}
 */
const clearSelect = (blockList) => assoc('selectIds', [], blockList);

/**
 * Determines if id includes in select list
 *
 * @param {BlockGroup} blockList
 * @param {Id} id
 * @returns {boolean}
 */
const includes = (blockList, id) => blockList.selectIds.includes(id);

const includesAny = (blockList, listIds) =>
  listIds.some((id) => includes(blockList, id));

const setLoading = (blockList, loading) =>
  assoc('isLoading', loading, blockList);

/**
 * Determines if item includes in select list
 *
 * @param {BlockGroup} blockList
 * @param {Item} item
 * @returns {boolean}
 */
const includesItem = (blockList, item) => includes(blockList, item.id);

/**
 * Determines if all item includes in select list
 *
 * @param {BlockGroup} blockList
 * @returns {boolean}
 */
const isSelectedAll = (blockList) =>
  blockList.list.every((item) => includesItem(blockList, item));

const isSelectedSome = (blockList) => blockList.selectIds.length > 0;

/**
 * Gets selected items
 *
 * @param {BlockGroup} blockList
 * @returns {[Item]}
 */
const getSelectedItem = (blockList) => blockList.list.filter(includesItem);

export {
  makeBlockGroup,
  setList,
  show,
  hide,
  isShown,
  isHidden,
  selectId,
  unselectId,
  selectAll,
  clearSelect,
  includes,
  includesItem,
  includesAny,
  isSelectedSome,
  isSelectedAll,
  getSelectedItem,
  setLoading
};
