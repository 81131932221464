import { assoc, uniq, append } from 'ramda';

/**
 * @typedef {(number|string)} Id
 */

/**
 * Any object with property id and any properties
 *
 * @typedef {object} Item
 * @property {(number|string)} id
 */

/**
 * @typedef BLWithSelect - BlockListWithSelect
 * @property {[Id]} selectIds
 * @property {[Item]} list - object with id
 * @property {[Item]} sublist - objects with id
 * @property {number} amountLeft - element left to load
 */

/**
 * Creates a block list data type
 *
 * @param {object} params
 * @param {[Item]} params.list
 * @param {{Item}} params.sublist
 * @param {[Id]} params.selectIds
 * @param {number} params.amountLeft
 * @returns {BLWithSelect}
 */
const makeBLWithSelect = ({
  list = [],
  sublist = [],
  selectIds = [],
  amountLeft = 0
} = {}) => ({
  type: 'BlockListWithSelect',
  list,
  sublist,
  selectIds,
  amountLeft
});

/**
 * Sets list
 *
 * @param {BLWithSelect} blockList
 * @param {[object]} list
 * @returns {BLWithSelect}
 */
const setList = (blockList, list = []) => assoc('list', list, blockList);

/**
 * Sets sublist
 *
 * @param {BLWithSelect} blockList
 * @param {[object]} sublist
 * @returns {BLWithSelect}
 */
const setSubList = (blockList, sublist = []) =>
  assoc('sublist', sublist, blockList);

/**
 * Sets amount left
 *
 * @param {BLWithSelect} blockList
 * @param {number} amountLeft
 * @returns {BLWithSelect}
 */
const setAmountLeft = (blockList, amountLeft) =>
  assoc('amountLeft', amountLeft, blockList);

/**
 * Sets selectedIds
 *
 * @param {BLWithSelect} blockList
 * @param {[object]} selectIds
 * @returns {BLWithSelect}
 */
const setSelectedIds = (blockList, selectIds = []) =>
  assoc('selectIds', selectIds, blockList);

/**
 * Add to select list id
 *
 * @param {BLWithSelect} blockList
 * @param {Id} id
 * @returns {BLWithSelect}
 */
const selectId = (blockList, id) => {
  const updateSelectId = uniq([...blockList.selectIds, id]);
  return assoc('selectIds', updateSelectId, blockList);
};

/**
 * Add to select list id
 *
 * @param {BLWithSelect} blockList
 * @param {Id} id
 * @returns {BLWithSelect}
 */
const unselectId = (blockList, id) => {
  const updateSelectId = blockList.selectIds.filter((sid) => sid !== id);
  return assoc('selectIds', updateSelectId, blockList);
};

/**
 * Clears select
 *
 * @param {BLWithSelect} blockList
 * @returns {BLWithSelect}
 */
const clearSelect = (blockList) => assoc('selectIds', [], blockList);

/**
 * Determines if id includes in select list
 *
 * @param {BLWithSelect} blockList
 * @param {Id} id
 * @returns {BLWithSelect}
 */
const includes = (blockList, id) => blockList.selectIds.includes(id);

/**
 * Add to select or remove from list id
 *
 * @param {BLWithSelect} blockList
 * @param {Id} id
 * @returns {BLWithSelect}
 */
const selectOrUnselectId = (blockList, id) => {
  if (includes(blockList, id)) {
    return unselectId(blockList, id);
  }
  return selectId(blockList, id);
};

/**
 * Determines if block selectedIds not empty
 *
 * @param {BLWithSelect} blockList
 * @returns {boolean}
 */
const haveSelectedItems = (blockList) => blockList.selectIds.length > 0;

/**
 * Return selectedIds count
 *
 * @param {BLWithSelect} blockList
 * @returns {number}
 */
const getSelectedCount = (blockList) => blockList.selectIds.length;

/**
 * Return sublist length
 *
 * @param {BLWithSelect} blockList
 * @returns {number}
 */
const getSubListLength = (blockList) => blockList.sublist.length;

/**
 * Determines if item includes in select list
 *
 * @param {BLWithSelect} blockList
 * @param {Item} item
 * @returns {BLWithSelect}
 */
const includesItem = (blockList, item) => includes(blockList, item.id);

/**
 * Gets selected items
 *
 * @param {BLWithSelect} blockList
 * @returns {[Item]}
 */
const getSelectedItem = (blockList) =>
  blockList.list.filter((item) => includesItem(blockList, item));

/**
 * Update item in list
 *
 * @param {BLWithSelect} blockList
 * @param {Item} item
 * @returns {BLWithSelect}
 */
const updateItem = (blockList, item) => {
  const updatedList = blockList.list.map((i) => {
    if (i.id === item.id) return item;
    return i;
  });
  return setList(blockList, updatedList);
};

/**
 * Determines if id includes in list
 *
 * @param {BLWithSelect} blockList
 * @param {Id} id
 * @returns {boolean}
 */
const isExistItem = (blockList, id) => {
  const index = blockList.list.findIndex((item) => item.id === id);
  return index !== -1;
};

/**
 * Add item to list
 *
 * @param {BLWithSelect} blockList
 * @param {Item} item
 * @returns {BLWithSelect}
 */
const addOrUpdateItem = (blockList, item) => {
  if (isExistItem(blockList, item.id)) {
    return updateItem(blockList, item);
  }
  const updatedList = append(item, blockList.list);
  return setList(blockList, updatedList);
};

/**
 * Remove item from list
 *
 * @param {BLWithSelect} blockList
 * @param {Id} itemId
 * @returns {BLWithSelect}
 */
const removeItem = (blockList, itemId) => {
  const updatedList = blockList.list.filter((i) => i.id !== itemId);
  return setList(blockList, updatedList);
};

const merge = (blockListA, blockListB) =>
  makeBLWithSelect({
    list: blockListA.list.concat(blockListB.list),
    amountLeft: blockListB.amountLeft
  });

export {
  makeBLWithSelect,
  setList,
  setSubList,
  setSelectedIds,
  selectId,
  unselectId,
  selectOrUnselectId,
  clearSelect,
  includes,
  includesItem,
  getSelectedItem,
  updateItem,
  addOrUpdateItem,
  removeItem,
  haveSelectedItems,
  getSelectedCount,
  getSubListLength,
  setAmountLeft,
  merge
};
