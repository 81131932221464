import { isNil } from 'ramda';

export const setReduxDataToChatInputCase = ({
  getMsgText,
  getOpenedChatId,
  clearData
}) => {
  document.addEventListener('input-mounted', () => {
    const chatId = getOpenedChatId();
    const msg = getMsgText(chatId);

    if (!isNil(msg)) {
      document.dispatchEvent(
        new CustomEvent('set-msg-to-editor', { detail: { msg } })
      );
      clearData(chatId);
    }
  });
};
