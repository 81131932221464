.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--editable-table-delete-row-background-color);
  cursor: pointer;
  border-radius: 2px;
}
