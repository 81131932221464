export enum SidebarMode {
  full = 'full',
  short = 'short'
}

export enum SidebarWidth {
  full = 226,
  short = 62
}

export enum IconTypes {
  link = 'link',
  default = 'default'
}

export const MAX_BADGE_COUNT_TO_SHOW = 99;
