import { Map, List, fromJS } from 'immutable';

const setUnits = (state, { units }) => state.set('list', List(fromJS(units)));

const initState = () =>
  Map({
    list: List(),
    selectedList: List()
  });

const list = {
  TABLE_UNITS_LOADED: setUnits
};

export default (state = initState(), action) => {
  const reducer = list[action.type];
  return reducer === undefined ? state : reducer(state, action.payload);
};
