import { compareDesc } from 'date-fns';

export function isFirstDateGTE(
  firstDate: Date | number,
  nextDate: Date | number
) {
  return compareDesc(new Date(firstDate), new Date(nextDate)) < 1;
}

export function isFirstDateGT(
  firstDate: Date | number,
  nextDate: Date | number
) {
  return compareDesc(new Date(firstDate), new Date(nextDate)) === -1;
}
