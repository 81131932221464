import { memo, useCallback } from 'react';
import cn from 'classnames';
import { isFunction } from '@/lib/utils';
import { makeKey } from '@/components/EditableTable/utils';
import { ColumnsMapByAccessorType, RowDef } from '../../types';
import { CellManager } from '../CellManager';
import { DeleteRow } from './components';
import styles from './Row.module.scss';

interface RowProps {
  accessorsList: string[];
  columnsMapByAccessor: ColumnsMapByAccessorType;
  rowIndex: number;
  data: RowDef;
  onRemove?: (row: RowDef, rowIndex: number) => void;
  onHover?: (row: RowDef, rowIndex: number) => void;
  removable?: boolean;
  onSelectRow?: (value: number) => void;
  selectableRow?: boolean;
  selectedRow: number | null;
  setSelectedRow: (value: number | null) => void;
}

export const Row = memo<RowProps>(
  ({
    rowIndex,
    data,
    onHover,
    onRemove,
    removable,
    accessorsList,
    columnsMapByAccessor,
    onSelectRow,
    selectableRow,
    selectedRow,
    setSelectedRow
  }) => {
    const onMouseEnter = () => {
      if (isFunction(onHover)) {
        onHover(data, rowIndex);
      }
    };

    const onDeleteRow = () => {
      if (removable && isFunction(onRemove)) {
        onRemove(data, rowIndex);
      }
    };

    const handleRowSelection = useCallback(() => {
      if (selectableRow && isFunction(onSelectRow)) {
        onSelectRow(rowIndex);
        setSelectedRow(rowIndex);
      }
    }, [rowIndex, onSelectRow, selectableRow]);

    return (
      <tr
        className={cn(styles.row, selectedRow === rowIndex && styles.selected)}
        onMouseEnter={onMouseEnter}
        onClick={handleRowSelection}>
        {/* TODO Сгенерировать статичный key вместо cellIndex */}
        {/* eslint-disable-next-line react/no-array-index-key */}
        {accessorsList.map((accessor, cellIndex) => (
          <CellManager
            key={makeKey(cellIndex)}
            accessor={accessor}
            rowIndex={rowIndex}
            rowData={data}
            columnsMapByAccessor={columnsMapByAccessor}
          />
        ))}
        {removable && (
          <DeleteRow className={styles.deleteRow} onClick={onDeleteRow} />
        )}
      </tr>
    );
  }
);
