import React from 'react';

// eslint-disable-next-line no-shadow
export enum Keys {
  ENTER = 'Enter',
  SPACE = 'Space',
  TAB = 'Tab',
  ESCAPE = 'Escape'
}

interface AccessibleKey {
  code: Keys;
  key: string[];
  keyCode: number;
}

const ACCESSIBLE_KEYS: AccessibleKey[] = [
  {
    code: Keys.ENTER,
    key: ['Enter'],
    keyCode: 13
  },
  {
    code: Keys.SPACE,
    key: ['Space', 'Spacebar', ' '],
    keyCode: 32
  },
  {
    code: Keys.TAB,
    key: ['Tab'],
    keyCode: 9
  },
  {
    code: Keys.ESCAPE,
    key: ['Escape'],
    keyCode: 27
  }
];

export function pressedKey(
  event: KeyboardEvent | React.KeyboardEvent<HTMLElement>
) {
  return (
    ACCESSIBLE_KEYS.find(
      ({ key, keyCode }) => key.includes(event.key) || keyCode === event.keyCode
    )?.code || null
  );
}

export function shouldTriggerClickOnEnterOrSpace(
  event: KeyboardEvent | React.KeyboardEvent<HTMLElement>
) {
  const element = event.target as HTMLElement;
  const { tagName } = element;

  const role = element.getAttribute('role');

  const isValidKeyboardEventTarget: boolean =
    !element.isContentEditable &&
    tagName !== 'INPUT' &&
    tagName !== 'TEXTAREA' &&
    (role === 'button' || role === 'link');

  if (!isValidKeyboardEventTarget) {
    return false;
  }

  const isNativeAnchorElement = tagName === 'A' && element.hasAttribute('href');
  const keyPressed = pressedKey(event);

  return (
    (keyPressed === Keys.SPACE && role === 'button') ||
    (keyPressed === Keys.ENTER && !isNativeAnchorElement)
  );
}
