.radio {
  width: 100%;
  margin: 0 !important;
  padding-right: var(--indent-xs) !important;

  & svg circle {
    fill: var(--color-gray-800);
  }

  & svg rect {
    stroke: var(--color-gray-800);
  }
}

.radioDisabled {
  & svg circle {
    fill: var(--color-gray-500);
  }

  & svg rect {
    stroke: var(--color-gray-500);
  }
}

.label {
  padding: 0 var(--indent-xs);

  &:hover {
    background-color: var(--color-blue-50);
    border-radius: var(--indent-xxs);
  }
}

.radioPlacement {
  &-start {
    align-self: flex-start;
  }

  &-center {
    align-self: center;
  }

  &-end {
    align-self: flex-end;
  }
}
