import debug from 'debug';

export const logModule = debug('module');
export const logView = debug('view');
export const logUseCase = debug('useCase');
export const logLogic = debug('logic');
export const initLogAlert =
  (enable = false) =>
  (msg) =>
    enable && alert(msg);
