import { assoc } from 'ramda';

/**
 * @typedef {(number|string)} Id
 */

/**
 * @typedef LastUnreadMessage
 * @property {Id} channelId
 * @property {Id} messageId
 */

/**
 * @typedef BlockLastUnreadMessages
 * @property {string} type
 * @property {[LastUnreadMessage]} list
 */

/**
 * Creates a block unreadLastMessages
 *
 * @param {[LastUnreadMessage]} list
 * @returns {BlockLastUnreadMessages}
 */
export const makeBlockLastUnreadMessages = (list = []) => ({
  type: 'BlockLastUnreadMessages',
  list
});

export const addLastUnreadMessages = (
  blockLastUnreadMessages,
  lastUnreadMessages
) => {
  const updatedBlock = [...blockLastUnreadMessages.list, ...lastUnreadMessages];

  return assoc('list', updatedBlock, blockLastUnreadMessages);
};

/**
 * Add or update last unread message item by chat id
 *
 * @param {BlockLastUnreadMessages} blockLastUnreadMessages
 * @param {LastUnreadMessage} lastUnreadMessage
 * @returns {BlockLastUnreadMessages}
 */
export const addOrUpdateLastUnreadMessage = (
  blockLastUnreadMessages,
  lastUnreadMessage
) => {
  let updatedBlock = [];
  const existBadge = blockLastUnreadMessages.list.find(
    (item) => item.channelId === lastUnreadMessage.channelId
  );

  if (existBadge) {
    updatedBlock = blockLastUnreadMessages.list.map((item) =>
      item.channelId === lastUnreadMessage.channelId ? lastUnreadMessage : item
    );
  } else {
    updatedBlock = [...blockLastUnreadMessages.list, lastUnreadMessage];
  }

  return assoc('list', updatedBlock, blockLastUnreadMessages);
};

/**
 * Clear badge item by chat id
 *
 * @param {BlockLastUnreadMessages} blockLastUnreadMessages
 * @param {Id} channelId
 * @returns {BlockLastUnreadMessages}
 */
export const clearLastUnreadMessageByChatId = (
  blockLastUnreadMessages,
  channelId
) => {
  const updatedBlock = blockLastUnreadMessages.list.filter(
    (item) => item.channelId !== channelId
  );
  return assoc('list', updatedBlock, blockLastUnreadMessages);
};
