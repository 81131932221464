import React from 'react';
import cn from 'classnames';
import { Icons } from '@/assets/images';
import styles from './Icon.module.css';

const sizes = {
  xss: 'xss', // 12
  xs: 'xs', // 14
  s: 's', // 16
  m: 'm', // 18
  l: 'l', // 22
  xl: 'xl', // 28
  xxl: 'xxl' // 34
};

const Icon = ({ name, size = sizes.m, className, ...props }) => {
  const IconComponent = Icons[name];

  if (!IconComponent) throw new Error('Unknown icon name');

  return (
    <span className={cn(styles[size], className)} {...props}>
      <IconComponent />
    </span>
  );
};
export default Icon;
