import React from 'react';

/* eslint-disable max-len */
function IconOk() {
  return (
    <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.806 2.378a.863.863 0 0 0-.137.064c-.055.031-6.543 6.963-7.059 7.541l-.07.079-1.572-1.613C2.773 7.224 2.37 6.825 2.289 6.785a.676.676 0 0 0-.543-.013.623.623 0 0 0-.387.566c-.018.289-.031.274 1.462 1.802 2.259 2.314 2.332 2.386 2.472 2.451.108.05.15.057.294.047a.593.593 0 0 0 .269-.067c.07-.039 1.24-1.279 3.86-4.09 2.067-2.219 3.788-4.076 3.823-4.128.217-.316.094-.759-.262-.939a.965.965 0 0 0-.471-.036"
        fill="#5B5F73"
        fillRule="evenodd"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconOk;
