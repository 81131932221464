import { isNil } from 'ramda';
import { getInterlocutor } from 'core/data/light/dialog';
import { getTempInput } from '../../../data-type/block-temp';
import { ChannelType } from '../../../types';

export const showChatService = async (
  {
    getChatInput,
    getChatTemp,
    getOpenedChat,
    getCurrentEmployeeId,
    setDraftMessage,
    setChatMode,
    addOrUpdateTempInput,
    setInputText,
    removeInputFromTemp,
    unsubscribeToUpdateChat,
    clearSelectedMessages,
    setOpenChat,
    uploadMessages,
    subscribeToUpdateChat,
    reqContactInfo,
    setDialogInfo
  },
  chat
) => {
  const openedChat = getOpenedChat();
  if (!isNil(openedChat)) {
    unsubscribeToUpdateChat(openedChat.id);
    setDraftMessage(openedChat.id, { withTimeout: false });
    addToTempInput(openedChat.id);
  }

  setInput();
  clearSelectedMessages();
  setOpenChat(chat);
  uploadMessages(chat);
  subscribeToUpdateChat(chat.id);
  setChatMode('chat');

  if (chat.type === ChannelType.Dialog) {
    const employeeId = getCurrentEmployeeId();
    const interlocutor = getInterlocutor(employeeId, chat);
    const info = await reqContactInfo(interlocutor.employeeId);
    setDialogInfo(info);
  }

  return undefined;

  function addToTempInput(chatId) {
    const blockInput = getChatInput();
    if (blockInput.text) {
      addOrUpdateTempInput(chatId, { id: chatId, text: blockInput.text });
      setInputText('');
    }
  }

  function setInput() {
    const temp = getTempInput(getChatTemp(), chat.id);

    if (!isNil(temp)) {
      setInputText(temp.text);
      removeInputFromTemp(chat.id);
    }
  }
};
