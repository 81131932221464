export class CompanyInfoDto {
  constructor(props) {
    this.inn = props.inn;
    this.kpp = props.kpp;
    this.ogrn = props.ogrn;
    this.fullName = props.fullName;
    this.shortName = props.shortName;
    this.legalAddress = props.legalAddress;
  }
}
