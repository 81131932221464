.container {
  display: flex;
  align-items: center;
  column-gap: var(--indent-xs);
}

.link {
  color: var(--main-blue);

  &:visited {
    color: var(--main-blue);
  }

  svg path {
    stroke: var(--main-blue);
  }
}
