import { bindActionCreators } from 'redux';
import { applyState } from '../../utils';
import { getChatForward, getOpenedChatId } from '../../../getter';
import * as getters from '../getters';
import * as actions from '../actions';
import { showModal } from '../../../../../action-creators/modal';
import { showPreviewModalCase } from './showPreviewModal';
import { cancelForwardCase } from './cancelForward';
import { sendCase } from './send';
import * as messagesStorage from '../../messages/storage';
import { createUnsentForwardMessages } from '../../unsentMessages/useCases';

export const showPreviewModal = () => (dispatch, getState) =>
  showPreviewModalCase({
    getOpenedChatId: applyState(getState, getOpenedChatId),
    getForwardMessages: applyState(getState, getters.getChatForward),
    showModal: bindActionCreators(showModal, dispatch),
    removeForwardMessage: bindActionCreators(
      actions.removeForwardMessage,
      dispatch
    )
  });

export const cancelForward = () => (dispatch, getState) =>
  cancelForwardCase({
    getOpenedChatId: applyState(getState, getOpenedChatId),
    setForwardMessages: bindActionCreators(actions.setForwardMessages, dispatch)
  });

export const sendForwardMessages =
  ({
    mentions = [],
    sendMessage = messagesStorage.sendMsg,
    isCreateUnsent = true
  }) =>
  (dispatch, getState) =>
    sendCase(
      {
        getOpenedChatId: applyState(getState, getOpenedChatId),
        getChatForward: applyState(getState, getChatForward),
        createUnsentForwardMessages: bindActionCreators(
          createUnsentForwardMessages,
          dispatch
        ),
        setForwardMessages: bindActionCreators(
          actions.setForwardMessages,
          dispatch
        ),
        sendMessage
      },
      { mentions, isCreateUnsent }
    );
