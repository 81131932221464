import { hasPath, isNil } from 'ramda';
import { getChatWidget, getOpenedChatId } from '../../getter';

export const getScrollPositionData = (state, chatId) =>
  getChatWidget(state).scrollPositions.list[getChatId(state, chatId)];

export const hasScrollPositionData = (state, chatId) => {
  const hasChatIdData = hasPath(
    ['scrollPositions', 'list', getChatId(state, chatId)],
    getChatWidget(state)
  );

  return hasChatIdData && !isNil(getScrollPositionData(state, chatId).message);
};

export function getChatId(state, chatId) {
  return chatId || getOpenedChatId(state);
}
