import {
  useState,
  useRef,
  useEffect,
  MouseEvent,
  MutableRefObject
} from 'react';
import LinesEllipsis from 'react-lines-ellipsis';

import styles from './ExpandableText.module.scss';
import { Typography } from '../Typography';

const DEFAULT_CLAMP = 2;
export interface ExpandableTextProps {
  textContent: string;
  clamp?: number;
  showMoreText: string;
  showLessText: string;
}
export interface ILinesEllipsis extends LinesEllipsis {
  isClamped: () => boolean;
}

export function ExpandableText({
  textContent,
  clamp: defaultClamp = DEFAULT_CLAMP,
  showMoreText,
  showLessText
}: ExpandableTextProps) {
  const clampRef: MutableRefObject<ILinesEllipsis | null> = useRef(null);
  const [clamped, setClamped] = useState(false);
  const [clamp, setClamp] = useState(defaultClamp);
  const [shownAll, setShownAll] = useState(false);

  useEffect(() => {
    setClamped(true);
    setClamp(defaultClamp);

    if (clampRef?.current) {
      setShownAll(!clampRef.current.isClamped());
    }
  }, [defaultClamp]);

  const handleClampClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setClamped(!clamped);
    setClamp(clamped ? Number.MAX_SAFE_INTEGER : defaultClamp);
  };

  return (
    <>
      <Typography variant="body1Reg">
        <LinesEllipsis
          className={styles.expendableTextContent}
          text={textContent}
          maxLine={clamp}
          ellipsis="..."
          trimRight
          ref={clampRef}
        />
      </Typography>
      {(!shownAll || !clamped) && (
        <button
          data-testid="expand-btn"
          type="button"
          className={styles.expandableContentButton}
          onClick={handleClampClick}>
          <Typography variant="body1Med">
            {clamped ? showMoreText : showLessText}
          </Typography>
        </button>
      )}
    </>
  );
}
