import { assoc } from 'ramda';
import {
  addOrUpdateDraftText,
  clearDraft,
  makeBlockDraftMessages
} from '../../data-type/block-drafts-msgs';
import { ADD_OR_UPDATE_DRAFT_INPUT_MESSAGE, CLEAR_DRAFT } from './constants';

export const defaultState = () => ({
  blockDraftMessages: makeBlockDraftMessages()
});

const setBlockDraftMsgs = (state, value) =>
  assoc('blockDraftMessages', value, state);

export const reducers = {
  [ADD_OR_UPDATE_DRAFT_INPUT_MESSAGE]: (state, { chatId, text, files }) =>
    setBlockDraftMsgs(
      state,
      addOrUpdateDraftText(state.blockDraftMessages, chatId, { text, files })
    ),
  [CLEAR_DRAFT]: (state, { chatId }) =>
    setBlockDraftMsgs(state, clearDraft(state.blockDraftMessages, chatId))
};
