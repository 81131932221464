import { initState, addErrors, clearErrors } from '../work-with-data/errors';

const errors = (state = initState(), { type, payload }) => {
  switch (type) {
    case 'ERRORS:ADD':
      return addErrors(state, payload);
    case 'ERRORS:CLEAR':
      return clearErrors(state, payload);
    default:
      return state;
  }
};

export default errors;
