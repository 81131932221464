import React from 'react';

export function MicOn() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width={18}
      height={18}
      viewBox="0 0 18 18">
      <path
        stroke="#5B5F73"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14.25 7.5V9c0 2.9-2.35 5.25-5.25 5.25M3.75 7.5V9c0 2.9 2.35 5.25 5.25 5.25m0 0v2.25m-3 0h6m-3-5.25A2.25 2.25 0 0 1 6.75 9V3.75a2.25 2.25 0 0 1 4.5 0V9A2.25 2.25 0 0 1 9 11.25Z"
      />
    </svg>
  );
}
