import {
  useState,
  SyntheticEvent,
  forwardRef,
  useImperativeHandle
} from 'react';
import cn from 'classnames';
import { Tabs as MUITabs, Tab as MUITab } from '@mui/material';
import styles from './Tabs.module.scss';
import { TabsProps, TabProps, ChangeTabHandler } from './Tabs.types';
import { Title } from './components';

export const Tabs = forwardRef<ChangeTabHandler, TabsProps>(
  (
    {
      tabContent,
      onChangeTab,
      initialTab,
      className = '',
      variant = 'standard'
    },
    ref
  ) => {
    const [currentTab, setCurrentTab] = useState(initialTab || 0);

    const handleChange = (_event: SyntheticEvent, newValue: number) => {
      onChangeTab?.(newValue);
      setCurrentTab(newValue);
    };

    useImperativeHandle(ref, () => ({
      setTabById: (tabId: number) => {
        setCurrentTab(tabId);
      }
    }));

    return (
      <div className={cn(className, styles.container)} data-testid="tabs">
        <MUITabs
          value={currentTab}
          onChange={handleChange}
          variant={variant}
          className={styles.tabs}>
          {tabContent.map((tab: TabProps, index: number) => (
            <MUITab
              key={tab.title}
              label={<Title title={tab.title} shownBadge={tab.shownBadge} />}
              value={index}
              disabled={tab?.disabled}
              disableRipple
              data-testid={tab.testId}
            />
          ))}
          <span className={styles.delimiter} />
        </MUITabs>
        {tabContent[currentTab].content}
      </div>
    );
  }
);
