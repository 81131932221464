import React from 'react';
import { SidebarModes } from './modes';
import TranscriptChat from '../TranscriptChat';

const Content = ({ mode, transcript }) => {
  switch (mode) {
    case SidebarModes.Transcript:
      return <TranscriptChat messages={transcript.messages} />;
    default:
      return null;
  }
};

export default Content;
