export const setAllBadgeCount = (count) => (dispatch) =>
  dispatch({ type: 'BADGE:SET_BADGE_COUNT', payload: count });

export const setCertainBadgeCount = (type, count) => (dispatch) =>
  dispatch({ type: 'BADGE:SET_BADGE_COUNT:ONE', payload: { type, count } });

export const setCertainBadge = (type, badge) => (dispatch) =>
  dispatch({ type: 'BADGE:SET_BADGE:ONE', payload: { type, badge } });

export const deleteBadgeByType = (section, typeId) => ({
  type: 'DELETE:BADGE:BY:SECTION:TYPE_ID',
  payload: { section, typeId }
});
