import React from 'react';

/* eslint-disable max-len */
function IconAlarm() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="14" height="14" fill="white" />
      <path
        className="cws-icon-main-fill"
        d="M2.32208 12.4033C2.10477 12.6891 2.16023 13.0969 2.44597 13.3142C2.7317 13.5315 3.1395 13.476 3.35682 13.1903L2.32208 12.4033ZM10.1116 11.4181L9.69747 10.9172L10.1116 11.4181ZM10.7533 13.2115C10.9823 13.4879 11.3921 13.5264 11.6685 13.2973C11.945 13.0683 11.9834 12.6586 11.7544 12.3821L10.7533 13.2115ZM2.76279 7.64972C2.76279 5.30967 4.65977 3.4127 6.99981 3.4127V2.1127C3.9418 2.1127 1.46279 4.5917 1.46279 7.64972H2.76279ZM6.99981 3.4127C9.33986 3.4127 11.2368 5.30967 11.2368 7.64972H12.5368C12.5368 4.5917 10.0578 2.1127 6.99981 2.1127V3.4127ZM6.99981 11.8867C5.97417 11.8867 5.03518 11.5231 4.30216 10.9172L3.47385 11.9191C4.43129 12.7106 5.66081 13.1867 6.99981 13.1867V11.8867ZM4.30216 10.9172C3.36084 10.139 2.76279 8.96446 2.76279 7.64972H1.46279C1.46279 9.36838 2.2466 10.9045 3.47385 11.9191L4.30216 10.9172ZM3.37063 11.0246L2.32208 12.4033L3.35682 13.1903L4.40537 11.8116L3.37063 11.0246ZM11.2368 7.64972C11.2368 8.96446 10.6388 10.139 9.69747 10.9172L10.5258 11.9191C11.753 10.9045 12.5368 9.36838 12.5368 7.64972H11.2368ZM9.69747 10.9172C8.96445 11.5231 8.02546 11.8867 6.99981 11.8867V13.1867C8.33882 13.1867 9.56834 12.7106 10.5258 11.9191L9.69747 10.9172ZM9.61109 11.8328L10.7533 13.2115L11.7544 12.3821L10.6122 11.0034L9.61109 11.8328Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        d="M7.6207 5.66309C7.6207 5.3041 7.32969 5.01309 6.9707 5.01309C6.61172 5.01309 6.3207 5.3041 6.3207 5.66309H7.6207ZM8.73513 8.84944C9.09411 8.84944 9.38513 8.55842 9.38513 8.19944C9.38513 7.84045 9.09411 7.54944 8.73513 7.54944V8.84944ZM6.3207 5.66309V7.9661H7.6207V5.66309H6.3207ZM7.20404 8.84944H8.73513V7.54944H7.20404V8.84944ZM6.3207 7.9661C6.3207 8.45395 6.71618 8.84944 7.20404 8.84944V7.54944C7.43416 7.54944 7.6207 7.73598 7.6207 7.9661H6.3207Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        d="M1.21594 4.3483L0.794538 4.6276C0.878345 4.75404 1.0145 4.83617 1.16544 4.85133C1.31638 4.86648 1.46615 4.81305 1.57342 4.70578L1.21594 4.3483ZM3.41379 2.15046L3.77127 2.50794C3.86892 2.41029 3.92237 2.27696 3.91921 2.1389C3.91606 2.00084 3.85656 1.87009 3.75456 1.77701L3.41379 2.15046ZM1.57342 4.70578L3.77127 2.50794L3.05631 1.79297L0.858456 3.99081L1.57342 4.70578ZM3.75456 1.77701C3.47005 1.5174 3.0083 1.26875 2.49159 1.20404C1.95183 1.13643 1.33745 1.2713 0.843764 1.8083L1.58811 2.49262C1.82597 2.23389 2.09661 2.17358 2.36594 2.20731C2.65832 2.24393 2.92972 2.39314 3.07302 2.5239L3.75456 1.77701ZM0.843764 1.8083C0.361029 2.33337 0.255962 2.92544 0.326352 3.44339C0.393913 3.94053 0.62003 4.36431 0.794538 4.6276L1.63734 4.069C1.50703 3.8724 1.36738 3.59511 1.32825 3.30723C1.29196 3.04016 1.3393 2.76325 1.58811 2.49262L0.843764 1.8083Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        d="M12.7841 4.3483L13.2055 4.6276C13.1217 4.75404 12.9855 4.83617 12.8346 4.85133C12.6836 4.86648 12.5338 4.81305 12.4266 4.70578L12.7841 4.3483ZM10.5862 2.15046L10.2287 2.50794C10.1311 2.41029 10.0776 2.27696 10.0808 2.1389C10.0839 2.00084 10.1434 1.87009 10.2454 1.77701L10.5862 2.15046ZM12.4266 4.70578L10.2287 2.50794L10.9437 1.79297L13.1415 3.99081L12.4266 4.70578ZM10.2454 1.77701C10.5299 1.5174 10.9917 1.26875 11.5084 1.20404C12.0482 1.13643 12.6625 1.2713 13.1562 1.8083L12.4119 2.49262C12.174 2.23389 11.9034 2.17358 11.6341 2.20731C11.3417 2.24393 11.0703 2.39314 10.927 2.5239L10.2454 1.77701ZM13.1562 1.8083C13.639 2.33337 13.744 2.92544 13.6736 3.44339C13.6061 3.94053 13.38 4.36431 13.2055 4.6276L12.3627 4.069C12.493 3.8724 12.6326 3.59511 12.6717 3.30723C12.708 3.04016 12.6607 2.76325 12.4119 2.49262L13.1562 1.8083Z"
        fill="#5B5F73"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconAlarm;
