import { ApplicationStatus } from '../constants';

export interface Filters {
  status?: Array<ApplicationStatus>;
}

export type UpdateFilters = { key: 'status'; value: ApplicationStatus };

export enum ApplicationTabs {
  inbox = 'inbox',
  outbox = 'outbox'
}
