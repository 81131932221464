import cn from 'classnames';
import type { IconProps } from './Icon.types';
import { findIconByName } from './Icon.helpers';
import styles from './icon.module.scss';

export function Icon({
  iconName: IconName,
  width,
  height,
  className
}: IconProps) {
  const Component = findIconByName(IconName);

  return (
    Component && (
      <Component
        width={width}
        height={height}
        className={cn(styles.icon, className)}
      />
    )
  );
}
