import { assoc } from 'ramda';
import { SET_GROUP_CHAT, HIDE_GROUP_CHAT, SHOW_GROUP_CHAT } from './constants';
import { hide, makeBlockGroup, show } from '../../../data-type/block-group';

const setBGChats = (chatWidget, value) =>
  assoc('blockGroupChats', value, chatWidget);

export const defaultState = () => ({
  blockGroupChat: makeBlockGroup()
});

export const reducers = {
  [SET_GROUP_CHAT]: (chatWidget, blockGroups) =>
    setBGChats(chatWidget, blockGroups),
  [SHOW_GROUP_CHAT]: (chatWidget, { kind }) =>
    setBGChats(chatWidget, show(chatWidget.blockGroupChats, kind)),
  [HIDE_GROUP_CHAT]: (chatWidget) =>
    setBGChats(chatWidget, hide(chatWidget.blockGroupChats))
};
