import {
  SET_BADGES,
  ADD_OR_UPDATE_BADGE,
  CLEAR_BADGE,
  SET_UNREAD_MSG_COUNT
} from './constants';

export const setBadges = (badges) => ({
  type: SET_BADGES,
  payload: { badges }
});

export const addOrUpdateBadge = (badge) => ({
  type: ADD_OR_UPDATE_BADGE,
  payload: badge
});

export const clearBadge = (chatId) => ({ type: CLEAR_BADGE, payload: chatId });

export const updateUnreadMsgsCount = ({ chats, archives }) => ({
  type: SET_UNREAD_MSG_COUNT,
  payload: { chats, archives }
});
