import React from 'react';
import { Typography } from '@link/react-components';
import cn from 'classnames';
import { useDefineSpeaker } from '../../hooks/useDefineSpeaker';

const Container = ({ id, stream, children, className }) => {
  const containerRef = React.useRef(null);

  useDefineSpeaker(containerRef, stream);

  return (
    <Typography variant="h1" Component="div">
      <div
        id={id}
        ref={containerRef}
        className={cn(className, 'video-chat__video-container')}>
        {children}
      </div>
    </Typography>
  );
};

export default Container;
