import { assoc } from 'ramda';

/**
 * @typedef {(number|string)} Id
 */

/**
 * Any object with property id, channelId and any properties
 *
 * @typedef {object} Message
 * @property {(number|string)} id
 * @property {(number|string)} channelId
 */

/**
 * @typedef BMReply - BlockMsgReply
 * @property {[Message]} messages
 */

/**
 * Creates a block input data type
 *
 * @param {[Message]} messages
 * @returns {BMReply}
 */
export const makeBlockReplyMsg = (messages = []) => ({
  type: 'BlockReplyMsg',
  messages
});

/**
 * Add reply message by chat id
 *
 * @param {BMReply} blockMsgReply
 * @param {Message} message
 * @returns {BMReply}
 */
export const addReplyMessage = (blockMsgReply, message) => {
  let newMessages;
  const messageFromChannel = blockMsgReply.messages.find(
    (msg) => msg.channelId === message.channelId
  );

  if (messageFromChannel) {
    newMessages = blockMsgReply.messages.map((msg) =>
      msg.channelId === message.channelId ? message : msg
    );
  } else {
    newMessages = [...blockMsgReply.messages, message];
  }

  return assoc('messages', newMessages, blockMsgReply);
};

/**
 * Clear reply message by id
 *
 * @param {BMReply} blockMsgReply
 * @param {Id} messageId
 * @returns {BMReply}
 */
export const clearReplyMessageById = (blockMsgReply, messageId) => {
  const newReplyMsgs = blockMsgReply.messages.filter(
    (replyMsg) => replyMsg.id !== messageId
  );
  return assoc('messages', newReplyMsgs, blockMsgReply);
};

/**
 * Clear reply message by chatId
 *
 * @param {BMReply} blockMsgReply
 * @param {Id} chatId
 * @returns {BMReply}
 */
export const clearReplyMessageByChatId = (blockMsgReply, chatId) => {
  const newReplyMsgs = blockMsgReply.messages.filter(
    (replyMsg) => replyMsg.channelId !== chatId
  );
  return assoc('messages', newReplyMsgs, blockMsgReply);
};
