import { RefObject, FocusEvent, useEffect, useRef } from 'react';

const defaultEvents = ['mousedown', 'touchstart'];

export const useClickAway = <
  E extends FocusEvent<HTMLElement>,
  K extends keyof GlobalEventHandlersEventMap
>(
  ref: RefObject<HTMLElement | null>,
  onClickAway: (event: E) => void,
  events: K[] = defaultEvents as K[]
) => {
  const savedCallback = useRef(onClickAway);

  useEffect(() => {
    savedCallback.current = onClickAway;
  }, [onClickAway]);

  useEffect(() => {
    const handler = (event: any) => {
      const { current: el } = ref;

      if (el && !el.contains(event.target)) {
        savedCallback.current(event);
      }
    };

    events.forEach((eventName) => {
      document.addEventListener(eventName, handler);
    });

    return () => {
      events.forEach((eventName) => {
        document.removeEventListener(eventName, handler);
      });
    };
  }, [events, ref]);
};
