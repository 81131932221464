import React from 'react';

/* eslint-disable max-len */
const IconReply = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="cws-icon-main-fill"
      d="M6.34964 10.7776L2.43377 6.84819C2.19005 6.6045 2.19005 6.208 2.43277 5.96531L6.34789 2.06794C6.59252 1.8244 6.59339 1.42869 6.34986 1.18406C6.10636 0.939404 5.71064 0.93856 5.46598 1.18206L1.54983 5.08044C0.818797 5.8115 0.818797 7.001 1.54911 7.73131L5.46423 11.6599C5.58633 11.7825 5.74661 11.8437 5.90692 11.8437C6.06652 11.8437 6.22614 11.783 6.34814 11.6614C6.59261 11.4178 6.59333 11.0221 6.34964 10.7776Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      d="M11.3903 5.78117H4.71845C4.37326 5.78117 4.09345 6.06099 4.09345 6.40617C4.09345 6.75136 4.37326 7.03117 4.71845 7.03117H11.3903C13.7941 7.03117 15.7497 8.9868 15.7497 11.3905C15.7497 13.7943 13.7941 15.7499 11.3903 15.7499C11.0451 15.7499 10.7653 16.0297 10.7653 16.3749C10.7653 16.7201 11.0451 16.9999 11.3903 16.9999C14.4834 16.9999 16.9997 14.4836 16.9997 11.3905C16.9997 8.29752 14.4834 5.78117 11.3903 5.78117Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconReply;
