import { makeQuery } from 'core/messages/utils';
import { makeCommand, makeEvent } from 'core/data/messageBus/message';

const NS = 'TRANSCRIPT';

const roomName = ({ roomId }) => `transcript-view.${roomId}`;

export const startRecordingCMD = makeCommand(NS, 'RECORDING_START');
export const startedRecordingEVT = makeEvent(NS, 'RECORDING_STARTED');
export const endRecordingCMD = makeCommand(NS, 'RECORDING_END');
export const endedRecordingEVT = makeEvent(NS, 'RECORDING_ENDED');
export const sendMessageCMD = makeCommand(NS, 'MESSAGE_SEND');
export const receivedMessageEVT = makeEvent(NS, 'MESSAGE_RECEIVED');
export const transcriptQRY = makeQuery(NS, 'transcript', roomName);
export const viewCMD = makeCommand(NS, 'view');
