import React from 'react';

/* eslint-disable max-len */
function IconList() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 1H2L2 2H3V1ZM2 0C1.44772 0 1 0.447715 1 1V2C1 2.55228 1.44772 3 2 3H3C3.55228 3 4 2.55228 4 2V1C4 0.447715 3.55228 0 3 0H2Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 8H5V9H6V8ZM5 7C4.44772 7 4 7.44772 4 8V9C4 9.55228 4.44772 10 5 10H6C6.55228 10 7 9.55228 7 9V8C7 7.44772 6.55228 7 6 7H5Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 15H8V16H9V15ZM8 14C7.44772 14 7 14.4477 7 15V16C7 16.5523 7.44772 17 8 17H9C9.55228 17 10 16.5523 10 16V15C10 14.4477 9.55228 14 9 14H8Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 2L6 2V1L17 1V2Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 9H9V8H17V9Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 16H12V15H17V16Z"
        fill="#5B5F73"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconList;
