import slice from '@/lib/actionReducer';
import type {
  MyProcurementsFiltersState,
  MyProcurementsActiveFilters,
  MyProcurementsSavedFilters
} from './myProcurementsFilter.types';
import type { MyProcurementsFilterStatus } from './myProcurementsFilterStatus';
import {
  INITIAL_FILTERS,
  INITIAL_STATE
} from './myProcurementsFilter.constants';

const SLICE_NAME = 'myProcurementFilter';

const myProcurementsFilterSlice = slice({
  name: SLICE_NAME,
  initState: INITIAL_STATE,
  reducers: {
    setHaveSavedFilters(
      state: MyProcurementsFiltersState,
      payload: boolean
    ): MyProcurementsFiltersState {
      return { ...state, haveSavedFilters: payload };
    },
    setStatus(
      state: MyProcurementsFiltersState,
      payload: MyProcurementsFilterStatus
    ): MyProcurementsFiltersState {
      return { ...state, status: payload };
    },
    setActiveFilters(
      state: MyProcurementsFiltersState,
      payload: MyProcurementsActiveFilters
    ): MyProcurementsFiltersState {
      return { ...state, activeFilters: payload };
    },
    setSavedFilters(
      state: MyProcurementsFiltersState,
      payload: MyProcurementsSavedFilters
    ): MyProcurementsFiltersState {
      return { ...state, savedFilters: payload, haveSavedFilters: true };
    },
    resetActiveFilters(
      state: MyProcurementsFiltersState
    ): MyProcurementsFiltersState {
      return { ...state, activeFilters: INITIAL_FILTERS };
    }
  }
});

export const myProcurementFilterActions = myProcurementsFilterSlice.actions;
export const myProcurementFilterReducer = myProcurementsFilterSlice.reducer;
