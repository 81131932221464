import { fromJS } from 'immutable';
import { isNil } from 'ramda';
import { toast } from '@/modules/toast';
import customHistory from '../customHistory';
import { setErrorMessage, setInfoMessage, setSuccessMessage } from './message';
import {
  convertFileUrls,
  convertUrlToFile
} from '../components/ChatWidget/modules/files/utils';
import { escapeText } from '../utils/utils';

export const clearStorage = () => ({ type: 'STORAGE:CLEAR' });

export const clearStorageModalSearch = () => ({ type: 'STORAGE:CLEAR:ALL' });

export const clearSelected = () => (dispatch) =>
  dispatch({ type: 'STORAGE:CLEAR_SELECTED' });

export const selectRow = (id) => (dispatch) =>
  dispatch({ type: 'STORAGE:SELECT', payload: { id } });

export const addSeletedFilesIds = (ids) => (dispatch) =>
  dispatch({ type: 'STORAGE:ADD_SELECTED', payload: { ids } });

const storageOversizeHandler = () => () => {
  const openBrowserTab = (url) =>
    Object.assign(document.createElement('a'), {
      target: '_blank',
      href: url
    }).click();

  openBrowserTab('/file-storage/chat');
};

export const removeOrderAttach = (ids) => (dispatch) =>
  fetch('/api/storage/orders', {
    credentials: 'include',
    method: 'delete',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids: fromJS(ids) })
  })
    .then((res) => res.json())
    .then((attach) => {
      dispatch({
        type: 'STORAGE:REMOVE_ORDER_ATTACH:SUCCESS',
        payload: { attach }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'STORAGE:REMOVE_ORDER_ATTACH:ERROR',
        payload: { error: res.error }
      });
    });

export const setGoBackPage = (link) => ({
  type: 'STORAGE:SET_GO_BACK_LINK',
  payload: { link }
});

export const unsetLink = () => ({ type: 'STORAGE:UNSET_LINK' });

export const attachFileChat =
  (ids, dialogId, link, forwardedMessages = []) =>
  (dispatch) =>
    fetch('/api/storage/chat/attach', {
      credentials: 'include',
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ids: fromJS(ids), dialogId })
    })
      .then((res) => res.json())
      .then(() => {
        dispatch({ type: 'STORAGE:ATTACH_FILES_CHAT:SUCCESS' });
        customHistory.push({
          pathname: link,
          state: {
            forwarded: forwardedMessages.length > 0,
            messages: forwardedMessages
          }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'STORAGE:ATTACH_FILES_CHAT:ERROR',
          payload: { error: res.error }
        });
      });

export const attachFileOrder = (ids, orderId, link) => (dispatch) =>
  fetch('/api/storage/orders/attach', {
    credentials: 'include',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids: fromJS(ids), orderId })
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({ type: 'STORAGE:ATTACH_FILES_ORDER:SUCCESS' });
      customHistory.push(link);
    })
    .catch((res) => {
      dispatch({
        type: 'STORAGE:ATTACH_FILES_ORDER:ERROR',
        payload: { error: res.error }
      });
    });

export const attachFileCatalog =
  (ids, productId, link, imageAttach = false) =>
  async (dispatch) => {
    const res = await fetch('/api/storage/catalog/attach', {
      credentials: 'include',
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ids: fromJS(ids), productId, imageAttach })
    });
    const json = await res.json();
    if (res.status === 200) {
      dispatch({ type: 'STORAGE:ATTACH_FILES_CATALOG:SUCCESS' });
      customHistory.push(link);
      return json;
    }
    dispatch({
      type: 'STORAGE:ATTACH_FILES_CATALOG:ERROR',
      payload: { error: res.error }
    });
    return json;
  };

const initStorage =
  ({ typeResource, TYPE_DISPATCH }) =>
  (options = {}, orderId, productId) =>
  (dispatch) =>
    fetch(`/api/storage/${typeResource}`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ options, orderId, productId })
    })
      .then((res) => res.json())
      .then(({ files, offset, size, storageSize = 0 }) => {
        /* eslint-disable */

        const f = files.map((file) => {
          if (!isNil(file.fileInfo)) {
            file.id = file.fileInfo.id;
            file.size = file.fileInfo.size;
            file.type = file.fileInfo.type;
            file.url = file.fileInfo.url;
            file.originalUrl = file.fileInfo.originalUrl;
            file.createdAt = file.fileInfo.createdAt;
            return file;
          }

          file.id = file['fileInfo.id'];
          file.size = file['fileInfo.size'];
          file.type = file['fileInfo.type'];
          file.url = file['fileInfo.url'];
          file.originalUrl = file['fileInfo.originalUrl'];
          file.createdAt = file['fileInfo.createdAt'];
          return file;
        });
        /* eslint-enable */
        dispatch({
          type: `STORAGE:${TYPE_DISPATCH}:SUCCESS`,
          payload: { files: f, offset, size, storageSize }
        });
      })
      .catch((res) => {
        console.error(res);
        dispatch({
          type: `STORAGE:${TYPE_DISPATCH}:ERROR`,
          payload: { error: res.error }
        });
      });

export const getCatalogsStorage = initStorage({
  typeResource: 'catalogs',
  TYPE_DISPATCH: 'GET_CATALOGS_ATTACH'
});

export const getChatStorage = initStorage({
  typeResource: 'chat',
  TYPE_DISPATCH: 'GET_CHAT_ATTACH'
});

export const getSupplierOrdersStorage = initStorage({
  typeResource: 'suppliersorder',
  TYPE_DISPATCH: 'GET_SUPPLIER_ORDER_ATTACH'
});

export const getCustomerOrdersStorage = initStorage({
  typeResource: 'customersorder',
  TYPE_DISPATCH: 'GET_CUSTOMER_ORDER_ATTACH'
});

export const getUploadsStorage = initStorage({
  typeResource: 'uploads',
  TYPE_DISPATCH: 'GET_UPLOADS'
});

export const addStorageResources =
  (resourceType, options = {}) =>
  (dispatch) => {
    const getTypeResourceURL = () => {
      switch (resourceType) {
        case 'customersOrders':
          return 'customersorder';
        case 'suppliersOrders':
          return 'suppliersorder';
        case 'catalogs':
          return 'catalogs';
        case 'chat':
          return 'chat';
        case 'uploads':
          return 'uploads';
        default:
          throw new Error(`no such type ${resourceType}`);
      }
    };

    return fetch(`/api/storage/${getTypeResourceURL()}`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ options })
    })
      .then((res) => res.json())
      .then(({ files, offset, size }) => {
        /* eslint-disable */
        const f = files.map((file) => {
          if (!isNil(file.fileInfo)) {
            file.id = file.fileInfo.id;
            file.size = file.fileInfo.size;
            file.type = file.fileInfo.type;
            file.url = convertUrlToFile(file.fileInfo.url);
            file.originalUrl = convertUrlToFile(file.fileInfo.originalUrl);
            file.createdAt = file.fileInfo.createdAt;
            return file;
          }

          file.id = file['fileInfo.id'];
          file.size = file['fileInfo.size'];
          file.type = file['fileInfo.type'];
          file.url = convertUrlToFile(file['fileInfo.url']);
          file.originalUrl = convertUrlToFile(file['fileInfo.originalUrl']);
          file.createdAt = file['fileInfo.createdAt'];
          return file;
        });
        /* eslint-enable */
        dispatch({
          type: `STORAGE:ADD_RESOURCES:SUCCESS`,
          payload: {
            files: f,
            offset,
            size,
            resourceType: resourceType.toLowerCase()
          }
        });
      })
      .catch((res) => {
        console.error(res);
        dispatch({
          type: `STORAGE:ADD_RESOURCES:ERROR`,
          payload: { error: res.error }
        });
      });
  };

export const getModalStorageSearch =
  (resourceType, { search = '', limit = 20, page = 0 }) =>
  (dispatch) => {
    const getTypeResourceURL = () => {
      switch (resourceType) {
        case 'customersOrders':
          return 'customersorder';
        case 'suppliersOrders':
          return 'suppliersorder';
        case 'catalogs':
          return 'catalogs';
        case 'chat':
          return 'chat';
        case 'uploads':
          return 'uploads';
        default:
          throw new Error(`no such type ${resourceType}`);
      }
    };

    return fetch(`/api/storage/${getTypeResourceURL()}`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ options: { search, limit }, page })
    })
      .then((res) => res.json())
      .then(({ files, size }) => {
        // todo refactor files type to one structure
        const f = convertFileUrls(
          getTypeResourceURL() === 'uploads'
            ? files.map((file) => ({ ...file, ...file.fileInfo }))
            : files.map((file) => ({
                ...file,
                id: file['fileInfo.id'],
                size: file['fileInfo.size'],
                type: file['fileInfo.type'],
                url: file['fileInfo.url'],
                createdAt: file['fileInfo.createdAt']
              })),
          'url'
        );
        dispatch({
          type: 'STORAGE:GET_TAB_SEARCH:SUCCESS',
          payload: { files: f, page, totalSize: size }
        });
      })
      .catch((res) => console.error(res));
  };

export const getFilesByIds = (fileIds, section) => () =>
  fetch('/api/storage/files', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ fileIds, section })
  })
    .then((res) => res.json())
    .then(({ files }) => files)
    .catch((res) => {
      console.error(res);
    });

export const getFilesById =
  (fileId, section, orderBy, direction) => (dispatch) =>
    fetch(
      `/api/storage/files/${fileId}?orderBy=${orderBy}&&direction=${direction}`,
      {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ section })
      }
    )
      .then((res) => res.json())
      .then(({ files }) => {
        dispatch({ type: 'STORAGE:GET_FILES:SUCCESS', payload: { files } });
      })
      .catch((res) => {
        dispatch({
          type: 'STORAGE:GET_FILES:ERROR',
          payload: { error: res.error }
        });
      });

export const removeFileLink = (linkId) => (dispatch) =>
  fetch('/api/storage/deleteFile', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ linkId })
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({ type: 'STORAGE:REMOVE_FILES_LINK:SUCCESS' });
    })
    .catch((res) => {
      dispatch({
        type: 'STORAGE:REMOVE_FILES_LINK:ERROR',
        payload: { error: res.error }
      });
    });

export const removeFileLinkBySection = (fileId, section) => (dispatch) =>
  fetch('/api/storage/deleteFileBySection', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ fileId, section })
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({
        type: 'STORAGE:REMOVE_FILES_LINK:SUCCESS',
        payload: { fileId, section }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'STORAGE:REMOVE_FILES_LINK:ERROR',
        payload: { error: res.error }
      });
    });

export const uploadFile =
  (body, storageUploadEvent = true) =>
  (dispatch) => {
    toast.system('File upload started', {
      key: 'upload-files-processing'
    });
    return fetch(`/api/storage/upload/${escapeText(body.name)}`, {
      credentials: 'include',
      method: 'POST',
      body
    })
      .then((res) =>
        res.json().then((json) =>
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({
                // eslint-disable-line
                errors: json.errors.map((error) => error.message) || [],
                status: res.status
              })
        )
      )
      .then((response) => {
        if (storageUploadEvent) {
          const convertedResponce = {
            ...response,
            id: response.fileInfo.id,
            size: response.fileInfo.size,
            url: response.fileInfo.url,
            'fileInfo.id': response.fileInfo?.id,
            'fileInfo.size': response.fileInfo?.size,
            'fileInfo.type': response.fileInfo?.type,
            'fileInfo.url': response.fileInfo?.url,
            'fileInfo.createdAt': response.fileInfo?.createdAt
          };
          dispatch({
            type: 'STORAGE:FILE_UPLOAD:SUCCESS',
            payload: convertedResponce
          });
          toast.system('File upload finished', {
            key: 'upload-files-success'
          });
        }
        return response;
      })
      .catch(({ errors = [] }) => {
        dispatch({ type: 'STORAGE:FILE_UPLOAD:FAILURE', payload: { errors } });
        const error = errors[0];
        if (!errors.length) {
          toast.system('Error while uploading files', {
            key: 'upload-files-failed'
          });
        } else if (error === 'Storage space limit') {
          const linkKey = 'increase_storage';
          const linkClickHandler = () => dispatch(storageOversizeHandler());

          toast.system('', {
            key: 'upload-files-failed',
            data: {
              key: error,
              linkClickHandler,
              linkKey
            }
          });
        } else {
          toast.system(errors[0] || 'File cant be upload', {
            key: 'upload-files-failed'
          });
        }
      });
  };

export const copyFilesToStorageFromOrder =
  (orderId, attacheIds) => (dispatch) => {
    dispatch(setInfoMessage({ key: 'Downloading' }));
    return fetch(`/api/storage/copy_from_order/${orderId}`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ attacheIds })
    })
      .then((res) => res.json())
      .then(() => {
        dispatch(setSuccessMessage({ key: 'Сopied' }));
        dispatch({ type: 'STORAGE:COPY_FILES:SUCCESS' });
      })
      .catch((res) => {
        dispatch(setErrorMessage({ key: 'Сopy failed' }));
        dispatch({
          type: 'STORAGE:COPY_FILES:ERROR',
          payload: { error: res.error }
        });
      });
  };

export const checkFileExist = (name, section) => (dispatch) =>
  fetch(`/api/storage/check_file_exist?section=${section}&name=${name}`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then(({ existsCount, exist }) => {
      dispatch({ type: 'STORAGE:CHECK_EXIST:SUCCESS' });
      return { existsCount, exist };
    })
    .catch((res) => {
      dispatch({
        type: 'STORAGE:CHECK_EXIST:ERROR',
        payload: { error: res.error }
      });
    });

export const getStorageSize = () => (dispatch) =>
  fetch('/api/storage/getLimit', {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then(({ size, maxSize }) => {
      dispatch({
        type: 'STORAGE:SET_LIMIT:SUCCESS',
        payload: { maxSize, size }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'STORAGE:SET_LIMIT:ERROR',
        payload: { error: res.error }
      });
    });

export const getEmployeesStorageStatistic =
  (options = {}) =>
  (dispatch) => {
    const { offset = 0, limit = 10 } = options;
    return fetch(`/api/storage/employees?offset=${offset}&limit=${limit}`, {
      credentials: 'include',
      method: 'GET'
    })
      .then((res) => res.json())
      .then((statistic) => {
        dispatch({ type: 'STORAGE:SET_STATISTIC', payload: statistic });
      })
      .catch((res) => {
        dispatch({
          type: 'STORAGE:SET_STATISTIC:ERROR',
          payload: { error: res.error }
        });
      });
  };

export const updateStorageSize = (size) => (dispatch) =>
  dispatch({ type: 'STORAGE:SET_SIZE', payload: { size } });
