import { ChangeEvent, useCallback } from 'react';
import {
  RadioGroup as MuiRadioGroup,
  Radio as MuiRadio,
  FormControlLabel,
  FormControl
} from '@mui/material';
import { Typography } from '@/components/Typography';
import { isFunction } from '@/lib/utils';
import cn from 'classnames';
import Selected from './assets/selected.svg';
import Unselected from './assets/unselected.svg';
import { RadioGroupProps, RadioProps } from './radioGroup.types';
import styles from './radioGroup.module.scss';

const radioClasses = {
  root: styles.radio,
  disabled: styles.radioDisabled
};

export function Radio({
  label,
  value,
  disabled,
  selected,
  className,
  radioClassName,
  radioPlacement = 'center'
}: RadioProps) {
  return (
    <Typography variant="body1Reg" className={cn(styles.label, className)}>
      <FormControlLabel
        disableTypography
        value={value}
        control={
          <MuiRadio
            disableRipple
            disabled={disabled}
            checked={selected}
            checkedIcon={<Selected width={16} height={16} />}
            icon={<Unselected width={16} height={16} />}
            classes={{
              root: cn(
                radioClassName,
                styles[`radioPlacement-${radioPlacement}`]
              )
            }}
          />
        }
        label={label}
        classes={radioClasses}
      />
    </Typography>
  );
}

export function RadioGroup({ onChange, children }: RadioGroupProps) {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (isFunction(onChange)) {
        onChange(event.target.value);
      }
    },
    [onChange]
  );

  return (
    <FormControl>
      <MuiRadioGroup onChange={handleChange}>{children}</MuiRadioGroup>
    </FormControl>
  );
}
