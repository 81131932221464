import ReactGA from 'react-ga';
import { getCurrentUserEmail } from '../storeGetters';

export const gaPageView = (view) => () => {
  // eslint-disable-line
  if (process.env.GA_ENABLED) {
    ReactGA.pageview(view);
  }
};

export const gaSend =
  ({ category, action, label }) =>
  () => {
    // eslint-disable-line
    if (process.env.GA_ENABLED && label) {
      ReactGA.ga('send', 'event', category, action, label);
    }
  };

export const gaSendServiceAction = (action) => async (dispatch, getState) => {
  dispatch(
    gaSend({
      category: 'Common',
      action,
      label: getCurrentUserEmail(getState())
    })
  );
};

export const getSupportData = () => (dispatch) =>
  fetch('/api/user/support', {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((supportData) => {
      dispatch({ type: 'SET_SUPPORT_DATA', payload: { supportData } });
    });

export const saveOrderAndlogin =
  (email, password, priceId, token, items, priceCategoryConditions) =>
  async (dispatch) => {
    const res = await fetch('/api/register/order_login', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        password,
        priceId,
        token,
        items,
        priceCategoryConditions
      })
    });
    const json = await res.json();
    if (res.status > 200) {
      return { ok: false, error: json.errors[0] };
    }
    dispatch({ type: 'SEND_VERIFY:SUCCESS', payload: { data: json } });
    return { ok: true, orderId: json.orderId, priceOwner: json.priceOwner };
  };
