import request from '@/lib/request';
import { escapeText } from '@/utils/utils';

export const req = {
  getChats: ({
    page = 0,
    limit = 20,
    search = '',
    user = [],
    mode = ''
  } = {}) =>
    request.post('/api/dialogs', {
      mode,
      user,
      system: ['inactive'],
      search,
      page,
      limit,
      settingsOff: false
    }),
  getArchiveChats: ({
    page = 0,
    limit = 20,
    search = '',
    user = [],
    mode = ''
  } = {}) =>
    request.post('/api/dialogs', {
      mode,
      system: ['archive', 'inactive'],
      user,
      search,
      page,
      limit,
      settingsOff: false
    }),
  getMessages: (chatId) => request.get(`/api/channel/${chatId}/messages`),
  getUnsentFiles: (chatId) =>
    request.get(`/api/channel/${chatId}/unsent-files`),
  getContacts: ({
    search = '',
    limit = 20,
    page = 0,
    filterBy = [],
    mode = 'all'
  } = {}) =>
    request.post('/api/contacts', {
      search,
      contactLists: filterBy,
      limit,
      page,
      mode
    }),
  getContactInfo: (employeeId) =>
    request.get(`/api/contacts/info/employeeid/${employeeId}`),
  notificationOnFromProfile: (dialogId, uniqueId) =>
    request.post(`/api/dialogs/notification_on`, { dialogId, uniqueId }),
  notificationOffFromProfile: (dialogId, uniqueId) =>
    request.post(`/api/dialogs/notification_off`, { dialogId, uniqueId }),
  notifyOn: (channelId) =>
    request.post(`/api/channel/${channelId}/notification_on`),
  notifyOff: (channelId) =>
    request.post(`/api/channel/${channelId}/notification_off`),
  archiveChat: (chatId) => request.post(`/api/channel/${chatId}/archive`),
  unarchiveChat: (chatId) => request.post(`/api/channel/${chatId}/unarchive`),
  attachUnsentFileFromStorage: (channelId, filesId) =>
    request.post(`/api/channel/${channelId}/unsent-files`, { filesId }),
  removeUnsentFile: (chatId, fileId) =>
    request.delete(`/api/channel/${chatId}/unsent-files/${fileId}`),
  attachUnsentFile: (channelId, fileName, file) =>
    request.sendFile(
      `/api/channel/${channelId}/unsent-files/${escapeText(fileName)}`,
      file
    ),
  copyFileToStorage: (chatId, fileId) =>
    request.post(`/api/channel/${chatId}/copy-file`, { fileId }),
  getTopicUnsaveInfoFiles: (topicId) =>
    request.get(`/api/topic/${topicId}/unsave-info-files`),
  attachTopicUnsaveInfoFile: (topicId, fileName, file) =>
    request.sendFile(
      `/api/topic/${topicId}/unsave-info-files/${escapeText(fileName)}`,
      file
    ),
  attachTopicUnsaveInfoFileFromStorage: (topicId, filesId) =>
    request.post(`/api/topic/${topicId}/unsave-info-files`, { filesId }),
  removeTopicUnsaveInfoFile: (topicId, fileId) =>
    request.delete(`/api/topic/${topicId}/unsave-info-files/${fileId}`),
  clearTopicUnsaveInfoFile: (topicId) =>
    request.delete(`/api/topic/${topicId}/unsave-info-files/all`)
};
