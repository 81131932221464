import { createContext, PropsWithChildren, useContext } from 'react';
import { TableContextDef } from './types';

const TableContext = createContext<TableContextDef>(
  null as unknown as TableContextDef
);

interface TableContextProviderProps extends PropsWithChildren {
  context: TableContextDef;
}

export function useEditableTableContext() {
  const context = useContext(TableContext);

  if (!context) {
    throw new Error(
      'useEditableTableContext must be used within a TableContext'
    );
  }

  return context;
}

export function TableContextProvider({
  context,
  children
}: TableContextProviderProps) {
  return (
    <TableContext.Provider value={context}>{children}</TableContext.Provider>
  );
}
