import { Map, List, fromJS } from 'immutable';

const initialState = Map({
  mode: 'all',
  system: List(),
  user: Map({
    all: List(),
    channel: List(),
    topic: List(),
    dialog: List()
  }),
  systemChecked: List(),
  userChecked: List()
});

// Dialog lists reducers
// ----------------------------------------------------------------------------------
const setMode = (state, { mode }) => state.set('mode', mode);

const setLists = (state, { system, user }) =>
  state.set('system', fromJS(system)).set('user', fromJS(user));

const setInitialCheckedLists = (
  state,
  { systemChecked = [], userChecked = [] }
) =>
  state
    .set('systemChecked', fromJS(systemChecked))
    .set('userChecked', fromJS(userChecked));

const clearLists = (state) => state.set('system', List()).set('user', List());

const clearSystemChecked = (state) => state.set('systemChecked', List());
const clearUserChecked = (state) => state.set('userChecked', List());

const toggleSystemChecked = (state, { id }) => {
  const checked = state.get('systemChecked');
  const includes = checked.includes(id);

  if (includes) {
    return state.set(
      'systemChecked',
      checked.filter((value) => id !== value)
    );
  }
  return state.set('systemChecked', checked.push(id));
};

const toggleUserChecked = (state, { id }) => {
  const checked = state.get('userChecked');
  const includes = checked.includes(id);

  if (includes) {
    return state.set(
      'userChecked',
      checked.filter((value) => id !== value)
    );
  }
  return state.set('userChecked', checked.push(id));
};

const addUserList = (state, { id, name }) =>
  state.setIn(
    ['user', 'all'],
    state.getIn(['user', 'all']).push(fromJS({ id, name }))
  );

const editUserList = (state, { id, name }) => {
  // Update name in user's all lists
  let newState = state.setIn(
    ['user', 'all'],
    state.getIn(['user', 'all']).set(
      state.getIn(['user', 'all']).findIndex((list) => list.get('id') === id),
      fromJS({ id, name })
    )
  );

  // If the list exists in other lists, then update them too
  const channelListIndex = newState
    .getIn(['user', 'channel'])
    .findIndex((list) => list.get('id') === id);
  const topicListIndex = newState
    .getIn(['user', 'topic'])
    .findIndex((list) => list.get('id') === id);
  const dialogListIndex = newState
    .getIn(['user', 'dialog'])
    .findIndex((list) => list.get('id') === id);

  if (channelListIndex !== -1) {
    newState = newState.setIn(
      ['user', 'channel'],
      state
        .getIn(['user', 'channel'])
        .set(channelListIndex, fromJS({ id, name }))
    );
  }

  if (channelListIndex !== -1) {
    newState = newState.setIn(
      ['user', 'topic'],
      state.getIn(['user', 'topic']).set(topicListIndex, fromJS({ id, name }))
    );
  }

  if (dialogListIndex !== -1) {
    newState = newState.setIn(
      ['user', 'dialog'],
      state.getIn(['user', 'dialog']).set(dialogListIndex, fromJS({ id, name }))
    );
  }

  return newState;
};

const removeUserList = (state, { id }) =>
  state
    .setIn(
      ['user', 'all'],
      state.getIn(['user', 'all']).filter((list) => list.get('id') !== id)
    )
    .setIn(
      ['user', 'channel'],
      state.getIn(['user', 'channel']).filter((list) => list.get('id') !== id)
    )
    .setIn(
      ['user', 'topic'],
      state.getIn(['user', 'topic']).filter((list) => list.get('id') !== id)
    )
    .setIn(
      ['user', 'dialog'],
      state.getIn(['user', 'dialog']).filter((list) => list.get('id') !== id)
    );

const dialogList = (state = initialState, action) => {
  switch (action.type) {
    case 'DIALOG_LISTS:SET_MODE':
      return setMode(state, action.payload);
    case 'DIALOG_LISTS:SET_LISTS':
      return setLists(state, action.payload);
    case 'DIALOG_LISTS:SET_INITIAL_CHECKED_LISTS':
      return setInitialCheckedLists(state, action.payload);
    case 'DIALOG_LISTS:CLEAR_SYSTEM_CHECKED':
      return clearSystemChecked(state);
    case 'DIALOG_LISTS:CLEAR_USER_CHECKED':
      return clearUserChecked(state);
    case 'DIALOG_LISTS:CLEAR_LISTS':
      return clearLists(state);
    case 'DIALOG_LISTS:TOGGLE_SYSTEM_CHECKED':
      return toggleSystemChecked(state, action.payload);
    case 'DIALOG_LISTS:TOGGLE_USER_CHECKED':
      return toggleUserChecked(state, action.payload);
    case 'DIALOG_LISTS:ADD_USER_LIST':
      return addUserList(state, action.payload);
    case 'DIALOG_LISTS:EDIT_USER_LIST':
      return editUserList(state, action.payload);
    case 'DIALOG_LISTS:REMOVE_USER_LIST':
      return removeUserList(state, action.payload);
    default:
      return state;
  }
};

export default dialogList;
