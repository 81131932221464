.select {
  color: #252b49;
  font-size: 14px;
  line-height: 150%;
  width: 100%;

  :global(.dbs__control) {
    border: 1px solid #ebeef4;
    box-shadow: none;
  }

  &:hover :global(.dbs__control) {
    border: 1px solid #ebeef4;
  }

  :global(.dbs__indicators) {
    border: none;
    visibility: hidden;
    height: 34px;
  }

  .readable {
    display: block;
  }

  .editable {
    display: none;
  }
}

.select:not(:disabled):focus .editable {
  display: block;
}

.select:not(:disabled):focus .readable {
  display: none;
}
