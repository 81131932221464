import { assoc, assocPath, isNil, update } from 'ramda';
import {
  REDUX_KEY,
  SET_COMPANIES_LIST,
  CLEAR_COMPANIES_LIST,
  ADD_CHUNK_TO_COMPANIES_LIST,
  SET_OPENED_COMPANY_PROFILE,
  CLEAR_OPENED_COMPANY_PROFILE,
  UPDATE_COMPANY_IN_LIST
} from './constants';
import { makeBlockCompanies } from './data-type';

export const defaultState = () => ({
  [REDUX_KEY]: makeBlockCompanies()
});

const setBlockCompanies = (chatWidget, blockCompanies) =>
  assoc(REDUX_KEY, makeBlockCompanies(blockCompanies), chatWidget);

const getList = (chatWidget) => chatWidget[REDUX_KEY].list;
const setList = (chatWidget, list) =>
  assocPath([REDUX_KEY, 'list'], list, chatWidget);

const getOpenedCompanyProfile = (chatWidget) =>
  chatWidget[REDUX_KEY].openedCompanyProfile;

const clearBlockCompanies = (chatWidget) =>
  assoc(REDUX_KEY, makeBlockCompanies(), chatWidget);

const addChunkToList = (chatWidget, data) => {
  let result = setList(chatWidget, getList(chatWidget).concat(data.list));
  result = assocPath([REDUX_KEY, 'restCount'], data.restCount, result);
  result = assocPath([REDUX_KEY, 'offset'], data.offset, result);
  result = assocPath([REDUX_KEY, 'limit'], data.limit, result);

  return result;
};

const setOpenedCompanyProfile = (chatWidget, profile) =>
  assocPath([REDUX_KEY, 'openedCompanyProfile'], profile, chatWidget);

const clearOpenedCompanyProfile = (chatWidget) =>
  assocPath([REDUX_KEY, 'openedCompanyProfile'], null, chatWidget);

const updateCompanyInList = (chatWidget, company) => {
  let result = chatWidget;

  const index = getList(chatWidget).findIndex((item) => item.id === company.id);

  if (index === -1) return chatWidget;

  const updatedList = update(index, company, getList(chatWidget));
  result = setList(chatWidget, updatedList);

  const openedCompanyProfile = getOpenedCompanyProfile(chatWidget);
  if (!isNil(openedCompanyProfile) && openedCompanyProfile.id === company.id) {
    result = setOpenedCompanyProfile(result, company);
  }
  return result;
};

export const reducers = {
  [SET_COMPANIES_LIST]: setBlockCompanies,
  [ADD_CHUNK_TO_COMPANIES_LIST]: addChunkToList,
  [CLEAR_COMPANIES_LIST]: clearBlockCompanies,
  [SET_OPENED_COMPANY_PROFILE]: setOpenedCompanyProfile,
  [CLEAR_OPENED_COMPANY_PROFILE]: clearOpenedCompanyProfile,
  [UPDATE_COMPANY_IN_LIST]: updateCompanyInList
};
