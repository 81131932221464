import { Map } from 'immutable';

const updateToken = (state, { token, email }) =>
  state.set('token', token).set('email', email);

const updateTokenVerificationError = (state, { message }) =>
  state.set('tokenError', message);

export default (state = Map(), { type, payload }) => {
  switch (type) {
    case 'UPDATE_TOKEN':
      return updateToken(state, payload);
    case 'UPDATE_TOKEN_VERIFICATION_ERROR':
      return updateTokenVerificationError(state, payload);
    default:
      return state;
  }
};
