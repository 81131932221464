import { sckt } from '@/components/ChatWidget/useCases/shared/socket';

export const unsubscribeToUpdateChat = (chatId) => () => {
  sckt.unsubscribeFromNewMsg();
  sckt.unsubscribeToUpdateChannel();
  sckt.unsubToAddUnreadMsg();
  sckt.unsubToClearUnreadMsg();
  sckt.unsubscribeToRemoveMsg();
  sckt.unsubscribeFromUpdateMsg();
  sckt.leaveFromChannel(chatId);
};
