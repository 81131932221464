import React from 'react';
import { usePreviousDistinct } from 'react-use';
import cn from 'classnames';
import { Tooltip } from '@link/react-components';
import { useTranslate } from '@/hooks';
import { useVideoChatContext } from '../../../hooks';
import { VIDEO_CHAT_MODES } from '../../../hooks/useVideoChat';
import { MicOn } from './components/MicOn';
import { MicOff } from './components/MicOff';
import styles from './ButtonMicrophone.module.css';

const MODES = {
  On: 'on',
  Off: 'off'
};

const VARIANTS = {
  guest: 'guest',
  outline: 'outline',
  filled: 'filled'
};

export const ButtonMicrophone = ({
  variant = VARIANTS.guest,
  hideTooltips = false,
  tooltipPlacement = 'bottom'
}) => {
  const t = useTranslate();
  const { mode, toggleAudio, currentMember, canUseMicrophone } =
    useVideoChatContext();
  const previousVideoChatMode = usePreviousDistinct(mode);

  const initializeMode = () => {
    if (!canUseMicrophone || !currentMember) return MODES.Off;

    return currentMember.isMicrophoneMuted ? MODES.Off : MODES.On;
  };

  const [disabled, setDisabled] = React.useState(!canUseMicrophone);
  const [micMode, setMicMode] = React.useState(initializeMode);

  React.useEffect(() => {
    setMicMode(initializeMode());
  }, [currentMember?.isMicrophoneMuted]);

  React.useEffect(() => {
    if (previousVideoChatMode === VIDEO_CHAT_MODES.Close) {
      setMicMode(currentMember?.isMicrophoneMuted ? MODES.Off : MODES.On);
    }
  }, [mode === VIDEO_CHAT_MODES.Open]);

  React.useEffect(() => {
    setDisabled(!canUseMicrophone);
  }, [canUseMicrophone]);

  const toggleMicMode = () =>
    setMicMode((prev) => {
      const result = prev === MODES.On ? MODES.Off : MODES.On;
      toggleAudio(result === MODES.On);
      return result;
    });

  const translateTooltip = (tKey) => t(`videoChat.tooltips.${tKey}`);

  const icon = micMode === MODES.On ? <MicOn /> : <MicOff />;
  const className = cn(
    styles.ButtonMicrophone,
    styles[variant],
    micMode === MODES.On ? styles.on : styles.off,
    disabled && styles.disabled
  );
  const tooltip = (() => {
    if (hideTooltips) return '';

    if (!canUseMicrophone) return translateTooltip('no-microphone');
    if (micMode === MODES.Off) return translateTooltip('microphone-on');
    if (micMode === MODES.On) return translateTooltip('microphone-off');

    return '';
  })();

  return (
    <Tooltip placement={tooltipPlacement} title={tooltip}>
      <button className={className} onClick={!disabled && toggleMicMode}>
        {icon}
      </button>
    </Tooltip>
  );
};
