import React from 'react';

/* eslint-disable max-len */
const IconAddChat = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      className="cws-icon-hover"
      cx="17"
      cy="17"
      r="17"
      fill="#767B92"
      fillOpacity="0"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.08016 10.1584C9.08577 9.66536 9.48704 9.26862 9.98011 9.26862H17.3553C17.7142 9.26862 18.0052 9.55963 18.0052 9.91862C18.0052 10.2776 17.7142 10.5686 17.3553 10.5686H10.3756V23.3802H23.5205V16.5334C23.5205 16.1744 23.8115 15.8834 24.1705 15.8834C24.5295 15.8834 24.8205 16.1744 24.8205 16.5334V23.7802C24.8205 24.2772 24.4176 24.6802 23.9205 24.6802H9.97102C9.46996 24.6802 9.06538 24.271 9.07107 23.77L9.08016 10.1584Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.8536 10.9223L23.0777 10.1465C22.8824 9.95118 22.5659 9.95118 22.3706 10.1465L21.4817 11.0353L22.9717 12.5113L23.8536 11.6294C24.0488 11.4341 24.0488 11.1176 23.8536 10.9223ZM22.2645 13.2184L20.7746 11.7425L15.0975 17.4196C15.0515 17.4656 15.0149 17.5201 14.9898 17.58L14.4408 18.8912C14.2691 19.3012 14.6777 19.7146 15.0896 19.5477L16.4167 19.0103C16.4788 18.9851 16.5352 18.9478 16.5826 18.9004L22.2645 13.2184Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconAddChat;
