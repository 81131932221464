import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import { BasePopups, OpenedPopup, PopupContext } from './popup.types';

interface PopupContextProviderProps extends PropsWithChildren {
  popups: BasePopups;
}

const Context = createContext<PopupContext>(null as unknown as PopupContext);

export function usePopupContext() {
  const context = useContext(Context);

  if (!context) {
    throw new Error(`usePopup must used within a PopupContextProvider`);
  }

  return context;
}

export function PopupProvider(props: PopupContextProviderProps) {
  const { children, popups } = props;

  const [opened, setOpened] = useState<OpenedPopup | null>(null);

  const onClose = useCallback((key?: string) => {
    setOpened((prevState) => {
      if (!key || prevState?.key === key) {
        return null;
      }

      return prevState;
    });
  }, []);

  const context = useMemo(
    () => ({
      opened,
      popups,
      setOpened,
      onClose
    }),
    [opened, popups, onClose]
  );

  const Popup = opened ? popups[opened.key] : null;
  const shownPopup = !!Popup;

  return (
    <Context.Provider value={context}>
      <>
        {children}
        {shownPopup && <Popup {...opened?.props} />}
      </>
    </Context.Provider>
  );
}
