import { getCurrentEmployeeId } from '@/storeGetters';
import { setSuccessMessage } from '@/action-creators/message';
import { isNewsFeedChannel } from 'core/data/light/newsFeedChannel';
import { showModal } from '@/action-creators/modal';
import { getInterlocutor } from 'core/data/light/dialog';
import { req } from '@/components/ChatWidget/useCases/shared/requests';
import { sckt } from '@/components/ChatWidget/useCases/shared/socket';
import { ChannelType } from '../types';

export const toggleArchive = (chat) => async (dispatch, getState) => {
  const employeeId = getCurrentEmployeeId(getState());

  const showNotifyArhived = (isArchived) => {
    const status = isArchived ? 'archived' : 'unarchived';
    dispatch(
      setSuccessMessage({ key: `${chat.type.toLowerCase()}_${status}` })
    );
  };

  const unarchive = async () => {
    const confirm = (submitAction) => {
      if (
        chat.type === ChannelType.Topic ||
        chat.type === ChannelType.Channel ||
        isNewsFeedChannel(chat) ||
        chat.type === ChannelType.PurchaseInboundChannel ||
        chat.type === ChannelType.ProcurementChannel
      ) {
        dispatch(
          showModal('SIMPLE_SUBMIT', {
            captionKey: `modal_unarchive_${chat.type.toLowerCase()}_title`,
            text: {
              tkey: `modal_unarchive_${chat.type.toLowerCase()}_text`,
              options: { chatName: chat.name }
            },
            textBtnConfirm: 'move',
            submitAction
          })
        );
      } else if (chat.type === ChannelType.Dialog) {
        const interlocutor = getInterlocutor(employeeId, chat);

        dispatch(
          showModal('SIMPLE_SUBMIT', {
            captionKey: 'modal_unarchive_title',
            text: {
              tkey: 'modal_unarchive_text',
              options: { userName: interlocutor.userName }
            },
            textBtnConfirm: 'move',
            submitAction
          })
        );
      }
    };

    confirm(async () => {
      await req.unarchiveChat(chat.id); // call api
      showNotifyArhived(false);
      sckt.getUnreadMsgsCount(employeeId);
    });
  };

  const archive = async () => {
    const confirm = (submitAction) => {
      if (
        chat.type === ChannelType.Topic ||
        chat.type === ChannelType.Channel ||
        isNewsFeedChannel(chat) ||
        chat.type === ChannelType.PurchaseInboundChannel ||
        chat.type === ChannelType.ProcurementChannel
      ) {
        dispatch(
          showModal('SIMPLE_SUBMIT', {
            captionKey: `modal_archive_${chat.type.toLowerCase()}_title`,
            text: {
              tkey: `modal_archive_${chat.type.toLowerCase()}_text`,
              options: { chatName: chat.name }
            },
            textBtnConfirm: 'move',
            submitAction
          })
        );
      } else if (chat.type === ChannelType.Dialog) {
        const interlocutor = getInterlocutor(employeeId, chat);

        dispatch(
          showModal('SIMPLE_SUBMIT', {
            captionKey: 'modal_archive_title',
            text: {
              tkey: 'modal_archive_text',
              options: { userName: interlocutor.userName }
            },
            textBtnConfirm: 'move',
            submitAction
          })
        );
      }
    };

    confirm(async () => {
      await req.archiveChat(chat.id); // call api
      showNotifyArhived(true);
      sckt.getUnreadMsgsCount(employeeId);
    });
  };

  if (chat.archived) {
    await unarchive();
  } else {
    await archive();
  }
};
