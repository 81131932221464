import React from 'react';

/* eslint-disable max-len */
const IconArchive = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      className="cws-icon-hover"
      cx="17"
      cy="17"
      r="17"
      fill="#767B92"
      fillOpacity="0"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 12V23.5C9 24.3284 9.67157 25 10.5 25H23.5C24.3284 25 25 24.3284 25 23.5V12H26V23.5C26 24.8807 24.8807 26 23.5 26H10.5C9.11929 26 8 24.8807 8 23.5V12H9Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.4676 9H11.5324C11.1811 9 10.8556 9.1843 10.6749 9.4855L9.76619 11H24.2338L23.3251 9.4855C23.1444 9.1843 22.8189 9 22.4676 9ZM24.1826 8.97101C23.8212 8.3686 23.1701 8 22.4676 8H11.5324C10.8299 8 10.1788 8.3686 9.8174 8.97101L8 12H26L24.1826 8.97101Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8536 19.1464C20.0488 19.3417 20.0488 19.6583 19.8536 19.8536L17.3535 22.3536C17.1583 22.5489 16.8417 22.5489 16.6464 22.3536L14.1464 19.8536C13.9512 19.6583 13.9512 19.3417 14.1464 19.1465C14.3417 18.9512 14.6583 18.9512 14.8535 19.1465L17 21.2929L19.1465 19.1464C19.3417 18.9512 19.6583 18.9512 19.8536 19.1464Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 22V15H17.5V22H16.5Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconArchive;
