import React from 'react';

function IconSlidersPath() {
  return (
    <svg width="18" height="19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M2.685 1.916a.459.459 0 0 0-.269.282c-.04.096-.046.456-.044 2.924.002 2.077.012 2.837.038 2.904.096.25.258.344.592.344.295 0 .458-.078.559-.269l.069-.13V5.117c0-2.774-.002-2.858-.059-2.969-.101-.199-.269-.283-.571-.285a.76.76 0 0 0-.315.053M8.7 1.908a.557.557 0 0 0-.281.252c-.024.052-.039.587-.049 1.755l-.015 1.68-.93.015c-.605.01-.956.027-1.005.05-.213.097-.315.325-.293.655.017.27.092.405.276.494.13.063.149.063 2.597.063s2.467 0 2.597-.063c.184-.089.259-.224.276-.494.022-.33-.08-.558-.293-.655-.049-.023-.4-.04-1.005-.05l-.93-.015-.015-1.68c-.01-1.168-.025-1.703-.049-1.755a.549.549 0 0 0-.288-.254c-.141-.058-.456-.058-.593.002m6.014-.008a.468.468 0 0 0-.285.248c-.058.112-.059.201-.059 3.719v3.604l.069.13c.097.183.266.269.528.269.109 0 .246-.014.304-.03.128-.035.278-.187.325-.327.025-.074.034-1.086.033-3.66 0-3.388-.003-3.564-.055-3.678a.459.459 0 0 0-.282-.269 1.08 1.08 0 0 0-.578-.006M8.709 8.643a.52.52 0 0 0-.308.321c-.041.149-.043 7.148-.001 7.297.035.128.187.278.327.325.131.044.399.044.545 0a.544.544 0 0 0 .324-.323c.046-.136.048-7.135.003-7.299a.521.521 0 0 0-.306-.314c-.116-.042-.449-.046-.584-.007M1.665 10.11c-.784.01-1.195.026-1.245.049-.329.151-.411.766-.141 1.056.122.132.176.139 1.146.155l.93.015.015 2.43c.014 2.187.02 2.439.065 2.52.12.218.308.3.639.279.251-.017.379-.08.476-.234l.065-.105.015-2.445.015-2.445.945-.015c.919-.015.948-.017 1.055-.084.161-.099.235-.272.235-.546 0-.273-.074-.447-.234-.546-.091-.055-.16-.069-.395-.075-.654-.019-2.494-.023-3.586-.009m11.985 1.5c-1.17.015-1.186.016-1.295.084-.161.099-.235.272-.235.546s.074.447.235.546c.107.067.136.069 1.055.084l.945.015.015 1.695c.014 1.628.017 1.699.075 1.795.093.154.23.222.48.238.332.022.52-.06.64-.278.044-.08.052-.281.065-1.77l.015-1.68.93-.015c.602-.01.956-.027 1.005-.049.186-.085.3-.306.3-.581 0-.313-.138-.536-.37-.596-.149-.038-2.135-.056-3.86-.034"
        fillRule="evenodd"
        fill="#000"
      />
    </svg>
  );
}

export default IconSlidersPath;
