import React from 'react';
import { handle } from 'core/data/messageBus/frontend';
import { isFunction } from '@/utils';

export function useSubscriptions() {
  const storage = React.useRef([]);

  return {
    subscribe: (key, cb) => storage.current.push(handle(key, cb)),
    unsubscribeAll: () => {
      storage.current.forEach(
        (unsubscribeFn) => isFunction(unsubscribeFn) && unsubscribeFn()
      );
      storage.current = [];
    }
  };
}
