import React from 'react';

const IconMinus = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      className="cws-icon-main-stroke"
      x="0.5"
      y="0.5"
      width="33"
      height="33"
      rx="16.5"
      stroke="white"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16.5L26 16.5V17.5L8 17.5L8 16.5Z"
      fill="white"
    />
  </svg>
);

export default IconMinus;
