// @ts-nocheck

import { useCallback, useState } from 'react';
import cn from 'classnames';
import Select, { components } from 'react-select';
import styles from './DynamicBorderSelect.module.scss';

export function DynamicBorderSelect({
  id,
  value,
  options,
  onChange,
  onInputChange,
  onInputKeyDown,
  arrowRenderer,
  placeholder,
  noResultsText,
  isMulti = false,
  isClearable = false,
  isRemoveSelected = false
}) {
  const [isFocused, setIsFocused] = useState(false);

  const onFocus = useCallback(() => setIsFocused(true), []);
  const onBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return (
    <div
      className={cn(styles.select, {
        [styles.focused]: isFocused
      })}
      onFocus={onFocus}
      onBlur={onBlur}>
      <Select
        id={id}
        classNamePrefix="dbs"
        value={value}
        options={options}
        onChange={onChange}
        onInputChange={onInputChange}
        onInputKeyDown={onInputKeyDown}
        arrowRenderer={arrowRenderer}
        placeholder={placeholder}
        noResultsText={noResultsText}
        isMulti={isMulti}
        isClearable={isClearable}
        isRemoveSelected={isRemoveSelected}
        components={{
          DropdownIndicator: arrowRenderer
            ? // eslint-disable-next-line react/no-unstable-nested-components
              (props) => <DropdownIcon {...props} icon={arrowRenderer} />
            : null,
          IndicatorSeparator: null
        }}
      />
    </div>
  );
}

function DropdownIcon(props) {
  const { icon } = props;

  return (
    <components.DropdownIndicator {...props}>
      {icon}
    </components.DropdownIndicator>
  );
}
