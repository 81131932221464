import React from 'react';

/* eslint-disable max-len */
function IconTask() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 15.5C11.866 15.5 15 12.366 15 8.5C15 7.74976 14.882 7.02709 14.6635 6.34954L9.35212 11.6609C8.63899 12.374 7.48277 12.374 6.76964 11.6609L4.64786 9.53912C4.17482 9.06608 4.17482 8.29915 4.64786 7.82612C5.12089 7.35309 5.88782 7.35309 6.36086 7.82612L7.63047 9.09573C7.86818 9.33344 8.25358 9.33344 8.49129 9.09573L13.4635 4.12351C12.1806 2.52406 10.2099 1.5 8 1.5C4.13401 1.5 1 4.63401 1 8.5C1 12.366 4.13401 15.5 8 15.5Z"
        fill="#5B5F73"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconTask;
