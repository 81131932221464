import React from 'react';
import { useVideoChatContext } from '../../hooks/useVideoChatContext';

const VideoChatWrapper = ({ children }) => {
  const { mode } = useVideoChatContext();

  return <div className={`video-chat video-chat_${mode}`}>{children}</div>;
};

export default VideoChatWrapper;
