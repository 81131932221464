import React from 'react';

const IconEmail = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 6.0625L7.71544 9.99187C8.17826 10.3101 8.40967 10.4692 8.66138 10.5308C8.88373 10.5852 9.11627 10.5852 9.33862 10.5308C9.59033 10.4692 9.82174 10.3101 10.2846 9.99187L16 6.0625M5.36 15H12.64C13.8161 15 14.4042 15 14.8534 14.7752C15.2485 14.5775 15.5698 14.2619 15.7711 13.8739C16 13.4327 16 12.8551 16 11.7V7.3C16 6.14489 16 5.56734 15.7711 5.12614C15.5698 4.73806 15.2485 4.42254 14.8534 4.2248C14.4042 4 13.8161 4 12.64 4H5.36C4.18389 4 3.59583 4 3.14662 4.2248C2.75148 4.42254 2.43022 4.73806 2.22889 5.12614C2 5.56734 2 6.14489 2 7.3V11.7C2 12.8551 2 13.4327 2.22889 13.8739C2.43022 14.2619 2.75148 14.5775 3.14662 14.7752C3.59583 15 4.18389 15 5.36 15Z"
      stroke="#5B5F73"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconEmail;
