import React, {
  PropsWithChildren,
  useCallback,
  useState,
  useRef,
  useEffect
} from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { Typography } from '@/components/Typography';
import { Icon, IconName } from '@/components/Icon';
import cn from 'classnames';
import styles from './NumericInput.module.scss';
import { NumericInputProps } from './NumericInput.types';

const RU_LOCALE = 'ru-RU';
const EN_LOCALE = 'en-US';

interface CustomAdornmentProps {
  units?: string;
}

function CustomAdornment({
  units,
  children
}: PropsWithChildren<CustomAdornmentProps>) {
  return (
    <InputAdornment className={styles.adornment} position="end">
      {units && units.length !== 0 && (
        <Typography className={styles.units} variant="body1Med">
          {units}
        </Typography>
      )}
      {children}
    </InputAdornment>
  );
}

interface SpinButtonProps {
  iconName: IconName;
  disabled: boolean;
  onClick: () => void;
}

function SpinButton({ iconName, disabled, onClick }: SpinButtonProps) {
  return (
    <div
      className={cn(
        styles.spinButton,
        disabled && styles.spinButtonDisabled,
        !disabled && styles.spinButtonHovered
      )}
      role="presentation"
      onClick={onClick}>
      <Icon iconName={iconName} height={8} width={8} />
    </div>
  );
}

interface SpinControlProps {
  disabled: boolean;
  disableUp: boolean;
  disableDown: boolean;
  increase: () => void;
  decrease: () => void;
}

function SpinControl({
  disabled,
  disableUp,
  disableDown,
  increase,
  decrease
}: SpinControlProps) {
  return (
    <div
      className={cn(
        styles.spinControl,
        disabled && styles.spinControlDisabled
      )}>
      <SpinButton iconName="arrow-up" disabled={disableUp} onClick={increase} />
      <div className={styles.delimiter} />
      <SpinButton
        iconName="arrow-down"
        disabled={disableDown}
        onClick={decrease}
      />
    </div>
  );
}

function getConvertValue(rawValue: string, lang: string) {
  return +(lang === 'en'
    ? rawValue.replace(/,/g, '')
    : rawValue.replace(/\s/g, ''));
}

const isNumber = (char: string) => /^-?\d*\.?\d+$/.test(char);

function getInRangeValue(rawValue: number, min: number, max: number) {
  if (rawValue < min) return min;
  if (rawValue > max) return max;
  return rawValue;
}

function toLocaleString(rawValue: number, lang: string) {
  const locale = lang === 'en' ? EN_LOCALE : RU_LOCALE;
  return rawValue.toLocaleString(locale);
}

function getResultedValues(
  rawValue: string,
  lang: string,
  min: number,
  max: number
): [number | null, string | null] {
  if (rawValue.length === 0 || (rawValue.length === 1 && rawValue[0] === '-')) {
    return [null, rawValue];
  }

  if (!isNumber(rawValue[rawValue.length - 1])) {
    return [null, null];
  }

  let split = [];

  if (rawValue.includes('.')) {
    split = rawValue.split('.');
  } else if (rawValue.includes(',')) {
    split = rawValue.split(',');
  } else {
    split = [rawValue];
  }

  const convertedValue = getConvertValue(split[0], lang);
  const resultedValue = getInRangeValue(convertedValue, min, max);

  return [resultedValue, toLocaleString(resultedValue, lang)];
}

export function NumericInput({
  units,
  showSpin = true,
  step = 1,
  value,
  maxValue = Number.MAX_SAFE_INTEGER,
  minValue = Number.MIN_SAFE_INTEGER,
  onChange,
  valid = true,
  disabled = false,
  lang = 'ru',
  placeholder,
  className
}: NumericInputProps) {
  const [currentValue, setCurrentValue] = useState(
    typeof value === 'number'
      ? toLocaleString(getInRangeValue(value, minValue, maxValue), lang)
      : ''
  );
  const refNumber = useRef(value);

  const handleSpin = useCallback(
    (delta: number) => {
      if (!disabled) {
        let numValue = Math.floor((value || 0) / step) * step + delta;
        if (step < 0) {
          numValue += step;
        }
        const result = getInRangeValue(numValue, minValue, maxValue);
        setCurrentValue(toLocaleString(result, lang));
        onChange(result);
      }
    },
    [disabled, step, lang, maxValue, minValue, onChange, value]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const [numValue, strValue] = getResultedValues(
        event.target.value,
        lang,
        minValue,
        maxValue
      );
      if (strValue === null) return;
      if (numValue !== value) {
        onChange(numValue);
        refNumber.current = numValue;
      }

      if (strValue !== currentValue) {
        setCurrentValue(strValue);
      }
    },
    [lang, minValue, maxValue, value, currentValue, onChange]
  );

  useEffect(() => {
    if (value !== refNumber.current) {
      let formattedValue = '';
      if (typeof value === 'number') {
        formattedValue = toLocaleString(
          getInRangeValue(value, minValue, maxValue),
          lang
        );
      }
      refNumber.current = value;
      setCurrentValue(formattedValue);
    }
  }, [value, maxValue, minValue, lang]);

  return (
    <div
      className={cn(
        className,
        styles.numericInput,
        disabled && styles.disabled,
        !valid && styles.error
      )}
      data-testid="numericInput">
      <Typography variant="body1Reg">
        <TextField
          id="text"
          type="text"
          value={currentValue}
          onChange={handleChange}
          disabled={disabled}
          error={!valid}
          placeholder={placeholder}
          InputProps={{
            endAdornment: (
              <CustomAdornment units={units}>
                {showSpin && (
                  <SpinControl
                    disabled={disabled}
                    disableDown={value === minValue}
                    disableUp={value === maxValue}
                    increase={() => handleSpin(step)}
                    decrease={() => handleSpin(-step)}
                  />
                )}
              </CustomAdornment>
            ),
            inputProps: {
              'data-testid': 'input'
            }
          }}
        />
      </Typography>
    </div>
  );
}
