import React from 'react';

/* eslint-disable max-len */
function IconAdd() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 18V0L9.5 5.96046e-08L9.5 18H8.5Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.96046e-08 8.5L18 8.5V9.5L0 9.5L5.96046e-08 8.5Z"
        fill="#5B5F73"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconAdd;
