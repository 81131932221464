import { isNil } from 'ramda';
import { getChatWidget } from '../../getterCommon';
import { REDUX_KEY } from './constants';

export const getChatInputItem = (state, chatId) =>
  getChatWidget(state)[REDUX_KEY].inputsList[chatId] || null;
export const getChatInputItemText = (state, chatId) => {
  const chatInputItem = getChatWidget(state)[REDUX_KEY].inputsList[chatId];

  return !isNil(chatInputItem) ? chatInputItem.text : null;
};
