import React from 'react';

function IconOk() {
  return (
    <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M16.23 3.801c-.118.04-.763.673-5.182 5.09L6 13.935l-2.272-2.27c-1.611-1.608-2.312-2.289-2.407-2.336-.395-.197-.908.028-1.034.455-.059.196-.059.245 0 .448.047.158.117.232 2.468 2.587 1.331 1.333 2.48 2.464 2.554 2.513.176.118.476.208.691.208.216 0 .515-.09.691-.208.074-.049 2.458-2.415 5.298-5.258 4.763-4.767 5.168-5.18 5.212-5.317a.739.739 0 0 0-.175-.783.756.756 0 0 0-.796-.173"
        fill="#5B5F73"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default IconOk;
