import React from 'react';

/* eslint-disable max-len */
const IconLink = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.4979 2.696C10.3774 0.816501 13.4247 0.816501 15.3042 2.696C17.1837 4.57551 17.1837 7.62278 15.3042 9.50228L13.1967 11.6097L12.4896 10.9026L14.5971 8.79518C16.0861 7.3062 16.0861 4.89209 14.5971 3.40311C13.1081 1.91413 10.694 1.91413 9.20501 3.40311L7.09757 5.51055L6.39046 4.80344L8.4979 2.696ZM5.51079 7.09733L3.40335 9.20476C1.91438 10.6937 1.91438 13.1079 3.40335 14.5968C4.89233 16.0858 7.30644 16.0858 8.79542 14.5968L10.9029 12.4894L11.61 13.1965L9.50252 15.3039C7.62302 17.1834 4.57575 17.1834 2.69625 15.3039C0.816745 13.4244 0.816746 10.3772 2.69625 8.49766L4.80368 6.39022L5.51079 7.09733Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2864 5.71379C12.4817 5.90905 12.4817 6.22564 12.2864 6.4209L6.35377 12.3535C6.15851 12.5488 5.84193 12.5488 5.64667 12.3535C5.4514 12.1583 5.4514 11.8417 5.64667 11.6464L11.5793 5.71379C11.7746 5.51853 12.0911 5.51853 12.2864 5.71379Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconLink;
