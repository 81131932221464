import React from 'react';

export function useQueue(initialValue = []) {
  const [state, set] = React.useState(initialValue);

  return {
    add: (value) => {
      set((queue) => [...queue, value]);
    },
    remove: () => {
      let result;

      set(([first, ...rest]) => {
        result = first;
        return rest;
      });

      return result;
    },
    get all() {
      return state;
    },
    get first() {
      return state[0];
    },
    get last() {
      return state[state.length - 1];
    },
    get size() {
      return state.length;
    }
  };
}
