import { assoc } from 'ramda';

/*
 * Сообщение (Message) это абстрактный тип данных
 * который выражается в Команде и Событии
 */

export const makeCommand = (namespace, name) => ({
  type: 'Command',
  name: `COMMAND:${namespace}:${name}`
});

export const makeEvent = (namespace, name) => ({
  type: 'Event',
  name: `EVENT:${namespace}:${name}`
});

export const addParams = assoc('params');
