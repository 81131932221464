import { assoc, prop } from 'ramda';
import { makeFilterOptions } from 'core/data/filter/filterOptions';
import { FILTERS_SET, FILTERS_CLEAR } from '../actionTypes/filterOptions';
import { MY_ORDERS } from '../constants';

const defaultValue = () => ({
  [MY_ORDERS]: makeFilterOptions()
});

const setter = (func) => (list, name, payload) =>
  assoc(name, func(prop(name, list), payload), list);

const reducers = {
  [FILTERS_SET]: setter((state, filterOptions) => filterOptions),
  [FILTERS_CLEAR]: setter(() => makeFilterOptions())
};

export default (state = defaultValue(), { type, name, payload }) => {
  if (reducers[type]) return reducers[type](state, name, payload);
  return state;
};
