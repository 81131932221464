import { increateLoadCounter, resetPage } from '../../../data-type/query';
import { setList } from '../../../data-type/block-group';

const setCountsForGroupContacts = async (
  { getGroups, getGroupContacts, setGroupContacts },
  { count }
) => {
  const groups = await getGroups();
  const groupContacts = getGroupContacts();
  const updatedGroupChat = groups.lists.map((item) => ({
    ...item,
    count: count[item.id]
  }));
  const blockGroups = setList(groupContacts, updatedGroupChat);
  setGroupContacts(blockGroups);
};

export const uploadContactsCase = async (
  {
    getQuery,
    updateQuery,
    getContacts,
    getGroups,
    getGroupContacts,
    setContacts,
    setFilteredContacts,
    setGroupContacts,
    setLoading
  },
  { newParams = {} } = {}
) => {
  const query = newParams.query || getQuery();
  const resetedPages = resetPage(query);

  setLoading(true);
  const contacts = await getContacts({
    ...newParams,
    query: resetedPages
  });
  await setCountsForGroupContacts(
    { getGroups, getGroupContacts, setGroupContacts },
    { count: contacts.membersCount }
  );
  setContacts(contacts);
  setFilteredContacts(contacts);
  setLoading(false);

  const increaseCounterQuery = increateLoadCounter(resetedPages);
  updateQuery(increaseCounterQuery);
};
