import React from 'react';

/* eslint-disable max-len */
function IconStylePunkt() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className="cws-icon-main-fill"
        cx="1.40002"
        cy="4.93701"
        r="0.881466"
        fill="#5B5F73"
      />
      <rect
        className="cws-icon-main-fill"
        x="3.52368"
        y="4.23706"
        width="14.1197"
        height="1.4"
        rx="0.7"
        fill="#5B5F73"
      />
      <circle
        className="cws-icon-main-fill"
        cx="1.40002"
        cy="9.5"
        r="0.881466"
        fill="#5B5F73"
      />
      <rect
        className="cws-icon-main-fill"
        x="3.52368"
        y="8.80005"
        width="14.1197"
        height="1.4"
        rx="0.7"
        fill="#5B5F73"
      />
      <circle
        className="cws-icon-main-fill"
        cx="1.40002"
        cy="14.063"
        r="0.881466"
        fill="#5B5F73"
      />
      <rect
        className="cws-icon-main-fill"
        x="3.52368"
        y="13.363"
        width="14.1197"
        height="1.4"
        rx="0.7"
        fill="#5B5F73"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconStylePunkt;
