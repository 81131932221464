import React, { useLayoutEffect, useMemo, useRef } from 'react';
import { HasRootRef } from '@/components/common.types';
import {
  coordX,
  coordY,
  getSupportedEvents,
  touchEnabled,
  UITouchEvent
} from '@/lib/touch';
import { useEventListener, useExternRef } from '@/hooks';

export interface TouchProps
  extends React.AllHTMLAttributes<HTMLElement>,
    HasRootRef<HTMLElement> {
  Component?: React.ElementType;
  usePointerHover?: boolean;
  useCapture?: boolean;
  slideThreshold?: number;
  noSlideClick?: boolean;
  onEnter?: HoverHandler;
  onLeave?: HoverHandler;
  onStart?: TouchEventHandler;
  onStartX?: TouchEventHandler;
  onStartY?: TouchEventHandler;
  onMove?: TouchEventHandler;
  onMoveX?: TouchEventHandler;
  onMoveY?: TouchEventHandler;
  onEnd?: TouchEventHandler;
  onEndX?: TouchEventHandler;
  onEndY?: TouchEventHandler;
  stopPropagation?: boolean;
}

interface Gesture {
  startX: number;
  startY: number;
  startT: Date;
  duration: number;
  isPressed: boolean;
  isY: boolean;
  isX: boolean;
  isSlideX: boolean;
  isSlideY: boolean;
  isSlide: boolean;
  shiftX: number;
  shiftY: number;
  shiftXAbs: number;
  shiftYAbs: number;
}

export interface TouchEvent extends Gesture {
  originalEvent: UITouchEvent;
}

type HoverHandler = (event: MouseEvent) => void;
type TouchEventHandler = (event: TouchEvent) => void;

function initGesture(startX: number, startY: number): Gesture {
  return {
    startX,
    startY,
    startT: new Date(),
    duration: 0,
    isPressed: true,
    isY: false,
    isX: false,
    isSlideX: false,
    isSlideY: false,
    isSlide: false,
    shiftX: 0,
    shiftY: 0,
    shiftXAbs: 0,
    shiftYAbs: 0
  };
}

export function Touch({
  getRootRef,
  Component = 'div',
  usePointerHover,
  useCapture = false,
  onClickCapture,
  slideThreshold = 5,
  noSlideClick = false,
  onEnter,
  onLeave,
  onStart,
  onStartX,
  onStartY,
  onMove: _onMove,
  onMoveX,
  onMoveY,
  onEnd: _onEnd,
  onEndX,
  onEndY,
  stopPropagation = false,
  ...restProps
}: TouchProps) {
  const events = useMemo(getSupportedEvents, []);
  const didSlide = useRef(false);
  const gesture = React.useRef<Partial<Gesture> | null>(null);

  const handle = (
    event: UITouchEvent,
    handlers: Array<TouchEventHandler | undefined | false>
  ) => {
    if (stopPropagation) {
      event.stopPropagation();
    }

    handlers.forEach((cb) => {
      const duration = Date.now() - (gesture.current?.startT?.getTime() ?? 0);

      if (cb) {
        cb({ ...(gesture.current as Gesture), duration, originalEvent: event });
      }
    });
  };

  const enterHandler = useEventListener(
    usePointerHover ? 'pointerenter' : 'mouseenter',
    onEnter
  );
  const leaveHandler = useEventListener(
    usePointerHover ? 'pointerleave' : 'mouseleave',
    onLeave
  );

  const startHandler = useEventListener(
    events[0],
    (event: UITouchEvent) => {
      gesture.current = initGesture(coordX(event), coordX(event));

      handle(event, [onStart, onStartX, onStartY]);

      subscribe(touchEnabled() ? (event.target as HTMLElement) : document);
    },
    { capture: useCapture, passive: false }
  );

  const containerRef = useExternRef(getRootRef);

  useLayoutEffect(() => {
    const element = containerRef.current;

    if (element) {
      enterHandler.add(element);
      leaveHandler.add(element);
      startHandler.add(element);
    }
  }, [Component]);

  function onMove(event: UITouchEvent) {
    const {
      isPressed,
      isX,
      isY,
      startX = 0,
      startY = 0
    } = gesture.current ?? {};

    if (isPressed) {
      const shiftX = coordX(event) - startX;
      const shiftY = coordY(event) - startY;

      const shiftXAbs = Math.abs(shiftX);
      const shiftYAbs = Math.abs(shiftY);

      if (event.touches?.length > 1) {
        onEnd(event);
        return;
      }

      if (!isX && !isY && gesture.current) {
        const isWillBeX = shiftXAbs >= slideThreshold && shiftXAbs > shiftYAbs;
        const isWillBeY = shiftYAbs >= slideThreshold && shiftYAbs > shiftXAbs;
        const isWillBeSlidedX =
          isWillBeX && (Boolean(onMoveX) || Boolean(_onMove));
        const isWillBeSlidedY =
          isWillBeY && (Boolean(onMoveY) || Boolean(_onMove));

        Object.assign(gesture.current, {
          isX: isWillBeX,
          isY: isWillBeY,
          isSlideX: isWillBeSlidedX,
          isSlideY: isWillBeSlidedY,
          isSlide: isWillBeSlidedX || isWillBeSlidedY
        });
      }

      if (gesture.current?.isSlide) {
        Object.assign(gesture.current, {
          shiftX,
          shiftY,
          shiftXAbs,
          shiftYAbs
        });

        handle(event, [
          _onMove,
          gesture.current?.isSlideX && onMoveX,
          gesture.current?.isSlideY && onMoveY
        ]);
      }
    }
  }

  function onEnd(event: UITouchEvent) {
    const { isPressed, isSlide, isSlideX, isSlideY } = gesture.current ?? {};

    if (isPressed) {
      handle(event, [_onEnd, isSlideY && onEndY, isSlideX && onEndX]);
    }

    didSlide.current = Boolean(isSlide);
    gesture.current = {};

    if (touchEnabled() && onLeave) {
      onLeave(event);
    }

    unsubscribe();
  }

  const listenerParams = { capture: useCapture, passive: false };
  const listeners = [
    useEventListener(events[1], onMove, listenerParams),
    useEventListener(events[2], onEnd, listenerParams),
    useEventListener(events[3], onEnd, listenerParams)
  ];

  function subscribe(element: HTMLElement | Document | null | undefined) {
    if (element) {
      listeners.forEach((listener) => listener.add(element));
    }
  }

  function unsubscribe() {
    listeners.forEach((listener) => listener.remove());
  }

  const onDragStart = (event: React.DragEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;

    if (target.tagName === 'A' || target.tagName === 'IMG') {
      event.preventDefault();
    }
  };

  const postGestureClick: typeof onClickCapture = (event) => {
    if (!didSlide.current) {
      if (onClickCapture) {
        onClickCapture(event);
      }
      return;
    }

    if ((event.target as HTMLElement).closest('a')) {
      event.preventDefault();
    }

    if (noSlideClick) {
      event.stopPropagation();
    } else if (onClickCapture) {
      onClickCapture(event);
    }

    didSlide.current = false;
  };

  return (
    <Component
      {...restProps}
      onDragStart={onDragStart}
      onClickCapture={postGestureClick}
      ref={containerRef}
    />
  );
}
