import { logUseCase } from '@/utils/logger';
import { SET_MODE } from '../../constants';
import { getChatMode } from '../../getter';
import { setMode, prevMode, currentMode } from '../../data-type/mode';

const acSetMode = (mode) => ({ type: SET_MODE, payload: mode });

const logSetCM = logUseCase.extend('setChatMode');
export const setChatMode = (newMode) => (dispatch, getState) => {
  const mode = getChatMode(getState());
  logSetCM(`call with mode "${currentMode(mode)}"`);
  const updatedMode = setMode(mode, newMode);
  logSetCM(`change mode to "${currentMode(updatedMode)}"`);
  dispatch(acSetMode(updatedMode));
};

const logPrevCM = logUseCase.extend('prevChatMode');
export const prevChatMode = () => (dispatch, getState) => {
  const mode = getChatMode(getState());
  logPrevCM(`call with mode "${currentMode(mode)}"`);
  const updatedMode = prevMode(mode);
  logPrevCM(`return mode to "${currentMode(updatedMode)}"`);
  dispatch(acSetMode(updatedMode));
};
