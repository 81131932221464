import { assocPath } from 'ramda';
import {
  REDUX_KEY,
  SHOW,
  HIDE,
  SET_FILTER_DATA,
  CLEAN_FILTER_DATA,
  IS_SUBSCRIBED
} from './constants';
import { makePurchaseFilter } from './dataType';

export const defaultState = () => ({
  [REDUX_KEY]: makePurchaseFilter()
});

const setIsShow = (state, value) =>
  assocPath([REDUX_KEY, 'isShow'], value, state);
const setFilterData = (state, value) =>
  assocPath([REDUX_KEY, 'filterData'], value, state);
const setIsSubscribed = (state, value) =>
  assocPath([REDUX_KEY, 'isSubscribed'], Boolean(value), state);

export const reducers = {
  [SHOW]: (state) => setIsShow(state, true),
  [HIDE]: (state) => setIsShow(state, false),
  [SET_FILTER_DATA]: (state, payload) => setFilterData(state, payload),
  [CLEAN_FILTER_DATA]: (state) => setFilterData(state, null),
  [IS_SUBSCRIBED]: (state, payload) => setIsSubscribed(state, payload)
};
