import { Map } from 'immutable';

export const initState = () =>
  new Map({
    internet: true,
    server: true
  });

export const changeInternetConnection = (data, value) =>
  data.set('internet', value);
export const changeServerConnection = (data, value) =>
  data.set('server', value);
