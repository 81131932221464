import { assocPath, dissocPath } from 'ramda';
import { REDUX_KEY } from './constants';

export const makeBlockChatInputStorage = ({ inputsList = {} } = {}) => ({
  type: 'BlockChatInputStorage',
  inputsList
});

export const makeChatInputItem = ({ text = '' }) => ({
  text
});

export const setData = (state, chatId, data) =>
  assocPath([REDUX_KEY, 'inputsList', chatId], data, state);
export const removeData = (state, chatId) =>
  dissocPath([REDUX_KEY, 'inputsList', chatId], state);
