import React from 'react';
import Icons from '../constants';
import IconPin from './pin';
import IconAt from './at';
import IconAlarm from './alarm';
import IconAlarmActive from './alarm-active';
import IconDelayedMessage from './delayed-message';
import IconNotificationMute from './notification-mute';
import IconAlarmDone from './alarmDone';
import IconCloseLarge from './close-large';
import IconPen from './pen';
import IconOk from './ok';
import IconPublish from './publish';
import IconTrash from './trash';

export const Icons14x14 = {
  [Icons.Pin]: <IconPin />,
  [Icons.At]: <IconAt />,
  [Icons.Alarm]: <IconAlarm />,
  [Icons.AlarmActive]: <IconAlarmActive />,
  [Icons.DelayedMessage]: <IconDelayedMessage />,
  [Icons.NotificationMute]: <IconNotificationMute />,
  [Icons.AlarmDone]: <IconAlarmDone />,
  [Icons.CloseLarge]: <IconCloseLarge />,
  [Icons.Pen]: <IconPen />,
  [Icons.Ok]: <IconOk />,
  [Icons.Publish]: <IconPublish />,
  [Icons.Trash]: <IconTrash />
};
