.container {
  width: 100%;

  .multiSelect {
    width: 100%;

    &.error {
      :global(.MuiInputBase-root) {
        :global(.MuiOutlinedInput-notchedOutline) {
          border: 1px solid var(--color-red-error);
        }
      }
    }

    :global(.MuiInputBase-root) {
      cursor: pointer;
      width: 100%;
      border-radius: 4px;

      :global(.MuiInputBase-input) {
        display: flex;
        align-items: center;
        padding: var(--indent-xs) 44px var(--indent-xs) var(--indent-m);
        min-height: 20px;
      }

      .inputText {
        color: var(--color-gray-900);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      :global(.MuiSelect-icon) {
        width: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 0;
        top: 0;
        height: 100%;
        border-left: 1px solid var(--color-gray-500);
        padding-right: var(--indent-xxxs);

        &:global(.MuiSelect-iconOpen) {
          transform: rotate(180deg) scale(-1, 1);
        }
      }

      :global(.MuiOutlinedInput-notchedOutline) {
        border: 1px solid var(--color-gray-500);
      }

      &:hover {
        :global(.MuiOutlinedInput-notchedOutline) {
          border-color: var(--color-blue-200);
        }
      }

      &:has(:global(.MuiInputBase-input[aria-expanded='true'])) {
        :global(.MuiOutlinedInput-notchedOutline) {
          border: 1px solid var(--color-blue-400);
          box-shadow: 0 0 5px rgb(35 105 241 / 31%);
        }
      }
    }

    .arrow {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .arrow:hover {
      background-color: transparent;
    }
  }

  .errorMessage {
    margin-top: var(--indent-xxxs);
    color: var(--color-red-error);
  }
}

.menu {
  :global(.MuiMenu-paper) {
    box-shadow: 0 6px 10px 0 rgb(118 123 146 / 20%);

    :global(.MuiList-root) {
      padding: var(--indent-xs);
      display: flex;
      flex-direction: column;
      gap: var(--indent-xxs);

      .menuItem {
        padding: var(--indent-xs);
        display: flex;
        flex-direction: row;
        gap: var(--indent-xs);

        &:hover {
          background-color: var(--color-blue-50);
        }

        &:global(.Mui-focusVisible) {
          background-color: var(--color-white);
        }

        &:global(.Mui-selected) {
          background-color: inherit;

          &:hover {
            background-color: var(--color-blue-50);
          }
        }
      }
    }
  }
}
