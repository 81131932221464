import { ClipboardEventHandler, useEffect, useState } from 'react';
import { MaskColumnDef, RowDef } from '@/components/EditableTable/types';
import {
  BaseCell,
  styles
} from '@/components/EditableTable/components/BaseCell';
import { MaskedInput } from '@/components/MaskedInput';
import { Tooltip } from '@/components/Tooltip';
import { ReadOnlyTextCell } from '@/components/EditableTable/components/ReadOnlyTextCell';
import { isFunction, isString } from '@/lib/utils';

interface CellProps {
  itemIndex: number;
  item: RowDef;
  value: string;
  column: MaskColumnDef;
}

type HandleChange = (value: string) => void;
type HandlePaste = ClipboardEventHandler<HTMLDivElement>;
type HandleFocus = () => void;

export function MaskCell({ itemIndex, item, value, column }: CellProps) {
  const {
    variant = 'body1Reg',
    width,
    colSpan,
    noWrap,
    align,
    mask,
    onPaste,
    onChange,
    style,
    onFocus,
    completed = true,
    scale = 2
  } = column || {};

  const [inputValue, setInputValue] = useState(value);

  const handleFocus: HandleFocus = () =>
    isFunction(onFocus) ? onFocus(item, itemIndex) : null;

  const placeholder = (() => {
    const { placeholder: placeholderProp } = column;

    return isFunction(placeholderProp)
      ? placeholderProp(item, itemIndex)
      : placeholderProp;
  })();

  const endAdornment = (() => {
    const { endAdornment: endAdornmentProp } = column;

    return isFunction(endAdornmentProp)
      ? endAdornmentProp(item, itemIndex)
      : endAdornmentProp;
  })();

  const startAdornment = (() => {
    const { startAdornment: startAdornmentProp } = column;

    return isFunction(startAdornmentProp)
      ? startAdornmentProp(item, itemIndex)
      : startAdornmentProp;
  })();

  const handleChange: HandleChange = (newValue) => setInputValue(newValue);
  const handlePaste: HandlePaste = (event) =>
    isFunction(onPaste) ? onPaste(item, itemIndex)(event) : undefined;

  const handleBlur = () => {
    if (value !== inputValue) {
      onChange(inputValue, item, itemIndex);
    }
  };

  useEffect(() => {
    const convertedInputValue = isString(inputValue)
      ? +inputValue.replace(/\s/g, '').replace(',', '.')
      : inputValue;

    if (Number(value) !== convertedInputValue) {
      setInputValue(value);
    }
  }, [value]);

  const cellClassName = isFunction(column.cellClassName)
    ? column.cellClassName(item, itemIndex)
    : column.cellClassName;
  const className = isFunction(column.className)
    ? column.className(item, itemIndex)
    : column.className;
  const title = isFunction(column.title)
    ? column.title({ row: item, rowIndex: itemIndex })
    : column.title;
  const valid = isFunction(column.valid)
    ? column.valid(item, itemIndex)
    : column.valid;

  const renderEditableCall = () => (
    <BaseCell
      className={cellClassName}
      colSpan={colSpan}
      width={width}
      style={style}
      unstyled>
      <Tooltip title={title} className={styles.container}>
        <MaskedInput
          className={className}
          value={inputValue}
          mask={mask}
          valid={valid}
          onPaste={handlePaste}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          onFocus={handleFocus}
          completed={completed}
          scale={scale}
        />
      </Tooltip>
    </BaseCell>
  );

  const editable = isFunction(column.editable)
    ? column.editable(item, itemIndex)
    : column.editable;

  return editable ? (
    renderEditableCall()
  ) : (
    <ReadOnlyTextCell
      cellClassName={cellClassName}
      colSpan={colSpan}
      width={width}
      value={value}
      variant={variant}
      align={align}
      noWrap={noWrap}
      style={style}
      endAdornment={endAdornment}
      startAdornment={startAdornment}
    />
  );
}
