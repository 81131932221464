import assert from 'assert';
import { getList, hasList } from '../block-list-forward';

export const showPreviewModalCase = ({
  getOpenedChatId,
  getForwardMessages,
  showModal,
  removeForwardMessage
}) => {
  const openedChatId = getOpenedChatId();
  const forwardMessages = getForwardMessages();

  assert(
    hasList(forwardMessages, openedChatId),
    `Not found forward messages for chat with id=${openedChatId}`
  );

  const forwardBlock = getList(forwardMessages, openedChatId);
  const { list } = forwardBlock;

  showModal('MESSAGES_LIST', {
    onRemove,
    list,
    title: 'forwarding_msgs'
  });

  return undefined;

  function onRemove(messageId) {
    removeForwardMessage({ toChatId: openedChatId, messageId });
  }
};
