import { ChannelType } from '../../../types';

export const filterChats = ({ filterBy, chatsList }) => {
  if (filterBy === 'all-chats') return chatsList;
  if (filterBy === 'dialogs')
    return chatsList.filter(({ type }) => type === ChannelType.Dialog);
  if (filterBy === 'channels')
    return chatsList.filter(({ type }) => type === ChannelType.Channel);
  if (filterBy === 'topics')
    return chatsList.filter(({ type }) => type === ChannelType.Topic);
  return chatsList;
};
