// const { mergeRight } = require('ramda');
// const typeOfClass = require('./typeOfClass');
// const defMethod = require('./method');
// const structMethods = require('./struct');
// const recordMethods = require('./record');
// const checkMethods = require('./checkMethods');

import { mergeRight } from 'ramda';
import { typeOfClass } from './typeOfClass';
import { defMethod } from './method';
import * as structMethods from './struct';
import * as recordMethods from './record';
import checkMethods from './checkMethods';

const methods = mergeRight(
  { defMethod, typeOfClass, checkMethods },
  { ...structMethods, ...recordMethods }
);

export const { isType, getType, defRecord, defStruct } = methods;
