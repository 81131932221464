import { Map } from 'immutable';

const setHeight = (state, windowHeight) =>
  state.set('windowHeight', windowHeight);

const brower = (state = new Map(), action) => {
  switch (action.type) {
    case 'WINDOW_RESIZE':
      return setHeight(state, window.innerHeight);
    default:
      return state;
  }
};

export default brower;
