import React from 'react';

/* eslint-disable max-len */
const IconView = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      className="cws-icon-hover"
      cx="17"
      cy="17"
      r="17"
      fill="#767B92"
      fillOpacity="0"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 17.5C9 17.5 10.3333 23.5 17 23.5C23.6667 23.5 25 17.5 25 17.5C25 17.5 23.6667 11.5 17 11.5C10.3333 11.5 9 17.5 9 17.5ZM10.0372 17.5C10.0793 17.6367 10.1445 17.8294 10.2386 18.0602C10.4423 18.5602 10.7753 19.2251 11.2894 19.8861C12.2967 21.1812 14.0212 22.5 17 22.5C19.9788 22.5 21.7033 21.1812 22.7106 19.8861C23.2247 19.2251 23.5577 18.5602 23.7614 18.0602C23.8555 17.8294 23.9207 17.6367 23.9628 17.5C23.9207 17.3633 23.8555 17.1706 23.7614 16.9398C23.5577 16.4398 23.2247 15.7749 22.7106 15.1139C21.7033 13.8188 19.9788 12.5 17 12.5C14.0212 12.5 12.2967 13.8188 11.2894 15.1139C10.7753 15.7749 10.4423 16.4398 10.2386 16.9398C10.1445 17.1706 10.0793 17.3633 10.0372 17.5Z"
      fill="#252B49"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 20.5C18.6569 20.5 20 19.1569 20 17.5C20 15.8431 18.6569 14.5 17 14.5C15.3431 14.5 14 15.8431 14 17.5C14 19.1569 15.3431 20.5 17 20.5ZM17 21.5C19.2091 21.5 21 19.7091 21 17.5C21 15.2909 19.2091 13.5 17 13.5C14.7909 13.5 13 15.2909 13 17.5C13 19.7091 14.7909 21.5 17 21.5Z"
      fill="#252B49"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconView;
