import React from 'react';

/* eslint-disable max-len */
function IconVideo() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.671 5.25477C17.8163 5.37827 17.9 5.55932 17.9 5.74998V13.25C17.9 13.4407 17.8162 13.6217 17.6709 13.7452C17.5256 13.8687 17.3334 13.9222 17.1452 13.8915L11.8952 13.0343C11.5808 12.9829 11.35 12.7113 11.35 12.3928V6.60578C11.35 6.28716 11.5809 6.01551 11.8954 5.96425L17.1454 5.10845C17.3336 5.07777 17.5257 5.13127 17.671 5.25477ZM12.65 7.1584V11.8403L16.6 12.4852V6.51452L12.65 7.1584Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.24998 4.89998C1.78053 4.89998 1.39998 5.28053 1.39998 5.74998V13.25C1.39998 13.7194 1.78053 14.1 2.24998 14.1H10.5C10.9694 14.1 11.35 13.7194 11.35 13.25V5.74998C11.35 5.28053 10.9694 4.89998 10.5 4.89998H2.24998ZM0.0999756 5.74998C0.0999756 4.56256 1.06256 3.59998 2.24998 3.59998H10.5C11.6874 3.59998 12.65 4.56256 12.65 5.74998V13.25C12.65 14.4374 11.6874 15.4 10.5 15.4H2.24998C1.06256 15.4 0.0999756 14.4374 0.0999756 13.25V5.74998Z"
        fill="#5B5F73"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconVideo;
