import { Route, Routes } from 'react-router-dom';
import {
  SurveyCreation,
  SurveyEdit,
  CampaignCreation,
  SurveyView,
  VendorsEvaluationMain,
  Poll,
  CampaignView,
  CampaignResults,
  PollsResults,
  PollResults
} from '@/pages/vendorsEvaluation';
import { ProtectedRoute } from '@/components/PrivateRoute';
import { useSelector } from 'react-redux';
import { getPlainCurrentUser } from '@/storeGetters';
import { usePermissions } from '@/modules/permissions';
import * as storeGetters from '@/storeGetters';

const AuthorizedRoutes = () => {
  const { isCustomer } = useSelector(getPlainCurrentUser);

  const permissions = usePermissions();

  const hasVendorsEvaluationManagementPrivilege =
    permissions.hasVendorsEvaluationManagementPrivilege();

  const hasVendorsEvaluationResultsPrivilege =
    permissions.hasVendorsEvaluationResultsPrivilege();

  return (
    <ProtectedRoute isAllowed={isCustomer} redirectPath={null}>
      <Routes>
        <Route path="/" element={<VendorsEvaluationMain />} />
        <Route
          path="/campaigns/creation"
          element={
            <ProtectedRoute
              isAllowed={hasVendorsEvaluationManagementPrivilege}
              redirectPath={null}>
              <CampaignCreation />
            </ProtectedRoute>
          }
        />
        <Route
          path="/campaigns/:id/view"
          element={
            <ProtectedRoute
              isAllowed={
                hasVendorsEvaluationManagementPrivilege ||
                hasVendorsEvaluationResultsPrivilege
              }
              redirectPath={null}>
              <CampaignView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/campaigns/:id/results"
          element={
            <ProtectedRoute
              isAllowed={hasVendorsEvaluationResultsPrivilege}
              redirectPath={null}>
              <CampaignResults />
            </ProtectedRoute>
          }
        />
        <Route
          path="/campaigns/:id/vendors/:vendorId/results"
          element={
            <ProtectedRoute
              isAllowed={hasVendorsEvaluationResultsPrivilege}
              redirectPath={null}>
              <PollsResults />
            </ProtectedRoute>
          }
        />
        <Route
          path="/surveys/creation"
          element={
            <ProtectedRoute
              isAllowed={hasVendorsEvaluationManagementPrivilege}
              redirectPath={null}>
              <SurveyCreation />
            </ProtectedRoute>
          }
        />
        <Route
          path="/surveys/:id/edit"
          element={
            <ProtectedRoute
              isAllowed={hasVendorsEvaluationManagementPrivilege}
              redirectPath={null}>
              <SurveyEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/surveys/:id/view"
          element={
            <ProtectedRoute
              isAllowed={hasVendorsEvaluationManagementPrivilege}
              redirectPath={null}>
              <SurveyView />
            </ProtectedRoute>
          }
        />
        <Route path="/polls/:pollId/view" element={<Poll />} />
        <Route
          path="/campaigns/:id/vendors/:vendorId/respondents/:respondentId/results"
          element={
            <ProtectedRoute
              isAllowed={
                hasVendorsEvaluationManagementPrivilege ||
                hasVendorsEvaluationResultsPrivilege
              }
              redirectPath={null}>
              <PollResults />
            </ProtectedRoute>
          }
        />
      </Routes>
    </ProtectedRoute>
  );
};

export const VendorsEvaluationRoutes = () => {
  const isAuth = useSelector(storeGetters.isAuth);

  return (
    <Routes>
      <Route path="/polls/:pollId/view" element={<Poll />} />
      <Route
        path="/*"
        element={
          <ProtectedRoute isAllowed={isAuth} redirectPath={null}>
            <AuthorizedRoutes />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
