import { makeBadge } from '../dataType';

export const subscribeToUpdateBadgesCase = ({
  subscribeToUpdateBadges,
  addOrUpdateBadge,
  getClientData
}) => {
  subscribeToUpdateBadges((badgeInfo) => {
    const clientData = getClientData();

    addOrUpdateBadge(setBadge(badgeInfo, clientData));
  });
};

function setBadge(badgeInfo, clientData) {
  if (clientData.chatId !== badgeInfo.chatId) return makeBadge(badgeInfo);

  const { isScrollBottom = false, isActiveTab = false } = clientData;
  if (isScrollBottom && isActiveTab)
    return makeBadge({ chatId: badgeInfo.chatId });

  return makeBadge(badgeInfo);
}
