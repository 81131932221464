import { getDialogInfo, getIsShown } from '@/components/ChatWidget/getter';
import {
  getAllBadges,
  subscribeToUpdateBadges,
  unsubscribeFromUpdateBadges
} from '@/components/ChatWidget/modules/badges';
import { ac } from '@/components/ChatWidget/useCases/shared/actions';
import { req } from '@/components/ChatWidget/useCases/shared/requests';

const updateDialogInfo = () => async (dispatch, getState) => {
  const { employeeId } = getDialogInfo(getState());
  if (employeeId) {
    const info = await req.getContactInfo(employeeId);
    dispatch(ac.setDialogInfo(info));
  }
};

export const toggleChatWidget = () => (dispatch, getState) => {
  const isShownCW = getIsShown(getState());
  if (isShownCW) {
    dispatch(unsubscribeFromUpdateBadges());
    dispatch(ac.hideChatWidget());
  } else {
    dispatch(updateDialogInfo());
    dispatch(getAllBadges());
    dispatch(subscribeToUpdateBadges());
    dispatch(ac.showChatWidget());
  }
};
