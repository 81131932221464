import { useCallback, useRef, MutableRefObject } from 'react';

export function useDebounce<T>(
  callback: (data: T) => void,
  delay: number = 500
) {
  const timer: MutableRefObject<number | null> = useRef(null);

  return useCallback(
    (...args: T[]) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = window.setTimeout(() => {
        callback(...(args as unknown as [T]));
      }, delay);
    },
    [callback, delay]
  );
}
