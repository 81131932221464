export const updateInternetConnection = (value) => (dispatch) =>
  dispatch({
    type: 'INTERNET_CONNECTION',
    payload: value
  });

export const updateServerConnection = (value) => (dispatch) =>
  dispatch({
    type: 'SERVER_CONNECTION',
    payload: value
  });
