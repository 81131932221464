import { useCallback, useState, FocusEvent } from 'react';

export function useFocusVisible() {
  const [isFocused, setIsFocused] = useState(false);

  const onFocus = useCallback((event: FocusEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsFocused(true);
  }, []);

  const onBlur = useCallback((event: FocusEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsFocused(false);
  }, []);

  return {
    isFocused,
    onFocus,
    onBlur
  };
}
