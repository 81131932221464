import React, { useCallback, useEffect } from 'react';
import { useModal } from '@hooks';
import { useQueue } from './useQueue';
import { useVideoChatContext } from './useVideoChatContext';
import * as ModalConfirmMember from '../../../components/Modal/videoChat/ModalConfirmMember';

export function useConfirmForJoined() {
  const { onOpen: showModal } = useModal();

  const context = useVideoChatContext();
  const incomingRequests = useQueue();

  const receivedNewIncomingRequest = useCallback(
    ({ roomId, targetEmployee }) => {
      if (hasSameRequests()) return;

      incomingRequests.add({ ...targetEmployee, roomId });

      function hasSameRequests() {
        return incomingRequests.all.some(
          (r) => r.employeeId === targetEmployee.employeeId
        );
      }
    },
    [incomingRequests.size]
  );

  function showConfirmModal(roomId, employee) {
    showModal(ModalConfirmMember.key, {
      employee,
      incomingRequests
    });
  }

  useEffect(() => {
    if (incomingRequests.size) {
      if (incomingRequests.first.roomId === context.roomId) {
        showConfirmModal(context.roomId, incomingRequests.first);
      } else {
        incomingRequests.remove();
      }
    }
  }, [incomingRequests.size, context.roomId]);

  return receivedNewIncomingRequest;
}
