import React from 'react';
import MutedIcon from '@/img/videochat/muted.svg';
import UnmutedIcon from '@/img/videochat/unmuted.svg';

export function Mic({ micMuted }) {
  return (
    <img
      src={micMuted ? MutedIcon : UnmutedIcon}
      className="video-chat__mic"
      alt=""
    />
  );
}
