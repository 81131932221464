import { makeEvent, makeCommand } from '@/core/data/messageBus/message';
import { makeQuery } from '@/core/messages/utils';

const NS = 'VIDEO_ROOM';

export const createdRoom = makeEvent(NS, 'ROOM_WAS_CREATED');

export const allowJoinCMD = makeCommand(NS, 'ALLOW_JOIN');
export const allowedJoinEVT = makeEvent(NS, 'ALLOWED_JOIN');

export const joinedToRoom = makeEvent(NS, 'JOINED_TO_ROOM');

export const leaveRoom = makeCommand(NS, 'LEAVE_ROOM');
export const leftRoom = makeEvent(NS, 'LEFT_ROOM');

export const joinRequestCMD = makeCommand(NS, 'REQUEST_JOIN_TO_ROOM');

export const rejectJoinRequest = makeCommand(NS, 'REJECT_REQUEST_JOIN_TO_ROOM');
export const rejectedJoinRequest = makeEvent(
  NS,
  'REJECTED_REQUEST_JOIN_TO_ROOM'
);

export const viewCMD = makeCommand(NS, 'view');
export const videoCallQRY = makeQuery(NS, 'video-call');
