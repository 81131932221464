import req from '../../../lib/request';
import { videoRoomWasCreatedEvt } from '../commandsAndEvents';

const generateVideoRoom = async (chatId, peerId) =>
  req.post(`/api/video-room/chat/${chatId}/new`, { peerId });

export const createVideoRoom = async ({ chatId }) => {
  const { roomId } = await generateVideoRoom(chatId);
  videoRoomWasCreatedEvt.emit(roomId);
};
