import React from 'react';

/* eslint-disable max-len */
function IconNotificationMute() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.73934 3.03764C7.0947 2.76439 7.49256 2.54317 7.93478 2.37764C8.12419 1.58528 8.78995 1 9.58217 1C10.3744 1 11.0401 1.58526 11.2296 2.3776C11.6718 2.54313 12.0697 2.76437 12.4251 3.03764C13.2781 3.69354 13.8202 4.59837 14.1685 5.58196C14.8405 7.47966 14.8497 9.86118 14.8499 11.8286L15.0025 12.1661L16.8672 14.0307C17.1601 14.3236 17.1601 14.7985 16.8672 15.0914C16.5743 15.3843 16.0994 15.3843 15.8065 15.0914L3.21967 2.50454C2.92678 2.21165 2.92678 1.73678 3.21967 1.44388C3.51256 1.15099 3.98744 1.15099 4.28033 1.44388L6.2799 3.44345C6.42205 3.29975 6.57492 3.16407 6.73934 3.03764ZM7.34066 4.50421L13.3409 10.5044C13.3132 8.91145 13.2017 7.34546 12.7546 6.08267C12.4712 5.28245 12.0691 4.65609 11.5107 4.22675C11.0474 3.87044 10.4299 3.61393 9.58222 3.54103C8.73458 3.61393 8.11706 3.87044 7.65369 4.22675C7.54334 4.3116 7.43909 4.40415 7.34066 4.50421Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2908 13.606L13.7908 15.106H12.1197C12.1016 16.4395 10.9725 17.515 9.58219 17.515C8.19193 17.515 7.06277 16.4395 7.04463 15.106H4.45953C3.69674 15.106 3.18849 14.3184 3.50283 13.6233L4.31451 11.8286C4.31468 10.0486 4.32223 7.92956 4.82137 6.13659L6.07368 7.3889C5.82309 8.78302 5.81456 10.3808 5.81451 11.9261C5.8145 12.0764 5.78225 12.2234 5.72121 12.3584L5.15694 13.606H12.2908ZM8.54508 15.106C8.56362 15.5613 8.96855 16.015 9.58219 16.015C10.1958 16.015 10.6008 15.5613 10.6193 15.106H8.54508Z"
        fill="#5B5F73"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconNotificationMute;
