import cn from 'classnames';
import { SortAscIcon, SortDescIcon } from '@/components/EditableTable/assets';
import { useEditableTableContext } from '@/components/EditableTable/EditableTable.context';
import { SortDirections } from '@/components/EditableTable/types';
import { WithClassName } from '@/components/common.types';
import styles from './SortController.module.scss';

interface SortControllerProps extends WithClassName {
  accessor: string;
}

export function SortController({ className, accessor }: SortControllerProps) {
  const { sort, toggleSortRows } = useEditableTableContext();

  function isActive(direction: SortDirections) {
    return sort[accessor] === direction;
  }

  const isAscActive = isActive(SortDirections.Asc);
  const isDescActive = isActive(SortDirections.Desc);
  const isSortActive = isAscActive || isDescActive;

  return (
    <div
      className={cn(className, {
        [styles.container]: true,
        [styles.visible]: isSortActive
      })}>
      <div
        role="presentation"
        className={cn({
          [styles.icon]: true,
          [styles.active]: isAscActive
        })}
        onClick={() => toggleSortRows(accessor, SortDirections.Asc)}>
        <SortAscIcon width={8} height={5} />
      </div>
      <div
        role="presentation"
        className={cn({
          [styles.icon]: true,
          [styles.active]: isDescActive
        })}
        onClick={() => toggleSortRows(accessor, SortDirections.Desc)}>
        <SortDescIcon width={8} height={5} />
      </div>
    </div>
  );
}
