import React from 'react';

/* eslint-disable max-len */
const IconDownload1 = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      className="cws-icon-hover"
      cx="17"
      cy="17"
      r="17"
      fill="#767B92"
      fillOpacity="0"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 26H10V25H24V26Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 21L16.5 9L17.5 9L17.5 21H16.5Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0711 21.0086L12.5668 16.5043L11.8597 17.2114L17.0711 22.4228L22.2825 17.2114L21.5754 16.5043L17.0711 21.0086Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconDownload1;
