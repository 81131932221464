import React from 'react';

/* eslint-disable max-len */
const IconForward = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.72037 5.76513L8.61616 14.0181L14.0179 8.61638L5.76491 2.72059L2.72037 5.76513ZM5.65685 1.41444L1.41421 5.65708L8.48861 15.5599L15.5597 8.48883L5.65685 1.41444Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.06063 6.71781C0.865369 6.52255 0.865369 6.20597 1.06063 6.0107L6.06063 1.0107C6.25589 0.815442 6.57248 0.815442 6.76774 1.0107C6.963 1.20597 6.963 1.52255 6.76774 1.71781L1.76774 6.71781C1.57248 6.91307 1.25589 6.91307 1.06063 6.71781Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.42464 15.9101C7.22938 15.7149 7.22938 15.3983 7.42464 15.203L15.2028 7.42484C15.3981 7.22958 15.7147 7.22958 15.9099 7.42484C16.1052 7.6201 16.1052 7.93668 15.9099 8.13194L8.13175 15.9101C7.93649 16.1054 7.61991 16.1054 7.42464 15.9101Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6673 17.3745L11.6673 12.3745L12.3744 11.6674L17.3744 16.6674L16.6673 17.3745Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconForward;
