import { assoc } from 'ramda';
import { makeQuery } from './query';

/**
 * @typedef {import('./query')} Query
 */

/**
 * @typedef {{string: Query}} QueryList
 */

/**
 * Creates a query list data type
 *
 * @param {[string]} names
 * @returns {Query}
 */
const makeQueryList = (names) =>
  names.reduce((list, name) => assoc(name, makeQuery(), list), {
    type: 'QueryList'
  });

/**
 * Finds a query in list
 *
 * @param {string} name
 * @param {QueryList} queryList
 * @returns {(Query|null)}
 */
const findQuery = (name, queryList) => {
  const query = queryList[name];
  return !query ? null : query;
};

/**
 * Sets a query in list
 *
 * @param {string} name
 * @param {Query} query
 * @param {QueryList} queryList
 * @returns {(Query|null)}
 */
const setQuery = (name, query, queryList) => {
  const foundQuery = findQuery(name, queryList);
  if (foundQuery) {
    return assoc(name, query, queryList);
  }
  return queryList;
};

export { makeQueryList, findQuery, setQuery };
