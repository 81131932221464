import { pipe, isEmpty } from 'ramda';
import request from '../../../../../lib/request';
import { extractFirstUnreadMsgFromChannel } from '../transformData';

const getChats = async ({
  page = 0,
  limit = 20,
  lang = 'ru',
  isArchive = false
} = {}) => {
  const system = ['inactive'];

  if (isArchive) system.unshift('archive');

  return request.post('/api/dialogs', {
    mode: 'all',
    system: ['inactive'],
    search: '',
    page,
    limit,
    settingsOff: false,
    lang
  });
};

const iterativeLoadChats = async (
  addMethod,
  addLastUnreadMsgs,
  setLoading,
  { limit = 30, page = 0 } = {}
) => {
  try {
    setLoading(true);
    const chats = await getChats({ page, limit });
    setLoading(false);
    addMethod(chats);

    const lastUnreadMsgs = chats.dialogs
      .filter((chat) => chat.firstUnreadMessageId)
      .map(extractFirstUnreadMsgFromChannel);

    if (!isEmpty(lastUnreadMsgs)) {
      addLastUnreadMsgs(lastUnreadMsgs);
    }

    if (canLoadMore({ nextPage: page + 1, total: chats.total })) {
      iterativeLoadChats(addMethod, addLastUnreadMsgs, setLoading, {
        limit,
        page: page + 1
      });
    }
  } catch (err) {
    setLoading(false);
  }

  function canLoadMore({ nextPage, total }) {
    return nextPage * limit < total;
  }
};

export const uploadChatsService = async ({
  addChats,
  addLastUnreadMsgs,
  getChatGroups,
  transformToChatModels,
  setLoading
}) => {
  await Promise.all([
    getChatGroups(),
    iterativeLoadChats(
      pipe(transformToChatModels, addChats),
      addLastUnreadMsgs,
      setLoading
    )
  ]);
};
