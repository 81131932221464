import React from 'react';

/* eslint-disable max-len */
const IconHide = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="cws-icon-main-fill"
      d="M9.02691 3C7.90371 3 6.82753 3.25457 5.82846 3.6804L6.59012 4.44205C7.3619 4.16246 8.18008 3.99997 9.02691 3.99997C12.1196 3.99997 14.8994 6.00238 15.9966 8.99991C15.5785 10.1422 14.9136 11.1369 14.0825 11.9344L14.7857 12.6376C15.7616 11.6967 16.5359 10.5196 17 9.16153C17.0357 9.05655 17.0357 8.94327 17 8.83829C15.8066 5.34616 12.6025 3 9.02691 3Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      d="M10.9784 8.86834L11.8802 9.77013C11.9458 9.5238 11.992 9.26984 11.992 9.00304C11.992 7.34877 10.6464 6.00307 8.99206 6.00307C8.72527 6.00307 8.47131 6.04929 8.22498 6.1149L9.12677 7.01669C10.1206 7.0843 10.9108 7.87446 10.9784 8.86834Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      d="M1.99988 2.70705L4.02732 4.73448C2.68455 5.74998 1.60657 7.14155 1.02674 8.83832C0.991088 8.9433 0.991088 9.05658 1.02674 9.16155C2.22008 12.6537 5.42414 14.9999 8.99979 14.9999C10.4862 14.9999 11.89 14.5631 13.1285 13.8356L15.2927 15.9999L15.9997 15.2929L2.70687 2L1.99988 2.70705ZM8.99982 13.9999C5.9071 13.9999 3.12733 11.9975 2.03016 8.99997C2.5713 7.52139 3.54375 6.31873 4.74101 5.44818L6.55977 7.26694C6.21024 7.75747 5.99985 8.3531 5.99985 8.99994C5.99985 10.6542 7.34552 11.9999 8.99982 11.9999C9.64666 11.9999 10.2423 11.7895 10.7328 11.44L12.3967 13.1039C11.364 13.6635 10.2138 13.9999 8.99982 13.9999ZM7.28316 7.99036L10.0095 10.7167C9.71198 10.8923 9.36965 11 8.99982 11C7.89679 11 6.99985 10.103 6.99985 9C6.99985 8.63017 7.1075 8.28784 7.28316 7.99036Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconHide;
