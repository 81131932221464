import React from 'react';

/* eslint-disable max-len */
function IconStylePunkt1() {
  return (
    <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.035 3.641c-.171.08-.379.291-.453.459-.078.178-.071.499.016.693a.959.959 0 0 0 .517.474c.2.066.501.047.679-.044a.881.881 0 0 0 .427-1.089.806.806 0 0 0-.426-.472.886.886 0 0 0-.76-.021m2.843.2a.686.686 0 0 0 .027 1.211l.157.078h13.049l.129-.063c.528-.255.525-1.02-.005-1.261-.115-.052-.398-.055-6.66-.055h-6.54l-.157.09m-2.771 4.33A.881.881 0 0 0 .774 9.61c.168.174.315.238.584.254.216.013.247.007.426-.081a.802.802 0 0 0 .437-.477.907.907 0 0 0-.151-.881 1.017 1.017 0 0 0-.275-.203.68.68 0 0 0-.382-.091 1.196 1.196 0 0 0-.306.04m2.796.218a.683.683 0 0 0 0 1.222l.159.079 6.526-.001c6.249 0 6.532-.003 6.647-.055.066-.03.17-.11.231-.177a.69.69 0 0 0-.227-1.085l-.128-.062H4.062l-.159.079m-2.815 4.349a1.009 1.009 0 0 0-.49.469.76.76 0 0 0-.069.368c.003.256.059.392.239.585.525.561 1.458.22 1.506-.55a.885.885 0 0 0-.48-.834c-.168-.086-.524-.105-.706-.038m2.857.191a.684.684 0 0 0-.404.491c-.067.3.09.622.373.768l.149.077h6.496c6.187 0 6.503-.003 6.636-.054a.719.719 0 0 0 .445-.648c0-.275-.225-.593-.469-.66-.081-.023-2.06-.033-6.611-.032-6.333 0-6.498.002-6.615.058"
        fill="#5B5F73"
        fillRule="evenodd"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconStylePunkt1;
