import request from '../../../../lib/request';
import { escapeText } from '../../../../utils/utils';

export const attachUnsentFileFromStorage = (channelId, filesId) =>
  request.post(`/api/channel/${channelId}/unsent-files`, { filesId });

export const attachUnsentFile = (channelId, fileName, file) =>
  request.sendFile(
    `/api/channel/${channelId}/unsent-files/${escapeText(fileName)}`,
    file
  );

export const getUnsentFiles = (chatId) =>
  request.get(`/api/channel/${chatId}/unsent-files`);

export const removeUnsentFile = (chatId, fileId) =>
  request.delete(`/api/channel/${chatId}/unsent-files/${fileId}`);
