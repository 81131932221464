import { makeQuery } from 'core/messages/utils';
import { makeEvent, makeCommand } from 'core/data/messageBus/message';

const NS = 'contact';

export const toggleBlockCMD = makeCommand(NS, 'toggleBlock');
export const blockedEVT = makeEvent(NS, 'blocked');
export const unblockedEVT = makeEvent(NS, 'unblocked');
export const blockedMeEVT = makeEvent(NS, 'blockedMe');
export const unblockedMeEVT = makeEvent(NS, 'unblockedMe');

const roomName = ({ whoEmployeeId, targetEmployeeId }) =>
  `profile-view.${whoEmployeeId}.${targetEmployeeId}`;
export const profileQRY = makeQuery(NS, 'profile', roomName);

export const notPossibleEVT = makeEvent(
  NS,
  'error:not-possible-to-service-account'
);

export const createProfileCMD = makeCommand(NS, 'createProfile');
export const errorTriedCreateExistingProfileEVT = makeEvent(
  NS,
  'error:tried-to-create-an-existing-profile'
);
export const profileWasCreatedEVT = makeEvent(NS, 'profileWasCreated');
