export const AllPurchases = {
  name: 'Purchases_l',
  link: '/',
  isTranslated: false
};

export const MyOffice = {
  name: 'My_office',
  link: '/my-office',
  isTranslated: false
};

export const MyRequests = {
  name: 'Request_plural',
  link: '/requests/my',
  isTranslated: false
};

export const MyResponses = {
  name: 'Responses',
  link: '/requests/my-responses',
  isTranslated: false
};

export const MyCompany = {
  name: 'My company',
  link: '/company/edit',
  isTranslated: false
};

export const Administration = {
  name: 'modules.administration.title',
  link: '/administration',
  isTranslated: false
};

export const Settings = {
  name: 'settings.title',
  link: '/settings',
  isTranslated: false
};

export const Companies = {
  name: 'modules.companies.title',
  link: '/companies',
  isTranslated: false
};
