import React from 'react';

const IconCalendar2 = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      className="cws-icon-hover"
      cx="17"
      cy="17"
      r="17"
      fill="#767B92"
      fillOpacity="0"
    />
    <rect
      className="cws-icon-main-stroke"
      x="9.5"
      y="9.5"
      width="15"
      height="15"
      rx="1.5"
      stroke="#767B92"
    />
    <line
      className="cws-icon-main-stroke"
      x1="10"
      y1="13.5"
      x2="24"
      y2="13.5"
      stroke="#767B92"
    />
    <line
      className="cws-icon-main-stroke"
      x1="14.5"
      y1="8"
      x2="14.5"
      y2="11"
      stroke="#767B92"
    />
    <line
      className="cws-icon-main-stroke"
      x1="19.5"
      y1="8"
      x2="19.5"
      y2="11"
      stroke="#767B92"
    />
  </svg>
);

export default IconCalendar2;
