import { PREFIX } from '../constants';

export const REDUX_KEY = 'companies';
export const SET_COMPANIES_LIST = `${PREFIX}:SET_COMPANIES_LIST`;
export const ADD_CHUNK_TO_COMPANIES_LIST = `${PREFIX}:ADD_CHUNK_TO_COMPANIES_LIST`;
export const CLEAR_COMPANIES_LIST = `${PREFIX}:CLEAR_COMPANIES_LIST`;
export const SET_OPENED_COMPANY_PROFILE = `${PREFIX}:SET_OPENED_COMPANY_PROFILE`;
export const CLEAR_OPENED_COMPANY_PROFILE = `${PREFIX}:CLEAR_OPENED_COMPANY_PROFILE`;
export const UPDATE_COMPANY_IN_LIST = `${PREFIX}:UPDATE_COMPANY_IN_LIST`;

export const fetchCompaniesModes = {
  general: 'load-general',
  chunk: 'load-chunk'
};
