export const STEP_TYPES = {
  agreement: 'agreement',
  introduction: 'introduction',
  publication: 'publication',
  begin: 'begin',
  end: 'end'
};

export const serviceCardIds = {
  [STEP_TYPES.begin]: 'begin-step-card',
  [STEP_TYPES.end]: 'end-step-card'
};

export const createStepBtn = 'create-step-button';

export const serviceTypes = [STEP_TYPES.begin, STEP_TYPES.end];

export const MAX_STEPS_IN_PATH = 15;

export const ACCEPTABLE_AGREEMENT_CARDS = 15;

export const THRESHOLD_FOR_PARALLEL_STEP = 1;

export const MAX_USERS_IN_INTRODUCTION_CARD = 10;

export const routeType = {
  request: 'request',
  response: 'response'
};
