export function createAudioStreamByStream(stream) {
  if (!stream?.getAudioTracks()?.length) {
    return null;
  }

  const audioTrack = stream.getAudioTracks()[0].clone();

  return new MediaStream([audioTrack]);
}

export function createMediaStreamFake() {
  return new MediaStream([createEmptyAudioTrack(), createEmptyVideoTrack()]);
}

function createEmptyAudioTrack() {
  const ctx = new AudioContext();
  const oscillator = ctx.createOscillator();
  const dst = oscillator.connect(ctx.createMediaStreamDestination());
  oscillator.start();
  const track = dst.stream.getAudioTracks()[0];
  return Object.assign(track, { enabled: false });
}

function createEmptyVideoTrack({ width = 480, height = 270 } = {}) {
  const canvas = Object.assign(
    document.createElement('canvas', { width, height })
  );
  canvas
    .getContext('2d', { willReadFrequently: true })
    .fillRect(0, 0, width, height);

  const stream = canvas.captureStream();
  const track = stream.getVideoTracks()[0];

  return Object.assign(track, { enabled: false });
}

export function hasStreamAudioTrack(stream) {
  if (!stream) return false;

  const audioTracks =
    stream && stream.getAudioTracks && stream.getAudioTracks();

  return audioTracks && audioTracks[0];
}

export function hasStreamVideoTrack(stream) {
  if (!stream) return false;

  const videoTracks =
    stream && stream.getVideoTracks && stream.getVideoTracks();

  return videoTracks && videoTracks[0];
}

export function stopStream(stream) {
  stream.getTracks().forEach((track) => track.stop());
}

export function isShowAvatar(stream) {
  if (!hasStreamVideoTrack(stream)) return true;

  const currentRS = stream.getVideoTracks()[0].readyState;
  return currentRS === 'ended';
}

export function forEachVideoTrackReceivers(call, cb) {
  call.peerConnection
    .getReceivers()
    .filter((r) => r.track.kind === 'video')
    .forEach(cb);
}

export function getVideoResolution(progressiveScan) {
  switch (progressiveScan) {
    case 480:
      return {
        width: { ideal: 480, max: 480 },
        height: { ideal: 270, max: 270 }
      };
    default:
      return {
        width: true,
        height: true
      };
  }
}

export function isVideoTrackMuted(stream) {
  if (!stream?.getVideoTracks()[0]) {
    return true;
  }

  const videoTrack = stream.getVideoTracks()[0];

  return (
    videoTrack.muted || !videoTrack.enabled || videoTrack.readyState === 'ended'
  );
}

export function isAudioTrackMuted(stream) {
  if (!stream?.getAudioTracks()[0]) {
    return true;
  }

  const audioTrack = stream.getAudioTracks()[0];

  return audioTrack.muted || !audioTrack.enabled;
}
