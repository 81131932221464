import { assoc } from 'ramda';
import {
  ADD_OR_UPDATE_BADGE,
  CLEAR_BADGE,
  SET_BADGES,
  SET_UNREAD_MSG_COUNT
} from './constants';
import {
  addOrUpdateBadge,
  clearBadgeByChatId,
  makeBlockBadges
} from '../../data-type/block-bages';

export const defaultState = () => ({
  unreadMsgsCount: { chats: 0, archives: 0 },
  blockBadges: makeBlockBadges()
});

const setUnreadMsgsCount = (chatWidget, data) =>
  assoc('unreadMsgsCount', data, chatWidget);
const setBadges = (chatWidget, badges) => {
  const blockBadges = badges ?? makeBlockBadges();
  return assoc('blockBadges', blockBadges, chatWidget);
};

export const reducers = {
  [SET_UNREAD_MSG_COUNT]: (chatWidget, data) =>
    setUnreadMsgsCount(chatWidget, data),

  [SET_BADGES]: (chatWidget, badges) => setBadges(chatWidget, badges),
  [ADD_OR_UPDATE_BADGE]: (chatWidget, badge) =>
    setBadges(chatWidget, addOrUpdateBadge(chatWidget.blockBadges, badge)),
  [CLEAR_BADGE]: (chatWidget, chatId) =>
    setBadges(chatWidget, clearBadgeByChatId(chatWidget.blockBadges, chatId))
};
