import { PropsWithChildren, isValidElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import type { Nullable } from '@/types';
import { useConfig } from '@/ConfigProvider';

interface ProtectedRouteProps extends PropsWithChildren {
  isAllowed?: boolean;
  redirectPath?: Nullable<string>;
}

export const ProtectedRoute = (props: ProtectedRouteProps): JSX.Element => {
  const {
    isAllowed = false,
    redirectPath: redirectPathProp = null,
    children
  } = props;

  // @ts-ignore
  const [config] = useConfig();

  const redirectPath = redirectPathProp ?? (config as any).noAccessPath;

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  if (!isValidElement(children)) {
    throw new Error(`Only jsx element supported!`);
  }

  return children ?? <Outlet />;
};
