// create func pasteImageReader for jQuery and fix clipboardData files copy

/* eslint-disable */
module.exports = ($) => {
  $.event.fix = (function (originalFix) {
    return function (event) {
      event = originalFix.apply(this, arguments);
      if (
        event.type.indexOf('copy') === 0 ||
        event.type.indexOf('paste') === 0
      ) {
        event.clipboardData = event.originalEvent.clipboardData;
      }
      return event;
    };
  })($.event.fix);
  const defaults = {
    callback: $.noop,
    matchType: /image.*/
  };
  return ($.fn.pasteImageReader = function (options) {
    if (typeof options === 'function') {
      options = {
        callback: options
      };
    }
    options = $.extend({}, defaults, options);
    return this.each(function () {
      const element = this;
      const $this = $(this);
      return $this.bind('paste', function (event) {
        let found = false;
        const clipboardData = event.clipboardData;
        return Array.prototype.forEach.call(
          clipboardData.types,
          function (type, i) {
            if (found) {
              return;
            }
            if (
              type.match(options.matchType) ||
              clipboardData.items[i].type.match(options.matchType)
            ) {
              const file = clipboardData.items[i].getAsFile();
              const reader = new FileReader();
              reader.onload = function (evt) {
                return options.callback.call(element, {
                  dataURL: evt.target.result,
                  event: evt,
                  file: file,
                  name: file.name
                });
              };
              reader.readAsDataURL(file);
              return (found = true);
            }
          }
        );
      });
    });
  });
};
/* eslint-enable */
