/* eslint-disable react/no-array-index-key */
import { useMemo, useState, useEffect } from 'react';
import {
  Menu as MUIMenu,
  MenuItem as MUIMenuItem,
  MenuItemClasses as MUIMenuItemClasses,
  MenuClasses as MUIMenuClasses,
  Popper,
  Grow,
  Paper,
  MenuList,
  ClickAwayListener,
  SwipeableDrawer,
  useMediaQuery,
  useTheme
} from '@mui/material';
import cn from 'classnames';
import { noop } from '@/lib/utils';
import { Icon } from '../Icon';
import { ButtonIcon } from '../ButtonIcon';
import { Typography } from '../Typography';
import {
  MenuProps,
  MenuItemProps,
  SubMenuProps,
  SwipableListProps
} from './Menu.types';
import styles from './Menu.module.scss';

function Delimiter() {
  return <div className={styles.delimiter} />;
}

const drawerClasses = {
  paper: styles.drawer
};

function SwipableList(props: SwipableListProps) {
  const { children, open, onClose, className, menuName } = props;
  return (
    <SwipeableDrawer
      data-testid="modal"
      open={open}
      onClose={onClose}
      onOpen={noop}
      anchor="bottom"
      swipeAreaWidth={56}
      disableEscapeKeyDown={false}
      classes={drawerClasses}>
      <div className={cn(styles.drawerContainer, className)}>
        <div className={styles.swipeableArea}>
          <div className={styles.puller} />
        </div>
        <div className={styles.drawerContent}>
          <div className={styles.drawerHeader}>
            <Typography variant="h2">{menuName}</Typography>
            <ButtonIcon
              iconName="close"
              onClick={onClose}
              className={styles.closeBtn}
            />
          </div>
          {children}
        </div>
      </div>
    </SwipeableDrawer>
  );
}

export function Menu({
  children,
  anchorEl,
  open,
  onClose,
  alignment = 'right',
  groups = [],
  className,
  onMouseOver,
  onMouseLeave,
  adaptive,
  menuName
}: MenuProps) {
  const MenuClasses: Partial<MUIMenuClasses> = useMemo(
    () => ({
      paper: styles.menu,
      list: cn(styles.list, className)
    }),
    [className]
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  return adaptive && isMobile ? (
    <SwipableList menuName={menuName} open={open} onClose={onClose}>
      {children}
    </SwipableList>
  ) : (
    <MUIMenu
      elevation={1}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: `${alignment}`
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: `${alignment}`
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{ onMouseOver, onMouseLeave }}
      classes={MenuClasses}>
      {children ?? null}
      {groups.length !== 0
        ? groups.map((group, index) => [
            index < 1 || groups.length === 1 || group.length === 0 ? null : (
              <Delimiter key={`group${index}`} />
            ),
            group.length === 0
              ? null
              : group.map((item, itemIndex) => (
                  <div
                    key={`group${index}item${itemIndex}`}
                    className={styles.groupItem}>
                    {item}
                  </div>
                ))
          ])
        : null}
    </MUIMenu>
  );
}

function MenuItem({ children, onClick, disabled, className }: MenuItemProps) {
  const MenuItemClasses: Partial<MUIMenuItemClasses> = useMemo(
    () => ({
      root: cn(styles.item, className),
      disabled: cn(styles.disabled, className)
    }),
    [className]
  );
  return (
    <MUIMenuItem
      onClick={onClick}
      disableRipple
      classes={MenuItemClasses}
      disabled={disabled}>
      {children}
    </MUIMenuItem>
  );
}

function SubMenu({
  children,
  disabled,
  className,
  rootNode,
  alignment = 'left',
  menuActive
}: SubMenuProps) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [subMenuRootActive, setSubMenuRootActive] = useState(false);
  const [subMenuActive, setSubMenuActive] = useState(false);

  const MenuItemClasses: Partial<MUIMenuItemClasses> = useMemo(
    () => ({
      root: cn(styles.item, open && styles.active, className),
      disabled: cn(styles.disabled, className)
    }),
    [className, open]
  );

  useEffect(() => {
    if (menuActive && !subMenuRootActive && !subMenuActive) {
      setOpen(false);
    }
  }, [menuActive, subMenuRootActive, subMenuActive]);

  const handleRootOver = () => {
    setOpen(true);
    setSubMenuRootActive(true);
  };

  const handleRootLeave = () => {
    setSubMenuRootActive(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubMenuOver = () => {
    setSubMenuActive(true);
  };

  const handleSubMenuLeave = () => {
    setSubMenuActive(false);
  };

  return (
    <div ref={(node) => setAnchorEl(node)}>
      <MUIMenuItem
        onMouseOver={handleRootOver}
        onMouseLeave={handleRootLeave}
        disableRipple
        classes={MenuItemClasses}
        disabled={disabled}>
        <div className={cn(styles.subMenuItem)}>
          {rootNode}
          <Icon iconName="arrow-right-01" width={18} height={18} />
        </div>
      </MUIMenuItem>
      <Popper
        style={{ zIndex: '1500' }}
        open={open}
        anchorEl={anchorEl}
        placement={alignment === 'left' ? 'left-start' : 'right-start'}
        transition
        onMouseOver={handleSubMenuOver}
        onMouseLeave={handleSubMenuLeave}>
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                alignment === 'left' ? 'left bottom' : 'right bottom',
              margin: '0 8px'
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList classes={{ root: styles.list }}>{children}</MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

Menu.Item = MenuItem;
Menu.SubMenu = SubMenu;
