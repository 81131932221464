import React from 'react';

/* eslint-disable max-len */
const IconDownUpCircle = ({ width = 28, height = 28 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="14" cy="14" r="13.5" stroke="#EBEEF4" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 17L19.3536 11.6464L18.6465 10.9393L14 15.5858L9.35352 10.9393L8.64641 11.6464L14 17Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconDownUpCircle;
