import React from 'react';

/* eslint-disable max-len */
function IconTopic() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.67651 10.8761V3.66287C8.67651 3.51917 8.77873 3.39644 8.91844 3.3628C10.3134 3.02694 11.4503 2.19401 12.8166 1.77938C13.7304 1.50205 14.2406 1.99532 14.2406 2.86145C14.2406 5.08229 14.2257 10.647 14.2257 11.9289C14.2257 12.7254 13.0956 13.1109 12.4016 12.7254C11.3615 12.1475 10.1657 11.3728 8.95239 11.1859C8.79745 11.162 8.67651 11.0328 8.67651 10.8761Z"
        fill="#5B5F73"
      />
      <path
        d="M7.5469 10.8715V3.64228C7.5469 3.50804 7.45798 3.38916 7.32712 3.35923C4.75427 2.77072 1.38687 4.34843 1.4242 7.07778C1.47197 10.5718 4.66238 11.2676 7.26508 11.1679C7.42359 11.1618 7.5469 11.0302 7.5469 10.8715Z"
        fill="#5B5F73"
      />
      <path
        d="M5.65548 12.1269C6.18077 12.1668 6.76933 12.1336 7.12846 12.104C7.30775 12.0893 7.46483 12.2292 7.46334 12.409C7.45083 13.923 7.34905 16.298 6.9219 16.298H5.95869C5.38802 16.298 4.36412 13.8087 3.97119 12.234C3.9115 11.9948 4.16719 11.7958 4.40127 11.8731C4.71902 11.9781 5.15439 12.0888 5.65548 12.1269Z"
        fill="#5B5F73"
      />
      <path
        d="M15.0422 8.90721L15.0423 5.73514C15.0423 5.53127 15.2424 5.38683 15.4243 5.47899C17.0056 6.28033 16.9129 8.35944 15.4261 9.1593C15.2453 9.2566 15.0422 9.11258 15.0422 8.90721Z"
        fill="#5B5F73"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconTopic;
