import request from '../lib/request';

export const loadContacts =
  ({
    search = '',
    contactLists = [
      'employees',
      'suppliers',
      'customers',
      'partners',
      'companyPartners'
    ],
    page = 0,
    limit = 20,
    mode = 'all'
  } = {}) =>
  (dispatch) =>
    request
      .post('/api/contacts', { search, contactLists, page, limit, mode })
      .then((payload) => {
        dispatch({ type: 'SET_CONTACTS', payload });
      })
      .catch((err) => {
        console.error(err);
        dispatch({ type: 'CLEAR_CONTACTS' });
      });

export const loadContactsChunk =
  ({
    search = '',
    contactLists = ['employees', 'suppliers', 'customers', 'partners'],
    page = 0,
    limit = 20
  } = {}) =>
  (dispatch) =>
    request
      .post('/api/contacts', { search, contactLists, page, limit })
      .then((payload) => {
        dispatch({ type: 'MERGE_CONTACTS', payload });
      })
      .catch((err) => {
        console.error(err);
      });

export const clearContacts = () => (dispatch) => {
  dispatch({ type: 'CLEAR_CONTACTS' });
};

export const setSearch = (search) => (dispatch) => {
  dispatch({ type: 'SET_CONTACTS_SEARCH', payload: { search } });
};

export const loadContactsLists = () => (dispatch) => {
  request
    .get('/api/contacts/lists')
    .then((payload) => {
      dispatch({ type: 'SET_CONTACTS_LISTS', payload });
    })
    .catch((err) => {
      console.error(err);
      dispatch({ type: 'CLEAR_CONTACTS_LISTS' });
    });
};

export const loadContactsForGroupChat = () => (dispatch) => {
  request
    .get('/api/contacts/channel')
    .then((payload) => {
      dispatch({ type: 'SET_CONTACTS_FOR_CHANNEL', payload });
    })
    .catch((err) => {
      console.error(err);
      dispatch({ type: 'CLEAR_CONTACTS_FOR_CHANNEL' });
    });
};

export const clearContactsForGroupChat = () => (dispatch) =>
  dispatch({ type: 'CLEAR_CONTACTS_FOR_CHANNEL' });

export const addContactList =
  ({ name }) =>
  (dispatch) =>
    request.post('/api/contacts/lists', { name }).then((payload) => {
      dispatch({ type: 'ADD_CONTACT_LIST', payload });
      return payload;
    });

export const editContactList =
  ({ id, name }) =>
  (dispatch) =>
    request.patch(`/api/contacts/lists/${id}`, { name }).then((payload) => {
      dispatch({ type: 'EDIT_CONTACT_LIST', payload: { id, name } });
      return payload;
    });

export const deleteContactList = (id) => (dispatch) => {
  request
    .delete(`/api/contacts/lists/${id}`)
    .then(() => {
      dispatch({ type: 'DELETE_CONTACT_LIST', payload: { id } });
    })
    .catch((err) => {
      console.error(err);
    });
};

export const addMemberToContactList =
  ({ memberId, listId }) =>
  (dispatch) => {
    request
      .post('/api/contacts/lists/member', { memberId, listId })
      .then(() => {
        dispatch({
          type: 'ADD_MEMBER_TO_CONTACT_LIST',
          payload: { memberId, listId }
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

export const removeMemberFromContactList =
  ({ listId, memberId }) =>
  (dispatch) => {
    request
      .delete(`/api/contacts/lists/member/${listId}/${memberId}`)
      .then(() => {
        dispatch({
          type: 'REMOVE_MEMBER_FROM_CONTACT_LIST',
          payload: { memberId, listId }
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

export const toggleContactList = (id) => (dispatch) => {
  dispatch({ type: 'TOGGLE_CONTACT_LIST', payload: { id } });
};

export const clearContactsLists = () => (dispatch) => {
  dispatch({ type: 'CLEAR_CONTACTS_LISTS' });
};

export const getContactInfoByEmployeeId = (employeeId) => (dispatch) =>
  request
    .get(`/api/contacts/info/employeeid/${employeeId}`)
    .then((payload) => {
      dispatch({ type: 'CONTACTS:SET_CONTACT_INFORMATION', payload });
    })
    .catch((err) => {
      console.error(err);
    });

export const clearContactInfo = () => (dispatch) => {
  dispatch({ type: 'CONTACTS:CLEAR_CONTACT_INFORMATION' });
};
