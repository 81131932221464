import { REDUX_KEY } from './constants';
import { getChatWidget } from '../../getterCommon';

export const getDelayMessagesBlock = (state) => getChatWidget(state)[REDUX_KEY];

export const getDelayMessagesList = (state) =>
  getDelayMessagesBlock(state).list;

export const getDelayMessagesAmountLeft = (state) =>
  getDelayMessagesBlock(state).amountLeft;

export const getDelayMessagesPlannedDate = (state) =>
  getDelayMessagesBlock(state).plannedDate;
