import { CustomColumnDef, RowDef } from '@/components/EditableTable/types';
import {
  BaseCell,
  styles
} from '@/components/EditableTable/components/BaseCell';
import { isFunction } from '@/lib/utils';

interface CustomCellProps {
  value: unknown;
  column: CustomColumnDef;
  rowIndex: number;
  rowData: RowDef;
}

export function CustomCell({
  value,
  column,
  rowIndex,
  rowData
}: CustomCellProps) {
  const { colSpan, width, style } = column;
  const cellClassName = isFunction(column.cellClassName)
    ? column.cellClassName(rowData, rowIndex)
    : column.cellClassName;
  return (
    <BaseCell
      colSpan={colSpan}
      width={width}
      style={style}
      unstyled
      className={cellClassName}>
      {column.renderCell({
        item: rowData,
        itemIndex: rowIndex,
        value,
        classNames: {
          cell: styles.cell
        }
      })}
    </BaseCell>
  );
}
