import {
  ADD_WAITING_MESSAGE,
  REMOVE_WAITING_MESSAGE,
  REMOVE_WAITING_MESSAGE_FILE_ID,
  ADD_FILE_TO_WAITING_MESSAGE
} from './constants';

export const addWaitingMessage = (message) => ({
  type: ADD_WAITING_MESSAGE,
  payload: { message }
});

export const removeWaitingMessage = (id) => ({
  type: REMOVE_WAITING_MESSAGE,
  payload: { id }
});

export const removeFileId = (fileId) => ({
  type: REMOVE_WAITING_MESSAGE_FILE_ID,
  payload: { id: fileId }
});

export const addFileToMessage = ({ fileId, file }) => ({
  type: ADD_FILE_TO_WAITING_MESSAGE,
  payload: { fileId, file }
});
