import React from 'react';

/* eslint-disable max-len */
const IconVecticalExtra = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      className="cws-icon-hover"
      cx="17"
      cy="17"
      r="17"
      fill="#767B92"
      fillOpacity="0"
    />
    <path
      className="cws-icon-main-fill"
      d="M17 13C17.8284 13 18.5 12.3284 18.5 11.5C18.5 10.6716 17.8284 10 17 10C16.1716 10 15.5 10.6716 15.5 11.5C15.5 12.3284 16.1716 13 17 13Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      d="M17 18.5C17.8284 18.5 18.5 17.8284 18.5 17C18.5 16.1716 17.8284 15.5 17 15.5C16.1716 15.5 15.5 16.1716 15.5 17C15.5 17.8284 16.1716 18.5 17 18.5Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      d="M17 24C17.8284 24 18.5 23.3284 18.5 22.5C18.5 21.6716 17.8284 21 17 21C16.1716 21 15.5 21.6716 15.5 22.5C15.5 23.3284 16.1716 24 17 24Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconVecticalExtra;
