import React from 'react';

/* eslint-disable max-len */
const IconPerson = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      className="cws-icon-main-stroke"
      x="5.5"
      y="0.5"
      width="5"
      height="8"
      rx="2.5"
      stroke="#767B92"
    />
    <path
      className="cws-icon-main-stroke"
      d="M8.33333 16C4.28325 16 1 15.2674 1 14.3636C1.11111 13.4545 1.33832 12.1126 3.66667 11.6364C5 11.3636 5.77778 10.5455 5.66667 10M7.66667 16C11.7168 16 15 15.2674 15 14.3636C14.8889 13.4545 14.6617 12.1126 12.3333 11.6364C11 11.3636 10.2222 10.5455 10.3333 10"
      stroke="#767B92"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconPerson;
