.hint {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.container {
  transform: translate(-6px, 6px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  .content {
    position: relative;
    padding: 20px 16px 8px;
    border: 1px solid var(--color-purple-heart);
    border-radius: 3px;
    border-top: 3px solid var(--color-purple-heart);
    box-shadow: 0 0 11px 0 rgb(28 80 222 / 16%);
    background-color: var(--color-white);

    .info {
      position: absolute;
      top: -12px;
      left: -12px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: var(--color-purple-heart);
      box-shadow: 0 3px 5px 0 rgb(124 64 184 / 67%);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .text {
      color: var(--color-gray-900);
    }
  }
}

.pointer {
  transform: translate(-6px, 6px);
  display: flex;
  flex-direction: column;
  align-items: center;

  .line {
    width: 0;
    height: 8px;
    border: 1px solid var(--color-purple-heart);
  }

  .circle {
    width: 12px;
    height: 12px;
    background-color: var(--color-purple-heart);
    border-radius: 50%;
  }
}

.highlight {
  border: 2px solid var(--color-purple-heart);
  border-radius: 2px;
}

.children {
  width: 100%;
}
