export const clearMessageDividerCase = ({
  getOpenedChatId,
  getClientData,
  removeDivider,
  sendRemoveDivider
}) => {
  const chatId = getOpenedChatId();
  const clientData = getClientData();

  if (clientData.isScrollBottom && clientData.chatId === chatId) {
    removeDivider(chatId);
    sendRemoveDivider(chatId);
  }
};
