export const updateDialogInfoByEmployeeIdCase = async (
  {
    getDialogInfo,
    getContactInfo,
    setDialogInfo,
    setContactInfo,
    fetchContactInfo
  },
  { employeeId }
) => {
  const contactInfo = await fetchContactInfo(employeeId);

  if (getDialogInfo().employeeId === employeeId) {
    setDialogInfo(contactInfo);
  }

  if (getContactInfo().employeeId === employeeId) {
    setContactInfo(contactInfo);
  }
};
