import React from 'react';
import Label from './Label';
import Container from './Container';
import Background from './Background';
import MediaContent from './MediaContent';
import { Mic } from './Mic';

const MemberCard = ({
  member: {
    type,
    peerId,
    stream,
    label,
    avatarSrc,
    muted = false,
    isMicrophoneMuted = muted,
    isCameraMuted = false
  },
  className
}) => (
  <Container id={peerId} stream={stream} className={className}>
    <Background
      show={isCameraMuted}
      avatar={avatarSrc}
      label={label.initials}
    />
    <Label label={label} />
    <Mic micMuted={isMicrophoneMuted} />
    <MediaContent
      id={peerId}
      type={type}
      stream={stream}
      isMicrophoneMuted={isMicrophoneMuted}
      isCameraMuted={isCameraMuted}
    />
  </Container>
);

export default React.memo(MemberCard);
