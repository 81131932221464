import {
  LOADER_START,
  LOADER_FINISH,
  LOADER_RESET
} from '../actionTypes/loaderList';

export const startLoader = (name) => ({
  type: LOADER_START,
  payload: { name }
});

export const finishLoader = (name) => ({
  type: LOADER_FINISH,
  payload: { name }
});

export const resetLoader = (name) => ({
  type: LOADER_RESET,
  payload: { name }
});
