import React from 'react';

/* eslint-disable max-len */
const IconAdd = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      className="cws-icon-hover"
      cx="17"
      cy="17"
      r="17"
      fill="#767B92"
      fillOpacity="0"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 26V8L17.5 8L17.5 26H16.5Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16.5L26 16.5V17.5L8 17.5L8 16.5Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconAdd;
