import React from 'react';

/* eslint-disable max-len */
function IconCloseLarge() {
  return (
    <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.275 2.051a.448.448 0 0 0-.22.609c.037.081.538.596 2.228 2.287l2.18 2.182-2.159 2.158c-1.187 1.187-2.186 2.198-2.22 2.247a.54.54 0 0 0-.056.419.51.51 0 0 0 .311.285c.125.037.22.027.344-.036.045-.023 1.064-1.025 2.263-2.225l2.182-2.183L9.31 9.973c1.546 1.544 2.212 2.194 2.285 2.231a.478.478 0 0 0 .447-.024.463.463 0 0 0 .162-.585c-.037-.073-.687-.739-2.231-2.285L7.794 7.128l2.183-2.182c1.2-1.199 2.202-2.218 2.225-2.263a.417.417 0 0 0 .036-.343.46.46 0 0 0-.648-.284c-.067.033-.798.748-2.28 2.228L7.128 6.463l-2.181-2.18C3.255 2.592 2.741 2.092 2.66 2.055a.451.451 0 0 0-.385-.004"
        fill="#5B5F73"
        fillRule="evenodd"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconCloseLarge;
