import React from 'react';

/* eslint-disable max-len */
function IconSearch() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.17949 2.73077C4.8941 2.73077 2.23077 5.3941 2.23077 8.67949C2.23077 11.9649 4.8941 14.6282 8.17949 14.6282C11.4649 14.6282 14.1282 11.9649 14.1282 8.67949C14.1282 5.3941 11.4649 2.73077 8.17949 2.73077ZM1 8.67949C1 4.71437 4.21437 1.5 8.17949 1.5C12.1446 1.5 15.359 4.71437 15.359 8.67949C15.359 12.6446 12.1446 15.859 8.17949 15.859C4.21437 15.859 1 12.6446 1 8.67949Z"
        fill="#9DA2B4"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3802 12.8802C12.6206 12.6399 13.0102 12.6399 13.2505 12.8802L16.8198 16.4495C17.0601 16.6898 17.0601 17.0794 16.8198 17.3198C16.5794 17.5601 16.1898 17.5601 15.9495 17.3198L12.3802 13.7505C12.1399 13.5102 12.1399 13.1206 12.3802 12.8802Z"
        fill="#9DA2B4"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconSearch;
