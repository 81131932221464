import React from 'react';
import { Button } from '@link/react-components';
import { useVideoChatContext } from '../../../../hooks/useVideoChatContext';
import './style.css';

const Footer = () => {
  const { roomId, isCurrentMemberAdmin, transcript } = useVideoChatContext();

  if (!isCurrentMemberAdmin) {
    return null;
  }

  return (
    <div className="transcript-chat-footer">
      <Button
        mode="text"
        onClick={() => transcript.stop(roomId)}
        disabled={!transcript.isActive}>
        Остановить запись
      </Button>
      <Button
        mode="text"
        onClick={() => transcript.start(roomId)}
        disabled={transcript.isActive}>
        Начать запись
      </Button>
    </div>
  );
};

export default React.memo(Footer);
