import { assoc } from 'ramda';
import slice from '@/lib/actionReducer';
import appConfig from '@/config/app';
import { ModuleName } from '@/constants/module';

interface ModuleReducerState {
  activeModule: ModuleName;
}

const { DEFAULT_ACTIVE_MODULE } = appConfig;

const initState = {
  activeModule: DEFAULT_ACTIVE_MODULE ?? ModuleName.ETP
};

export const MODULE_REDUCER_NAME = 'module';

const moduleSlice = slice({
  name: MODULE_REDUCER_NAME,
  initState,
  reducers: {
    setActiveModule: (state: ModuleReducerState, payload: ModuleName) =>
      assoc('activeModule', payload, state)
  }
});

export const moduleActions = moduleSlice.actions;
export const moduleReducer = moduleSlice.reducer;
