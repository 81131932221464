// Actions type
const PREFIX = 'CHAT-WIDGET';

export const SHOW = `${PREFIX}:SHOW_CHAT_WIDGET`;
export const HIDE = `${PREFIX}:HIDE_CHAT_WIDGET`;

export const SET_OPEN_CHAT = `${PREFIX}:SET_OPEN_CHAT`;

export const SET_TAB = `${PREFIX}:TAB:SET`;
export const SET_MODE = `${PREFIX}:MODE:SET`;
export const SET_FILTER = `${PREFIX}:FILTER:SET`;
export const SET_GROUP_CONTACT = `${PREFIX}:GROUP_CONTACT:SET`;

export const SET_CHATS = `${PREFIX}:SET_CHATS`;
export const ADD_CHATS = `${PREFIX}:ADD_CHATS`;
export const HIDE_CHAT = `${PREFIX}:HIDE_CHAT`;
export const UPDATE_CHAT = `${PREFIX}:UPDATE_CHAT`;

export const SET_ARCHIVES = `${PREFIX}:SET_ARCHIVES`;
export const ADD_ARCHIVES = `${PREFIX}:ADD_ARCHIVES`;
export const SHOW_ARCHIVE = `${PREFIX}:SHOW_ARCHIVE`;
export const HIDE_ARCHIVE = `${PREFIX}:HIDE_ARCHIVE`;
export const UPDATE_ARCHIVE = `${PREFIX}:UPDATE_ARCHIVE`;

export const SET_MESSAGES = `${PREFIX}:SET_MESSAGES`;
export const SET_MESSAGES_LOADED = `${PREFIX}:SET_MESSAGES_LOADED`;
export const SET_MESSAGES_NEXT = `${PREFIX}:SET_MESSAGES_NEXT`;
export const SET_MESSAGES_MANY = `${PREFIX}:SET_MESSAGES_MANY`;
export const ADD_OR_UPDATE_MESSAGE = `${PREFIX}:ADD_OR_UPDATE_MESSAGE`;
export const UPDATE_MESSAGE = `${PREFIX}:UPDATE_MESSAGE`;
export const REMOVE_MESSAGE = `${PREFIX}:REMOVE_MESSAGE`;
export const SELECT_MESSAGE = `${PREFIX}:SELECT_MESSAGE`;
export const CLEAR_SELECTED_MSGS = `${PREFIX}:CLEAR_SELECTED_MSGS`;

export const SET_SEARCH_MESSAGES = `${PREFIX}:SET_SEARCH_MESSAGES`;

export const SET_GROUP_EDIT = `${PREFIX}:SET_GROUP_EDIT`;

export const SET_INPUT_BLOCK = `${PREFIX}:SET_INPUT_BLOCK`;
export const SET_TEXT_INPUT_BLOCK = `${PREFIX}:SET_TEXT_INPUT_BLOCK`;
export const SET_FILES_INPUT_BLOCK = `${PREFIX}:SET_FILES_INPUT_BLOCK`;
export const HIDE_INPUT_BLOCK = `${PREFIX}:HIDE_INPUT_BLOCK`;
export const SHOW_INPUT_BLOCK = `${PREFIX}:SHOW_INPUT_BLOCK`;
export const SET_LOADING_FILES_IDS_INPUT_BLOCK = `${PREFIX}:SET_LOADING_FILES_IDS_INPUT_BLOCK`;
export const ADD_LOADING_FILE_ID_INPUT_BLOCK = `${PREFIX}:ADD_LOADING_FILE_ID_INPUT_BLOCK`;
export const REMOVE_LOADING_FILE_ID_INPUT_BLOCK = `${PREFIX}:REMOVE_LOADING_FILE_ID_INPUT_BLOCK`;
export const CLEAR_INPUT_BLOCK = `${PREFIX}:CLEAR_INPUT_BLOCK`;

export const SET_TEMP_BLOCK = `${PREFIX}:SET_TEMP_BLOCK`;
export const ADD_OR_UPDATE_TEMP_INPUT = `${PREFIX}:ADD_OR_UPDATE_TEMP_INPUT`;
export const REMOVE_INPUT_FROM_TEMP = `${PREFIX}:REMOVE_INPUT_FROM_TEMP`;

export const SET_QUERY_LIST = `${PREFIX}:SET_QUERY_LIST`;

export const SET_FAVOTIRE_MSGS = `${PREFIX}:SET_FAVOTIRE_MSGS`;
export const UPDATE_FAVOTIRE_MSG = `${PREFIX}:UPDATE_FAVOTIRE_MSG`;

export const SCROLL_TO_MSG = `${PREFIX}:SCROLL_TO_MSG`;
export const SCROLL_WHEN_LOAD_CHUNK = `${PREFIX}:SCROLL_WHEN_LOAD_CHUNK`;

export const SET_UNSENT_MSG = `${PREFIX}:SET_UNSENT_MSG`;
export const REMOVE_UNSENT_MSG = `${PREFIX}:REMOVE_UNSENT_MSG`;

export const SET_HEADER_VIEW = `${PREFIX}:SET_HEADER_VIEW`;

export const SET_EDIT_MSG = `${PREFIX}:SET_EDIT_MSG`;

export const SET_MSG_TO_EDITOR = `${PREFIX}:SET_MSG_TO_EDITOR`;

export const SET_UNREAD_MSG_COUNT = `${PREFIX}:UNREAD_MSG_COUNT`;

export const UPDATE_TYPING_INFO = `${PREFIX}:UPDATE_TYPING_INFO`;

export const SET_ADVANCED_SEARCH = `${PREFIX}:SET_ADVANCED_SEARCH`;
export const SHOW_ADVANCED_SEARCH = `${PREFIX}:SHOW_ADVANCED_SEARCH`;
export const HIDE_ADVANCED_SEARCH = `${PREFIX}:HIDE_ADVANCED_SEARCH`;
export const SET_ADVANCED_SEARCH_PARAM = `${PREFIX}:SET_ADVANCED_SEARCH_PARAM`;
export const SET_ADVANCED_SEARCH_RESULT = `${PREFIX}:SET_ADVANCED_SEARCH_RESULT`;
export const SET_ADVANCED_SEARCH_RESULT_PART = `${PREFIX}:SET_ADVANCED_SEARCH_RESULT_PART`;
export const SET_ADVANCED_SEARCH_GLOBAL_SEARCH = `${PREFIX}:SET_ADVANCED_SEARCH_GLOBAL_SEARCH`;

export const SET_ERRORS = `${PREFIX}:SET_ERRORS`;
export const ADD_ERROR = `${PREFIX}:ADD_ERROR`;
export const CLEAR_ERRORS = `${PREFIX}:CLEAR_ERRORS`;

export const SET_REPLY_MSGS = `${PREFIX}:SET_REPLY_MSGS`;
export const ADD_REPLY_MSG = `${PREFIX}:ADD_REPLY_MSG`;
export const CLEAR_REPLY_MSG = `${PREFIX}:CLEAR_REPLY_MSG`;
export const CLEAR_REPLY_MSG_BY_CHAT = `${PREFIX}:CLEAR_REPLY_MSG_BY_CHAT`;

export const ADD_LAST_UNREAD_MESSAGES = `${PREFIX}:ADD_LAST_UNREAD_MESSAGES`;
export const ADD_OR_UPDATE_LAST_UNREAD_MESSAGE = `${PREFIX}:ADD_OR_UPDATE_LAST_UNREAD_MESSAGE`;
export const CLEAR_LAST_UNREAD_MESSAGE = `${PREFIX}:CLEAR_LAST_UNREAD_MESSAGE`;
export const UPDATE_LAST_MESSAGE = `${PREFIX}:UPDATE_LAST_MESSAGE`;

export const SET_CURRENT_EMPLOYEE_ID = `${PREFIX}:SET_CURRENT_EMPLOYEE_ID`;
