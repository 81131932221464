import { has, last } from 'ramda';

export const anyValue = Symbol('any');

export const defMulti = (name, dispatcher) => {
  const list = {};

  const add = (kind, fn) => {
    list[kind] = fn;
  };

  const isExistKind = (kind) => has(kind, list) || has(anyValue, list);
  const getHandler = (kind) => list[kind] || list[anyValue];

  const callFn = (...args) => {
    const kind = dispatcher(...args);
    if (!isExistKind(kind))
      throw new Error(
        `Handler for kind "${kind}" not found in def multi ${name}`
      );
    const handler = getHandler(kind);
    return handler(...args);
  };

  return {
    callFn,
    add
  };
};

export const addHandler = (multi, kind, handler) => multi.add(kind, handler);
export const call = (...args) => {
  const multi = last(args);
  return multi.callFn(...args);
};
