import { List, Map, fromJS } from 'immutable';

const setCompanies = (state, { companies, offset, size, limit }) =>
  state
    .set('companies', fromJS(companies))
    .set('offset', offset)
    .set('size', size)
    .set('limit', limit);

const setCompanyInfo = (state, { company }) =>
  state.set('companyInfo', fromJS(company));

const setUserInfo = (state, { user }) => state.set('userInfo', fromJS(user));

const setUsers = (state, { users, offset, size, limit }) =>
  state
    .set('users', fromJS(users))
    .set('offset', offset)
    .set('size', size)
    .set('limit', limit);

const setCompanyTariff = (data, { companyId, tariff }) =>
  data.update('companies', (companies) => {
    const index = companies.findIndex((i) => i.get('id') === +companyId);
    return companies.setIn([index, 'billing', 'tariff'], tariff);
  });

const initState = () =>
  Map({
    companies: List(),
    companyInfo: List(),
    userInfo: List(),
    users: List(),
    offset: 0,
    limit: 20,
    size: 0
  });

const adminReducer = (state = initState(), action) => {
  switch (action.type) {
    case 'ADMIN:GET_ALL_COMPANIES:SUCCESS':
      return setCompanies(state, action.payload);
    case 'ADMIN:GET_ALL_USERS:SUCCESS':
      return setUsers(state, action.payload);
    case 'ADMIN:GET_COMPANY_INFO:SUCCESS':
      return setCompanyInfo(state, action.payload);
    case 'ADMIN:GET_USER_INFO:SUCCESS':
      return setUserInfo(state, action.payload);
    case 'ADMIN:CHANGE_COMPANY_TARIFF:SUCCESS':
      return setCompanyTariff(state, action.payload);
    default:
      return state;
  }
};

export default adminReducer;
