.textFiled {
  width: 100%;
}

.input {
  max-width: 100% !important;
  min-width: 20px !important;
  padding: 0 !important;
  font-size: 14px !important;
  width: unset !important;
  flex-grow: 1;
}

.hidden {
  display: none !important;
}

.placeholder {
  width: 100% !important;
}

.inputRoot {
  display: flex;
  flex-wrap: wrap;
  gap: var(--indent-xxs);
  max-width: 100%;
  min-height: 34px;
  padding: var(--indent-xs) !important;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-500);
  border-radius: var(--indent-xxs);
  max-height: 166px;
  overflow-y: auto;
}

.inputRoot::-webkit-scrollbar {
  width: 5px;
  background-color: var(--color-gray-500);
  border-radius: var(--indent-xxs);
}

.inputRoot::-webkit-scrollbar-thumb {
  background: var(--color-gray-700);
  border-radius: 13px;
}

.inputRoot:active,
.inputFocused {
  border: 1px solid var(--color-blue-500);
  box-shadow: 0 0 5px 0 rgba(35 105 241 / 31%);
}

.inputRoot:hover {
  border: 1px solid var(--color-blue-200);
}

.inputRoot div {
  display: flex;
  flex-wrap: wrap;
  gap: var(--indent-xxs);
  position: relative;
}

.inputRoot fieldset {
  border: none;
}

.loader {
  position: relative;
  height: 26px;
  width: 100px;
}

.popper {
  border-radius: 4px;
  box-shadow: 0 3px 7px 0 rgb(36 48 103 / 20%);
}

.inputRootAutocomplete {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-500);
  border-radius: var(--indent-xxs);
  overflow-y: auto;
}

.inputRootAutocomplete:not(.error):active,
.inputFocusedAutocomplete:not(.error) {
  border: 1px solid var(--color-blue-500);
  box-shadow: 0 0 5px 0 rgba(35 105 241 / 31%);
}

.inputRootAutocomplete:not(.error):hover {
  border: 1px solid var(--color-blue-200);
}

.inputRootAutocomplete div {
  display: flex;
  flex-wrap: wrap;
  gap: var(--indent-xxs);
  position: relative;
}

.inputRootAutocomplete > div > div {
  padding-top: var(--indent-xxs) !important;
  padding-bottom: var(--indent-xxs) !important;
}

.inputRootAutocomplete fieldset {
  border: none;
}

.error {
  border-color: var(--color-red-error);
}

.errorText {
  color: var(--color-red-error);
}

.inputAutocomplete {
  min-width: 120px !important;
  font-size: 14px !important;
  padding: var(--indent-xxs) !important;
}

.option {
  margin: 0 var(--indent-xs);
  cursor: pointer;
}

.optionContent {
  padding: var(--indent-xs);
  margin: 0;
}

.optionContent:hover {
  background-color: var(--color-alpha-blue-50);
  border-radius: var(--indent-xxs);
}
