/**
 * @typedef Error
 * @property {string} type
 * @property {number} code
 * @property {string} message
 */

/**
 * @typedef Errors
 * @property {[Error]} errors
 */

/**
 * Add error
 *
 * @param {Errors} errors
 * @param {Error} error
 * @returns {Errors}
 */
export const addError = (errors, error) => [...errors, error];

/**
 * Clear errors
 *
 * @returns {Errors}
 */
export const clearErrors = () => [];
