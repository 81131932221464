import { toast as libToast } from 'react-toastify';
import cn from 'classnames';
import { isFunction, isString } from '@/lib/utils';
import {
  ToastOptions,
  ToastContent,
  ToastId,
  OnChangeCallback,
  ClearWaitingQueueParams,
  GeneralToastOptions
} from './types';
import { ToastStorage } from './ToastStorage';
import { ToastContentContainer } from './ToastContentContainer';
import styles from './Toast.module.scss';

export class Toast {
  private originalToast = libToast;

  private content: ToastContent = (<ToastContentContainer />);

  private storage: ToastStorage;

  private generalOptions: GeneralToastOptions = {};

  public testCounter = 0;

  constructor(storage: ToastStorage) {
    this.storage = storage;

    this.setContent = this.setContent.bind(this);
  }

  setContent(content: ToastContent) {
    this.content = content;

    return this;
  }

  setGeneralOptions(options: GeneralToastOptions) {
    this.generalOptions = options;

    return this;
  }

  show(content: ToastContent, options: ToastOptions = {}) {
    const $defaultContent = options.content ?? this.content;
    const $content = typeof content !== 'string' ? content : $defaultContent;
    const text = typeof content === 'string' ? content : '';

    const classNameObj: Record<string, boolean> = {
      [styles.toast]: true,
      [styles.notInteractive]: options.closeOnClick === false
    };
    if (isString(options.className)) {
      classNameObj[options.className] = true;
    }

    const className = isFunction(options.className)
      ? options.className
      : cn(classNameObj);

    const preparedOptions = {
      ...this.generalOptions,
      ...options,
      data: { text, ...options.data },
      className
    };

    if (options?.toastId !== undefined && this.storage.has(options.toastId)) {
      const { toastId } = options;

      this.originalToast.update(toastId, {
        render: $content,
        ...preparedOptions
      });
      this.storage.save({ id: toastId, ...preparedOptions });

      return toastId;
    }

    const newToastId = this.originalToast($content, preparedOptions);

    this.storage.save({ id: newToastId, ...preparedOptions });

    return newToastId;
  }

  success(content: ToastContent, options?: ToastOptions) {
    return this.show(content, { ...options, type: 'success' });
  }

  info(content: ToastContent, options?: ToastOptions) {
    return this.show(content, { ...options, type: 'info' });
  }

  warning(content: ToastContent, options?: ToastOptions) {
    return this.show(content, { ...options, type: 'warning' });
  }

  error(content: ToastContent, options?: ToastOptions) {
    return this.show(content, { ...options, type: 'error' });
  }

  dismiss(id?: ToastId) {
    this.originalToast.dismiss(id);

    if (id) {
      this.storage.delete(id);
    } else {
      this.storage.clear();
    }
  }

  clearWaitingQueue(params?: ClearWaitingQueueParams) {
    return this.originalToast.clearWaitingQueue(params);
  }

  onChange(cb: OnChangeCallback) {
    this.originalToast.onChange(cb);
  }
}
