import React from 'react';
/* eslint-disable max-len */
const Filter = () => (
  <svg viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6793 1.94909C17.8923 2.4082 17.8195 2.94921 17.4927 3.33567L11.8 10.0672V15.9962C11.8 16.4468 11.5667 16.8652 11.1835 17.1021C10.8002 17.3389 10.3216 17.3605 9.91865 17.159L6.91865 15.659C6.47823 15.4388 6.20003 14.9886 6.20003 14.4962V10.0672L0.507389 3.33567C0.180572 2.94921 0.107761 2.4082 0.320776 1.94909C0.533792 1.48997 0.993906 1.19623 1.50003 1.19623H16.5C17.0062 1.19623 17.4663 1.48997 17.6793 1.94909ZM7.50003 9.59123V14.4962L10.5 15.9962V9.59123L16.5 2.49623H1.50003L7.50003 9.59123Z"
      fill="#5B5F73"
    />
  </svg>
);
export default Filter;
/* eslint-disable max-len */
