import { fromJS } from 'immutable';
import { curry, tap, is } from 'ramda';

export * from './deepEqualSelector';
export * from './translate';
export * from './money';

export const actionCreator = (type, payload) => ({
  type,
  payload: payload || {}
});
export const curriedActionCreator = curry(actionCreator);

export const log = (v, caption = 'log') =>
  tap((value) => {
    const convertedValue = is(Object, value) ? fromJS(value).toJS() : value;
    console.info(`${caption}: `, convertedValue);
  }, v);

export const testClass = (type, name) => `test_${type}_${name}`;
export const concatClass = (...classes) => classes.join(' ');

export const convertToBoolMap = (list, excludeList) => {
  const result = list.reduce((acc, attr) => {
    acc[attr] = !excludeList.includes(attr);
    return acc;
  }, {});
  return result;
};

export const hexToRgb = (hex) =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));

export function isMobileDevice() {
  try {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  } catch (e) {
    return false;
  }
}

export function isMobileIosDevice() {
  try {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  } catch (e) {
    return false;
  }
}
