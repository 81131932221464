import { defRecord } from 'core/lib';

const { makeClientData } = defRecord(
  'ClientData',
  ['chatId', 'isScrollBottom', 'isActiveTab', 'isFocusedInput'],
  {
    chatId: null,
    isScrollBottom: false,
    isActiveChat: false,
    isFocusedInput: false
  }
);

const updateClientData = (clientData, chatId, data) => {
  if (isSameChat()) return { ...clientData, ...data };

  return { chatId, ...data };

  function isSameChat() {
    return clientData.chatId === chatId;
  }
};

export { makeClientData, updateClientData };
