import { assoc } from 'ramda';
import slice from '../../../../../lib/actionReducer';

const initState = {
  loading: false,
  messages: [],
  connectedEmails: []
};

const messagesSlice = slice({
  name: 'EMAIL_MESSAGES',
  initState,
  reducers: {
    setLoading: (state, payload) => assoc('loading', payload, state),
    setMessages: (state, payload) => assoc('messages', payload, state),
    setConnectedEmails: (state, payload) =>
      assoc('connectedEmails', payload, state)
  }
});

export const { actions } = messagesSlice;
export default messagesSlice.reducer;
