import React from 'react';

/* eslint-disable max-len */
const IconTask = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 17C13.4183 17 17 13.4183 17 9C17 8.14258 16.8651 7.31667 16.6154 6.54233L10.5453 12.6125C9.73027 13.4275 8.40888 13.4275 7.59387 12.6125L5.16898 10.1876C4.62837 9.64695 4.62837 8.77046 5.16898 8.22985C5.70959 7.68924 6.58609 7.68924 7.12669 8.22985L8.57768 9.68083C8.84934 9.9525 9.28981 9.9525 9.56148 9.68083L15.244 3.9983C13.7779 2.17035 11.5256 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconTask;
