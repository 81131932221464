import React from 'react';
import cn from 'classnames';
import { useVideoChatContext } from '@/modules/VideoChat/hooks/useVideoChatContext';
import Content from './Content';
import { SidebarModes } from './modes';
import './style.css';

const Sidebar = () => {
  const { sidebarMode, transcript } = useVideoChatContext();

  return (
    <div
      className={cn('video-chat-sidebar-wrapper', {
        'video-chat-sidebar-wrapper_opened': sidebarMode !== SidebarModes.None
      })}>
      <div className="video-chat-sidebar">
        <div className="video-chat-sidebar__content">
          <Content mode={sidebarMode} transcript={transcript} />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
