import React from 'react';
import EndCallIcon from '@/img/videochat/end-call.svg';
import { useVideoChatContext } from '../../hooks';

const ButtonEndCall = () => {
  const { endCall } = useVideoChatContext();

  return (
    <button
      type="button"
      className="video-chat__btn video-chat__btn-call-end"
      onClick={endCall}>
      <img
        className="video-chat__btn-img_call_end"
        src={EndCallIcon}
        draggable={false}
        alt=""
      />
    </button>
  );
};

export default ButtonEndCall;
