import { bindActionCreators } from 'redux';
import { applyState } from '../utils';
import * as actions from './actions';
import * as storage from './storage';
import { getOpenedChatId } from '../../getter';
import { getClientData } from '../clientData/getters';
import { clearMessageDividerCase } from './useCases/clearMessageDivider';
import { subscribeToClearMessageDividerCase } from './useCases/subscribeToClearMessageDivider';
import { unsubscribeFromClearMessageDividerCase } from './useCases/unsubscribeFromClearMessageDivider';

export const clearMessageDivider = () => (dispatch, getState) =>
  clearMessageDividerCase({
    getOpenedChatId: applyState(getState, getOpenedChatId),
    getClientData: applyState(getState, getClientData),
    removeDivider: bindActionCreators(actions.removeLastUnreadMsg, dispatch),
    sendRemoveDivider: storage.sendRemoveDivider
  });

export const subscribeToClearMessageDivider = () => (dispatch) =>
  subscribeToClearMessageDividerCase({
    subscribeToRemoveDivider: storage.subscribeToRemoveDivider,
    removeDivider: bindActionCreators(actions.removeLastUnreadMsg, dispatch)
  });

export const unsubscribeFromClearMessageDivider = () =>
  unsubscribeFromClearMessageDividerCase({
    unsubscribeFromRemoveDivider: storage.unsubscribeFromRemoveDivider
  });
