import React from 'react';
// eslint-disable-next-line max-len
import { getMessageDOMId } from '@/components/ChatWidget/main/chat-items/helpers';
import TranscriptMessage from '@/components/ChatWidget/main/chat-messages/components/Message/TranscriptMessage';
import Header from './components/Header';
import Footer from './components/Footer';
import './style.css';

const TranscriptChat = ({ messages }) => (
  <div className="transcript-chat">
    <div className="transcript-chat__header">
      <Header />
    </div>
    <div className="transcript-chat__body">
      {messages.map((message) => (
        <TranscriptMessage
          key={message.id}
          id={getMessageDOMId(message.id)}
          message={message}
          avatarSize={40}
        />
      ))}
    </div>
    <div className="transcript-chat__footer">
      <Footer />
    </div>
  </div>
);

export default React.memo(TranscriptChat);
