import React from 'react';

/* eslint-disable max-len */
function IconCloseLarge() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1096 1.69038C15.3635 1.94422 15.3635 2.35578 15.1096 2.60962L2.10962 15.6096C1.85578 15.8635 1.44422 15.8635 1.19038 15.6096C0.93654 15.3558 0.93654 14.9442 1.19038 14.6904L14.1904 1.69038C14.4442 1.43654 14.8558 1.43654 15.1096 1.69038Z"
        fill="#9DA2B4"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.19038 1.69038C1.44422 1.43654 1.85578 1.43654 2.10962 1.69038L15.1096 14.6904C15.3635 14.9442 15.3635 15.3558 15.1096 15.6096C14.8558 15.8635 14.4442 15.8635 14.1904 15.6096L1.19038 2.60962C0.93654 2.35578 0.93654 1.94422 1.19038 1.69038Z"
        fill="#9DA2B4"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconCloseLarge;
