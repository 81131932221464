import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const DEFAULT_LNG = 'ru';
const DEFAULT_NS = 'ui';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: DEFAULT_LNG,
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    nsSeparator: false,
    ns: DEFAULT_NS,
    defaultNS: DEFAULT_NS,
    fallbackNS: DEFAULT_NS,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      requestOptions: {
        cache: 'no-store'
      }
    }
  });

export default i18n;
