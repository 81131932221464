import { List, Map, fromJS } from 'immutable';

const setMessages = (state, messages) =>
  state.set('isMessagesLoaded', true).set('messages', fromJS(messages));

const addMessage = (state, message) =>
  state.update('messages', (list) => list.push(fromJS(message)));

const updateMessage = (state, message) =>
  state.update('messages', (list) =>
    list.update(
      list.findIndex((oldMessage) => oldMessage.get('_id') === message._id),
      () => fromJS(message)
    )
  );

const changeViewMode = (state, { viewMode }) => state.set('viewMode', viewMode);

const setBadgeCount = (state, count) => state.set('chatBadgeCount', count);

const setLastMessages = (state, message) =>
  state.set('lastMessages', fromJS(message));

const setDialogs = (state, { dialogs, page, limit, total, orderBy }) =>
  state.set(
    'dialogs',
    fromJS({
      items: dialogs,
      page,
      limit,
      total,
      orderBy
    })
  );

const mergeDialogs = (state, { dialogs, page, limit, total, orderBy }) => {
  let items = state.getIn(['dialogs', 'items']).concat(fromJS(dialogs));

  // Delete duplicates
  items = items.reduce((acc, current) => {
    const dialogType = current.get('type');

    if (
      dialogType === 'Dialog' ||
      dialogType === 'Channel' ||
      dialogType === 'Topic'
    ) {
      const dialogIndex = acc.findIndex(
        (dialog) => dialog.get('id') === current.get('id')
      );
      // If channel is not exists in acc
      if (dialogIndex === -1) {
        return acc.push(current);
      }
    }

    return acc;
  }, List());

  return state.set(
    'dialogs',
    fromJS({
      items,
      page,
      limit,
      total,
      orderBy
    })
  );
};

const deleteDialogFromStore = (state, { dialogId }) => {
  let items = state.getIn(['dialogs', 'items']);
  items = items.filter((dialog) => {
    const dialogType = dialog.get('type');

    if (dialogType === 'Dialog') {
      const id = dialog.get('id');
      return id !== dialogId;
    }
    return true;
  });

  return state
    .setIn(['dialogs', 'items'], items)
    .setIn(['dialogs', 'total'], state.getIn(['dialogs', 'total']) - 1);
};

const deleteChannelFromStore = (state, { channelId }) => {
  let items = state.getIn(['dialogs', 'items']);
  items = items.filter((dialog) => {
    const dialogType = dialog.get('type');

    if (dialogType === 'Channel' || dialogType === 'Topic') {
      const id = dialog.get('id');
      return id !== channelId;
    }

    return true;
  });

  return state
    .setIn(['dialogs', 'items'], items)
    .setIn(['dialogs', 'total'], state.getIn(['dialogs', 'total']) - 1);
};

const setDialogsSearch = (state, { search }) => state.set('search', search);

const clearDialogsSearch = (state) => state.set('search', '');

const setLoadingChunk = (state, { loadingChunk }) =>
  state.set('loadingChunk', loadingChunk);

const setSupportUser = (state, user) => state.set('supportUser', user);

const setDialogId = (state, dialogId) => state.set('dialogId', dialogId);

const setDraftMessage = (state, { dialogId, msg }) =>
  state.set('draftMessage', { dialogId, msg });

const clearDraftMessage = (state) => state.set('draftMessage', null);

const pushOrReplaceDialog = (state, { dialog: newDialog, sort }) => {
  const { dialogId } = newDialog;
  const currentDialogs = state.getIn(['dialogs', 'items']);
  const indexOfOldDialog = currentDialogs.findIndex(
    (oldDialog) => oldDialog.get('dialogId') === dialogId
  );

  let newDialogs = currentDialogs;

  if (sort) {
    if (indexOfOldDialog !== -1) {
      newDialogs = newDialogs.delete(indexOfOldDialog);
    }
    // Sort by time
    newDialogs = newDialogs.push(fromJS(newDialog));
    newDialogs = newDialogs.sort((dialog1, dialog2) => {
      const date1 =
        dialog1.get('lastMessage') &&
        (dialog1.getIn(['lastMessage', 'date']) ||
          dialog1.getIn(['lastMessage', 'time']));
      const date2 =
        dialog2.get('lastMessage') &&
        (dialog2.getIn(['lastMessage', 'date']) ||
          dialog2.getIn(['lastMessage', 'time']));
      if (!date1) {
        return 1;
      }
      if (!date2) {
        return -1;
      }
      return new Date(date2) - new Date(date1);
    });

    return state.setIn(['dialogs', 'items'], newDialogs);
  }

  // If dialog already exists, then update
  if (indexOfOldDialog !== -1) {
    newDialogs = newDialogs.set(indexOfOldDialog, fromJS(newDialog));
    return state.setIn(['dialogs', 'items'], newDialogs);
  }

  // If dialog is not exists, then push it to bottom
  // Disabled due sorting issue (case UN-5625)
  // newDialogs = newDialogs.push(fromJS(newDialog));
  // return state.setIn(['dialogs', 'items'], newDialogs);
  return state;
};

const pushOrReplaceChannel = (state, { dialog: newChannel, sort }) => {
  const { id } = newChannel;
  const currentDialogs = state.getIn(['dialogs', 'items']);
  const indexOfOldDialog = currentDialogs.findIndex(
    (oldDialog) => oldDialog.get('id') === id
  );

  let newDialogs = currentDialogs;

  if (sort) {
    if (indexOfOldDialog !== -1) {
      newDialogs = newDialogs.delete(indexOfOldDialog);
    }
    // Sort by time
    newDialogs = newDialogs.push(fromJS(newChannel));
    newDialogs = newDialogs.sort((dialog1, dialog2) => {
      const date1 =
        dialog1.get('lastMessage') &&
        (dialog1.getIn(['lastMessage', 'date']) ||
          dialog1.getIn(['lastMessage', 'time']));
      const date2 =
        dialog2.get('lastMessage') &&
        (dialog2.getIn(['lastMessage', 'date']) ||
          dialog2.getIn(['lastMessage', 'time']));
      if (!date1) {
        return 1;
      }
      if (!date2) {
        return -1;
      }
      return new Date(date2) - new Date(date1);
    });

    return state.setIn(['dialogs', 'items'], newDialogs);
  }

  // If channel already exists, then update
  if (indexOfOldDialog !== -1) {
    newDialogs = newDialogs.set(indexOfOldDialog, fromJS(newChannel));
    return state.setIn(['dialogs', 'items'], newDialogs);
  }

  // If channel is not exists, then push it to bottom
  // Disabled due sorting issue (case UN-5625)
  // newDialogs = newDialogs.push(fromJS(newChannel));
  // return state.setIn(['dialogs', 'items'], newDialogs);
  return state;
};

const clearMessages = (state) =>
  state
    .set('isMessagesLoaded', false)
    .set('messages', List())
    .set('lastMessages', List())
    .set('dialogId', null)
    .set('files', []);

const clearAttach = (state) => state.set('files', []);

const getAttach = (state, { files }) => state.set('files', files || []);

const unhideDialog = (state, { uniqueId }) =>
  state.updateIn(['dialogs', 'items'], (dialog) =>
    dialog.update(
      dialog.findIndex((oldDialog) => oldDialog.get('uniqueId') === uniqueId),
      (oldDialog) => oldDialog.set('isHidden', false)
    )
  );

const addDialogListToCheckedLists = (
  state,
  { dialogId, dialogType, dialogListId }
) => {
  const dialogs = state.getIn(['dialogs', 'items']);
  let dialogIndex = -1;

  if (dialogType === 'Dialog') {
    dialogIndex = dialogs.findIndex((dialog) => dialog.get('id') === dialogId);
  } else if (dialogType === 'Channel') {
    dialogIndex = dialogs.findIndex((dialog) => dialog.get('id') === dialogId);
  } else if (dialogType === 'Topic') {
    dialogIndex = dialogs.findIndex((dialog) => dialog.get('id') === dialogId);
  }

  if (dialogIndex !== -1) {
    const dialog = state.getIn(['dialogs', 'items']).get(dialogIndex);
    const checkedListsUser = dialog.getIn(['checkedLists', 'user']);

    const updatedCheckedListsUser = checkedListsUser.push(dialogListId);
    const updatedDialog = dialog.setIn(
      ['checkedLists', 'user'],
      updatedCheckedListsUser
    );

    return state.setIn(['dialogs', 'items', dialogIndex], updatedDialog);
  }

  return state;
};

const removeDialogListFromCheckedLists = (
  state,
  { dialogId, dialogType, dialogListId }
) => {
  const dialogs = state.getIn(['dialogs', 'items']);
  let dialogIndex = -1;

  if (dialogType === 'Dialog') {
    dialogIndex = dialogs.findIndex((dialog) => dialog.get('id') === dialogId);
  } else if (dialogType === 'Channel' || dialogType === 'Topic') {
    dialogIndex = dialogs.findIndex((dialog) => dialog.get('id') === dialogId);
  }

  if (dialogIndex !== -1) {
    const dialog = state.getIn(['dialogs', 'items']).get(dialogIndex);

    const checkedListsUser = dialog.getIn(['checkedLists', 'user']);

    const updatedCheckedListsUser = checkedListsUser.filter(
      (id) => id !== dialogListId
    );
    const updatedDialog = dialog.setIn(
      ['checkedLists', 'user'],
      updatedCheckedListsUser
    );

    return state.setIn(['dialogs', 'items', dialogIndex], updatedDialog);
  }

  return state;
};

const toggleBlockMe = (data, { employeeId, isBlocked }) => {
  const index = data
    .getIn(['dialogs', 'items'])
    .findIndex((dialog) => dialog.get('employeeId') === employeeId);

  if (index === -1) return data;

  return data.setIn(['dialogs', 'items', index, 'isBlockedMe'], isBlocked);
};

const toggleBlockContact = (data, { employeeId, isBlocked }) => {
  const index = data
    .getIn(['dialogs', 'items'])
    .findIndex((dialog) => dialog.get('employeeId') === employeeId);

  if (index === -1) return data;

  return data.setIn(['dialogs', 'items', index, 'isBlockedContact'], isBlocked);
};

const initState = () =>
  Map({
    isMessagesLoaded: false,
    messages: List(),
    lastMessages: List(),
    chatBadgeCount: 0,
    dialogs: Map({
      items: List(),
      page: 0,
      limit: 20,
      total: 0,
      orderBy: 'update-time'
    }),
    loadingChunk: false,
    search: '',
    files: [],
    dialogId: null,
    supportUser: '',
    viewMode: 'chat',
    draftMessage: null
  });

const chatReducer = (state = initState(), action) => {
  switch (action.type) {
    case 'CHAT:TOGGLE_BLOCK_ME':
      return toggleBlockMe(state, action.payload);
    case 'CHAT:TOGGLE_BLOCK_CONTACT':
      return toggleBlockContact(state, action.payload);
    case 'CHAT:SET_MESSAGES':
      return setMessages(state, action.payload);
    case 'CHAT:DELETE_FILE_ATTACH:SUCCESS':
      return setMessages(state, action.payload);
    case 'CHAT:ADD_MESSAGE':
      return addMessage(state, action.payload);
    case 'CHAT:UPDATE_MESSAGE':
      return updateMessage(state, action.payload);
    case 'CHAT:DELETE_DIALOG_FROM_STORE':
      return deleteDialogFromStore(state, action.payload);
    case 'CHAT:DELETE_CHANNEL_FROM_STORE':
      return deleteChannelFromStore(state, action.payload);
    case 'CHAT:UNHIDE_DIALOG':
      return unhideDialog(state, action.payload);
    case 'CHAT:CHANGE_VIEW_MODE':
      return changeViewMode(state, action.payload);
    case 'CHAT:LAST_MESSAGES':
      return setLastMessages(state, action.payload);
    case 'CHAT:SET_BADGE_COUNT':
      return setBadgeCount(state, action.payload);
    case 'CHAT:SET_DIALOGS':
      return setDialogs(state, action.payload);
    case 'CHAT:SET_CHUNK_LOADER':
      return setLoadingChunk(state, action.payload);
    case 'CHAT:SET_DIALOGS_SEARCH':
      return setDialogsSearch(state, action.payload);
    case 'CHAT:CLEAR_DIALOGS_SEARCH':
      return clearDialogsSearch(state);
    case 'CHAT:MERGE_DIALOGS':
      return mergeDialogs(state, action.payload);
    case 'CHAT:SET_DIALOG_ID':
      return setDialogId(state, action.payload);
    case 'CHAT:SET_DRAFT_MESSAGE':
      return setDraftMessage(state, action.payload);
    case 'CHAT:CLEAR_DRAFT_MESSAGE':
      return clearDraftMessage(state);
    case 'CHAT:GET_ATTACH:SUCCESS':
      return getAttach(state, action.payload);
    case 'CHAT:CLEAR_MESSAGES':
      return clearMessages(state);
    case 'CHAT:CLEAR_ATTACH':
      return clearAttach(state);
    case 'CHAT:SET_IN_DIALOGS_LIST':
      return pushOrReplaceDialog(state, action.payload);
    case 'CHAT:SET_CHANNEL_IN_DIALOGS_LIST':
      return pushOrReplaceChannel(state, action.payload);
    case 'CHAT:ADD_MEMBER_TO_DIALOG_LISTS':
      return addDialogListToCheckedLists(state, action.payload);
    case 'CHAT:REMOVE_MEMBER_FROM_DIALOG_LISTS':
      return removeDialogListFromCheckedLists(state, action.payload);
    case 'CHAT:SET_SUPPORT_USER':
      return setSupportUser(state, action.payload);
    default:
      return state;
  }
};

export default chatReducer;
