import React from 'react';

/* eslint-disable max-len */
function IconAt() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        d="M7.00002 1.16666C3.78352 1.16666 1.16669 3.78349 1.16669 6.99999C1.16669 10.2165 3.78352 12.8333 7.00002 12.8333C7.85519 12.8333 8.72727 12.6169 9.59119 12.1893L9.07494 11.1434C8.3726 11.4905 7.67435 11.6667 7.00002 11.6667C4.42694 11.6667 2.33335 9.57307 2.33335 6.99999C2.33335 4.42691 4.42694 2.33332 7.00002 2.33332C9.5731 2.33332 11.6667 4.42691 11.6667 6.99999V7.58332C11.6667 7.98699 11.4841 8.74999 10.7917 8.74999C9.97735 8.74999 9.92019 7.68891 9.91669 7.58332V4.66666H8.75002V4.68124C8.24793 4.29563 7.6331 4.08556 7.00002 4.08332C5.39177 4.08332 4.08335 5.39174 4.08335 6.99999C4.08335 8.60824 5.39177 9.91666 7.00002 9.91666C7.84585 9.91666 8.60302 9.54857 9.13619 8.97107C9.44185 9.49024 9.95752 9.91666 10.7917 9.91666C12.1176 9.91666 12.8334 8.71441 12.8334 7.58332V6.99999C12.8334 3.78349 10.2165 1.16666 7.00002 1.16666ZM7.00002 8.74999C6.03519 8.74999 5.25002 7.96482 5.25002 6.99999C5.25002 6.03516 6.03519 5.24999 7.00002 5.24999C7.96485 5.24999 8.75002 6.03516 8.75002 6.99999C8.75002 7.96482 7.96485 8.74999 7.00002 8.74999Z"
        fill="#1C50DE"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconAt;
