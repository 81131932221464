import { ReactNode, useCallback, useRef } from 'react';
import { ToastContainer as RTToastContainer } from 'react-toastify';
import { useMediaQuery, useTheme } from '@mui/material';
import cn from 'classnames';
import { useClickAway } from '@/hooks';
import { ErrorIcon, SuccessIcon } from './Toast.assets';
import { ButtonIcon } from '../ButtonIcon';
import { Configurator } from './ToastConfigurator';
import { CloseButtonProps } from './types';
import styles from './Toast.module.scss';

type TypeOptions = 'info' | 'success' | 'warning' | 'error' | 'default';

interface ToastContainerProps {
  limit?: number;
  autoClose?: number | false;
  configurator: Configurator;
  containerId?: string | number;
}

interface InitializeToastIconsProps {
  type: TypeOptions;
}

type InitializeToastIcons = (props: InitializeToastIconsProps) => ReactNode;

function CloseButton(props: CloseButtonProps) {
  const { closeToast } = props;

  return (
    <ButtonIcon
      className={styles.CloseButton}
      // @ts-ignore
      onClick={closeToast}
      iconName="close"
    />
  );
}

export function ToastContainer({
  containerId,
  autoClose = 5000,
  limit,
  configurator
}: ToastContainerProps) {
  const ref = useRef<HTMLDivElement>(null);

  const handleClickAway = useCallback(() => {
    configurator.handleClickOutside();
  }, [configurator]);

  useClickAway(ref, handleClickAway);

  const initializeToastIcons: InitializeToastIcons = ({ type }) => {
    switch (type) {
      case 'success':
        return <SuccessIcon width={18} height={18} />;
      case 'error':
        return <ErrorIcon width={18} height={18} />;
      case 'warning':
      case 'info':
      case 'default':
      default:
        return true;
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <RTToastContainer
      containerId={containerId}
      ref={ref}
      closeButton={CloseButton}
      draggable={false}
      hideProgressBar
      position={isMobile ? 'top-center' : 'bottom-center'}
      autoClose={autoClose}
      limit={limit}
      pauseOnHover
      pauseOnFocusLoss
      icon={initializeToastIcons}
      theme="dark"
      className={cn(isMobile && styles.container)}
    />
  );
}
