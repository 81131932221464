.cell {
  position: relative;
  height: 1px;
}

.cell:not(.unstyled) {
  border-radius: 2px;
  border: 1px solid #f2f4f8;
  padding: var(--padding-xxs) var(--padding-xs);
  color: var(--editable-table-cell-text-color);
  background-color: var(--editable-table-cell-background-color);
}
