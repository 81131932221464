import { bindActionCreators } from 'redux';
import { addUnsentMessageCase } from './addUnsentMessage';
import { applyState } from '../../utils';
import {
  getCurrentEmployeeId,
  getCurrentUser
} from '../../../../../storeGetters';
import { getChatForward, getChatBlock, getOpenedChatId } from '../../../getter';
import { hasInternetConnections } from '../../connections/getters';
import * as actions from '../actions';
import * as storage from '../storage';
import * as chatActions from '../../chats/actions';
import { createUnsentForwardMessagesCase } from './createUnsentForwardMessages';

const addUnsentMessage = (message) => (dispatch, getState) =>
  addUnsentMessageCase(
    {
      getCurrentEmployeeId: applyState(getState, getCurrentEmployeeId),
      getOpenedChatId: applyState(getState, getOpenedChatId),
      getChatList: applyState(getState, getChatBlock),
      hasInternetConnection: applyState(getState, hasInternetConnections),
      addUnsentMessage: bindActionCreators(actions.addUnsentMessage, dispatch),
      updateChat: bindActionCreators(chatActions.updateChat, dispatch),
      writeUnsentChatMessagesToLS: bindActionCreators(
        storage.writeUnsentChatMessagesToLS,
        dispatch
      )
    },
    { message }
  );

export const createUnsentForwardMessages = () => (dispatch, getState) =>
  createUnsentForwardMessagesCase({
    getOpenedChatId: applyState(getState, getOpenedChatId),
    getChatForward: applyState(getState, getChatForward),
    getCurrentUser: applyState(getState, getCurrentUser),
    addUnsentMessage: bindActionCreators(addUnsentMessage, dispatch)
  });
