import { defStruct } from '../../lib';
import { makeDataPart, getById } from '../dataPart';
import { makeSelectedList, getFirst } from '../selectedList';

/**
 * @typedef PartList
 * @typedef {import('../dataPart')} DataPart
 * @typedef {import('../selectedList')} SelectedList
 */
const methods = defStruct('PartList', ['dataPart', 'selectedList']);

/**
 * Create a part list data type
 *
 * @param {object} params
 * @param {DataPart} params.dataPart
 * @param {SelectedList} params.selectedList
 * @returns {PartList}
 */
export const makePartList = ({
  dataPart = makeDataPart(),
  selectedList = makeSelectedList()
} = {}) => methods.makePartList(dataPart, selectedList);

/**
 * Gives dataPart from part list
 *
 * @param {PartList} partList
 * @returns {DataPart}
 */
export const getDataPart = (partList) => methods.getDataPart(partList);

/**
 * Gives dataPart from part list
 *
 * @param {PartList} partList
 * @returns {SelectedList}
 */
export const getSelectedList = (partList) => methods.getSelectedList(partList);

/**
 * Checks if is part list
 *
 * @param {*} a
 * @returns {boolean}
 */
export const isPartList = (a) => methods.isPartList(a);

/**
 * Gives first selected item
 *
 * @param {PartList} partList
 * @returns {object}
 */
export const getFirstSelectedItem = (partList) =>
  getById(
    getFirst(methods.getSelectedList(partList)),
    methods.getDataPart(partList)
  );
