import React from 'react';

/* eslint-disable max-len */
function IconAddChat() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.08016 2.15839C1.08577 1.66536 1.48704 1.26862 1.98011 1.26862H9.35525C9.71424 1.26862 10.0052 1.55963 10.0052 1.91862C10.0052 2.2776 9.71424 2.56862 9.35525 2.56862H2.37559V15.3802H15.5205V8.53341C15.5205 8.17442 15.8115 7.88341 16.1705 7.88341C16.5295 7.88341 16.8205 8.17442 16.8205 8.53341V15.7802C16.8205 16.2772 16.4176 16.6802 15.9205 16.6802H1.97102C1.46996 16.6802 1.06538 16.271 1.07107 15.77L1.08016 2.15839Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8536 2.9223L15.0777 2.14645C14.8824 1.95118 14.5659 1.95118 14.3706 2.14645L13.4817 3.03533L14.9717 4.5113L15.8536 3.62941C16.0488 3.43414 16.0488 3.11756 15.8536 2.9223ZM14.2645 5.21842L12.7746 3.74245L7.09745 9.4196C7.05148 9.46556 7.01491 9.52006 6.9898 9.58002L6.44075 10.8912C6.26907 11.3012 6.67766 11.7146 7.08963 11.5477L8.4167 11.0103C8.47879 10.9851 8.5352 10.9478 8.58257 10.9004L14.2645 5.21842Z"
        fill="#5B5F73"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconAddChat;
