import React from 'react';

/* eslint-disable max-len */
function IconPurchase() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_28754_631)">
        <path
          className="cws-icon-main-fill"
          d="M11.8334 1.83865C12.2134 1.83861 12.5794 1.98285 12.8572 2.24223C13.135 2.50162 13.304 2.85679 13.33 3.23599L13.3334 3.33865V13.6707C13.3332 14.0506 13.1889 14.4164 12.9295 14.6941C12.6702 14.9717 12.3151 15.1406 11.936 15.1667L11.8334 15.17H4.16669C3.7866 15.17 3.42067 15.0258 3.14285 14.7664C2.86503 14.507 2.69604 14.1518 2.67002 13.7727L2.66669 13.6707V3.33865C2.66664 2.95857 2.81088 2.59264 3.07027 2.31482C3.32965 2.037 3.68483 1.868 4.06402 1.84199L4.16669 1.83865H11.8334ZM5.16669 5.16665C5.03408 5.16665 4.9069 5.21933 4.81313 5.3131C4.71937 5.40687 4.66669 5.53404 4.66669 5.66665C4.66669 5.79926 4.71937 5.92644 4.81313 6.02021C4.9069 6.11397 5.03408 6.16665 5.16669 6.16665H10.8334C10.966 6.16665 11.0931 6.11397 11.1869 6.02021C11.2807 5.92644 11.3334 5.79926 11.3334 5.66665C11.3334 5.53404 11.2807 5.40687 11.1869 5.3131C11.0931 5.21933 10.966 5.16665 10.8334 5.16665H5.16669ZM4.66669 8.33332C4.66669 8.60932 4.89069 8.83332 5.16669 8.83332H10.8334C10.966 8.83332 11.0931 8.78064 11.1869 8.68687C11.2807 8.5931 11.3334 8.46593 11.3334 8.33332C11.3334 8.20071 11.2807 8.07353 11.1869 7.97977C11.0931 7.886 10.966 7.83332 10.8334 7.83332H5.16669C5.03408 7.83332 4.9069 7.886 4.81313 7.97977C4.71937 8.07353 4.66669 8.20071 4.66669 8.33332ZM5.16669 10.5C5.03408 10.5 4.9069 10.5527 4.81313 10.6464C4.71937 10.7402 4.66669 10.8674 4.66669 11C4.66669 11.1326 4.71937 11.2598 4.81313 11.3535C4.9069 11.4473 5.03408 11.5 5.16669 11.5H10.8334C10.966 11.5 11.0931 11.4473 11.1869 11.3535C11.2807 11.2598 11.3334 11.1326 11.3334 11C11.3334 10.8674 11.2807 10.7402 11.1869 10.6464C11.0931 10.5527 10.966 10.5 10.8334 10.5H5.16669Z"
          fill="#5B5F73"
        />
      </g>
      <defs>
        <clipPath id="clip0_28754_631">
          <rect
            className="cws-icon-main-fill"
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
/* eslint-enable max-len */

export default IconPurchase;
