import { lensPath, set, view, prop, pipe, defaultTo } from 'ramda';
import { fromJS } from 'immutable';
import { getDataBy } from '../../getterCommon';
import {
  greaterThanDraft,
  isStatusDraft,
  hasResponded,
  isPublished
} from '../../../PurchaseRequests/Request/requestMethods';

const MAIN_PATH_KEY = 'PRWithResponseModal';
const lensInModal = (key) => lensPath([MAIN_PATH_KEY, key]);

const xShow = lensInModal('isShow');
export const showModal = set(xShow, true);
export const hideModal = set(xShow, false);
export const isShowPRWPModal = getDataBy(view(xShow));

const xRequest = lensInModal('request');
export const setRequest = (state, data) => set(xRequest, data, state);
export const emptyRequest = set(xRequest, null);
export const getRequest = getDataBy(view(xRequest));
const getRequestCatsIds = pipe(getRequest, prop('categoriesId'), defaultTo([]));
const _getRequestId = (request) =>
  prop('requestId', request) || prop('id', request);
export const getRequestId = pipe(getRequest, _getRequestId);
export const getImmutableRequest = pipe(getRequest, fromJS);

const xCategories = lensInModal('categories');
export const setCategories = (state, data) => set(xCategories, data, state);
export const emptyCategories = set(xCategories, []);
const getCategories = getDataBy(view(xCategories));

export const getCatsOfRequest = (state) => {
  const requestCategoriesId = getRequestCatsIds(state);
  return getCategories(state).map((category) =>
    enrichCategory(category, requestCategoriesId.includes(category.id))
  );

  function enrichCategory(category, selected) {
    return { ...category, selected };
  }
};

const makeProps = ({ tkeyText, disabled, onClick }) => ({
  tkeyText,
  disabled,
  onClick
});
export const btnProps = (
  request,
  goToRequest,
  isRequestCurrentCompany = false
) => {
  if (isRequestCurrentCompany) {
    return makeProps({
      tkeyText: isPublished(request)
        ? 'respondForm.chat-form.btn.unpublish'
        : 'respondForm.chat-form.btn.publish',
      disabled: false,
      onClick: goToRequest
    });
  }
  if (!hasResponded(request)) {
    return makeProps({
      tkeyText: 'respondForm.chat-form.btn.respond',
      disabled: false,
      onClick: goToRequest
    });
  }
  if (isStatusDraft(request)) {
    return makeProps({
      tkeyText: 'respondForm.chat-form.btn.continue',
      disabled: false,
      onClick: goToRequest
    });
  }
  if (greaterThanDraft(request)) {
    return makeProps({
      tkeyText: 'respondForm.chat-form.btn.you-responded',
      disabled: true,
      onClick: () => {}
    });
  }
  throw new Error('Not handler');
};
