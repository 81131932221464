import { assoc } from 'ramda';
import { SET_DIALOG_INFO, CLEAR_DIALOG_INFO } from './constants';

export const defaultState = () => ({
  dialogInfo: {}
});

const setDialogInfo = (chatWidget, data) =>
  assoc('dialogInfo', data, chatWidget);

export const reducers = {
  [SET_DIALOG_INFO]: (chatWidget, { info }) => setDialogInfo(chatWidget, info),
  [CLEAR_DIALOG_INFO]: (chatWidget) => setDialogInfo(chatWidget, {})
};
