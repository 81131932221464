.dynamicList {
  width: 100%;
  height: 100%;
}

.list {
  &.loading {
    display: none;
  }
}
