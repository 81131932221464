import React, { createContext, useContext } from 'react';

const ConfigContext = createContext(null);

export const useConfig = () => {
  const context = useContext(ConfigContext);

  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }

  return context;
};

export const ConfigProvider = ({ initialConfig = {}, children }) => {
  const config = [initialConfig];

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};
