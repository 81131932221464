export const unhideContactGeneralCase = async (
  { reqUnhideGeneralContact, uploadContactsCase, setSuccessMessage },
  { employeeId, customSuccessMessage }
) => {
  const { firstName, lastName } = await reqUnhideGeneralContact(employeeId);
  await uploadContactsCase();
  setSuccessMessage(
    customSuccessMessage || {
      key: 'username_was_added_to_list',
      params: { userName: `${lastName} ${firstName}` }
    }
  );
};
