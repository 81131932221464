import * as chatActions from '@/components/ChatWidget/modules/chats/actions';
import { getOpenedChat } from '@/components/ChatWidget/getter';
import { getCurrentEmployeeId } from '@/storeGetters';

export function updateOpenedChat(chat) {
  return (dispatch, getState) => {
    const state = getState();
    const currentEmployeeId = getCurrentEmployeeId(state);

    if (
      Array.isArray(chat.lastMessage?.accessList) &&
      !chat.lastMessage.accessList.includes(currentEmployeeId)
    ) {
      const prevOpenedChat = getOpenedChat(state) ?? {};

      const updatedChat = {
        ...chat,
        lastMessage: prevOpenedChat.lastMessage ?? null
      };

      dispatch(chatActions.setOpenChat(updatedChat));

      return;
    }

    dispatch(chatActions.setOpenChat(chat));
  };
}
