import { setQuery } from '../../../data-type/query-list';

export const updateQueryCase = (
  { getQueryList, setQueryList },
  { queryName, updatedQuery }
) => {
  const queryList = getQueryList();
  const updateQueryList = setQuery(queryName, updatedQuery, queryList);
  setQueryList(updateQueryList);
};
