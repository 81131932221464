import React from 'react';
import { isNil } from 'ramda';
import cn from 'classnames';
import { Tooltip } from '@link/react-components';
import { useTranslate } from '@hooks';
import { useVideoChatContext } from '../../../hooks';
import { VideoOn } from './components/VideoOn';
import { VideoOff } from './components/VideoOff';
import styles from './ButtonCamera.module.css';

const MODES = {
  On: 'on',
  Off: 'off'
};

const VARIANTS = {
  guest: 'guest',
  outline: 'outline',
  filled: 'filled'
};

export const ButtonCamera = ({
  variant = VARIANTS.guest,
  hideTooltips = false,
  tooltipPlacement = 'bottom'
}) => {
  const t = useTranslate();
  const { toggleVideo, currentMember, canUseCamera } = useVideoChatContext();

  const initializeMode = () => {
    if (!canUseCamera || !currentMember) return MODES.Off;

    return currentMember.isCameraMuted ? MODES.Off : MODES.On;
  };

  const [disabled, setDisabled] = React.useState(!canUseCamera);
  const [camMode, setCamMode] = React.useState(initializeMode);

  React.useEffect(() => {
    if (isNil(currentMember)) return;

    setCamMode(currentMember.isCameraMuted ? MODES.Off : MODES.On);
  }, [currentMember?.isCameraMuted]);

  React.useEffect(() => {
    setDisabled(!canUseCamera);
  }, [canUseCamera]);

  const toggleCamMode = () =>
    setCamMode((prev) => {
      const result = prev === MODES.On ? MODES.Off : MODES.On;
      toggleVideo(result === MODES.On);
      return result;
    });

  const translateTooltip = (tKey) => t(`videoChat.tooltips.${tKey}`);

  const icon = camMode === MODES.On ? <VideoOn /> : <VideoOff />;
  const className = cn(
    styles.ButtonCamera,
    styles[variant],
    camMode === MODES.On ? styles.on : styles.off,
    disabled && styles.disabled
  );
  const tooltip = (() => {
    if (hideTooltips) return '';

    if (!canUseCamera) return translateTooltip('no-camera');
    if (camMode === MODES.Off) return translateTooltip('camera-on');
    if (camMode === MODES.On) return translateTooltip('camera-off');

    return '';
  })();

  return (
    <Tooltip placement={tooltipPlacement} title={tooltip}>
      <button className={className} onClick={!disabled && toggleCamMode}>
        {icon}
      </button>
    </Tooltip>
  );
};
