import { HTMLAttributes, SyntheticEvent, useCallback, useState } from 'react';
import {
  Autocomplete,
  AutocompleteClasses,
  AutocompleteRenderInputParams,
  AutocompleteInputChangeReason,
  TextField
} from '@mui/material';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider
} from '@mui/material/styles';
import { Typography } from '@/components/Typography';
import { Icon } from '@/components/Icon';
import cn from 'classnames';
import { isString } from '@/lib/utils';
import { SelectProps, SelectOption } from './Select.types';
import styles from './Select.module.scss';

interface ArrowProps {
  open: boolean;
  size?: number;
}

interface NoOptionsProps {
  text: string;
}

const classes: Partial<AutocompleteClasses> = {
  popper: styles.popper,
  input: styles.input
};

const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }
      }
    }
  }
});

const componentsProps = {
  popper: {
    popperOptions: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 4]
          }
        }
      ]
    }
  }
};

function Arrow({ open, size = 18 }: ArrowProps) {
  return (
    <div
      className={cn(styles.arrow, styles[open ? 'up' : 'down'])}
      role="presentation">
      <Icon iconName="arrow-down-select" width={size} height={size} />
    </div>
  );
}

function NoOptions({ text }: NoOptionsProps) {
  return (
    <Typography variant="body1Reg" className={styles.noOption}>
      {text}
    </Typography>
  );
}

export function Select<TEntity>({
  className,
  id,
  options,
  placeholder,
  getOptionLabel,
  getOptionDisabled,
  renderOption,
  onSelect,
  onFocus,
  onBlur,
  valid = true,
  errorText,
  denyTyping = false,
  acceptTyped = false,
  noOptionsText,
  disabled = false,
  isOptionEqualToValue,
  defaultValue,
  value,
  startAdornment = null,
  endAdornmentIconSize,
  testId,
  disableOptionsManually = false
}: SelectProps<TEntity>) {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);

  const handleFocus = useCallback(() => setActive(true), []);
  const handleBlur = useCallback(() => setActive(false), []);

  const getOptionLabelCallback = useCallback(
    (option?: string | SelectOption<TEntity> | null) => {
      if (isString(option) || option === null || option === undefined) {
        return option;
      }
      return getOptionLabel ? getOptionLabel(option.value) : `${option.value}`;
    },
    [getOptionLabel]
  );

  const renderOptionCallback = (
    props: HTMLAttributes<HTMLLIElement>,
    option: SelectOption<TEntity>
  ) => {
    const isTrue = [true, 'true'];
    const ariaDisabled = props['aria-disabled'];

    const isDisabled = ariaDisabled ? isTrue.includes(ariaDisabled) : false;

    return (
      <li
        {...props}
        key={option.key}
        className={cn(styles.option, {
          [styles.disabled]: isDisabled,
          [styles.disablePointerEvents]: !disableOptionsManually
        })}>
        {renderOption ? (
          renderOption(option.value, { disabled: isDisabled })
        ) : (
          <Typography
            disabled={disabled}
            className={styles.optionContent}
            Component="span"
            variant="body1Reg">
            {`${option.value}`}
          </Typography>
        )}
      </li>
    );
  };

  const renderInputCallback = useCallback(
    (params: AutocompleteRenderInputParams) => {
      const { InputProps, inputProps, ...otherProps } = params;
      return (
        <Typography variant="body1Reg">
          <TextField
            {...otherProps}
            placeholder={placeholder}
            InputProps={{
              ...InputProps,
              id,
              startAdornment: startAdornment ? (
                <span className={styles.startAdornment}>{startAdornment}</span>
              ) : null
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={
              denyTyping
                ? {
                    ...inputProps,
                    value: getOptionLabelCallback(value) || inputProps.value,
                    readOnly: true
                  }
                : {
                    ...inputProps,
                    value: getOptionLabelCallback(value) || inputProps.value
                  }
            }
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Typography>
      );
    },
    [
      placeholder,
      handleBlur,
      handleFocus,
      denyTyping,
      startAdornment,
      getOptionLabelCallback,
      value,
      id
    ]
  );

  const onChangeCallback = useCallback(
    (
      _event: SyntheticEvent<Element, Event>,
      option: string | SelectOption<TEntity> | null
    ) => {
      setOpen(false);
      if (typeof onSelect === 'function' && option) {
        if (isString(option)) {
          onSelect(option);
        } else {
          onSelect(option.value);
        }
      }
    },
    [onSelect]
  );

  const onInputChangeCallback = useCallback(
    (
      _event: SyntheticEvent<Element, Event>,
      inputValue: string,
      reason: AutocompleteInputChangeReason
    ) => {
      if (acceptTyped && reason === 'input') {
        onSelect?.(inputValue);
      }
    },
    [onSelect, acceptTyped]
  );

  const handleEquality = useCallback(
    (option: SelectOption<TEntity>, currentValue: SelectOption<TEntity>) => {
      if (typeof isOptionEqualToValue === 'function') {
        return isOptionEqualToValue(option, currentValue);
      }
      return option.key === currentValue.key;
    },
    [isOptionEqualToValue]
  );

  return (
    <>
      <div
        className={cn(
          styles.select,
          className,
          !valid && styles.error,
          active && styles.active,
          disabled && styles.disabled
        )}>
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <Autocomplete
              value={value}
              defaultValue={defaultValue}
              freeSolo={acceptTyped}
              options={options}
              renderInput={renderInputCallback}
              popupIcon={<Arrow size={endAdornmentIconSize} open={open} />}
              forcePopupIcon
              // @ts-ignore
              getOptionLabel={getOptionLabelCallback}
              getOptionDisabled={getOptionDisabled}
              clearIcon={null}
              renderOption={renderOptionCallback}
              onChange={onChangeCallback}
              onInputChange={onInputChangeCallback}
              onFocus={onFocus}
              onBlur={onBlur}
              classes={classes}
              componentsProps={componentsProps}
              noOptionsText={<NoOptions text={noOptionsText ?? ''} />}
              disabled={disabled}
              isOptionEqualToValue={handleEquality}
              data-testId={testId}
            />
          </StyledEngineProvider>
        </ThemeProvider>
      </div>

      {!valid && errorText && (
        <Typography variant="caption" className={styles.errorMessage}>
          {errorText}
        </Typography>
      )}
    </>
  );
}
