import { assoc, pipe, update, path, append } from 'ramda';
import type { Nullable } from '@/types/app';
import slice from '@/lib/actionReducer';
import { LocalStorageProxy } from '@/utils/localStorageProxy';
import { CART_STORAGE } from '@/modules/marketplace/shared/constants/cart';
import type { Cart, CartItem } from './cart.types';

const storage = new LocalStorageProxy(CART_STORAGE);

const initState: Cart = {
  loading: false,
  items: storage.get() ?? []
};

const cartSlice = slice({
  name: 'cart',
  initState,
  reducers: {
    setLoading: (state: Cart, payload: boolean) =>
      assoc('loading', payload, state),
    setCart: (state: Cart, payload: Nullable<CartItem[]>) => {
      const cartItems = payload ?? [];

      storage.save(cartItems);
      return pipe(assoc('items', cartItems), assoc('loading', false))(state);
    },
    addToCart(state: Cart, payload: { id: string; quantity: number }) {
      const { id, quantity } = payload;
      let newState = state;

      if (quantity === 0) {
        newState = assoc(
          'items',
          state.items.filter((item) => item.id !== id),
          state
        );
      } else {
        const itemIndex = state.items.findIndex((item) => item.id === id);

        if (itemIndex !== -1) {
          newState = assoc(
            'items',
            update(
              itemIndex,
              assoc('quantity', quantity, path(['items', itemIndex], state)),
              state.items
            ),
            state
          );
        } else {
          newState = assoc('items', append(payload, state.items), state);
        }
      }

      storage.save(newState.items);

      return newState;
    },
    deleteCartItems(state: Cart, payload: { ids: string[] }) {
      const { ids } = payload;
      const newState = assoc(
        'items',
        state.items.filter((item) => !ids.includes(item.id)),
        state
      );

      storage.save(newState.items);

      return newState;
    },
    clearCart() {
      const newState: Cart = {
        loading: false,
        items: []
      };

      storage.save(newState.items);

      return newState;
    }
  }
});

export const cartActions = cartSlice.actions;
export const cartReducer = cartSlice.reducer;
