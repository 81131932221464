import { increateLoadCounter } from '../../../data-type/query';

export const chunkUploadContactsCase = async (
  { getContacts, addContacts, getQuery, updateQuery },
  { newParams = {} } = {}
) => {
  const contacts = await getContacts(newParams);
  addContacts(contacts);

  const query = newParams.query || getQuery();
  const increaseCounterQuery = increateLoadCounter(query);
  updateQuery(increaseCounterQuery);
};
