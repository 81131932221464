export const showDelayedMessagesCase = async ({
  getOpenedChatId,
  fetchMessages,
  setDelayedMsgs,
  setChatMode,
  sendCloseEditMessagePanel
}) => {
  const chatId = getOpenedChatId();
  const messages = await fetchMessages(chatId);

  setDelayedMsgs(messages);

  setChatMode('show-delayed-messages');
  sendCloseEditMessagePanel();
};
