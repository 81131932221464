import { Map } from 'immutable';

const updateVerifyEmailErrorMessage = (state, { message }) =>
  state.set('error', message);

export default (state = Map(), { type, payload }) => {
  switch (type) {
    case 'UPDATE_VERIFY_EMAIL_ERROR_MESSAGE':
      return updateVerifyEmailErrorMessage(state, payload);
    default:
      return state;
  }
};
