import { Map } from 'immutable';

// INITIALIZATION (not needed yet)
// function initialize(state, fields) {
//   return state.clear().merge(fields);
// }

// VALIDATION
const addInvalidField = (
  state,
  fieldName,
  isValid,
  messageKey,
  messageParams
) =>
  state.update('invalidFields', Map(), (invalidFields) =>
    isValid
      ? invalidFields.remove(fieldName)
      : invalidFields.set(fieldName, Map({ messageKey, messageParams }))
  );

const updateServerValidation = (
  state,
  fieldName,
  isValid,
  messageKey,
  messageParams
) =>
  state.update('serverValidation', Map(), (invalidFields) =>
    isValid
      ? invalidFields.remove(fieldName)
      : invalidFields.set(fieldName, Map({ messageKey, messageParams }))
  );

export default (state = Map(), action) => {
  switch (action.type) {
    case 'VALIDATOR_UPDATE_STATE':
      return addInvalidField(
        state,
        action.fieldName,
        action.isValid,
        action.messageKey,
        action.messageParams
      );
    case 'SERVER_UPDATE_VALIDATOR':
      return updateServerValidation(
        state,
        action.fieldName,
        action.isValid,
        action.messageKey,
        action.messageParams
      );
    case 'VALIDATOR_CLEAR_STATE':
      return state.clear();
    default:
      return state;
  }
};
