import React from 'react';
import { useTranslate } from '@hooks';
import { Typography } from '@link/react-components';

const Label = ({ label }) => {
  const t = useTranslate();
  return (
    <Typography variant="body1Med" className="video-chat__label">
      {label.self ? t('videoChat.self') : label.name}
    </Typography>
  );
};

export default Label;
