import React from 'react';

/* eslint-disable max-len */
function IconSliders() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-stroke"
        d="M3 16.25V11"
        stroke="#5B5F73"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="cws-icon-main-stroke"
        d="M3 8V2.75"
        stroke="#5B5F73"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="cws-icon-main-stroke"
        d="M9 16.25V9.5"
        stroke="#5B5F73"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="cws-icon-main-stroke"
        d="M9 6.5V2.75"
        stroke="#5B5F73"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="cws-icon-main-stroke"
        d="M15 16.25V12.5"
        stroke="#5B5F73"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="cws-icon-main-stroke"
        d="M15 9.5V2.75"
        stroke="#5B5F73"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="cws-icon-main-stroke"
        d="M0.75 11H5.25"
        stroke="#5B5F73"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="cws-icon-main-stroke"
        d="M6.75 6.5H11.25"
        stroke="#5B5F73"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="cws-icon-main-stroke"
        d="M12.75 12.5H17.25"
        stroke="#5B5F73"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconSliders;
