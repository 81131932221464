import React from 'react';

/* eslint-disable max-len */
function IconDelayedMessageEmpty() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 0.25C6.91421 0.25 7.25 0.585786 7.25 1V1.75H10.75V1C10.75 0.585786 11.0858 0.25 11.5 0.25C11.9142 0.25 12.25 0.585786 12.25 1V1.75H14C15.5188 1.75 16.75 2.98122 16.75 4.5V14.5C16.75 16.0188 15.5188 17.25 14 17.25H4C2.48122 17.25 1.25 16.0188 1.25 14.5V4.5C1.25 2.98122 2.48122 1.75 4 1.75H5.75V1C5.75 0.585786 6.08579 0.25 6.5 0.25ZM5.75 3.25H4C3.30964 3.25 2.75 3.80964 2.75 4.5V5.25H15.25V4.5C15.25 3.80964 14.6904 3.25 14 3.25H12.25V3.5C12.25 3.91421 11.9142 4.25 11.5 4.25C11.0858 4.25 10.75 3.91421 10.75 3.5V3.25H7.25V3.5C7.25 3.91421 6.91421 4.25 6.5 4.25C6.08579 4.25 5.75 3.91421 5.75 3.5V3.25ZM15.25 6.75H2.75V14.5C2.75 15.1904 3.30964 15.75 4 15.75H14C14.6904 15.75 15.25 15.1904 15.25 14.5V6.75Z"
        fill="#5B5F73"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconDelayedMessageEmpty;
