import {
  assoc,
  insert,
  append,
  prepend,
  update,
  assocPath,
  concat,
  remove,
  hasPath,
  isNil,
  pipe
} from 'ramda';
import createSlice from '../../lib/actionReducer';

export const initState = {
  badges: [],
  currentTask: null,
  taskList: {},
  executors: [],
  tagList: []
};

const slice = createSlice({
  name: 'TASK_MANAGER:TASKS',
  initState,
  reducers: {
    setTaskList,
    setCurrentTask,
    setBadges,
    addBadge,
    removeBadge,
    addTaskToList,
    updateTask,
    uploadChunk,
    reloadTaskSection,
    moveTask,
    editTask,
    editCurrentTask,
    removeTask,
    setExecutorsList,
    setTagList,
    addTagToList,
    changeTag
  }
});

export const { actions } = slice;
export default slice.reducer;

function setTaskList(state, payload) {
  return assoc('taskList', payload.list, state);
}

function setCurrentTask(state, payload) {
  return assoc('currentTask', payload.task, state);
}

function setBadges(state, payload) {
  return assoc('badges', payload.badges, state);
}

function addTaskToList(state, payload) {
  const section = payload.section || payload.task.status;

  const taskList = state.taskList[section].list || [];
  return assocPath(
    ['taskList', section, 'list'],
    prepend(payload.task, taskList),
    state
  );
}

function getCoords(block, taskId) {
  for (const key in block) {
    if (Object.prototype.hasOwnProperty.call(block, key)) {
      const index = block[key].list.findIndex((t) => t.id === taskId);

      if (index !== -1) return { key, index };
    }
  }

  return null;
}

function addBadge(state, payload) {
  return pipe(
    () => prepend(payload.badge, state.badges),
    (badges) => setBadges(state, { badges })
  )();
}

function removeBadge(state, payload) {
  return pipe(
    () => state.badges.filter((badge) => badge !== payload.badge),
    (badges) => setBadges(state, { badges })
  )();
}

function updateTask(state, payload) {
  const coords = getCoords(state.taskList, payload.taskId);

  if (isNil(coords)) return state;

  const { key, index } = coords;
  const { list } = state.taskList[key];

  const updatedTask = { ...list[index], ...payload.data };
  return assocPath(
    ['taskList', key, 'list'],
    update(index, updatedTask, list),
    state
  );
}

function editTask(state, payload) {
  const { task } = payload;

  const list = state.taskList[task.status].list || [];

  const index = list.findIndex((t) => t.id === task.id);

  if (index === -1) return state;

  return assocPath(
    ['taskList', task.status, 'list'],
    update(index, task, list),
    state
  );
}

function editCurrentTask(state, payload) {
  const { task } = payload;

  if (isNil(state.currentTask) || state.currentTask.id !== task.id)
    return state;

  return setCurrentTask(state, payload);
}

function moveTask(state, payload) {
  const { id, position, fromStatus, atStatus } = payload;
  const fromList = state.taskList[fromStatus].list || [];

  const index = fromList.findIndex((t) => t.id === id);

  if (index === -1) return state;

  const updatedTask = assoc('status', atStatus, fromList[index]);

  return pipe(
    (result) =>
      assocPath(
        ['taskList', fromStatus, 'list'],
        removeFromSection(result, fromStatus),
        result
      ),
    (result) =>
      assocPath(
        ['taskList', atStatus, 'list'],
        addToSection(result, atStatus),
        result
      ),
    (result) => updateCurrentTaskStatus(result, atStatus)
  )(state);

  function updateCurrentTaskStatus(data, status) {
    if (isNil(data.currentTask) || data.currentTask.id !== id) return data;

    return assocPath(['currentTask', 'status'], status, data);
  }

  function removeFromSection(data, section) {
    return remove(index, 1, getListByStatus(data, section));
  }

  function addToSection(data, section) {
    return insert(position, updatedTask, getListByStatus(data, section));
  }

  function getListByStatus(data, status = '') {
    return data.taskList[status].list || [];
  }
}

function uploadChunk(state, payload) {
  if (!hasPath(['taskList', payload.section, 'list'], state)) return state;

  const updatedBlock = payload;
  const { list } = state.taskList[payload.section];
  updatedBlock.list = concat(list, payload.list);
  return assocPath(['taskList', payload.section], updatedBlock, state);
}

function reloadTaskSection(state, payload) {
  return assocPath(['taskList', payload.section], payload, state);
}

function removeTask(state, payload) {
  const { id, status } = payload;

  if (!hasPath(['taskList', status, 'list'], state)) return state;

  const targetList = state.taskList[status].list;
  const index = targetList.findIndex((t) => t.id === id);

  if (index === -1) return state;

  return assocPath(
    ['taskList', status, 'list'],
    remove(index, 1, targetList),
    state
  );
}

function setExecutorsList(state, payload) {
  return assoc('executors', payload.list, state);
}
function getTagList(state) {
  return state.tagList;
}

function setTagList(state, payload) {
  return assoc('tagList', payload.list, state);
}

function addTagToList(state, payload) {
  return assoc('tagList', append(payload.tag, getTagList(state)), state);
}

function changeTag(state, payload) {
  const index = getTagList(state).findIndex(
    (tag) => tag.value === payload.tag.value
  );

  return assoc('tagList', update(index, payload.tag, getTagList(state)), state);
}
