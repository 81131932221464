import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isNilOrEmpty } from '@/utils/utils';
import { getPermissions } from './permissions';
import { LinkPermissionsStrategy } from './strategies';

const NOOP_ARR = Object.freeze([]);

export const usePermissions = () => {
  const permissions = useSelector(getPermissions);

  return useMemo(() => {
    const strategy = new LinkPermissionsStrategy();

    const preparedPermissions = isNilOrEmpty(permissions)
      ? NOOP_ARR
      : permissions;

    strategy.setPermissions(preparedPermissions);

    return strategy;
  }, [permissions]);
};
