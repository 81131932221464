import { RowDef, TextColumnDef } from '@/components/EditableTable/types';
import { Typography } from '@/components/Typography';
import { BaseCell } from '@/components/EditableTable/components/BaseCell';
import { ReadOnlyTextCell } from '@/components/EditableTable/components/ReadOnlyTextCell';
import { isFunction } from '@/lib/utils';

interface CellProps {
  itemIndex: number;
  item: RowDef;
  value: string;
  column: TextColumnDef;
}

export function TextCell({ itemIndex, item, value, column }: CellProps) {
  const {
    variant = 'body1Reg',
    width,
    colSpan,
    noWrap,
    align,
    style
  } = column || {};

  function calcPlaceholder() {
    const { placeholder } = column;
    if (isFunction(placeholder)) {
      return placeholder(item, itemIndex);
    }

    return null;
  }

  function getEndAdornment() {
    const { endAdornment } = column;
    if (isFunction(endAdornment)) {
      return endAdornment(item, itemIndex);
    }

    return null;
  }

  function getStartAdornment() {
    const { startAdornment } = column;
    if (isFunction(startAdornment)) {
      return startAdornment(item, itemIndex);
    }

    return null;
  }

  const editable = isFunction(column.editable)
    ? column.editable(item, itemIndex)
    : column.editable;
  const cellClassName = isFunction(column.cellClassName)
    ? column.cellClassName(item, itemIndex)
    : column.cellClassName;

  const renderEditableCall = () => (
    <BaseCell
      className={cellClassName}
      colSpan={colSpan}
      width={width}
      style={style}>
      <Typography variant={variant} align={align} noWrap={noWrap}>
        {value || calcPlaceholder()}
      </Typography>
    </BaseCell>
  );

  return editable ? (
    renderEditableCall()
  ) : (
    <ReadOnlyTextCell
      cellClassName={cellClassName}
      colSpan={colSpan}
      width={width}
      value={value}
      placeholder={calcPlaceholder()}
      variant={variant}
      align={align}
      noWrap={noWrap}
      style={style}
      endAdornment={getEndAdornment()}
      startAdornment={getStartAdornment()}
    />
  );
}
