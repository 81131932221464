import React from 'react';
import './Select.css';
import CWSDropdownMenu from './menu';
import ButtonIcon from '../ButtonIcon';
import './Icon.css';

/**
 * @typedef {import('../../../lib/data-types.js')} ReactDom
 */

/**
 * @typedef {object} SelectItem
 * @property {string} icon
 * @property {string} value
 * @property {string} text
 */

/**
 * Render the icon dropdown
 *
 * @param {object} params
 * @param {string} params.icon
 * @param {string} params.tooltip
 * @param {Function} params.onSelect
 * @param {[SelectItem]} params.list
 * @param params.title
 * @param params.disabled
 * @param params.fixedMenuPos
 * @param params.conditions
 * @param params.position
 * @param params.possiblePositions
 * @param params.active
 * @param params.onToggle
 * @returns {ReactDom}
 */
const CWSIconDropdown = ({
  title,
  icon,
  list,
  disabled,
  fixedMenuPos,
  conditions,
  tooltip,
  onSelect,
  position,
  possiblePositions,
  active,
  onToggle
}) => (
  <div className="cws-icon-dropdown">
    <CWSDropdownMenu
      title={
        title ??
        (() => (
          <ButtonIcon
            active={active}
            icon={icon}
            tooltip={tooltip}
            disabled={disabled}
          />
        ))
      }
      onSelect={(item) => onSelect(item.value)}
      conditions={conditions}
      list={list}
      position={position}
      possiblePositions={possiblePositions}
      disabled={disabled}
      fixedMenuPos={fixedMenuPos}
      onToggle={onToggle}
    />
  </div>
);

export default CWSIconDropdown;
