import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as modalActions from '../action-creators/modal';

export default function useModal() {
  const dispatch = useDispatch();

  const onOpen = useCallback((modalKey, props) => {
    dispatch(modalActions.showModal(modalKey, props));
  }, []);

  const onClose = useCallback((modalKey) => {
    const handler =
      typeof modalKey === 'string'
        ? () => modalActions.hideTargetModal(modalKey)
        : modalActions.hideModal;

    dispatch(handler());
  }, []);

  return { onOpen, onClose };
}
