import React from 'react';

/* eslint-disable max-len */
const IconDownload = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      className="cws-icon-hover"
      cx="17"
      cy="17"
      r="17"
      fill="#767B92"
      fillOpacity="0"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5612 9.75749C13.6952 9.99895 13.6081 10.3033 13.3666 10.4373C11.0596 11.7174 9.5 14.177 9.5 17.0001C9.5 21.1422 12.8579 24.5001 17 24.5001C21.1421 24.5001 24.5 21.1422 24.5 17.0001C24.5 14.177 22.9404 11.7174 20.6334 10.4373C20.3919 10.3033 20.3048 9.99895 20.4388 9.75749C20.5728 9.51603 20.8772 9.42891 21.1186 9.56289C23.7304 11.0122 25.5 13.7991 25.5 17.0001C25.5 21.6945 21.6944 25.5001 17 25.5001C12.3056 25.5001 8.5 21.6945 8.5 17.0001C8.5 13.7991 10.2696 11.0122 12.8814 9.56289C13.1228 9.42891 13.4272 9.51603 13.5612 9.75749Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 9.50009C17.2761 9.50009 17.5 9.72395 17.5 10.0001V20.0001C17.5 20.2762 17.2761 20.5001 17 20.5001C16.7239 20.5001 16.5 20.2762 16.5 20.0001V10.0001C16.5 9.72395 16.7239 9.50009 17 9.50009Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.4751 15.7681C21.6704 15.9633 21.6704 16.2799 21.4751 16.4752L17.3536 20.5967C17.1583 20.792 16.8417 20.792 16.6464 20.5967L12.5249 16.4752C12.3296 16.2799 12.3296 15.9633 12.5249 15.7681C12.7202 15.5728 13.0367 15.5728 13.232 15.7681L17 19.5361L20.768 15.7681C20.9633 15.5728 21.2798 15.5728 21.4751 15.7681Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconDownload;
