import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
        navigate={navigate}
        location={location}
        params={params}
        history={{
          push: (path) => {
            if (typeof path === 'string') {
              navigate(path, { replace: true });
              return;
            }

            navigate(path.pathname, { state: path.state, replace: true });
          },
          replace: (path) => {
            navigate(path, { replace: true });
          },
          goBack: () => navigate(-1),
          goForward: () => navigate(1),
          location
        }}
        match={{ params }}
      />
    );
  }

  return ComponentWithRouterProp;
}
