import { setList } from '../../../data-type/block-group';

export const setCountsForGroupContactsCase = async (
  { getGroups, getGroupContacts, setGroupContacts },
  { count }
) => {
  const groups = await getGroups();
  const groupContacts = getGroupContacts();
  const updatedGroupChat = groups.lists.map((item) => ({
    ...item,
    count: count[item.id]
  }));
  const blockGroups = setList(groupContacts, updatedGroupChat);
  setGroupContacts(blockGroups);
};
