import { bindActionCreators } from 'redux';
import * as storage from '../storage';
import * as actions from '../actions';
import * as messageInputActions from '../../messageInput/actions';
import { clearBadge } from '../../badges';
import { activeChatNotification } from '../../sw/push/services';
import { setReadMessagesInChatCase } from './readMessages';
import { editMessageCase } from './editMessage';

export const setReadMessagesInChat = (chatId) => (dispatch) =>
  setReadMessagesInChatCase(
    {
      readMessagesInChat: storage.readMessagesInChat,
      clearBadge: (prop) => dispatch(clearBadge(prop)),
      activeChatNotification
    },
    { chatId }
  );

export const editMessage = (chatId, message) => (dispatch) =>
  editMessageCase(
    {
      setEditingMessage: bindActionCreators(
        actions.setEditingMessage,
        dispatch
      ),
      setInputFiles: bindActionCreators(
        messageInputActions.setInputFiles,
        dispatch
      ),
      setMsgToEditor: bindActionCreators(actions.setMsgToEditor, dispatch)
    },
    { chatId, message }
  );
