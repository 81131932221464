import { isSupportedBrowserNotifications } from '@/lib/isSupportedBrowserNotifications';

export const changeMutedCase = async ({ changeMuted }, { isMuted }) => {
  if (!isSupportedBrowserNotifications()) {
    return;
  }

  const registration = await navigator.serviceWorker.ready;
  const subscription = await registration.pushManager.getSubscription();

  if (subscription) {
    await changeMuted(subscription.endpoint, isMuted);
  }
};
