import { getScktChl } from '@/components/ChatWidget/useCases/service';

const scktChl = getScktChl();
export const sckt = {
  joinToChat: (chatId) => scktChl.emit('join', { channelId: chatId }),
  subscribeToArchiveChat: (cb) => scktChl.on('archive:toArchive', cb),
  subscribeToUnarchiveChat: (cb) => scktChl.on('archive:toUnarchive', cb),
  subscribeToTypingInfo: (cb) => scktChl.on('typing', cb),
  subscribeToUpdate: (cb) => scktChl.on('update', cb),
  subscribeToNewMsg: (cb) => scktChl.on('message', cb),
  subscribeToRemoveUnsentMessage: (cb) =>
    scktChl.on('unsentMessage:remove', cb),
  subToAddUnreadMsg: (cb) => scktChl.on('unreadMessagesId:add', cb),
  subToClearUnreadMsg: (cb) => scktChl.on('unreadMessagesId:clear', cb),
  subscribeToUpdateMsg: (cb) => scktChl.on('message:update', cb),
  subscribeToRemoveMsg: (cb) => scktChl.on('message:remove', cb),
  subscribeToUpdateInList: (cb) =>
    scktChl.on('update-channel-in-dialog-list', cb),
  subscribeToUpdateInListWithoutSort: (cb) =>
    scktChl.on('update-channel-in-dialog-list:without-sort', cb),
  subscribeToReloadChatList: (cb) => scktChl.on('reload-channel-list', cb),
  subscribeToUpdateLstMsg: (cb) => scktChl.on('lastMsg', cb),
  subscribeToNeedUpdate: (cb) => scktChl.on('need-to-update', cb),
  unsubscribeToTypingInfo: () => scktChl.removeListener('typing'),
  unsubscribeFromNewMsg: () => scktChl.removeListener('message'),
  unsubscribeToUpdateChannel: () => scktChl.removeListener('update'),
  unsubToAddUnreadMsg: () => scktChl.removeListener('unreadMessagesId:add'),
  unsubToClearUnreadMsg: () => scktChl.removeListener('unreadMessagesId:clear'),
  unsubscribeToRemoveMsg: () => scktChl.removeListener('message:remove'),
  unsubscribeFromUpdateMsg: () => scktChl.removeListener('message:update'),
  leaveFromChannel: (chatId) => scktChl.emit('leave', { channelId: chatId }),
  quitFromChannel: (channelId) => scktChl.emit('quit', { channelId }),
  unsubscribeToUpdateInList: () =>
    scktChl.removeListener('update-channel-in-dialog-list'),
  unsubscribeFromUnreadMessageCount: () =>
    scktChl.removeListener('updateUnreadMsgsCount'),
  unsubscribeToUpdateInListWithoutSort: () =>
    scktChl.removeListener('update-channel-in-dialog-list:without-sort'),
  unsubscribeToReloadChatList: () => scktChl.removeListener('reload-chat-list'),
  unsubscribeFromUpdateLstMsg: () => scktChl.removeListener('lastMsg'),
  sendMsg: ({
    channelId,
    msg,
    html,
    files,
    mentions,
    forwardedMessages,
    replyByMessageId,
    deliveryId
  }) =>
    scktChl.emit('message', {
      channelId,
      msg,
      html,
      files,
      mentions,
      forwardedMessages,
      replyByMessageId,
      deliveryId
    }),
  updateMsg: ({
    chatId,
    messageId,
    html,
    msg,
    mentions,
    addedFiles,
    removedFiles,
    deliveryId
  }) =>
    scktChl.emit('message:update', {
      channelId: chatId,
      messageId,
      html,
      msg,
      mentions,
      addedFiles,
      removedFiles,
      deliveryId
    }),
  updateChannel: (channelId, config) =>
    scktChl.emit('update', { channelId, config }),
  restoreAccessToChat: (channelId) => scktChl.emit('restore', { channelId }),
  closeChat: (channelId, cb = () => {}) =>
    scktChl.emit('close:toggle', { channelId }, cb),
  removeFileFromMsg: (channelId, messageId, fileId) =>
    scktChl.emit('file:remove', { channelId, messageId, fileId }),
  removeMessage: (channelId, messageId, mainMessageId) =>
    scktChl.emit('message:remove', { channelId, messageId, mainMessageId }),
  getUnreadMsgsCount: (employeeId) =>
    scktChl.emit('getUnreadMsgsCount', employeeId),
  emitTyping: (channelId, value) =>
    scktChl.emit('typing', { channelId, value }),
  pinChat: (chatId) => scktChl.emit('pin-chat', { chatId }),
  unpinChat: (chatId) => scktChl.emit('unpin-chat', { chatId }),
  subscribeToUpdateLastUnreadMessages: (cb) =>
    scktChl.on('lastUnreadMessage', cb),
  deleteMessageFromBuffer: (messageId) => {
    const buffer = scktChl.sendBuffer;
    const filteredBuffer = buffer.filter((event) => {
      const { data } = event;
      const [eventType = '', payload = {}] = data;
      return !(eventType === 'message' && payload.deliveryId === messageId);
    });
    scktChl.sendBuffer = filteredBuffer;
  }
};
