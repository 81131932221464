import { PropsWithChildren, useState } from 'react';
import { Icon } from '@/components/Icon';
import styles from './InfoBlock.module.scss';

export interface InfoBlockProps extends PropsWithChildren {}

export function InfoBlock({ children = '' }: InfoBlockProps) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div className={styles.root}>
      <span
        data-testid="info-block-trigger"
        onMouseEnter={() => setIsCollapsed(true)}
        onMouseLeave={() => setIsCollapsed(false)}>
        {/* TODO: изменить на ButtonIcon */}
        <Icon
          className={styles.helpIcon}
          iconName="info-fill"
          width={18}
          height={18}
        />
      </span>
      {isCollapsed && <div className={styles.infoContainer}>{children}</div>}
    </div>
  );
}
