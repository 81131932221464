import { Map, fromJS } from 'immutable';

const initState = () => Map();

const add = (data, companyInfo) => data.merge(fromJS(companyInfo));

const toggleBlockMe = (data, { employeeId, isBlocked }) => {
  const index = data
    .get('users')
    .findIndex((user) => user.get('employeeId') === employeeId);

  if (index === -1) return data;

  return data.setIn(['users', index, 'isBlockedMe'], isBlocked);
};

const info = (state = initState(), { type, payload }) => {
  switch (type) {
    case 'COMPANY:GET_INFO:SUCCESS':
      return add(state, payload);
    case 'COMPANY_INFO:TOGGLE_BLOCK_ME':
      return toggleBlockMe(state, payload);
    case 'COMPANY:GET_INFO:CLEAR':
      return initState();
    default:
      return state;
  }
};

export default info;
