export const subscribeToUpdateContactListByInviteCase = async ({
  subToUpdateByInvite
}) => {
  await subToUpdateByInvite();
};

export const unSubscribeToUpdateContactListByInviteCase = ({
  unsubToUpdateByInvite
}) => {
  unsubToUpdateByInvite();
};
