import { useEditableTableContext } from '@/components/EditableTable/EditableTable.context';
import { ColumnHeader, TopPanel } from './components';

export function TableHeader() {
  const { columns, topPanel } = useEditableTableContext();

  return (
    <thead>
      {topPanel && (
        <tr>
          <td colSpan={columns.length}>
            <TopPanel>{topPanel}</TopPanel>
          </td>
        </tr>
      )}
      <tr>
        {columns.map((column) => (
          <ColumnHeader key={column.accessor} column={column} />
        ))}
      </tr>
    </thead>
  );
}
