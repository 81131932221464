import React from 'react';

/* eslint-disable max-len */
const IconChat1Circle = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      className="cws-icon-hover"
      cx="17"
      cy="17"
      r="17"
      fill="#767B92"
      fillOpacity="0"
    />
    <rect
      className="cws-icon-main-circle-stroke"
      x="0.5"
      y="0.5"
      width="33"
      height="33"
      rx="16.5"
      stroke="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      d="M8.2036 13.6366C6.92805 14.1492 6.58984 15.7976 7.5605 16.7711L10.8601 20.0801L21.8189 12.1811L13.9199 23.1399L17.2289 26.4395C18.2024 27.4102 19.8508 27.072 20.3634 25.7964L26.8566 9.63868C27.4866 8.071 25.929 6.5134 24.3613 7.14339L8.2036 13.6366Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconChat1Circle;
