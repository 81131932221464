/**
 * Prepare data for request
 *
 * @param {Function} getter
 * @param {Function} transform
 * @param {Function} httpReq - async
 * @returns {Function}
 */
const initRequest =
  (getter, transform, httpReq) =>
  async (state, newParams = {}) => {
    const getters = getter(state);

    const params = { ...getters, ...newParams };
    return httpReq(transform(params));
  };

export { initRequest };
