export const makePurchaseFilter = ({
  isShow = false,
  filterData = null,
  isSubscribed = false
} = {}) => ({
  type: 'blockPurchaseFilter',
  isShow,
  filterData,
  isSubscribed
});
