import {
  SET_INPUT_BLOCK,
  SET_TEXT_INPUT_BLOCK,
  SET_FILES_INPUT_BLOCK,
  CLEAR_INPUT_BLOCK,
  SET_LOADING_FILES_IDS_INPUT_BLOCK,
  REMOVE_LOADING_FILE_ID_INPUT_BLOCK
} from './constants';

export const setInputBlock = (blockInput) => ({
  type: SET_INPUT_BLOCK,
  payload: blockInput
});

export const setInputText = (text) => ({
  type: SET_TEXT_INPUT_BLOCK,
  payload: { text }
});

export const setInputFiles = (files) => ({
  type: SET_FILES_INPUT_BLOCK,
  payload: { files }
});

export const clearInputBlock = () => ({
  type: CLEAR_INPUT_BLOCK
});

export const setInputLoadingFilesIds = (ids) => ({
  type: SET_LOADING_FILES_IDS_INPUT_BLOCK,
  payload: { ids }
});

export const removeInputLoadingFileId = (id) => ({
  type: REMOVE_LOADING_FILE_ID_INPUT_BLOCK,
  payload: { id }
});
