import { makeCommand, makeEvent } from 'core/data/messageBus/message';
import { initMessage } from 'core/data/messageBus/frontend';
import { debounce } from '../../../../utils/debounce';

const NS = 'ADVANCED_SEARCH';

const setSearchQueryCMD = makeCommand(NS, 'SET_SEARCH_QUERY');
const repeatSearchCMD = makeCommand(NS, 'REPEAT_SEARCH');
const setDateRangeCMD = makeCommand(NS, 'SET_DATE_RANGE');
const toggleSortDirCMD = makeCommand(NS, 'TOGGLE_SORT_DIRECTION');
const toggleTagCMD = makeCommand(NS, 'TOGGLE_TAG');
const disableTagCMD = makeCommand(NS, 'DISABLE_TAG');
const resetSearchQueryCMD = makeCommand(NS, 'RESET_SEARCH_QUERY');
const loadMoreMsgsCMD = makeEvent(NS, 'LOAD_MORE_MESSAGES');
const updatedSearchQueryEVT = makeEvent(NS, 'UPDATED_SEARCH_QUERY');

export const setSearchQuery = initMessage(setSearchQueryCMD);
export const setSearchQueryDebounced = {
  ...setSearchQuery,
  emit: debounce(setSearchQuery.emit, 300)
};
export const repeatSearch = initMessage(repeatSearchCMD);
export const setDateRange = initMessage(setDateRangeCMD);
export const toggleSortDir = initMessage(toggleSortDirCMD);
export const toggleTag = initMessage(toggleTagCMD);
export const disableTag = initMessage(disableTagCMD);
export const resetSearchQuery = initMessage(resetSearchQueryCMD);
export const loadMoreMsgs = initMessage(loadMoreMsgsCMD);
export const updatedSearchQuery = initMessage(updatedSearchQueryEVT);
