import React from 'react';

/* eslint-disable max-len */
function IconEmoji() {
  return (
    <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.28.379a8.81 8.81 0 0 0-4.035 1.4c-.714.474-1.523 1.213-2.019 1.842C1.269 4.835.676 6.214.429 7.8.387 8.072.375 8.326.375 9c0 .674.012.928.054 1.2.249 1.596.849 2.984 1.815 4.2.291.366.99 1.065 1.356 1.356 1.216.966 2.598 1.563 4.2 1.815.494.077 1.906.077 2.4 0 1.602-.252 2.984-.849 4.2-1.815.366-.291 1.065-.99 1.356-1.356.966-1.216 1.563-2.598 1.815-4.2.077-.494.077-1.906 0-2.4-.252-1.602-.846-2.979-1.814-4.2-.305-.384-1.063-1.133-1.432-1.413C13.089 1.246 11.838.703 10.35.461 9.963.398 9.761.384 9.15.376a17.653 17.653 0 0 0-.87.003m1.725 1.343c2.088.307 3.862 1.402 5.05 3.119.655.948 1.072 2.042 1.239 3.259.051.368.051 1.432 0 1.8-.165 1.199-.557 2.246-1.194 3.189-1.185 1.755-2.945 2.861-5.08 3.192-.524.081-1.621.073-2.142-.016-1.268-.217-2.322-.664-3.303-1.402-1.581-1.189-2.59-2.934-2.869-4.963-.051-.368-.051-1.432 0-1.8.266-1.935 1.191-3.606 2.656-4.798a7.453 7.453 0 0 1 3.963-1.62c.359-.037 1.305-.014 1.68.04M6.328 5.589a1.051 1.051 0 0 0-.685.59c-.065.138-.078.209-.078.422 0 .228.011.277.098.454.114.231.276.39.513.501.141.066.211.079.424.079s.283-.013.424-.079a1.03 1.03 0 0 0 .513-.501c.087-.177.098-.226.098-.454a.973.973 0 0 0-.27-.717c-.234-.259-.698-.391-1.037-.295m4.8 0a1.051 1.051 0 0 0-.685.59c-.065.138-.078.209-.078.422 0 .228.011.277.098.454.114.231.276.39.513.501.141.066.211.079.424.079s.283-.013.424-.079a1.03 1.03 0 0 0 .513-.501c.087-.177.098-.226.098-.454a.973.973 0 0 0-.27-.717c-.234-.259-.698-.391-1.037-.295m-5.593 4.428a.654.654 0 0 0-.313.871c.081.18.712.799 1.078 1.059a5.247 5.247 0 0 0 1.86.824c.362.077 1.318.077 1.68 0a5.23 5.23 0 0 0 1.86-.825c.364-.257.996-.878 1.078-1.058.198-.436-.105-.925-.575-.927-.231-.001-.357.069-.597.335-.967 1.072-2.306 1.495-3.52 1.113-.592-.186-1.077-.495-1.582-1.006-.195-.196-.398-.375-.457-.399a.713.713 0 0 0-.512.013"
        fill="#5B5F73"
        fillRule="evenodd"
      />
    </svg>
  );
}
/* eslint-enable max-len */
export default IconEmoji;
