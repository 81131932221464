/* eslint-disable max-len */
import React from 'react';

const ArrowDown = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.45958 13.8096C9.20574 14.0635 8.79419 14.0635 8.54034 13.8096L1.04034 6.30962C0.786503 6.05578 0.786503 5.64422 1.04034 5.39038C1.29418 5.13654 1.70574 5.13654 1.95958 5.39038L8.99996 12.4308L16.0403 5.39038C16.2942 5.13654 16.7057 5.13654 16.9596 5.39038C17.2134 5.64422 17.2134 6.05578 16.9596 6.30962L9.45958 13.8096Z"
      fill="#5B5F73"
    />
  </svg>
);

export default ArrowDown;

/* eslint-enable max-len */
