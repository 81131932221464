import { nanoid } from 'nanoid';

export const addMessageToWaitingCase = (
  { getChatInputLoadingFilesIds, clearInputBlock, addWaitingMessage },
  { message }
) => {
  const loadingFilesIds = getChatInputLoadingFilesIds();

  const waitingMessage = {
    ...message,
    id: nanoid(),
    forwardedMessages: null,
    waitingFiles: loadingFilesIds
  };

  addWaitingMessage(waitingMessage);
  clearInputBlock();
};
