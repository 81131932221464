function openBrowserTab(url) {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    href: url
  }).click();
}

export function linkClickHandler() {
  openBrowserTab('/file-storage/chat');
}

export const filesErrorCase = ({ setErrorMessage }, { key, params }) => {
  const linkKey = key === 'Storage space limit' ? 'increase_storage' : '';

  setErrorMessage({ key, params, linkClickHandler, linkKey });
};
