import { curry } from 'ramda';
// NOTE: Use only in browser

const commonHandle = curry((name, cb) => {
  const extractParams = (e) => cb(e.detail);
  document.addEventListener(name, extractParams); // eslint-disable-line no-undef
  return () => {
    document.removeEventListener(name, extractParams); // eslint-disable-line no-undef
  };
});

export const initEvent = curry((namespace, eventName) => {
  const name = `EVENT:${namespace}:${eventName}`;

  const emit = (params) => {
    const customEvent = new CustomEvent(name, { detail: params }); // eslint-disable-line no-undef
    // console.log(`emit "${name}" with params: `, params);
    document.dispatchEvent(customEvent); // eslint-disable-line no-undef
  };

  return {
    type: 'EventFrontend',
    emit,
    handle: commonHandle(name)
  };
});

export const initCommand = curry((namespace, eventName) => {
  const name = `COMMAND:${namespace}:${eventName}`;

  const emit = (params) => {
    const customEvent = new CustomEvent(name, { detail: params }); // eslint-disable-line no-undef
    // console.log(`emit "${name}" with params: `, params);
    document.dispatchEvent(customEvent); // eslint-disable-line no-undef
  };

  return {
    type: 'CommandFrontend',
    emit,
    handle: commonHandle(name)
  };
});

export const emit = curry((message, params) => {
  const customEvent = new CustomEvent(message.name, { detail: params }); // eslint-disable-line no-undef
  // console.log(`emit "${message.name}" with params: `, params);
  document.dispatchEvent(customEvent); // eslint-disable-line no-undef
});

export const handle = curry((message, cb) => commonHandle(message.name, cb));

export const initMessage = (message) => ({
  emit: (params) => emit(message, params),
  handle: handle(message)
});
