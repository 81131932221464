import { isNil } from 'ramda';
import { makeChatInputItem } from '../dataType';

export const saveDataCase = (
  { setData, clearData, dataToView = makeChatInputItem },
  { data }
) => {
  if (!isNil(data.text)) {
    setData({ chatId: data.chatId, data: dataToView(data) });
  } else {
    clearData(data.chatId);
  }
};
