import { ToastStorage, toastStorage } from './ToastStorage';
import {
  ToastId,
  ToastOptions,
  ToastContent,
  ClearWaitingQueueParams,
  OnChangeCallback,
  GeneralToastOptions
} from './types';
import { Toast } from './toast';

type OnCallToastCallback = (
  content: ToastContent,
  options?: ToastOptions
) => ToastId;
type InnerToast = Record<string, ToastOptions>;
type OnCallToastByKeyCallback<T> = (
  content: ToastContent,
  options?: ToastOptionsWithKey<T>
) => ToastId;

interface BaseToast {
  dismiss: (id?: ToastId) => void;
  clearWaitingQueue: (params?: ClearWaitingQueueParams) => void;
  onChange: (cb: OnChangeCallback) => void;
  success: OnCallToastCallback;
  info: OnCallToastCallback;
  warning: OnCallToastCallback;
  error: OnCallToastCallback;
}

interface ToastOptionsWithKey<T> extends ToastOptions {
  key?: T extends { toasts: unknown } ? keyof T['toasts'] : never;
}

interface ExtendToastOptions extends ToastOptions {
  toasts?: InnerToast;
}

type ComputedToast<BASE, T> = {
  [K in keyof T as K extends keyof BASE ? never : K]: T[K] extends {
    toasts: unknown;
  }
    ? OnCallToastByKeyCallback<T[K]>
    : OnCallToastCallback;
} & BASE;

export interface Configurator<ConfiguredToasts = BaseToast> {
  setContent(content: ToastContent): this;
  options(options: GeneralToastOptions): this;
  extend: <Type extends string, Options extends ExtendToastOptions>(
    type: Type extends keyof ConfiguredToasts ? never : Type,
    options: Options
  ) => Configurator<ConfiguredToasts & Record<Type, Options>>;
  toast: ComputedToast<BaseToast, ConfiguredToasts>;
  handleClickOutside(): void;
}

export function ToastConfigurator(): Configurator {
  const storage: ToastStorage = toastStorage;
  const toast = new Toast(storage);

  toast.onChange((payload) => {
    switch (payload.status) {
      case 'removed':
        storage.delete(payload.id);
        break;
      default:
        break;
    }
  });

  return {
    toast,
    options(options) {
      toast.setGeneralOptions(options);

      return this;
    },
    setContent(content) {
      toast.setContent(content);
      return this;
    },
    // @ts-ignore
    extend(type: string, options: ExtendToastOptions) {
      const { toasts, ...commonOptions } = options;

      Object.assign(toast, {
        [type]: (
          content: ToastContent,
          callOptions: ToastOptionsWithKey<typeof options> | undefined = {}
        ) => {
          const { key } = callOptions;

          if (toasts && key && key in toasts) {
            const toastOptions = toasts[key];
            const callOptionsWithoutKey = { ...callOptions };
            delete callOptionsWithoutKey.key;

            const mergedOptions: ToastOptions = {
              ...commonOptions,
              ...toastOptions,
              ...callOptionsWithoutKey
            };

            return toast.show(content, mergedOptions);
          }

          const mergedOptions: ToastOptions = {
            ...commonOptions,
            ...callOptions
          };

          return toast.show(content, mergedOptions);
        }
      });

      return this;
    },
    handleClickOutside() {
      storage
        .getAllByCloseClickOutside(true)
        .forEach((toastInfo) => toast.dismiss(toastInfo.id));
    }
  };
}
