import React from 'react';
import HideCallIcon from '@/img/videochat/hide-call.svg';
import { useVideoChatContext } from '../../hooks';

const ButtonHideVideoChat = () => {
  const { hideWindow } = useVideoChatContext();

  return (
    <button
      type="button"
      className="video-chat__btn-hide-call"
      onClick={hideWindow}>
      <img
        className="video-chat__btn-img_hide_call"
        src={HideCallIcon}
        draggable={false}
        alt=""
      />
    </button>
  );
};

export default ButtonHideVideoChat;
