import { getScktChl } from '@/components/ChatWidget/useCases/service';
import request from '../lib/request';

export const channelSocket = getScktChl();

export const findChannel = (id) => request.get(`/api/channel/${id}`);
export const findMessages = (id) => request.get(`/api/channel/${id}/messages`);
export const createChannel = (channelName, employeesId, membersRoles) =>
  request.post(`/api/channel`, { channelName, employeesId, membersRoles });

export const getUnsentFiles = (id) =>
  request.get(`/api/channel/${id}/unsent-files`);
export const removeUnsentFiles = (channelId, id) =>
  request.delete(`/api/channel/${channelId}/unsent-files/${id}`);
export const attachUnsentFile = (channelId, fileName, file) =>
  request.sendFile(`/api/channel/${channelId}/unsent-files/${fileName}`, file);

export const attachUnsentFileFromStorage = (channelId, filesId) =>
  request.post(`/api/channel/${channelId}/unsent-files`, { filesId });

export const copyFileToStorage = (channelId, fileId) =>
  request.post(`/api/channel/${channelId}/copy-file`, { fileId });

export const getForwardMessages = (id, msgs) =>
  request.post(`/api/channel/${id}/forward_messages`, { msgs });

export const removeFile = (channelId, messageId, fileId) =>
  channelSocket.emit('file:remove', { channelId, messageId, fileId });

export const addMessage = (channelId, msg, files, forwardedMessages) =>
  channelSocket.emit('message', { channelId, msg, files, forwardedMessages });

export const updateChannel = (channelId, config) =>
  channelSocket.emit('update', { channelId, config });

export const joinToChannel = (channelId) =>
  channelSocket.emit('join', { channelId });
export const leaveFromChannel = (channelId) =>
  channelSocket.emit('leave', { channelId });

export const restoreAccesstoChannel = (channelId) =>
  channelSocket.emit('restore', { channelId });

export const subToAddUnreadMsg = (cb) =>
  channelSocket.on('unreadMessagesId:add', cb);
export const unsubToAddUnreadMsg = () =>
  channelSocket.removeListener('unreadMessagesId:add');

export const subToClearUnreadMsg = (cb) =>
  channelSocket.on('unreadMessagesId:clear', cb);
export const unsubToClearUnreadMsg = () =>
  channelSocket.removeListener('unreadMessagesId:clear');

export const subscribeToNewMsg = (cb) => channelSocket.on('message', cb);
export const unsubscribeFromNewMsg = () =>
  channelSocket.removeListener('message');

export const subscribeToRemoveMsg = (cb) =>
  channelSocket.on('message:remove', cb);
export const unsubscribeToRemoveMsg = () =>
  channelSocket.removeListener('message:remove');
export const removeMsg = (channelId, messageId, mainMessageId) =>
  channelSocket.emit('message:remove', { channelId, messageId, mainMessageId });

export const updateMsg = (channelId, messageId, data = {}) =>
  channelSocket.emit('message:update', { channelId, messageId, ...data });

export const addBookmarkToMsg = (channelId, messageId) =>
  channelSocket.emit('message:bookmark:add', { channelId, messageId });
export const removeBookmarkFromMsg = (channelId, messageId) =>
  channelSocket.emit('message:bookmark:remove', { channelId, messageId });

export const subscribeToUpdateMsg = (cb) =>
  channelSocket.on('message:update', cb);
export const unsubscribeFromUpdateMsg = () =>
  channelSocket.removeListener('message:update');

export const subscribeToUpdateChannel = (cb) => channelSocket.on('update', cb);

export const unsubscribeToUpdateChannel = () =>
  channelSocket.removeListener('update');

export const quitFromChannel = (channelId) =>
  channelSocket.emit('quit', { channelId });

export const closeChannel = (channelId, cb = () => {}) =>
  channelSocket.emit('close:toggle', { channelId }, cb);
