import { interval } from 'd3-timer';

const initializeAudioAnalyzer = (audioTrack) => {
  const audioCtx = new AudioContext();
  const mediaStream = new MediaStream([audioTrack.clone()]);
  const audioSource = audioCtx.createMediaStreamSource(mediaStream);

  const analyser = audioCtx.createAnalyser();
  analyser.smoothingTimeConstant = 0.2;
  analyser.fftSize = 256;

  audioSource.connect(analyser);

  const stopAllMediaStreamTracks = () =>
    mediaStream.getTracks().forEach((track) => track.stop());

  return { analyser, stopAnalyser: stopAllMediaStreamTracks };
};

function calcVolume(analyser, sampleArray) {
  analyser.getByteFrequencyData(sampleArray);
  let values = 0;

  const { length } = sampleArray;
  for (let i = 0; i < length; i++) {
    values += sampleArray[i];
  }

  return Math.min(14, Math.max(0, Math.log10(values / length / 3) * 7));
}

const getVolumeEvtName = (streamId) => `volume-[${streamId}]`;

export function audioAnalyserEventDispatcher(stream, { node, onVolume } = {}) {
  const audioTrack = stream?.getAudioTracks()[0];

  if (!audioTrack) {
    return null;
  }

  const evtName = getVolumeEvtName(stream.id);
  const { analyser, stopAnalyser } = initializeAudioAnalyzer(audioTrack);
  const sampleArray = new Uint8Array(analyser.frequencyBinCount);
  const $el = node || document;
  const timer = interval(() => {
    const volume = audioTrack.enabled ? calcVolume(analyser, sampleArray) : 0;

    $el.dispatchEvent(
      new CustomEvent(evtName, { detail: { trackId: audioTrack.id, volume } })
    );
    $el.addEventListener(evtName, onVolume);
  }, 100);

  return () => {
    $el.removeEventListener(evtName, onVolume);
    stopAnalyser();
    timer.stop();
  };
}
