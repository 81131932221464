import React from 'react';

/* eslint-disable max-len */
function IconCopy() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.921021 2.58799C0.921021 1.67672 1.65975 0.937988 2.57102 0.937988H11.7203C12.0793 0.937988 12.3703 1.229 12.3703 1.58799C12.3703 1.94697 12.0793 2.23799 11.7203 2.23799H2.57102C2.37772 2.23799 2.22102 2.39469 2.22102 2.58799V11.9045C2.22102 12.2635 1.93001 12.5545 1.57102 12.5545C1.21204 12.5545 0.921021 12.2635 0.921021 11.9045V2.58799ZM4.04193 5.85167C4.04193 4.94039 4.78066 4.20167 5.69193 4.20167H15.4426C16.3539 4.20167 17.0926 4.9404 17.0926 5.85167V15.1337C17.0926 16.045 16.3539 16.7837 15.4426 16.7837H5.69193C4.78066 16.7837 4.04193 16.045 4.04193 15.1337V5.85167ZM5.69193 5.50167C5.49862 5.50167 5.34193 5.65837 5.34193 5.85167V15.1337C5.34193 15.327 5.49863 15.4837 5.69193 15.4837H15.4426C15.6359 15.4837 15.7926 15.327 15.7926 15.1337V5.85167C15.7926 5.65837 15.6359 5.50167 15.4426 5.50167H5.69193Z"
        fill="#7F869E"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconCopy;
