import { useCallback, useLayoutEffect, useRef } from 'react';

type Callback<A extends any[], R> = (...args: A) => R;

export function useEventCallback<A extends any[], R>(
  cb: Callback<A, R>
): Callback<A, R> {
  const ref = useRef(cb);

  useLayoutEffect(() => {
    ref.current = cb;
  });

  return useCallback((...args) => {
    const { current } = ref;

    return current(...args);
  }, []);
}
