import { useCallback, useLayoutEffect, useRef } from 'react';
import { noop } from '../lib/utils';
import { canUseDom } from '../lib/dom';

interface EventListenerHandle {
  add: (element: HTMLElement | Document | Window) => void;
  remove: () => void;
}

export function useEventListener<T extends keyof GlobalEventHandlersEventMap>(
  event: T,
  _cb:
    | false
    | null
    | undefined
    | ((ev: GlobalEventHandlersEventMap[T]) => void),
  _options?: AddEventListenerOptions
): EventListenerHandle;

export function useEventListener<T extends Event>(
  event: string,
  _cb: false | null | undefined | ((ev: T) => void),
  _options?: AddEventListenerOptions
): EventListenerHandle;

export function useEventListener<
  E extends Event,
  K extends keyof GlobalEventHandlersEventMap
>(
  event: string | K,
  _cb: false | null | undefined | ((ev: E) => void),
  _options?: AddEventListenerOptions
): EventListenerHandle {
  const cbRef = useRef(_cb);

  useLayoutEffect(() => {
    cbRef.current = _cb;
  }, [_cb]);

  const cb = useCallback((e: any) => cbRef.current && cbRef.current(e), []);

  const detach = useRef(noop);

  const remove = useCallback(() => {
    detach.current();
    detach.current = noop;
  }, []);

  const add = useCallback(
    (element: HTMLElement | Document | Window) => {
      if (!canUseDom) {
        return;
      }

      remove();
      if (!element) {
        return;
      }
      const options = { ..._options };

      element.addEventListener(event, cb, options);
      detach.current = () => element.removeEventListener(event, cb, options);
    },
    [_options, cb, event, remove]
  );

  return { add, remove };
}
