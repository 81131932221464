import { assoc, isNil } from 'ramda';
import { REMOVE_FORWARD_MSG, SET_FORWARD_MSGS } from './constants';
import {
  addToList,
  getList,
  hasList,
  makeBLForward,
  makeListsOfBLForward,
  removeFromList,
  removeItem as removeForwardMessage
} from './block-list-forward';

export const defaultState = () => ({
  blockForward: makeListsOfBLForward()
});

const setBlockForward = (chatWidget, value) =>
  assoc('blockForward', value, chatWidget);

export const reducers = {
  [SET_FORWARD_MSGS]: (chatWidget, { toChatId, fromChatId, messages }) => {
    let bForward;
    if (isNil(fromChatId)) {
      bForward = removeFromList(chatWidget.blockForward, toChatId);
    } else {
      bForward = addToList(
        chatWidget.blockForward,
        toChatId,
        makeBLForward({ list: messages, fromChatId })
      );
    }
    return setBlockForward(chatWidget, bForward);
  },
  [REMOVE_FORWARD_MSG]: (chatWidget, { toChatId, messageId }) => {
    const { blockForward } = chatWidget;
    if (!hasList(blockForward, toChatId)) return chatWidget;
    return setBlockForward(
      chatWidget,
      addToList(
        chatWidget.blockForward,
        toChatId,
        removeForwardMessage(getList(blockForward, toChatId), messageId)
      )
    );
  }
};
