import { socket } from '../../../../modules/contact/storage';

export const toggleBlock = (employeeId) =>
  socket.emit('toggle-block:contact', { employeeId });

export const subToBlockedContact = (cb) =>
  socket.on('toggle-blocked:contact', cb);
export const subToBlockedMe = (cb) => socket.on('toggle-blocked:me', cb);

export const unsubFromBlockedContact = () =>
  socket.removeListener('toggle-blocked:contact');
export const unsubFromBlockedMe = () =>
  socket.removeListener('toggle-blocked:me');
