.root {
  width: 100%;

  :global(.MuiInputBase-root) {
    color: inherit;
    font: inherit;

    input {
      color: inherit;
      font: inherit;
      padding: var(--indent-xs) 16px;
      height: auto;
      line-height: 18px;

      &::placeholder {
        color: var(--color-gray-600);
        opacity: 1;
      }
    }

    :global(.MuiOutlinedInput-notchedOutline) {
      border-color: var(--color-gray-500);
    }

    &:global(.MuiInputBase-adornedStart) {
      input {
        padding: var(--indent-xs) 16px var(--indent-xs) 2px;
      }
    }

    &:global(.MuiOutlinedInput-root.Mui-disabled) {
      background-color: var(--color-gray-300);

      & > fieldset {
        border: 1px solid transparent;
        border-radius: 4px;
        color: var(--color-gray-700);
      }

      & > input {
        -webkit-text-fill-color: var(--color-gray-700);
      }

      & > input::placeholder {
        color: var(--color-gray-600);
      }
    }

    &:global(.MuiOutlinedInput-root.Mui-error) {
      & > fieldset {
        border: 1px solid var(--color-red-error);
        border-radius: 4px;
      }
    }

    &:hover {
      :global(.MuiOutlinedInput-notchedOutline) {
        border-color: var(--color-blue-200);
      }
    }

    &:global(.MuiOutlinedInput-root.Mui-focused) {
      & > fieldset {
        border: 1px solid var(--color-blue-500);
        border-radius: 4px;
        box-shadow: 0 0 5px rgb(35 105 241 / 31%);
      }
    }
  }
}

.required::after {
  content: ' *';
  color: var(--color-red-error);
}

.errorMessage {
  margin-top: 2px;
  color: var(--color-red-error);
}

.background {
  background-color: var(--color-white);
}
