import React from 'react';
import { defMulti, addHandler, call } from '../../../../../lib/multi';
import ButtonIcon from '../../../ButtonIcon';
import CWSIconDropdown from '../../../Dropdown/Icon';

const multiExtraBtns = defMulti('extraBtns', ({ data }) => data.type);

addHandler(multiExtraBtns, 'none', () => null);

addHandler(multiExtraBtns, 'dropdown', ({ data }) => (
  <CWSIconDropdown
    icon={data.icon}
    onSelect={data.onSelect}
    list={data.list}
    conditions={data.conditions}
    fixedMenuPos={false}
    position={data.position}
    possiblePositions={data.possiblePositions}
    onToggle={data.onToggle}
  />
));

addHandler(multiExtraBtns, 'regular', ({ data }) => (
  <ButtonIcon icon={data.icon} tooltip={data.tooltip} onClick={data.onClick} />
));

const ExtraBtn = (props) => call(props, multiExtraBtns);
export default ExtraBtn;
