import React from 'react';
import { useTranslate } from '@hooks';
import { Typography } from '@link/react-components';
import cn from 'classnames';

export function MemberCardExtra({ membersNumber, className }) {
  const t = useTranslate();
  return (
    <div
      className={cn(
        className,
        'video-chat__video-container',
        'video-chat__extra-members_container'
      )}>
      <Typography variant="h1" className="video-chat__extra-members">{`${t(
        'videoChat.extraMembers'
      )} ${membersNumber}`}</Typography>
    </div>
  );
}
