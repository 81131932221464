import React from 'react';

/* eslint-disable max-len */
function IconPublish() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8399 3.1412C12.6867 2.90929 12.369 2.86902 12.1487 3.03851C11.9284 3.208 11.8892 3.52297 12.0397 3.75671C12.5517 4.55238 12.8694 5.46019 12.9636 6.40647C13.0744 7.52013 12.8713 8.64258 12.377 9.64672C11.8828 10.6509 11.1173 11.4966 10.1673 12.0881C9.21717 12.6795 8.12044 12.9932 7.00128 12.9935C5.88211 12.9938 4.78521 12.6808 3.8348 12.0898C2.88439 11.4988 2.11844 10.6535 1.62367 9.64966C1.12891 8.64579 0.925112 7.52345 1.03535 6.40973C1.12902 5.4634 1.44624 4.55542 1.95784 3.75946C2.10813 3.52564 2.06876 3.2107 1.84836 3.04133C1.62797 2.87196 1.31033 2.9124 1.15723 3.14439C0.53263 4.09082 0.145866 5.17715 0.0336779 6.31058C-0.0950737 7.61135 0.142955 8.92218 0.720809 10.0946C1.29866 11.2671 2.19326 12.2543 3.30328 12.9446C4.41331 13.6348 5.69443 14.0004 7.00155 14.0001C8.30868 13.9997 9.58959 13.6334 10.6992 12.9425C11.8089 12.2517 12.7029 11.264 13.2802 10.0912C13.8574 8.91843 14.0947 7.60747 13.9652 6.30677C13.8524 5.1734 13.4651 4.08728 12.8399 3.1412Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.42481 4.41544L6.64214 1.19811C6.83957 1.00068 7.15967 1.00068 7.35711 1.19811L10.5744 4.41544C10.7719 4.61287 10.7719 4.93297 10.5744 5.13041C10.377 5.32784 10.0569 5.32784 9.85948 5.13041L7.50518 2.77611L7.50518 10.1111C7.50518 10.3904 7.27883 10.6167 6.99962 10.6167C6.72041 10.6167 6.49407 10.3904 6.49407 10.1111L6.49407 2.77611L4.13977 5.13041C3.94234 5.32784 3.62224 5.32784 3.42481 5.13041C3.22738 4.93297 3.22738 4.61287 3.42481 4.41544Z"
        fill="white"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconPublish;
