import React from 'react';

/* eslint-disable max-len */
function IconUnpin() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className="cws-icon-hover"
        cx="11"
        cy="11"
        r="11"
        fill="#767B92"
        fillOpacity="0"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3065 4.28853C12.6162 3.89086 13.1345 3.97066 13.3942 4.23037L17.7696 8.60575C18.0293 8.86545 18.1091 9.38381 17.7115 9.69353C17.2321 10.0669 16.6703 10.0087 16.3627 9.9769C16.3382 9.97437 16.3154 9.97201 16.2944 9.97005C15.9288 9.9359 15.8521 9.96213 15.7769 10.0374L13.1412 12.673C13.508 13.3118 13.527 13.9457 13.3974 14.4759C13.2536 15.0634 12.9333 15.5096 12.7601 15.7208C12.4632 16.083 11.958 16.0518 11.6758 15.7696L9.42102 13.5148L6.2386 16.6972C6.01079 16.925 5.64145 16.925 5.41364 16.6972C5.18583 16.4694 5.18583 16.1001 5.41364 15.8723L8.59606 12.6898L6.23043 10.3242C5.94821 10.042 5.91695 9.5368 6.27916 9.23985C6.49037 9.0667 6.93659 8.74635 7.52413 8.60264C8.05425 8.47298 8.68822 8.49197 9.32696 8.85879L11.9626 6.22314C12.0379 6.14789 12.0641 6.07119 12.0299 5.70564C12.028 5.68459 12.0256 5.66176 12.0231 5.63732C11.9913 5.32972 11.9331 4.76789 12.3065 4.28853ZM12.1254 14.5693C12.1814 14.4576 12.2314 14.3325 12.2641 14.1987C12.3426 13.8777 12.328 13.4958 12.0104 13.0746C11.7989 12.7942 11.7939 12.3704 12.0755 12.0888L14.9519 9.21243C15.4107 8.75358 15.9673 8.77032 16.3143 8.80033L13.1997 5.6857C13.2297 6.03267 13.2464 6.58925 12.7876 7.0481L9.91119 9.92447C9.62957 10.2061 9.20581 10.2011 8.92536 9.98959C8.50422 9.67198 8.12226 9.6574 7.80133 9.7359C7.66746 9.76864 7.54235 9.81859 7.43069 9.87455L12.1254 14.5693Z"
        fill="black"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconUnpin;
