import { useCallback } from 'react';
import * as modalActions from '@/action-creators/modal';
import { useActions } from './useActions';

type Result<Props> = [(props?: Props) => void, () => void];

export function useKeyModal<Props = unknown>(modalKey: string): Result<Props> {
  const { showModal, hideTargetModal } = useActions(modalActions);

  const show = useCallback(
    (props?: Props) => {
      showModal(modalKey, props);
    },
    [modalKey, showModal]
  );

  const hide = useCallback(() => {
    hideTargetModal(modalKey);
  }, [hideTargetModal]);

  return [show, hide];
}
