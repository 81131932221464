import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import cn from 'classnames';
import { useLayer } from 'react-laag';
import { Icon } from '@/components/Icon';
import { Checkbox } from '@/components/Checkbox';
import { Typography } from '@/components/Typography';
import styles from './Filter.module.scss';
import type { FilterOptionProps, FilterProps } from './Filter.types';

function FilterOption({ label, onChange, checked }: FilterOptionProps) {
  return (
    <li className={styles.filterOptionItem}>
      <Checkbox
        id={label}
        checked={checked}
        onChange={onChange}
        label={label}
      />
    </li>
  );
}
export function Filter({
  options,
  onCheck,
  clearOptionsChecked,
  headerTitle,
  isShowFilterIcon = true,
  className
}: FilterProps) {
  const [isShow, setIsShow] = useState(false);

  const onToggle = useCallback(() => {
    setIsShow((prevState) => !prevState);
  }, []);

  const isShowCloseButton = !options.every((option) => !option.checked);

  const filterRef = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState<number | string>();

  useLayoutEffect(() => {
    if (filterRef.current) {
      setWidth(filterRef.current.getBoundingClientRect().width);
    }
  }, []);

  const onHide = () => {
    setIsShow(false);
  };

  const { layerProps, triggerProps, renderLayer } = useLayer({
    ResizeObserver,
    isOpen: isShow,
    placement: 'bottom-center',
    overflowContainer: true,
    container: filterRef.current!,
    arrowOffset: 10,
    triggerOffset: 5,
    containerOffset: 5,
    onOutsideClick: onHide
  });

  const onClearOption = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    clearOptionsChecked();
  };

  return (
    <div ref={filterRef} className={className}>
      <div
        role="presentation"
        className={cn(styles.headerContainer, {
          [styles.selectedHeader]: isShow
        })}
        onClick={onToggle}
        {...triggerProps}>
        {isShowFilterIcon && (
          <Icon
            iconName="filter"
            width={12}
            height={12}
            className={styles.filterIcon}
          />
        )}

        <Typography variant="body1Reg" className={styles.headerTextColor}>
          {headerTitle}
        </Typography>
        {isShowCloseButton ? (
          <button
            type="button"
            onClick={onClearOption}
            className={styles.closeIcon}>
            <Icon iconName="close" width={18} height={18} />
          </button>
        ) : (
          <Icon iconName="arrow-down" height={18} width={18} />
        )}
      </div>

      {isShow &&
        renderLayer(
          <div
            ref={layerProps.ref}
            style={{ ...layerProps.style, zIndex: 1051, minWidth: width }}>
            <ul className={styles.filterOptionList}>
              {options.map((option) => (
                <FilterOption
                  key={`${option.label} - ${option.value}`}
                  checked={option.checked}
                  label={option.label}
                  onChange={() => onCheck(option.value)}
                />
              ))}
            </ul>
          </div>
        )}
    </div>
  );
}
