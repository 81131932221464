import type { ThemeOptions } from '@mui/material';
import breakpoints from './breakpoints.module.scss';

const Theme: ThemeOptions = {
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: false
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#DCE1EE',
          opacity: 1
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: Number(breakpoints.breakpointXs),
      sm: Number(breakpoints.breakpointSm),
      md: Number(breakpoints.breakpointMd),
      lg: Number(breakpoints.breakpointLg),
      xl: Number(breakpoints.breakpointXl),
      xxl: Number(breakpoints.breakpointXxl)
    }
  },
  spacing: 4
};

export default Theme;
