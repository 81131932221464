import React from 'react';

/* eslint-disable max-len */
function IconArchiveDownload() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.58335 5.34998C2.94233 5.34998 3.23335 5.64099 3.23335 5.99998V15.1H15.4333V5.99998C15.4333 5.64099 15.7244 5.34998 16.0833 5.34998C16.4423 5.34998 16.7333 5.64099 16.7333 5.99998V15.75C16.7333 16.109 16.4423 16.4 16.0833 16.4H2.58335C2.22436 16.4 1.93335 16.109 1.93335 15.75V5.99998C1.93335 5.64099 2.22436 5.34998 2.58335 5.34998Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.43335 2.24998C0.43335 1.89099 0.724365 1.59998 1.08335 1.59998H17.5833C17.9423 1.59998 18.2333 1.89099 18.2333 2.24998V5.99998C18.2333 6.35896 17.9423 6.64998 17.5833 6.64998H1.08335C0.724365 6.64998 0.43335 6.35896 0.43335 5.99998V2.24998ZM1.73335 2.89998V5.34998H16.9333V2.89998H1.73335Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.26295 7.63574C9.62194 7.63574 9.91295 7.92676 9.91295 8.28574V11.7472L10.6181 11.1831C10.8984 10.9588 11.3074 11.0043 11.5317 11.2846C11.7559 11.5649 11.7105 11.974 11.4302 12.1982L9.669 13.6071C9.42379 13.8033 9.0734 13.7961 8.83644 13.5901L7.21617 12.1811C6.94528 11.9456 6.91663 11.535 7.15219 11.2641C7.38775 10.9932 7.79831 10.9646 8.0692 11.2002L8.61295 11.673V8.28574C8.61295 7.92676 8.90397 7.63574 9.26295 7.63574Z"
        fill="#5B5F73"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconArchiveDownload;
