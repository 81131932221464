import * as actions from '../../chats/actions';
import * as storage from '../storage';
import * as getters from '../../chats/getters';
import { applyState } from '../../utils';
import { getChatGroupsService } from './getChatGroups';

export const getChatGroups = () => async (dispatch, getState) =>
  getChatGroupsService({
    reqGetGroups: storage.getGroups,
    getGroupChats: applyState(getState, getters.getGroupChats),
    setGroupChats: (groups) => dispatch(actions.setGroupChats(groups))
  });
