import {
  HIDE_GROUP_CONTACT,
  SHOW_GROUP_CONTACT,
  SET_GROUP_CONTACT
} from './constants';

export const showGroupContact = (kind) => ({
  type: SHOW_GROUP_CONTACT,
  payload: { kind }
});

export const hideGroupContact = () => ({
  type: HIDE_GROUP_CONTACT,
  payload: {}
});

export const setGroupContacts = (blockGroups) => ({
  type: SET_GROUP_CONTACT,
  payload: blockGroups
});
