/**
 * @typedef {(number|string)} Id
 */

/**
 * Any object with property id and any properties
 *
 * @typedef {object} Item
 * @property {(number|string)} id
 */

/**
 * @typedef BMEdit - BlockMsgEdit
 * @property {[Id]} chatId
 * @property {Item} message - object with id
 */

/**
 * Creates a block input data type
 *
 * @param {object} params
 * @param {Item} params.message
 * @param {[Id]} params.chatId
 * @returns {BMEdit}
 */
export const makeBlockEditMsg = ({ message = {}, chatId = null } = {}) => ({
  type: 'BlockEditMsg',
  message,
  chatId
});
