import { increateLoadCounter, resetPage } from '../../../data-type/query';

export const uploadArchivesService = async (
  {
    reqGetArchives,
    setArchives,
    getArchivesQuery,
    updateArchivesQuery,
    getChatGroups,
    transformToChatModels,
    setLoading
  },
  { newParams = {} }
) => {
  try {
    setLoading(true);
    const archives = await reqGetArchives(newParams);
    setLoading(false);
    setArchives(transformToChatModels(archives));
    getChatGroups();
    const query = newParams.query || getArchivesQuery();
    updateArchivesQuery(increateLoadCounter(resetPage(query)));
  } catch (err) {
    setLoading(false);
  }
};
