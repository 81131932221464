.headerTextColor {
  margin-left: var(--padding-xxs);
  margin-right: auto;
  color: var(--color-gray-900);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectedHeader {
  background: var(--color-blue-50);
}

.headerContainer {
  max-height: 34px;
  padding: var(--indent-xs) var(--indent-s);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.filterIcon {
  margin-right: 8px;
}

.buttonIcon {
  max-width: 18px;
  max-height: 18px;
  min-width: 18px;
  min-height: 18px;
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

.closeIcon {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  max-height: 18px;
  max-width: 18px;
  min-width: 18px;
  min-height: 18px;
}

.filterOptionItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--padding-xs);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filterOptionList {
  padding: var(--indent-xs) var(--indent-s);
  background: var(--color-white);
  box-shadow: 0 3px 7px rgb(36 48 103 / 20%);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;
}

.labelContainer {
  margin-bottom: 0;
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.label {
  color: var(--color-black);
  cursor: pointer;
}
