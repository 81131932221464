import React from 'react';

/* eslint-disable max-len */
const IconEdit = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.2593 7.66016L10.3399 4.74077M1.94659 16.0534L4.41667 15.779C4.71846 15.7454 4.86935 15.7287 5.01039 15.683C5.13551 15.6425 5.25459 15.5853 5.36439 15.5129C5.48815 15.4313 5.59551 15.3239 5.81021 15.1092L15.4488 5.47062C16.255 4.66445 16.255 3.35739 15.4488 2.55122C14.6426 1.74505 13.3356 1.74505 12.5294 2.55122L2.89082 12.1898C2.67611 12.4045 2.56876 12.5119 2.48715 12.6356C2.41474 12.7454 2.35751 12.8645 2.317 12.9896C2.27134 13.1307 2.25458 13.2816 2.22105 13.5833L1.94659 16.0534Z"
      stroke="#5B5F73"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconEdit;
