import { setInfoMessage, setErrorMessage } from './message';

export const toggleBlockMe = ({ employeeId, isBlocked }) => ({
  type: 'CHAT:TOGGLE_BLOCK_ME',
  payload: { employeeId, isBlocked }
});

export const toggleBlockContact = ({ employeeId, isBlocked }) => ({
  type: 'CHAT:TOGGLE_BLOCK_CONTACT',
  payload: { employeeId, isBlocked }
});

export const setChatUsersList = (users) => (dispatch) =>
  dispatch({ type: 'CHAT:SET_USERS_LIST', payload: users });

export const addMessage = (msg) => (dispatch) =>
  dispatch({ type: 'CHAT:ADD_MESSAGE', payload: msg });

export const updateMessage = (msg) => (dispatch) =>
  dispatch({ type: 'CHAT:UPDATE_MESSAGE', payload: msg });

export const changeViewMode = (viewMode) => (dispatch) =>
  dispatch({ type: 'CHAT:CHANGE_VIEW_MODE', payload: { viewMode } });

export const setMessages = (msg) => (dispatch) =>
  dispatch({ type: 'CHAT:SET_MESSAGES', payload: msg });

export const clearMessages = () => (dispatch) =>
  dispatch({ type: 'CHAT:CLEAR_MESSAGES' });

export const setLastMessages = (msg) => (dispatch) =>
  dispatch({ type: 'CHAT:LAST_MESSAGES', payload: msg });

export const setDialogList = (dialogs) => (dispatch) =>
  dispatch({ type: 'CHAT:SET_DIALOGS', payload: dialogs });

export const setSupportUser = (user) => (dispatch) =>
  dispatch({ type: 'CHAT:SET_SUPPORT_USER', payload: user });

export const setBadgeCount = (count) => (dispatch) =>
  dispatch({ type: 'CHAT:SET_BADGE_COUNT', payload: count });

export const setDialogId = (dialogId) => (dispatch) =>
  dispatch({ type: 'CHAT:SET_DIALOG_ID', payload: dialogId });

export const setDraftMessage = (dialogId, msg) => (dispatch) =>
  dispatch({ type: 'CHAT:SET_DRAFT_MESSAGE', payload: { dialogId, msg } });

export const clearDraftMessage = () => (dispatch) =>
  dispatch({ type: 'CHAT:CLEAR_DRAFT_MESSAGE' });

export const clearAttach = (dialogId) => (dispatch) =>
  dispatch({ type: 'CHAT:CLEAR_ATTACH', payload: dialogId });

export const getAttach = (dialogId) => async (dispatch) => {
  try {
    dispatch({ type: 'CHAT:GET_ATTACH:REQUEST', payload: { dialogId } });
    const res = await fetch(`/api/chat/${dialogId}/attach`, {
      credentials: 'include',
      method: 'GET'
    });
    const { files } = await res.json();
    dispatch({ type: 'CHAT:GET_ATTACH:SUCCESS', payload: { files } });
  } catch (e) {
    dispatch({ type: 'CHAT:GET_ATTACH:FAILURE', payload: { error: e } });
  }
};

export const deleteAttach = (dialogId, _id) => (dispatch) => {
  dispatch({ type: 'CHAT:DELETE_ATTACH:REQUEST', payload: { dialogId } });
  return fetch(`/api/chat/${dialogId}/attach/delete/${_id}`, {
    credentials: 'include',
    method: 'POST'
  })
    .then((res) => res.json())
    .then(({ id }) => {
      dispatch({ type: 'CHAT:DELETE_ATTACH:SUCCESS', payload: { id } });
    })
    .catch((res) => {
      dispatch({
        type: 'CHAT:DELETE_ATTACH:FAILURE',
        payload: { error: res.error }
      });
    });
};

export const attachFile = (dialogId, body) => async (dispatch) => {
  try {
    dispatch({ type: 'CHAT:FILE_ATTACH:REQUEST', payload: { dialogId } });
    dispatch(setInfoMessage({ key: 'File upload started' }));
    const res = await fetch(`/api/chat/${dialogId}/attach/${body.name}`, {
      credentials: 'include',
      method: 'POST',
      body
    });
    const json = await res.json();

    if (json.errors && json.errors.length > 0) {
      dispatch({
        type: 'CHAT:FILE_ATTACH:FAILURE',
        payload: { dialogId, errors: json.errors[0].message }
      });
      dispatch(
        setErrorMessage({
          key: json.errors[0].message || 'File cant be upload'
        })
      );
      return await Promise.reject();
    }

    dispatch({
      type: 'CHAT:FILE_ATTACH:SUCCESS',
      payload: json
    });
    dispatch(clearMessages());

    return await Promise.resolve();
  } catch (e) {
    dispatch({
      type: 'CHAT:FILE_ATTACH:FAILURE',
      payload: { dialogId, errors: e }
    });
    dispatch(setErrorMessage({ key: e[0] || 'Error while uploading files' }));

    return Promise.reject();
  }
};
