import { useCallback } from 'react';
import { Tooltip as MUITooltip } from '@mui/material';
import cn from 'classnames';
import { HintProps } from './Hint.types';
import styles from './Hint.module.scss';
import Info from './assets/information.svg';

const componentsProps = {
  tooltip: {
    style: {
      background: 'transparent',
      padding: '0',
      margin: '0',
      transform: 'translate(6px)'
    }
  },
  popper: {
    disablePortal: true,
    modifiers: [
      {
        name: 'flip',
        enabled: false,
        options: {
          altBoundary: false,
          rootBoundary: 'viewport',
          padding: 8
        }
      },
      {
        name: 'preventOverflow',
        enabled: false,
        options: {
          altAxis: false,
          altBoundary: true,
          tether: true,
          rootBoundary: 'viewport',
          padding: 8
        }
      }
    ]
  }
};

export function Hint({
  children,
  Content,
  open,
  classes,
  className,
  rootClassName
}: HintProps) {
  const HintContent = useCallback(
    () => (
      <div className={cn(styles.container, classes?.contentRoot)}>
        <div className={styles.content}>
          <div className={styles.info}>
            <Info width={18} height={18} />
          </div>
          {Content}
        </div>
      </div>
    ),
    [Content, classes?.contentRoot]
  );

  const Pointer = useCallback(
    () => (
      <div className={cn(styles.pointer, classes?.pointerRoot)}>
        <div className={cn(styles.line, classes?.pointerLine)} />
        <div className={cn(styles.circle, classes?.pointerCircle)} />
      </div>
    ),
    [classes?.pointerCircle, classes?.pointerLine, classes?.pointerRoot]
  );

  return (
    <div className={cn(styles.hint, rootClassName)}>
      <MUITooltip
        placement="top"
        title={<HintContent />}
        open={open}
        arrow
        components={{ Arrow: Pointer }}
        componentsProps={componentsProps}>
        <div
          className={cn(
            className,
            classes?.highlightRoot,
            styles.children,
            open ? styles.highlight : ''
          )}>
          {children}
        </div>
      </MUITooltip>
    </div>
  );
}
