import { useCallback } from 'react';
import { usePopupContext } from './popup.context';

export function usePopup(key: string) {
  const context = usePopupContext();
  const { setOpened, onClose } = context;

  const handleOpen = useCallback(
    <Props extends Record<string, unknown>>(props: Props) => {
      setOpened({
        key,
        props
      });
    },
    [key, setOpened]
  );

  const handleClose = useCallback(() => {
    onClose(key);
  }, [key, onClose]);

  return [handleOpen, handleClose];
}
