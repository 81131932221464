import { PREFIX } from '../constants';

export const SET_INPUT_BLOCK = `${PREFIX}:SET_INPUT_BLOCK`;
export const SET_TEXT_INPUT_BLOCK = `${PREFIX}:SET_TEXT_INPUT_BLOCK`;
export const SET_FILES_INPUT_BLOCK = `${PREFIX}:SET_FILES_INPUT_BLOCK`;
export const HIDE_INPUT_BLOCK = `${PREFIX}:HIDE_INPUT_BLOCK`;
export const SHOW_INPUT_BLOCK = `${PREFIX}:SHOW_INPUT_BLOCK`;
export const CLEAR_INPUT_BLOCK = `${PREFIX}:CLEAR_INPUT_BLOCK`;
export const SET_LOADING_FILES_IDS_INPUT_BLOCK = `${PREFIX}:SET_LOADING_FILES_IDS_INPUT_BLOCK`;
export const REMOVE_LOADING_FILE_ID_INPUT_BLOCK = `${PREFIX}:REMOVE_LOADING_FILE_ID_INPUT_BLOCK`;
