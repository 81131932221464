import { Map } from 'immutable';

const initState = () =>
  Map({
    token: '',
    userId: null,
    valid: false
  });

const setToken = (state, { token = '' }) => state.set('token', token);
const clearToken = (state) => state.set('token', '');

const setValidation = (state, { userId = null, valid = false }) =>
  state.set('userId', userId).set('valid', valid);
const clearValidation = (state) =>
  state.set('userId', null).set('valid', false);

const partnersReducer = (state = initState(), { type, payload = {} }) => {
  switch (type) {
    case 'PARTNERS:SET_TOKEN':
      return setToken(state, payload);
    case 'PARTNERS:CLEAR_TOKEN':
      return clearToken(state);
    case 'PARTNERS:SET_TOKEN_VALIDATION':
      return setValidation(state, payload);
    case 'PARTNERS:CLEAR_TOKEN_VALIDATION':
      return clearValidation(state);
    default:
      return state;
  }
};

export default partnersReducer;
