import React from 'react';

/* eslint-disable max-len */
const IconFilter = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      className="cws-icon-hover"
      cx="17"
      cy="17"
      r="17"
      fill="#767B92"
      fillOpacity="0"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 8.6532H25V10.8731L19 16.3731V24.0136L15 25.3469V16.3731L9 10.8731V8.6532ZM10 9.6532V10.4332L16 15.9332V23.9595L18 23.2928V15.9332L24 10.4332V9.6532H10Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconFilter;
