import request from '../../../../lib/request';

export const fetchDelayedMessages = async (
  chatId,
  { limit = 40, pageNumber = 0, offset = pageNumber * limit } = {}
) =>
  request.post(`/api/channel/${chatId}/delayed-messages`, {
    limit,
    offset
  });
