import request from '../../../../lib/request';
import { initRequest } from '../../data-type/request';
import { getFilter, getQuery } from '../query';
import { getGroupChats } from './getters';
import { getScktChl } from '../../useCases/service';
import { getCurrentUserLanguage } from '../../../../storeGetters';

const scktChl = getScktChl();

export const unsubscribeFromNewMsg = () => scktChl.removeListener('message');
export const unsubscribeFromUpdateChannel = () =>
  scktChl.removeListener('update');
export const unsubscribeFromAddUnreadMsg = () =>
  scktChl.removeListener('unreadMessagesId:add');
export const unsubscribeFromClearUnreadMsg = () =>
  scktChl.removeListener('unreadMessagesId:clear');
export const unsubscribeFromRemoveMsg = () =>
  scktChl.removeListener('message:remove');
export const unsubscribeFromUpdateMsg = () =>
  scktChl.removeListener('message:update');
export const leaveFromChannel = (chatId) =>
  scktChl.emit('leave', { channelId: chatId });
export const updateChannelListByCreateGroupChat = (chat) =>
  scktChl.emit('updateChannelList:chat', { chat });
export const subToupdateChannelListByCreateGroupChat = (cb) =>
  scktChl.on('updateChannelList:chat', cb);

const getChats =
  ({ isArchive = false } = {}) =>
  async ({
    page = 0,
    limit = 20,
    search = '',
    mode = '',
    lang = 'ru'
  } = {}) => {
    const system = ['inactive'];

    if (isArchive) system.unshift('archive');

    return request.post('/api/dialogs', {
      mode,
      system,
      search,
      page,
      limit,
      settingsOff: false,
      lang
    });
  };

export const reqGetChats = initRequest(
  (state) => ({
    query: getQuery('chats', state),
    blockGroup: getGroupChats(state),
    filter: getFilter('chat', state),
    lang: getCurrentUserLanguage(state)
  }),
  (params) => ({
    search: params.query.search,
    page: params.query.page,
    limit: params.query.limit,
    user: params.blockGroup.selectIds,
    mode: getFilterMode(params.filter.value),
    lang: params.lang
  }),
  getChats()
);

export const reqGetArchives = initRequest(
  (state) => ({
    query: getQuery('archives', state),
    blockGroup: getGroupChats(state),
    filter: getFilter('archive', state),
    lang: getCurrentUserLanguage(state)
  }),
  (params) => ({
    search: params.query.search,
    page: params.query.page,
    limit: params.query.limit,
    user: params.blockGroup.selectIds,
    mode: getFilterMode(params.filter.value),
    lang: params.lang
  }),
  getChats({ isArchive: true })
);

function getFilterMode(name) {
  const values = {
    'all-chats': 'all',
    dialogs: 'dialog',
    channels: 'channel',
    topics: 'topic'
  };
  return values[name] || name;
}
