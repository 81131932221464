import { CurrencyCode } from '@/utils/currency';
import { ModuleName } from '@/constants/module';

function isTrue(env: unknown) {
  return env === 'true';
}

export default {
  version: process.env.REACT_APP_VERSION,
  buildIn: new Date(process.env.REACT_APP_BUILD_TIME),
  userAgreementUrl: process.env.REACT_APP_USER_AGREEMENT_URL,
  privatePolicyUrl: process.env.REACT_APP_PRIVATE_POLICY_URL,
  BASE_CURRENCY: CurrencyCode.RUB,
  BASE_URL: process.env.REACT_APP_ISTOCK_MESSENGER_SERVICE,
  MOBILE_START_PAGE: '/my-office', // TODO remove this when mobile view is done,
  MARKETPLACE_ENABLED_FEATURE_FLAG: isTrue(
    process.env.REACT_APP_MARKETPLACE_ENABLED_FEATURE_FLAG
  ),
  DEFAULT_ACTIVE_MODULE: ModuleName.ETP,
  DEFAULT_SEARCH_TYPING_DEBOUNCE: 500,
  ISTOCK_INFO_SERVICE: process.env.REACT_APP_ISTOCK_INFO_SERVICE
} as const;
