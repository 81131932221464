import { isNil } from 'ramda';
import { getChatMsgs } from '../../getter';
import { getChatId } from './getters';
import * as actions from './actions';
import { makeScrollPosition } from './dataType';

export const saveScrollData =
  (chatId, paddingTop = 180) =>
  (dispatch, getState) => {
    const $container = document.getElementById('chat-messages');

    if (isNil($container)) return;

    if (isScrollBottom()) {
      saveData();
      return;
    }

    const $firstVisibleMessage = getFirstVisibleMessageNode($container);
    if (isNil($firstVisibleMessage)) {
      saveData({ message: null, scrollByTop: 120 });
      return;
    }

    const scrollByTop = $firstVisibleMessage.getBoundingClientRect().top;
    const message = getChatMsgs(getState()).list.find(
      (msg) => msg.id === getMessageIdFromNode($firstVisibleMessage)
    );

    saveData({ message, scrollByTop });

    function saveData(data) {
      dispatch(
        actions.saveScrollPosition({
          chatId: getChatId(getState(), chatId),
          data: makeScrollPosition(data)
        })
      );
    }

    function isScrollBottom() {
      const { clientHeight, scrollTop, scrollHeight } = $container;

      return clientHeight + scrollTop === scrollHeight;
    }

    function isMessageNode($node) {
      return $node.childNodes[0].id?.split('-')[0] === 'msgId';
    }

    function isVisibleMessageNode($node) {
      return isVisible($node) && isMessageNode($node);
    }

    function getFirstVisibleMessageNode() {
      const $messages = getMessagesNode();

      return Array.from($messages).find(isVisibleMessageNode);
    }

    function getMessagesNode() {
      // struct Virtuoso lib
      return $container.childNodes[0].childNodes[1].childNodes;
    }

    function isVisible($node) {
      return $node.getBoundingClientRect().y > paddingTop;
    }

    function getMessageIdFromNode($node) {
      return $node.childNodes[0].id.split('-')[1];
    }
  };
