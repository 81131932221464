import React from 'react';

/* eslint-disable max-len */
const IconForward = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="cws-icon-main-fill"
      d="M11.6504 10.7776L15.5662 6.84819C15.81 6.6045 15.81 6.208 15.5672 5.96531L11.6521 2.06794C11.4075 1.8244 11.4066 1.42869 11.6501 1.18406C11.8936 0.939404 12.2894 0.93856 12.534 1.18206L16.4502 5.08044C17.1812 5.8115 17.1812 7.001 16.4509 7.73131L12.5358 11.6599C12.4137 11.7825 12.2534 11.8437 12.0931 11.8437C11.9335 11.8437 11.7739 11.783 11.6519 11.6614C11.4074 11.4178 11.4067 11.0221 11.6504 10.7776Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      d="M6.60967 5.78117H13.2815C13.6267 5.78117 13.9065 6.06099 13.9065 6.40617C13.9065 6.75136 13.6267 7.03117 13.2815 7.03117H6.60967C4.20589 7.03117 2.2503 8.9868 2.2503 11.3905C2.2503 13.7943 4.20589 15.7499 6.60967 15.7499C6.95486 15.7499 7.23467 16.0297 7.23467 16.3749C7.23467 16.7201 6.95486 16.9999 6.60967 16.9999C3.51664 16.9999 1.0003 14.4836 1.0003 11.3905C1.0003 8.29752 3.51664 5.78117 6.60967 5.78117Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconForward;
