import React from 'react';

/* eslint-disable max-len */
function IconNotificationMute() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.2417 2.3626C5.5181 2.15007 5.82754 1.97801 6.17149 1.84927C6.31881 1.23299 6.83662 0.777771 7.4528 0.777771C8.06896 0.777771 8.58677 1.23297 8.7341 1.84924C9.07808 1.97799 9.38754 2.15006 9.66396 2.3626C10.3274 2.87275 10.749 3.5765 11.02 4.34152C11.5426 5.8175 11.5498 7.6698 11.5499 9.20005L11.6686 9.46251L13.1189 10.9128C13.3467 11.1406 13.3467 11.5099 13.1189 11.7377C12.8911 11.9656 12.5218 11.9656 12.294 11.7377L2.50418 1.94797C2.27638 1.72017 2.27638 1.35082 2.50418 1.12301C2.73199 0.895207 3.10133 0.895207 3.32914 1.12301L4.88436 2.67823C4.99493 2.56646 5.11382 2.46094 5.2417 2.3626ZM5.7094 3.50327L10.3762 8.17011C10.3547 6.93112 10.268 5.71312 9.92021 4.73096C9.69981 4.10857 9.38708 3.62139 8.9528 3.28746C8.5924 3.01034 8.1121 2.81083 7.45283 2.75413C6.79356 2.81083 6.31326 3.01034 5.95286 3.28746C5.86704 3.35346 5.78595 3.42544 5.7094 3.50327Z"
        fill="#7F869E"
      />
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.55951 10.5825L10.7262 11.7491H9.42647C9.41236 12.7862 8.53413 13.6228 7.45281 13.6228C6.37149 13.6228 5.49326 12.7862 5.47916 11.7491H3.46852C2.87523 11.7491 2.47994 11.1365 2.72442 10.5959L3.35573 9.20005C3.35586 7.81555 3.36173 6.16743 3.74995 4.7729L4.72397 5.74692C4.52907 6.83123 4.52243 8.07397 4.52239 9.27588C4.52239 9.39277 4.4973 9.50708 4.44983 9.61206L4.01095 10.5825H9.55951ZM6.64617 11.7491C6.66059 12.1032 6.97553 12.4561 7.45281 12.4561C7.93009 12.4561 8.24503 12.1032 8.25945 11.7491H6.64617Z"
        fill="#7F869E"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconNotificationMute;
