import cn from 'classnames';
import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { HasRootRef } from '@/components/common.types';
import { Touch, TouchEvent, TouchProps } from '@/components/Touch';
import { noop } from '@/lib/utils';
import { shouldTriggerClickOnEnterOrSpace } from '@/lib/accessibility';
import { callMultiple } from '@/lib/callMultiple';
import { useBooleanState, useExternRef, useFocusVisible } from '../../hooks';
import TouchRootContext from '../Touch/TouchContext';
import TappableContext from './TappableContext';
import styles from './Tappable.module.css';
import { TapState } from './constants';
import { useActivity } from './hooks/useActivity';

export interface TappableProps
  extends Omit<
      React.AllHTMLAttributes<HTMLElement>,
      | 'onTouchStart'
      | 'onTouchMove'
      | 'onTouchEnd'
      | 'onTouchCancel'
      | 'onMouseDown'
      | 'onMouseMove'
      | 'onMouseUp'
      | 'onMouseLeave'
    >,
    HasRootRef<HTMLElement>,
    Pick<TouchProps, 'onStart' | 'onEnd' | 'onMove'> {
  activeEffectDelay?: number;
  stopPropagation?: boolean;
  hasHover?: boolean;
  hasActive?: boolean;
  activeMode?: string;
  hoverMode?: string;
  deviceHasHover?: boolean;
  focusVisibleMode?: string;
  children?: React.ReactNode;
  Component?: React.ElementType;
  onEnter?(event: MouseEvent): void;
  onLeave?(event: MouseEvent): void;
}

interface RootComponentProps extends TouchProps {
  ref?: React.Ref<HTMLElement>;
}

const ACTIVE_DELAY = 70;
const ACTIVE_EFFECT_DELAY = 600;

export function Tappable({
  children,
  className,
  Component: _Component,
  getRootRef,
  onClick,
  onKeyDown: _onKeyDown,
  activeEffectDelay = ACTIVE_EFFECT_DELAY,
  stopPropagation = false,
  hasHover: _hasHover = true,
  hoverMode = 'background',
  deviceHasHover = true,
  hasActive: _hasActive = true,
  activeMode = 'background',
  focusVisibleMode = 'inside',
  onEnter,
  onLeave,
  ...props
}: TappableProps) {
  const Component =
    _Component || ((props.href ? 'a' : 'div') as React.ElementType);

  const { onHoverChange } = useContext(TappableContext);
  const insideTouchRoot = useContext(TouchRootContext);

  const { isFocused, onFocus, onBlur } = useFocusVisible();
  const [isChildHover, setIsChildHover] = useState(false);

  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    value: _hovered,
    setTrue: setHoveredTrue,
    setFalse: setHoveredFalse
  } = useBooleanState(false);

  const hovered = _hovered && !props.disabled;
  const hasActive = _hasActive && !isChildHover && !props.disabled;
  const hasHover = deviceHasHover && _hasHover && !isChildHover;

  const isCustomElement =
    Component !== 'a' &&
    Component !== 'button' &&
    Component !== 'label' &&
    !props.contentEditable;
  const isPresetHoverMode = ['opacity', 'background'].includes(hoverMode);
  const isPresetActiveMode = ['opacity', 'background'].includes(activeMode);
  const isPresetFocusVisibleMode = ['inside', 'outside'].includes(
    focusVisibleMode
  );

  const [activity, { delayStart, start, stop }] = useActivity({
    hasActive,
    activeDelay: ACTIVE_DELAY,
    stopDelay: activeEffectDelay
  });

  const active = activity === TapState.active || activity === TapState.exiting;

  const containerRef = useExternRef(getRootRef);

  const childContext = useRef({ onHoverChange: setIsChildHover }).current;

  useLayoutEffect(() => {
    if (!hovered) {
      return noop;
    }

    onHoverChange(true);

    return () => onHoverChange(false);
  }, [hovered]);

  function onKeyDown(event: React.KeyboardEvent<HTMLElement>) {
    if (isCustomElement && shouldTriggerClickOnEnterOrSpace(event)) {
      event.preventDefault();
      containerRef.current?.click();
    }
  }

  function onStart({ originalEvent }: TouchEvent) {
    if (hasActive) {
      if (originalEvent.touches?.length > 1) {
        stop();
        return;
      }

      delayStart();
    }
  }

  function onMove({ isSlide }: TouchEvent) {
    if (isSlide) {
      stop();
    }
  }

  function onEnd({ duration }: TouchEvent) {
    if (activity === TapState.none) {
      return;
    }

    if (activity === TapState.pending) {
      start();
    }

    const activeDuration = duration - ACTIVE_DELAY;
    stop(activeDuration >= 100 ? 0 : activeEffectDelay - activeDuration);
  }

  const classes = cn(className, styles.tappable, hasHover && styles.hasHover, {
    [hoverMode]: hasHover && hovered && !isPresetHoverMode,
    [activeMode]: hasActive && active && !isPresetActiveMode,
    [focusVisibleMode]: isFocused && !isPresetFocusVisibleMode,
    [styles.active]: hasActive && active,
    [`tappable-hover-${hoverMode}`]: hasHover && hovered && isPresetHoverMode,
    [`tappable-active-${activeMode}`]:
      hasActive && active && isPresetActiveMode,
    [styles.focusVisible]: isFocused
  });

  const handlers: RootComponentProps = {
    onStart: callMultiple(onStart, props.onStart),
    onMove: callMultiple(onMove, props.onMove),
    onEnd: callMultiple(onEnd, props.onEnd),
    onClick,
    onKeyDown: callMultiple(onKeyDown, _onKeyDown)
  };

  const role = props.href ? 'link' : 'button';

  return (
    <Touch
      onEnter={callMultiple(setHoveredTrue, onEnter)}
      onLeave={callMultiple(setHoveredFalse, onLeave)}
      type={Component === 'button' ? 'button' : undefined}
      tabIndex={isCustomElement && !props.disabled ? 0 : undefined}
      role={isCustomElement ? role : undefined}
      aria-disabled={isCustomElement ? props.disabled : undefined}
      stopPropagation={stopPropagation && !insideTouchRoot && !props.disabled}
      {...props}
      slideThreshold={20}
      usePointerHover
      className={classes}
      Component={Component}
      getRootRef={containerRef}
      onBlur={callMultiple(onBlur, props.onBlur)}
      onFocus={callMultiple(onFocus, props.onFocus)}
      {...(props.disabled ? {} : handlers)}>
      <TappableContext.Provider value={childContext}>
        {children}
      </TappableContext.Provider>
    </Touch>
  );
}
