import React from 'react';

function IconBtnSendEnable() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 8.19444C17.8 8.59444 18 9.36111 18 9.69444L10.0174 9.69464L18 9.69484C18 10.0282 17.8 10.7948 17 11.1948L2 18.6948C1 19.1946 0 18.8948 0 17.6948C0 16.5621 0.855428 12.5777 1.27429 10.6946H8C8.55229 10.6946 9 10.2469 9 9.69456C9 9.14228 8.55229 8.69456 8 8.69456H1.27426C0.855383 6.81141 0 2.82717 0 1.69444C0 0.494441 1 0.19464 2 0.694441L17 8.19444Z"
        fill="#1C50DE"
      />
    </svg>
  );
}

export default IconBtnSendEnable;
