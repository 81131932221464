import { isType } from 'core/lib';
import { ChannelType } from '../types';

export const isDialog = (prop) => {
  if (typeof prop === 'string') {
    return prop === ChannelType.Dialog;
  }

  return isType(ChannelType.Dialog, prop);
};

export const isChannel = (prop) => {
  if (typeof prop === 'string') {
    return prop === ChannelType.Channel;
  }

  return isType(ChannelType.Channel, prop);
};

export const isTopic = (prop) => {
  if (typeof prop === 'string') {
    return prop === ChannelType.Topic;
  }

  return isType(ChannelType.Topic, prop);
};

export const isPurchaseRequestsChannel = (prop) => {
  if (typeof prop === 'string') {
    return prop === ChannelType.PurchaseRequestChannel;
  }

  return isType(ChannelType.PurchaseRequestChannel, prop);
};

export const isResponseChat = (prop) => {
  if (typeof prop === 'string') {
    return prop === ChannelType.PurchaseResponseChat;
  }
  return prop.hardcodeType === ChannelType.PurchaseResponseChat;
};

export const isNewsFeedChannel = (prop) => {
  if (typeof prop === 'string') {
    return prop === ChannelType.NewsFeedChannel;
  }

  return isType(ChannelType.NewsFeedChannel, prop);
};

export const isPurchaseInboundChannel = (prop) => {
  if (typeof prop === 'string') {
    return prop === ChannelType.PurchaseInboundChannel;
  }

  return isType(ChannelType.PurchaseInboundChannel, prop);
};

export const isProcurementChannel = (prop) => {
  if (typeof prop === 'string') {
    return prop === ChannelType.ProcurementChannel;
  }

  return isType(ChannelType.ProcurementChannel, prop);
};
