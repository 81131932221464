import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

export function isFunction(arg: any): arg is Function {
  return typeof arg === 'function';
}

export function isBoolean(arg: any): arg is boolean {
  return typeof arg === 'boolean';
}

export function isString(arg: any): arg is string {
  return typeof arg === 'string';
}

export function notEmpty<T>(arg: T | null | undefined): arg is T {
  return arg !== null && arg !== undefined;
}

export function setRef<T>(element: T, ref?: React.Ref<T>): void {
  if (!ref) return;

  if (isFunction(ref)) {
    ref(element);
  }

  // eslint-disable-next-line no-param-reassign
  (ref as React.MutableRefObject<T>).current = element;
}
