import React from 'react';

/* eslint-disable max-len */
function IconGroup() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        d="M6.2 8.5C7.52563 8.5 8.6 7.38066 8.6 6C8.6 4.61934 7.52563 3.5 6.2 3.5C4.87438 3.5 3.8 4.61934 3.8 6C3.8 7.38066 4.87438 8.5 6.2 8.5ZM7.15063 9.4375H5.24938C3.45444 9.4375 2 10.9531 2 12.8223C2 13.1973 2.291 13.5 2.64988 13.5H9.74937C10.1094 13.5 10.4 13.1973 10.4 12.8223C10.4 10.9531 8.945 9.4375 7.15063 9.4375ZM10.9831 9.75H9.59844C10.46 10.4863 11 11.5879 11 12.8223C11 13.0723 10.9288 13.3027 10.8125 13.5H13.4C13.7319 13.5 14 13.2188 14 12.8574C14 11.1484 12.6575 9.75 10.9831 9.75ZM10.1 8.5C11.2606 8.5 12.2 7.52148 12.2 6.3125C12.2 5.10352 11.2606 4.125 10.1 4.125C9.62919 4.125 9.19925 4.29209 8.849 4.56465C9.065 4.99668 9.2 5.48047 9.2 6C9.2 6.69375 8.97631 7.33086 8.60769 7.8498C8.98813 8.25 9.515 8.5 10.1 8.5Z"
        fill="#5B5F73"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconGroup;
