import { useLayoutEffect, useMemo, useState } from 'react';
import mitt from 'mitt';
import { useTimeout } from '@/hooks';
import { noop } from '@/lib/utils';
import { TapState } from '../constants';

const activeBus = mitt<{ active: string }>();

interface UseActivityProps {
  hasActive: boolean;
  activeDelay: number;
  stopDelay: number;
}

export function useActivity({
  hasActive,
  activeDelay,
  stopDelay
}: UseActivityProps) {
  const id = useMemo(() => Math.round(Math.random() * 1e8).toString(16), []);

  const [activity, setActivity] = useState<
    typeof TapState[keyof typeof TapState]
  >(TapState.none);
  // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/naming-convention
  const _stop = () => setActivity(TapState.none);
  const start = () => hasActive && setActivity(TapState.active);
  const delayStart = () => hasActive && setActivity(TapState.pending);

  const activeTimeout = useTimeout(start, activeDelay);
  const stopTimeout = useTimeout(_stop, stopDelay);

  useLayoutEffect(() => {
    if (activity === TapState.pending) {
      activeTimeout.set();
      return activeTimeout.clear;
    }

    if (activity === TapState.exiting) {
      return stopTimeout.clear;
    }

    if (activity === TapState.active) {
      activeBus.emit('active', id);
    }

    return noop;
  }, [activity]);

  useLayoutEffect(() => {
    if (activity === TapState.none) {
      return noop;
    }

    const onActiveChange = (activeId: string) => {
      if (activeId !== id) {
        _stop();
      }
    };

    activeBus.on('active', onActiveChange);
    return () => activeBus.off('active', onActiveChange);
  }, [activity === TapState.none]);

  useLayoutEffect(() => {
    if (!hasActive) {
      _stop();
    }
  }, [hasActive]);

  const stop = (delay?: number) => {
    if (delay) {
      setActivity(TapState.exiting);
      stopTimeout.set(delay);
      return;
    }
    _stop();
  };

  return [activity, { delayStart, start, stop }] as const;
}
