import {
  makeLoaderList,
  start,
  finish,
  resetLoader
} from 'core/data/loaderList';
import {
  LOADER_START,
  LOADER_FINISH,
  LOADER_RESET
} from '../actionTypes/loaderList';

const reducers = {
  [LOADER_START]: (loaderList, { name }) => start(name, loaderList),
  [LOADER_FINISH]: (loaderList, { name }) => finish(name, loaderList),
  [LOADER_RESET]: (loaderList, { name }) => resetLoader(name, loaderList)
};

export default (state = makeLoaderList(), { type, payload }) => {
  if (reducers[type]) return reducers[type](state, payload);
  return state;
};
