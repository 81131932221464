export const unsubscribeFromUpdateChatService = (
  {
    unsubscribeFromNewMsg,
    unsubscribeFromUpdateChannel,
    unsubscribeFromAddUnreadMsg,
    unsubscribeFromClearUnreadMsg,
    unsubscribeFromRemoveMsg,
    unsubscribeFromUpdateMsg,
    leaveFromChannel
  },
  chatId
) => {
  unsubscribeFromNewMsg();
  unsubscribeFromUpdateChannel();
  unsubscribeFromAddUnreadMsg();
  unsubscribeFromClearUnreadMsg();
  unsubscribeFromRemoveMsg();
  unsubscribeFromUpdateMsg();
  leaveFromChannel(chatId);
};
