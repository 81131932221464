import { SidebarMode, SidebarWidth } from './constants';

export const makeNavigationSidebar = ({
  mode = SidebarMode.full,
  width = SidebarWidth.full
} = {}) => ({
  mode,
  width
});

export const setFullMode = () =>
  makeNavigationSidebar({ mode: SidebarMode.full, width: SidebarWidth.full });

export const setShortMode = () =>
  makeNavigationSidebar({ mode: SidebarMode.short, width: SidebarWidth.short });

export const toggleNavigationSidebarMode = (currentMode = SidebarMode.full) => {
  if (currentMode === SidebarMode.full) {
    return setShortMode();
  }

  return setFullMode();
};
