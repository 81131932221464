import { Icons12x12 } from './12x12';
import { Icons14x14 } from './14x14';
import { Icons16x16 } from './16x16';
import { Icons18x18 } from './18x18';
import { Icons22x22 } from './22x22';
import { Icons28x28 } from './28x28';
import { Icons34x34 } from './34x34';

export { default as Icons } from './constants';

const Icon = ({ size, icon }) => {
  switch (size) {
    case 12:
      return Icons12x12[icon];
    case 14:
      return Icons14x14[icon];
    case 16:
      return Icons16x16[icon];
    case 18:
      return Icons18x18[icon];
    case 22:
      return Icons22x22[icon];
    case 28:
      return Icons28x28[icon];
    case 34:
      return Icons34x34[icon];
    default:
      throw new Error(`No such handler for size = ${size}`);
  }
};

export default Icon;
