import React from 'react';

/* eslint-disable max-len */
const IconClose = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.4 7.6L6.6 12.4M6.6 7.6L11.4 12.4M17 10C17 14.4183 13.4183 18 9 18C4.58172 18 1 14.4183 1 10C1 5.58172 4.58172 2 9 2C13.4183 2 17 5.58172 17 10Z"
      stroke="#5B5F73"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconClose;
