import {
  append,
  remove,
  pipe,
  prop,
  propEq,
  filter,
  equals,
  not,
  findIndex,
  update
} from 'ramda';
import { addUnreadMsg, clearUnreadMsg } from 'core/data/light/channel';
import {
  CHANNEL_SET_INFO,
  CHANNEL_SET_FILES,
  CHANNEL_SET_MESSAGES,
  CHANNEL_ADD_MESSAGE,
  CHANNEL_REMOVE_MESSAGE,
  CHANNEL_CLEAR,
  CHANNEL_ADD_UNREAD_MESSAGE,
  CHANNEL_CLEAR_UNREAD_MESSAGE,
  CHANNEL_SET_MODE,
  CHANNEL_SET_SEARCH,
  CHANNEL_CLEAR_SEARCH,
  CHANNEL_UPDATE_MESSAGE,
  CHANNEL_SET_BOOKMARKED_IDS,
  CHANNEL_CLEAR_BOOKMARKED_IDS
} from '../../actionTypes/channel';

const defaultValue = () => ({
  channel: { type: '', id: '', name: '', files: [], members: [] },
  messages: [],
  bookmarkedIds: [],
  files: [],
  mode: 'view',
  search: null
});

const reducers = {
  [CHANNEL_SET_INFO]: (state, { channel }) => ({ ...state, channel }),
  [CHANNEL_SET_FILES]: (state, { files }) => ({ ...state, files }),
  [CHANNEL_SET_MESSAGES]: (state, { messages }) => ({ ...state, messages }),
  [CHANNEL_ADD_MESSAGE]: (state, { message }) => {
    const index = state.messages.findIndex((msg) => msg.id === message.id);
    let messages;
    if (index !== -1) {
      messages = append(message, remove(index, 1, state.messages));
    } else {
      messages = append(message, state.messages);
    }
    return { ...state, messages };
  },
  [CHANNEL_REMOVE_MESSAGE]: (state, { channelId, messageId }) => {
    if (channelId !== state.channel.id) return state;

    return {
      ...state,
      messages: filter(pipe(prop('id'), equals(messageId), not), state.messages)
    };
  },
  [CHANNEL_ADD_UNREAD_MESSAGE]: (state, { channelId, messageId }) => {
    if (state.channel.id === channelId) {
      const updatedChannel = addUnreadMsg(state.channel, messageId);
      return { ...state, channel: updatedChannel };
    }
    return state;
  },
  [CHANNEL_CLEAR_UNREAD_MESSAGE]: (state, { channelId }) => {
    if (state.channel.id === channelId) {
      const updatedChannel = clearUnreadMsg(state.channel);
      return { ...state, channel: updatedChannel };
    }
    return state;
  },
  [CHANNEL_SET_MODE]: (state, { mode }) => ({ ...state, mode }),
  [CHANNEL_SET_SEARCH]: (state, { search }) => ({ ...state, search }),
  [CHANNEL_CLEAR_SEARCH]: (state) => ({ ...state, search: null }),
  [CHANNEL_UPDATE_MESSAGE]: (state, { channelId, messageId, message }) => {
    if (state.channel.id === channelId) {
      const messageIndex = findIndex(propEq('id', messageId), state.messages);
      return {
        ...state,
        messages: update(messageIndex, message, state.messages)
      };
    }
    return { ...state };
  },
  [CHANNEL_SET_BOOKMARKED_IDS]: (state, { bookmarkedIds = [] }) => ({
    ...state,
    bookmarkedIds
  }),
  [CHANNEL_CLEAR_BOOKMARKED_IDS]: (state) => ({ ...state, bookmarkedIds: [] }),
  [CHANNEL_CLEAR]: defaultValue
};

export default (state = defaultValue(), { type, payload }) => {
  if (reducers[type]) return reducers[type](state, payload);
  return state;
};
