.numericInput {
  color: var(--color-gray-900);
  background-color: var(--color-white);

  :global(.MuiTextField-root) {
    width: 100%;

    :global(.MuiInputBase-root) {
      color: inherit;
      font: inherit;
      padding: 0;

      input {
        color: inherit;
        font: inherit;
        padding: var(--indent-xs) var(--indent-m);
        line-height: 18px;
        height: unset;

        &::placeholder {
          color: var(--color-gray-600);
          opacity: 1;
        }
      }

      :global(.MuiOutlinedInput-notchedOutline) {
        border-color: var(--color-gray-500);
      }

      &:hover {
        :global(.MuiOutlinedInput-notchedOutline) {
          border-color: var(--color-blue-200);
        }
      }

      &:global(.MuiOutlinedInput-root.Mui-focused) {
        & > fieldset {
          border: 1px solid var(--color-blue-500);
          border-radius: 4px;
          box-shadow: 0 0 5px rgb(35 105 241 / 31%);
        }
      }

      &:global(.MuiOutlinedInput-root.Mui-disabled) {
        background-color: var(--color-gray-300);

        & > fieldset {
          border: 1px solid transparent;
          border-radius: 4px;
          color: var(--color-gray-700);
        }

        & > input {
          -webkit-text-fill-color: var(--color-gray-700);
        }

        & > input::placeholder {
          color: var(--color-gray-600);
        }
      }

      &:global(.MuiOutlinedInput-root.Mui-error) {
        & > fieldset {
          border: 1px solid var(--color-red-error);
          border-radius: 4px;
        }
      }
    }
  }
}

.adornment {
  display: flex;
  justify-content: flex-end;

  .units {
    color: var(--color-gray-600);
    padding: 0 16px 0 0;
  }
}

.spinControl {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid transparent;
  border-left-color: var(--color-gray-500);
  cursor: pointer;

  .delimiter {
    height: 0;
    width: 100%;
    border-bottom: 1px solid var(--color-gray-500);
  }
}

.spinControlDisabled {
  border: 1px solid var(--color-gray-600);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  .delimiter {
    border-bottom: 1px solid var(--color-gray-600);
  }
}

.disabled {
  background-color: var(--color-gray-200);
  border-radius: 4px;
}

.spinButtonDisabled {
  cursor: default;

  svg > path {
    stroke: var(--color-gray-600);
  }

  &:hover {
    svg > path {
      stroke: var(--color-gray-600);
    }
  }
}

.spinButton {
  height: 16px;
  padding: 0 6px;
  display: flex;
  align-items: center;
}

.spinButtonHovered {
  &:hover {
    svg > path {
      stroke: var(--color-blue-500);
    }
  }
}
