import { indexOf, append, remove as removeR, isEmpty as isEmptyR } from 'ramda';
import { defStruct } from '../../lib';

/**
 * @typedef SelectedList
 */
const methods = defStruct('SelectedList', ['ids']);

/**
 * Creates a selected list data type
 *
 * @param {object} params
 * @param {[number]} params.ids
 * @returns {SelectedList}
 */
export const makeSelectedList = ({ ids = [] } = {}) =>
  methods.makeSelectedList(ids);

/**
 * Gives list of id from selected list
 *
 * @param {SelectedList} selectedList
 * @returns {[number]}
 */
export const getIds = (selectedList) => methods.getIds(selectedList);

/**
 * Checks if is selected list
 *
 * @param {*} a
 * @returns {boolean}
 */
export const isSelectedList = (a) => methods.isSelectedList(a);

/**
 * Checks if id contains in selected list
 *
 * @param {number} id
 * @param {SelectedList} selectedList
 * @returns {boolean}
 */
export const contains = (id, selectedList) => {
  const indexOfId = indexOf(id, methods.getIds(selectedList));

  return indexOfId !== -1;
};

/**
 * Adds at selected list id
 *
 * @param {number} id
 * @param {SelectedList} selectedList
 * @returns {SelectedList}
 */
export const add = (id, selectedList) =>
  contains(id, selectedList)
    ? selectedList
    : makeSelectedList({ ids: append(id, methods.getIds(selectedList)) });

/**
 * Removes at selected list id
 *
 * @param {number} id
 * @param {SelectedList} selectedList
 * @returns {SelectedList}
 */
export const remove = (id, selectedList) => {
  const indexOfId = indexOf(id, methods.getIds(selectedList));

  if (indexOfId === -1) return selectedList;

  const selectedListWithoutId = removeR(
    indexOfId,
    1,
    methods.getIds(selectedList)
  );
  return makeSelectedList({ ids: selectedListWithoutId });
};

/**
 * Checks if selected list is empty
 *
 * @param {SelectedList} selectedList
 * @returns {boolean}
 */
export const isEmpty = (selectedList) => isEmptyR(methods.getIds(selectedList));

/**
 * Gives first value from selected list
 *
 * @param {SelectedList} selectedList
 * @returns {number}
 */
export const getFirst = (selectedList) => methods.getIds(selectedList)[0];

/**
 * Gives first value from selected list
 *
 * @param {number} number
 * @param {SelectedList} selectedList
 * @returns {boolean}
 */
export const lengthIsMoreThan = (number, selectedList) =>
  methods.getIds(selectedList).length > number;
