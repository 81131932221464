import { ToastId, ToastItem } from './types';

export interface ToastStorage {
  save: (toast: ToastItem) => void;
  delete: (toastId: ToastId) => void;
  clear: () => void;
  has: (toastId: ToastId) => boolean;
  getOneById: (toastId: ToastId) => ToastItem | undefined;
  getAllByCloseClickOutside: (closeOnClickOutside: boolean) => ToastItem[];
}

class MemoryToastStorage implements ToastStorage {
  private storage = new Map();

  clear(): void {
    this.storage.clear();
  }

  delete(toastId: ToastId): void {
    this.storage.delete(toastId);
  }

  save(toast: ToastItem): void {
    this.storage.set(toast.id, toast);
  }

  has(toastId: ToastId) {
    return this.storage.has(toastId);
  }

  getOneById(toastId: ToastId) {
    return this.storage.get(toastId);
  }

  getAllByCloseClickOutside(closeOnClickOutside: boolean) {
    return Array.from(this.storage.values()).filter(
      (toast) => toast.closeOnClickOutside === closeOnClickOutside
    );
  }
}

export const toastStorage = new MemoryToastStorage();
