export const subscribeToUpdateContactBlockInfoCase = ({
  subToBlockedContact,
  subToUnblockedContact,
  subToBlockedMe,
  subToUnblockedMe,
  toggleBlockContact,
  updateDataAfterToggleBlock,
  toggleArchive,
  getContactEmployeeId,
  getContactInfoByEmployeeId,
  getCompaniesInfo,
  toggleBlockMeOfCompInfo,
  getCompanyUsersList,
  toggleBlockMeOfEmployees,
  getDialogs,
  toggleBlockMe,
  updateDialogData
}) => {
  // blocked
  subToBlockedContact(({ targetEmployeeId }) =>
    toggleBlockContact({ employeeId: targetEmployeeId, isBlocked: true })
  );

  subToBlockedContact(({ dialog }) => {
    const params = { key: 'notice-msg.contact.blocked' };
    if (dialog && !dialog.archived) {
      params.linkKey = 'modal_archive_title';
      params.linkClickHandler = async () => toggleArchive(dialog);
    }
  });

  subToBlockedContact(({ targetEmployeeId }) =>
    updateDataAfterToggleBlock({
      employeeId: targetEmployeeId,
      isBlockedContact: true
    })
  );

  // unblocked
  subToUnblockedContact(({ targetEmployeeId }) =>
    toggleBlockContact({ employeeId: targetEmployeeId, isBlocked: false })
  );

  subToUnblockedContact(({ targetEmployeeId }) =>
    updateDataAfterToggleBlock({
      employeeId: targetEmployeeId,
      isBlockedContact: false
    })
  );

  // blockedMe
  subToBlockedMe(({ whoEmployeeId }) => {
    if (getContactEmployeeId() === whoEmployeeId) {
      getContactInfoByEmployeeId(whoEmployeeId);
    }
  });

  subToBlockedMe(({ whoEmployeeId }) => {
    if (!getCompaniesInfo().isEmpty()) {
      toggleBlockMeOfCompInfo({ employeeId: whoEmployeeId, isBlocked: true });
    }
  });

  subToBlockedMe(({ whoEmployeeId }) => updateDialogData(whoEmployeeId));

  subToBlockedMe(({ whoEmployeeId }) => {
    if (!getCompanyUsersList().isEmpty()) {
      toggleBlockMeOfEmployees({ employeeId: whoEmployeeId, isBlocked: true });
    }
  });

  subToBlockedMe(({ whoEmployeeId }) => {
    if (!getDialogs().isEmpty()) {
      toggleBlockMe({ employeeId: whoEmployeeId, isBlocked: true });
    }
  });

  // unblockedMe
  subToUnblockedMe(({ whoEmployeeId }) => {
    if (getContactEmployeeId() === whoEmployeeId) {
      getContactInfoByEmployeeId(whoEmployeeId);
    }
  });

  subToUnblockedMe(({ whoEmployeeId }) => {
    if (!getCompaniesInfo().isEmpty()) {
      toggleBlockMeOfCompInfo({ employeeId: whoEmployeeId, isBlocked: false });
    }
  });

  subToUnblockedMe(({ whoEmployeeId }) => updateDialogData(whoEmployeeId));

  subToUnblockedMe(({ whoEmployeeId }) => {
    if (!getCompanyUsersList().isEmpty()) {
      toggleBlockMeOfEmployees({ employeeId: whoEmployeeId, isBlocked: false });
    }
  });

  subToUnblockedMe(({ whoEmployeeId }) => {
    if (!getDialogs().isEmpty()) {
      toggleBlockMe({ employeeId: whoEmployeeId, isBlocked: false });
    }
  });
};
