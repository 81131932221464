.topPanel {
  width: 100%;
  background-color: var(--editable-table-header-background-color);
  padding: var(--padding-xs);

  &:empty,
  & > div:empty {
    display: none;
  }
}
