import React from 'react';

/* eslint-disable max-len */
function IconAdd() {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.335.028C3.072.407-.127 3.999.009 8.253a7.63 7.63 0 0 0 .404 2.267 7.848 7.848 0 0 0 1.934 3.133c1.237 1.237 2.749 1.992 4.559 2.274.492.077 1.636.083 2.121.011.979-.146 1.864-.425 2.648-.835 2.375-1.24 3.944-3.513 4.277-6.196.046-.372.045-1.452-.001-1.814-.255-1.974-1.109-3.642-2.55-4.982A7.669 7.669 0 0 0 11.533.838 7.713 7.713 0 0 0 8.977.065 10.782 10.782 0 0 0 7.335.028m1.644 1.383a6.666 6.666 0 0 1 5.162 4.002c.357.84.51 1.626.508 2.6-.001.628-.031.909-.158 1.475-.521 2.327-2.333 4.249-4.628 4.908a6.347 6.347 0 0 1-1.663.262 6.642 6.642 0 0 1-5.405-2.499 6.784 6.784 0 0 1-1.248-2.498C1.399 9.083 1.35 8.671 1.35 8c0-.668.049-1.083.195-1.654.653-2.552 2.784-4.506 5.366-4.919a7.102 7.102 0 0 1 2.068-.016M7.762 4.712a.717.717 0 0 0-.385.395c-.038.112-.044.272-.044 1.178v1.048H6.285c-1.193 0-1.224.005-1.419.2a.655.655 0 0 0 0 .934c.195.195.226.2 1.419.2h1.048v1.048c0 1.193.005 1.224.2 1.419.26.261.674.261.934 0 .195-.195.2-.226.2-1.419V8.667h1.048c1.193 0 1.224-.005 1.419-.2a.655.655 0 0 0 0-.934c-.195-.195-.226-.2-1.419-.2H8.667V6.285c0-1.193-.005-1.224-.2-1.419a.668.668 0 0 0-.705-.154"
        fill="#1C50DE"
        fillRule="evenodd"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconAdd;
