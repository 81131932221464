export const MAX_TEXT_LENGTH = 50;

export const changeTextCase = (
  { getChatInputText, setInputText },
  { text }
) => {
  const newText = text.slice(0, MAX_TEXT_LENGTH);

  if (getChatInputText() !== newText) setInputText(newText);
};
