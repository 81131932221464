import { assoc } from 'ramda';

const REDUX_KEY = 'PRWithResponseCompanyModal';
const SHOW = 'CW-PR-WITH-RESPONSE-COMPANY:SHOW';
const HIDE = 'CW-PR-WITH-RESPONSE-COMPANY:HIDE';

export const initState = {
  isShow: false,
  companyId: null
};

const actions = {
  show: (payload) => ({ type: SHOW, payload }),
  hide: () => ({ type: HIDE })
};

export const reducers = {
  [SHOW]: (state, payload) =>
    assoc(REDUX_KEY, { isShow: true, companyId: payload.companyId }, state),
  [HIDE]: (state) => assoc(REDUX_KEY, initState, state)
};

export const { show, hide } = actions;
