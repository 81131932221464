import React from 'react';
import Icons from '../constants';
import IconBlock from './block';
import IconGroup from './group';
import IconPurchase from './purchase';
import IconTopic from './topic';
import IconTask from './task';
import IconArrowDown from './arrow-down';
import IconAdd from './add1';
import IconPen from './pen';

export const Icons16x16 = {
  [Icons.Block]: <IconBlock />,
  [Icons.Group]: <IconGroup />,
  [Icons.Purchase]: <IconPurchase />,
  [Icons.Topic]: <IconTopic />,
  [Icons.Task]: <IconTask />,
  [Icons.ArrowDown]: <IconArrowDown />,
  [Icons.Add]: <IconAdd />,
  [Icons.Pen]: <IconPen />
};
