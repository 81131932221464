import React from 'react';

function IconData() {
  return (
    <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.225.416a.681.681 0 0 0-.343.386c-.018.062-.032.326-.032.585v.473H4.788c-.801 0-1.117.011-1.283.043-1.015.199-1.776.907-2.072 1.928l-.068.234v10.89l.083.264c.157.501.377.856.75 1.21.342.325.68.51 1.192.652.223.062.265.063 5.445.072 3.68.007 5.295 0 5.473-.024 1.074-.14 1.913-.854 2.244-1.91l.083-.264.008-5.348c.008-5.181.006-5.356-.05-5.631a2.632 2.632 0 0 0-2.098-2.073c-.166-.032-.482-.043-1.283-.043H12.15v-.473c0-.535-.024-.653-.159-.808a.65.65 0 0 0-.976.005.842.842 0 0 0-.127.186c-.015.041-.028.303-.028.582v.508H7.14v-.511c0-.444-.008-.526-.057-.623a.663.663 0 0 0-.858-.31M5.85 3.367c0 .28.04.418.159.554.25.285.7.294.955.019.141-.152.176-.257.176-.534V3.15h3.72v.256c0 .284.048.417.2.558a.586.586 0 0 0 .436.175.643.643 0 0 0 .495-.218c.119-.136.159-.274.159-.554V3.15h.999c1.087 0 1.205.014 1.504.173.215.115.447.361.556.59.112.234.149.489.15 1.029l.001.398H2.64l.001-.398c0-.218.014-.496.029-.617.071-.542.46-.984.993-1.128.126-.033.389-.043 1.174-.045L5.85 3.15v.217m9.509 7.186c0 2.324-.013 3.982-.03 4.115a1.34 1.34 0 0 1-.914 1.112c-.172.059-.249.06-5.418.06H3.753l-.198-.072a1.332 1.332 0 0 1-.884-1.083c-.019-.132-.03-1.655-.03-4.118L2.64 6.66h12.72l-.001 3.893"
        fill="#5B5F73"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default IconData;
