import React, { useMemo, useRef } from 'react';
import { setRef } from '../lib/utils';

export function useExternRef<T>(
  ...externRefs: Array<React.Ref<T> | undefined | false>
): React.MutableRefObject<T | null> {
  const stableRef = useRef<T | null>(null);

  return useMemo(
    () => ({
      get current() {
        return stableRef.current;
      },
      set current(element) {
        stableRef.current = element;
        externRefs.forEach((ref) => {
          if (ref) {
            setRef(element, ref);
          }
        });
      }
    }),
    externRefs
  );
}
