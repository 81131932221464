import {
  getChatArchive,
  getChatBlock,
  getChatMode,
  getChatMsgs,
  getCurrEmplId,
  maybeGetOpenedChatId
} from '@/components/ChatWidget/getter';
import { canLoadNext } from '@/components/ChatWidget/data-type/lists-kit';
import {
  getItem,
  updateItem
} from '@/components/ChatWidget/data-type/block-list';
import { clearUnreadMsg } from 'core/data/light/mainChannel';
import { ac } from '@/components/ChatWidget/useCases/shared/actions';
import { sckt } from '@/components/ChatWidget/useCases/shared/socket';
import { uploadMessages } from '@/components/ChatWidget/useCases/uploadMessages';
import { getChatGroups } from '@/components/ChatWidget/useCases/getChatGroups';
import { writeUnsentChatMessagesToLS } from '@/components/ChatWidget/useCases/writeUnsentChantMessageToLS';
import { addMsgToUnread } from '@/components/ChatWidget/useCases/addMsgToUnread';

export const subscribeToUpdateChat = (chatId) => (dispatch, getState) => {
  sckt.joinToChat(chatId);
  sckt.subscribeToUpdate((chat) => {
    const state = getState();

    const employeeId = getCurrEmplId(state);

    if (
      Array.isArray(chat.lastMessage?.accessList) &&
      !chat.lastMessage.accessList.includes(employeeId)
    ) {
      const chatList = getChatBlock(state);
      const prevChat = getItem(chatList, chatId) ?? {};

      dispatch(
        ac.updateChat({ ...chat, lastMessage: prevChat.lastMessage ?? null })
      );
      return;
    }

    dispatch(ac.updateChat(chat));
  });
  sckt.subscribeToNewMsg(({ message, channelId, unread, deliveryId }) => {
    const state = getState();

    const employeeId = getCurrEmplId(state);
    const msgs = getChatMsgs(state);

    if (unread) {
      dispatch(addMsgToUnread(channelId, message.id));
    }

    dispatch(
      ac.removeUnsentMessage({
        chatId: channelId,
        employeeId,
        message,
        deliveryId
      })
    );
    dispatch(writeUnsentChatMessagesToLS());

    if (
      Array.isArray(message.accessList) &&
      !message.accessList.includes(employeeId)
    ) {
      return;
    }

    // TODO: Временный фикс кейса, когда на сервере осталась подписка на один из посещенных ранее каналов
    //  и теперь сообщения из тех каналов могут попадать в текущий https://jira.istock.info/browse/UN-8306
    const openedChatId = maybeGetOpenedChatId(state);

    if (!canLoadNext(msgs) && openedChatId === channelId) {
      dispatch(ac.addOrUpdateMsg(message));
    }
  });
  sckt.subToAddUnreadMsg(({ channelId, messageId }) =>
    dispatch(addMsgToUnread(channelId, messageId))
  );
  sckt.subToClearUnreadMsg(({ channelId: id }) => {
    const state = getState();
    const chatList = getChatBlock(state);
    const chat = getItem(chatList, id);
    const archivesList = getChatArchive(state);
    const archive = getItem(archivesList, id);
    if (chat) {
      const updatedChat = clearUnreadMsg(chat);
      dispatch(ac.updateChat({ ...updatedChat, haveNewMessages: false }));
    } else if (archive) {
      const updatedArhive = clearUnreadMsg(archive);
      const updateArchives = updateItem(archivesList, updatedArhive);
      dispatch(ac.setArchives(updateArchives));
    }
  });
  sckt.subscribeToUpdateMsg(({ channelId, message }) => {
    const employeeId = getCurrEmplId(getState());

    dispatch(ac.updateMsg(message));
    dispatch(
      ac.removeUnsentMessage({
        chatId: channelId,
        employeeId,
        message,
        deliveryId: message.deliveryId
      })
    );
    dispatch(writeUnsentChatMessagesToLS());
    const mode = getChatMode(getState());
    if (mode.currentMode === 'favorites') {
      dispatch(ac.updateFavotireMsg(message));
    }
  });
  sckt.subscribeToRemoveMsg(({ messageId }) =>
    dispatch(ac.removeMessage(messageId))
  );
  sckt.subscribeToNeedUpdate(({ channelId, type }) => {
    switch (type) {
      case 'messages':
        dispatch(uploadMessages({ id: channelId }));
        break;
      case 'dialogsListCounts':
        dispatch(getChatGroups());
        break;
      default:
        break;
    }
  });
  sckt.subscribeToRemoveUnsentMessage(({ channelId, deliveryId }) => {
    const employeeId = getCurrEmplId(getState());

    dispatch(
      ac.removeUnsentMessage({
        chatId: channelId,
        employeeId,
        deliveryId
      })
    );
    dispatch(writeUnsentChatMessagesToLS());
  });
};
