import { assoc, append, last, dropLast, isEmpty } from 'ramda';

export const DEFAULT_MODE = 'none';
export const DEFAULT_HISTORY = [];

/**
 * @typedef Mode
 * @property {string} type
 * @property {string} currentMode
 * @property {[string]} history
 */

/**
 * Creates a mode data type
 *
 * @param {object} param
 * @param {string} [param.defaultMode=DEFAULT_MODE]
 * @param param.history
 * @returns {Mode}
 */
const makeMode = ({
  defaultMode = DEFAULT_MODE,
  history = DEFAULT_HISTORY
} = {}) => ({
  type: 'Mode',
  currentMode: defaultMode,
  history
});

/**
 * Sets a new mode
 * not save same mode
 *
 * @param {Mode} mode
 * @param {string} newMode
 * @returns {Mode}
 */
const setMode = (mode, newMode = DEFAULT_MODE) => {
  if (mode.currentMode === newMode) return mode;

  const updatedHistory = assoc(
    'history',
    append(mode.currentMode, mode.history),
    mode
  );
  return assoc('currentMode', newMode, updatedHistory);
};

/**
 * Return previous mode
 *
 * @param {Mode} mode
 * @returns {Mode}
 */
const prevMode = (mode) => {
  if (isEmpty(mode.history)) return mode;

  const lastMode = last(mode.history);
  const newHistory = dropLast(1, mode.history);

  const updatedHistory = assoc('history', newHistory, mode);
  return assoc('currentMode', lastMode, updatedHistory);
};

/**
 * Determine if current mode is modeName
 *
 * @param {Mode} mode
 * @param {string} modeName
 * @returns {boolean}
 */
const isCurrentMode = (mode, modeName) => mode.currentMode === modeName;

/**
 * Determine if previous mode is modeName
 *
 * @param {Mode} mode
 * @param {string} modeName
 * @returns {boolean}
 */
const isPrevMode = (mode, modeName) => {
  if (isEmpty(mode.history)) return false;
  return last(mode.history) === modeName;
};

const currentMode = (mode) => mode.currentMode;

export { makeMode, setMode, prevMode, currentMode, isCurrentMode, isPrevMode };
