import { useMemo } from 'react';
import cn from 'classnames';
import { Divider as MuiDivider, StyledEngineProvider } from '@mui/material';

import styles from './divider.module.scss';

export interface DividerProps {
  className?: string;
  orientation?: 'horizontal' | 'vertical';
  variant?: 'no' | 'xxs' | 'xs' | 's' | 'm' | 'l';
}
export function Divider({
  className,
  orientation = 'horizontal',
  variant = 'no'
}: DividerProps) {
  const dividerClasses = useMemo(
    () => ({
      root: cn(styles.root, styles[`${orientation}-${variant}`], className)
    }),
    [className, variant, orientation]
  );
  return (
    <StyledEngineProvider injectFirst>
      <MuiDivider
        data-testid="divider"
        classes={dividerClasses}
        orientation={orientation}
        flexItem={orientation === 'vertical'}
      />
    </StyledEngineProvider>
  );
}
