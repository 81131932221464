import React from 'react';

/* eslint-disable max-len */
const IconAddManCircle = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      className="cws-icon-hover"
      cx="17"
      cy="17"
      r="17"
      fill="#767B92"
      fillOpacity="0"
    />
    <rect
      className="cws-icon-main-circle-stroke"
      x="0.5"
      y="0.5"
      width="33"
      height="33"
      rx="16.5"
      stroke="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      d="M16.3333 24.9706C16.6095 24.9706 16.8333 24.7468 16.8333 24.4706C16.8333 24.1945 16.6095 23.9706 16.3333 23.9706V24.9706ZM9 22.633L8.50294 22.5789L8.5 22.6059V22.633H9ZM11.6667 19.5703L11.7786 20.0576L11.6667 19.5703ZM14.1586 17.6434C14.1093 17.3717 13.8491 17.1914 13.5774 17.2407C13.3057 17.29 13.1254 17.5502 13.1747 17.8219L14.1586 17.6434ZM18.8158 11.3158V13.6681H19.8158V11.3158H18.8158ZM13.1842 13.6681V11.3158H12.1842V13.6681H13.1842ZM16 16.4839C14.4449 16.4839 13.1842 15.2233 13.1842 13.6681H12.1842C12.1842 15.7755 13.8926 17.4839 16 17.4839V16.4839ZM18.8158 13.6681C18.8158 15.2233 17.5551 16.4839 16 16.4839V17.4839C18.1074 17.4839 19.8158 15.7755 19.8158 13.6681H18.8158ZM16 8.5C17.5551 8.5 18.8158 9.76067 18.8158 11.3158H19.8158C19.8158 9.20838 18.1074 7.5 16 7.5V8.5ZM16 7.5C13.8926 7.5 12.1842 9.20838 12.1842 11.3158H13.1842C13.1842 9.76067 14.4449 8.5 16 8.5V7.5ZM16.3333 23.9706C14.3369 23.9706 12.5463 23.7674 11.2694 23.4474C10.6271 23.2864 10.1428 23.1028 9.83114 22.9181C9.48632 22.7138 9.5 22.6014 9.5 22.633H8.5C8.5 23.1721 8.92408 23.543 9.32143 23.7784C9.75192 24.0335 10.3416 24.2458 11.0263 24.4174C12.4036 24.7625 14.2796 24.9706 16.3333 24.9706V23.9706ZM9.49706 22.6871C9.55246 22.1781 9.63026 21.6512 9.92429 21.1869C10.2042 20.7448 10.7207 20.3006 11.7786 20.0576L11.5547 19.083C10.2843 19.3748 9.52296 19.9515 9.07945 20.6519C8.65002 21.33 8.55865 22.0669 8.50294 22.5789L9.49706 22.6871ZM11.7786 20.0576C12.5284 19.8854 13.1417 19.5368 13.5605 19.1212C13.9594 18.7254 14.2569 18.185 14.1586 17.6434L13.1747 17.8219C13.1876 17.8929 13.1517 18.1181 12.8561 18.4114C12.5805 18.6849 12.1383 18.9489 11.5547 19.083L11.7786 20.0576ZM18 22H25V21H18V22ZM21 18V25H22V18H21Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconAddManCircle;
