import React, { useMemo } from 'react';
import Text from 'react-components/src/components/Typography';
import { format } from 'date-fns';
import UserMessageLayout from '../../../layouts/UserMessageLayout';

const TranscriptMessage = ({ id, message, avatarSize = 30 }) => {
  const avatar = useMemo(
    () => ({
      show: true,
      size: avatarSize,
      info: {
        type: 'user',
        subType: 'avatar-in-chat',
        src: message.avatarSrc,
        alt: message.userName,
        employeeId: message.employeeId
      }
    }),
    [message.avatarSrc, message.id]
  );

  return (
    <UserMessageLayout
      id={id}
      className="cwm-msg_theme-transcript-chat"
      bgColor="gray-1"
      avatar={avatar}
      header={
        <div style={{ padding: '8px 0 4px' }}>
          <Text variant="body1Med">{message.userName}</Text>
        </div>
      }
      content={
        <>
          <div>{message.text}</div>
          <div className="cwm-um-footer">
            <div className="cwm-um-footer__time cwm-um-footer__time_show">
              {format(new Date(message.time), 'hh:mm')}
            </div>
          </div>
        </>
      }
    />
  );
};

export default React.memo(TranscriptMessage);
