import { equals, find, append, remove, findIndex, curry } from 'ramda';
import { defStruct } from '../../lib';
import { getLabel, getKey } from '../configItem';

/**
 * @typedef Configs - configuration list
 * @typedef {import('../configItem')} ConfigItem
 */
const methods = defStruct('Configs', ['list']);

/**
 * Create a configuration list data type
 *
 * @param {[ConfigItem]} list
 * @returns {Configs}
 */
export const makeConfigs = (list = []) => methods.makeConfigs(list);

/**
 * Gives a list of configuration items
 *
 * @param {Configs} configs
 * @returns {[ConfigItem]}
 */
export const getList = (configs) => methods.getList(configs);

/**
 * Checks if is Configs
 *
 * @param {*} a
 * @returns {boolean}
 */
export const isConfigs = (a) => methods.isConfigs(a);

/**
 *
 * @param {string} label
 * @param {string} key
 * @param {ConfigItem} configItem
 * @returns {boolean}
 */
export const isEqualItem = curry(
  (label, key, configItem) =>
    equals(getLabel(configItem), label) && equals(getKey(configItem), key)
);

/**
 * Find a configuration item
 *
 * @param {string} label
 * @param {string} key
 * @param {Configs} configs
 * @returns {ConfigItem}
 */
export const findItem = (label, key, configs) =>
  find(isEqualItem(label, key), getList(configs));

/**
 * Set item in configs list
 *
 * @param {ConfigItem} configItem
 * @param {Configs} configs
 * @returns {Configs}
 */
export const setItem = (configItem, configs) => {
  let list = getList(configs);

  const label = getLabel(configItem);
  const key = getKey(configItem);
  const index = findIndex(isEqualItem(label, key), list);
  if (index > -1) {
    list = remove(index, 1, list);
  }

  return makeConfigs(append(configItem, list));
};

/**
 * Merge two configs list
 *
 * @param {Configs} configOne
 * @param {Configs} configTwo
 * @returns {Configs}
 */
export const mergeConfigs = (configOne, configTwo) =>
  makeConfigs(
    getList(
      getList(configTwo).reduce(
        (acc, configItem) => setItem(configItem, acc),
        configOne
      )
    )
  );
