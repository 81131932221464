import React from 'react';

/* eslint-disable max-len */
const IconAddM = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      className="cws-icon-hover"
      width="28"
      height="28"
      rx="14"
      fill="#767B92"
      fillOpacity="0"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0001 14.5002C20.0001 14.7764 19.7762 15.0002 19.5001 15.0002L14.5001 15.0003L14.5001 20.0005C14.5001 20.2766 14.2762 20.5005 14.0001 20.5005C13.7239 20.5005 13.5001 20.2766 13.5001 20.0005L13.5001 15.0003L8.5 15.0003C8.22386 15.0003 8 14.7764 8 14.5003C8 14.2241 8.22385 14.0003 8.5 14.0003L13.5001 14.0003V9.00049C13.5001 8.72435 13.7239 8.50049 14.0001 8.50049C14.2762 8.50049 14.5001 8.72435 14.5001 9.00049V14.0003L19.5001 14.0002C19.7762 14.0002 20.0001 14.2241 20.0001 14.5002Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconAddM;
