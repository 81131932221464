import React from 'react';

/* eslint-disable max-len */
const IconVideoCall = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      className="cws-icon-hover"
      cx="17"
      cy="17"
      r="17"
      fill="#767B92"
      fillOpacity="0"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 11H10C9.44772 11 9 11.4477 9 12V22C9 22.5523 9.44772 23 10 23H20C20.5523 23 21 22.5523 21 22V12C21 11.4477 20.5523 11 20 11ZM10 10C8.89543 10 8 10.8954 8 12V22C8 23.1046 8.89543 24 10 24H20C21.1046 24 22 23.1046 22 22V12C22 10.8954 21.1046 10 20 10H10Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 13.8966V20.1034L25 21.4534V12.5466L22 13.8966ZM21 20.75L24.5896 22.3653C25.2514 22.6631 26 22.1791 26 21.4534V12.5466C26 11.8209 25.2514 11.3369 24.5896 11.6347L21 13.25V20.75Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconVideoCall;
