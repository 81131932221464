import { assoc, isNil } from 'ramda';
import { defStruct } from '../../lib';

/**
 * @typedef WhereParamItem
 * @typedef {import('../whereParams')} WhereParams
 * @typedef EqualToOneOf
 * @typedef EqualOne
 * @typedef ILike
 * @typedef Or
 * @typedef Between
 */

const makeWhereParam = (type) => defStruct(type, ['field', 'value']);

const equalToOneOfMethods = makeWhereParam('EqualToOneOf');
const equalToOneMethods = makeWhereParam('EqualOne');
const iLikeMethods = makeWhereParam('ILike');
const orMethods = defStruct('Or', ['whereParams']);
const betweenMethods = defStruct('Between', ['field', 'from', 'to']);

/**
 * Creates a equal to one of data type, is sub type of where param item
 *
 * @param {string} field
 * @param {*} value
 * @returns {EqualToOneOf}
 */
export const makeEqualToOneOf = (field, value) =>
  equalToOneOfMethods.makeEqualToOneOf(field, value);

/**
 * Creates a equal one data type, is sub type of where param item
 *
 * @param {string} field
 * @param {*} value
 * @returns {EqualOne}
 */
export const makeEqualOne = (field, value) =>
  equalToOneMethods.makeEqualOne(field, value);

/**
 * Creates a i like data type, is sub type of where param item
 *
 * @param {string} field
 * @param {*} value
 * @returns {ILike}
 */
export const makeILike = (field, value) => iLikeMethods.makeILike(field, value);

/**
 * Creates a "or" data type, is sub type of where param item
 *
 * @param {WhereParams} whereParams
 * @returns {Or}
 */
export const makeOr = (whereParams) => orMethods.makeOr(whereParams);

/**
 * Creates a between data type
 *
 * @param {object} params
 * @param {*} params.from
 * @param {*} params.to
 * @returns {Between}
 */
export const makeBetween = ({ field, from, to } = {}) =>
  betweenMethods.makeBetween(field, from, to);

/**
 * Gives a from
 *
 * @param {Between} between
 * @returns {*}
 */
export const getFrom = (between) => betweenMethods.getFrom(between);

/**
 * Gives a to
 *
 * @param {Between} between
 * @returns {*}
 */
export const getTo = (between) => betweenMethods.getTo(between);

/**
 * Checks if has from
 *
 * @param {Between} between
 * @returns {boolean}
 */
export const hasFrom = (between) => !isNil(betweenMethods.getFrom(between));

/**
 * Checks if has to
 *
 * @param {Between} between
 * @returns {boolean}
 */
export const hasTo = (between) => !isNil(betweenMethods.getTo(between));

/**
 * Checks if is between
 *
 * @param {*} a
 * @returns {boolean}
 */
export const isBetween = (a) => betweenMethods.isBetween(a);

/**
 * Gives value from where parameter item
 *
 * @param {WhereParamItem} whereParamItem
 * @returns {string}
 */
export const getField = (whereParamItem) => whereParamItem.field;

/**
 * Gives value from where parameter item
 *
 * @param {WhereParamItem} whereParamItem
 * @returns {*}
 */
export const getValue = (whereParamItem) => whereParamItem.value;

/**
 * Gives the list of where parameters from or data type
 *
 * @param {Or} or
 * @returns {WhereParams}
 */
export const getWhereParams = (or) => orMethods.getWhereParams(or);

/**
 * Return a new where parameter item with consumes filed name
 *
 * @param {string} fieldName
 * @param {WhereParamItem} whereParamItem
 * @returns {WhereParamItem}
 */
export const setField = (fieldName, whereParamItem) =>
  assoc('field', fieldName, whereParamItem);

/**
 * Checks if is equal to one of
 *
 * @param {*} a
 * @returns {boolean}
 */
export const isEqualToOneOf = (a) => equalToOneOfMethods.isEqualToOneOf(a);

/**
 * Checks if is equal one
 *
 * @param {*} a
 * @returns {boolean}
 */
export const isEqualOne = (a) => equalToOneMethods.isEqualOne(a);

/**
 * Checks if is i like
 *
 * @param {*} a
 * @returns {boolean}
 */
export const isILike = (a) => iLikeMethods.isILike(a);

/**
 * Checks if is or
 *
 * @param {*} a
 * @returns {boolean}
 */
export const isOr = (a) => orMethods.isOr(a);

/**
 * Checks if is where parameter item
 *
 * @param {*} a
 * @returns {boolean}
 */
export const isWhereParamItems = (a) =>
  equalToOneOfMethods.isEqualToOneOf(a) ||
  equalToOneMethods.isEqualOne(a) ||
  iLikeMethods.isILike(a) ||
  orMethods.isOr(a);
