import { noop } from '@/utils/utils';
import config from '../config';
import { createMediaStreamFake, hasStreamAudioTrack } from '../utils';

export class Media {
  static async init(props) {
    const { video } = props || config;

    const stream = await createMediaStream(video);

    return new Media({ ...props, stream });
  }

  constructor({ config: _config, stream, onUpdateTrack = noop, ...restProps }) {
    this.video = _config || config.video;
    this.stream = stream;
    this.onUpdateTrack = onUpdateTrack;

    this.props = restProps;
  }

  static hasCameraPermission() {
    return hasCameraPermission();
  }

  static hasMicrophonePermission() {
    return hasMicrophonePermission();
  }

  get isStreamActive() {
    return Boolean(this.stream?.active);
  }

  async startStream({ video, audio } = {}) {
    console.info('call startStream [Media]');
    let _video;

    if (video === false) {
      _video = false;
    } else if (video === true) {
      _video = this.video;
    } else {
      _video = video ?? this.video;
    }

    if (!this.stream) {
      this.stream = await createMediaStream(_video, audio);
    }

    return this.stream;
  }

  endStream() {
    if (this.stream) {
      this._destroyStream();
    }
  }

  _destroyStream() {
    this.stream.getTracks().forEach((track) => track.stop());
    this.stream = null;
  }

  async toggleVideo(isEnabled = false) {
    if (!this.stream) {
      console.error("Can't toggle the video because have not stream");
      return;
    }

    const currentTrack = this.stream.getVideoTracks()[0];
    if (isEnabled) {
      if (currentTrack && currentTrack.readyState === 'ended') {
        const newTrack = (
          await navigator.mediaDevices.getUserMedia({ video: true })
        ).getVideoTracks()[0];
        this.stream.removeTrack(currentTrack);
        this.stream.addTrack(newTrack);

        this.onUpdateTrack(newTrack);
      }
    } else {
      currentTrack.stop();
    }
  }

  toggleAudio(isEnabled = false) {
    if (hasStreamAudioTrack(this.stream)) {
      this.stream.getAudioTracks()[0].enabled = isEnabled;
    } else {
      console.error("Can't toggle the audio because have not stream");
    }
  }
}

function createBlankVideoStream(opts = {}) {
  const { width = 480, height = 270 } = opts;

  const canvas = Object.assign(document.createElement('canvas'), {
    width,
    height
  });

  canvas
    .getContext('2d', { willReadFrequently: true })
    .fillRect(0, 0, width, height);

  return canvas.captureStream();
}

function modifyStreamWithEmptyVideoTrack(stream, opts) {
  const audioTrack = stream.getAudioTracks()[0];

  const videoStream = createBlankVideoStream(opts);

  videoStream.addTrack(audioTrack);

  return videoStream;
}

async function createMediaStream(video = true, audio = true) {
  try {
    return await getUserMedia({ video, audio });
  } catch (_) {
    try {
      if (!audio) throw new Error();

      // fixme this is temporary fix of this problem https://github.com/peers/peerjs/issues/944
      const streamWithoutVideo = await getUserMedia({ video: false, audio });
      const stream = modifyStreamWithEmptyVideoTrack(streamWithoutVideo, video);
      stream.getVideoTracks()[0].stop();
      return stream;
    } catch (_0) {
      try {
        return await getUserMedia({ video, audio: false });
      } catch (e) {
        console.error('Failed to get local stream', e);

        return createMediaStreamFake();
      }
    }
  }
}

async function getUserMedia({ video = true, audio = true } = {}) {
  return navigator.mediaDevices.getUserMedia({ video, audio });
}

async function hasCameraPermission() {
  try {
    await getUserMedia({ video: true, audio: false });
    return true;
  } catch (e) {
    return false;
  }
}

async function hasMicrophonePermission() {
  try {
    await getUserMedia({ video: false, audio: true });
    return true;
  } catch (e) {
    return false;
  }
}
