import { bindActionCreators } from 'redux';
import { makeCommand, makeEvent } from 'core/data/messageBus/message';
import { handle } from 'core/data/messageBus/frontend';
import { emitToServer } from '@/storage/message';
import { showModal } from '@/action-creators/modal';
import { setSuccessMessage } from '@/action-creators/message';
import { getContactInfoByEmployeeId } from '@/action-creators/contacts';
import { applyState } from '../utils';
import { getChatContact, getContactFilter } from '../contacts/getters';
import { getDialogInfo } from '../dialogInfo/getters';
import * as storage from './storage';
import * as getters from './getters';
import * as actions from './actions';
import * as contactActions from '../contacts/actions';
import * as dialogInfoActions from '../dialogInfo/actions';
import * as profileActions from '../profile/actions';
import * as dialogInfoUC from '../dialogInfo';
// NOTE: Будет исправлено после вынесения toggleArchive в отдельный модуль
import { toggleArchive } from '../../useCases/toggleArchive';
import { toggleBlockCase } from './useCases/toggleBlock';
import { toggleBlockModalCase } from './useCases/toggleBlockModal';
import { subscribeToUpdateContactBlockInfoCase } from './useCases/subscribeToUpdateContactBlockInfo';
import { unsubscribeFromUpdateContactBlockInfoCase } from './useCases/unsubscribeFromUpdateContactBlockInfo';
import { updateDateAfterToggleBlockCase } from './useCases/updateDataAfterToggleBlock';
import { getAdvancedSearch, getChatWidget } from '../../getter';
import { getProfile } from '../profile/getters';

const NS = 'contact';
const toggleBlockCMD = makeCommand(NS, 'toggleBlock');
const blockedEVT = makeEvent(NS, 'blocked-front');
const unblockedEVT = makeEvent(NS, 'unblocked-front');
const blockedMeEVT = makeEvent(NS, 'blockedMe');
const unblockedMeEVT = makeEvent(NS, 'unblockedMe');

export const toggleBlock = (employeeId) => () =>
  toggleBlockCase({ toggleBlock: storage.toggleBlock }, { employeeId });

export const toggleBlockModal =
  (employeeId, availableFilterList = ['blocked']) =>
  (dispatch, getState) =>
    toggleBlockModalCase(
      {
        toggleBlock: () => emitToServer(toggleBlockCMD, { employeeId }),
        getDialogInfo: applyState(getState, getDialogInfo),
        getChatContact: applyState(getState, getChatContact),
        getChatWidget: applyState(getState, getChatWidget),
        showModal: (props) => dispatch(showModal('CW_BLOCK_CONTACT', props)),
        getContactFilter: applyState(getState, getContactFilter),
        updateContacts: (props) =>
          dispatch(contactActions.updateContacts(props))
      },
      { employeeId, availableFilterList }
    );

const updateDateAfterToggleBlock =
  ({ employeeId, isBlockedContact }) =>
  (dispatch, getState) =>
    updateDateAfterToggleBlockCase(
      {
        getAdvancedSearch: applyState(getState, getAdvancedSearch),
        getChatContact: applyState(getState, getChatContact),
        getDialogInfo: applyState(getState, getDialogInfo),
        getProfile: applyState(getState, getProfile),
        updateContact: (props) => dispatch(contactActions.updateContact(props)),
        setDialogInfo: (props) =>
          dispatch(dialogInfoActions.setDialogInfo(props)),
        showProfile: (props) => dispatch(profileActions.showProfile(props))
      },
      { employeeId, isBlockedContact }
    );

export const subscribeToUpdateContactBlockInfo = () => (dispatch, getState) =>
  subscribeToUpdateContactBlockInfoCase({
    subToBlockedContact: (cb) => handle(blockedEVT, cb),
    subToUnblockedContact: (cb) => handle(unblockedEVT, cb),
    subToBlockedMe: (cb) => handle(blockedMeEVT, cb),
    subToUnblockedMe: (cb) => handle(unblockedMeEVT, cb),
    toggleBlockContact: (props) => dispatch(actions.toggleBlockContact(props)),
    updateDataAfterToggleBlock: (props) =>
      dispatch(updateDateAfterToggleBlock(props)),
    updateDialogData: bindActionCreators(
      dialogInfoUC.updateDialogInfoByEmployeeId,
      dispatch
    ),
    setSuccessMessage: (props) => dispatch(setSuccessMessage(props)),
    toggleArchive: (chat) => dispatch(toggleArchive(chat)),
    getContactEmployeeId: applyState(getState, getters.getContactEmployeeId),
    getCompaniesInfo: applyState(getState, getters.getCompaniesInfo),
    getCompanyUsersList: applyState(getState, getters.getCompanyUsersList),
    getDialogs: applyState(getState, getters.getDialogs),
    getContactInfoByEmployeeId: (employeeId) =>
      dispatch(getContactInfoByEmployeeId(employeeId)),
    toggleBlockMe: (props) => dispatch(actions.toggleBlockMe(props)),
    toggleBlockMeOfCompInfo: (props) =>
      dispatch(actions.toggleBlockMeOfCompInfo(props)),
    toggleBlockMeOfEmployees: (props) =>
      dispatch(actions.toggleBlockMeOfEmployees(props))
  });

export const unsubscribeFromUpdateContactBlockInfo = () => () =>
  unsubscribeFromUpdateContactBlockInfoCase({
    unsubFromBlockedContact: storage.unsubFromBlockedContact,
    unsubFromBlockedMe: storage.unsubFromBlockedMe
  });
