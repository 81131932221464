import { PREFIX } from '../constants';

export const REDUX_KEY = 'delayedMessages';
export const SET_DELAYED_MESSAGES = `${PREFIX}:DELAYED_MESSAGES:SET_MESSAGES`;
export const ADD_CHUNK_DELAYED_MESSAGES = `${PREFIX}:DELAYED_MESSAGES:ADD_CHUNK_MESSAGES`;
export const ADD_DELAYED_MESSAGE = `${PREFIX}:DELAYED_MESSAGES:ADD_MESSAGE`;
export const UPDATE_DELAYED_MESSAGE = `${PREFIX}:DELAYED_MESSAGES:UPDATE_MESSAGE`;
export const DELETE_DELAYED_MESSAGE = `${PREFIX}:DELAYED_MESSAGES:DELETE_MESSAGE`;
export const CLEAR_DELAYED_MESSAGES = `${PREFIX}:DELAYED_MESSAGES:CLEAR_MESSAGES`;
export const SET_PLANNED_DATE = `${PREFIX}:DELAYED_MESSAGES:SET_PLANNED_DATE`;
export const CLEAR_PLANNED_DATE = `${PREFIX}:DELAYED_MESSAGES:CLEAR_PLANNED_DATE`;
