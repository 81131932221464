import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { usePermissions } from '@/modules/permissions';
import { ProtectedRoute } from '@/components/PrivateRoute';
import { ConstructorView } from '@/pages/constructor/view';
import { BuilderPage } from '@/pages/constructor/builder';

export const ConstructorRouters = () => {
  const permissions = usePermissions();

  const haveRoutesEditPermissions = permissions.haveRoutesEditPermissions();

  return (
    <Routes>
      <Route path="/" element={<ConstructorView />} />
      <Route
        path="create/:type"
        element={
          <ProtectedRoute isAllowed={haveRoutesEditPermissions}>
            <BuilderPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="edit/:id"
        element={
          <ProtectedRoute isAllowed={haveRoutesEditPermissions}>
            <BuilderPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
