export enum AppSection {
  Administration = 'administration',
  AdministrationCompanies = 'administrationCompanies',
  AdministrationUsers = 'administrationUsers',
  AdministrationSettings = 'administrationSettings',
  AdministrationInboxes = 'administrationInboxes',
  AdministrationModeration = 'administrationModeration',
  AllProcurements = 'allProcurements',
  MyOffice = 'myOffice',
  MyProcurements = 'myProcurements',
  MyProposals = 'myProposals',
  AccreditationRequests = 'accreditationRequests',
  PartnershipRequests = 'partnershipRequests',
  Needs = 'needs',
  SupplierManagement = 'supplierManagement',
  QualificationRequests = 'qualificationRequests',
  QualifiedSuppliers = 'qualifiedSuppliers',
  VendorsEvaluation = 'vendorsEvaluation',
  Accreditation = 'accreditation',
  PartnersCustomer = 'partnersCustomer',
  PartnersSupplier = 'partnersSupplier',
  ProfileEdit = 'profileEdit',
  Companies = 'companies',
  CompanyProfile = 'companyProfile',
  Messenger = 'messenger',
  MyCompany = 'myCompany',
  Warehouses = 'warehouses',
  CompanyEmployees = 'companyEmployees',
  CompanyRoleManagement = 'companyRoleManagement',
  CompanyFinance = 'companyFinance',
  Tools = 'tools',
  Routes = 'routes',
  Integrations = 'integrations',
  Tasks = 'tasks',
  SettingsNotifications = 'settingsNotifications',
  SettingsGeneral = 'settingsGeneral',
  ProcurementManagement = 'procurementManagement',
  ProcurementManagementScrips = 'procurementManagementScrips',
  ProcurementManagementFormSettings = 'procurementManagementFormSettings',
  Constructor = 'constructor',
  Goods = 'goods',
  InboxOrders = 'inboxOrders',
  OutboxOrders = 'outboxOrders',
  Cart = 'cart',
  CostAccounting = 'costAccounting',
  CostObjects = 'costObjects',
  CostsDistribution = 'costsDistribution',
  CostAnalysis = 'costAnalysis'
}
