import React from 'react';

/* eslint-disable max-len */
function IconAlarmActive() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="14" height="14" fill="white" />
      <path
        className="cws-icon-main-fill"
        d="M2.3222 12.4026C2.10489 12.6883 2.16035 13.0961 2.44609 13.3134C2.73182 13.5307 3.13963 13.4753 3.35694 13.1895L2.3222 12.4026ZM10.1117 11.4174L9.69759 10.9164L10.1117 11.4174ZM10.7534 13.2107C10.9824 13.4872 11.3922 13.5256 11.6686 13.2966C11.9451 13.0676 11.9835 12.6578 11.7545 12.3814L10.7534 13.2107ZM2.76292 7.64895C2.76292 5.30891 4.65989 3.41193 6.99994 3.41193V2.11193C3.94192 2.11193 1.46292 4.59094 1.46292 7.64895H2.76292ZM6.99994 3.41193C9.33998 3.41193 11.237 5.30891 11.237 7.64895H12.537C12.537 4.59094 10.0579 2.11193 6.99994 2.11193V3.41193ZM6.99994 11.886C5.9743 11.886 5.0353 11.5224 4.30228 10.9164L3.47397 11.9183C4.43141 12.7099 5.66093 13.186 6.99994 13.186V11.886ZM4.30228 10.9164C3.36096 10.1382 2.76292 8.9637 2.76292 7.64895H1.46292C1.46292 9.36762 2.24673 10.9038 3.47397 11.9183L4.30228 10.9164ZM3.37076 11.0239L2.3222 12.4026L3.35694 13.1895L4.40549 11.8108L3.37076 11.0239ZM11.237 7.64895C11.237 8.9637 10.6389 10.1382 9.69759 10.9164L10.5259 11.9183C11.7531 10.9038 12.537 9.36762 12.537 7.64895H11.237ZM9.69759 10.9164C8.96457 11.5224 8.02558 11.886 6.99994 11.886V13.186C8.33894 13.186 9.56846 12.7099 10.5259 11.9183L9.69759 10.9164ZM9.61121 11.832L10.7534 13.2107L11.7545 12.3814L10.6123 11.0027L9.61121 11.832Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        d="M7.62083 5.66241C7.62083 5.30343 7.32981 5.01241 6.97083 5.01241C6.61184 5.01241 6.32083 5.30343 6.32083 5.66241H7.62083ZM8.73525 8.84876C9.09423 8.84876 9.38525 8.55775 9.38525 8.19876C9.38525 7.83978 9.09423 7.54876 8.73525 7.54876V8.84876ZM6.32083 5.66241V7.96543H7.62083V5.66241H6.32083ZM7.20416 8.84876H8.73525V7.54876H7.20416V8.84876ZM6.32083 7.96543C6.32083 8.45328 6.71631 8.84876 7.20416 8.84876V7.54876C7.43428 7.54876 7.62083 7.73531 7.62083 7.96543H6.32083Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        d="M1.21594 4.3479L0.794538 4.6272C0.878345 4.75365 1.0145 4.83578 1.16544 4.85093C1.31638 4.86608 1.46615 4.81265 1.57342 4.70538L1.21594 4.3479ZM3.41379 2.15006L3.77127 2.50754C3.86892 2.4099 3.92237 2.27656 3.91921 2.1385C3.91606 2.00045 3.85656 1.86969 3.75456 1.77661L3.41379 2.15006ZM1.57342 4.70538L3.77127 2.50754L3.05631 1.79258L0.858456 3.99042L1.57342 4.70538ZM3.75456 1.77661C3.47005 1.517 3.0083 1.26836 2.49159 1.20364C1.95183 1.13604 1.33745 1.27091 0.843764 1.8079L1.58811 2.49222C1.82597 2.2335 2.09661 2.17318 2.36594 2.20691C2.65832 2.24353 2.92972 2.39275 3.07302 2.52351L3.75456 1.77661ZM0.843764 1.8079C0.361029 2.33298 0.255962 2.92504 0.326352 3.44299C0.393913 3.94013 0.62003 4.36391 0.794538 4.6272L1.63734 4.0686C1.50703 3.872 1.36738 3.59471 1.32825 3.30684C1.29196 3.03977 1.3393 2.76286 1.58811 2.49222L0.843764 1.8079Z"
        fill="#5B5F73"
      />
      <path
        className="cws-icon-main-fill"
        d="M12.7839 4.34787L13.2053 4.62717C13.1215 4.75362 12.9854 4.83575 12.8344 4.8509C12.6835 4.86605 12.5337 4.81262 12.4265 4.70535L12.7839 4.34787ZM10.5861 2.15003L10.2286 2.50751C10.131 2.40987 10.0775 2.27653 10.0807 2.13847C10.0838 2.00042 10.1433 1.86966 10.2453 1.77658L10.5861 2.15003ZM12.4265 4.70535L10.2286 2.50751L10.9436 1.79255L13.1414 3.99039L12.4265 4.70535ZM10.2453 1.77658C10.5298 1.51697 10.9916 1.26833 11.5083 1.20361C12.048 1.13601 12.6624 1.27088 13.1561 1.80787L12.4118 2.49219C12.1739 2.23347 11.9033 2.17315 11.6339 2.20688C11.3416 2.2435 11.0702 2.39272 10.9269 2.52348L10.2453 1.77658ZM13.1561 1.80787C13.6388 2.33295 13.7439 2.92501 13.6735 3.44296C13.606 3.9401 13.3798 4.36388 13.2053 4.62717L12.3625 4.06857C12.4928 3.87197 12.6325 3.59468 12.6716 3.30681C12.7079 3.03974 12.6606 2.76283 12.4118 2.49219L13.1561 1.80787Z"
        fill="#5B5F73"
      />
      <circle cx="12" cy="12" r="2" fill="#DD1E2A" />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconAlarmActive;
