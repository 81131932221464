import { fromJS, Map, OrderedMap } from 'immutable';
import { isEmpty } from 'ramda';
import {
  initState,
  setInvites,
  updateNewCompany,
  updateNewCompanyRole,
  clearNewCompany,
  selectEmployee,
  clearSelected,
  clearSelectedInvite,
  selectInvite
} from '../work-with-data/company';

const setCompanyField = (state, field, value) =>
  state.set(field, fromJS(value));

const setListOfUsers = (state, users) =>
  state.set('listOfUsers', fromJS(users || {}));

const setListOfCompanies = (state, companies) =>
  state.set('listOfCompanies', fromJS(companies));

const setCompany = (state, company) => {
  if (!company || isEmpty(company)) return state.set('isUploaded', true);
  return state
    .mergeDeep(fromJS(company))
    .set('categoriesId', company.categoriesId || [])
    .set('salesCategoryIds', company.salesCategoryIds || [])
    .set('contacts', OrderedMap(company.contacts))
    .setIn(['conditions', 'delivery'], OrderedMap(company.conditions.delivery))
    .setIn(['conditions', 'payment'], OrderedMap(company.conditions.payment))
    .setIn(
      ['conditions', 'myConditions'],
      OrderedMap(company.conditions.myConditions)
    )
    .set('isUploaded', true)
    .set('description', company.description || '');
};

const trimCompanyField = (state, field) =>
  state.updateIn([field], (value) => (value || '').trim());

const setEmailValidationState = (state, isUnique) =>
  state.setIn(['newCompany', 'uniqueEmail'], isUnique);

const setSystemIdValidationState = (state, isUnique) =>
  state.setIn(['newCompany', 'uniqueSystemId'], isUnique);

const setNameInCompanyValidationState = (state, isUnique) =>
  state.set('uniqueNameInCompany', isUnique);

const setRequisites = (data, requisitesValues) =>
  data.update('requisites', (requisites) => {
    let newRequisites = requisites;
    const requisitesNames = Object.keys(requisitesValues);
    requisitesNames.forEach((name) => {
      newRequisites = newRequisites.setIn(
        [name, 'value'],
        requisitesValues[name]
      );
    });
    return newRequisites;
  });

const addCompany = (data, { company }) =>
  data.update('listOfCompanies', (companies) =>
    companies.push(fromJS(company))
  );

const addInvite = (data, { id, email }) =>
  data.update('invites', (invites) => invites.push(Map({ id, to: email })));

const addContact = (data, { label, value, key }) =>
  data.setIn(['contacts', key], { label, value, removable: true });

const removeContact = (data, { key }) =>
  data.update('contacts', (contacts) => contacts.delete(key));

const addOption = (data, { type, option, key }) =>
  data.setIn(['conditions', type, key], option);
const removeOption = (data, { type, key }) =>
  data.updateIn(['conditions', type], (conditions) => conditions.delete(key));
const removeCompany = (data, { companyId }) =>
  data.update('listOfCompanies', (list) =>
    list.filter((i) => i.get('id') !== companyId)
  );

const removeInvite = (data, { ids }) => {
  const filter = data
    .get('invites')
    .filter((invite) => !ids.includes(invite.get('id')));
  return data.set('invites', filter);
};

const unblockItems = (data, { ids = [] }) =>
  data.update('listOfUsers', (items) => {
    if (!items) return items;
    ids.forEach((id) => {
      const index = items.findIndex((i) => i.get('id') === +id);
      // eslint-disable-next-line no-param-reassign
      items = items.setIn([index, 'blocked'], false);
    });
    return items;
  });

const setEmail = (data, { email }) => data.set('email', email);

const toggleBlockMe = (data, { employeeId, isBlocked }) => {
  const index = data
    .get('listOfUsers')
    .findIndex((user) => user.get('employeeId') === employeeId);

  if (index === -1) return data;

  return data.setIn(['listOfUsers', index, 'isBlockedMe'], isBlocked);
};

const companyReducer = (state = initState(), action) => {
  let newState;

  switch (action.type) {
    case 'SET_LIST_OF_USERS':
      newState = setListOfUsers(state, action.users);
      return clearSelected(newState, action.payload);
    case 'COMPANY_EMPLOYEES:TOGGLE_BLOCK_ME':
      return toggleBlockMe(state, action.payload);
    case 'COMPANY_SET_FIELD':
      return setCompanyField(state, action.field, action.value);
    case 'COMPANY_TRIM_FIELD':
      return trimCompanyField(state, action.field);
    case 'SET_COMPANY':
      return setCompany(state, action.company);
    case 'COMPANY:GET_INVITES:SUCCESS':
      return setInvites(state, action.payload);
    case 'COMPANY:VALIDATE_EMAIL:SUCCESS':
      return setEmailValidationState(state, action.payload);
    case 'COMPANY:SYSTEM_ID_VALIDATE:SUCCESS':
      return setSystemIdValidationState(state, action.payload);
    case 'COMPANY:VALIDATE_UNIQUE_USER_NAME:SUCCESS':
      return setNameInCompanyValidationState(state, action.payload);
    case 'SET_LIST_OF_COMPANIES':
      return setListOfCompanies(state, action.companies);
    case 'COMPANY_NEW_SET_FIELD':
      return updateNewCompany(state, {
        field: action.field,
        value: action.value
      });
    case 'COMPANY_NEW_SET_ROLE':
      return updateNewCompanyRole(state, {
        role: action.role,
        value: action.value
      });
    case 'COMPANY_NEW_CLEAR':
      return clearNewCompany(state);
    case 'COMPANY:SELECT_EMPLOYEE':
      return selectEmployee(state, action.payload);
    case 'COMPANY:SELECT_INVITE':
      return selectInvite(state, action.payload);
    case 'COMPANY:CLEAR_SELECTED_EMPLOYEE':
      return clearSelected(state, action.payload);
    case 'COMPANY:CLEAR_SELECTED_INVITE':
      return clearSelectedInvite(state, action.payload);
    case 'COMPANY:REMOVE_EMPLOYEE:SUCCESS':
      return clearSelected(state);
    case 'COMPANY:CREATE:SUCCESS':
      return addCompany(state, action.payload);
    case 'COMPANY:SEND_INVITE:SUCCESS':
      return addInvite(state, action.payload);
    case 'COMPANY:REMOVE_INVITE:SUCCESS':
      return removeInvite(state, action.payload);
    case 'COMPANY:UPDATE_REQUISITES:SUCCESS':
      return setRequisites(state, action.payload);
    case 'COMPANY:ADD_CONTACT:SUCCESS':
      return addContact(state, action.payload);
    case 'COMPANY:REMOVE_CONTACT:SUCCESS':
      return removeContact(state, action.payload);
    case 'COMPANY:ADD_OPTION:SUCCESS':
      return addOption(state, action.payload);
    case 'COMPANY:REMOVE_CONDITION:SUCCESS':
      return removeOption(state, action.payload);
    case 'COMPANY:REMOVE:SUCCESS':
      return removeCompany(state, action.payload);
    case 'COMPANY:UNBLOCK_EMPLOYEES:SUCCESS':
      return unblockItems(state, action.payload);
    case 'COMPANY:CHANGE_EMAIL:SUCCESS':
      return setEmail(state, action.payload);
    default:
      return state;
  }
};

export default companyReducer;
