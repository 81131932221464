.tappable {
  position: relative;
  cursor: default;
  transition: background-color 0.15s ease-out;
}

.hasHover,
.hasActive {
  cursor: pointer;
}

.tappable[disabled],
.tappable[aria-disabled='true'] {
  cursor: default;
}

.focusVisible {
  outline: none;
}
