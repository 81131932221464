import request from '../../../../lib/request';
import { getScktChl } from '../../useCases/service';

const scktChl = getScktChl();
export const readMessagesInChat = (channelId) =>
  scktChl.emit('messages:read', { channelId });

export const sendMsg = ({
  chatId,
  msg,
  html,
  files,
  mentions,
  forwardedMessages,
  replyByMessageId,
  deliveryId
}) =>
  scktChl.emit('message', {
    channelId: chatId,
    msg,
    html,
    files,
    mentions,
    forwardedMessages,
    replyByMessageId,
    deliveryId
  });

export const findChunkPrev = async (chatId, date = null, limit = 40) =>
  request.post(`/api/channel/${chatId}/chunk-messages`, { date, limit });
