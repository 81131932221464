import React from 'react';
import Icons from '../constants';
import IconCross from './cross';
import IconStyleBold from './style-bold';
import IconStyleItalic from './style-italic';
import IconStyleUnderline from './style-underline';

export const Icons12x12 = {
  [Icons.Cross]: <IconCross />,
  [Icons.StyleBold]: <IconStyleBold />,
  [Icons.StyleItalic]: <IconStyleItalic />,
  [Icons.StyleUnderline]: <IconStyleUnderline />
};
