import { TableHeader } from '../TableHeader';
import { TableBody } from '../TableBody';
import { TableFooter } from '../TableFooter';
import styles from './Table.module.scss';

export function Table() {
  return (
    <table className={styles.table}>
      <TableHeader />
      <TableBody />
      <TableFooter />
    </table>
  );
}
