export const createdDelayedMessageCase = (
  { addDelayedMessage, getChatMode, showDelayedMessages },
  { message }
) => {
  const chatMode = getChatMode();

  if (chatMode !== 'show-delayed-messages') {
    showDelayedMessages();
    return;
  }

  addDelayedMessage(message);
};
