import { bindActionCreators } from 'redux';
import * as messages from './message';
import { makeBlockEditMsg } from '../../data-type/block-msg-edit';
import { makeBlockInput } from '../../data-type/block-input';
import { ac } from '../../useCases/shared/actions';

const closeEditMessagePanel = () => (dispatch) => {
  dispatch(ac.setMsgToEditor(''));
  dispatch(ac.setEditingMessage(makeBlockEditMsg()));
  dispatch(ac.setInputBlock(makeBlockInput()));
};

export const init = () => (dispatch) => {
  messages.onCloseEditMessagePanel(
    bindActionCreators(closeEditMessagePanel, dispatch)
  );
};
