import {
  ADD_ARCHIVES,
  ADD_CHATS,
  ADD_ERROR,
  ADD_LOADING_FILE_ID_INPUT_BLOCK,
  ADD_OR_UPDATE_LAST_UNREAD_MESSAGE,
  ADD_OR_UPDATE_MESSAGE,
  ADD_OR_UPDATE_TEMP_INPUT,
  ADD_REPLY_MSG,
  CLEAR_ERRORS,
  CLEAR_LAST_UNREAD_MESSAGE,
  CLEAR_REPLY_MSG,
  CLEAR_REPLY_MSG_BY_CHAT,
  CLEAR_SELECTED_MSGS,
  HIDE,
  HIDE_ADVANCED_SEARCH,
  HIDE_INPUT_BLOCK,
  REMOVE_INPUT_FROM_TEMP,
  REMOVE_LOADING_FILE_ID_INPUT_BLOCK,
  REMOVE_MESSAGE,
  REMOVE_UNSENT_MSG,
  SCROLL_TO_MSG,
  SCROLL_WHEN_LOAD_CHUNK,
  SELECT_MESSAGE,
  SET_ADVANCED_SEARCH,
  SET_ADVANCED_SEARCH_GLOBAL_SEARCH,
  SET_ADVANCED_SEARCH_PARAM,
  SET_ADVANCED_SEARCH_RESULT,
  SET_ARCHIVES,
  SET_CHATS,
  SET_EDIT_MSG,
  SET_ERRORS,
  SET_FAVOTIRE_MSGS,
  SET_FILES_INPUT_BLOCK,
  SET_FILTER,
  SET_GROUP_CONTACT,
  SET_GROUP_EDIT,
  SET_HEADER_VIEW,
  SET_INPUT_BLOCK,
  SET_LOADING_FILES_IDS_INPUT_BLOCK,
  SET_MESSAGES,
  SET_MESSAGES_LOADED,
  SET_MESSAGES_MANY,
  SET_MESSAGES_NEXT,
  SET_MSG_TO_EDITOR,
  SET_OPEN_CHAT,
  SET_REPLY_MSGS,
  SET_SEARCH_MESSAGES,
  SET_TEMP_BLOCK,
  SET_TEXT_INPUT_BLOCK,
  SET_UNREAD_MSG_COUNT,
  SET_UNSENT_MSG,
  SHOW,
  SHOW_ADVANCED_SEARCH,
  SHOW_INPUT_BLOCK,
  UPDATE_ARCHIVE,
  UPDATE_CHAT,
  UPDATE_FAVOTIRE_MSG,
  UPDATE_LAST_MESSAGE,
  UPDATE_MESSAGE,
  UPDATE_TYPING_INFO
} from '@/components/ChatWidget/constants';
import * as profileActions from '@/components/ChatWidget/modules/profile/actions';
import * as contactActions from '@/components/ChatWidget/modules/contacts/actions';
import * as dialogInfoActions from '@/components/ChatWidget/modules/dialogInfo/actions';
import * as contactCategoriesActions from '@/components/ChatWidget/modules/categories/contacts/actions';
import * as chatCategoriesActions from '@/components/ChatWidget/modules/categories/chats/actions';

export const ac = {
  showChatWidget: () => ({ type: SHOW, payload: {} }),
  hideChatWidget: () => ({ type: HIDE, payload: {} }),
  setOpenChat: (chat) => ({ type: SET_OPEN_CHAT, payload: chat }),
  scrollToMsg: (messageId, { align }) => ({
    type: SCROLL_TO_MSG,
    payload: { messageId, align }
  }),
  scrollWhenLoadChunk: (messageId) => ({
    type: SCROLL_WHEN_LOAD_CHUNK,
    payload: messageId
  }),
  setMsgToEditor: (msg) => ({ type: SET_MSG_TO_EDITOR, payload: msg }),
  changeFilter: (name, value) => ({
    type: SET_FILTER,
    payload: { name, value }
  }),
  groupBy: (value) => ({
    type: SET_GROUP_CONTACT,
    payload: { value }
  }),
  showProfile: profileActions.showProfile,
  setContacts: contactActions.setContacts,
  addContacts: contactActions.addContacts,
  updateContact: contactActions.updateContact,
  setDialogInfo: dialogInfoActions.setDialogInfo,
  setChats: (chats) => ({ type: SET_CHATS, payload: chats }),
  addChats: (chats) => ({ type: ADD_CHATS, payload: chats }),
  setMessages: (list, amountLeft, amountLeftNext, cancelLoad) => ({
    type: SET_MESSAGES,
    payload: { list, amountLeft, amountLeftNext, cancelLoad }
  }),
  setMessagesLoaded: (isLoaded) => ({
    type: SET_MESSAGES_LOADED,
    payload: { isLoaded }
  }),
  setMessagesNext: (list, amountLeft) => ({
    type: SET_MESSAGES_NEXT,
    payload: { list, amountLeft }
  }),
  setManyMessages: ({
    list,
    amountLeftPrev,
    amountLeftNext,
    initTopItemIndex
  }) => ({
    type: SET_MESSAGES_MANY,
    payload: { list, amountLeftPrev, amountLeftNext, initTopItemIndex }
  }),
  setSearchMessages: (list, amountLeft) => ({
    type: SET_SEARCH_MESSAGES,
    payload: { list, amountLeft }
  }),
  addOrUpdateMsg: (msg) => ({ type: ADD_OR_UPDATE_MESSAGE, payload: { msg } }),
  updateMsg: (msg) => ({ type: UPDATE_MESSAGE, payload: { msg } }),
  removeMessage: (messageId) => ({
    type: REMOVE_MESSAGE,
    payload: { messageId }
  }),
  selectMessage: (messageId) => ({
    type: SELECT_MESSAGE,
    payload: { messageId }
  }),
  clearSelectedMessages: () => ({ type: CLEAR_SELECTED_MSGS }),
  addUnsentMessage: ({ chatId, employeeId, message }) => ({
    type: SET_UNSENT_MSG,
    payload: { chatId, employeeId, message }
  }),
  removeUnsentMessage: ({ chatId, employeeId, message, deliveryId }) => ({
    type: REMOVE_UNSENT_MSG,
    payload: { chatId, employeeId, message, deliveryId }
  }),
  updateChat: (chat) => ({ type: UPDATE_CHAT, payload: { chat } }),
  updateArchive: (chat) => ({ type: UPDATE_ARCHIVE, payload: { chat } }),
  setArchives: (chats) => ({ type: SET_ARCHIVES, payload: chats }),
  addArchives: (chats) => ({ type: ADD_ARCHIVES, payload: chats }),
  hideGroupContact: contactCategoriesActions.hideGroupContact,
  setGroupChats: chatCategoriesActions.setGroupChats,
  showGroupChat: chatCategoriesActions.showGroupChat,
  hideGroupChat: chatCategoriesActions.hideGroupChat,
  setInputBlock: (blockInput) => ({
    type: SET_INPUT_BLOCK,
    payload: blockInput
  }),
  setInputText: (text) => ({ type: SET_TEXT_INPUT_BLOCK, payload: { text } }),
  setInputFiles: (files) => ({
    type: SET_FILES_INPUT_BLOCK,
    payload: { files }
  }),
  setInputLoadingFilesIds: (ids) => ({
    type: SET_LOADING_FILES_IDS_INPUT_BLOCK,
    payload: { ids }
  }),
  addInputLoadingFileId: (id) => ({
    type: ADD_LOADING_FILE_ID_INPUT_BLOCK,
    payload: { id }
  }),
  removeInputLoadingFileId: (id) => ({
    type: REMOVE_LOADING_FILE_ID_INPUT_BLOCK,
    payload: { id }
  }),
  hideInputText: () => ({ type: HIDE_INPUT_BLOCK, payload: {} }),
  showInputText: () => ({ type: SHOW_INPUT_BLOCK, payload: {} }),
  setTempBlock: (blockTemp) => ({ type: SET_TEMP_BLOCK, payload: blockTemp }),
  addOrUpdateTempInput: (chatId, input) => ({
    type: ADD_OR_UPDATE_TEMP_INPUT,
    payload: { chatId, input }
  }),
  removeInputFromTemp: (chatId) => ({
    type: REMOVE_INPUT_FROM_TEMP,
    payload: { chatId }
  }),
  setFavotireMsgs: (fmsgs) => ({ type: SET_FAVOTIRE_MSGS, payload: fmsgs }),
  updateFavotireMsg: (message) => ({
    type: UPDATE_FAVOTIRE_MSG,
    payload: message
  }),
  setSidebarHeaderView: (view) => ({ type: SET_HEADER_VIEW, payload: view }),
  setEditingMessage: (data) => ({ type: SET_EDIT_MSG, payload: data }),
  setGroupChatsEdit: (blockGroups) => ({
    type: SET_GROUP_EDIT,
    payload: blockGroups
  }),
  updateUnreadMsgsCount: ({ chats, archives }) => ({
    type: SET_UNREAD_MSG_COUNT,
    payload: { chats, archives }
  }),
  updateTypingInfo: ({ id, userName, value, employeeId }) => ({
    type: UPDATE_TYPING_INFO,
    payload: { id, userName, value, employeeId }
  }),
  setAdvancedSearchBlock: (blockSearch) => ({
    type: SET_ADVANCED_SEARCH,
    payload: blockSearch
  }),
  setAdvancedSearchParam: (payload) => ({
    type: SET_ADVANCED_SEARCH_PARAM,
    payload
  }),
  setAdvancedSearchGlobalSearch: (payload) => ({
    type: SET_ADVANCED_SEARCH_GLOBAL_SEARCH,
    payload
  }),
  setAdvancedSearchResults: (results) => ({
    type: SET_ADVANCED_SEARCH_RESULT,
    payload: results
  }),
  showAdvancedSearch: () => ({ type: SHOW_ADVANCED_SEARCH, payload: {} }),
  hideAdvancedSearch: () => ({ type: HIDE_ADVANCED_SEARCH, payload: {} }),
  setErrors: (errors) => ({ type: SET_ERRORS, payload: errors }),
  addError: (error) => ({ type: ADD_ERROR, payload: error }),
  clearErrors: () => ({ type: CLEAR_ERRORS }),
  updateLastUnreadMsg: (item) => ({
    type: ADD_OR_UPDATE_LAST_UNREAD_MESSAGE,
    payload: item
  }),
  removeLastUnreadMsg: (channelId) => ({
    type: CLEAR_LAST_UNREAD_MESSAGE,
    payload: channelId
  }),
  setReplyMsgs: (messages) => ({ type: SET_REPLY_MSGS, payload: { messages } }),
  addReplyMsg: (message) => ({ type: ADD_REPLY_MSG, payload: message }),
  clearReplyMsg: (messageId) => ({ type: CLEAR_REPLY_MSG, payload: messageId }),
  clearReplyMsgByChat: (chatId) => ({
    type: CLEAR_REPLY_MSG_BY_CHAT,
    payload: chatId
  }),
  updateLastMessage: (block) => ({ type: UPDATE_LAST_MESSAGE, payload: block })
};
