import queryString from 'query-string';

class SearchQuery {
  #defaultGenerateOption = {
    arrayFormat: 'bracket',
    skipNull: true,
    skipEmptyString: true
  };

  generate(payload, options = {}) {
    if (!payload) {
      return '';
    }

    const searchQuery = queryString.stringify(payload, {
      ...this.#defaultGenerateOption,
      ...options
    });
    return searchQuery ? `?${searchQuery}` : '';
  }
}

export const searchQuery = new SearchQuery();
