import { createContext, useContext } from 'react';
import { AccordionContextValue } from './Accordion.types';

export const AccordionContext = createContext<AccordionContextValue>(
  {} as AccordionContextValue
);

export function useAccordionContext() {
  const context = useContext(AccordionContext);

  if (!context) {
    throw new Error(
      'useAccordionContext must be used within a AccordionProvider'
    );
  }

  return context;
}
