import { isArchivesActive, isChatsActive } from '../utils';
import { logUseCase } from '../../../../../utils/logger';

const log = logUseCase.extend('sortChatsCase');

export const sortChatsCase = async (
  { getActiveTab, updateChatsFilter, updateArchiviesFilter },
  { sortBy = 'sort-by-last-update', direction = 'down' }
) => {
  const activeTab = getActiveTab();

  log(`active tab = ${activeTab}`);

  if (isChatsActive(activeTab)) return sortChats();
  if (isArchivesActive(activeTab)) return sortArchives();

  throw new Error(`No such handle for active tab ${activeTab}`);

  function sortChats() {
    updateChatsFilter({ sortBy, direction });
  }

  function sortArchives() {
    updateArchiviesFilter({ sortBy, direction });
  }
};
