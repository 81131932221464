import React from 'react';

/* eslint-disable max-len */
const IconNoticeOn = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 6C4 3.23858 6.23858 1 9 1C11.7614 1 14 3.23858 14 6V12.5H13V6C13 3.79086 11.2091 2 9 2C6.79086 2 5 3.79086 5 6V12.5H4V6Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 13L2 13L2 12L16 12V13Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 16C9.74028 16 10.3866 15.5978 10.7324 15H7.26756C7.61337 15.5978 8.25972 16 9 16ZM6.17071 15C6.06015 14.6872 6 14.3506 6 14H12C12 14.3506 11.9398 14.6872 11.8293 15C11.4175 16.1652 10.3062 17 9 17C7.69378 17 6.58254 16.1652 6.17071 15Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconNoticeOn;
