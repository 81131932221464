import { Map, fromJS } from 'immutable';

const init = () =>
  new Map({
    inviteInfo: Map({
      isUploaded: false,
      errors: ''
    })
  });

const setInviteInfo = (data, { inviteInfo }) =>
  data
    .set('inviteInfo', fromJS(inviteInfo))
    .setIn(['inviteInfo', 'isUploaded'], true);

const setRegisterErrors = (data, { errors }) =>
  data.set('errors', fromJS(errors));

const invitesReducer = (state = init(), { type, payload }) => {
  switch (type) {
    case 'INVITE_INFO':
      return setInviteInfo(state, payload);
    case 'REGISTER_ERRORS':
      return setRegisterErrors(state, payload);
    case 'CLEAR_INVITE':
      return init();
    default:
      return state;
  }
};

export default invitesReducer;
