import {
  append,
  assoc,
  assocPath,
  propEq,
  hasPath,
  path,
  reject,
  lensProp,
  over
} from 'ramda';

/**
 * @typedef {(number|string)} Id
 */

/**
 * Any object with property id and any properties
 *
 * @typedef {object} Item
 * @property {(number|string)} id
 */

/**
 * @typedef BLUnsent - BlockListUnsent
 * @property {[Item]} list - object with id
 */

const makeListsOfBLUnsent = () => ({ type: 'ListOfBLUnsent' });

/**
 * Creates a block list data type
 *
 * @param {object} params
 * @param {[Item]} params.list
 * @returns {BLUnsent}
 */
const makeBLUnsent = ({ list = [] } = {}) => ({
  type: 'BlockListUnsent',
  list
});

const getList = (blockList, chatId) => path(['list', chatId], blockList);
const hasList = (blockList, chatId) => hasPath(['list', chatId], blockList);

const addToList = (blockList, chatId, blunsent) =>
  assocPath(['list', chatId], blunsent, blockList);

const appendTo = (key, message, data) =>
  over(lensProp(key), append(message), data);
const removeFrom = (key, deliveryId, data) =>
  reject(propEq(key, deliveryId))(data);

/**
 * Set or update list
 *
 * @param {BLUnsent} blockList
 * @param {Id} chatId
 * @param {Item} message
 * @returns {BLUnsent}
 */
const addOrUpdateUnsentList = (blockList, chatId, message) => {
  if (hasList(blockList, chatId)) {
    const updatedList = getList(blockList, chatId);
    const newList = appendTo('list', message, updatedList);
    return addToList(blockList, chatId, newList);
  }
  const newList = makeBLUnsent({ list: append(message, []) });
  return addToList(blockList, chatId, newList);
};

/**
 * Remove item from ListOfBLUnsent
 *
 * @param {BLUnsent} blockList
 * @param {Id} chatId
 * @param {Item} message
 * @param {Id} deliveryId
 * @returns {BLUnsent}
 */
const removeFromUnsentList = (blockList, chatId, message, deliveryId) => {
  if (!hasList(blockList, chatId)) {
    return blockList;
  }
  const updatedList = getList(blockList, chatId);
  const newList = removeFrom('deliveryId', deliveryId, updatedList.list);
  return addToList(blockList, chatId, assoc('list', newList, updatedList));
};

export {
  makeListsOfBLUnsent,
  removeFromUnsentList,
  addOrUpdateUnsentList,
  getList,
  hasList
};
