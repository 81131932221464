import React from 'react';
/* eslint-disable max-len */
const CloseLarge = () => (
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1259 2.13663C12.308 2.3188 12.308 2.61416 12.1259 2.79633L2.79633 12.1259C2.61416 12.308 2.3188 12.308 2.13663 12.1259C1.95446 11.9437 1.95446 11.6483 2.13663 11.4662L11.4662 2.13663C11.6483 1.95446 11.9437 1.95446 12.1259 2.13663Z"
      fill="#5B5F73"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.13663 2.13663C2.3188 1.95446 2.61416 1.95446 2.79633 2.13663L12.1259 11.4662C12.308 11.6483 12.308 11.9437 12.1259 12.1259C11.9437 12.308 11.6483 12.308 11.4662 12.1259L2.13663 2.79633C1.95446 2.61416 1.95446 2.3188 2.13663 2.13663Z"
      fill="#5B5F73"
    />
  </svg>
);
export default CloseLarge;
/* eslint-disable max-len */
