export const unhideContactAndDialogCase = async (
  {
    reqUnhideContact,
    uploadContactsCase,
    setSuccessMessage,
    updateDataAfterToggleHide
  },
  { employeeId }
) => {
  await reqUnhideContact(employeeId);
  updateDataAfterToggleHide(employeeId, { isHidden: false });

  await uploadContactsCase();

  setSuccessMessage('contact_was_added_to_list');
};
