import { bindActionCreators } from 'redux';
import * as actions from './actions';
import * as storage from './storage';
import * as getters from './getters';
import { saveClientDataCase } from './useCases/saveClientData';
import { applyState } from '../utils';

export const saveClientData = (chatId, data) => (dispatch, getState) =>
  saveClientDataCase(
    {
      getClientData: applyState(getState, getters.getClientData),
      saveClientData: bindActionCreators(actions.saveClientData, dispatch),
      sendClientData: storage.sendClientChatsData
    },
    { chatId, data }
  );
