import { getFullName } from '@root/utils/utils';

const makeContact = ({
  userId,
  companyId,
  employeeId,
  isBlockedContact,
  firstName,
  middleName,
  email,
  avatar,
  company,
  department
}) => ({
  userId,
  companyId,
  employeeId,
  name: `${middleName} ${firstName}`,
  company,
  avatar,
  email,
  isBlockedContact,
  department
});

export const toggleBlockModalCase = (
  {
    toggleBlock,
    getDialogInfo,
    getChatContact,
    showModal,
    updateContacts,
    getContactFilter
  },
  { employeeId, availableFilterList }
) => {
  const dialogInfo = getDialogInfo();

  const contacts = getChatContact().list;

  const contact = contacts.find((user) => user.employeeId === employeeId);

  const { isBlockedContact } = contact || dialogInfo;
  const filter = getContactFilter().contact.value;

  const name = (() => {
    if (contact?.name) return contact.name;

    if (dialogInfo) return getFullName(dialogInfo);

    return '';
  })();

  const onBlock = (id) => {
    toggleBlock(id);
    if (availableFilterList.includes(filter)) {
      const updatedContacts = updateContactsByContact({
        contact,
        contacts,
        dialogInfo
      });
      updateContacts({
        contacts: updatedContacts,
        size: updatedContacts.length
      });
    }
  };

  showModal({
    name,
    employeeId,
    isBlocked: isBlockedContact,
    onBlock
  });
};

function updateContactsByContact({ contact, contacts, dialogInfo }) {
  const { isBlockedContact } = contact || dialogInfo;

  return isBlockedContact
    ? contacts.filter((cont) => cont.employeeId !== contact.employeeId)
    : [...contacts, contact ?? makeContact(dialogInfo)];
}
