import {
  assocPath,
  isEmpty,
  isNil,
  length,
  path,
  pipe,
  append,
  keys
} from 'ramda';
import { nanoid } from 'nanoid';
import { isParallelStep } from '@root/pages/constructor/builder/components/helpers';
import {
  STEP_TYPES,
  MAX_STEPS_IN_PATH
} from '@root/pages/constructor/builder/components/constants';
import {
  ADD_CARD_TO_STEP,
  ADD_USER_TO_CARD,
  CHANGE_BUILDER_NAME,
  CHANGE_USER_IS_EXECUTE_AUTOMATICALLY,
  CLEAR_CONSTRUCTOR,
  CREATE_STEP,
  DELETE_CARD_FROM_STEP,
  DELETE_ROUTE,
  DELETE_STEP,
  ERROR_CHECKED,
  EXCHANGE_USER,
  FETCH_CONSTRUCTOR,
  FETCH_CONSTRUCTOR_LIST,
  FETCH_USERS_LIST,
  INIT_ROUTE_NAME,
  INIT_ROUTE_TYPE,
  REDUX_KEY,
  REMOVE_USER_FROM_CARD,
  SAVE_BUILDER_PATH,
  CREATE_END_STEP,
  DELETE_END_STEP
} from './constants';

const INITIAL_ROUTE_NAME = 'Новый маршрут';
const PATH_TO_IS_SAVED = [REDUX_KEY, 'constructor', 'isSaved'];
const PATH_TO_STEPS = [REDUX_KEY, 'constructor', 'builder', 'path'];
const PATH_TO_BUILDER = [REDUX_KEY, 'constructor', 'builder'];
const editIsSaved = assocPath(PATH_TO_IS_SAVED, false);

const addEndStepAutomatically = (state) => {
  const pathToSteps = generateEditableKey();
  const steps = path(pathToSteps, state);
  const lastStep = steps.at(-1);
  if (
    steps.length === MAX_STEPS_IN_PATH ||
    keys(lastStep).includes(STEP_TYPES.publication)
  ) {
    return assocPath(append('isEndStepExist', PATH_TO_BUILDER), true, state);
  }
  return state;
};

const constructorsInitialState = {
  list: [],
  error: null,
  isLoading: false
};

const usersInitialState = {
  list: [],
  error: null,
  isLoading: false
};

function generateBuilderState() {
  return {
    builder: {
      path: [],
      name: 'Новый маршрут',
      type: '',
      isEndStepExist: false
    },
    isSaved: true,
    isLoading: false,
    error: null
  };
}

const builderInitialState = generateBuilderState();

const initialStata = {
  constructors: constructorsInitialState,
  users: usersInitialState,
  constructor: builderInitialState
};

export const defaultState = () => ({
  [REDUX_KEY]: initialStata
});

const fetchConstructorListStart = (state) =>
  assocPath([REDUX_KEY, 'constructors', 'isLoading'], true, state);
const fetchConstructorListSuccess = (state, payload) =>
  assocPath(
    [REDUX_KEY, 'constructors'],
    {
      ...state[REDUX_KEY].constructors,
      isLoading: false,
      list: payload
    },
    state
  );

const fetchConstructorListError = (state, payload) =>
  assocPath(
    [REDUX_KEY, 'constructors'],
    {
      ...state[REDUX_KEY].constructor,
      isLoading: false,
      error: payload
    },
    state
  );

const fetchUsersListStart = (state) =>
  assocPath([REDUX_KEY, 'users', 'isLoading'], true, state);
const fetchUsersListSuccess = (state, payload) =>
  assocPath(
    [REDUX_KEY, 'users'],
    {
      ...state[REDUX_KEY].users,
      isLoading: false,
      // list: append(payload, state[REDUX_KEY].users.list)
      list: payload
    },
    state
  );

const fetchUsersListError = (state, payload) =>
  assocPath(
    [REDUX_KEY, 'users'],
    {
      ...state[REDUX_KEY].users,
      isLoading: false,
      error: payload
    },
    state
  );

function addDefaultCard() {
  return {
    isError: false,
    id: nanoid(),
    members: []
  };
}

function generateNewStepByType(type) {
  return {
    id: nanoid(),
    [type]: [addDefaultCard()]
  };
}

const saveBuilder = (state, payload) => {
  const newState = assocPath(PATH_TO_IS_SAVED, true, state);
  const editKey = [REDUX_KEY, 'constructor', 'builder'];
  return assocPath(editKey, payload, newState);
};

const generateEditableKey = (...args) => [...PATH_TO_STEPS, ...args];

const createStep = (state, payload) => {
  const { stepType } = payload;
  const pathToSteps = generateEditableKey();

  const steps = path(pathToSteps, state);
  const newStep = generateNewStepByType(stepType);

  const stepsAfterAddNewStep = [].concat(steps, newStep);

  const editSteps = assocPath(pathToSteps, stepsAfterAddNewStep);

  return pipe(editSteps, editIsSaved, addEndStepAutomatically)(state);
};

const addUserToCard = (state, payload) => {
  const { stepId, stepType, cardId, user } = payload;

  const pathToSteps = generateEditableKey();

  const steps = path(pathToSteps, state);
  const stepsAfterAddUserToCard = steps.map((step) => {
    if (step.id === stepId) {
      const currentCardsByStepType = step[stepType];

      const stepAfterAddingUser = currentCardsByStepType.map((card) => {
        if (card.id === cardId) {
          return {
            ...card,
            isError: false,
            members: [].concat(card.members, user)
          };
        }
        return card;
      });

      return {
        ...step,
        [stepType]: stepAfterAddingUser
      };
    }
    return step;
  });
  const editSteps = assocPath(pathToSteps, stepsAfterAddUserToCard);

  return pipe(editSteps, editIsSaved)(state);
};

const exchangeUser = (state, payload) => {
  const { stepId, stepType, cardId, deletedUserId, user } = payload;

  const pathToSteps = generateEditableKey();

  const steps = path(pathToSteps, state);
  const stepsAfterAddUserToCard = steps.map((step) => {
    if (step.id === stepId) {
      const currentCardsByStepType = step[stepType];

      const stepAfterAddingUser = currentCardsByStepType.map((card) => {
        if (card.id === cardId) {
          return {
            ...card,
            isError: false,
            members: [
              ...card.members.filter((member) => member.id !== deletedUserId),
              user
            ]
          };
        }
        return card;
      });

      return {
        ...step,
        [stepType]: stepAfterAddingUser
      };
    }
    return step;
  });
  const editSteps = assocPath(pathToSteps, stepsAfterAddUserToCard);

  return pipe(editSteps, editIsSaved)(state);
};

const removeUserFromCard = (state, payload) => {
  const pathToSteps = generateEditableKey();

  const steps = path(pathToSteps, state);
  const { stepId, stepType, cardId, userId } = payload;

  const stepsAfterRemoveUserFromCard = steps.map((step) => {
    if (step.id === stepId) {
      const currentCardsByStepType = step[stepType];

      const stepAfterRemovingUser = currentCardsByStepType.map((card) => {
        if (card.id === cardId) {
          const membersAfterDeleteUser = card.members.filter(
            (user) => user.id !== userId
          );
          return {
            ...card,
            isError: false,
            members: membersAfterDeleteUser
          };
        }
        return card;
      });

      return {
        ...step,
        [stepType]: stepAfterRemovingUser
      };
    }
    return step;
  });

  const editSteps = assocPath(pathToSteps, stepsAfterRemoveUserFromCard);

  return pipe(editSteps, editIsSaved)(state);
};

const changeUserIsExecuteAutomatically = (state, payload) => {
  const pathToSteps = generateEditableKey();

  const steps = path(pathToSteps, state);
  const { stepId, stepType, cardId, userId } = payload;
  steps.forEach((step) => {
    if (step.id === stepId) {
      const currentCardsByStepType = step[stepType];
      currentCardsByStepType.forEach((card) => {
        if (card.id === cardId) {
          // eslint-disable-next-line no-param-reassign
          card.isError = false;
          card.members.forEach((user) => {
            if (user.id === userId) {
              // eslint-disable-next-line no-param-reassign
              user.isExecuteAutomatically = !user.isExecuteAutomatically;
            }
          });
        }
      });
    }
  });

  const editSteps = assocPath(pathToSteps, steps);

  return pipe(editSteps, editIsSaved)(state);
};

const changeBuilderName = (state, payload) => {
  const editName = assocPath(
    [REDUX_KEY, 'constructor', 'builder', 'name'],
    payload
  );
  return pipe(editName, editIsSaved)(state);
};

const initBuilderName = (state, payload) => {
  const currentName = path(
    [REDUX_KEY, 'constructor', 'builder', 'name'],
    state
  );

  if (currentName !== INITIAL_ROUTE_NAME) {
    return state;
  }

  const editName = assocPath(
    [REDUX_KEY, 'constructor', 'builder', 'name'],
    `Новый маршрут ${payload}`
  );
  return pipe(editName)(state);
};

const initBuilderType = (state, payload) => {
  const editType = assocPath(
    [REDUX_KEY, 'constructor', 'builder', 'type'],
    payload
  );

  return pipe(editType)(state);
};

const deleteEndStep = (state) => {
  const editType = assocPath(append('isEndStepExist', PATH_TO_BUILDER), false);

  return pipe(editType)(state);
};
const createEndStep = (state) => {
  const editType = assocPath(append('isEndStepExist', PATH_TO_BUILDER), true);
  return pipe(editType)(state);
};

const addCardToStep = (state, payload) => {
  const { stepId, stepType } = payload;

  const pathToSteps = generateEditableKey();

  const steps = path(pathToSteps, state);

  const stepsAfterAddCard = steps.map((step) => {
    if (step.id === stepId) {
      // eslint-disable-next-line no-param-reassign
      if (!step[stepType]) {
        // eslint-disable-next-line no-param-reassign
        step[stepType] = [addDefaultCard()];
      } else {
        // eslint-disable-next-line no-param-reassign
        step[stepType] = [].concat(step[stepType], addDefaultCard());
      }

      return {
        ...step
      };
    }
    return step;
  });

  const editSteps = assocPath(pathToSteps, stepsAfterAddCard);

  return pipe(editSteps, editIsSaved)(state);
};

const deleteCardFromStep = (state, payload) => {
  const { stepId, cardId, stepType } = payload;

  const pathToSteps = generateEditableKey();

  const steps = path(pathToSteps, state);

  const currentStep = steps.find((step) => step.id === stepId);

  if (!isParallelStep(currentStep)) {
    const stepsAfterDelete = deleteEntityFromEntities(steps, stepId);
    const editSteps = assocPath(pathToSteps, stepsAfterDelete);

    return pipe(editSteps, editIsSaved)(state);
  }

  const stepsAfterDelete = steps.map((step) => {
    if (step.id === stepId) {
      const currentCardsFromStepByType = step[stepType];
      const afterDeletingCards = deleteEntityFromEntities(
        currentCardsFromStepByType,
        cardId
      );
      return {
        ...step,
        [stepType]: afterDeletingCards
      };
    }
    return step;
  });

  const editSteps = assocPath(pathToSteps, stepsAfterDelete);

  return pipe(editSteps, editIsSaved)(state);
};

function deleteEntityFromEntities(entities, entityId) {
  return entities.filter((entity) => entity.id !== entityId);
}

const deleteStep = (state, payload) => {
  const { stepId } = payload;

  const pathToSteps = generateEditableKey();

  const steps = path(pathToSteps, state);

  const stepsAfterDelete = deleteEntityFromEntities(steps, stepId);

  const editSteps = assocPath(pathToSteps, stepsAfterDelete);

  return pipe(editSteps, editIsSaved)(state);
};

const fetchConstructorStart = (state) =>
  assocPath([REDUX_KEY, 'constructor', 'isLoading'], true, state);
const fetchConstructorSuccess = (state, payload) => {
  const editableKey = [REDUX_KEY, 'constructor'];

  return assocPath(
    editableKey,
    {
      ...state[REDUX_KEY].constructor,
      isLoading: false,
      builder: payload
    },
    state
  );
};

const fetchConstructorError = (state, payload) => {
  const editableKey = [REDUX_KEY, 'constructor'];

  const constructor = path(editableKey, state);

  constructor.error = payload;
  constructor.isLoading = false;

  return assocPath([REDUX_KEY, 'constructor'], constructor, state);
};

const clearConstructor = (state) =>
  assocPath([REDUX_KEY, 'constructor'], generateBuilderState(), state);

const deleteRouteFromList = (state, payload) => {
  const editableKey = [REDUX_KEY, 'constructors', 'list'];

  const list = path(editableKey, state);

  const listAfterDeleteRoute = list.filter((route) => route.id !== payload);

  return assocPath(editableKey, listAfterDeleteRoute, state);
};

/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
const errorChecked = (state) => {
  const editableKey = [REDUX_KEY, 'constructor', 'builder', 'path'];
  const steps = path(editableKey, state);

  for (const step of steps) {
    if (!isNil(step[STEP_TYPES.publication])) {
      const publications = step[STEP_TYPES.publication];
      for (const publication of publications) {
        if (isEmpty(publication.members)) {
          publication.isError = true;
        }
      }
    }
    if (!isNil(step[STEP_TYPES.agreement])) {
      const agreements = step[STEP_TYPES.agreement];
      for (const agreement of agreements) {
        if (isEmpty(agreement.members)) {
          agreement.isError = true;
        }
      }
    }
    if (!isNil(step[STEP_TYPES.introduction])) {
      const introductions = step[STEP_TYPES.introduction];
      // eslint-disable-next-line no-restricted-syntax
      for (const introduction of introductions) {
        if (length(introduction.members) < 1) {
          introduction.isError = true;
        }
      }
    }
  }

  return assocPath(editableKey, steps, state);
};
/* eslint-enable no-restricted-syntax */
/* eslint-enable no-unused-vars */
/* eslint-enable no-param-reassign */
export const reducers = {
  [FETCH_CONSTRUCTOR_LIST.START]: fetchConstructorListStart,
  [FETCH_CONSTRUCTOR_LIST.SUCCESS]: fetchConstructorListSuccess,
  [FETCH_CONSTRUCTOR_LIST.ERROR]: fetchConstructorListError,
  [FETCH_USERS_LIST.START]: fetchUsersListStart,
  [FETCH_USERS_LIST.SUCCESS]: fetchUsersListSuccess,
  [FETCH_USERS_LIST.ERROR]: fetchUsersListError,
  [FETCH_CONSTRUCTOR.START]: fetchConstructorStart,
  [FETCH_CONSTRUCTOR.SUCCESS]: fetchConstructorSuccess,
  [FETCH_CONSTRUCTOR.ERROR]: fetchConstructorError,
  [INIT_ROUTE_NAME]: initBuilderName,
  [INIT_ROUTE_TYPE]: initBuilderType,
  [CREATE_END_STEP]: createEndStep,
  [DELETE_END_STEP]: deleteEndStep,

  [DELETE_ROUTE]: deleteRouteFromList,

  [DELETE_STEP]: deleteStep,
  [CREATE_STEP]: createStep,
  [SAVE_BUILDER_PATH]: saveBuilder,
  [ADD_CARD_TO_STEP]: addCardToStep,
  [DELETE_CARD_FROM_STEP]: deleteCardFromStep,
  [ADD_USER_TO_CARD]: addUserToCard,
  [REMOVE_USER_FROM_CARD]: removeUserFromCard,
  [EXCHANGE_USER]: exchangeUser,
  [CHANGE_USER_IS_EXECUTE_AUTOMATICALLY]: changeUserIsExecuteAutomatically,
  [CHANGE_BUILDER_NAME]: changeBuilderName,
  [CLEAR_CONSTRUCTOR]: clearConstructor,
  [ERROR_CHECKED]: errorChecked
};
