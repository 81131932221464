import React from 'react';

/* eslint-disable max-len */
const IconSelected = () => (
  <svg
    width="13"
    height="10"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M1 5.5L4 8.5L11.5 1" stroke="#1C50DE" strokeWidth="2" />
  </svg>
);

export default IconSelected;
