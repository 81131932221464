import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { useParams, useLocation } from '@/hooks';

import { QuickResponse } from '@/pages/quickResponse';
import { QuickResponseSuccess } from '@/pages/quickResponseSuccess';

import * as storeGetters from '@/storeGetters';

const RequestViewPage = () => {
  const { requestId } = useParams();
  const { search } = useLocation();

  const parsedSearch = queryString.parse(search);
  const query = queryString.stringify(
    {
      response: true,
      token: parsedSearch.token
    },
    { skipNull: true }
  );

  return <Navigate to={`/requests/all/${requestId}?${query}`} />;
};

const AuthorizedRoutes = () => (
  <Routes>
    <Route path="/:requestId" element={<RequestViewPage />} />
  </Routes>
);

const NoAuthorizedRoutes = () => (
  <Routes>
    <Route path="/:requestId" element={<QuickResponse />} />
    <Route path="/success" element={<QuickResponseSuccess />} />
  </Routes>
);

export const QuickResponseRouter = () => {
  const isAuth = useSelector(storeGetters.isAuth);
  if (!isAuth) return <NoAuthorizedRoutes />;

  return <AuthorizedRoutes />;
};
