import { makeCommand, makeEvent } from '../data/messageBus/message';
import { makeQuery } from './utils';

const NS = 'TASK_BADGES';

export const badgesQRY = makeQuery(NS, 'TASK_BADGES');

export const removeBadgeCMD = makeCommand(NS, 'TASK_BADGE_REMOVE');

export const badgesCreatedEVT = makeEvent(NS, 'TASK_BADGES_WAS_CREATED');
export const badgeRemovedEVT = makeEvent(NS, 'TASK_BADGE_WAS_REMOVED');

export const viewCMD = makeCommand(NS, 'view');
