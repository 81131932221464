import { bindActionCreators } from 'redux';
import { showDelayedMessagesCase } from './showDelayedMessages';
import { hideDelayedMessagesCase } from './hideDelayedMessages';
import { loadMoreCase } from './loadMore';
import { setPlannedDateCase } from './setPlannedDate';
import { clearPlannedDateCase } from './clearPlannedDate';
import { sendDelayedMessageCase } from './sendDelayedMessage';
import { publishDelayedMessageCase } from './publishDelayedMessage';
import { deleteDelayedMessageCase } from './deleteDelayedMessage';
import { createdDelayedMessageCase } from './createdDelayedMessage';
import { publishedDelayedMessageCase } from './publishedDelayedMessage';
import { editedDelayedMessageCase } from './editedDelayedMessage';
import { deletedDelayedMessageCase } from './deletedDelayedMessage';
import { editDelayedMessageCase } from './editDelayedMessage';
import * as commonGetters from '../../../getter';
import { applyState } from '../../utils';
import * as storage from '../storage';
import * as getters from '../getters';
import * as messages from '../message';
import { actions } from '../reducer';
import { setChatMode, prevChatMode } from '../../chatMode';

export const getDelayedMessages = async (chatId) =>
  storage.fetchDelayedMessages(chatId);

export const showDelayedMessages = () => async (dispatch, getState) =>
  showDelayedMessagesCase({
    getOpenedChatId: applyState(getState, commonGetters.getOpenedChatId),
    fetchMessages: storage.fetchDelayedMessages,
    setDelayedMsgs: bindActionCreators(actions.setDelayedMessages, dispatch),
    setChatMode: bindActionCreators(setChatMode, dispatch),
    sendCloseEditMessagePanel: messages.sendCloseEditMessagePanel
  });

export const hideDelayedMessages = () => (dispatch) =>
  hideDelayedMessagesCase({
    setPrevChatMode: bindActionCreators(prevChatMode, dispatch),
    clearDelayedMsgs: bindActionCreators(
      actions.clearDelayedMessages,
      dispatch
    ),
    sendCloseEditMessagePanel: messages.sendCloseEditMessagePanel
  });

export const loadMoreDelayedMessages =
  (pageNumber) => async (dispatch, getState) =>
    loadMoreCase(
      {
        getOpenedChatId: applyState(getState, commonGetters.getOpenedChatId),
        fetchMessages: storage.fetchDelayedMessages,
        addChunkDelayedMsgs: bindActionCreators(
          actions.addChunkDelayedMessages,
          dispatch
        )
      },
      { pageNumber }
    );

export const setPlannedDate = (date) => (dispatch) =>
  setPlannedDateCase(
    { setPlannedDate: bindActionCreators(actions.setPlannedDate, dispatch) },
    { date }
  );

export const clearPlannedDate = () => (dispatch) =>
  clearPlannedDateCase({
    clearPlannedDate: bindActionCreators(actions.clearPlannedDate, dispatch)
  });

export const sendDelayedMessage = (data) => (dispatch, getState) =>
  sendDelayedMessageCase(
    {
      getOpenedChannelId: applyState(getState, commonGetters.getOpenedChatId),
      getPlannedTime: applyState(getState, getters.getDelayMessagesPlannedDate),
      sendCreateDelayedMessageCMD: messages.sendCreateDelayedMessageCMD,
      clearPlannedDate: bindActionCreators(clearPlannedDate, dispatch),
      showDelayedMessages: bindActionCreators(showDelayedMessages, dispatch)
    },
    { data }
  );

export const publishDelayedMessage = (messageId) => (dispatch, getState) =>
  publishDelayedMessageCase(
    {
      getOpenedChannelId: applyState(getState, commonGetters.getOpenedChatId),
      sendPublishMessageCMD: messages.sendPublishDelayedMessageCMD
    },
    { messageId }
  );

export const deleteDelayedMessage = (messageId) => (dispatch, getState) =>
  deleteDelayedMessageCase(
    {
      getOpenedChannelId: applyState(getState, commonGetters.getOpenedChatId),
      sendDeleteMessageCMD: messages.sendDeleteDelayedMessageCMD
    },
    { messageId }
  );

export const createdDelayedMessage =
  ({ message }) =>
  (dispatch, getState) =>
    createdDelayedMessageCase(
      {
        addDelayedMessage: bindActionCreators(
          actions.addDelayedMessage,
          dispatch
        ),
        getChatMode: applyState(getState, commonGetters.getChatMode),
        showDelayedMessages: bindActionCreators(showDelayedMessages, dispatch)
      },
      { message }
    );

export const publishedDelayedMessage =
  ({ messageId }) =>
  (dispatch) =>
    publishedDelayedMessageCase(
      {
        removeMessage: bindActionCreators(
          actions.deleteDelayedMessage,
          dispatch
        )
      },
      { messageId }
    );

export const deletedDelayedMessage =
  ({ messageId }) =>
  (dispatch) =>
    deletedDelayedMessageCase(
      {
        removeMessage: bindActionCreators(
          actions.deleteDelayedMessage,
          dispatch
        )
      },
      { messageId }
    );

export const editDelayedMessage =
  ({ messageId, data }) =>
  (dispatch, getState) =>
    editDelayedMessageCase(
      {
        getOpenedChannelId: applyState(getState, commonGetters.getOpenedChatId),
        getPlannedTime: applyState(
          getState,
          getters.getDelayMessagesPlannedDate
        ),
        sendEditMessageCMD: messages.sendUpdateDelayedMessageCMD
      },
      { messageId, data }
    );

export const editedDelayedMessage =
  ({ message }) =>
  (dispatch) =>
    editedDelayedMessageCase(
      {
        updateMessage: bindActionCreators(
          actions.updateDelayedMessage,
          dispatch
        )
      },
      { message }
    );
