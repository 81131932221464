import {
  Dialog,
  SwipeableDrawer,
  useMediaQuery,
  useTheme
} from '@mui/material';
import cn from 'classnames';
import { WithClassName } from '@/components/common.types';
import { Typography } from '@/components/Typography';
import {
  PropsWithChildren,
  useCallback,
  useMemo,
  createContext,
  useContext
} from 'react';
import { ButtonIcon } from '@/components/ButtonIcon';
import { noop } from '@/lib/utils';
import styles from './Modal.module.scss';
import {
  ModalProps,
  ModalHeaderProps,
  ModalContentProps,
  ModalActionsProps,
  ModalContext
} from './Modal.types';

type CloseReason = 'escapeKeyDown' | 'backdropClick';

const drawerClasses = {
  paper: styles.drawer
};

const Context = createContext<ModalContext>(null as unknown as ModalContext);

function useModal() {
  return useContext<ModalContext>(Context);
}

function DesktopModal({
  children,
  open,
  onClose,
  className,
  disableBackdropClick = false,
  alignmentTop = false
}: PropsWithChildren<ModalProps>) {
  const classes = useMemo(
    () =>
      alignmentTop
        ? {
            root: styles.root
          }
        : { root: styles.paper },
    [alignmentTop]
  );

  const close = useCallback(
    (_event: {}, reason?: CloseReason) => {
      if (disableBackdropClick && reason && reason === 'backdropClick') {
        return;
      }
      if (typeof onClose === 'function') {
        onClose();
      }
    },
    [onClose, disableBackdropClick]
  );

  const context = useMemo(
    () => ({
      onClose: () => close({})
    }),
    [close]
  );

  return (
    <Dialog
      classes={classes}
      data-testid="modal"
      onClose={close}
      open={open}
      fullScreen={false}
      fullWidth={false}
      disableEscapeKeyDown={false}>
      <Context.Provider value={context}>
        <div
          className={cn(styles.container, className)}
          data-testid="modal-container">
          {children}
        </div>
      </Context.Provider>
    </Dialog>
  );
}

function SwipeableModal(props: PropsWithChildren<ModalProps>) {
  const { children, open, onClose, className } = props;

  const context = useMemo(
    () => ({
      onClose
    }),
    [onClose]
  );

  return (
    <SwipeableDrawer
      data-testid="modal"
      open={open}
      onClose={onClose}
      onOpen={noop}
      anchor="bottom"
      swipeAreaWidth={56}
      disableEscapeKeyDown={false}
      classes={drawerClasses}>
      <Context.Provider value={context}>
        <div
          className={cn(styles.drawerContainer, className)}
          data-testid="modal-container">
          <div className={styles.swipeableArea}>
            <div className={styles.puller} />
          </div>
          <div className={styles.drawerContent}>{children}</div>
        </div>
      </Context.Provider>
    </SwipeableDrawer>
  );
}

export function Modal(props: PropsWithChildren<ModalProps>) {
  const { adaptive } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  return adaptive && isMobile ? (
    <SwipeableModal {...props} />
  ) : (
    <DesktopModal {...props} />
  );
}

export function ModalHeader({
  children,
  className
}: PropsWithChildren<ModalHeaderProps>) {
  const { onClose } = useModal();

  return (
    <div className={cn(styles.header, className)} data-testid="modal-header">
      <Typography variant="h2" className={styles.headerText}>
        {children}
      </Typography>
      <ButtonIcon
        iconName="close"
        onClick={onClose}
        className={styles.closeBtn}
      />
    </div>
  );
}

export function ModalContent({
  children,
  className
}: PropsWithChildren<ModalContentProps>) {
  return (
    <div
      className={cn(styles.content, className, styles.specialTextColor)}
      data-testid="modal-content">
      {children}
    </div>
  );
}

type ActionsAlign = 'right' | 'left' | 'center' | 'space-between';

export interface ModalFooterProps extends WithClassName {
  align?: ActionsAlign;
}

export function ModalFooter({
  children,
  className,
  align = 'right'
}: PropsWithChildren<ModalFooterProps>) {
  return (
    <div
      className={cn(styles.footer, styles[align], className)}
      data-testid="modal-footer">
      {children}
    </div>
  );
}

export function ModalActions({
  children,
  className
}: PropsWithChildren<ModalActionsProps>) {
  return (
    <div className={cn(styles.actions, className)} data-testid="modal-actions">
      {children}
    </div>
  );
}

Modal.Content = ModalContent;
Modal.Header = ModalHeader;
Modal.Footer = ModalFooter;
Modal.Actions = ModalActions;
