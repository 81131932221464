import * as storage from './storage';
import * as actions from './actions';
import { getAllBadgesCase } from './useCases/getAllBadges';
import { clearBadgeCase } from './useCases/clearBadge';
import { subscribeToUpdateBadgesCase } from './useCases/subscribeToUpdateBadges';
import { unsubscribeFromUpdateBadgesCase } from './useCases/unsubscribeFromUpdateBadges';
import { subscribeToUnreadMessageCountCase } from './useCases/subscribeToUnreadMessageCount';
import { unsubscribeFromUnreadMessageCountCase } from './useCases/unsubscribeFromUnreadMessageCount';
import { applyState } from '../utils';
import { getClientData } from '../clientData/getters';

export const getAllBadges = () => async (dispatch) =>
  getAllBadgesCase({
    reqBadges: storage.reqBadges,
    setBadges: (badges) => dispatch(actions.setBadges(badges))
  });

export const clearBadge = (channelId) => (dispatch) =>
  clearBadgeCase(
    { clearBadge: (id) => dispatch(actions.clearBadge(id)) },
    { channelId }
  );

export const subscribeToUpdateBadges = () => (dispatch, getState) =>
  subscribeToUpdateBadgesCase({
    getClientData: applyState(getState, getClientData),
    subscribeToUpdateBadges: storage.subscribeToUpdateBadges,
    addOrUpdateBadge: (badge) => dispatch(actions.addOrUpdateBadge(badge))
  });

export const unsubscribeFromUpdateBadges = () => () =>
  unsubscribeFromUpdateBadgesCase({
    unsubscribeFromUpdateBadges: storage.unsubscribeFromUpdateBadges
  });

export const subscribeToUnreadMessageCount = () => (dispatch) =>
  subscribeToUnreadMessageCountCase({
    subscribeToUnreadMessageCount: storage.subscribeToUnreadMessageCount,
    updateUnreadMsgsCount: (msgsCount) =>
      dispatch(actions.updateUnreadMsgsCount(msgsCount))
  });

export const unsubscribeFromUnreadMessageCount = () => () =>
  unsubscribeFromUnreadMessageCountCase({
    unsubscribeFromUnreadMessageCount: storage.unsubscribeFromUnreadMessageCount
  });
