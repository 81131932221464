import { isNil } from 'ramda';
import { logModule } from '@/utils/logger';

const log = logModule.extend('dispatcher');

/**
 * Finds a function by event and calls it
 *
 * @param {string} fnName - function name
 * @param {{ string: Function }} handlers - hash of function key is eventName
 * @param {string} event - event name
 * @param {Function} [notHandlerEvent]
 * @returns {*}
 */
export const dispatcher = async (
  fnName,
  handlers,
  event,
  notHandlerEvent = () =>
    console.error(
      `Don't have handler for event: "${event}", at function: "${fnName}"`
    )
) => {
  const func = handlers[event];

  log(`function name: "${fnName}" with event: "${event}"`);

  return isNil(func) ? notHandlerEvent() : func();
};
