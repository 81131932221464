import { List, Set, Map, fromJS } from 'immutable';

const addResources = (state, { files, offset, size, resourceType }) =>
  state
    .setIn(
      [resourceType, 'files'],
      state.getIn([resourceType, 'files']).concat(fromJS(files))
    )
    .setIn([resourceType, 'offset'], offset)
    .setIn([resourceType, 'size'], size)
    .setIn([resourceType, 'isUpload'], true);

const initSetResources =
  (resourceType) =>
  (state, { files, offset, size, storageSize = 0 }) =>
    state
      .setIn([resourceType, 'files'], fromJS(files))
      .setIn([resourceType, 'offset'], offset)
      .setIn([resourceType, 'size'], size)
      .setIn([resourceType, 'isUpload'], true)
      .setIn([resourceType, 'storageSize'], storageSize);

const setCustomerOrderAttach = initSetResources('customersorders');

const setSupplierOrderAttach = initSetResources('suppliersorders');

const setCatalogsAttach = initSetResources('catalogs');

const setChatAttach = initSetResources('chat');

const setUploads = initSetResources('uploads');

const setAllFiles = (state, { files, page, totalSize }) =>
  state
    .set('all', state.get('all').concat(fromJS(files)))
    .set('page', page)
    .set('totalSize', totalSize)
    .set('isUpload', true);

const setFiles = (state, { files }) =>
  state.set('files', fromJS(files)).set('isUpload', true);

const select = (state, { id }) =>
  state.update('selected', new Set(), (selected) =>
    selected.includes(id) ? selected.remove(id) : selected.add(id)
  );

const addSelected = (state, { ids }) =>
  state.update('selected', (selected) => {
    const notAddedIds = ids.filter((id) => !selected.includes(id));
    return selected.concat(notAddedIds);
  });

const clearSelected = (state) =>
  state.set('selected', new Set()).set('isUpload', false);

const clearStorageAll = (state) => state.set('all', new List());

const setGoBackLink = (state, { link }) => state.set('link', link);
const unsetLink = (state) => state.set('link', '');

const addUpload = (state, file) =>
  state.update('uploads', (uploads) => {
    const updatedFiles = uploads.get('files').push(fromJS(file));
    const updatedSection = uploads.set('files', updatedFiles);
    return updatedSection;
  });

const setStorageLimit = (state, { size, maxSize }) =>
  state.set('maxStorageSize', maxSize).set('storageSize', size);

const setStorageSize = (state, { size }) => state.set('storageSize', size);

const removeFileFromSection = (state, { fileId, section: sectionName }) => {
  let name = sectionName;
  if (sectionName === 'customers-orders') {
    name = 'customersOrders';
  }
  if (sectionName === 'suppliers-orders') {
    name = 'suppliersOrders';
  }
  return state
    .update(name, (section) => {
      if (fileId) {
        const updatedFiles = section
          .get('files')
          .filter((i) => !fileId.includes(i.get('fileId')));
        const updatedSection = section
          .set('files', updatedFiles)
          .set('size', section.get('size') - fileId.length);
        return updatedSection;
      }
      return section;
    })
    .set('selected', new Set());
};

const setStatistic = (state, { employees, size, limit, offset }) =>
  state
    .setIn(['statistic', 'employees'], fromJS(employees))
    .setIn(['statistic', 'size'], size)
    .setIn(['statistic', 'limit'], limit)
    .setIn(['statistic', 'offset'], offset);

const makeStorage = () =>
  Map({
    files: List(),
    isUpload: false,
    offset: 0,
    size: 0,
    limit: 20,
    storageSize: 0
  });

const initState = () =>
  Map({
    suppliersorders: makeStorage(),
    customersorders: makeStorage(),
    catalogs: makeStorage(),
    chat: makeStorage(),
    uploads: makeStorage(),
    files: List(),
    all: List(),
    selected: new Set(),
    limit: 20,
    size: 0,
    offset: 0,
    link: '',
    isUpload: false,
    maxStorageSize: 2147483648,
    storageSize: 0,
    statistic: Map()
  });

/**
 * Do action
 *
 * @param {object} state
 * @param {Function} action
 * @returns {Function}
 */
export default function (state = initState(), action) {
  switch (action.type) {
    case 'STORAGE:ADD_RESOURCES:SUCCESS':
      return addResources(state, action.payload);
    case 'STORAGE:GET_SUPPLIER_ORDER_ATTACH:SUCCESS':
      return setSupplierOrderAttach(state, action.payload);
    case 'STORAGE:GET_CUSTOMER_ORDER_ATTACH:SUCCESS':
      return setCustomerOrderAttach(state, action.payload);
    case 'STORAGE:GET_CATALOGS_ATTACH:SUCCESS':
      return setCatalogsAttach(state, action.payload);
    case 'STORAGE:GET_CHAT_ATTACH:SUCCESS':
      return setChatAttach(state, action.payload);
    case 'STORAGE:GET_TAB_SEARCH:SUCCESS':
      return setAllFiles(state, action.payload);
    case 'STORAGE:GET_FILES:SUCCESS':
      return setFiles(state, action.payload);
    case 'STORAGE:GET_UPLOADS:SUCCESS':
      return setUploads(state, action.payload);
    case 'STORAGE:CLEAR_ORDERS_ATTACH':
      return initState();
    case 'STORAGE:SELECT':
      return select(state, action.payload);
    case 'STORAGE:ADD_SELECTED':
      return addSelected(state, action.payload);
    case 'STORAGE:CLEAR_SELECTED':
      return clearSelected(state);
    case 'STORAGE:SET_GO_BACK_LINK':
      return setGoBackLink(state, action.payload);
    case 'STORAGE:UNSET_LINK':
      return unsetLink(state);
    case 'STORAGE:FILE_UPLOAD:SUCCESS':
      return addUpload(state, action.payload);
    case 'STORAGE:REMOVE_FILES_LINK:SUCCESS':
      return removeFileFromSection(state, action.payload);
    case 'STORAGE:SET_LIMIT:SUCCESS':
      return setStorageLimit(state, action.payload);
    case 'STORAGE:SET_SIZE':
      return setStorageSize(state, action.payload);
    case 'STORAGE:SET_STATISTIC':
      return setStatistic(state, action.payload);
    case 'STORAGE:CLEAR:ALL':
      return clearStorageAll(state);
    case 'STORAGE:CLEAR':
      return initState();
    default:
      return state;
  }
}
