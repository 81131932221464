import cn from 'classnames';
import { WithClassName } from '@/components/common.types';
import styles from './dotBadge.module.scss';

interface DotBadgeProps extends WithClassName {
  shown: boolean;
}

export function DotBadge(props: DotBadgeProps) {
  const { className, shown } = props;

  return shown ? <div className={cn(styles.badge, className)} /> : null;
}
