import { assoc, isNil } from 'ramda';

/**
 * @typedef {(number|string)} Id
 */

/**
 * @typedef Input
 * @property {Id} id
 * @property {string} text
 */

/**
 * @typedef BlockTemp
 * @property {[Input]} inputs
 */

/**
 * Creates a block temp data type
 *
 * @param {object} params
 * @param {[Input]} params.inputs
 * @returns {BlockTemp}
 */
export const makeBlockTemp = ({ inputs = [] } = {}) => ({
  type: 'BlockTemp',
  inputs
});

/**
 * Check temporary
 *
 * @param {BlockTemp} blockTemp
 * @param {Id} id
 * @returns {Input}
 */
export const getTempInput = (blockTemp, id) =>
  blockTemp.inputs.find((item) => item.id === id);

/**
 * Add to temporary input
 *
 * @param {BlockTemp} blockTemp
 * @param {Id} chatId
 * @param {Input} tempInput
 * @returns {BlockTemp}
 */
export const addOrUpdateToTempInput = (blockTemp, chatId, tempInput) => {
  const newTemp = { id: chatId, ...tempInput };
  let updatedTemp = [...blockTemp.inputs];
  if (!isNil(getTempInput(blockTemp, chatId))) {
    updatedTemp = updatedTemp.map((item) =>
      item.id === chatId ? newTemp : item
    );
  } else {
    updatedTemp.push(newTemp);
  }
  return assoc('inputs', updatedTemp, blockTemp);
};

/**
 * Remove from temporary input
 *
 * @param {BlockTemp} blockTemp
 * @param {Id} id
 * @returns {BlockTemp}
 */
export const removeInputFromTemp = (blockTemp, id) => {
  const updatedTemp = blockTemp.inputs.filter((item) => item.id !== id);
  return assoc('inputs', updatedTemp, blockTemp);
};

/**
 * Clear temporary inputs
 *
 * @param {BlockTemp} blockTemp
 * @returns {BlockTemp}
 */
export const clearInputsTemp = (blockTemp) => assoc('inputs', [], blockTemp);
