import { assoc } from 'ramda';
import {
  NAV_PANEL_LS_KEY,
  REDUX_KEY,
  SET_FULL_MODE,
  SET_SHORT_MODE,
  TOGGLE_MODE
} from './constants';
import {
  makeNavigationSidebar,
  setFullMode,
  setShortMode,
  toggleNavigationSidebarMode
} from '../SidebarNavMenu/dataType';
import { getData } from '../../storage/lib/LS';

function fromLS() {
  return getData(NAV_PANEL_LS_KEY);
}

export const defaultState = () => ({
  [REDUX_KEY]: makeNavigationSidebar(fromLS() || {})
});

const setNavigationSidebar = (state, value) => assoc([REDUX_KEY], value, state);

export const reducers = {
  [SET_FULL_MODE]: (state) => setNavigationSidebar(state, setFullMode()),
  [SET_SHORT_MODE]: (state) => setNavigationSidebar(state, setShortMode()),
  [TOGGLE_MODE]: (state) =>
    setNavigationSidebar(
      state,
      toggleNavigationSidebarMode(state[REDUX_KEY].mode)
    )
};
