import React from 'react';

/* eslint-disable max-len */
const IconArchiveIn = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 9.0955161,13.044558 7.4720671,11.2407 m 3.2468999,0 -1.6234499,1.803858 m 0,-4.0586147 V 13.044558 M 15.581758,5.7451009 c 0.133134,-0.00346 0.229805,-0.011148 0.316724,-0.028432 0.644001,-0.1281011 1.147433,-0.6315329 1.275534,-1.275534 0.03119,-0.1568253 0.03119,-0.345416 0.03119,-0.7225868 0,-0.3771708 0,-0.5657616 -0.03119,-0.722576 C 17.045915,2.3519602 16.542483,1.8485283 15.898482,1.7204273 15.741657,1.6892354 15.553066,1.6892354 15.175895,1.6892354 H 3.0000214 c -0.3771816,0 -0.5657183,0 -0.7225436,0.031192 C 1.633401,1.8485284 1.1300232,2.3519603 1.0018789,2.9959722 0.9707087,3.1527866 0.9707087,3.3413774 0.9707087,3.7185482 c 0,0.3771708 0,0.5657615 0.0311702,0.7225868 0.1281443,0.6440011 0.6315221,1.1474329 1.2755989,1.275534 0.086909,0.017284 0.1835581,0.02499 0.316681,0.028432 M 15.581758,5.747861 H 2.5941588 v 6.656135 c 0,1.363806 0,2.045763 0.26538,2.566674 0.2335604,0.458246 0.6060881,0.830774 1.0642257,1.064226 0.520911,0.26538 1.2028683,0.26538 2.5666747,0.26538 h 5.1950378 c 1.363828,0 2.045742,0 2.566653,-0.26538 0.458203,-0.233452 0.830742,-0.60598 1.064204,-1.064226 0.265424,-0.520911 0.265424,-1.202868 0.265424,-2.566674 z"
      stroke="#5B5F73"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconArchiveIn;
