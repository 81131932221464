import {
  SHOW_PROFILE,
  HIDE_PROFILE,
  SET_PROFILE,
  UPDATE_PROFILE
} from './constants';

export const showProfile = (contact) => ({
  type: SHOW_PROFILE,
  payload: contact
});
export const hideProfile = () => ({ type: HIDE_PROFILE, payload: {} });
export const setProfile = (profile) => ({
  type: SET_PROFILE,
  payload: profile
});
export const updateProfile = (profile) => ({
  type: UPDATE_PROFILE,
  payload: profile
});
