import React from 'react';

/* eslint-disable max-len */
const IconCalendar = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      className="cws-icon-hover"
      cx="17"
      cy="17"
      r="17"
      fill="#767B92"
      fillOpacity="0"
    />
    <rect
      className="cws-icon-main-stroke"
      x="8.5"
      y="9.5"
      width="17"
      height="16"
      rx="1.5"
      stroke="#767B92"
    />
    <line
      className="cws-icon-main-stroke"
      x1="12.5"
      y1="8.5"
      x2="12.5"
      y2="11.5"
      stroke="#767B92"
      strokeLinecap="round"
    />
    <line
      className="cws-icon-main-stroke"
      x1="21.5"
      y1="8.5"
      x2="21.5"
      y2="11.5"
      stroke="#767B92"
      strokeLinecap="round"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 19H12V18H13V19Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 22H12V21H13V22Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 16H15V15H16V16Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 16H12V15H13V16Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 19H15V18H16V19Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 22H15V21H16V22Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 16H18V15H19V16Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 19H18V18H19V19Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 22H18V21H19V22Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 16H21V15H22V16Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 19H21V18H22V19Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconCalendar;
