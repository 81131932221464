import React from 'react';

/* eslint-disable max-len */
const IconSettings1 = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      className="cws-icon-hover"
      cx="17"
      cy="17"
      r="17"
      fill="#767B92"
      fillOpacity="0"
    />
    <path
      className="cws-icon-main-stroke"
      d="M24.5 12.5L17.5 12.5"
      stroke="#767B92"
      strokeLinecap="round"
    />
    <path
      className="cws-icon-main-stroke"
      d="M9.5 21.5L16.5 21.5"
      stroke="#767B92"
      strokeLinecap="round"
    />
    <path
      className="cws-icon-main-fill"
      d="M9.5 12C9.22386 12 9 12.2239 9 12.5C9 12.7761 9.22386 13 9.5 13L9.5 12ZM12 12L9.5 12L9.5 13L12 13L12 12Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      d="M24.5 22C24.7761 22 25 21.7761 25 21.5C25 21.2239 24.7761 21 24.5 21L24.5 22ZM22 22L24.5 22L24.5 21L22 21L22 22Z"
      fill="#767B92"
    />
    <circle
      className="cws-icon-main-stroke"
      cx="15.5"
      cy="12.5"
      r="2"
      transform="rotate(-180 15.5 12.5)"
      stroke="#767B92"
    />
    <circle
      className="cws-icon-main-stroke"
      cx="18.5"
      cy="21.5"
      r="2"
      stroke="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconSettings1;
