import React from 'react';

export const UploadIcon = ({ width = 18, height = 18 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 9L9 6M9 6L6 9M9 6V12.9C9 13.943 9 14.4645 9.41289 15.0485C9.68722 15.4364 10.4771 15.9152 10.9479 15.979C11.6566 16.075 11.9257 15.9347 12.4639 15.6539C14.8625 14.4027 16.5 11.8926 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 11.7761 3.00825 14.1998 5.25 15.4966"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
