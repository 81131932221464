import { PropsWithChildren, CSSProperties } from 'react';
import cn from 'classnames';
import { WithClassName } from '@/components/common.types';
import styles from './BaseCell.module.scss';

interface BaseCellProps extends PropsWithChildren, WithClassName {
  colSpan?: number;
  width?: number;
  unstyled?: boolean;
  style?: CSSProperties;
}

export function BaseCell({
  colSpan,
  width,
  className,
  unstyled,
  style,
  children
}: BaseCellProps) {
  return (
    <td
      className={cn(styles.cell, className, {
        [styles.unstyled]: unstyled
      })}
      colSpan={colSpan}
      width={width}
      style={style}>
      {children}
    </td>
  );
}
