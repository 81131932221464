import { setQueryList } from './actions';
import { getChatFilter, getQueryList } from './getters';
import { getQueryCase } from './useCases/getQuery';
import { getFilterCase } from './useCases/getFilter';
import { updateQueryCase } from './useCases/updateQuery';
import { applyState } from '../utils';

export const getQuery = (queryName, state) =>
  getQueryCase(
    {
      getQueryList: () => getQueryList(state)
    },
    { queryName }
  );

export const getFilter = (name, state) =>
  getFilterCase(
    {
      getChatFilter: () => getChatFilter(state)
    },
    { name }
  );

export const updateQuery = (queryName, updatedQuery) => (dispatch, getState) =>
  updateQueryCase(
    {
      getQueryList: applyState(getState, getQueryList),
      setQueryList: (queryList) => dispatch(setQueryList(queryList))
    },
    { queryName, updatedQuery }
  );
