import { pipe } from 'ramda';
import {
  makeReduxDispatcher,
  extractActions,
  extractReducers
} from '../../../../lib/actionReducer';
import * as data from './data-type';

export const initState = pipe(
  data.hideModal,
  data.emptyRequest,
  data.emptyCategories
)({});

const dispatcher = makeReduxDispatcher({
  name: 'CW-PR-WITH-PESPONSE',
  initState,
  reducers: {
    show: data.showModal,
    hide: data.hideModal,
    setRequest: data.setRequest,
    emptyRequest: data.emptyRequest,
    setCategories: data.setCategories,
    emptyCategories: data.emptyCategories
  }
});

export const reducers = extractReducers(dispatcher);
export const {
  show,
  hide,
  setRequest,
  setCategories,
  emptyRequest,
  emptyCategories
} = extractActions(dispatcher);
