import { defRecord } from 'core/lib';

const {
  makeProfile,
  lastName,
  firstName,
  middleName,
  getEmployeeId,
  getAvatar,
  getCompany,
  getPhoneNumber,
  getEmail,
  getSkypeLogin,
  getViberLogin,
  getWhatsappLogin,
  getCompanyDeleted,
  getEmployeeVisibility,
  getIsBlockedContact,
  getIsBlockedMe,
  getIsDeleted,
  getIsHidden,
  getNickname,
  getPosition,
  getDepartment,
  getIsContactHidden
} = defRecord('Profile', [
  'employeeId',
  'lastName',
  'firstName',
  'middleName',
  'avatar',
  'company',
  'phoneNumber',
  'email',
  'nickname',
  'position',
  'department',
  'skypeLogin',
  'viberLogin',
  'whatsappLogin',
  'companyDeleted',
  'employeeVisibility',
  'isBlockedContact',
  'isBlockedMe',
  'isDeleted',
  'isHidden',
  'isContactHidden'
]);

function getFullName(profile) {
  return `${lastName(profile) || ''} ${firstName(profile) || ''} ${
    middleName(profile) || ''
  }`.trim();
}

function isVisibleToAllPartners(profile) {
  return getEmployeeVisibility(profile) === 'visible-to-all';
}

function isEmployeeEqual(profile, myEmployeeId) {
  return myEmployeeId === getEmployeeId(profile);
}

function isDeleteCompanyOrEmployee(profile) {
  return getCompanyDeleted(profile) || getIsDeleted(profile);
}

export {
  makeProfile,
  getFullName,
  isVisibleToAllPartners,
  isEmployeeEqual,
  isDeleteCompanyOrEmployee,
  getEmployeeId,
  getAvatar,
  getCompany,
  getPhoneNumber,
  getEmail,
  getNickname,
  getPosition,
  getDepartment,
  getSkypeLogin,
  getViberLogin,
  getWhatsappLogin,
  getIsBlockedContact,
  getIsBlockedMe,
  getIsDeleted,
  getIsHidden,
  getIsContactHidden
};
