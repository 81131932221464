import { toast } from '@/modules/toast';

const PLACEHOLDER_TYPE = '__NOTHING_DO__';

const placeholderAction = { type: PLACEHOLDER_TYPE };

export const setMessage = () => {
  toast.dismiss();

  return placeholderAction;
};

export const clearMessages = () => {
  toast.dismiss();

  return placeholderAction;
};

export const setSuccessMessage = (body, dismissible) => {
  if (!body) {
    return clearMessages();
  }

  toast.success('', {
    autoClose: dismissible,
    data: body
  });

  return placeholderAction;
};

export const setWarningMessage = (body, dismissible) => {
  if (!body) {
    return clearMessages();
  }

  toast.warning('', {
    autoClose: dismissible,
    data: body
  });

  return placeholderAction;
};

export const setErrorMessage = (body, dismissible) => {
  if (!body) {
    return clearMessages();
  }

  toast.error('', {
    autoClose: dismissible,
    data: body
  });

  return placeholderAction;
};

export const setInfoMessage = (body, dismissible) => {
  if (!body) {
    return clearMessages();
  }

  toast.info('', {
    autoClose: dismissible,
    data: body
  });

  return placeholderAction;
};

export const deleteMessageAttach = (attachId, dialogId) => async (dispatch) => {
  try {
    dispatch({
      type: 'CHAT:DELETE_FILE_ATTACH:REQUEST',
      payload: { attachId }
    });
    const res = await fetch(`/api/chat/attach/${attachId}/delete`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ attachId, dialogId })
    });
    const { messages } = await res.json();
    dispatch({
      type: 'CHAT:DELETE_FILE_ATTACH:SUCCESS',
      payload: messages
    });
    dispatch(setSuccessMessage({ key: 'attach_delete_success' }));
    return await Promise.resolve();
  } catch (e) {
    dispatch({
      type: 'CHAT:DELETE_FILE_ATTACH:FAILURE',
      payload: { attachId, errors: e }
    });
    dispatch(setErrorMessage({ key: e[0] || 'Error while deleting attach' }));
    return Promise.reject();
  }
};

export const copyMessageAttach = (attachId, dialogId) => async (dispatch) => {
  try {
    dispatch({ type: 'CHAT:COPY_FILE_ATTACH:REQUEST', payload: { attachId } });
    const res = await fetch(`/api/chat/attach/${attachId}/copy`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ attachId, dialogId })
    });
    const json = await res.json();
    if (json.errors && json.errors[0].message) {
      return dispatch(
        setErrorMessage({
          key: json.errors[0].message || 'Error while coping attach'
        })
      );
    }
    dispatch({
      type: 'CHAT:COPY_FILE_ATTACH:SUCCESS',
      payload: { json }
    });
    return dispatch(setSuccessMessage({ key: 'attach_copy_success' }));
  } catch (e) {
    dispatch({
      type: 'CHAT:COPY_FILE_ATTACH:FAILURE',
      payload: { attachId, errors: e }
    });
    return dispatch(
      setErrorMessage({ key: e[0] || 'Error while coping attach' })
    );
  }
};
