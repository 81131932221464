import { CSSProperties, ReactNode } from 'react';
import { BaseCell } from '@/components/EditableTable/components/BaseCell';
import { Typography, TypographyProps } from '@/components/Typography';
import styles from './readOnlyTextCell.module.scss';

interface ReadOnlyTextCellProps
  extends Pick<TypographyProps, 'variant' | 'align' | 'noWrap'> {
  value: string;
  placeholder?: string | null;
  colSpan?: number;
  width?: number;
  cellClassName?: string;
  style?: CSSProperties;
  endAdornment: ReactNode | null;
  startAdornment: ReactNode | null;
}

export function ReadOnlyTextCell({
  cellClassName,
  colSpan,
  width,
  variant = 'body1Reg',
  value,
  placeholder,
  noWrap,
  align,
  style,
  endAdornment,
  startAdornment
}: ReadOnlyTextCellProps) {
  return (
    <BaseCell
      className={cellClassName}
      colSpan={colSpan}
      width={width}
      style={style}>
      <span
        className={
          startAdornment
            ? styles.cellWithStartAdornment
            : styles.cellWithEndAdornment
        }>
        {startAdornment}
        <Typography variant={variant} align={align} noWrap={noWrap}>
          {value || placeholder}
        </Typography>
        {endAdornment}
      </span>
    </BaseCell>
  );
}
