import { useEffect, useState, useRef, useCallback } from 'react';
import { useList } from 'react-use';
import { isNil, last } from 'ramda';
import { emitToServer } from '@/storage/message';
import {
  transcriptQRY,
  startRecordingCMD,
  endRecordingCMD,
  startedRecordingEVT,
  endedRecordingEVT,
  sendMessageCMD,
  receivedMessageEVT
} from './messages';
import { useSubscriptions } from '../useSubscriptions';

const SpeechRecognition =
  window.SpeechRecognition ||
  window.webkitSpeechRecognition ||
  window.mozSpeechRecognition ||
  window.msSpeechRecognition ||
  window.oSpeechRecognition;

export function useTranscript(
  roomId,
  currentMember,
  isMicrophoneMuted = false
) {
  const speechRecogniser = useRef(null);
  const [isActive, setActive] = useState(false);
  const [messages, messagesActions] = useList();
  const onPauseRef = useRef(false);

  const { subscribe, unsubscribeAll } = useSubscriptions();

  useEffect(() => {
    if (!roomId) return undefined;
    if (!SpeechRecognition) {
      console.error(`Speech recognition is not supported in your browser`);
      return undefined;
    }

    emitToServer(transcriptQRY.sync, { roomId });

    subscribe(receivedMessageEVT, (data) => {
      messagesActions.push(data.message);
    });

    subscribe(startedRecordingEVT, onStartTranscript);
    subscribe(endedRecordingEVT, onEndTranscript);

    return () => {
      onEndTranscript();
      emitToServer(transcriptQRY.syncOff, { roomId });
      messagesActions.clear();
      unsubscribeAll();
    };
  }, [roomId]);

  useEffect(() => {
    if (!isActive) {
      return;
    }

    if (!isMicrophoneMuted) {
      onPauseRef.current && onStartTranscript();
    } else {
      !onPauseRef.current && onPauseTranscript();
    }
  }, [isMicrophoneMuted, isActive, onPauseRef]);

  function onStartTranscript() {
    if (isNil(speechRecogniser.current)) {
      speechRecogniser.current = new SpeechRecognition();
      speechRecogniser.current.continuous = true;
      speechRecogniser.current.lang = 'ru-RU';
      speechRecogniser.current.onresult = (event) => {
        emitToServer(sendMessageCMD, {
          roomId,
          message: { text: last(event.results)[0].transcript },
          sender: currentMember
        });
      };
      speechRecogniser.current.onaudioend = () => {
        setTimeout(() => {
          onPauseRef.current = true;
        }, 0);
      };
      speechRecogniser.current.onaudiostart = () => {
        setTimeout(() => {
          onPauseRef.current = false;
        }, 100);
      };
    }

    speechRecogniser.current.start();
    setActive(true);
  }

  function onPauseTranscript() {
    if (speechRecogniser.current instanceof SpeechRecognition) {
      speechRecogniser.current.stop();
    }
  }

  function onEndTranscript() {
    if (speechRecogniser.current instanceof SpeechRecognition) {
      speechRecogniser.current.stop();
      setActive(false);
      speechRecogniser.current = null;
      onPauseRef.current = false;
    }
  }

  const emitStart = useCallback(() => {
    emitToServer(startRecordingCMD, { roomId });
  }, [roomId]);

  const emitStop = useCallback(() => {
    emitToServer(endRecordingCMD, { roomId });
  }, [roomId]);

  return {
    messages,
    start: emitStart,
    stop: emitStop,
    isActive
  };
}
