import React from 'react';

function IconStyleItalic() {
  return (
    <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.287.421a.693.693 0 0 0-.444.348c-.046.088-.053.125-.053.281 0 .218.041.322.179.461.178.177.184.178.966.185.377.003.685.011.685.018 0 .01-3.763 8.148-3.866 8.36l-.041.084-.892.006-.891.006-.1.053a.626.626 0 0 0-.346.54c-.013.221.029.334.184.489.215.215.008.198 2.484.198h2.142l.137-.07a.638.638 0 0 0 .165-1.043c-.169-.166-.225-.177-.879-.177-.526 0-.56-.002-.548-.035.008-.019.887-1.923 1.956-4.23L9.067 1.7h.766c.718 0 .772-.003.873-.04a.65.65 0 0 0 .406-.464.432.432 0 0 0 .008-.244.634.634 0 0 0-.379-.49L10.63.41 8.5.407C7.328.405 6.332.411 6.287.421"
        fill="#7F869E"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default IconStyleItalic;
