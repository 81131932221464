.root {
  height: 16px;
}

.helpIcon {
  width: 18px;
  height: 18px;

  &:hover {
    cursor: pointer;

    & path {
      fill: var(--color-blue-500);
    }
  }
}

.infoContainer {
  width: 100%;
  position: absolute;
  top: 34px;
  left: 0;
  z-index: 10;
  padding: 12px;
  background-color: var(--color-gray-100);
  border: 1px solid var(--color-blue-500);
  border-radius: 4px;
  border-top: 4px solid var(--color-blue-500);
  box-shadow: 0 1px 4px rgb(118 123 146 / 30%);
}
