import { PREFIX } from '../constants';

export const SET_CONTACTS = `${PREFIX}:SET_CONTACTS`;
export const SET_FILTERED_CONTACTS = `${PREFIX}:SET_FILTERED_CONTACTS`;
export const ADD_CONTACTS = `${PREFIX}:ADD_CONTACTS`;
export const UPDATE_CONTACT = `${PREFIX}:UPDATE_CONTACT`;
export const SET_LOADING_CONTACTS = `${PREFIX}:SET_LOADING_CONTACTS`;

export const UPDATE_CONTACTS = `${PREFIX}:UPDATE_CONTACTS`;

export const SET_CONTACT_STATUS = `${PREFIX}:SET_CONTACT_STATUS`;

export const UNHIDE_DIALOG = 'CHAT:UNHIDE_DIALOG';
