import React from 'react';
import Icon, { Icons } from 'react-components/src/icons';

const SelectIcon = ({ size = 18, isSelected, onClick }) => (
  <div style={{ width: size, height: size }} onClick={onClick}>
    <Icon
      icon={isSelected ? Icons.SelectActive : Icons.SelectBlank}
      size={size}
    />
  </div>
);

export default SelectIcon;
