import { prepend, isEmpty, dropLast } from 'ramda';

export const EMPTY_MODAL = null;
/**
 * @typedef {(object|EMPTY_MODAL)} Modal
 * @property {string} type
 * @property {string} name
 * @property {object} props
 */

/**
 * @typedef {object} ModalManager
 * @property {string} type
 * @property {string} current
 * @property {Date} history
 */

/**
 * Creates a modal data type
 *
 * @param {string} name
 * @param {object} props
 * @returns {Modal}
 */
export const makeModal = (name, props = {}) => ({ type: 'Modal', name, props });

/**
 * Creates a modal history data type
 *
 * @param {[Modal]} history
 * @returns {[Modal]}
 */
export const makeHistory = (history = []) => history;

/**
 * Creates modal manager
 *
 * @param {Modal} current
 * @param {[Modal]} history
 * @returns {ModalManager}
 */
export const makeModalManager = (
  current = EMPTY_MODAL,
  history = makeHistory()
) => ({
  type: 'ModalManager',
  current,
  history
});

/**
 * Shows modal with name and props
 *
 * @param {ModalManager} modalManager
 * @param {string} name
 * @param {object} props
 * @returns {ModalManager}
 */
export const show = (modalManager, name, props) =>
  makeModalManager(makeModal(name, props));

/**
 * Hides modal with name and props
 *
 * @param {ModalManager} modalManager
 * @returns {ModalManager}
 */
export const hide = (modalManager) => {
  /**
   * Adds to history new modal
   *
   * @param {Modal} modal
   * @param {[Modal]} history
   * @returns {[Modal]}
   */
  const addToHistory = (modal, history) => {
    if (history.length === 3) {
      const updatedHistory = prepend(modal, history);
      return dropLast(1, updatedHistory);
    }

    return prepend(modal, history);
  };

  return makeModalManager(
    EMPTY_MODAL,
    addToHistory(modalManager.current, modalManager.history)
  );
};

/**
 * Shows last modal
 *
 * @param {ModalManager} modalManager
 * @returns {ModalManager}
 */
export const showLast = (modalManager) => {
  if (isEmpty(modalManager.history)) return modalManager;

  const [head, ...tail] = modalManager.history;
  return makeModalManager(head, tail);
};

/**
 * Determiners if show modal
 *
 * @param {ModalManager} modalManager
 * @returns {boolean}
 */
export const isShow = (modalManager) => EMPTY_MODAL !== modalManager.current;

/**
 * Determiners if previous modal have such name
 *
 * @param {string} name
 * @param {ModalManager} modalManager
 * @returns {boolean}
 */
export const equalPrevName = (name, modalManager) => {
  if (isEmpty(modalManager.history)) return false;

  return modalManager.history[0].name === name;
};
