import React from 'react';

function IconStylePunkt2() {
  return (
    <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.765 3.004c-.413.233-.755.428-.76.433a4.27 4.27 0 0 0 .206.431l.217.422.093-.056c.134-.08.502-.289.582-.331l.067-.035V6.12H.178l.009.472.008.473h3.09l.008-.473.009-.472H2.37V2.58h-.855l-.75.424m3.886.479a.652.652 0 0 0-.378.802c.06.181.256.375.426.423.172.047 12.636.045 12.795-.003a.687.687 0 0 0 .402-.369.667.667 0 0 0-.246-.796l-.111-.075-6.407-.006c-4.169-.003-6.433.005-6.481.024m.104 4.465a.68.68 0 0 0-.496.472c-.094.333.141.715.484.789.121.026 1.754.032 6.447.025l6.285-.009.143-.084a.805.805 0 0 0 .225-.208c.071-.108.082-.153.082-.353 0-.2-.011-.245-.082-.353a.805.805 0 0 0-.225-.208l-.143-.084-6.315-.005c-3.473-.002-6.356.006-6.405.018M1.263 9.754c-.392.036-.926.226-1.133.405l-.065.056.168.397.168.397.092-.061c.305-.201.604-.298.917-.298.624 0 .753.517.271 1.092-.067.08-.412.449-.767.818l-.644.672v.839l1.477-.008 1.478-.008.008-.443.009-.442h-.796c-.438 0-.796-.009-.796-.02 0-.011.222-.245.492-.52.521-.529.712-.784.832-1.11.06-.165.071-.25.071-.556 0-.347-.004-.368-.099-.561-.246-.501-.833-.727-1.683-.649m3.357 2.654a.644.644 0 0 0-.347.787c.06.181.256.375.426.423.172.047 12.636.045 12.795-.003a.687.687 0 0 0 .402-.369.667.667 0 0 0-.246-.796l-.111-.075-6.407-.006c-5.673-.006-6.419-.002-6.512.039"
        fill="#7F869E"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default IconStylePunkt2;
