import { getBadges, getChatMsgs } from '@/components/ChatWidget/getter';
import { PURCHASE_REQUESTS_CHANNEL } from 'core/data/light/purchaseRequestChannel';
import { NEWS_FEED_CHANNEL } from 'core/data/light/newsFeedChannel';
import { ac } from '@/components/ChatWidget/useCases/shared/actions';
import { uploadFiles } from '@/components/ChatWidget/useCases/uploadFiles';

export const uploadMessages =
  (chat, initial = false) =>
  async (dispatch, getState, service) => {
    const { getFindChunkPrev } = service.chatMessage;

    const { cancelLoad } = getChatMsgs(getState());

    cancelLoad?.();

    async function calcLimit() {
      const defaultLimit = 40;
      const maxLimit = 100;
      const threshold = 3;

      let result = defaultLimit;

      const { badges } = getBadges(getState());
      const unreadCount =
        badges?.find((badge) => badge.chatId === chat.id)?.count ?? 0;

      result =
        unreadCount > defaultLimit ? unreadCount + threshold : defaultLimit;
      if (result > maxLimit) {
        result = defaultLimit;
      }

      return result;
    }

    const limit = await calcLimit();

    const [findChunk, cancel] = getFindChunkPrev(chat.id, null, limit, initial);

    dispatch(ac.setMessages([], 0, 0, cancel));

    dispatch(ac.setMessagesLoaded(false));
    const chunk = await findChunk();

    const NOT_LOAD_FILES = [PURCHASE_REQUESTS_CHANNEL, NEWS_FEED_CHANNEL];
    if (!NOT_LOAD_FILES.includes(chat.type)) dispatch(uploadFiles(chat));

    if (chunk) {
      dispatch(
        ac.setMessages(chunk.list, chunk.amountLeft, chunk.amountLeftNext, null)
      );
    }
    dispatch(ac.setMessagesLoaded(true));
  };
