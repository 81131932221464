import React from 'react';

const ImagePlusS = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      className="cws-icon-main-stroke"
      x="0.5"
      y="0.5"
      width="17"
      height="17"
      rx="8.5"
      stroke="white"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.73535 13.7657V4.23633L9.26476 4.23633L9.26476 13.7657H8.73535Z"
      fill="white"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.23535 8.73633L13.7648 8.73633V9.26574L4.23535 9.26574L4.23535 8.73633Z"
      fill="white"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.73535 13.7657V4.23633L9.26476 4.23633L9.26476 13.7657H8.73535Z"
      stroke="white"
      strokeWidth="0.5"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.23535 8.73633L13.7648 8.73633V9.26574L4.23535 9.26574L4.23535 8.73633Z"
      stroke="white"
      strokeWidth="0.5"
    />
  </svg>
);

export default ImagePlusS;
