import { isNil, assocPath, dissocPath, path, hasPath, curry } from 'ramda';
import { getChatUnsentAllUsers } from '@/components/ChatWidget/getter';
import { logModule } from '@/utils/logger';

const log = logModule.extend('localStorage');
export const setData = (key, data) => {
  log(`setData key:`, key, 'data', data);
  localStorage.setItem(key, JSON.stringify(data));
};

export const setDataWithUserId = ({ key, data, userId }) => {
  localStorage.setItem(`${userId}-${key}`, JSON.stringify(data));
};

export const getDataByKeyAndUserId = ({ key, userId }) =>
  JSON.parse(localStorage.getItem(`${userId}-${key}`));

export const removeData = (key) => localStorage.removeItem(key);

export const getData = (key) => JSON.parse(localStorage.getItem(key));

export const setOrUpdateData = (key, data) => {
  const prevData = getData(key);

  if (isNil(prevData)) return setData(key, data);

  return setData(key, { ...prevData, ...data });
};

export const getDataDefault = (key, defaults) => {
  const data = getData(key);
  return isNil(data) ? defaults : data;
};

export const saveToLSByState = (key, func) => () => (dispatch, getState) =>
  setData(key, func(getState()));

export const writeUnsentChatMessagesToLS = saveToLSByState(
  'unsentMessages',
  getChatUnsentAllUsers
);

export const setEmployeeData = curry((key, employeeId, dataId, data) => {
  const currentData = getData(key);
  const updatedData = assocPath([employeeId, dataId], data, currentData);
  setData(key, updatedData);
});

export const getEmployeeData = curry((key, employeeId, dataId) => {
  const currentData = getData(key);
  return path([employeeId, dataId], currentData);
});

export const hasEmployeeData = curry((key, employeeId, dataId) => {
  const currentData = getData(key);
  return hasPath([employeeId, dataId], currentData);
});

export const removeEmployeeData = curry((key, employeeId, dataId) => {
  const currentData = getData(key);
  const updatedData = dissocPath([employeeId, dataId], currentData);
  setData(key, updatedData);
  return updatedData;
});

export const listClearData = [
  {
    fn: () => removeData('unsentMessages'),
    itemMsg: 'Неотправленные на сервер сообщения из мессенджера'
  },
  {
    fn: () => {
      removeData('purchase-requests-responses-filter');
      removeData('my-responses-filter');
      removeData('purchase-requests-filter');
    },
    itemMsg: 'Данные фильтров'
  }
];

export const clearData = () => {
  listClearData.forEach((i) => i.fn());
};

export const confirmClearData = (cb) => {
  const prepareItems = () =>
    listClearData.map((i) => `\n- ${i.itemMsg};`).join('');
  const isConfirmed = confirm(
    `Очистить localstorage?\n\nВнимание!\nОчистятся следующие данные:${prepareItems()}`
  );
  if (isConfirmed) {
    cb();
    alert('Данные были очищены!');
  }
};
