import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
  useCallback
} from 'react';

interface NotificationProviderProps {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  onClose: () => void;
  isTrial: boolean;
  setIsTrial: (value: boolean) => void;
}

const Context = createContext(null as unknown as NotificationProviderProps);

export function useNotificationContext() {
  const context = useContext(Context);

  if (!context) {
    throw new Error(`useNotificationContext cannot be used here`);
  }

  return context;
}

export function NotificationProvider({ children }: PropsWithChildren) {
  const [isVisible, setIsVisible] = useState(false);
  const [isTrial, setIsTrial] = useState(false);
  const onClose = useCallback(() => setIsVisible(false), []);

  const context = useMemo(
    () => ({
      isVisible,
      setIsVisible,
      onClose,
      isTrial,
      setIsTrial
    }),
    [isVisible, isTrial]
  );

  return (
    <Context.Provider value={context as NotificationProviderProps}>
      {children}
    </Context.Provider>
  );
}
