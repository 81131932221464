import { assoc } from 'ramda';
import slice from '../../../../../lib/actionReducer';

const initState = {
  loading: false,
  newsAPIUrl: ''
};

const externalAPISlice = slice({
  name: 'EXTERNAL_API',
  initState,
  reducers: {
    setLoading: (state, payload) => assoc('loading', payload, state),
    setNewsAPIUrl: (state, payload) => assoc('newsAPIUrl', payload, state)
  }
});

export const { actions } = externalAPISlice;
export default externalAPISlice.reducer;
