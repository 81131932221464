import React from 'react';

function IconStyleBold() {
  return (
    <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.729.405c-.005.014-.007 2.54-.004 5.615l.005 5.59 2.64.006c2.742.006 2.921.002 3.278-.078a3.328 3.328 0 0 0 .633-6.301c-.022-.008-.002-.048.081-.158.284-.381.467-.8.549-1.254.038-.209.042-.693.009-.979C8.752 1.413 7.909.52 6.61.4 6.266.369 1.741.373 1.729.405m5.04 1.262c.378.114.641.363.796.754.111.277.177.825.136 1.13-.047.354-.23.718-.49.976a1.433 1.433 0 0 1-.495.33l-.126.053-1.815.005-1.815.006V1.619l1.845.006c1.703.006 1.854.009 1.964.042m.587 4.565a2.06 2.06 0 0 1 1.018.536c.482.458.716 1.082.647 1.723a2.1 2.1 0 0 1-1.552 1.824l-.199.055-2.155.005-2.155.006V6.2h2.121c1.857 0 2.14.004 2.275.032"
        fill="#7F869E"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default IconStyleBold;
