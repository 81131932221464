import React from 'react';

/* eslint-disable max-len */
const IconAddFolder = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.208 5L8.72068 2.4902C8.54062 2.18633 8.2136 2 7.86039 2H3C2.44772 2 2 2.44772 2 3V15C2 15.5523 2.44772 16 3 16H15C15.5523 16 16 15.5523 16 15V6C16 5.44771 15.5523 5 15 5H10.208ZM3 1C1.89543 1 1 1.89543 1 3V15C1 16.1046 1.89543 17 3 17H15C16.1046 17 17 16.1046 17 15V6C17 4.89543 16.1046 4 15 4H10.7778L9.58097 1.9804C9.22084 1.37267 8.56681 1 7.86039 1H3Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 7C9.27614 7 9.5 7.22386 9.5 7.5V13.5C9.5 13.7761 9.27614 14 9 14C8.72386 14 8.5 13.7761 8.5 13.5L8.5 7.5C8.5 7.22386 8.72386 7 9 7Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 10.5C12.5 10.7761 12.2761 11 12 11L6 11C5.72386 11 5.5 10.7761 5.5 10.5C5.5 10.2239 5.72386 10 6 10L12 10C12.2761 10 12.5 10.2239 12.5 10.5Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconAddFolder;
