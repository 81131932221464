import { assoc } from 'ramda';
import { SET_QUERY_LIST } from './constants';
import { makeQueryList } from '../../data-type/query-list';

export const defaultState = () => ({
  queryList: makeQueryList(['contacts', 'chats', 'archives'])
});

const setQueryList = (chatWidget, data) => assoc('queryList', data, chatWidget);

export const reducers = {
  [SET_QUERY_LIST]: (chatWidget, newQueryList) =>
    setQueryList(chatWidget, newQueryList)
};
