.row {
  position: relative;
}

.deleteRow {
  position: absolute;
  width: 16px;
  height: 100%;
  opacity: 0;
  margin-right: -18px;
}

.row:hover .deleteRow {
  opacity: 1;
}

.selected {
  td {
    border-bottom: 1px solid var(--color-blue-400) !important;
    border-top: 1px solid var(--color-blue-400) !important;

    &:last-of-type {
      border-right: 1px solid var(--color-blue-400) !important;
    }

    &:first-of-type {
      border-left: 1px solid var(--color-blue-400) !important;
    }
  }
}
