import { Map, fromJS } from 'immutable';

const setSupportData = (data, { supportData }) =>
  data.set('supportData', fromJS(supportData));

const setIsTokenValid = (data, { isTokenValid, userId }) =>
  data.set('isTokenValid', isTokenValid).set('userId', userId);

const setBillingTariffsData = (data, { tariffs, additions }) =>
  data.set('tariffs', fromJS(tariffs)).set('additions', fromJS(additions));

export default (state = Map({}), { type, payload }) => {
  switch (type) {
    case 'SET_SUPPORT_DATA':
      return setSupportData(state, payload);
    case 'SET_IS_TOKEN_VALID':
      return setIsTokenValid(state, payload);
    case 'SET_BILLING_TARIFFS_DATA':
      return setBillingTariffsData(state, payload);
    default:
      return state;
  }
};
