import React from 'react';

/* eslint-disable max-len */
function IconPin() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cws-icon-main-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1915 5.11366C12.5012 4.716 13.0196 4.7958 13.2793 5.0555L17.6546 9.43088C17.9143 9.69059 17.9941 10.2089 17.5965 10.5187C17.1171 10.892 16.5553 10.8339 16.2477 10.802C16.2232 10.7995 16.2004 10.7971 16.1794 10.7952C15.8138 10.761 15.7371 10.7873 15.6619 10.8625L13.0262 13.4982C13.393 14.1369 13.412 14.7709 13.2824 15.301C13.1387 15.8885 12.8183 16.3348 12.6452 16.546C12.3482 16.9082 11.843 16.8769 11.5608 16.5947L9.30603 14.3399L6.12361 17.5224C5.8958 17.7502 5.52646 17.7502 5.29865 17.5224C5.07084 17.2946 5.07084 16.9252 5.29865 16.6974L8.48107 13.515L6.11544 11.1493C5.83322 10.8671 5.80196 10.3619 6.16417 10.065C6.37538 9.89183 6.8216 9.57149 7.40914 9.42778C7.93926 9.29811 8.57323 9.3171 9.21197 9.68393L11.8476 7.04828C11.9229 6.97302 11.9491 6.89632 11.915 6.53078C11.913 6.50972 11.9106 6.48689 11.9081 6.46246C11.8763 6.15486 11.8181 5.59302 12.1915 5.11366Z"
        fill="#5B5F73"
      />
    </svg>
  );
}
/* eslint-enable max-len */

export default IconPin;
