import React from 'react';

/* eslint-disable max-len */
function IconAlarmDone() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_248_3571)">
        <rect
          width="14"
          height="14"
          transform="translate(0 0.5)"
          fill="white"
        />
        <path
          d="M2.3222 12.9025C2.10489 13.1882 2.16035 13.596 2.44609 13.8133C2.73182 14.0306 3.13963 13.9752 3.35694 13.6894L2.3222 12.9025ZM10.1117 11.9173L9.69759 11.4163L10.1117 11.9173ZM10.7534 13.7106C10.9824 13.9871 11.3922 14.0255 11.6686 13.7965C11.9451 13.5675 11.9835 13.1577 11.7545 12.8813L10.7534 13.7106ZM2.76292 8.14886C2.76292 5.80882 4.65989 3.91184 6.99994 3.91184V2.61184C3.94192 2.61184 1.46292 5.09085 1.46292 8.14886H2.76292ZM6.99994 3.91184C9.33998 3.91184 11.237 5.80882 11.237 8.14886H12.537C12.537 5.09085 10.0579 2.61184 6.99994 2.61184V3.91184ZM6.99994 12.3859C5.9743 12.3859 5.0353 12.0223 4.30228 11.4163L3.47397 12.4182C4.43141 13.2098 5.66093 13.6859 6.99994 13.6859V12.3859ZM4.30228 11.4163C3.36096 10.6381 2.76292 9.46361 2.76292 8.14886H1.46292C1.46292 9.86753 2.24673 11.4037 3.47397 12.4182L4.30228 11.4163ZM3.37076 11.5238L2.3222 12.9025L3.35694 13.6894L4.40549 12.3108L3.37076 11.5238ZM11.237 8.14886C11.237 9.46361 10.6389 10.6381 9.69759 11.4163L10.5259 12.4182C11.7531 11.4037 12.537 9.86753 12.537 8.14886H11.237ZM9.69759 11.4163C8.96457 12.0223 8.02558 12.3859 6.99994 12.3859V13.6859C8.33894 13.6859 9.56846 13.2098 10.5259 12.4182L9.69759 11.4163ZM9.61121 12.332L10.7534 13.7106L11.7545 12.8813L10.6123 11.5026L9.61121 12.332Z"
          fill="#5B5F73"
        />
        <path
          d="M7.62083 6.16235C7.62083 5.80337 7.32981 5.51235 6.97083 5.51235C6.61184 5.51235 6.32083 5.80337 6.32083 6.16235H7.62083ZM8.73525 9.3487C9.09423 9.3487 9.38525 9.05769 9.38525 8.6987C9.38525 8.33972 9.09423 8.0487 8.73525 8.0487V9.3487ZM6.32083 6.16235V8.46537H7.62083V6.16235H6.32083ZM7.20416 9.3487H8.73525V8.0487H7.20416V9.3487ZM6.32083 8.46537C6.32083 8.95322 6.71631 9.3487 7.20416 9.3487V8.0487C7.43428 8.0487 7.62083 8.23525 7.62083 8.46537H6.32083Z"
          fill="#5B5F73"
        />
        <path
          d="M1.21594 4.84781L0.794538 5.12711C0.878345 5.25356 1.0145 5.33569 1.16544 5.35084C1.31638 5.36599 1.46615 5.31256 1.57342 5.20529L1.21594 4.84781ZM3.41379 2.64997L3.77127 3.00745C3.86892 2.90981 3.92237 2.77647 3.91921 2.63841C3.91606 2.50036 3.85656 2.3696 3.75456 2.27652L3.41379 2.64997ZM1.57342 5.20529L3.77127 3.00745L3.05631 2.29249L0.858456 4.49033L1.57342 5.20529ZM3.75456 2.27652C3.47005 2.01691 3.0083 1.76826 2.49159 1.70355C1.95183 1.63595 1.33745 1.77082 0.843764 2.30781L1.58811 2.99213C1.82597 2.7334 2.09661 2.67309 2.36594 2.70682C2.65832 2.74344 2.92972 2.89266 3.07302 3.02341L3.75456 2.27652ZM0.843764 2.30781C0.361029 2.83289 0.255962 3.42495 0.326352 3.9429C0.393913 4.44004 0.62003 4.86382 0.794538 5.12711L1.63734 4.56851C1.50703 4.37191 1.36738 4.09462 1.32825 3.80674C1.29196 3.53967 1.3393 3.26277 1.58811 2.99213L0.843764 2.30781Z"
          fill="#5B5F73"
        />
        <path
          d="M12.7839 4.84781L13.2053 5.12711C13.1215 5.25356 12.9854 5.33569 12.8344 5.35084C12.6835 5.36599 12.5337 5.31256 12.4265 5.20529L12.7839 4.84781ZM10.5861 2.64997L10.2286 3.00745C10.131 2.90981 10.0775 2.77647 10.0807 2.63841C10.0838 2.50036 10.1433 2.3696 10.2453 2.27652L10.5861 2.64997ZM12.4265 5.20529L10.2286 3.00745L10.9436 2.29249L13.1414 4.49033L12.4265 5.20529ZM10.2453 2.27652C10.5298 2.01691 10.9916 1.76826 11.5083 1.70355C12.048 1.63595 12.6624 1.77082 13.1561 2.30781L12.4118 2.99213C12.1739 2.7334 11.9033 2.67309 11.6339 2.70682C11.3416 2.74344 11.0702 2.89266 10.9269 3.02341L10.2453 2.27652ZM13.1561 2.30781C13.6388 2.83289 13.7439 3.42495 13.6735 3.9429C13.606 4.44004 13.3798 4.86382 13.2053 5.12711L12.3625 4.56851C12.4928 4.37191 12.6325 4.09462 12.6716 3.80674C12.7079 3.53967 12.6606 3.26277 12.4118 2.99213L13.1561 2.30781Z"
          fill="#5B5F73"
        />
        <circle cx="12" cy="12.5" r="2" fill="#DD1E2A" />
      </g>
      <defs>
        <clipPath id="clip0_248_3571">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
/* eslint-enable max-len */

export default IconAlarmDone;
