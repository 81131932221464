.badge {
  background: var(--color-additionaly-cardinal);
  border-radius: 18px;
  font-size: 10px;
  font-weight: 600;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  height: 18px;
  min-width: 18px;
  padding: var(--indent-xxxs) var(--indent-xxs);
}
