import React from 'react';

/* eslint-disable max-len */
const IconFolder = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.37423 2.65162C2.12529 2.65162 1.88654 2.74844 1.71051 2.92077C1.53448 3.0931 1.43558 3.32683 1.43558 3.57054V14.9219C1.43558 15.1656 1.53448 15.3993 1.71051 15.5717C1.88654 15.744 2.12529 15.8408 2.37423 15.8408H15.6258C15.8747 15.8408 16.1135 15.744 16.2895 15.5717C16.4655 15.3993 16.5644 15.1656 16.5644 14.9219V6.00297C16.5644 5.75926 16.4655 5.52553 16.2895 5.3532C16.1135 5.18087 15.8747 5.08405 15.6258 5.08405H8.17178C7.93178 5.08405 7.70767 4.96663 7.57454 4.77114L6.13119 2.65162H2.37423ZM0.695397 1.92699C1.14065 1.4911 1.74455 1.24622 2.37423 1.24622H6.51534C6.75533 1.24622 6.97945 1.36364 7.11258 1.55913L8.55593 3.67865H15.6258C16.2555 3.67865 16.8593 3.92353 17.3046 4.35943C17.7499 4.79532 18 5.38652 18 6.00297V14.9219C18 15.5383 17.7499 16.1295 17.3046 16.5654C16.8593 17.0013 16.2555 17.2462 15.6258 17.2462H2.37423C1.74455 17.2462 1.14065 17.0013 0.695397 16.5654C0.250142 16.1295 0 15.5383 0 14.9219V3.57054C0 2.95409 0.250142 2.36289 0.695397 1.92699Z"
      fill="#5B5F73"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconFolder;
