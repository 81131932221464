import React from 'react';

/* eslint-disable max-len */
const IconArrowLeft = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      className="cws-icon-hover"
      cx="17"
      cy="17"
      r="17"
      fill="#767B92"
      fillOpacity="0"
    />
    <path
      className="cws-icon-main-stroke"
      d="M19.0181 10.8359L14.916 16.8179L19.0181 22.7999"
      stroke="#767B92"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconArrowLeft;
