import { useCallback, useLayoutEffect, useRef } from 'react';
import { canUseDom } from '../lib/dom';
import { isFunction } from '../lib/utils';

export function useTimeout(cb: () => any, duration: number) {
  const options = useRef({ cb, duration });

  useLayoutEffect(() => {
    options.current.cb = cb;
    options.current.duration = duration;
  }, [cb, duration]);

  const timeout = useRef<ReturnType<typeof setTimeout>>();

  const clear = useCallback(() => {
    if (canUseDom && timeout?.current) {
      clearTimeout(timeout.current);
    }
  }, []);

  const set = useCallback(
    (_duration = options.current.duration) => {
      clear();

      if (canUseDom) {
        timeout.current = setTimeout(() => {
          if (isFunction(options.current.cb)) {
            options.current.cb();
          }
        }, _duration);
      }
    },
    [clear]
  );

  useLayoutEffect(() => clear, []);

  return { set, clear };
}
