import { makeEvent, makeCommand } from '../data/messageBus/message';
import { makeQuery } from './utils';

const NS = 'TASKS';

export const taskQRY = makeQuery(NS, 'task');

export const createdTaskEVT = makeEvent(NS, 'TASK_WAS_CREATED');

export const expiredTaskEVT = makeEvent(NS, 'TASK_WAS_EXPIRED');

export const changedExecutorOfTaskEVT = makeEvent(
  NS,
  'EXECUTOR_OF_TASK_WAS_CHANGED'
);

export const destroyedTaskEVT = makeEvent(NS, 'TASK_WAS_DESTROYED');

export const setTaskAsViewedCMD = makeCommand(NS, 'SET_TASK_AS_VIEWED');

export const updateTaskCMD = makeCommand(NS, 'UPDATE_TASK');

export const checkExpiredTasksCMD = makeCommand(NS, 'CHECK_EXPIRED_TASKS');

export const viewCMD = makeCommand(NS, 'view');
