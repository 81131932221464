import { indexOf, append } from 'ramda';
import { defStruct } from '../../lib';

/**
 * @typedef LoaderList
 */
const methods = defStruct('LoaderList', ['names', 'history']);

const haveLoader = (name, loaderList) => {
  const index = indexOf(name, loaderList);
  return index > -1;
};

const addToList = (name, loaderList) =>
  haveLoader(name, loaderList) ? loaderList : append(name, loaderList);
const delFromList = (name, loaderList) => loaderList.filter((n) => n !== name);

/**
 * Create a LoaderList
 *
 * @param {object} params
 * @param {?[string]} params.names
 * @param {?[string]} params.history
 * @returns {LoaderList}
 */
export const makeLoaderList = ({ names = [], history = [] } = {}) =>
  methods.makeLoaderList(names, history);

/**
 * Gives a list of loader names
 *
 * @param {LoaderList} loaderList
 * @returns {[string]}
 */
export const getNames = (loaderList) => methods.getNames(loaderList);

/**
 * Checks if is LoaderList
 *
 * @param {*} a
 * @returns {boolean}
 */
export const isLoaderList = (a) => methods.isLoaderList(a);

/**
 * Check if is loading a loader
 *
 * @param {string} name
 * @param {LoaderList} loaderList
 * @returns {boolean}
 */
export const isLoading = (name, loaderList) =>
  haveLoader(name, getNames(loaderList));

/**
 * Check if is loading a loader at least once
 *
 * @param {string} name
 * @param {LoaderList} loaderList
 * @returns {boolean}
 */
export const isLoadedOnce = (name, loaderList) =>
  haveLoader(name, methods.getHistory(loaderList));

/**
 * Launch the loader by name
 *
 * @param {string} name
 * @param {LoaderList} loaderList
 * @returns {LoaderList}
 */
export const start = (name, loaderList) =>
  isLoading(name, loaderList)
    ? loaderList
    : makeLoaderList({
        names: addToList(name, getNames(loaderList)),
        history: methods.getHistory(loaderList)
      });

/**
 * Disables the loader by name
 *
 * @param {string} name
 * @param {LoaderList} loaderList
 * @returns {LoaderList}
 */
export const finish = (name, loaderList) => {
  if (!haveLoader(name, getNames(loaderList))) return loaderList;

  return makeLoaderList({
    names: delFromList(name, getNames(loaderList)),
    history: addToList(name, methods.getHistory(loaderList))
  });
};

/**
 * Reset loader startup history by name
 *
 * @param {string} name
 * @param {LoaderList} loaderList
 * @returns {LoaderList}
 */
export const resetLoader = (name, loaderList) =>
  makeLoaderList({
    names: getNames(loaderList),
    history: delFromList(name, methods.getHistory(loaderList))
  });
