import { has, assoc, pipe, uniq } from 'ramda';

const setIfExists = (isExist, setValue, obj) => {
  if (isExist(obj)) {
    return setValue(obj);
  }
  return obj;
};

const assocIfExists = (keyName, value, obj) =>
  setIfExists(has(keyName), assoc(keyName, value), obj);

const constr = (...withModules) => pipe(...withModules)({ type: 'ListsKit' });

const withList =
  (list = []) =>
  (obj = {}) => ({
    ...obj,
    list
  });

const setList = (list, obj) => assocIfExists('list', list, obj);

const withSubList =
  (sublist = []) =>
  (obj = {}) => ({
    ...obj,
    sublist
  });

const setSubList = (sublist, obj) => assocIfExists('sublist', sublist, obj);

const getSubListLength = (obj) => obj.sublist.length;

const withAmountLeftNext =
  (amountLeft = 0) =>
  (obj = {}) => ({
    ...obj,
    amountLeftNext: amountLeft
  });

const setAmountLeftNext = (amountLeft, obj) =>
  assocIfExists('amountLeftNext', amountLeft, obj);

const canLoadNext = (listKit) => listKit.amountLeftNext > 0;

const withAmountLeftPrev =
  (amountLeft = 0) =>
  (obj = {}) => ({
    ...obj,
    amountLeftPrev: amountLeft
  });

const setAmountLeftPrev = (amountLeft, obj) =>
  assocIfExists('amountLeftPrev', amountLeft, obj);

const canLoadPrev = (listKit) => listKit.amountLeftPrev > 0;

const withSelectedIds =
  (selectIds = []) =>
  (obj = {}) => ({
    ...obj,
    selectIds
  });

const assocSelectIds = assoc('selectIds');

const setSelectedIds = (selectIds, obj) =>
  assocIfExists('selectIds', selectIds, obj);

const selectId = (id, obj) => {
  const updateSelectId = uniq([...obj.selectIds, id]);
  return assocSelectIds(updateSelectId, obj);
};

const unselectId = (id, obj) => {
  const updateSelectId = obj.selectIds.filter((sid) => sid !== id);
  return assocSelectIds(updateSelectId, obj);
};

const toggleSelect = (id, obj) => {
  if (obj.selectIds.includes(id)) {
    return unselectId(id, obj);
  }
  return selectId(id, obj);
};

const withInitTopItemIndex =
  (index = 0) =>
  (obj = {}) => ({
    ...obj,
    initTopItemIndex: index
  });

const setInitTopItemIndex = (index, obj) =>
  assocIfExists('initTopItemIndex', index, obj);

export {
  constr,
  withList,
  setList,
  withSubList,
  setSubList,
  getSubListLength,
  withAmountLeftNext,
  setAmountLeftNext,
  canLoadNext,
  withAmountLeftPrev,
  setAmountLeftPrev,
  canLoadPrev,
  withSelectedIds,
  setSelectedIds,
  selectId,
  unselectId,
  toggleSelect,
  withInitTopItemIndex,
  setInitTopItemIndex
};
