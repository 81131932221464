import React from 'react';

/* eslint-disable max-len */
const IconCopyM = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      className="cws-icon-hover"
      cx="14"
      cy="14"
      r="14"
      fill="#767B92"
      fillOpacity="0"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 14C6 12.3431 7.34315 11 9 11H14C15.6569 11 17 12.3431 17 14V19C17 20.6569 15.6569 22 14 22H9C7.34315 22 6 20.6569 6 19V14ZM9 12C7.89543 12 7 12.8954 7 14V19C7 20.1046 7.89543 21 9 21H14C15.1046 21 16 20.1046 16 19V14C16 12.8954 15.1046 12 14 12H9Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 9C11 7.34315 12.3431 6 14 6H19C20.6569 6 22 7.34315 22 9V14C22 15.6569 20.6569 17 19 17H16V16H19C20.1046 16 21 15.1046 21 14V9C21 7.89543 20.1046 7 19 7H14C12.8954 7 12 7.89543 12 9V12H11V9Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconCopyM;
